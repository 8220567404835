const components = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        cH1: 'h1',
        cH2: 'h2',
        cH3: 'h3',
        cH4: 'h4',
        cH5: 'h5',
        cH6: 'h6',
        cBody1: 'body1',
        cBody2: 'body2',
        cCaption: 'caption',
      },
    },
  },
  MuiButton: {
    defaultProps: {},
    styleOverrides: {
      text: {
        color: ' #F5F7FF',
      },
      contained: {
        backgroundColor: '#419EE9',
        '&:hover': {
          backgroundColor: '#6ABCFB',
        },
        borderRadius: 8,
        fontWeight: 'bold',
        letterSpacing: 0.3,
      },
      outlined: {
        borderRadius: 8,
        letterSpacing: 0.3,
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        borderColor: '#419EE9',
        '&:hover': {
          borderColor: '#6ABCFB',
          color: '#6ABCFB',
        },
        color: ' #419EE9',
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {},
    styleOverrides: {
      input: {
        padding: 12,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#F5F7FF',
          fontWeight: 'bold',
          borderBottom: '4px solid',
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        '&.Mui-checked': {
          color: '#419EE9',
          '& + .MuiSwitch-track': {
            backgroundColor: '#F5F7FF',
          },
        },
      },
      track: {
        backgroundColor: '#F5F7FF', // Cambia 'tuColorParaCuandoEstaDesactivado' al color deseado
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: '#F5F7FF',
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: '#F5F7FF',
        },
      },
    },
  },
};

export default components;
