import { useGrowthBook } from '@growthbook/growthbook-react';
import {
  Handshake as HandshakeIcon,
  Logout as LogoutIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
} from '@mui/icons-material';
import GavelIcon from '@mui/icons-material/Gavel';
import { Box, Divider, Popover, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React from 'react';

import Env from '../../../../env/env';
import { selectUserSession } from '../../../../features/session/Session.selector';
import { removeSession } from '../../../../features/session/Session.slice';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import {
  SHOW_GOLPOINTS,
  SHOW_REFERRALS,
  SHOW_TRADES,
  WEB3_FEATURES,
} from '../../../../utils/growthbookFeatures';
import { TAG_VALUE } from '../../../../utils/tagger';
import GolpointsBalance from '../../../components/GolpointsBalance/GolpointsBalance';
import { ImageFallback } from '../../../components/ImageFallback/ImageFallback';
import UserAvatar from '../../../components/UserAvatar/UserAvatar';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../../hooks/useDesktopDeviceMediaQuery';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import MenuDropdownItem from '../mobile/MenuDropdownItem';
import { USER_DROPDOWN_EMAIL_MAX_WIDTH } from './UserDropdown.constants';
import { UserDropdownProps } from './UserDropdown.types';

const referralsIcon = `${Env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/referrals/referrals-icon.svg`;

const UserDropdown = ({
  isDropdownOpen,
  setIsDropdownOpen,
}: UserDropdownProps) => {
  const { t } = useTranslationByNamespaces('header.userMenu.userDropdown');
  const isMobile = useMobileMediaQuery();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserSession);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'menu-popover' : undefined;
  const isDesktop = useDesktopDeviceMediaQuery();
  const growthbook = useGrowthBook();

  const handleClick = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsDropdownOpen(false);
  };

  const useLogOutHandler = () => {
    setIsDropdownOpen(false);
    dispatch(removeSession());
    window.location.href = `${Env.REACT_APP_BASE_APP_URL}login`;
  };

  return (
    <Stack justifyContent="center">
      <UserAvatar
        height={isMobile ? `${SpaceSizes.lg}px` : undefined}
        width={isMobile ? `${SpaceSizes.lg}px` : undefined}
        onClick={handleClick}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={id}
        open={isDropdownOpen}
        onClose={handleClose}
      >
        <Box p={SpaceSizes.md} width={250}>
          <Stack
            divider={<Divider sx={{ width: '100%' }} />}
            spacing={SpaceSizes.md}
          >
            {user && (
              <Stack
                flexDirection="column"
                justifyContent="center"
                spacing={SpaceSizes.sm}
              >
                <Stack flexDirection="column" spacing={SpaceSizes.xs}>
                  <Typography>
                    {isNotNilOrEmpty(user.first_name)
                      ? user.first_name
                      : user.nickname}
                  </Typography>
                  <Typography
                    fontSize="small"
                    maxWidth={USER_DROPDOWN_EMAIL_MAX_WIDTH}
                    sx={{ opacity: 0.8 }}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    noWrap
                  >
                    {isNotNilOrEmpty(user.email) && user.email}
                  </Typography>
                </Stack>
                {growthbook?.isOn(SHOW_GOLPOINTS) && (
                  <GolpointsBalance
                    shouldRefetch={isDropdownOpen}
                    onNavigate={() => setIsDropdownOpen(false)}
                  />
                )}
              </Stack>
            )}

            <Stack spacing={SpaceSizes.sm}>
              {user && (
                <>
                  <MenuDropdownItem
                    icon={<PersonIcon sx={{ color: 'white' }} />}
                    setIsDropdownOpen={() => {
                      setIsDropdownOpen(false);
                    }}
                    tagId={TAG_VALUE.HEADER.PROFILE}
                    text={t('buys')}
                    to={`/user/${user.user_id}`}
                  />
                  <MenuDropdownItem
                    icon={<GavelIcon sx={{ color: 'white' }} />}
                    setIsDropdownOpen={() => {
                      setIsDropdownOpen(false);
                    }}
                    tagId={TAG_VALUE.HEADER.AUCTIONS}
                    text={t('auctions')}
                    to="/auctions"
                  />
                  <MenuDropdownItem
                    icon={<ShoppingBagIcon sx={{ color: 'white' }} />}
                    setIsDropdownOpen={() => {
                      setIsDropdownOpen(false);
                    }}
                    tagId={TAG_VALUE.HEADER.MOVEMENTS}
                    text={t('movements')}
                    to="/movements"
                  />
                  {growthbook?.isOn(SHOW_TRADES) &&
                    growthbook?.isOn(WEB3_FEATURES) && (
                      <MenuDropdownItem
                        icon={<HandshakeIcon />}
                        setIsDropdownOpen={() => {
                          setIsDropdownOpen(false);
                        }}
                        tagId={TAG_VALUE.HEADER.TRADES}
                        text={t('trades')}
                        to="/trades"
                      />
                    )}
                  {growthbook?.isOn(SHOW_REFERRALS) && (
                    <MenuDropdownItem
                      icon={
                        <ImageFallback
                          fallback={referralsIcon}
                          height={20}
                          src={referralsIcon}
                          width={20}
                        />
                      }
                      setIsDropdownOpen={() => {
                        setIsDropdownOpen(false);
                      }}
                      tagId={TAG_VALUE.HEADER.REFERRALS}
                      text={t('referrals')}
                      to="/referrals"
                    />
                  )}
                  <MenuDropdownItem
                    icon={<SettingsIcon sx={{ color: 'white' }} />}
                    setIsDropdownOpen={() => {
                      setIsDropdownOpen(false);
                    }}
                    tagId={TAG_VALUE.HEADER.CONFIGURATION}
                    text={t('configuration')}
                    to="/configuration"
                  />
                  {!isDesktop && (
                    <MenuDropdownItem
                      icon={<NotificationsIcon sx={{ color: 'white' }} />}
                      setIsDropdownOpen={() => {
                        setIsDropdownOpen(false);
                      }}
                      tagId={TAG_VALUE.HEADER.NOTIFICATIONS}
                      text={t('notifications')}
                      to="/notifications"
                    />
                  )}
                </>
              )}
              {user && (
                <MenuDropdownItem
                  data-testid="userMenuLogout"
                  icon={<LogoutIcon sx={{ color: 'white' }} />}
                  setIsDropdownOpen={useLogOutHandler}
                  text={t('logout')}
                  to="/login"
                />
              )}
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
};

export default UserDropdown;
