import { Dialog, Stack } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getStorage } from '../../../utils/storage';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import i18n from '../../i18n';
import { defaultDelay, storySx } from './constants.json';
import { previewVariants } from './motion.json';
import { StoryHighlightProps } from './Story.interfaces';
import { hasFastConnection } from './Story.utils';
import { StoryBubble } from './StoryBubble';
import { StoryPreview } from './StoryPreview';
import { StoryVideo } from './StoryVideo';

const AnimatedStack = motion(Stack);
export const StoryHighlight: FC<StoryHighlightProps> = ({
  stories,
  needsResizing = false,
  storyType,
}) => {
  const finished = getStorage()?.getItem(`finishedStories-${storyType}`);
  const initialFinishedStories = useMemo(() => {
    return finished ? JSON.parse(finished) : [];
  }, [finished]);
  const [active, setActive] = useState<boolean>(false);
  const [activeStory, setActiveStory] = useState<number>(0);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [finishedStories, setFinishedStories] = useState<string[]>(
    initialFinishedStories,
  );
  const [remainingStories, setRemainingStories] = useState<string[]>(
    stories.filter((story) => !initialFinishedStories.includes(story)),
  );
  const [unseenStories, setUnseenStories] =
    useState<string[]>(remainingStories);

  const content = useMemo(() => {
    if (typeof remainingStories[activeStory] !== 'string') {
      const lastStory = remainingStories[activeStory - 1];
      if (typeof lastStory === 'string') {
        return lastStory;
      }
      setActive(false);
      return null;
    }
    return remainingStories[activeStory];
  }, [remainingStories, activeStory]);
  const locale = i18n.resolvedLanguage || 'es';
  const { t } = useTranslationByNamespaces('pages.home.stories');
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const loadVideo = useCallback(
    async (target: string) => {
      const quality = hasFastConnection() ? '' : '_sd';
      const response = await fetch(
        `${env.REACT_APP_STATIC_BASE_URL}marketplace/stories/${target}/${locale}${quality}.mp4`,
      );
      const videoBlob = await response.blob();
      const videoUrl = URL.createObjectURL(videoBlob);
      setVideoSrc(videoUrl);
      const video = document.createElement('video');
      video.src = videoUrl;
      setVideoLoaded(true);
    },
    [locale],
  );

  useEffect(() => {
    const finishedStoriesList = finished ? JSON.parse(finished) : [];
    const newUnseenStories = remainingStories.filter(
      (story) => !finishedStoriesList.includes(story),
    );
    setUnseenStories(newUnseenStories);
  }, [remainingStories, finished, storyType]);
  const handleVideoEnd = useCallback(() => {
    setIsChanging(true);
    const removeRemainingStories = setTimeout(() => {
      setRemainingStories((prevStories: string[]) => {
        const newStories = prevStories.filter(
          (_, index) => index !== activeStory,
        );
        const newFinishedStories = [
          ...finishedStories,
          remainingStories[activeStory],
        ];
        getStorage()?.setItem(
          `finishedStories-${storyType}`,
          JSON.stringify(newFinishedStories),
        );
        setFinishedStories(newFinishedStories);
        getStorage()?.setItem(
          `unseenStories-${storyType}`,
          JSON.stringify(newStories),
        );
        if (newStories.length === 0) {
          setActive(false);
        } else if (activeStory === prevStories.length - 1) {
          setActiveStory(newStories.length - 1);
        }
        return newStories;
      });
    }, defaultDelay);
    return () => clearTimeout(removeRemainingStories);
  }, [activeStory, finishedStories, remainingStories, storyType]);

  useEffect(() => {
    if (active && content) loadVideo(content);
  }, [active, content, loadVideo]);

  const handleClose = useCallback(() => {
    setVideoLoaded(false);
    setActive(false);
  }, []);

  const videoChange = (direction: number) => {
    if (!isChanging) {
      setIsChanging(true);
      const initializeChange = setTimeout(() => {
        setActiveStory(activeStory + direction);
      }, defaultDelay);
      return () => clearTimeout(initializeChange);
    }
    return null;
  };

  return unseenStories.length ? (
    <Stack
      alignItems="center"
      direction="row"
      height={needsResizing ? SpaceSizes.xxl : 'auto'}
      width="100%"
    >
      <Stack data-testid="story-highlight-component" position="relative">
        <Stack direction="row" gap={SpaceSizes.md}>
          {unseenStories.map((story: string, index: number) => {
            return (
              <Stack
                key={`remaining-stories-${story}`}
                data-testid={`story-activate-${story}`}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setActive(true);
                  setActiveStory(index);
                }}
              >
                <StoryBubble
                  isLoading={active && activeStory === index}
                  preview={`${env.REACT_APP_STATIC_BASE_URL}marketplace/stories/${story}/prev.png`}
                  title={t(`title.${story}`)}
                />
              </Stack>
            );
          })}
        </Stack>
        <Dialog
          aria-describedby="precached-video"
          aria-labelledby="video-modal"
          open={videoLoaded && active}
          PaperProps={{
            sx: {
              margin: SpaceSizes.none,
              display: 'flex',
              overflow: 'visible',
              backdropFilter: 'blur(3px)',
              gap: SpaceSizes.md,
              maxWidth: '100%',
              maxHeight: '100vh',
              minHeight: '100vh',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              top: 0,
              position: 'absolute',
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
          sx={{
            background: 'none',
          }}
          onClose={handleClose}
        >
          <AnimatePresence>
            {videoLoaded && active && (
              <Stack
                key="loaded-active-highlight"
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                direction="row"
                gap={SpaceSizes.lg}
                height="100%"
                justifyContent="center"
                position="relative"
                top={0}
                width="100%"
              >
                <AnimatedStack
                  animate="show"
                  exit="hidden"
                  initial="hidden"
                  sx={{
                    display: storySx.display,
                    height: storySx.height,
                    width: storySx.width,
                  }}
                  variants={previewVariants}
                >
                  <StoryPreview
                    clickFn={() => {
                      videoChange(-1);
                    }}
                    story={remainingStories[activeStory - 1]}
                  />
                </AnimatedStack>
                <AnimatedStack
                  alignContent="center"
                  data-testid="story-content"
                  height={storySx.height}
                  initial="initial"
                  justifyContent="flex-start"
                  position="relative"
                  width={storySx.width}
                >
                  {isChanging && (
                    <StoryPreview
                      story={remainingStories[activeStory]}
                      isStatic
                    />
                  )}
                  <StoryVideo
                    ref={videoRef}
                    closeFn={handleClose}
                    finishedStories={finishedStories.length}
                    isActive={active}
                    isChanging={isChanging}
                    src={videoSrc}
                    stories={stories}
                    storyName={remainingStories[activeStory]}
                    onEnd={handleVideoEnd}
                    onLoaded={() => setIsChanging(false)}
                  />
                </AnimatedStack>

                <AnimatedStack
                  animate="animate"
                  exit="exit"
                  initial="initial"
                  sx={{
                    display: storySx.display,
                    height: storySx.height,
                    width: storySx.width,
                  }}
                  variants={previewVariants}
                >
                  <StoryPreview
                    clickFn={() => {
                      videoChange(1);
                    }}
                    story={remainingStories[activeStory + 1]}
                  />
                </AnimatedStack>
              </Stack>
            )}
          </AnimatePresence>
        </Dialog>
      </Stack>
    </Stack>
  ) : null;
};
