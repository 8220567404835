import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { TeamProductCard } from '../../../features/home/Home.types';
import { parsePriceValue } from '../../../features/products/Product.selectors';
import { PriceCurrency } from '../../../features/products/Product.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import DEFAULT_LOGO from '../../assets/img/default_logo_club.png';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import ProductPrice from '../../components/ProductPrice/ProductPrice';

export const ProductHomeCard = ({
  showBorder,
  title,
  clickable,
  noCover,
  cardData,
}: {
  showBorder?: boolean;
  title?: string;
  clickable?: boolean;
  noCover?: boolean;
  cardData: TeamProductCard;
}) => {
  return (
    // Ubicamos los elementos de manera estatica porque el componente swiper rompe cuando metemos flex
    // Hay que ver si se puede arreglar
    <>
      <Box
        sx={{
          height: '45%',
          width: '100%',
          position: 'absolute',
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 2,
          opacity: 0.7,
          borderRadius: '8px',
        }}
      />
      <Box
        sx={{
          px: SpaceSizes.sm,
          py: SpaceSizes.smPlus,
          position: 'absolute',
          bottom: '0',
          left: '0',
        }}
        zIndex={3}
      >
        <Stack direction="column">
          <Typography
            align="left"
            color="white"
            fontWeight="bold"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            variant="body2"
          >
            {cardData.player_name}
          </Typography>

          <Stack alignItems="center" direction="row" gap={SpaceSizes.xs}>
            <Typography
              align="left"
              color="white"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              variant="body2"
            >
              vs{' '}
            </Typography>
            <ImageFallback
              alt="Home club"
              fallback={DEFAULT_LOGO}
              height={25}
              src={cardData.goal_against_logo}
              style={{ padding: 0 }}
              width={25}
            />
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <ImageFallback
            alt="Goal for club"
            fallback={DEFAULT_LOGO}
            height={45}
            src={cardData.goal_for_logo}
            style={{ padding: 0 }}
            width={45}
          />
          <Stack alignItems="center" flexDirection="row" p={SpaceSizes.sm}>
            <ProductPrice
              currency={cardData.price_currency as PriceCurrency}
              size="medium"
              value={Number(parsePriceValue(cardData.price_value))}
              showLogo
            />
          </Stack>
        </Stack>
      </Box>

      <ImageFallback
        alt={title || 'Home Card'}
        fallback={DEFAULT_LOGO}
        height="25"
        src={cardData.card_image}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          objectFit: noCover ? 'fill' : 'cover',
          border: showBorder ? '1px solid #7A7A93;' : 'none',
          cursor: clickable ? 'pointer' : 'default',
        }}
        width="25"
      />
    </>
  );
};
