import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { SmsForm } from '../../components/SmsForm/SmsForm';

const SmsVerification = () => {
  const { t } = useTranslation();

  return (
    <PageContainer
      id="smsValidationPage"
      title={t('pages.smsVerification.title')}
      backButton
    >
      <SmsForm />
    </PageContainer>
  );
};

export default SmsVerification;
