import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DialogCommon } from '../Dialog/Dialog';
import { PhoneVerificationDialogProps } from './PhoneVerificationDialog.types';

export const PhoneVerificationDialog = ({
  open,
  handleClose,
}: PhoneVerificationDialogProps) => {
  const { t } = useTranslation();

  const onClose = () => {
    handleClose();
  };

  return (
    <DialogCommon
      confirm={{
        url: '/sms-verification',
        message: t('components.phoneVerificationDialog.confirm'),
      }}
      description={
        <Trans i18nKey="components.phoneVerificationDialog.description" t={t} />
      }
      handleClose={onClose}
      isOpen={open}
      title={t('components.phoneVerificationDialog.title')}
    />
  );
};
