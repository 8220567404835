import { Box, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { BenefitList } from '../BenefitList/BenefitList';
import ProductDetailTitle from '../ProductDetailTitle/ProductDetailTitle';
import { boxContainerStyled } from './ProductBenefitsWidget.styled';
import { ProductBenefitsWidgetProps } from './ProductBenefitsWidget.types';

export const ProductBenefitsWidget = ({
  hasPhysicalBall,
}: ProductBenefitsWidgetProps) => {
  const { t } = useTranslationByNamespaces('components.productBenefits');
  return (
    <Box sx={boxContainerStyled}>
      <Stack justifyContent="center" spacing={SpaceSizes.md}>
        <ProductDetailTitle variant="cH3">{t('title')}</ProductDetailTitle>
        <BenefitList hasPhysicalBall={hasPhysicalBall} />
      </Stack>
    </Box>
  );
};
