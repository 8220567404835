import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  STATIC_SIZE_HEIGHT,
  STATIC_SIZE_WIDTH,
} from '../../components/CompactCard/CompactCard.constants';
import { useTradeItem } from '../../components/TradeItem/useTradeItem';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import {
  TRADE_TEAM_FONT_SIZE,
  TRADE_TITLE_FONT_SIZE,
} from './CardTradeDetail.constants';
import { ICardTradeDetailSummary } from './CardTradeDetailSummary.interfaces';

export const CardTradeDetailSummary = ({
  events,
  player,
  match,
  ballId,
}: ICardTradeDetailSummary) => {
  const { awayTeam, pickGoal, firstGoalEvent } = useTradeItem(
    events,
    player,
    match,
  );
  const { t } = useTranslationByNamespaces('pages.cardTrade');
  const isSmallestMobile = useSmallMobileMediaQuery();
  const isDesktop = useDesktopDeviceMediaQuery();
  return (
    <Stack
      direction={isDesktop ? 'column' : 'row'}
      gap={isDesktop ? SpaceSizes.lgPlus : SpaceSizes.md}
    >
      <Stack
        borderRadius={SpaceSizes.xxs}
        height={STATIC_SIZE_HEIGHT}
        sx={{
          backgroundSize: 'auto 100%',
          backgroundImage: `url("${player?.image}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        width={STATIC_SIZE_WIDTH}
      />
      <Stack gap={SpaceSizes.xs}>
        <Typography fontSize={TRADE_TITLE_FONT_SIZE} fontWeight={600}>
          {player.name}
        </Typography>
        <Stack
          direction={isSmallestMobile ? 'column' : 'row'}
          gap={isSmallestMobile ? SpaceSizes.none : SpaceSizes.sm}
        >
          <Stack alignItems="center" direction="row" gap={SpaceSizes.xxs}>
            <Typography
              fontSize={
                isSmallestMobile ? SpaceSizes.smPlus : TRADE_TEAM_FONT_SIZE
              }
              fontWeight={400}
            >
              {t('detail.goal')} #{pickGoal}
            </Typography>
            <Typography
              color="text.secondary"
              fontSize={
                isSmallestMobile ? SpaceSizes.smPlus : TRADE_TEAM_FONT_SIZE
              }
              fontWeight={400}
            >
              {firstGoalEvent?.event_data.is_own_goal
                ? `(${t('ownGoal')})`
                : null}
            </Typography>
          </Stack>
          <Stack direction="row" gap={SpaceSizes.xs}>
            <Typography
              fontSize={
                isSmallestMobile ? SpaceSizes.smPlus : TRADE_TEAM_FONT_SIZE
              }
              fontWeight={400}
            >
              vs
            </Typography>
            <Typography
              fontSize={
                isSmallestMobile ? SpaceSizes.smPlus : TRADE_TEAM_FONT_SIZE
              }
              fontWeight={400}
            >
              {awayTeam?.name}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          color="text.secondary"
          fontSize={isSmallestMobile ? SpaceSizes.smPlus : TRADE_TEAM_FONT_SIZE}
          fontWeight={500}
        >
          #{ballId.toUpperCase()}
        </Typography>

        {!isSmallestMobile && (
          <Typography color="text.primary" fontWeight={400} variant="cH6">
            {t('detail.description')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
