import { Stack } from '@mui/material';
import React from 'react';

import { selectWebBenefits } from '../../../features/products/Product.selectors';
import { useGetBenefitsInformationQuery } from '../../../features/products/Products.api';
import i18n from '../../i18n';
import { BenefitItem } from '../BenefitItem/BenefitItem';
import { benefitContainer } from './BenefitList.styled';
import { BenefitListProps } from './BenefitList.types';

export const BenefitList = ({ hasPhysicalBall }: BenefitListProps) => {
  const { language } = i18n;
  const { benefits, isLoadingBenefits, isSuccessBenefits } =
    useGetBenefitsInformationQuery(
      {
        lang: language,
      },
      {
        selectFromResult: ({ data, isLoading, isSuccess }) => ({
          ...data,
          isLoadingBenefits: isLoading,
          isSuccessBenefits: isSuccess,
          benefits: selectWebBenefits(data),
        }),
      },
    );

  if (!benefits || isLoadingBenefits || !isSuccessBenefits) return null;
  const webPlatformList = benefits.filter((item) =>
    hasPhysicalBall ? item : item.id !== 'physical_ball',
  );

  return (
    <Stack sx={benefitContainer}>
      {webPlatformList.map((item) => {
        return (
          <BenefitItem
            key={item.id}
            actionUrl={item.actionUrl}
            description={item.description}
            icon={item.icon}
            list={item.bullets}
            title={item.title}
          />
        );
      })}
    </Stack>
  );
};
