import { StoryAction, StoryActionType, StoryState } from './Story.enums';
import { CustomNavigator } from './Story.interfaces';

export function storyReducer(
  state: StoryState,
  action: StoryAction,
): StoryState {
  switch (action.type) {
    case StoryActionType.NEXT_STORY:
      if (state.activeStoryIndex < state.stories.length - 1) {
        const newViewedStories = [
          ...state.viewedStories,
          state.stories[state.activeStoryIndex],
        ];
        return {
          ...state,
          activeStoryIndex: state.activeStoryIndex + 1,
          viewedStories: newViewedStories,
        };
      }
      return state;
    case StoryActionType.PREV_STORY:
      if (state.activeStoryIndex > 0) {
        return { ...state, activeStoryIndex: state.activeStoryIndex - 1 };
      }
      return state;
    default:
      return state;
  }
}
export const hasFastConnection = (): boolean => {
  const nav = navigator as CustomNavigator;
  const connection =
    nav.connection || nav.mozConnection || nav.webkitConnection;

  if (!connection) {
    return false;
  }
  return connection.effectiveType ? connection.effectiveType === '4g' : false;
};
