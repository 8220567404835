import { Grid, Pagination, Skeleton, Stack, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import { useGetCollectionItemsQuery } from '../../../features/collections/Collections.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { Animation } from '../../components/Animate/Animate';
import { AnimationEnum } from '../../components/Animate/Animate.constants';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { CardAlbum } from './CardAlbum';
import { CARD_SIZE, CONTAINER_SIZE } from './CardAlbum.constants';
import { FutureCard } from './FutureCard';
import { useCollectionPagination } from './useCollectionPagination';

const SHOW_FUTURE_CARD = false;

interface CardsContainerProps {
  collectionId: string;
  loading: boolean;
}

export const CardsContainer = ({
  collectionId,
  loading,
}: CardsContainerProps) => {
  const isMobile = useMobileMediaQuery();
  const theme = useTheme();

  const { data, isLoading: isLoadingCollections } = useGetCollectionItemsQuery({
    collectionId,
  });
  const {
    pageAmount,
    currentPage,
    isPreviewVisible,
    handlePageChange,
    currentPageData,
    itemsPerPage,
  } = useCollectionPagination(data || []);

  return (
    <Stack
      alignItems="center"
      alignSelf="stretch"
      gap={SpaceSizes.lg}
      height="100%"
      justifyContent="space-between"
      minHeight={CONTAINER_SIZE}
      paddingY={SpaceSizes.lg}
      sx={{
        border: '2px solid #707070',
        borderRadius: SpaceSizes.xs,
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
      }}
      width="100%"
    >
      <Grid direction="row" rowGap={SpaceSizes.md} width="100%" container>
        {loading || isLoadingCollections ? (
          Array.from({ length: itemsPerPage }, (_, index) => (
            <Grid key={`${_}${index}`} md={3} sm={4} xs={6} item>
              <Stack direction="row" justifyContent="center">
                <Skeleton
                  style={{ marginTop: 0 }}
                  sx={{
                    height: CARD_SIZE.height,
                    width: CARD_SIZE.width,
                  }}
                  variant="rectangular"
                />
              </Stack>
            </Grid>
          ))
        ) : (
          <AnimatePresence mode="sync">
            {currentPageData?.map((card, index) => {
              return (
                <Grid key={`${card.product_id}-key`} md={3} sm={4} xs={6} item>
                  <Animation
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 0,
                      height: String(CARD_SIZE.height),
                      width: String(CARD_SIZE.width),
                    }}
                    timeProps={{ delay: index / 10 + 0.05, duration: 0.15 }}
                    type={AnimationEnum.Offset}
                  >
                    <CardAlbum
                      key={`${card.product_id}-card`}
                      available={card.available_goals}
                      match={card.match}
                      player={card.player}
                      productId={card.product_id}
                      title={card.ball_id}
                      userOwnsIt={card.is_owned}
                    />
                  </Animation>
                </Grid>
              );
            })}
            {SHOW_FUTURE_CARD && isPreviewVisible && (
              <Grid md={3} sm={4} xs={6} item>
                <Animation
                  key="future-card"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: 0,
                  }}
                  timeProps={{
                    delay: Number(currentPageData?.length) / 10 + 0.05,
                    duration: 0.25,
                  }}
                  type={AnimationEnum.Offset}
                >
                  <FutureCard />
                </Animation>
              </Grid>
            )}
          </AnimatePresence>
        )}
      </Grid>

      <Pagination
        count={pageAmount}
        page={currentPage}
        size={isMobile ? 'small' : 'medium'}
        sx={{
          paddingTop: SpaceSizes.md,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          ul: {
            display: 'flex',
            justifyContent: 'center',
          },
        }}
        onChange={handlePageChange}
      />
    </Stack>
  );
};
