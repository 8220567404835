import { Skeleton, Stack } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CollectionListItem } from '../../../features/collections/Collections.types';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { useCollectionByGroup } from '../collection-album/useCollectionByGroup';
import { CollectionCard } from './components/CollectionCard';
import {
  SKELETON_HEIGHT,
  SKELETON_WIDTH,
} from './components/CollectionCard.constants';

const CollectionsList = () => {
  const { t } = useTranslation();
  const { collections, isLoading, isError, errorCode, collectionGroupId } =
    useCollectionByGroup();
  const navigate = useNavigate();
  const onBackButtonClick = useCallback(() => {
    navigate(`/collections`);
  }, [navigate]);

  useEffect(() => {
    if (errorCode === 'NOT_FOUND') {
      navigate(`/collections`);
    }
  }, [navigate, errorCode]);

  return (
    <PageContainer
      {...(isError && {
        errorCode: `pages.collectionsList.errors.${errorCode}`,
      })}
      id="collectionsList"
      sx={{ minHeight: '70vh' }}
      title={t(`pages.collectionsList.pageTitle.${collectionGroupId}`)}
      backButton
      onBackButtonClick={onBackButtonClick}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        gap="20px"
        justifyContent="center"
        marginY="40px"
      >
        {!collections?.collectionByGroup || isLoading ? (
          <>
            {Array.from({ length: 12 }, (_, index) => (
              <Skeleton
                key={`${_}${index}`}
                sx={{
                  height: SKELETON_HEIGHT,
                  width: SKELETON_WIDTH,
                }}
                variant="rectangular"
              />
            ))}
          </>
        ) : (
          collections?.collectionByGroup?.map(
            (collection: CollectionListItem) => (
              <CollectionCard
                key={collection.collection_id}
                collection={collection}
                collectionGroup={collectionGroupId}
              />
            ),
          )
        )}
      </Stack>
    </PageContainer>
  );
};

export default CollectionsList;
