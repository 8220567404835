import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Items } from '../../app/components/CardsCart/CardsCart.interfaces';

export interface ShoppingCartState {
  cart: Items[];
  totalPrice: number;
}

const initialState = {
  totalPrice: 0,
  cart: [],
} as ShoppingCartState;

const shoppingCartSlice = createSlice({
  name: 'shopping-cart',
  initialState,
  reducers: {
    syncCart: (state, action: PayloadAction<Items[]>) => {
      state.cart = action.payload;

      state.totalPrice = state.cart
        .filter((item) => item.status === 'active')
        .reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.price.value * currentValue.selected_quantity,
          0,
        );
    },
    increaseQuantityProduct: (state, action: PayloadAction<Items>) => {
      const productCurrent = state.cart.find(
        (item) => item.product.id === action.payload.product.id,
      );
      const isMaxAllowedQuantity =
        productCurrent &&
        productCurrent?.selected_quantity <
          parseInt(action.payload.max_allowed_quantity, 10);

      if (isMaxAllowedQuantity) {
        productCurrent.selected_quantity += 1;
        state.totalPrice = state.cart
          .filter((item) => item.status === 'active')
          .reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.price.value * currentValue.selected_quantity,
            0,
          );
      }

      return state;
    },
    decreaseQuantityProduct: (state, action: PayloadAction<Items>) => {
      const productCurrent = state.cart.find(
        (item) => item.product.id === action.payload.product.id,
      );

      const isMinAllowedQuantity =
        productCurrent && productCurrent?.selected_quantity > 1;

      if (isMinAllowedQuantity) {
        productCurrent.selected_quantity -= 1;
        state.totalPrice = state.cart
          .filter((item) => item.status === 'active')
          .reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.price.value * currentValue.selected_quantity,
            0,
          );
      }

      return state;
    },
    removeProductCart: (state, action: PayloadAction<Items>) => {
      const removeProduct = state.cart.find(
        (item) => item.product.id === action.payload.product.id,
      );
      if (removeProduct) {
        removeProduct.status = 'deleted';
      }

      state.totalPrice = state.cart
        .filter((item) => item.status === 'active')
        .reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.price.value * currentValue.selected_quantity,
          0,
        );
    },
    removeProductCartRemoved: (state, action: PayloadAction<Items>) => {
      state.cart = state.cart.filter(
        (item) =>
          item.status !== 'deleted' &&
          item.product.id !== action.payload.product.id,
      );
    },
    restoreProductCart: (state, action: PayloadAction<Items>) => {
      const removedItem = state.cart.find(
        (item) =>
          item.product.id === action.payload.product.id &&
          item.status === 'deleted',
      );
      if (removedItem) {
        removedItem.status = 'active';
        state.totalPrice = state.cart
          .filter((item) => item.status === 'active')
          .reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.price.value * currentValue.selected_quantity,
            0,
          );
      }
    },
  },
});

export const {
  syncCart,
  increaseQuantityProduct,
  decreaseQuantityProduct,
  removeProductCart,
  removeProductCartRemoved,
  restoreProductCart,
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
