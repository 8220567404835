import 'react-phone-input-2/lib/style.css';

import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import { selectUserSession } from '../../../../features/session/Session.selector';
import { useAppSelector } from '../../../hooks';
import { DEFAULT_COUNTRY } from './CellphoneInput.constants';

interface Props {
  cellphone: string;
  isFocused: boolean;
  placeholder?: string;
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (arg0: string) => void;
  disabled?: boolean;
}

export const CellphoneInput = ({
  cellphone,
  isFocused,
  onSubmit,
  placeholder,
  onChange,
  disabled = false,
}: Props) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const user = useAppSelector(selectUserSession);
  const [countryId, setCountryId] = useState<string>(DEFAULT_COUNTRY);

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    switch (user?.country_id) {
      //  U.S. Virgin Islands Country, Code 1 340, Country Code VI must be added.
      case 'VI':
        setCountryId(DEFAULT_COUNTRY);
        break;

      default:
        setCountryId(user?.country_id || DEFAULT_COUNTRY);
        break;
    }
  }, [user]);

  return (
    <PhoneInput
      country={countryId.toLowerCase()}
      disabled={disabled}
      dropdownStyle={{ color: 'black' }}
      inputProps={{
        ref,
      }}
      placeholder={placeholder}
      value={cellphone}
      enableLongNumbers
      onChange={onChange}
      onEnterKeyPress={(e) =>
        onSubmit(e as unknown as React.ChangeEvent<HTMLInputElement>)
      }
    />
  );
};
