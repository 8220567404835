import { Close, HighlightOffOutlined, InfoOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { DIALOG_SIZE } from './DialogFeedback.constants';
import { IDialogFeedback } from './DialogFeedback.types';

export const DialogFeedback = ({
  dataCy,
  isOpen,
  isLoading,
  handleClose,
  title,
  icon,
  content,
}: IDialogFeedback) => {
  const theme = useTheme();
  return (
    <Dialog
      data-testid={dataCy}
      open={isOpen}
      PaperProps={{
        sx: {
          margin: SpaceSizes.md,
          width: DIALOG_SIZE.width,
          height: DIALOG_SIZE.height,
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      }}
      sx={{ backdropFilter: 'blur(4px)' }}
      onClose={handleClose}
    >
      <Close
        fontSize="large"
        sx={{
          color: '#7179A6',
          fontWeight: 400,
          position: 'absolute',
          top: SpaceSizes.md,
          right: SpaceSizes.md,
        }}
        onClick={handleClose}
      />
      <Stack
        alignItems="center"
        gap={SpaceSizes.sm}
        height="100%"
        justifyContent="center"
      >
        {icon === 'info' ? (
          <InfoOutlined
            sx={{
              color: '#B6C1D6',
              height: SpaceSizes.lg,
              width: SpaceSizes.lg,
            }}
          />
        ) : (
          <HighlightOffOutlined
            sx={{
              color: '#B6C1D6',
              height: SpaceSizes.lg,
              width: SpaceSizes.lg,
            }}
          />
        )}
        <Typography
          data-testid={title}
          fontWeight={600}
          textAlign="center"
          variant="cH4"
        >
          {title}
        </Typography>
        <Typography color="text.secondary" fontWeight={400} textAlign="center">
          {content}
        </Typography>
        {isLoading && <CircularProgress size={SpaceSizes.mdPlus} />}
      </Stack>
    </Dialog>
  );
};
