import { LoadingButton } from '@mui/lab';
import { Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ADD_SIZE_HEIGHT } from '../../components/CompactCard/CompactCard.constants';
import { ConfirmWarningModal } from '../../components/ConfirmWarningModal/ConfirmWarningModal';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { HorizontalSelector } from '../../components/HorizontalSelector/HorizontalSelector';
import { BUTTON_WIDTH } from '../../components/HorizontalSelector/HorizontalSelector.constants';
import { UserSummary } from '../../components/UserSummary/UserSummary';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { Offer } from './offer/Offer';
import { TradeOfferSummary } from './TradeOfferSummary';
import { useBuildOffer } from './useBuildOffer';
import { useTradeDetail } from './useTradeDetail';

export const TradeOfferBuilder = () => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');

  const {
    country,
    profilePic,
    userId,
    userName,
    offer,
    loadingDetail,
    refetchDetail,
    errorDetail,
  } = useTradeDetail();

  const {
    ownedCards,
    loadingOwned,
    isSending,
    isSendSuccessful,
    handleOffer,
    sendTrade,
    removeOffer,
    userOffer,
    availableSelection,
    sentPieces,
    enterWaypoint,
    isLastPage,
  } = useBuildOffer(refetchDetail);
  const [deliveryModalOpen, setDeliveryModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const cancelOffer = useCallback(() => {
    navigate('/marketplace?tab=trade');
  }, [navigate]);

  const disabledOffer = useMemo(() => {
    return sentPieces?.length === 0;
  }, [sentPieces]);

  return (
    <PageContainer
      id="tradesPage"
      {...(errorDetail && {
        errorCode: 'pages.product.piece.modal.errors.UNKNOWN_OFFER',
      })}
      title={t('pageTitle')}
      backButton
    >
      <Stack gap={SpaceSizes.md} width="100%">
        <Stack
          alignItems="start"
          gap={SpaceSizes.lg}
          my={SpaceSizes.lg}
          width="100%"
        >
          <UserSummary
            country={country}
            isLoading={loadingDetail}
            profilePic={profilePic}
            userId={userId}
            userName={userName}
            navigable
          />
          <Typography color="text.secondary">{t('warning')}</Typography>
          <Stack
            alignItems="stretch"
            flexDirection="row"
            gap={SpaceSizes.md}
            justifyContent="space-between"
            width="100%"
          >
            <Offer
              isLoading={loadingDetail}
              removeOffer={removeOffer}
              requestOffer={offer}
              sentOffer={userOffer}
              isBuild
            />
          </Stack>
        </Stack>
        {loadingOwned ? (
          <Stack gap={SpaceSizes.mdPlus} width="100%">
            <Skeleton
              sx={{
                height: {
                  xs: SpaceSizes.mdPlus,
                  sm: SpaceSizes.lgPlus,
                },
                width: {
                  xs: SpaceSizes.xlPlus,
                  sm: SpaceSizes.xxl,
                  md: SpaceSizes.xxl2,
                },
              }}
              variant="rectangular"
            />
            <Stack alignItems="center" gap={SpaceSizes.lgPlus} width="100%">
              <Skeleton
                sx={{ height: ADD_SIZE_HEIGHT }}
                variant="rectangular"
                width="100%"
              />
              <Skeleton
                sx={{
                  height: {
                    xs: SpaceSizes.mdPlus,
                    sm: SpaceSizes.lgPlus,
                  },
                  width: {
                    xs: SpaceSizes.xxl,
                    sm: SpaceSizes.xxl2,
                  },
                }}
                variant="rectangular"
              />
            </Stack>
          </Stack>
        ) : (
          ownedCards && (
            <HorizontalSelector
              availableSelection={availableSelection}
              data={ownedCards}
              disabled={userOffer?.length === 4}
              handleEnterWaypoint={enterWaypoint}
              handleOffer={handleOffer}
              isLastPage={isLastPage}
            />
          )
        )}
        {loadingDetail ? (
          <Skeleton
            height={SpaceSizes.xlPlus}
            variant="rectangular"
            width="100%"
          />
        ) : (
          <TradeOfferSummary offer={offer} sentPieces={sentPieces} />
        )}
        <Stack
          direction="row"
          gap={SpaceSizes.lgPlus}
          justifyContent="center"
          my={SpaceSizes.lg}
          width="100%"
        >
          <LoadingButton
            data-testid="trade-offer-cancel"
            disabled={isSending}
            sx={{ paddingY: SpaceSizes.sm, width: BUTTON_WIDTH }}
            variant="outlined"
            onClick={cancelOffer}
          >
            {t('cta.cancel.button')}
          </LoadingButton>
          <LoadingButton
            data-testid="trade-offer-send"
            disabled={
              isSendSuccessful || disabledOffer || loadingDetail || loadingOwned
            }
            loading={isSending}
            sx={{ paddingY: SpaceSizes.sm, width: BUTTON_WIDTH }}
            type="submit"
            variant="contained"
            onClick={() => setDeliveryModalOpen(true)}
          >
            {t('cta.submit.button')}
          </LoadingButton>
        </Stack>
      </Stack>
      <ConfirmWarningModal
        externalComponent={
          <TradeOfferSummary offer={offer} sentPieces={sentPieces} />
        }
        isLoading={isSending}
        isOpen={deliveryModalOpen}
        namespaces="pages.myTradeRequests.cta.submit.warning"
        onClose={() => setDeliveryModalOpen(false)}
        onSubmit={sendTrade}
      />
    </PageContainer>
  );
};
