import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NotificationStatus } from '../../../features/notifications/responses/Notification';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { StyledNotificationImage } from './Notification.styled';
import { NotificationProps } from './Notification.types';
import NotificationAlert from './NotificationAlert';
import { NotificationContent } from './NotificationContent';
import { NotificationDate } from './NotificationDate';
import { NotificationLayout } from './NotificationLayout';

const NotificationItem = ({ notification }: NotificationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const notificationType = notification.type || 'UNHANDLED';
  const notificationMessage = t(
    `header.userMenu.notifications.type.${notificationType}`,
  );

  return (
    <NotificationLayout>
      <Stack
        alignContent="center"
        direction="row"
        justifyContent="space-between"
        spacing={SpaceSizes.sm}
        {...(notification.link
          ? { onClick: () => navigate(notification.link) }
          : {})}
      >
        <Stack
          alignContent="center"
          direction="row"
          justifyContent="space-between"
          spacing={SpaceSizes.sm}
        >
          {notification.thumbnail && (
            <StyledNotificationImage src={notification.thumbnail} />
          )}

          <NotificationContent message={notificationMessage} />
        </Stack>
        {notification.status_code === NotificationStatus.SHOWN && (
          <NotificationAlert />
        )}
      </Stack>

      <Stack direction="row" justifyContent="flex-end">
        <NotificationDate
          createdDt={notification.created_dt}
          isShown={notification.status_code === NotificationStatus.SHOWN}
        />
      </Stack>
    </NotificationLayout>
  );
};

export default NotificationItem;
