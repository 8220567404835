import { useSearchMyCollectionQuery } from '../../../features/searchfilter/mycollection/MyCollectionFilter.api';
import {
  selectSearchMyCollectionIsSkipped,
  selectSearchMyCollectionPath,
} from '../../../features/searchfilter/mycollection/MyCollectionFilter.selector';
import { syncAppliedFilters } from '../../../features/searchfilter/mycollection/MyCollectionFilter.slice';
import { SearchResponse } from '../../../features/searchfilter/responses/SearchResponse';
import createUseDataWithInfiniteScroll from '../shared/createUseDataWithInfiniteScroll';

/**
 * Hook that let us reuse infinite scroll for search api
 * @returns
 */
const useSearchMyCollectionWithInfiniteScroll =
  createUseDataWithInfiniteScroll<SearchResponse>(
    useSearchMyCollectionQuery,
    syncAppliedFilters,
    selectSearchMyCollectionPath,
    selectSearchMyCollectionIsSkipped,
  );

export default useSearchMyCollectionWithInfiniteScroll;
