import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  STATIC_SIZE_HEIGHT,
  STATIC_SIZE_WIDTH,
} from '../../components/CompactCard/CompactCard.constants';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { TRADE_SKELETON_TEXT_WIDTH } from './CardTradeDetail.constants';

export const CardTradeDetailSkeleton = () => {
  const isDesktop = useDesktopDeviceMediaQuery();
  const isSmallestMobile = useSmallMobileMediaQuery();

  return (
    <Grid spacing={isDesktop ? SpaceSizes.xs : SpaceSizes.md} container>
      <Grid my={SpaceSizes.lg} xs={isDesktop ? 6 : 12} item>
        <Stack
          gap={SpaceSizes.md}
          height="100%"
          position="relative"
          width="100%"
        >
          <Stack gap={SpaceSizes.xl}>
            <Stack gap={SpaceSizes.md}>
              <Stack
                direction={isDesktop ? 'column' : 'row'}
                gap={isDesktop ? SpaceSizes.lgPlus : SpaceSizes.md}
              >
                <Skeleton
                  sx={{
                    height: STATIC_SIZE_HEIGHT,
                    width: STATIC_SIZE_WIDTH,
                  }}
                  variant="rectangular"
                />
                <Stack gap={SpaceSizes.xs} width="100%">
                  <Skeleton
                    height={SpaceSizes.lgPlus}
                    sx={{ borderRadius: SpaceSizes.xxs }}
                    variant="text"
                    width={TRADE_SKELETON_TEXT_WIDTH.md}
                  />
                  <Skeleton
                    height={SpaceSizes.lg}
                    sx={{ borderRadius: SpaceSizes.xxs }}
                    variant="text"
                    width={TRADE_SKELETON_TEXT_WIDTH.lg}
                  />
                  <Skeleton
                    height={SpaceSizes.lg}
                    sx={{ borderRadius: SpaceSizes.xxs }}
                    variant="text"
                    width={TRADE_SKELETON_TEXT_WIDTH.sm}
                  />
                  {isSmallestMobile && (
                    <Skeleton
                      height={SpaceSizes.lg}
                      sx={{ borderRadius: SpaceSizes.xxs }}
                      variant="text"
                      width="80%"
                    />
                  )}
                </Stack>
              </Stack>
              {!isSmallestMobile && (
                <Skeleton
                  height={SpaceSizes.lg}
                  sx={{ borderRadius: SpaceSizes.xxs }}
                  variant="text"
                  width="50%"
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid my={SpaceSizes.lg} xs={isDesktop ? 6 : 12} item>
        <Stack gap={SpaceSizes.sm}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton
              key={`${Date.now() + index}`}
              height={SpaceSizes.xlPlus}
              sx={{ borderRadius: SpaceSizes.xs }}
              variant="rectangular"
              width="100%"
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};
