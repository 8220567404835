import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import React, { useCallback, useEffect } from 'react';

import { CreateCheckoutApiError } from '../../../features/checkout/Checkout.types';
import { selectPrice } from '../../../features/products/Product.selectors';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { useGetItemToTrack } from '../../../utils/useGetItemToTrack';
import { useCreateCheckout } from '../../hooks/modules/Product/useCreateCheckout';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { CARD_PAYMENT_METHOD } from '../../pages/tiers/Tiers.constants';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import {
  BUTTON_FONT_SIZE_DESKTOP,
  BUTTON_FONT_SIZE_MOBILE,
} from './CheckoutWidget.constants';
import { ICheckoutWidgetProps } from './CheckoutWidget.types';

export const CheckoutWidget = ({
  showPrice = true,
  product,
  productId,
  price,
  validations,
  setErrorCode,
  setShowAlert,
  selectedQuantity,
}: ICheckoutWidgetProps) => {
  const {
    handleCreateCheckout,
    createCheckoutIsLoading,
    createCheckoutIsUninitialized,
    createCheckoutError,
  } = useCreateCheckout({
    price,
    product: { productId },
    validations,
    selectedQuantity,
  });
  const sendDataToGTM = useGTMDispatch();
  const productPrice = selectPrice(product);
  const itemToTrack = useGetItemToTrack(product, 0);
  const isMobile = useMobileMediaQuery();

  const createCheckout = useCallback(() => {
    if (handleCreateCheckout) {
      handleCreateCheckout();
      sendDataToGTM({
        event: TAG_EVENT.BEGIN_CHECKOUT,
        ecommerce: {
          value: productPrice?.coins,
          currency: productPrice?.currency,
          payment_type: CARD_PAYMENT_METHOD,
          coupon: '',
          items: [
            {
              ...itemToTrack,
              quantity: selectedQuantity,
              price,
            },
          ],
        },
      });
    }
  }, [
    selectedQuantity,
    handleCreateCheckout,
    itemToTrack,
    productPrice,
    sendDataToGTM,
    price,
  ]);

  useEffect(() => {
    if (createCheckoutError) {
      const errorCode = createCheckoutError as CreateCheckoutApiError;
      setErrorCode({
        id: errorCode.data.code ?? 'createCheckout',
        exist: true,
      });

      setShowAlert(true);
    }
  }, [setErrorCode, createCheckoutError, setShowAlert]);

  return (
    <PaymentButton
      currency={price.currency}
      dataTestId="checkoutWidgetButton"
      disableButton={createCheckoutIsLoading && !createCheckoutIsUninitialized}
      handleBuy={createCheckout}
      id="buy"
      isLoading={createCheckoutIsLoading}
      price={price.priceValue}
      showPrice={showPrice}
      size={{
        fontSize: isMobile ? BUTTON_FONT_SIZE_MOBILE : BUTTON_FONT_SIZE_DESKTOP,
      }}
      sx={{
        width: '100%',
        borderRadius: SpaceSizes.lg,
        paddingX: isMobile ? SpaceSizes.xs : SpaceSizes.sm,
        paddingY: isMobile ? SpaceSizes.sm : SpaceSizes.smPlus,
        textTransform: 'none',
      }}
    />
  );
};
