import { Box, Stack } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React, { useCallback } from 'react';

import env from '../../../../env/env';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import useTabletMediaQuery from '../../../hooks/useTabletMediaQuery';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import { ClubIcon } from '../ClubIcon/ClubIcon';
import { Nickname } from '../Nickname/Nickname';
import { flexDirectionT } from '../Nickname/Nickname.type';
import { PropsPresentation } from './Presentation.types';

const anonymousUserUrl = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/anonymous-user.png`;
const fallbackMobile = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/banner/golball_mobile.jpeg`;
const fallbackDesktop = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/banner/golball.jpg`;

export const Presentation = ({ user, team }: PropsPresentation) => {
  const isSmallestMobile = useSmallMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const sizeAvatar = useCallback(() => {
    if (isSmallestMobile) return '50px';
    if (isTablet) return '100px';
    return '150px';
  }, [isSmallestMobile, isTablet]);
  const { team_name: teamName, banner, icon } = team;

  const styleProps = {
    flexDirection: 'row' as flexDirectionT,
    fontWeight: '600',
    height: isSmallestMobile ? SpaceSizes.mdPlus : SpaceSizes.lgPlus,
    width: isSmallestMobile ? SpaceSizes.mdPlus : SpaceSizes.lgPlus,
    variant: (isSmallestMobile ? 'body2' : 'h5') as Variant,
  };

  const userProps = {
    countryId: user.country_id,
    isPublic: user.is_public,
    profileUrl: user.image_url,
    userName: user.user_name,
  };

  return (
    <Box width="100%">
      <Box style={{ position: 'relative' }}>
        <ImageFallback
          fallback={isSmallestMobile ? fallbackMobile : fallbackDesktop}
          height="130px"
          src={banner}
          style={{
            objectFit: 'cover',
          }}
          width="100%"
        />
      </Box>
      <Box
        style={{
          position: 'relative',
          top: '50%',
          left: '53%',
          transform: 'translate(-50%, -50%)',
          marginBottom: '-5%',
        }}
      >
        <ImageFallback
          fallback={user.image_url || anonymousUserUrl}
          height="100%"
          src={user.image_url || anonymousUserUrl}
          style={{ borderRadius: '100%', border: '2px solid white' }}
          width={sizeAvatar()}
        />
        <Stack alignItems="center" direction="row" gap={SpaceSizes.sm}>
          <Nickname styleProps={styleProps} userProps={userProps} />
          <ClubIcon
            height={isSmallestMobile ? SpaceSizes.mdPlus : SpaceSizes.lgPlus}
            icon={icon}
            teamName={teamName}
            width={isSmallestMobile ? SpaceSizes.mdPlus : SpaceSizes.lgPlus}
          />
        </Stack>
      </Box>
    </Box>
  );
};
