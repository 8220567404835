import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AppliedFilter,
  Filter,
} from '../../../../features/searchfilter/responses/SearchResponse';
import { Filters } from '../Filters/Filters';
import { SearchSectionOptions } from '../SearchSection/SearchSection.types';

interface FilterProps {
  isShowModalFilter: boolean;
  handleClose: () => void;
  filters: Filter[];
  appliedFilters: AppliedFilter[];
  options?: SearchSectionOptions;
  addFilter: (filter: AppliedFilter) => void;
  removeFilter: (filterId: string) => void;
}

export const SearchFilterModal = ({
  isShowModalFilter,
  handleClose,
  filters,
  appliedFilters,
  options,
  addFilter,
  removeFilter,
}: FilterProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isShowModalFilter} fullWidth onClose={handleClose}>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            {t('pages.marketplace.filters.title')}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Filters
        addFilter={addFilter}
        appliedFilters={appliedFilters}
        filters={filters}
        options={options}
        removeFilter={removeFilter}
        onFilterAdded={handleClose}
      />
    </Dialog>
  );
};
