import React from 'react';
import { useParams } from 'react-router-dom';

import { selectProduct } from '../../../features/products/Product.selectors';
import { useGetProductByBallIdQuery } from '../../../features/products/Products.api';
import { Certificate } from '../../components/Certificate/Certificate';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';

export const QRCertificate = () => {
  const { ballId } = useParams();

  const { product, isLoading, isError, isUninitialized } =
    useGetProductByBallIdQuery(
      { ballId: ballId ?? '', context: 'qr' },
      {
        selectFromResult: ({ data, ...rest }) => ({
          ...rest,
          product: selectProduct(data),
        }),
      },
    );

  return (
    <PageContainer
      {...(isError && {
        errorCode: 'pages.product.piece.modal.errors.standard',
      })}
      id="certificate-qr"
      maxWidth="md"
    >
      {product && (
        <Certificate
          isLoading={isLoading || isUninitialized}
          product={product}
          qrPage
        />
      )}
    </PageContainer>
  );
};
