import { Checkbox, Stack, Typography } from '@mui/material';
import { isNotEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { selectShouldTrackSignup } from '../../../../features/session/Session.selector';
import {
  setOnboardingHasSeen,
  setShouldTrackSignup,
} from '../../../../features/session/Session.slice';
import { useCompleteOnboardingMutation } from '../../../../features/users/Users.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { pixelTracks } from '../../../../utils/tagger';
import { AlertCard } from '../../../components/AlertCard/AlertCard';
import { PageContainer } from '../../../components/containers/PageContainer/PageContainer';
import { GolButton } from '../../../components/GolButton/GolButton.component';
import { Link } from '../../../components/Link/Link';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import CountrySelector from '../fans-preferences/GridSelectives/CountrySelector';

const Onboarding = () => {
  const dispatch = useAppDispatch();
  const [errorCode, setErrorCode] = useState('');
  const [checkedCountry, setCheckedCountry] = useState(false);
  const [checkedinstruction, setCheckedInstruction] = useState(false);
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const [onboardingHasSeen] = useCompleteOnboardingMutation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const redirect = params.get('redirect') || undefined;

  const itemsChecked = checkedCountry && checkedinstruction;

  const { t } = useTranslation();

  const shouldTrackSignup = useAppSelector(selectShouldTrackSignup);

  useEffect(() => {
    if (shouldTrackSignup) {
      pixelTracks();
      dispatch(setShouldTrackSignup(false));
    }
  }, [dispatch, shouldTrackSignup]);

  const saveAnswer = async () => {
    try {
      refSubmitButtom?.current?.click();
      await onboardingHasSeen({});
      dispatch(setOnboardingHasSeen(1));
      navigate(redirect ?? '/marketplace');
    } catch (error) {
      setErrorCode('defaultError');
    }
  };

  return (
    <PageContainer data-testid="onboarding" id="onboarding" maxWidth="xs">
      <Stack justifyContent="center" spacing={SpaceSizes.lg}>
        <CountrySelector
          refSubmitButtom={refSubmitButtom}
          setCheckedCountry={setCheckedCountry}
        />
        <Stack direction="column">
          <Stack alignItems="center" direction="row">
            <Checkbox
              checked={checkedinstruction}
              data-testid="onboardingTerm"
              sx={{
                paddingLeft: SpaceSizes.none,
              }}
              onChange={() => setCheckedInstruction((prevState) => !prevState)}
            />
            <Typography color="text.secondary" variant="caption">
              {`${t('pages.signup.agreeText')} `}
              <Link color="secondary.main" to={t('footer.legal.tycLink')}>
                {t('footer.legal.tyc')}
              </Link>
              {` ${t('pages.signup.agreeTextConector')} `}
              <Link color="secondary.main" to={t('footer.legal.polityLink')}>
                {t('footer.legal.polity')}
              </Link>
            </Typography>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          flexDirection="row"
          gap={SpaceSizes.none}
          justifyContent="center"
          width="100%"
        >
          <GolButton
            disabled={!itemsChecked}
            size="large"
            testId="onboardingFinishButton"
            value={t('pages.onboarding.finishAction')}
            variant="primary"
            fullWidth
            onClick={() => itemsChecked && saveAnswer()}
          />
        </Stack>
      </Stack>
      {isNotNilOrEmpty(errorCode) && (
        <AlertCard
          content={t(`pages.onboarding.errors.${errorCode}`)}
          id="onBoardingAlert"
          show={isNotEmpty(errorCode)}
        />
      )}
    </PageContainer>
  );
};

export default Onboarding;
