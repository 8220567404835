import {
  Avatar,
  FormControl,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { CheckmarksProps } from './Checkmarks.types';

export const Checkmarks = ({
  label,
  id,
  placeholder,
  options,
  required = true,
  initialValues,
  disabled,
  onChange,
}: CheckmarksProps) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const hasError = errors[id];

  useEffect(() => {
    if (initialValues) {
      setValue(
        id,
        initialValues.map((item) => item.id),
      );
    }
  }, [initialValues, setValue, id]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedIds = event.target.value as string[];
    const selectedItems = options.filter((option) =>
      selectedIds.includes(option.id),
    );

    if (onChange) onChange(selectedItems);
  };

  return (
    <Stack width="100%">
      {label && <Typography fontWeight={600}>{label}</Typography>}

      <Controller
        control={control}
        defaultValue={initialValues?.map((item) => item.id) || []}
        name={id}
        render={({ field }) => (
          <FormControl>
            <Select
              {...register(id, {
                required,
              })}
              error={isNotNilOrEmpty(hasError)}
              id={id}
              input={<OutlinedInput label={label} />}
              inputProps={{ 'data-testid': id }}
              labelId="demo-multiple-checkbox-label"
              {...field}
              disabled={disabled}
              renderValue={(selected) => {
                const selectedInThisLeague = options.filter((opt) =>
                  (selected as string[]).includes(opt.id),
                );

                if (selectedInThisLeague.length === 0) {
                  return <em>{placeholder}</em>;
                }

                return selectedInThisLeague.map((opt) => opt.name).join(', ');
              }}
              required={required}
              displayEmpty
              multiple
              onBlur={() => {
                field.onBlur();
              }}
              onChange={(event) => {
                handleChange(event);
                field.onChange(event);
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox
                    checked={(field.value as string[]).includes(option.id)}
                  />
                  <Stack
                    alignItems="center"
                    flexDirection="row"
                    gap={SpaceSizes.xs}
                  >
                    {option.icon && (
                      <Avatar
                        alt={option.name}
                        src={option.icon}
                        sx={{ width: 24, height: 24 }}
                      />
                    )}
                    <ListItemText primary={option.name} />
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Stack>
  );
};
