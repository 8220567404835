import './App.css';

import { useGrowthBook } from '@growthbook/growthbook-react';
import { AppBar, Box, Dialog, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
// eslint-disable-next-line sort-imports
import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Animation } from './app/components/Animate/Animate';
import { AnimationEnum } from './app/components/Animate/Animate.constants';
//
import { AppBannerRedirect } from './app/components/AppBanner/AppBannerRedirect';
import { PageContainer } from './app/components/containers/PageContainer/PageContainer';
import { CookiesDisabled as CookiesDisabledWarning } from './app/components/CookiesDisabled/CookiesDisabled';
import Footer from './app/components/Footer/Footer';
import Header from './app/components/header/Header';
import { Maintenance } from './app/components/Maintenance/Maintenance';
import { PageError } from './app/components/PageError/PageError';
import { Teams } from './app/components/profile/Teams/Teams';
import { SnackbarCookies } from './app/components/SnackbarCookies/SnackbarCookies';
import Spinner from './app/components/Spinner';
import TriviaLanding from './app/components/Trivia/TriviaLanding';
import { useAppSelector } from './app/hooks';
import { useBrand } from './app/hooks/useBrand';
import useIntercom from './app/hooks/useIntercom';
import useMobileMediaQuery from './app/hooks/useMobileMediaQuery';
import useSentryUser from './app/hooks/useSentryUser';
import useSession from './app/hooks/useSession';
import useTabletMediaQuery from './app/hooks/useTabletMediaQuery';
import AccountActivation from './app/pages/account-activation/AccountActivation';
import AccountRecovery from './app/pages/account-recovery/AccountRecovery';
import AccountRecoveryNewPassword from './app/pages/account-recovery-new-password/AccountRecoveryNewPassword';
import Auction from './app/pages/auction/Auction';
import AuctionSuccess from './app/pages/auction-success/AuctionSuccess';
import { CardTradeDetail } from './app/pages/card-trade-detail/CardTradeDetail';
import { Cart } from './app/pages/cart/Cart';
import ChangePassword from './app/pages/change-password/ChangePassword';
import { CollectionDetails } from './app/pages/collection-details/CollectionDetails';
import Configuration from './app/pages/configuration/Configuration';
import ConfirmationCart from './app/pages/confirmation-cart/ConfirmationCart';
import { ExternalContentPage } from './app/pages/external-content-page/ExternalContentPage';
import Login from './app/pages/login/Login';
import { Movements } from './app/pages/movements/Movements';
import MyAuctions from './app/pages/my-auctions/MyAuctions';
import Onboarding from './app/pages/onboarding-post-activation/onboarding/Onboarding';
import PaymentSuccessCheckout from './app/pages/payment-success/checkout/PaymentSuccessCheckout';
import { QRCertificate } from './app/pages/qr/QRCertificate';
import { RedirectManager } from './app/pages/redirect-manager/RedirectManager';
import { Selfie } from './app/pages/selfie/Selfie';
import Signup from './app/pages/signup/Signup';
import { SignupEmail } from './app/pages/signup-email/SignupEmail';
import SmsVerification from './app/pages/sms-verification/SmsVerification';
import { TradeOfferBuilder } from './app/pages/trade-offer-builder/TradeOfferBuilder';
import { TradeOfferResult } from './app/pages/trade-offer-builder/TradeOfferResult';
import { TradeOfferDetails } from './app/pages/trade-offer-details/TradeOfferDetails';
import Trivia from './app/pages/trivia/Trivia';
import { useCreateCookieQuery } from './features/referrals/Referrals.api';
import { selectIsAuthSection } from './features/router/router';
import {
  selectIsSessionLoading,
  selectIsUserLoggedIn,
  selectUserHasSeenOnboarding,
  selectUserId,
} from './features/session/Session.selector';
import { getReferralCookie, setReferralCookie } from './utils/cookies';
import { MAINTENANCE, WEB3_FEATURES } from './utils/growthbookFeatures';
import { getStorage } from './utils/storage';

const Home = lazy(() => import('./app/pages/home/Home'));
const Marketplace = lazy(() => import('./app/pages/marketplace/Marketplace'));
const Matches = lazy(() => import('./app/pages/matches/Matches'));
const Match = lazy(() => import('./app/pages/match/Match'));
const Rank = lazy(() => import('./app/pages/rank/Rank'));
const Product = lazy(() => import('./app/pages/product/Product'));
const Profile = lazy(() => import('./app/pages/profile/Profile'));
const MyTradeRequests = lazy(
  () => import('./app/pages/my-trade-requests/MyTradeRequests'),
);
const Notifications = lazy(
  () => import('./app/pages/notifications/Notifications'),
);
const Referral = lazy(() => import('./app/pages/referrals/Referrals'));
const Tiers = lazy(() => import('./app/pages/tiers/Tiers'));
const Checkout = lazy(() => import('./app/pages/checkout/Checkout'));
const Bookmarks = lazy(() => import('./app/pages/bookmarks/Bookmarks'));
const Collections = lazy(() => import('./app/pages/collections/Collections'));
const CollectionsList = lazy(
  () => import('./app/pages/collections/CollectionsList'),
);
const CollectionAlbum = lazy(
  () => import('./app/pages/collection-album/CollectionAlbum'),
);

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search,
        }}
      />
    );
  }
  return null;
};

/**
 * Return the element used within a <Route element="" />
 */
const PrivateRouteElement = ({
  isLoggedUser,
  element,
}: {
  isLoggedUser: boolean;
  element: JSX.Element;
}) => {
  return isLoggedUser ? element : <Navigate to="/signup" />;
};

const AllRoutes = (
  isLoggedUser: boolean,
  hasSeenOnboarding: boolean | undefined,
  isMaintenanceMode: boolean | undefined,
  isWeb3On: boolean | undefined,
) => {
  return (
    <>
      <RemoveTrailingSlash />
      <Suspense fallback={<Spinner dark={false} />}>
        <Routes>
          {/* LogIn paths */}
          <Route element={<Login />} path="/login" />
          <Route element={<Signup />} path="/signup" />
          <Route element={<SignupEmail />} path="/signup-email" />
          <Route
            element={
              isLoggedUser ? (
                <Navigate to="/marketplace" />
              ) : (
                <AccountActivation />
              )
            }
            path="/account-activation"
          />
          <Route
            element={
              isLoggedUser ? (
                <Navigate to="/marketplace" />
              ) : (
                <AccountRecovery />
              )
            }
            path="/account-recovery"
          />
          <Route
            element={
              isLoggedUser ? (
                <Navigate to="/marketplace" />
              ) : (
                <AccountRecoveryNewPassword />
              )
            }
            path="/account-recovery/:recoveryId"
          />
          {/* Public paths */}
          {!isMaintenanceMode && (
            <Route element={<Maintenance />} path="/maintenance" />
          )}
          <Route element={<ExternalContentPage />} path="/pages/:keyId" />
          <Route element={<Matches />} path="/matches" />
          <Route element={<Rank />} path="/rank" />
          <Route element={<Match />} path="/matches/:matchId" />
          {isWeb3On && (
            <Route element={<CardTradeDetail />} path="/marketplace/:id" />
          )}
          <Route element={<QRCertificate />} path="/qr/:ballId" />
          <Route element={<Home />} path="/home" />
          <Route element={<Product />} path="/product/:productId/*" />
          <Route element={<Auction />} path="/product/auction/:productId" />
          <Route
            element={<AuctionSuccess />}
            path="/product/auction/:productId/success"
          />
          <Route element={<MyAuctions />} path="/auctions" />
          <Route element={<Marketplace />} path="/marketplace" />
          <Route element={<RedirectManager />} path="/live/:leagueId" />
          <Route element={<Profile />} path="/user/:userId" />
          {/* Only logged out paths */}
          {!isLoggedUser && (
            <Route
              element={<ExternalContentPage />}
              path="/collections/:keyId"
            />
          )}
          {/* Private paths */}
          <Route
            element={
              <PrivateRouteElement
                element={<Cart />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/cart"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<ConfirmationCart />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/cart/confirmation/:productId"
          />
          <Route
            element={
              <PrivateRouteElement element={<Checkout />} isLoggedUser />
            }
            path="/cart/checkout"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<SmsVerification />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/sms-verification"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Configuration />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/configuration"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Teams />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/configuration/teams"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Movements />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/movements"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Referral />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/referrals"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Tiers />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/tiers"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Notifications />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/notifications"
          />
          <Route
            element={
              <PrivateRouteElement
                element={
                  !hasSeenOnboarding ? (
                    <Onboarding />
                  ) : (
                    <Navigate to="/marketplace" />
                  )
                }
                isLoggedUser={isLoggedUser}
              />
            }
            path="/onboarding"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<ChangePassword />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/change-password"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Bookmarks />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/favs"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Checkout />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/checkout/:checkoutId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<MyTradeRequests />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/trades"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<TradeOfferDetails />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/trades/offer/:offerId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<TradeOfferBuilder />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/trades/offer/new/:txId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<TradeOfferResult />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/trades/offer/result/:status"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<PaymentSuccessCheckout />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/payment/success/:checkoutId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Profile />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/user/:userId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Collections />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/collections"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<CollectionAlbum />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/collections/:collectionGroupId/:collectionId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<CollectionDetails />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/my-collection/:productId/*"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<Selfie />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/selfie/:productId"
          />
          <Route
            element={
              <PrivateRouteElement
                element={<CollectionsList />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/collections/:collectionGroupId"
          />
          <Route element={<TriviaLanding />} path="/trivial" />
          <Route
            element={
              <PrivateRouteElement
                element={<Trivia />}
                isLoggedUser={isLoggedUser}
              />
            }
            path="/trivia/:battleId"
          />
          <Route element={<Navigate to="/home" replace />} path="*" />
          <Route
            element={<PageError errorCode="error.404NOTFOUND" />}
            path="*"
          />
        </Routes>
      </Suspense>
    </>
  );
};

const Background = ({ children }: { children?: React.ReactNode }) => (
  <Box data-testid="layout">{children || null}</Box>
);

const App = () => {
  const isSessionLoading = useAppSelector(selectIsSessionLoading);
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const userId = useAppSelector(selectUserId);
  const hasSeenOnboarding = useAppSelector(selectUserHasSeenOnboarding);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const referralCodeCookie = getReferralCookie();
  const navigate = useNavigate();
  const growthbook = useGrowthBook();
  const isMaintenanceMode = growthbook?.isOn(MAINTENANCE);
  const isWeb3On = growthbook?.isOn(WEB3_FEATURES);
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  useSession();
  useBrand();
  useIntercom();
  useSentryUser();

  const isHiddenFooter = useMemo(
    () => selectIsAuthSection(location),
    [location],
  );

  const isNotOnboarding = useMemo(
    () => location.pathname !== '/onboarding',
    [location.pathname],
  );

  const redirect = searchParams.get('redirect') || undefined;

  const onboardingRoute = useMemo(() => {
    return `/onboarding${redirect ? `?redirect=${redirect}` : ''}`;
  }, [redirect]);

  const onboardingIsNeeded = useMemo(
    () => isLoggedUser && !hasSeenOnboarding && isNotOnboarding,
    [isLoggedUser, hasSeenOnboarding, isNotOnboarding],
  );

  const memoizedRoutes = useMemo(() => {
    if (isSessionLoading) {
      return <PageContainer id="loadingPage" maxWidth="sm" loading />;
    }
    if (isMaintenanceMode) {
      return <Maintenance />;
    }
    return AllRoutes(
      isLoggedUser,
      hasSeenOnboarding,
      isMaintenanceMode,
      isWeb3On,
    );
  }, [
    isSessionLoading,
    isMaintenanceMode,
    isLoggedUser,
    hasSeenOnboarding,
    isWeb3On,
  ]);

  useEffect(() => {
    if (userId && userId !== growthbook?.getAttributes().userId)
      growthbook?.setAttributeOverrides({ userId });
  }, [growthbook, userId]);

  useEffect(() => {
    if (isLoggedUser && onboardingIsNeeded) {
      navigate(onboardingRoute);
    }
  }, [isLoggedUser, navigate, onboardingIsNeeded, onboardingRoute]);

  useEffect(() => {
    if (isMaintenanceMode) {
      navigate('/maintenance');
    }
  }, [isMaintenanceMode, navigate]);

  // Referral Program
  if (navigator.cookieEnabled) {
    // if there's a param try to set the new cookie
    const referralCode = searchParams.get('ref') || undefined;
    if (referralCode && !getReferralCookie()) {
      setReferralCookie(referralCode);
    }
  }

  useCreateCookieQuery(
    isLoggedUser && referralCodeCookie ? referralCodeCookie : skipToken,
  );

  return navigator.cookieEnabled ? (
    <Background>
      {(isMobile || isTablet) && (
        <Animation
          key="app-banner-animation"
          timeProps={{ duration: 0.85 }}
          transformProps={{ offsetX: '-15%' }}
          type={AnimationEnum.Offset}
        >
          <Box width="100vw">
            <AppBannerRedirect />
          </Box>
        </Animation>
      )}

      {isNotOnboarding && (
        <AppBar
          data-testid="header"
          position="relative"
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          <Header data-testid="header" />
        </AppBar>
      )}
      <Box
        sx={{
          minHeight: '65vh',
        }}
      >
        <Stack data-testid="content">{memoizedRoutes}</Stack>
      </Box>
      {!isHiddenFooter && <Footer />}
      <SnackbarCookies />
    </Background>
  ) : (
    <Dialog open={!getStorage()}>
      <CookiesDisabledWarning />
    </Dialog>
  );
};

export default App;
