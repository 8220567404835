import { Button, Divider, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import {
  sessionApiExtractErrors,
  useAccountRecoveryResetPasswordMutation,
} from '../../../features/session/Session.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { isValidEmail } from '../../../utils/validation';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input/Input';
import { InputPassword } from '../../components/form/InputPassword/InputPassword';
import { GolButton } from '../../components/GolButton/GolButton.component';
import useRedirectLoginSignup from '../../hooks/useRedirectLoginSignup';

type Inputs = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const AccountRecoveryNewPassword = () => {
  const [errorCode, setErrorCode] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [setNewPassword, { isSuccess, isLoading }] =
    useAccountRecoveryResetPasswordMutation();
  // Get recovery id from url
  const { recoveryId } = useParams();
  const methods = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (recoveryId) {
      try {
        await setNewPassword({
          email: data.email,
          password: data.password,
          recoveryId,
        }).unwrap();
      } catch (error) {
        setErrorCode(sessionApiExtractErrors(error));
      }
    } else {
      navigate('/account-recovery', { replace: true });
    }
  };

  const { loginRoute } = useRedirectLoginSignup();

  return (
    <PageContainer id="accountRecoveryPage" maxWidth="xs">
      <AlertCard
        content={
          <Stack spacing={SpaceSizes.sm}>
            <Typography variant="body1">
              {t(`pages.accountRecoveryNewPassword.isSuccess.message.line1`)}
            </Typography>
            <Typography variant="body1">
              {t(`pages.accountRecoveryNewPassword.isSuccess.message.line2`)}{' '}
              <Button component={RouterLink} size="small" to={loginRoute}>
                {t(`pages.accountRecoveryNewPassword.isSuccess.action`)}
              </Button>
            </Typography>
          </Stack>
        }
        id="accountRecoveryNewPasswordSuccess"
        severity={AlertSeverity.SUCCESS}
        show={isSuccess}
      />
      {!isSuccess && (
        <Form methods={methods} onSubmit={onSubmit}>
          <Stack
            justifyContent="center"
            spacing={SpaceSizes.mdPlus}
            width="100%"
          >
            <Stack alignSelf="start">
              <Typography fontWeight="bold" variant="h5">
                {t(`pages.accountRecoveryNewPassword.title`)}
              </Typography>
            </Stack>
            <Stack alignSelf="start">
              <Typography align="center" textAlign="start">
                {t(`pages.accountRecoveryNewPassword.subtitle`)}
              </Typography>
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Input
              errorMessage={t(
                'pages.accountRecoveryNewPassword.errors.mailError',
              )}
              id="email"
              label={t(`pages.accountRecoveryNewPassword.email`)}
              validate={() => isValidEmail(methods.watch('email'))}
            />
            <InputPassword
              id="password"
              label={t(`pages.accountRecoveryNewPassword.passwordLabel`)}
              placeholder={t(
                `pages.accountRecoveryNewPassword.passwordPlaceholder`,
              )}
            />
            <InputPassword
              id="passwordConfirmation"
              label={t(
                `pages.accountRecoveryNewPassword.passwordConfirmationLabel`,
              )}
              match={methods.watch('password')}
              placeholder={t(
                `pages.accountRecoveryNewPassword.passwordConfirmationPlaceholder`,
              )}
            />
            {isNotNilOrEmpty(errorCode) && (
              <AlertCard
                content={t(
                  `pages.accountRecoveryNewPassword.errors.${errorCode}`,
                )}
                id="accountRecoveryNewPasswordError"
                show={isNotNilOrEmpty(errorCode)}
              />
            )}
            <GolButton
              data-testid="accountRecoveryNewPasswordButton"
              disabled={isNotNilOrEmpty(methods.formState.errors)}
              loading={isLoading && !isSuccess}
              type="submit"
              value={t(`pages.accountRecoveryNewPassword.actionButton`)}
              variant="primary"
            />
          </Stack>
        </Form>
      )}
    </PageContainer>
  );
};

export default AccountRecoveryNewPassword;
