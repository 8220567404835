import Env from '../../env/env';
import { api } from '../api/Api';
import { Notification, NotificationStatusType } from './responses/Notification';

export interface PatchNotificationType {
  notificationId: string;
  status: NotificationStatusType;
}

export const notificationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Notifications
    getNotifications: builder.query<Notification[], void>({
      query: () => {
        return `${Env.REACT_APP_BASE_API_URL_NOTIFICATIONS}notifications`;
      },
      providesTags: ['Notifications'],
    }),

    patchNotification: builder.mutation<Notification, PatchNotificationType>({
      query: (payload) => ({
        url: `${Env.REACT_APP_BASE_API_URL_NOTIFICATIONS}notifications/${payload.notificationId}`,
        method: 'PATCH',
        body: { status: payload.status },
      }),
      async onQueryStarted(
        { notificationId, status },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData(
            'getNotifications',
            undefined,
            (notifications) => {
              const notiResult = notifications.find(
                (noti) => noti.id === notificationId,
              );
              if (notiResult) {
                notiResult.status_code = status;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),

  overrideExisting: true,
});

export const { useGetNotificationsQuery, usePatchNotificationMutation } =
  notificationsApi;
