import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatUTCtoLocalTime } from '../../../utils/date';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import i18n from '../../i18n';
import { NO_GOALS_IMAGE } from './Match.constants';

export const MatchNoGoals = ({
  matchId,
  status,
  date,
  time,
  isPresaleButtonActive,
  navigateToPresale,
}: {
  matchId: string;
  status: string;
  date: string | undefined;
  time: string | undefined;
  isPresaleButtonActive: boolean;
  navigateToPresale: () => void;
}) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const isTablet = useTabletMediaQuery();
  const navigate = useNavigate();

  const matchIsLive = useMemo(() => status === 'Playing', [status]);

  const matchNoEnded = useMemo(
    () => status === 'Fixture' || status === 'Playing',
    [status],
  );

  const fixedDate = useMemo(
    () => date && formatUTCtoLocalTime(language, date, time),
    [date, language, time],
  );

  return matchNoEnded === false ? (
    <ExternalContent
      filter={{
        key: 'content_id',
        value: 'finished_match',
      }}
      type="contents"
    />
  ) : (
    <ExternalContent
      {...(isPresaleButtonActive &&
        !isTablet && {
          downContent: (
            <Button
              fullWidth={false}
              sx={{
                backgroundColor: '#f5ce42',
                color: 'black',
                '&:hover': { backgroundColor: '#f5ce42', opacity: 0.9 },
              }}
              variant="contained"
              onClick={navigateToPresale}
            >
              {t('pages.presale.navigateToPresale')}
            </Button>
          ),
        })}
      defaultChildren={
        <Stack
          spacing={SpaceSizes.md}
          sx={{
            height: '500px',
            width: '100%',
            backgroundSize: ' 100% 100%',
            backgroundImage: `url("${NO_GOALS_IMAGE}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: SpaceSizes.sm,
          }}
        >
          {!matchIsLive && (
            <AccessTimeIcon sx={{ height: '70px', width: '70px' }} />
          )}
          <Typography
            fontWeight="bold"
            letterSpacing={0.5}
            p={SpaceSizes.md}
            textAlign="center"
            variant={isTablet ? 'h6' : 'h5'}
          >
            {matchNoEnded
              ? matchIsLive
                ? `${t('pages.match.liveMatch')} ${fixedDate} `
                : `${t('pages.match.notStartedMatch')} ${fixedDate} `
              : t('pages.match.endMatch')}
          </Typography>

          <Stack
            alignItems="center"
            direction="row"
            spacing={SpaceSizes.mdPlus}
          >
            {!isPresaleButtonActive ? (
              <Button
                variant="contained"
                onClick={() => navigate('/marketplace')}
              >
                {t('pages.match.to_marketplace')}
              </Button>
            ) : !isTablet ? (
              <Button
                fullWidth={false}
                sx={{
                  backgroundColor: '#f5ce42',
                  color: 'black',
                  '&:hover': { backgroundColor: '#f5ce42', opacity: 0.9 },
                }}
                variant="contained"
                onClick={navigateToPresale}
              >
                {t('pages.presale.navigateToPresale')}
              </Button>
            ) : null}
          </Stack>
        </Stack>
      }
      filter={{ key: 'page_id', value: 'match_stats' }}
      type="pages"
      vars={[{ id: 'match_id', content: matchId }]}
    />
  );
};
