import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const MovementsSkeleton = () => {
  return (
    <Stack>
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={125} />
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={20} />
      </Stack>
    </Stack>
  );
};
