import React from 'react';
import { useTranslation } from 'react-i18next';

import { Country } from '../../../../features/countries/responses/Country';
import { FormSelectAuto } from '../FormSelectAuto/FormSelectAuto';
import { CountrySelectProps } from './CountrySelect.types';

export const CountrySelect = ({
  countries,
  defaultValue,
  handleCountryChange,
  loading,
  defaultCountryToIp,
  sx,
}: CountrySelectProps) => {
  const { t } = useTranslation();
  return (
    <FormSelectAuto
      defaultValue={defaultValue || defaultCountryToIp}
      errorMessage={t('pages.profile.errors.countryError')}
      getOptionLabel={(option: Country) => option.name ?? ''}
      id="country_id"
      label={t(`pages.profile.formBasicData.country`)}
      loading={loading}
      noOptionsText={t(`pages.profile.formBasicData.countryNoOptions`)}
      options={countries}
      placeholder={t(`pages.profile.formBasicData.countryPlaceholder`)}
      sx={sx}
      onChange={(e, data: unknown) => {
        handleCountryChange(data as Country);
      }}
    />
  );
};
