import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const SMALL_FONT_SIZE = {
  xs: 8,
  md: 10,
  lg: 12,
};

export const LARGE_FONT_SIZE = {
  xs: 13,
  md: 15,
  lg: 18,
};

export const ALERT = {
  SIZE_MOBILE: '15px',
  SIZE_DESKTOP: '25px',
};

export const ALARM = {
  ICON: {
    xs: '12px',
    sm: '16px',
    md: '20px',
  },
  TYPOGRAPHY: {
    xs: 7,
    sm: 10,
    md: 14,
  },
};

export const IMAGE = {
  SIZE_HEIGHT_MOBILE: '120px',
  SIZE_HEIGHT_DESKTOP: '180px',
  SIZE_WIDTH_MOBILE: '100px',
  SIZE_WIDTH_DESKTOP: '160px',
};

export const transition = {
  type: 'spring',
  duration: 0.15,
  damping: 20,
  stiffness: 100,
};

export const removeRed = {
  hidden: {
    transition,
    opacity: 0,
    y: -10,
  },
  visible: { transition, opacity: 1, y: 0 },
  exit: {
    transition: {
      duration: 0.3,
    },
    opacity: 0,
    filter: 'grayscale(1)',
    y: -30,
  },
};
export const fadeInDown = {
  hidden: {
    transition,
    opacity: 0,
    y: -10,
  },
  visible: { transition, opacity: 1, y: 0 },
};

export const fadeOutUp = {
  exit: {
    transition: {
      duration: 0.15,
    },
    opacity: 0,
    y: 10,
  },
};

export const GOAL_INFO_WIDTH = {
  xs: SpaceSizes.xxl,
  sm: 200,
  md: 300,
};

export const HISTORY_MIN_HEIGHT = 90;
