import { useCallback, useState } from 'react';

interface useCarouselProps {
  length: number;
  start?: number;
}

const useCarousel = ({ length, start = 0 }: useCarouselProps) => {
  const [page, setPage] = useState<number>(start);
  const [last, setLast] = useState<number>(0);

  const getPage = useCallback(
    (step: number) => {
      if (page + step > length) return start;
      if (page + step < start) return length;
      return page + step;
    },
    [length, page, start],
  );

  // changes page N steps
  const step = useCallback(
    (direction: number) => {
      const p = getPage(direction);
      setLast(direction);
      setPage(p);
      return p;
    },
    [getPage],
  );

  // gets page without stepping
  const peek = useCallback(
    (direction: number) => {
      return getPage(direction);
    },
    [getPage],
  );

  const set = useCallback((number: number) => setPage(number), []);

  return { step, set, peek, page, last };
};

export default useCarousel;
export type { useCarouselProps };
