/* eslint-disable react/no-array-index-key */
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import './ComponentSwiper.css';

import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import { ComponentSwiperProps } from './ComponentSwiper.types';

export const ComponentSwiper = ({
  children,
  slidesPerView,
  spaceBetween,
  pagination,
}: ComponentSwiperProps) => {
  const isDesktop = useDesktopDeviceMediaQuery();
  const modules = [Navigation, ...(pagination ? [Pagination] : [])];
  return (
    <Swiper
      modules={modules}
      navigation={isDesktop}
      pagination={{
        dynamicBullets: true,
      }}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      rewind
    >
      {children}
    </Swiper>
  );
};
