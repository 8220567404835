import env from '../env/env';
import { getRegisteredCookie, setRegisteredCookie } from './cookies';

export const pixelTracks = () => {
  const isRegistered = getRegisteredCookie();
  if (!isRegistered) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'CompleteRegistration');
        window.fbq('track', 'ProspectsGolBall');
      }
      if (window.gtag != null) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-407729401/G4p2CLq8jo8YEPnptcIB',
          transaction_id: '',
        });
      }
    }
    setRegisteredCookie();
  }
};

export const gtmParams = {
  id: env.REACT_APP_GTM_ID || 'GTM-NX8BGDD',
  environment: {
    gtm_auth: env.REACT_APP_GTM_AUTH || '',
    gtm_preview: env.REACT_APP_GTM_PREVIEW || '',
  },
};

export const TAG_EVENT = {
  EMAIL_SINGUP_ERROR: 'email_signup_error',
  PWD_SIGNUP_ERROR: 'pwd_signup_error',
  CONFIRM_PWD_SIGNUP_ERROR: 'confirm_pwd_signup_error',
  AVATAR_SEEN: 'avatar_seen',
  SELECT_CONTENT: 'select_content',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_ITEM: 'view_item',
  SOCIAL_MEDIA: 'social_media',
  SELECT_ITEM: 'select_item',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  TRACK_CONTENT_GROUP: 'trackContentGroup',
  PAYMENT_SUCCESS: 'trackDataProduct',
  COPY_LINK: 'copy_link',
  SHARE_LINK: 'share_link',
  ONBOARDING: {
    LOGIN: 'login',
    SIGN_UP: 'sign_up',
    ACCOUNT_VALIDATED: 'account_validated',
    COUNTRY_SELECTION: 'country_selection',
    CLUB_SELECTION: 'club_selection',
    RECOVERY_LINK: 'sent_passwordRecovery_email',
  },
  FILTER: 'filter',
  CONTENT_GROUP: {
    MARKETPLACE: 'Listado',
    BUYS: 'Mis Gol-Ball',
    AUCTIONS: 'auctions',
    PROFILE: 'Mi Perfil',
    COLLECTIONS: 'Colecciones',
    MATCHES: 'Partidos',
    PAYMENT_SUCCESS: 'Thank you page',
    CHECKOUT: 'Checkout',
    PRODUCT: 'Ficha',
    LIST_ITEMS: 'Listado Market',
    HOME: 'Home',
    MY_PROFILE: 'Mis Gol-Ball',
    ANOTHER_PROFILE: 'Otro Perfil',
    REFERRALS: 'Referidos',
    TIERS: 'Tiers',
    CONFIRMATION_CART: 'Confirmacion De Carrito',
    APP: 'App',
  },
  NEW_HOME: 'new_home',
  APP_DOWNLOAD: 'app_download',
};

export const TAG_TYPE = {
  MENU: 'Menu',
  MENU_FOOTER: 'Menu footer',
  CTA_HOME: 'CTAs Home',
  PRODUCT_TABS: 'Botones ficha',
  CTA_FORGOT_PASSWORD: 'Modal de login',
  CTA_BANNER: 'Banner',
};

export const TAG_VALUE = {
  HEADER: {
    OPEN_SEARCH: 'openSearch',
    CLOSE_SEARCH: 'closeSearch',
    NOTIF_DROPDOWN: 'notifications_dropdown',
    OPEN_USER: 'open_user_dropdown',
    CLOSE_USER: 'close_user_dropdown',
    LANG_DROPDOWN: 'language_dropdown',
    OPEN_MENU: 'open_menu',
    FAVS_DROPDOWN: 'favs_dropdown',
    FAVS: 'favs',
    HOME: 'home',
    MARKETPLACE: 'marketplace',
    MEMBERSHIPS: 'memberships',
    MATCHES: 'matches',
    COLLECTIONS: 'collections',
    NOTIFICATIONS: 'notifications',
    MOVEMENTS: 'movements',
    TRADES: 'trades',
    TIERS: 'tiers',
    PROFILE: 'profile',
    RANK: 'rank',
    REFERRALS: 'referrals',
    BUYS: 'buys',
    AUCTIONS: 'auctions',
    CONFIGURATION: 'configuration',
    LOGIN: 'login',
    REGISTER: 'register',
    LOGO: 'logo',
    CART: 'cart',
  },
  FOOTER: {
    MARKETPLACE: 'marketplace',
    CORPORATIVE: 'golball-corporative',
    US: 'us',
    CONTACT: 'contact',
    LOGO: 'logo',
    COPY: 'copyright',
    TYC: 'tyc',
    POLITY: 'polity',
  },
  SOCIAL_MEDIA: {
    IG: 'instagram',
    TW: 'twitter',
    FB: 'facebook',
    TWITCH: 'twitch',
    DISCORD: 'discord',
    LINKEDIN: 'linkedin',
    TIKTOK: 'tiktok',
  },
  CTA_FORGOT_PASSWORD: 'CTA - Olvide mi contraseña',
  CTA_HOME: {
    NEXT_MATCHES: 'CTA Ver proximos partidos',
    START: 'CTA Comienza ahora',
  },
};
