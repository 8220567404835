import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Stack, Typography } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import {
  EPaymentTypes,
  IBuyMovement,
} from '../../../../../features/movements/Movements.types';
import SpaceSizes from '../../../../../theme/foundations/spacing/SpaceSizes';
import { Animation } from '../../../../components/Animate/Animate';
import { AnimationEnum } from '../../../../components/Animate/Animate.constants';
import ProductPrice from '../../../../components/ProductPrice/ProductPrice';
import { useTranslationByNamespaces } from '../../../../hooks/shared/useTranslationByNamespaces';
import { MovementContentItemTeams } from '../../items/MovementContentItemTeams';
import { extractHour } from '../../utils/Movement.utils';
import { EMovementType } from './BuyMovementsContent.enums';
import { BuyMovementsMessage } from './BuysMovementsContentMessage';

export const BuyMovementContent = (buy: IBuyMovement) => {
  const {
    date,
    price,
    price_currency_symbol: priceCurrencySymbol,
    gateway,
    product,
    gp_amount: gpAmount,
    bonus_amount: bonusAmount,
  } = buy;

  const {
    type,
    id,
    match_date: matchDate,
    scorer_last_name: scorerLastName,
    goal_minute: minute,
    event: {
      awarded_position: awardedPosition,
      home_code: homeCode,
      away_code: awayCode,
    },
  } = product;
  const isFreePrice =
    gateway === EPaymentTypes.COUPON_PRESALES || gateway === EPaymentTypes.FREE;
  const { t } = useTranslationByNamespaces('pages.movements');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <Stack
      key={id}
      gap={SpaceSizes.sm}
      sx={{ cursor: 'pointer' }}
      width="100%"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <Stack width="100%">
        <Stack direction="row" gap={SpaceSizes.sm} padding={SpaceSizes.sm}>
          <Stack width="100%">
            <Typography
              color="text.primary"
              component="p"
              fontWeight={600}
              variant="cBody1"
            >
              {t(
                `card.movementType.${
                  gateway === EPaymentTypes.COUPON_PRESALES
                    ? EPaymentTypes.COUPON_PRESALES
                    : type
                }`,
              )}
            </Typography>
            {type !== EMovementType.GOLCOIN && (
              <Typography color="text.primary" variant="cH5">
                {type === EMovementType.GOAL ? (
                  `${scorerLastName} ${minute}’`
                ) : (
                  <MovementContentItemTeams
                    awardedPosition={awardedPosition}
                    awayCode={awayCode}
                    homeCode={homeCode}
                  />
                )}
              </Typography>
            )}
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <BuyMovementsMessage
                matchDate={matchDate}
                teamsData={product.event}
                type={type}
              />
              {isExpanded ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <AnimatePresence>
        {isExpanded && (
          <Animation
            key={`${id}-an`}
            timeProps={{ delay: 0, duration: 0.25, ease: 'easeInOut' }}
            transformProps={{ offsetY: '-5%' }}
            type={AnimationEnum.Offset}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              padding={SpaceSizes.md}
              sx={{
                borderRadius: `0 0 ${SpaceSizes.sm}px ${SpaceSizes.sm}px `,
                backgroundColor: 'background.paper',
              }}
              width="100%"
            >
              <Stack direction="column">
                <Typography color="text.primary" variant="cH6">
                  {t(`card.payment.${gateway}`)}{' '}
                  {type === EMovementType.GOLCOIN
                    ? ` ${priceCurrencySymbol}${price}`
                    : ''}
                </Typography>
                {bonusAmount && (
                  <Typography color="text.primary" variant="cH6">
                    + {t(`card.payment.BONUS`)}&nbsp;
                    {bonusAmount} Gol-Points
                  </Typography>
                )}

                <Typography
                  color="text.primary"
                  component="p"
                  fontWeight={400}
                  variant="cBody1"
                >
                  {t('card.buyTime')}: {extractHour(date)}
                </Typography>
              </Stack>

              <Typography color="text.primary" fontWeight={800} variant="cH4">
                <ProductPrice
                  size="medium"
                  value={
                    isFreePrice
                      ? '0'
                      : String(
                          type === EMovementType.GOLCOIN
                            ? gpAmount + bonusAmount
                            : price || '0',
                        )
                  }
                  showLogo
                />
              </Typography>
            </Stack>
          </Animation>
        )}
      </AnimatePresence>
    </Stack>
  );
};
