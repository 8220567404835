import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const stackStyled = {
  minHeight: '65vh',
  justifyContent: 'center',
};

export const alertStyled = {
  padding: SpaceSizes.none,
  position: 'absolute',
  margin: '0 auto',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  left: '50%',
  top: '50%',
  width: { xs: '100%', sm: 'auto' },
};

export const formSignUpStyled = {
  display: 'flex',
  justifyContent: 'center',
};

export const checkboxStyled = {
  padding: SpaceSizes.none,
  color: 'checkBox.primary.border',
};

export const pageContainerStyled = {
  minHeight: '65vh',
  position: 'relative',
};
