import { Stack, Typography } from '@mui/material';
import React from 'react';

import { IProduct } from '../../../features/products/Product.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import PiecesIcon from '../../assets/img/pieces.svg';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { TITLE_FONT_SIZE } from './GolBallQuantity.constants';

export const GolBallQuantity = ({ product }: { product: IProduct }) => {
  const { t } = useTranslationByNamespaces('components');
  const hasMultiplePieces = product?.pieces && product?.pieces?.length > 1;

  return (
    <Stack>
      {hasMultiplePieces && (
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.xs}>
          <Typography fontSize={TITLE_FONT_SIZE} fontWeight="semibold">
            {t('golBallQuantity')}
          </Typography>
          <ImageFallback
            height={SpaceSizes.md}
            src={PiecesIcon}
            width={SpaceSizes.md}
          />
          <Typography fontSize={TITLE_FONT_SIZE}>
            x{product.pieces?.length}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
