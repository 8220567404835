import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import Countdown from 'react-countdown';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import {
  CountdownComponentProps,
  CountDownRenderProps,
} from '../Countdown/Countdown.types';
import CountDownItem from './CountDownItem';

const CustomDivider = () => (
  <Divider
    orientation="vertical"
    sx={{
      marginRight: { xs: '0px' },
      marginLeft: { xs: '0px' },
      margin: 'auto',
      height: { xs: SpaceSizes.lg },
    }}
    flexItem
  />
);

export const CountdownComponent = ({
  datetime,
  title,
  isSmall = false,
  textAlign = 'center',
}: CountdownComponentProps) => {
  const isMobile = useMobileMediaQuery();
  const { t } = useTranslationByNamespaces('pages');
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
  }: CountDownRenderProps) => {
    const datetimeLimit = [days, hours, minutes, seconds].every(
      (item) => item === 0,
    );

    if (datetimeLimit) return null;

    return (
      <Stack gap={isMobile ? SpaceSizes.xs : SpaceSizes.xs} width="100%">
        <Typography textAlign={textAlign} variant={isSmall ? 'body2' : 'body1'}>
          {title ?? t('product.trivia.countdown.startsIn')}
        </Typography>
        <Stack
          direction="row"
          gap={isMobile ? SpaceSizes.md : SpaceSizes.lg}
          justifyContent="center"
        >
          {days > 0 && (
            <>
              <CountDownItem
                isSmall={isSmall}
                label={t('comingSoon.count_days')}
                value={days}
              />
              <CustomDivider />
            </>
          )}
          {(days > 0 || hours > 0) && (
            <>
              <CountDownItem
                isSmall={isSmall}
                label={t('comingSoon.count_hours')}
                value={hours}
              />
              <CustomDivider />
            </>
          )}
          <CountDownItem
            isSmall={isSmall}
            label={t('comingSoon.count_min')}
            value={minutes}
          />
        </Stack>
      </Stack>
    );
  };

  return <Countdown date={datetime} renderer={renderer} />;
};
