import { createSelector } from '@reduxjs/toolkit';
import { compose, isEmpty, prop, sortBy, toLower } from 'ramda';

import { RootState } from '../../../app/store';
import { BrandConfig } from '../../../brands/brandOptions';
import { selectCurrentBrand } from '../../brand/Brand.selector';
import { AppliedFilter, FilterRequest } from '../responses/SearchResponse';
import { SearchMyCollectionFilterState } from './MyCollectionFilter.slice';

export const selectSearchMyCollectionFilter = (
  state: RootState,
): SearchMyCollectionFilterState => state.myCollectionFilter;

const sortByIdFilter = sortBy<AppliedFilter>(compose(toLower, prop('id')));

export const selectSearchMyCollectionPath = createSelector(
  selectSearchMyCollectionFilter,
  selectCurrentBrand,
  (
    search: SearchMyCollectionFilterState,
    currentBrand: BrandConfig | undefined,
  ) => {
    const appliedFilters: FilterRequest[] = sortByIdFilter(
      search.appliedFilters,
    ).map((f) => ({
      id: f.id,
      value: f.value,
      valueLabel: f.valueLabel,
    }));

    const sortFilter: FilterRequest = {
      id: 'sort',
      value: search.appliedSort.id,
      valueLabel: '',
    };

    const query = search.searchQuery;

    return {
      appliedFilters,
      sortFilter,
      query,
      currentBrand,
    };
  },
);

export const selectSearchMyCollectionIsSkipped = createSelector(
  selectSearchMyCollectionFilter,
  (search: SearchMyCollectionFilterState) =>
    search?.appliedFilters?.length === 0 && isEmpty(search?.searchQuery),
);
