import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';

export const sizeAvatar = (isMobile: boolean, isThanksYouPage?: boolean) => {
  if (isMobile) {
    if (isThanksYouPage) return SpaceSizes.mdPlus;
    return SpaceSizes.lg;
  }
  return SpaceSizes.lgPlus;
};

export const sizeUserName = (isMobile: boolean, isThanksYouPage?: boolean) => {
  if (isMobile) {
    if (isThanksYouPage) return 'cButton';
    return 'body2';
  }
  return 'h6';
};
