import { Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import ProductDetailTitle from '../ProductDetailTitle/ProductDetailTitle';
import ProductTag from '../ProductTag/ProductTag';
import {
  RESULT_TIME_TITLE_SKELETON_HEIGHT,
  RESULT_TIME_TITLE_SKELETON_WIDTH,
} from './ResultTime.constants';
import { IResultTime } from './ResultTime.types';
import TypographycH3 from './TypographycH3';
import { usePlayerTime, useResultTime } from './useResultTime';

export const ResultTime = ({
  homeGoal,
  awayGoal,
  isLoading,
  isWinner = false,
  productEvent,
  homeClub,
  awayClub,
  player,
  tags,
  sx,
}: IResultTime) => {
  const isMobile = useMobileMediaQuery();

  const {
    homeClubTime,
    awayClubTime,
    productEventTime,
    homeGoalTime,
    awayGoalTime,
  } = useResultTime(homeGoal, awayGoal, productEvent, homeClub, awayClub);

  const timeAndPlayerName = usePlayerTime(productEvent, player);

  return (
    <Stack sx={{ ...sx }}>
      {isLoading ? (
        <Skeleton
          height={RESULT_TIME_TITLE_SKELETON_HEIGHT}
          width={
            isMobile
              ? RESULT_TIME_TITLE_SKELETON_WIDTH.mobile
              : RESULT_TIME_TITLE_SKELETON_WIDTH.desktop
          }
        />
      ) : (
        <>
          {tags && <ProductTag golden={isWinner} tags={tags} />}
          <ProductDetailTitle golden={isWinner} textAlign="left" variant="cH3">
            {timeAndPlayerName}
          </ProductDetailTitle>
          <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.sm}>
            <TypographycH3 golden={isWinner}>
              {homeClubTime?.code}
            </TypographycH3>
            <TypographycH3
              golden={isWinner}
              reducedOpacity={!homeGoalTime}
              bold
            >
              {productEventTime?.event_data.home_partial_score}
            </TypographycH3>
            <TypographycH3 golden={isWinner}>-</TypographycH3>
            <TypographycH3 golden={isWinner}>
              {awayClubTime?.code}
            </TypographycH3>
            <TypographycH3
              golden={isWinner}
              reducedOpacity={!awayGoalTime}
              bold
            >
              {productEventTime?.event_data.away_partial_score}
            </TypographycH3>
          </Stack>
        </>
      )}
    </Stack>
  );
};
