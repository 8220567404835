import { useCallback, useEffect, useState } from 'react';

import {
  useAnonymousSessionMutation,
  useIpLocationQuery,
} from '../../features/session/Session.api';
import {
  selectIsUserLoggedIn,
  selectIsUserNeedsAnonymousSession,
} from '../../features/session/Session.selector';
import {
  loadSession,
  setLocation,
  setSession,
} from '../../features/session/Session.slice';
import { useAppDispatch, useAppSelector } from '../hooks';

type SessionStatus = 'loading' | 'success';

export default function useSession() {
  const dispatch = useAppDispatch();

  const [sessionStatus, setSessionStatus] = useState<SessionStatus>('loading');

  const isLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isUserNeedsAnonSession = useAppSelector(
    selectIsUserNeedsAnonymousSession,
  );

  const [getAnonymousSession, { isLoading }] = useAnonymousSessionMutation();
  const { data: locationData, isSuccess: isSuccessLocation } =
    useIpLocationQuery();

  const loadAnonymousSession = useCallback(async () => {
    if (isUserNeedsAnonSession) {
      if (sessionStatus === 'loading' && !isLoading) {
        // load session from api
        const anonSession = await getAnonymousSession({
          seed: 'empty',
        }).unwrap();
        // set session in store
        dispatch(setSession(anonSession));
      }
    } else {
      dispatch(loadSession());
    }
    setSessionStatus('success');
  }, [
    dispatch,
    getAnonymousSession,
    isLoading,
    isUserNeedsAnonSession,
    sessionStatus,
  ]);

  const loadAuthSession = useCallback(() => {
    dispatch(loadSession());
    setSessionStatus('success');
  }, [dispatch]);

  useEffect(() => {
    if (isSuccessLocation && locationData) {
      dispatch(setLocation(locationData));
    }
  }, [dispatch, isSuccessLocation, locationData]);

  useEffect(() => {
    if (isLoggedIn) {
      loadAuthSession();
    } else {
      loadAnonymousSession();
    }
  }, [isLoggedIn, loadAuthSession, loadAnonymousSession]);

  return {
    sessionStatus,
  };
}
