import { useGrowthBook } from '@growthbook/growthbook-react';
import { Stack, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { selectProductPlayerImage } from '../../../features/products/Product.selectors';
import { selectUserId } from '../../../features/session/Session.selector';
import { ProductProvider } from '../../../provider/ProductProvider/ProductProvider';
import BorderRadius from '../../../theme/foundations/borders/BorderRadius';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { SPONSOR_USERS } from '../../../utils/growthbookFeatures';
import { boxShadowMixin } from '../../../utils/mixins';
import { useAppSelector } from '../../hooks';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import {
  CARD_FOOTER_HEIGHT_DESKTOP,
  CARD_FOOTER_HEIGHT_MOBILE,
  CARD_HEIGHT_DESKTOP,
  CARD_HEIGHT_MOBILE,
  CARD_WIDTH_DESKTOP,
  CARD_WIDTH_MOBILE,
} from '../ProductCard/ProductCard.constants';
import { CardTemplateProps } from './CardTemplate.types';
import { CardTemplateFooter } from './Footer/CardTemplateFooter';

const MotionStack = motion(Stack);

export const CardTemplate = ({
  brandSticker,
  product,
  header,
  footer,
  onClick,
  isGolden = false,
  triviaStarted = false,
}: CardTemplateProps) => {
  const growthbook = useGrowthBook();
  const isMobile = useMobileMediaQuery();
  const userId = useAppSelector(selectUserId);
  const [sponsorUsers, setSponsorUsers] = useState<string[]>([]);

  const cardSize = isMobile
    ? { y: CARD_HEIGHT_MOBILE, x: CARD_WIDTH_MOBILE }
    : { y: CARD_HEIGHT_DESKTOP, x: CARD_WIDTH_DESKTOP };

  const cardFooterSize = isMobile
    ? CARD_FOOTER_HEIGHT_MOBILE
    : CARD_FOOTER_HEIGHT_DESKTOP;
  const theme = useTheme();

  const playerImage = selectProductPlayerImage(product);

  useEffect(() => {
    const users = growthbook?.getFeatureValue(SPONSOR_USERS, []);
    setSponsorUsers(users ?? []);
  }, [growthbook]);

  return (
    <ProductProvider product={product}>
      <MotionStack
        borderRadius={BorderRadius.md}
        data-testid={`productCard-${product?.product_id}`}
        flexDirection="column"
        height={cardSize.y}
        justifyContent="space-between"
        sx={[
          {
            cursor: 'pointer',
          },
          triviaStarted && {
            borderBottom: 1,
            borderLeft: 1,
            borderColor: theme.palette.success.light,
          },
          isGolden && {
            border: 1,
            borderColor: theme.palette.golden.main,
          },
        ]}
        whileHover={{
          boxShadow: `0px 0px 15px 5px ${
            triviaStarted
              ? theme.palette.success.light
              : isGolden
              ? theme.palette.golden.main
              : theme.palette.secondary.main
          }`,
        }}
        width={cardSize.x}
        onClick={onClick}
      >
        <Stack
          borderRadius={`${BorderRadius.md} ${BorderRadius.md} 0 0`}
          boxShadow={boxShadowMixin(theme.palette.secondary.light)}
          height={`calc(${cardSize.y}px - ${cardFooterSize}px)`}
          sx={{
            background: `url(${playerImage}) center / cover no-repeat`,
          }}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            {userId && sponsorUsers.includes(userId) && (
              <Stack
                alignItems="flex-start"
                justifyItems="flex-start"
                top={SpaceSizes.none}
              >
                {brandSticker}
              </Stack>
            )}
            <Stack marginLeft="auto" top={SpaceSizes.none}>
              {header}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          bgcolor={
            isGolden
              ? theme.palette.background.default
              : theme.palette.text.primary
          }
          borderRadius={`0 0 ${BorderRadius.md} ${BorderRadius.md}`}
          height={cardFooterSize}
        >
          {footer || <CardTemplateFooter isGolden={isGolden} />}
        </Stack>
      </MotionStack>
    </ProductProvider>
  );
};
