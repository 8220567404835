import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { setTradableFilter } from '../../../features/searchfilter/mycollection/MyCollectionFilter.slice';
import { selectUserSession } from '../../../features/session/Session.selector';
import { useLazySendOfferQuery } from '../../../features/trades/Trades.api';
import { OfferedItem, Player } from '../../../features/trades/Trades.types';
import { IAvailableSelection } from '../../components/HorizontalSelector/HorizontalSelector.interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useSearchWithInfiniteScroll from '../../hooks/search/useSearchWithInfiniteScroll';

export const useBuildOffer = (refetchDetail: () => void) => {
  const { txId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    data: ownedCards,
    isLoading: loadingOwned,
    enterWaypoint,
    isLastPage,
  } = useSearchWithInfiniteScroll();

  //
  const user = useAppSelector(selectUserSession);
  const [
    send,
    {
      isLoading: isSending,
      isSuccess: isSendSuccessful,
      isError: isSendFailed,
    },
  ] = useLazySendOfferQuery();

  const [userOffer, setUserOffer] = useState<OfferedItem[]>([]);
  const sentPieces = useMemo(() => {
    if (userOffer.length === 0) {
      return [];
    }
    return userOffer.map((offer) => `${offer.player.name} #${offer.ball_id}`);
  }, [userOffer]);

  const availableSelection: IAvailableSelection = useMemo(() => {
    if (!ownedCards) return [];
    return ownedCards.results.flatMap(
      ({
        product_id: productId,
        assignment,
        product_entity_id: ballId,
        backdrop_url: backDrop,
        pieces,
        player,
      }) => {
        return (
          pieces
            ?.filter((piece) => {
              const pieceNumber =
                piece.piece_number || Number(assignment?.piece_number);
              return !userOffer.some(
                (item) =>
                  item.product_id === productId &&
                  item.piece_number === pieceNumber,
              );
            })
            ?.map((piece) => {
              return {
                product_id: productId,
                assignment,
                product_entity_id: ballId,
                backdrop_url: backDrop,
                piece,
                player,
              };
            }) || []
        );
      },
    );
  }, [ownedCards, userOffer]);

  const handleOffer = (
    ballId: string,
    pieceNumber: number,
    player: Player,
    productId: string,
  ) => {
    const newItem: OfferedItem = {
      ball_id: ballId,
      piece_number: pieceNumber,
      player,
      product_id: productId,
    };

    const itemIndex = userOffer.findIndex(
      (item) =>
        item.piece_number === newItem.piece_number &&
        item.product_id === newItem.product_id,
    );

    if (itemIndex !== -1) {
      setUserOffer(userOffer.filter((_, index) => index !== itemIndex));
    } else {
      setUserOffer([...userOffer, newItem]);
    }
  };

  const removeOffer = (pieceNumber?: number, productId?: string) => {
    const itemIndex = userOffer.findIndex(
      (item) =>
        item.piece_number === pieceNumber && item.product_id === productId,
    );
    if (itemIndex !== -1) {
      setUserOffer(userOffer.filter((_, index) => index !== itemIndex));
    }
  };

  useEffect(() => {
    if (user?.user_id) {
      dispatch(setTradableFilter(user.user_id));
    }
  }, [dispatch, user?.user_id]);

  const sendTrade = () => {
    if (!txId || userOffer.length === 0 || userOffer.length > 4) return;
    const payload = {
      trade_piece_id: txId,
      trading_items: userOffer.map((item) => {
        return { product_id: item.product_id, piece_number: item.piece_number };
      }),
    };
    try {
      send(payload);
    } catch (error) {
      navigate('/trades/offer/result/failure');
    }
  };

  useEffect(() => {
    if (isSendSuccessful) {
      refetchDetail();
      navigate('/trades/offer/result/success');
    }
  }, [isSendSuccessful, navigate, refetchDetail]);
  useEffect(() => {
    if (isSendFailed) {
      refetchDetail();
      navigate('/trades/offer/result/failure');
    }
  }, [isSendFailed, navigate, refetchDetail]);

  return {
    ownedCards,
    loadingOwned,
    handleOffer,
    removeOffer,
    userOffer,
    availableSelection,
    sendTrade,
    isSending,
    isSendSuccessful,
    sentPieces,
    enterWaypoint,
    isLastPage,
  };
};
