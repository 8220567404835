import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ProductTagProps, ProductTagTypeEnum } from './ProductTag.types';
import { ProductTagLabel } from './ProductTagLabel';

const ProductTag = ({ tags, golden = false }: ProductTagProps) => {
  const labelTags =
    tags?.filter((t) => t !== ProductTagTypeEnum.NO_COUPONS) || [];

  if (labelTags.length === 0) return null;

  return (
    <Stack
      direction="row"
      display="flex"
      flexWrap="wrap"
      gap={SpaceSizes.sm}
      sx={{ marginBottom: SpaceSizes.sm }}
    >
      {labelTags.map((tag) => (
        <ProductTagLabel key={tag} golden={golden} tag={tag} />
      ))}
    </Stack>
  );
};

export default ProductTag;
