import { Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PRODUCT_STATS_TABLE_WIDGET_SKELETON_HEIGHT } from './ProductStatsTableWidget.constants';

export const ProductStatsTableWidgetSkeleton = () => {
  return (
    <Stack gap={SpaceSizes.sm}>
      <Skeleton
        height={PRODUCT_STATS_TABLE_WIDGET_SKELETON_HEIGHT.md}
        variant="rounded"
        width="100%"
      />
      <Skeleton
        height={PRODUCT_STATS_TABLE_WIDGET_SKELETON_HEIGHT.lg}
        variant="rounded"
        width="100%"
      />
      {Array.from({ length: 5 }, (_, index) => (
        <Skeleton
          key={`skeleton_stats_${_}${index}`}
          height={PRODUCT_STATS_TABLE_WIDGET_SKELETON_HEIGHT.sm}
          variant="rounded"
          width="100%"
        />
      ))}
    </Stack>
  );
};
