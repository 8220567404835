import { LoadingButton } from '@mui/lab';
import { Skeleton, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';

import env from '../../../env/env';
import { OfferStatus, OfferType } from '../../../features/trades/Trades.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ConfirmWarningModal } from '../../components/ConfirmWarningModal/ConfirmWarningModal';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { BUTTON_WIDTH } from '../../components/HorizontalSelector/HorizontalSelector.constants';
import { UserSummary } from '../../components/UserSummary/UserSummary';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { Offer } from '../trade-offer-builder/offer/Offer';
import { TradeOfferSummary } from '../trade-offer-builder/TradeOfferSummary';
import { statusTitleFunction } from './TradeOfferDetails.constant';
import { useOfferDetails } from './useOfferDetails';

export const TradeOfferDetails = () => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const [deliveryModalOpen, setDeliveryModalOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const {
    offer: offerDetails,
    loadingDetail,
    getPieces,
    handleReject,
    loadingReject,
    loadingAccept,
    handleAccept,
  } = useOfferDetails();

  const country = useMemo(
    () =>
      `${
        env.REACT_APP_STATIC_BASE_URL
      }marketplace/img/commons/countries/${offerDetails?.trading_user?.country_id?.toLowerCase()}.svg`,
    [offerDetails?.trading_user?.country_id],
  );

  return (
    <PageContainer
      id="tradesOfferDetails"
      title={
        offerDetails?.status !== undefined
          ? statusTitleFunction(t)[offerDetails.status]
          : ''
      }
      backButton
    >
      <Stack gap={SpaceSizes.md} width="100%">
        <Stack
          alignItems="start"
          gap={SpaceSizes.lg}
          my={SpaceSizes.lg}
          width="100%"
        >
          <UserSummary
            country={country}
            isLoading={loadingDetail}
            profilePic={offerDetails?.trading_user.avatar_url}
            userName={offerDetails?.trading_user.nickname}
          />
          <Stack
            alignItems="stretch"
            flexDirection="row"
            gap={SpaceSizes.md}
            justifyContent="space-between"
            width="100%"
          >
            <Offer
              isLoading={loadingDetail}
              isReversed={offerDetails?.type === OfferType.SENT}
              requestOffer={offerDetails?.offered_item}
              sentOffer={offerDetails?.trading_items}
            />
          </Stack>
        </Stack>

        {loadingDetail ? (
          <Skeleton
            height={SpaceSizes.xlPlus}
            variant="rectangular"
            width="100%"
          />
        ) : (
          <TradeOfferSummary
            offer={offerDetails?.offered_item}
            offerType={offerDetails?.type}
            sentPieces={getPieces}
          />
        )}
        {offerDetails?.type === OfferType.RECEIVED &&
          offerDetails?.status === OfferStatus.OPEN && (
            <Stack
              direction="row"
              gap={SpaceSizes.lgPlus}
              justifyContent="center"
              my={SpaceSizes.lg}
              width="100%"
            >
              <LoadingButton
                data-testid="trade-offer-reject"
                disabled={loadingAccept || loadingReject}
                loading={loadingAccept || loadingReject}
                sx={{ paddingY: SpaceSizes.sm, width: BUTTON_WIDTH }}
                variant="outlined"
                onClick={() => setCancelModalOpen(true)}
              >
                {t('item.options.reject')}
              </LoadingButton>
              <LoadingButton
                data-testid="trade-offer-accept"
                disabled={loadingAccept || loadingReject}
                loading={loadingAccept || loadingReject}
                sx={{ paddingY: SpaceSizes.sm, width: BUTTON_WIDTH }}
                type="submit"
                variant="contained"
                onClick={() => setDeliveryModalOpen(true)}
              >
                {t('item.options.accept')}
              </LoadingButton>
            </Stack>
          )}
      </Stack>
      <ConfirmWarningModal
        isLoading={loadingReject}
        isOpen={cancelModalOpen}
        namespaces="pages.myTradeRequests.cta.cancel.warning"
        onClose={() => setCancelModalOpen(false)}
        onSubmit={handleReject}
      />
      <ConfirmWarningModal
        externalComponent={
          <TradeOfferSummary
            offer={offerDetails?.offered_item}
            sentPieces={getPieces}
          />
        }
        isLoading={loadingAccept}
        isOpen={deliveryModalOpen}
        namespaces="pages.myTradeRequests.cta.submit.warning"
        onClose={() => setDeliveryModalOpen(false)}
        onSubmit={handleAccept}
      />
    </PageContainer>
  );
};
