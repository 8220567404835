import { CSSProperties } from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    cH1: CSSProperties;
    cH2: CSSProperties;
    cH3: CSSProperties;
    cH4: CSSProperties;
    cH5: CSSProperties;
    cBody1: CSSProperties;
    cBody2: CSSProperties;
    cCaption: CSSProperties;
    cButton: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cH1: CSSProperties;
    cH2: CSSProperties;
    cH3: CSSProperties;
    cH4: CSSProperties;
    cH5: CSSProperties;
    cBody1: CSSProperties;
    cBody2: CSSProperties;
    cCaption: CSSProperties;
    cButton: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cH1: true;
    cH2: true;
    cH3: true;
    cH4: true;
    cH5: true;
    cH6: true;
    cBody1: true;
    cBody2: true;
    cCaption: true;
    cButton: true;
  }
}

export default {
  fontFamily: 'Poppins, Helvetica, sans-serif',
  cH1: {
    fontSize: 32,
  },
  cH2: {
    fontSize: 28,
  },
  cH3: {
    fontSize: 24,
  },
  cH4: {
    fontSize: 20,
  },
  cH5: {
    fontSize: 16,
    '@media screen and (max-width: 800px)': {
      fontSize: 14,
    },
  },
  cH6: {
    fontSize: 13,
  },
  cBody1: {
    fontSize: 12,
  },
  cBody2: {
    fontSize: 10,
  },
  cCaption: {
    fontSize: 8,
  },
  cButton: {
    fontSize: 10,
  },
};
