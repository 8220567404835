import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Stack, useTheme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { Animation } from '../Animate/Animate';
import { AnimationEnum } from '../Animate/Animate.constants';
import { ILiveIndicatorProps } from './LiveIndicator.types';

export const LiveIndicator = ({ isLive }: ILiveIndicatorProps) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={SpaceSizes.sm}
      justifyContent="flex-start"
      sx={{ opacity: isLive ? 1 : 0 }}
    >
      <Animation
        instantBorderDelay={1800}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: SpaceSizes.md,
        }}
        type={AnimationEnum.Pulse}
        instantBorder
      >
        <RadioButtonCheckedIcon sx={{ color: theme.palette.primary.light }} />
      </Animation>
    </Stack>
  );
};
