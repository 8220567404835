import { LoadingButton } from '@mui/lab';
import { Stack, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import BorderRadius from '../../../theme/foundations/borders/BorderRadius';
import type { GolButtonProps } from './GolButton.types';

export const GolButton = ({
  id,
  variant = 'primary',
  value,
  disabled = false,
  loading = false,
  onClick,
  sx = {},
  endIcon,
  startIcon,
  testId,
  size = 'large',
  type = 'button',
  to,
  fullWidth = false,
}: GolButtonProps) => {
  const theme = useTheme();
  const buttonVariant = useMemo(() => {
    switch (variant) {
      case 'primary':
        return 'contained';
      case 'secondary':
        return 'outlined';
      default:
        return 'text';
    }
  }, [variant]);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }

    if (to) {
      navigate(to);
    }
  };
  const stackSx = useMemo(
    () => ({
      ...(fullWidth && { width: '100%' }), // only add width if fullWidth is true
      ...sx,
    }),
    [fullWidth, sx],
  );

  return (
    <Stack sx={stackSx}>
      <LoadingButton
        data-testid={testId}
        disabled={disabled}
        endIcon={endIcon}
        id={id}
        loading={loading}
        size={size}
        startIcon={startIcon}
        sx={[
          {
            textTransform: 'none',
            backgroundColor: theme.palette.button[variant].background,
            borderColor: theme.palette.button[variant].border,
            color: theme.palette.button[variant].text,
            fontWeight: 'semibold',
            borderRadius: BorderRadius.mdPlus,
            '&:hover': {
              opacity: 0.8,
            },
          },
          disabled && {
            backgroundColor: theme.palette.button[variant].disableBackground,
            borderColor: theme.palette.button[variant].disableBorder,
            color: theme.palette.button[variant].disableText,
          },
        ]}
        type={type}
        variant={buttonVariant}
        onClick={handleButtonClick}
      >
        {value}
      </LoadingButton>
    </Stack>
  );
};
