import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { LoadingButton } from '@mui/lab';
import { Stack, styled, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useGetDynamicLinkQuery } from '../../../features/referrals/Referrals.api';
import {
  selectIsHomePage,
  selectIsMarketplacePage,
  selectIsMatchesPage,
  selectIsSignupPage,
} from '../../../features/router/router';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getReferralCookie } from '../../../utils/cookies';
import { TAG_EVENT, TAG_TYPE } from '../../../utils/tagger';
import LogoBoxBanner from './LogoBoxBanner';

const APP_LINK = 'https://golballapp.page.link/N8fh';

const StackAppBannerStyled = styled(Stack)(({ theme }) => ({
  height: '94px',
  paddingLeft: SpaceSizes.md,
  paddingRight: SpaceSizes.md,
  paddingTop: SpaceSizes.smPlus,
  paddingBottom: SpaceSizes.smPlus,
  background: theme.palette.primary.light,
}));

const StackInfoContainer = styled(Stack)({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  height: '100%',
  width: '100%',
});

const StackCentred = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StackLeftAligned = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  paddingLeft: SpaceSizes.sm,
});

const AppBannerTypographyTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: '700',
}));

const AppBannerTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const AppBannerRedirect = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const referralCode = getReferralCookie() ?? params.get('ref');
  const { data: dataDynamicLink, isLoading } = useGetDynamicLinkQuery(
    isNotNilOrEmpty(referralCode) ? referralCode : skipToken,
  );

  const location = useLocation();

  const isShowBanner =
    selectIsMatchesPage(location) ||
    selectIsHomePage(location) ||
    selectIsMarketplacePage(location) ||
    selectIsSignupPage(location);

  const sendDataToGTM = useGTMDispatch();

  const handleRedirect = useCallback(() => {
    // send data to GTM
    const to = dataDynamicLink?.link ?? APP_LINK;

    sendDataToGTM({
      content_group: TAG_EVENT.CONTENT_GROUP.APP,
      content_type: TAG_TYPE.CTA_BANNER,
      event: TAG_EVENT.APP_DOWNLOAD,
      item_id: location.pathname,
      to,
    });

    window.open(to, '_blank');
  }, [dataDynamicLink?.link, location.pathname, sendDataToGTM]);

  if (!isShowBanner) return null;
  return (
    <StackAppBannerStyled>
      <StackInfoContainer>
        <StackCentred>
          <LogoBoxBanner />
        </StackCentred>
        <StackLeftAligned spacing={SpaceSizes.min}>
          <AppBannerTypographyTitle>
            {t('pages.home.appBanner.title')}
          </AppBannerTypographyTitle>
          <AppBannerTypography>
            {t('pages.home.appBanner.sports')}
          </AppBannerTypography>
        </StackLeftAligned>
        <StackCentred>
          <LoadingButton
            data-testid="download-button"
            loading={isLoading}
            sx={{ borderRadius: '100px' }}
            variant="contained"
            onClick={handleRedirect}
          >
            {t('pages.home.appBanner.get')}
          </LoadingButton>
        </StackCentred>
      </StackInfoContainer>
    </StackAppBannerStyled>
  );
};
