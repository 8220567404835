import { useGrowthBook } from '@growthbook/growthbook-react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { WEB3_FEATURES } from '../../../utils/growthbookFeatures';
import { Link } from '../../components/Link/Link';
import { MatchResult } from '../../components/MatchResult/MatchResult';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import {
  ADD_SPINNER_IMAGE,
  CARD_ALBUM_HEADER_FONT_SIZE,
  CARD_ALBUM_SUB_FONT_SIZE,
  CARD_SIZE,
  TRADE_MARKET_AVAILABLE_FONT_SIZE,
} from './CardAlbum.constants';
import { CardAlbumI } from './CardAlbum.types';

export const CardAlbum = ({
  title,
  available,
  userOwnsIt,
  productId,
  player,
  match,
}: CardAlbumI) => {
  const growthbook = useGrowthBook();

  const isDesktop = useDesktopDeviceMediaQuery();
  const navigateToProduct = useCallback(() => {
    if (userOwnsIt) {
      return `/my-collection/${productId}`;
    }
    return available === 0 && growthbook?.isOn(WEB3_FEATURES)
      ? `/marketplace/${productId}`
      : `/product/${productId}`;
  }, [userOwnsIt, available, growthbook, productId]);

  const renderAvailable = useMemo(
    () => `${t('components.productCard.available')} ${available}`,
    [available],
  );

  return (
    <Link target="_blank" to={navigateToProduct()} underline="none">
      <Stack
        alignItems="center"
        border="1px solid #fff"
        borderRadius={SpaceSizes.xxs}
        boxShadow={userOwnsIt && 'inset 0px -130px 50px -15px rgb(0 0 0 / 82%)'}
        data-testid={productId}
        height={CARD_SIZE.height}
        justifyContent="flex-end"
        paddingY={SpaceSizes.sm}
        style={{
          backgroundSize: 'auto 100%',
          backgroundImage: `url("${player?.image}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundColor: userOwnsIt ? 'transparent' : '',
          position: 'relative',
          cursor: 'pointer',
        }}
        width={CARD_SIZE.width}
      >
        {!userOwnsIt && (
          <>
            <Stack
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top="5%"
            >
              <Typography
                fontSize={CARD_ALBUM_HEADER_FONT_SIZE}
                fontWeight={600}
              >
                {player.name}
              </Typography>
              <Typography fontSize={CARD_ALBUM_SUB_FONT_SIZE}>
                #{title.replace('laliga', 'LaLiga')}
              </Typography>
            </Stack>

            <Box
              sx={{
                position: 'relative',
                bottom: '25%',
                height: SpaceSizes.lgPlus,
                width: SpaceSizes.lgPlus,
                backgroundImage: ADD_SPINNER_IMAGE,
                borderRadius: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AddIcon data-testid="add" />
            </Box>
          </>
        )}

        <Stack alignItems="center" justifyContent="flex-end">
          <Box zIndex={3}>
            {userOwnsIt ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={SpaceSizes.xxs}
              >
                <Typography
                  align="left"
                  color="white"
                  fontSize={{
                    xs: 12,
                    sm: 14,
                    md: 15,
                  }}
                  fontWeight="bold"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {player?.name}
                </Typography>

                {!!match && (
                  <MatchResult
                    away={match.away}
                    awayScore={match.away.score}
                    golClubId={player.team_id}
                    home={match.home}
                    homeScore={match.home.score}
                    sx={{ gap: { xs: SpaceSizes.xs, sm: SpaceSizes.sm } }}
                    textVariant={isDesktop ? 'h5' : 'body1'}
                  />
                )}
              </Stack>
            ) : (
              /** if user owns it, don't show available and trades link */
              <Stack justifyContent="center" textAlign="center">
                <Stack height={SpaceSizes.lg} zIndex={1}>
                  {available ? (
                    <Typography
                      component="p"
                      fontSize={TRADE_MARKET_AVAILABLE_FONT_SIZE}
                    >
                      {renderAvailable}
                    </Typography>
                  ) : growthbook?.isOn(WEB3_FEATURES) ? (
                    <Typography fontSize={TRADE_MARKET_AVAILABLE_FONT_SIZE}>
                      {t('components.productCard.trades')}
                    </Typography>
                  ) : (
                    <Typography fontSize={TRADE_MARKET_AVAILABLE_FONT_SIZE}>
                      {t('components.productCard.productState.finished')}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
      </Stack>
    </Link>
  );
};
