export const transition = {
  type: 'spring',
  duration: 0.25,
  damping: 20,
  stiffness: 100,
};

export const fadeInDown = {
  hidden: {
    transition,
    opacity: 0,
    y: -50,
  },
  visible: { transition, opacity: 1, y: 0 },
};

export const fadeOutUp = {
  exit: {
    transition: {
      duration: 0.15,
    },
    opacity: 0,
    y: -50,
  },
};
