import { Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import {
  STATIC_SIZE_HEIGHT,
  STATIC_SIZE_WIDTH,
} from '../../../components/CompactCard/CompactCard.constants';

export const OfferSkeleton = () => (
  <Stack direction="row" justifyContent="space-between" width="100%">
    <Stack gap={SpaceSizes.md}>
      <Skeleton
        height={SpaceSizes.lgPlus}
        sx={{ xs: SpaceSizes.lgPlus, sm: SpaceSizes.xxl }}
        variant="text"
      />
      <Skeleton
        sx={{
          height: STATIC_SIZE_HEIGHT,
          width: STATIC_SIZE_WIDTH,
        }}
        variant="rectangular"
      />
    </Stack>
    <Stack gap={SpaceSizes.md}>
      <Skeleton
        height={SpaceSizes.lgPlus}
        sx={{ xs: SpaceSizes.lgPlus, sm: SpaceSizes.xxl }}
        variant="text"
      />
      <Skeleton
        sx={{
          height: STATIC_SIZE_HEIGHT,
          width: STATIC_SIZE_WIDTH,
        }}
        variant="rectangular"
      />
    </Stack>
  </Stack>
);
