import {
  Button,
  Dialog,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useRef, useState } from 'react';

import { useGetUserPolygonAddressQuery } from '../../../features/transfer/Transfer.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { Animation } from '../Animate/Animate';
import { AnimationEnum } from '../Animate/Animate.constants';
import { TOAST_VISIBLE_TIMEOUT } from './ReceiveModal.constants';
import { ITransferModal } from './ReceiveModal.types';
import { abbreviateWallet } from './ReceiveModal.utils';

export const ReceiveModal = ({ isOpen, handleClose }: ITransferModal) => {
  const { t } = useTranslationByNamespaces('pages.myBuys.trade.receive');
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { data: walletData, isLoading } =
    useGetUserPolygonAddressQuery(undefined);

  const walletAddress: string = walletData?.wallet_address || '- - -';

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
    if (buttonRef.current) {
      buttonRef.current.blur();
      buttonRef.current.focus();
    }
    if (!isToastVisible) {
      setIsToastVisible(true);

      if (buttonRef.current) {
        buttonRef.current.disabled = true;
      }

      const cooldown = setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.disabled = false;
        }
        setIsToastVisible(false);
      }, TOAST_VISIBLE_TIMEOUT);

      return () => clearTimeout(cooldown);
    }

    return () => null;
  };

  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      sx={{ backdropFilter: 'blur(2px)' }}
      onClose={handleClose}
    >
      <AnimatePresence>
        {isToastVisible && (
          <Animation
            key="receive-message"
            sx={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              position: 'fixed',
              top: SpaceSizes.lgPlus,
              left: 0,
              justifyContent: 'center',
            }}
            timeProps={{
              duration: 0.25,
            }}
            type={AnimationEnum.Offset}
          >
            <Stack
              alignItems="center"
              display="flex"
              justifyContent="center"
              sx={{
                padding: SpaceSizes.xs,
                backgroundColor: theme.palette.info.dark,
                border: `1px solid ${theme.palette.primary.dark}`,
                boxShadow: '0px 0px 7px rgba(187, 187, 187, 0.25)',
              }}
              top={SpaceSizes.lgPlus}
              width="50%"
            >
              <Typography
                data-testid="receive-message-toast"
                textAlign="center"
                variant="cH6"
              >
                {t('copiedMessage')}
              </Typography>
            </Stack>
          </Animation>
        )}
      </AnimatePresence>
      <Stack gap={SpaceSizes.mdPlus} padding={SpaceSizes.md}>
        <Stack gap={SpaceSizes.md}>
          <Typography
            component="h2"
            sx={{ color: theme.palette.text.primary }}
            variant="cH4"
          >
            {t('modalTitle')}
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap={SpaceSizes.xs}>
            <Typography
              component="h2"
              sx={{
                color: theme.palette.text.secondary,
                lineHeight: '120%',
              }}
              variant="cH5"
            >
              {t('modalDescription.init')}
            </Typography>
            <Typography
              component="h2"
              sx={{
                color: theme.palette.text.primary,
                lineHeight: '120%',
              }}
              variant="cH5"
            >
              {t('modalDescription.emphasis')}
            </Typography>
            <Typography
              component="h2"
              sx={{
                color: theme.palette.text.secondary,
                lineHeight: '120%',
              }}
              variant="cH5"
            >
              {t('modalDescription.end')}
            </Typography>
          </Stack>
          {isLoading ? (
            <Skeleton
              height={SpaceSizes.lgPlus}
              variant="rectangular"
              width="100%"
            />
          ) : (
            <Stack direction="row" gap={SpaceSizes.sm} width="100%">
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: '70%',
                  div: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                value={abbreviateWallet(walletAddress)}
                variant="outlined"
              />
              <Button
                ref={buttonRef}
                data-testid="receive-button-fastcopy"
                value={walletAddress}
                variant="contained"
                onClick={handleCopy}
              >
                <Typography variant="cButton">
                  {isToastVisible ? t('modalCtaAlt') : t('modalCta')}
                </Typography>
              </Button>
            </Stack>
          )}
        </Stack>
        <Divider />
        <Typography
          component="h2"
          sx={{
            color: theme.palette.text.secondary,
            lineHeight: '120%',
          }}
          variant="cH5"
        >
          {t('modalDescription.footer')}
        </Typography>
      </Stack>
    </Dialog>
  );
};
