import { Button, Grid, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isNonEmptyArray } from 'ramda-adjunct';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useSearchByMatchQuery } from '../../../features/searchfilter/match/MatchFilter.api';
import { selectMatchResults } from '../../../features/searchfilter/SearchFilter.selector';
import { selectIsUserLoggedIn } from '../../../features/session/Session.selector';
import { useGetIsCouponAvailableForUserQuery } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { FREE_COUPON_ID } from '../../components/CheckoutWidget/CheckoutWidget.constants';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import { MatchCard } from '../../components/MatchCard/MatchCard';
import { SearchResults } from '../../components/search/SearchResults/SearchResults';
import { useAppSelector } from '../../hooks';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { MatchNoGoals } from './MatchNoGoals';

const Match = () => {
  const { matchId } = useParams();
  const navigate = useNavigate();

  const { data, isSuccess, isError, isFetching } = useSearchByMatchQuery(
    matchId ?? skipToken,
  );

  const { t } = useTranslation();
  const match = selectMatchResults(data);
  const isTablet = useTabletMediaQuery();

  useEffect(() => {
    if (isSuccess && !match) {
      navigate('/matches');
    }
  }, [match, navigate, isSuccess]);

  const validatePresale = (
    status?: string,
    activePresale?: boolean,
  ): boolean => {
    const matchNoEnded = status === 'Fixture' || status === 'Playing';
    const matchIsLive = status === 'Playing';
    return Boolean(activePresale && matchNoEnded && !matchIsLive);
  };

  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const { data: canUserClaim, isLoading } = useGetIsCouponAvailableForUserQuery(
    isLoggedUser ? { couponId: FREE_COUPON_ID } : skipToken,
  );

  const navigateToPresale = useCallback(() => {
    if (matchId) {
      navigate(`/presale/${matchId}`);
    }
  }, [navigate, matchId]);

  return (
    <PageContainer
      {...(isError && { errorCode: 'error.DEFAULT' })}
      id="matchPage"
      loading={isFetching}
      // backButton
    >
      {/* <BenefitAlert
        available={
          (canUserClaim?.isCouponAvailableForUser &&
            match?.status === 'Playing') ||
          !isLoggedUser
        }
      /> */}
      {data && match && !isLoading && (
        <Stack data-testid="match-results" spacing={SpaceSizes.sm} width="100%">
          <Grid
            columnGap={SpaceSizes.md}
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent="center"
            rowGap={SpaceSizes.md}
            container
            item
          >
            {match.is_live && (
              <Grid alignItems="center" justifyContent="center" xs={12} item>
                <ExternalContent
                  defaultChildren={
                    <ExternalContent
                      filter={{
                        key: 'page_id',
                        value: 'match_header',
                      }}
                      type="pages"
                    />
                  }
                  filter={{
                    key: 'page_id',
                    value: matchId ? `header_${matchId}` : '',
                  }}
                  type="live-matches"
                />
              </Grid>
            )}

            <Stack
              alignItems="center"
              display="flex"
              width={{ xs: '100%', lg: '30%' }}
            >
              <MatchCard matchResult={match} isLarge />

              {validatePresale(match.status, match.active_presale) &&
                isTablet && (
                  <Button
                    fullWidth={false}
                    sx={{
                      backgroundColor: '#f5ce42',
                      color: 'black',
                      position: 'relative',
                      width: isTablet ? '80%' : '75%',
                      marginTop: SpaceSizes.sm,
                      '&:hover': { backgroundColor: '#f5ce42', opacity: 0.9 },
                    }}
                    variant="contained"
                    onClick={navigateToPresale}
                  >
                    {t('pages.presale.navigateToPresale')}
                  </Button>
                )}
            </Stack>

            <Grid lg={7} md={7} width={{ xs: '100%', lg: '70%' }} xs={12} item>
              {isNonEmptyArray(data.results) ? (
                <Stack>
                  <ExternalContent
                    filter={{
                      key: 'page_id',
                      value: 'match_education_header',
                    }}
                    paddingX={SpaceSizes.none}
                    type="pages"
                  />

                  <SearchResults results={data.results} />
                  <ExternalContent
                    filter={{
                      key: 'page_id',
                      value: 'match_education_footer',
                    }}
                    paddingX={SpaceSizes.none}
                    paddingY={SpaceSizes.md}
                    type="pages"
                  />
                </Stack>
              ) : (
                <MatchNoGoals
                  date={data.match_result?.date}
                  isPresaleButtonActive={validatePresale(
                    match.status,
                    match.active_presale,
                  )}
                  matchId={matchId ?? ''}
                  navigateToPresale={navigateToPresale}
                  status={data.match_result?.status ?? ''}
                  time={data.match_result?.time}
                />
              )}
            </Grid>
            {match.is_live && (
              <Grid alignItems="center" justifyContent="center" xs={12} item>
                <ExternalContent
                  defaultChildren={
                    <ExternalContent
                      filter={{
                        key: 'page_id',
                        value: 'match_footer',
                      }}
                      type="pages"
                    />
                  }
                  filter={{
                    key: 'page_id',
                    value: matchId ? `footer_${matchId}` : '',
                  }}
                  type="live-matches"
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      )}
    </PageContainer>
  );
};

export default Match;
