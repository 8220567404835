import { styled, Switch } from '@mui/material';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';

export const SwitchStyled = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: SpaceSizes.xxs,
  },

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.primary.contrastText,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
      },
    },
  },
}));

export const inlineItemStyled = {
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
};
