export const ProductTagTypeEnum = {
  DOUBLE_GOAL: 'DOUBLE_GOAL',
  TRIPLE_GOAL: 'TRIPLE_GOAL',
  OWN_GOAL: 'OWN_GOAL',
  MUSEUM: 'MUSEUM',
  NO_COUPONS: 'NO_COUPONS',
  AUCTION: 'AUCTION',
  LIMITED_EDITION: 'LIMITED_EDITION',
  DIRECT_SALE: 'DIRECT_SALE',
};

interface ProductTagProps {
  tags: string[];
  golden?: boolean;
}

export type { ProductTagProps };
