import { Backdrop, CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isNotEmpty } from 'ramda-adjunct';
import React from 'react';

import theme from '../../../theme';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { IMAGE_FALLBACK } from '../../pages/checkout/Checkout.constants';
import { AlertCard } from '../AlertCard/AlertCard';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import ProductPrice from '../ProductPrice/ProductPrice';
import { FONT_SIZE_TYPOGRAPHY } from './CheckoutTypeDirect.constant';
import { CheckoutTypeDirectProps } from './CheckoutTypeDirect.types';

export const CheckoutTypeDirect = ({
  item,
  totalPoints,
  createPayIsLoading = false,
  handlePayment,
  errorCode,
}: CheckoutTypeDirectProps) => {
  const { t } = useTranslationByNamespaces('pages.checkout');
  const isMobile = useMobileMediaQuery();

  if (!item) return null;

  return (
    <Stack gap={SpaceSizes.mdPlus}>
      <Stack
        bgcolor="background.paper"
        p={SpaceSizes.md}
        style={{
          borderRadius: SpaceSizes.md,
        }}
      >
        <Stack key={item.product_id}>
          <Stack gap={SpaceSizes.sm}>
            <Stack flexDirection="row" gap={SpaceSizes.smPlus}>
              <Stack
                height={IMAGE_FALLBACK.HEIGHT}
                position="relative"
                width={IMAGE_FALLBACK.WIDTH}
              >
                <ImageFallback
                  height="100%"
                  src={item.image}
                  style={{
                    borderRadius: IMAGE_FALLBACK.BORDER_RADIUS,
                    objectFit: 'cover',
                  }}
                  width="100%"
                />
                <Stack
                  bgcolor="primary.main"
                  bottom={0}
                  position="absolute"
                  sx={{
                    borderTopLeftRadius: SpaceSizes.none,
                    borderTopRightRadius: SpaceSizes.none,
                    borderBottomLeftRadius: IMAGE_FALLBACK.BORDER_RADIUS,
                    borderBottomRightRadius: IMAGE_FALLBACK.BORDER_RADIUS,
                    opacity: 0.7,
                  }}
                  width="100%"
                >
                  <Typography
                    fontWeight="500"
                    sx={FONT_SIZE_TYPOGRAPHY}
                    textAlign="center"
                    variant="caption"
                  >
                    {item.entity_name}
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={SpaceSizes.sm}>
                <Stack>
                  <Typography fontWeight={800} variant="body2">
                    {item.title}
                  </Typography>
                  <Typography fontWeight="600" variant="body2">
                    {item.subtitle.line1}
                  </Typography>
                </Stack>
                <Typography variant="body2">
                  {t('quantity')} {item.quantity}
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={SpaceSizes.smPlus}>
              <Stack gap={SpaceSizes.xs}>
                <Divider sx={{ width: '100%' }} />
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant={isMobile ? 'body2' : 'h6'}>
                    {t('amounts.price')}
                  </Typography>
                  <ProductPrice size="large" value={totalPoints} showEuro />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {handlePayment && (
        <Stack alignItems="center" width="100%">
          {isNotEmpty(errorCode) && (
            <AlertCard
              content={t(`errors.${errorCode}`)}
              id="alert-payError"
              show={isNotEmpty(errorCode)}
              sx={{
                margin: SpaceSizes.none,
                padding: SpaceSizes.none,
                fontSize: isMobile
                  ? typography.cBody1.fontSize
                  : typography.cH6.fontSize,
              }}
            />
          )}
          <PaymentButton
            buttonText={t('buy')}
            dataTestId="payment-button"
            disableButton={false}
            handleBuy={handlePayment}
            id="confirm-buy"
            showPrice={false}
            sx={{
              width: '100%',
            }}
            variant={isMobile ? 'body2' : 'subtitle1'}
          />
          <Backdrop
            open={createPayIsLoading}
            sx={{
              zIndex: theme.zIndex.drawer + 1,
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Stack>
      )}
    </Stack>
  );
};
