import { CARD_PAYMENT_METHOD } from '../../app/pages/tiers/Tiers.constants';

export const checkoutPayFactory = ({
  checkoutId,
  topUpId,
  successUrl,
  cancelUrl,
  userIp,
  metadata,
}: {
  checkoutId?: string;
  topUpId?: string;
  successUrl: string;
  cancelUrl: string;
  userIp: string;
  metadata: {
    user_ip: string;
    base_url: string;
  };
}) => {
  return {
    checkout_id: checkoutId,
    topup_id: topUpId,
    success_url: successUrl,
    cancel_url: cancelUrl,
    user_ip: userIp,
    payment_method: CARD_PAYMENT_METHOD,
    metadata,
  };
};

export const checkoutCreateFactory = ({
  productId,
  quantity,
  price,
  coupons = undefined,
}: {
  productId?: string;
  quantity: number;
  price: {
    unit_value?: string;
    currency?: string;
  };
  coupons?: string[];
}) => {
  return {
    item: {
      product_id: productId,
      quantity,
      price,
    },
    coupons,
  };
};
