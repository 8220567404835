import Env from '../../env/env';
import { api } from '../api/Api';
import { MatchesResponse } from '../products/Product.types';
import {
  GetLeaguesResponse,
  GetLiveMatchRequest,
  GetLiveMatchResponse,
  GetMatchesByTournamentRequest,
  GetMatchesByTournamentResponse,
  GetMatchesInWeekRequest,
  GetTeamsByLeagueResponse,
  GetTeamsRequest,
  GetTeamsResponse,
  GetTournamentsResponse,
  GetWeeksResponse,
} from './Tournaments.types';

export const tournamentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTournaments: builder.query<GetTournamentsResponse, string>({
      query: (brandId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments?brand_id=${brandId}`,
        method: 'GET',
      }),
    }),
    getWeeks: builder.query<GetWeeksResponse, string>({
      query: (competitionId) => {
        return `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/${competitionId}/weeks`;
      },
    }),
    getMatchesByTournament: builder.query<
      GetMatchesByTournamentResponse[],
      GetMatchesByTournamentRequest
    >({
      query: ({ from, to }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/matches-by-tournament`,
        params: { from, to },
        method: 'GET',
      }),
    }),
    getMatchesByWeek: builder.query<MatchesResponse, GetMatchesInWeekRequest>({
      query: ({ competitionId, week, stageId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/${competitionId}/matches`,
        params: { week, stage_id: stageId },
        method: 'GET',
      }),
    }),
    getBrands: builder.query<GetLeaguesResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/brands`,
        method: 'GET',
      }),
    }),
    getTeams: builder.query<GetTeamsResponse, GetTeamsRequest>({
      query: (name) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/leagues/${name}/teams`,
        method: 'GET',
      }),
    }),
    getTeamsByLeagues: builder.query<GetTeamsByLeagueResponse[], void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/teams-by-league`,
        method: 'GET',
      }),
    }),
    getLiveMatch: builder.query<GetLiveMatchResponse, GetLiveMatchRequest>({
      query: (leagueId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/leagues/${leagueId}/live`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetTournamentsQuery,
  useGetWeeksQuery,
  useLazyGetMatchesByWeekQuery,
  useGetBrandsQuery,
  useGetTeamsQuery,
  useGetTeamsByLeaguesQuery,
  useGetLiveMatchQuery,
  useLazyGetMatchesByTournamentQuery,
} = tournamentsApi;
