import { Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AppliedFilter } from '../../../features/searchfilter/responses/SearchResponse';
import {
  addFilter,
  removeFilter,
} from '../../../features/searchfilter/SearchFilter.slice';
import {
  selectIsUserLoggedIn,
  selectIsUserNeedsPhoneValidation,
} from '../../../features/session/Session.selector';
import { useGetIsCouponAvailableForUserQuery } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { BenefitAlert } from '../../components/BenefitAlert/BenefitAlert';
import { FREE_COUPON_ID } from '../../components/CheckoutWidget/CheckoutWidget.constants';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import { NoResults } from '../../components/search/NoResults/NoResults';
import { SearchResults } from '../../components/search/SearchResults/SearchResults';
import { SearchSection } from '../../components/search/SearchSection/SearchSection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { CollectableMarketSkeletons } from './CollectableMarketTab.skeleton';
import { ICollectableMarketTab } from './Marketplace.types';

export const CollectableMarketTab = ({
  openPhoneDialog,
  data,
  isLoading,
  isLastPage,
  enterWaypoint,
}: ICollectableMarketTab) => {
  const isTablet = useTabletMediaQuery();
  const isDesktop = useDesktopDeviceMediaQuery();
  const location = useLocation();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const { data: canUserClaim } = useGetIsCouponAvailableForUserQuery(
    isLoggedUser ? { couponId: FREE_COUPON_ID } : skipToken,
  );

  const dispatch = useAppDispatch();

  const userNeedsPhoneValidation = useAppSelector(
    selectIsUserNeedsPhoneValidation,
  );
  const { t } = useTranslationByNamespaces('pages.marketplace');

  useEffect(() => {
    const afterPaymentSuccess = location.state?.source === 'payment-success';
    if (userNeedsPhoneValidation && afterPaymentSuccess) openPhoneDialog();
  }, [
    location.pathname,
    location.state?.source,
    userNeedsPhoneValidation,
    openPhoneDialog,
  ]);

  const handleAddFilter = (filter: AppliedFilter) =>
    dispatch(addFilter(filter));

  const handleRemoveFilter = (filterId: string) =>
    dispatch(removeFilter(filterId));

  if (isLoading || !data) return <CollectableMarketSkeletons />;
  return (
    <Grid
      data-testid="collectable-tab-content"
      spacing={isTablet ? SpaceSizes.xs : SpaceSizes.md}
      container
    >
      {isDesktop && (
        <Grid xs={isDesktop ? 3 : 12} item>
          <SearchSection
            addFilter={handleAddFilter}
            data={data}
            removeFilter={handleRemoveFilter}
          />
        </Grid>
      )}
      <Grid xs={isDesktop ? 9 : 12} item>
        {/* {!isDesktop && (
          <SearchSection
            addFilter={handleAddFilter}
            data={data}
            removeFilter={handleRemoveFilter}
          />
        )} */}
        <ExternalContent
          filter={{ key: 'page_id', value: 'market_header' }}
          type="pages"
        />
        <BenefitAlert
          available={canUserClaim?.isCouponAvailableForUser || !isLoggedUser}
        />
        {!isLoading && (!data.results || data.results?.length === 0) ? (
          <NoResults
            subtitle={t('noResultsSubtitle.market')}
            title={t('noResultsTitle.market')}
          />
        ) : (
          <SearchResults
            enterWaypoint={enterWaypoint}
            filters={data.appliedFilters}
            isLastPage={isLastPage}
            results={data.results}
          />
        )}
      </Grid>
    </Grid>
  );
};
