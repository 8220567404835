import Clear from '@mui/icons-material/Backspace';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Stack, Tooltip, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AnimatePresence } from 'framer-motion';
import { isNaturalNumber, isNotEmpty } from 'ramda-adjunct';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectUserSession } from '../../../features/session/Session.selector';
import { updateCellphone } from '../../../features/session/Session.slice';
import {
  useCheckSMSCodeMutation,
  useLazyGetSMSTokenQuery,
  usersApiExtractSMSErrors,
  useSendSMSCodeMutation,
} from '../../../features/users/Users.api';
import {
  SMS_ERROR,
  SMS_RECAPTCHA_ERROR,
  SMS_SERVER_ERROR,
} from '../../../features/users/Users.constants';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { isValidCellphone } from '../../../utils/validation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useRecaptcha from '../../hooks/useRecaptcha';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import { Animation } from '../Animate/Animate';
import { AnimationEnum } from '../Animate/Animate.constants';
import { CodeInput } from '../CodeInput/CodeInput';
import { CellphoneInput } from '../form/CellphoneInput/CellphoneInput';
import {
  CODE_EXPIRATION_COOLDOWN,
  PHONE_CHAR_LIMIT,
  RESEND_COOLDOWN,
} from './SmsForm.constants';
import { formatSeconds } from './SmsForm.utils';

export const SmsForm = ({
  setIsSuccess,
}: {
  setIsSuccess?: (status: boolean) => void;
}) => {
  const { t } = useTranslation();
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const user = useAppSelector(selectUserSession);
  const dispatch = useAppDispatch();
  const [errorCode, setErrorCode] = useState<string>('');

  const [resendCooldown, setResendCooldown] = useState<number>(0);
  const [codeExpiration, setCodeExpiration] = useState<number>(0);

  const [focusCode, setFocusCode] = useState<boolean>(false);
  const [isEditOn, setIsEditOn] = useState<boolean>(false);
  const [temporaryPhone, setTemporaryPhone] = useState<string>(
    user?.phone || '',
  );
  const [loadingCode, setLoadingCode] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [cellphone, setCellphone] = useState<string>(user?.phone || '');
  const [prevCellphone, setPrevCellphone] = useState<string>('');
  const [smsSent, setSmsSent] = useState<boolean>(false);
  const [code, setCode] = useState('');
  const [isCodeSuccess, setIsCodeSuccess] = useState<boolean>(false);
  const [isRecaptchaSuccess, setIsRecaptchaSuccess] = useState<boolean>(false);

  const [
    sendSMS,
    {
      data: sendData,
      isSuccess: isSuccessSend,
      isError: isErrorSend,
      isLoading: isLoadingSend,
    },
  ] = useSendSMSCodeMutation();
  const [
    checkSMS,
    { error: checkError, isSuccess: isSuccessCheck, isError: isErrorCheck },
  ] = useCheckSMSCodeMutation();

  const [getSMSToken, { isLoading: getSMSTokenLoading }] =
    useLazyGetSMSTokenQuery();

  const isDisabledSendingSms = useMemo(
    () =>
      !isRecaptchaSuccess || // recapcha is not valid
      user?.phone === `+${cellphone}` || // phone is the same
      !isValidCellphone(`+${cellphone}`) || // phone is not valid
      resendCooldown > 0 || // cooldown is not over
      isEditOn, // edit mode is on
    [cellphone, isEditOn, isRecaptchaSuccess, resendCooldown, user?.phone],
  );

  const location = useLocation();
  const { pathname } = location;

  const clearCode = () => {
    setFocusCode(true);
    setCode('');
    setErrorCode('');
  };

  const handleSend = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    if (!isDisabledSendingSms) {
      setIsLocked(true);
      setResendCooldown(RESEND_COOLDOWN);
      setCodeExpiration(CODE_EXPIRATION_COOLDOWN);

      const { token } = await getSMSToken().unwrap();

      await sendSMS({ phone: `+${cellphone}`, token });
    }
  };

  const handleCodeChange = async (currentCode: string) => {
    if (currentCode?.length <= 6 && isNaturalNumber(Number(currentCode))) {
      setErrorCode('');
      setCode(currentCode);
      setIsLocked(true);
      if (currentCode?.length === 6) {
        setLoadingCode(true);
        try {
          await checkSMS({ code: currentCode });
        } catch (error) {
          if (setIsSuccess) {
            setIsSuccess(false);
          }
          setFocusCode(true);
        } finally {
          setLoadingCode(false);
        }
      }
    }
  };

  const handlePhoneChange = (currentPhone: string) => {
    if (isEditOn) {
      setTemporaryPhone(currentPhone || '');
      setPrevCellphone(cellphone);
    } else {
      setCellphone(currentPhone);
    }
  };

  const startEditNumber = () => {
    if (!loadingCode) {
      setIsEditOn(true);
      setTemporaryPhone(cellphone);
      setIsLocked(false);
    }
  };

  const confirmEdit = () => {
    if (!(temporaryPhone?.length >= PHONE_CHAR_LIMIT)) {
      setIsEditOn(false);
      setCode('');
      setErrorCode('');
      setSmsSent(false);
      setIsLocked(true);
      setSmsSent(false);
      setFocusCode(true);
      setIsCodeSuccess(false);
      setCellphone(temporaryPhone);
    }
  };

  const cancelEdit = () => {
    setIsLocked(true);
    setFocusCode(true);
    setIsEditOn(false);
    setTemporaryPhone(cellphone);
    if (prevCellphone) {
      setCellphone(prevCellphone);
    }
  };

  useRecaptcha({
    onSuccess: () => {
      setIsRecaptchaSuccess(true);
    },
    onFail: () => {
      setIsRecaptchaSuccess(false);
      setErrorCode(SMS_RECAPTCHA_ERROR);
    },
  });

  useEffect(() => {
    const unfocusTimer = setTimeout(() => {
      setFocusCode(false);
    }, 200);
    return () => clearTimeout(unfocusTimer);
  }, [focusCode]);

  useEffect(() => {
    setIsCodeSuccess(isSuccessCheck);
  }, [setIsCodeSuccess, isSuccessCheck]);

  useEffect(() => {
    if (setIsSuccess) {
      setIsSuccess(isCodeSuccess);
    }
  }, [isCodeSuccess, setIsSuccess, cellphone]);

  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setInterval(() => {
        setResendCooldown(resendCooldown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    return () => null;
  }, [resendCooldown]);
  useEffect(() => {
    if (codeExpiration > 0) {
      const timer = setInterval(() => {
        setCodeExpiration(codeExpiration - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    clearCode();
    return () => null;
  }, [codeExpiration]);

  useEffect(() => {
    // Send SMS handler
    if (isSuccessSend && sendData) {
      if (sendData.result === 'SENT') {
        setSmsSent(true);
      } else {
        setErrorCode(SMS_ERROR);
      }
    }
    if (isErrorSend) {
      setErrorCode(SMS_SERVER_ERROR);
    }
  }, [isSuccessSend, isErrorSend, sendData]);

  useEffect(() => {
    if (isErrorCheck && checkError) {
      setErrorCode(usersApiExtractSMSErrors(checkError));
    }
  }, [isErrorCheck, checkError]);

  useEffect(() => {
    if (isSuccessCheck) {
      dispatch(updateCellphone(`+${cellphone}`));
      if (!pathname.includes('onboarding')) {
        navigate('/configuration?alert=smsSuccess');
      }
    }
  }, [isSuccessCheck, cellphone, navigate, dispatch, pathname, setIsSuccess]);

  const theme = useTheme();

  return (
    <form
      style={{ minHeight: isMobile ? 400 : 480 }}
      onSubmit={(e) =>
        handleSend(e as unknown as React.ChangeEvent<HTMLInputElement>)
      }
    >
      <Stack alignItems="center" spacing={SpaceSizes.sm}>
        <Stack sx={{ height: SpaceSizes.xl }}>
          {isNotEmpty(errorCode) && (
            <Animation
              key="alert-success-sms"
              timeProps={{ delay: 0, duration: 0.25 }}
              type={AnimationEnum.Offset}
            >
              <AlertCard
                content={t(`pages.smsVerification.errors.${errorCode}`)}
                id="smsVerificationError"
                show={isNotEmpty(errorCode)}
              />
            </Animation>
          )}

          <AnimatePresence>
            {isCodeSuccess && (
              <Animation
                key="alert-success-sms"
                timeProps={{ delay: 0, duration: 0.25 }}
                type={AnimationEnum.Offset}
              >
                <AlertCard
                  content={t('pages.smsVerification.success')}
                  id="smsVerificationSuccess"
                  severity={AlertSeverity.SUCCESS}
                  show={isCodeSuccess}
                />
              </Animation>
            )}
          </AnimatePresence>
        </Stack>
        <Animation
          key="form-sms"
          timeProps={{ delay: 0, duration: 0.25 }}
          type={AnimationEnum.Offset}
        >
          <Stack alignItems="center" spacing={SpaceSizes.lg}>
            <Stack alignItems="center" spacing={SpaceSizes.sm}>
              <Typography>{t('pages.smsVerification.subtitle')}</Typography>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                sx={{ position: 'relative' }}
              >
                <CellphoneInput
                  cellphone={isEditOn ? temporaryPhone : cellphone}
                  disabled={isLocked}
                  isFocused={isEditOn}
                  placeholder={user?.phone}
                  onChange={handlePhoneChange}
                  onSubmit={handleSend}
                />
                {isSuccessSend && (
                  <Box
                    sx={{
                      position: 'absolute',
                      cursor: 'pointer',
                      right: SpaceSizes.xs,
                      color: theme.palette.primary.dark,
                    }}
                  >
                    {isLocked ? (
                      <Animation
                        key="edit-sms"
                        timeProps={{ delay: 0, duration: 0.25 }}
                        transformProps={{ offsetY: '5%' }}
                        type={AnimationEnum.Offset}
                      >
                        <Box display="flex" flexDirection="row">
                          <Tooltip title={t('pages.smsVerification.edit')}>
                            <EditIcon
                              fontSize="small"
                              sx={{
                                cursor: loadingCode ? 'not-allowed' : 'pointer',
                              }}
                              onClick={startEditNumber}
                            />
                          </Tooltip>
                        </Box>
                      </Animation>
                    ) : (
                      <Animation
                        key="edit-cancel-sms"
                        timeProps={{ delay: 0, duration: 0.25 }}
                        transformProps={{ offsetY: '5%' }}
                        type={AnimationEnum.Offset}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap={SpaceSizes.sm}
                        >
                          <Tooltip
                            title={
                              temporaryPhone?.length >= PHONE_CHAR_LIMIT
                                ? t('pages.smsVerification.blockedConfirm')
                                : t('pages.smsVerification.confirm')
                            }
                          >
                            <CheckCircleIcon
                              fontSize="small"
                              sx={{
                                cursor:
                                  temporaryPhone?.length >= PHONE_CHAR_LIMIT
                                    ? 'not-allowed'
                                    : 'pointer',
                                transition: 'color .5s ease-in-out',
                                color:
                                  temporaryPhone?.length >= PHONE_CHAR_LIMIT
                                    ? theme.palette.secondary.light
                                    : theme.palette.success.light,
                              }}
                              onClick={() => confirmEdit()}
                            />
                          </Tooltip>
                          <Tooltip title={t('pages.smsVerification.cancel')}>
                            <CancelIcon
                              fontSize="small"
                              sx={{
                                color: theme.palette.error.light,
                              }}
                              onClick={() => cancelEdit()}
                            />
                          </Tooltip>
                        </Box>
                      </Animation>
                    )}
                  </Box>
                )}
              </Box>
            </Stack>

            <AnimatePresence>
              {!smsSent && (
                <Animation
                  key="submit-form-sms"
                  timeProps={{ delay: 0.45, duration: 0.85 }}
                  type={AnimationEnum.Offset}
                >
                  {' '}
                  <Tooltip
                    disableHoverListener={resendCooldown === 0}
                    PopperProps={{
                      sx: {
                        display: resendCooldown === 0 ? 'none' : 'inherit',
                      },
                    }}
                    title={`${t(
                      'pages.smsVerification.cooldownStart',
                    )} ${resendCooldown} ${t(
                      `pages.smsVerification.${
                        resendCooldown > 1 ? 'seconds' : 'second'
                      }`,
                    )} ${t('pages.smsVerification.cooldownEnd')}`}
                  >
                    <span
                      style={{
                        cursor:
                          codeExpiration === 0 ? 'not-allowed' : 'pointer',
                      }}
                    >
                      <LoadingButton
                        disabled={isDisabledSendingSms}
                        loading={
                          isLoadingSend && getSMSTokenLoading && !isSuccessSend
                        }
                        variant={
                          resendCooldown === 0 ||
                          !isEditOn ||
                          isValidCellphone(`+${cellphone}`)
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => handleSend()}
                      >
                        {t('pages.smsVerification.sendCode')}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Animation>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {user?.phone && !errorCode && !isCodeSuccess && !smsSent && (
                <Animation
                  key="already-have-phone"
                  sx={{ marginY: SpaceSizes.md, textAlign: 'center' }}
                  timeProps={{ delay: 1, duration: 0.25 }}
                  type={AnimationEnum.Offset}
                >
                  {t('pages.smsVerification.alreadySavedPhone')} {user?.phone}
                </Animation>
              )}
              {smsSent && !isCodeSuccess && (
                <Animation
                  key="form-fill-sms"
                  timeProps={{ delay: 0.6, duration: 0.4 }}
                  type={AnimationEnum.Offset}
                >
                  <Stack
                    alignItems="center"
                    spacing={SpaceSizes.md}
                    sx={{ position: 'relative' }}
                  >
                    <CircularProgress
                      color="inherit"
                      sx={{
                        opacity: !loadingCode ? 0 : 0.5,
                        transition: 'opacity .4s ease-in-out',
                        position: 'absolute',
                        top: -SpaceSizes.md,
                        zIndex: 2,
                      }}
                    />
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <CodeInput
                        code={code}
                        isBlocked={codeExpiration === 0}
                        isError={isNotEmpty(errorCode)}
                        isRefocused={focusCode}
                        onChange={handleCodeChange}
                      />
                      <AnimatePresence>
                        {codeExpiration > 0 && code?.length > 0 && (
                          <Animation
                            key="clear-code-sms"
                            sx={{
                              position: 'absolute',
                              right: isMobile
                                ? -SpaceSizes.lg
                                : -SpaceSizes.lgPlus,
                              top: '25%',
                              cursor: 'pointer',
                            }}
                            timeProps={{ delay: 0.1, duration: 0.15 }}
                            type={AnimationEnum.Offset}
                            onClick={() => clearCode()}
                          >
                            <Tooltip title={t('pages.smsVerification.clear')}>
                              <Clear />
                            </Tooltip>
                          </Animation>
                        )}
                      </AnimatePresence>
                    </Box>
                    {codeExpiration > 0 ? (
                      <Typography textAlign="center" variant="body1">
                        {`${t(
                          'pages.smsVerification.codeExpiration',
                        )} ${formatSeconds(codeExpiration)}`}
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: SpaceSizes.sm,
                        }}
                      >
                        <MobileOffIcon />
                        <Typography textAlign="center" variant="body1">
                          {t('pages.smsVerification.codeExpired')}
                        </Typography>
                      </Box>
                    )}

                    <Stack
                      alignItems="center"
                      direction={isMobile ? 'column' : 'row'}
                      spacing={SpaceSizes.sm}
                    >
                      <Typography>
                        {t('pages.smsVerification.notReceivedCode')}
                      </Typography>
                      <Tooltip
                        PopperProps={{
                          sx: {
                            display: resendCooldown < 1 ? 'none' : 'inherit',
                          },
                        }}
                        title={`${t(
                          'pages.smsVerification.cooldownStart',
                        )} ${resendCooldown} ${t(
                          `pages.smsVerification.${
                            resendCooldown > 1 ? 'seconds' : 'second'
                          }`,
                        )} ${t('pages.smsVerification.cooldownEnd')}`}
                      >
                        <span
                          style={{
                            cursor:
                              resendCooldown > 0 ? 'not-allowed' : 'pointer',
                          }}
                        >
                          <LoadingButton
                            disabled={resendCooldown > 0}
                            loading={
                              isLoadingSend &&
                              getSMSTokenLoading &&
                              !isSuccessSend
                            }
                            sx={{
                              textDecoration: 'underline',
                            }}
                            onClick={() => handleSend()}
                          >
                            {t('pages.smsVerification.reSendCode')}
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Animation>
              )}
            </AnimatePresence>
          </Stack>
        </Animation>
      </Stack>
    </form>
  );
};
