import { ThemeOptions } from '@mui/material';

// Future specific details for this theme
// see ../components/index.ts
const dimayorTheme = {
  palette: {
    background: {
      // just for testing
      default: '#f3f3f3',
      paper: '#1B243E',
    },
  },
} as ThemeOptions;

export default dimayorTheme;
