import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const benefitContainer = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  // gap: { xs: SpaceSizes.sm, sm: SpaceSizes.mdPlus, xl: SpaceSizes.lg },
  // gap: SpaceSizes.sm,
  flexWrap: 'wrap',
  marginTop: SpaceSizes.none,
  paddingX: SpaceSizes.none,
};
