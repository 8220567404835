import { useState } from 'react';

export function useAccordion(initialState: string | false = false) {
  const [selectedAccordion, setSelectedAccordion] = useState<string | false>(
    initialState,
  );

  const handleFilterTypeChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSelectedAccordion(isExpanded ? panel : false);
    };

  return { selectedAccordion, handleFilterTypeChange };
}
