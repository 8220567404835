import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, Divider, Popover, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetNotificationsQuery } from '../../../features/notifications/Notifications.api';
import { selectNotificationsByStatus } from '../../../features/notifications/Notifications.selector';
import { NotificationStatus } from '../../../features/notifications/responses/Notification';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import palette from '../../../theme/palette';
import { MenuIconItem } from '../../components/MenuIconItem/MenuIconItem';
import useNotificationContext from '../../hooks/useNotificationContext';
import { NotificationContext } from './NotificationContext';
import NotificationsDropdownList from './NotificationsDropdownList';

const NotificationsDropdown = () => {
  const [t] = useTranslation();

  const { open, close, isOpen, anchorEl } = useNotificationContext();
  const providerValue = useMemo(() => ({ close }), [close]);
  const id = isOpen ? 'notifications-popover' : undefined;

  // RTKQ queries selecting sub-result from the notification whole result
  const { newNotifications } = useGetNotificationsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      newNotifications: selectNotificationsByStatus(
        NotificationStatus.NEW,
        result?.data,
      ),
    }),
  });

  return (
    <NotificationContext.Provider value={providerValue}>
      <MenuIconItem
        handleOnClick={open}
        popOver={
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            id={id}
            open={isOpen}
            onClose={close}
          >
            <Box p={SpaceSizes.md} width={400}>
              <Stack
                divider={<Divider sx={{ width: '100%' }} />}
                spacing={SpaceSizes.sm}
              >
                <Typography variant="h6">
                  {t('header.userMenu.notifications.title')}
                </Typography>
                <NotificationsDropdownList />
              </Stack>
            </Box>
          </Popover>
        }
        tagId="notifications"
      >
        <Badge
          badgeContent={newNotifications?.length || 0}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: palette.primary.dark,
              fontWeight: 'bold',
            },
          }}
        >
          <NotificationsIcon />
        </Badge>
      </MenuIconItem>
    </NotificationContext.Provider>
  );
};

export default NotificationsDropdown;
