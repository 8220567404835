import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import {
  ADD_SIZE_HEIGHT,
  ADD_SIZE_HEIGHT_SMALLEST,
  ADD_SIZE_WIDTH,
  ADD_SIZE_WIDTH_SMALLEST,
  BALL_ID_PADDING_X,
  FONT_SIZE_BALL_ID,
  FONT_SIZE_PIECE_NUMBER,
  ICON_SIZE_ACTION,
  STATIC_SIZE_HEIGHT,
  STATIC_SIZE_HEIGHT_SMALLEST,
  STATIC_SIZE_WIDTH,
  STATIC_SIZE_WIDTH_SMALLEST,
  VIEW_SIZE_HEIGHT,
  VIEW_SIZE_HEIGHT_SMALLEST,
  VIEW_SIZE_WIDTH,
  VIEW_SIZE_WIDTH_SMALLEST,
} from './CompactCard.constants';
import { CompactCardE, ICompactCard } from './CompactCard.interfaces';

export const CompactCard = ({
  isStatic,
  disabled,
  ballId,
  pieceNumber,
  image,
  type = CompactCardE.ADD,
}: ICompactCard) => {
  const icon = useMemo(() => {
    if (isStatic) return null;

    if (type === CompactCardE.ADD)
      return <ControlPointTwoToneIcon fontSize="inherit" />;

    if (type === CompactCardE.REMOVE)
      return <RemoveCircleOutlineIcon fontSize="inherit" />;

    return null;
  }, [type, isStatic]);

  const isEmpty = useMemo(() => {
    return !(Boolean(ballId) && Boolean(pieceNumber) && Boolean(image));
  }, [ballId, pieceNumber, image]);
  const isSmallestMobile = useSmallMobileMediaQuery();

  const cursorType = useMemo(() => {
    if (disabled) {
      return 'not-allowed';
    }
    if (type === CompactCardE.VIEW) {
      return 'inherit';
    }
    return 'pointer';
  }, [type, disabled]);

  const pieceNumberFontSize = useMemo(() => {
    return type === CompactCardE.VIEW
      ? FONT_SIZE_PIECE_NUMBER.static
      : FONT_SIZE_PIECE_NUMBER.dynamic;
  }, [type]);

  const ballIdFontSize = useMemo(() => {
    return type === CompactCardE.VIEW
      ? FONT_SIZE_BALL_ID.static
      : FONT_SIZE_BALL_ID.dynamic;
  }, [type]);

  const cardHeight = useMemo(() => {
    if (type === CompactCardE.ADD) {
      if (isSmallestMobile) {
        return ADD_SIZE_HEIGHT_SMALLEST;
      }
      return ADD_SIZE_HEIGHT;
    }
    if (isSmallestMobile) {
      return type === CompactCardE.VIEW
        ? STATIC_SIZE_HEIGHT_SMALLEST
        : VIEW_SIZE_HEIGHT_SMALLEST;
    }
    return type === CompactCardE.VIEW ? STATIC_SIZE_HEIGHT : VIEW_SIZE_HEIGHT;
  }, [type, isSmallestMobile]);

  const cardWidth = useMemo(() => {
    if (type === CompactCardE.ADD) {
      if (isSmallestMobile) {
        return ADD_SIZE_WIDTH_SMALLEST;
      }
      return ADD_SIZE_WIDTH;
    }
    if (isSmallestMobile) {
      return type === CompactCardE.VIEW
        ? STATIC_SIZE_WIDTH_SMALLEST
        : VIEW_SIZE_WIDTH_SMALLEST;
    }
    return type === CompactCardE.VIEW ? STATIC_SIZE_WIDTH : VIEW_SIZE_WIDTH;
  }, [isSmallestMobile, type]);

  if (isEmpty) {
    return (
      <Stack
        alignItems="center"
        height={isSmallestMobile ? VIEW_SIZE_HEIGHT_SMALLEST : VIEW_SIZE_HEIGHT}
        justifyContent="center"
        position="relative"
        sx={{ borderRadius: SpaceSizes.xs, overflow: 'visible' }}
        width={isSmallestMobile ? VIEW_SIZE_WIDTH_SMALLEST : VIEW_SIZE_WIDTH}
      />
    );
  }

  return (
    <Stack
      alignItems="center"
      height={cardHeight}
      justifyContent="center"
      position="relative"
      sx={{
        borderRadius: SpaceSizes.xs,
        cursor: cursorType,
        overflow: 'visible',
      }}
      width={cardWidth}
    >
      <Stack
        fontSize={ICON_SIZE_ACTION}
        position="absolute"
        sx={{
          backgroundColor: 'secondary.main',
          borderRadius: SpaceSizes.md,
          top: '-5%',
          left: '-5%',
          zIndex: 2,
          boxShadow: `0px ${SpaceSizes.sm}px ${SpaceSizes.sm}px rgba(0, 0, 0, 0.8)`,
        }}
      >
        {icon}
      </Stack>
      <Stack
        borderRadius={`${SpaceSizes.xs}px 0 0 ${SpaceSizes.xs}px`}
        paddingX={BALL_ID_PADDING_X}
        paddingY={SpaceSizes.xxs}
        position="absolute"
        right={SpaceSizes.xxs}
        sx={{
          zIndex: 2,
          backgroundColor: 'text.primary',
          boxShadow: `0px ${SpaceSizes.smPlus}px ${SpaceSizes.md}px rgba(0, 0, 0, 0.4)`,
        }}
        top="15%"
      >
        <Typography
          color="secondary.main"
          component="p"
          fontSize={pieceNumberFontSize}
          fontWeight={600}
        >
          #{pieceNumber}
        </Typography>
      </Stack>
      {image && (
        <Stack
          border="2px solid"
          borderColor="text.secondary"
          borderRadius={SpaceSizes.xxs}
          height="100%"
          position="absolute"
          sx={{
            backgroundSize: 'auto 100%',
            backgroundImage: `url("${image}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          width="100%"
        />
      )}
      <Stack
        borderRadius={`0 ${SpaceSizes.xs}px ${SpaceSizes.xs}px 0`}
        bottom="15%"
        left={SpaceSizes.xxs}
        paddingX={BALL_ID_PADDING_X}
        paddingY={SpaceSizes.xs}
        position="absolute"
        sx={{
          backgroundColor: 'primary.dark',
          boxShadow: `0px ${SpaceSizes.sm}px ${SpaceSizes.sm}px rgba(0, 0, 0, 0.4)`,
        }}
      >
        <Typography component="p" fontSize={ballIdFontSize} fontWeight={400}>
          #{ballId?.toUpperCase()}
        </Typography>
      </Stack>
    </Stack>
  );
};
