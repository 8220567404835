import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Grow, Stack } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_TYPE, TAG_VALUE } from '../../../../utils/tagger';
import useDesktopMediaQuery from '../../../hooks/useDesktopMediaQuery';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { MenuIconItem } from '../../MenuIconItem/MenuIconItem';
import { SearchBar } from '../SearchBar/SearchBar';
import { SEARCH_DEFAULT_TARGET } from './SearchBall.constants';
import { SearchBallProps } from './SearchBall.types';

export const SearchBall = ({ openSearch, setOpenSearch }: SearchBallProps) => {
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();
  const isDesktop = useDesktopMediaQuery();
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [queryValue, setQueryValue] = useState('');

  const q = useMemo(() => searchParams.get('q') ?? '', [searchParams]);
  const pathName = useMemo(() => location.pathname, [location]);

  useEffect(() => {
    if (pathName.includes('/marketplace')) {
      setQueryValue(q);
    }
  }, [pathName, q, setOpenSearch]);

  const handleClear = () => {
    setQueryValue('');
    if (pathName.includes('/marketplace')) {
      const tab = searchParams.get('tab');
      if (tab !== null) {
        setSearchParams({ tab });
      } else {
        setSearchParams();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryValue(event.target.value);
    if (event.target.value === '') {
      handleClear();
    }
  };

  const handleSearch = useCallback(
    (value: string) => {
      navigate(
        `/marketplace?tab=${
          searchParams.get('tab') || SEARCH_DEFAULT_TARGET
        }&q=${value}`,
      );
    },
    [navigate, searchParams],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(queryValue);
    }
  };

  const handleSearchButton = () => {
    setOpenSearch();
    setQueryValue('');

    const tab = searchParams.get('tab');
    if (tab) setSearchParams(new URLSearchParams({ tab: tab ?? undefined }));

    sendDataToGTM({
      event: TAG_EVENT.SELECT_CONTENT,
      content_type: TAG_TYPE.MENU,
      item_id: openSearch
        ? TAG_VALUE.HEADER.CLOSE_SEARCH
        : TAG_VALUE.HEADER.OPEN_SEARCH,
    });
  };

  const showSearch = useMemo(
    () => openSearch || isNotNilOrEmpty(queryValue),
    [openSearch, queryValue],
  );

  return (
    <Stack
      alignContent="center"
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={SpaceSizes.md}
    >
      {showSearch && (
        <Grow in={showSearch} {...(showSearch ? { timeout: 800 } : {})}>
          <Stack>
            <SearchBar
              data-testid="headerSearchInput"
              placeholder={t('header.searchBar.placeholder')}
              sx={{ width: isDesktop ? '25vw' : '60vw' }}
              value={queryValue}
              onChange={handleChange}
              onClear={handleClear}
              onKeyDown={handleKeyDown}
              onSearch={handleSearch}
            />
          </Stack>
        </Grow>
      )}
      <MenuIconItem
        handleOnClick={handleSearchButton}
        sx={{ minWidth: isMobile ? SpaceSizes.lg : SpaceSizes.xl }}
        tagId="search"
      >
        {!showSearch ? (
          <SearchIcon
            fontSize={isMobile ? 'inherit' : 'medium'}
            sx={{
              backgroundColor: 'background.default',
            }}
          />
        ) : (
          <CloseIcon
            fontSize={isMobile ? 'inherit' : 'medium'}
            sx={{
              backgroundColor: 'background.default',
            }}
          />
        )}
      </MenuIconItem>
    </Stack>
  );
};
