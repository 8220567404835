import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import type { TriviaInfoCardProps } from './TriviaInfoCard.types';

export const TriviaInfoCard = ({
  title,
  image,
  bullets,
}: TriviaInfoCardProps) => {
  return (
    <Stack
      bgcolor="background.paper"
      gap={SpaceSizes.md}
      p={SpaceSizes.md}
      sx={{ borderRadius: '8px' }}
    >
      <Stack alignItems="center" direction="row" gap={SpaceSizes.sm}>
        <ImageFallback
          height="35"
          src={image}
          style={{
            borderRadius: '8px',
            padding: SpaceSizes.xs,
            border: '1px solid',
          }}
          width="35"
        />

        <Typography fontWeight="700" variant="h6">
          {title}
        </Typography>
      </Stack>

      <Stack gap={SpaceSizes.sm}>
        {bullets.map((item) => (
          <Typography>• {item}</Typography>
        ))}
      </Stack>
    </Stack>
  );
};
