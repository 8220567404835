import { Button, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  DIALOG_CTA_PADDING,
  DIALOG_DESCRIPTION_FONT_SIZE,
  DIALOG_TITLE_FONT_SIZE,
} from './Dialog.constants';
import { IDialogProps } from './Dialog.types';

export const DialogCommon = ({
  title,
  description,
  confirm,
  isOpen,
  confirmFn,
  cancelText,
  handleClose,
  extraSx,
  isCentered,
}: IDialogProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { url, message } = confirm || {};

  const onConfirm = () => {
    if (!confirmFn) {
      return url ? navigate(url) : () => null;
    }
    return confirmFn();
  };

  const theme = useTheme();

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby={`alert-dialog-${title}`}
      open={isOpen}
      PaperProps={{
        style: {
          padding: SpaceSizes.sm,
          width: isCentered ? '600px' : 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isCentered ? 'center' : '',
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      }}
      sx={{ ...extraSx, backdropFilter: 'blur(4px)' }}
      onClose={handleClose}
    >
      <DialogTitle
        fontSize={DIALOG_TITLE_FONT_SIZE}
        fontWeight="bold"
        id="alert-dialog-title"
        textAlign="center"
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <DialogContentText
          fontSize={DIALOG_DESCRIPTION_FONT_SIZE}
          id="alert-dialog-description"
          textAlign="center"
          width={isCentered ? { xs: '95%', md: '100%' } : '100%'}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: SpaceSizes.md }}>
        <Button
          sx={{
            padding: DIALOG_CTA_PADDING,
          }}
          onClick={handleClose}
        >
          {cancelText ?? t('pages.collectionDetails.dialog.cancel')}
        </Button>
        <Button
          sx={{
            padding: DIALOG_CTA_PADDING,
          }}
          variant="contained"
          onClick={onConfirm}
        >
          {message}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
