import { useGetBuysMovementsQuery } from '../../../features/movements/Movements.api';
import { GetBuysMovementsApiResponse } from '../../../features/movements/Movements.types';
import createUseDataWithInfiniteScroll from '../shared/createUseDataWithInfiniteScroll';

/**
 * Hook that let us reuse infinite scroll for movements api
 * @returns
 */
const useMyBuysMovementsWithInfiniteScroll =
  createUseDataWithInfiniteScroll<GetBuysMovementsApiResponse>(
    useGetBuysMovementsQuery,
  );

export default useMyBuysMovementsWithInfiniteScroll;
