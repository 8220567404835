export const componentSwiperSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  wide: 'wide',
};

export type ComponentSwiperSizes =
  (typeof componentSwiperSizes)[keyof typeof componentSwiperSizes];

export interface ComponentSwiperProps {
  children: React.ReactNode;
  spaceBetween: number;
  slidesPerView: number | 'auto';
  pagination?: boolean;
}

export interface ComponentSwiperSlideProps extends React.PropsWithChildren {
  children: React.ReactNode;
  size?: string | ComponentSwiperSizes;
  onClick?: () => void;
}
