/* eslint-disable no-console */
import env from '../env/env';
import theme from '../theme';

export const debugInit = () => {
  // DEBUG mode
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.debug) {
    console.log('####### ENV:');
    console.log(env);
    console.log('######');
    console.log('###### THEME:');
    console.log('######');
    console.log(theme);
  }
};
