import { Grid, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AppliedFilter } from '../../../features/searchfilter/responses/SearchResponse';
import {
  addFilter,
  clearFilters,
  removeFilter,
  setMarketplaceFilter,
} from '../../../features/searchfilter/SearchFilter.slice';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { PhoneVerificationDialog } from '../../components/PhoneVerificationDialog/PhoneVerificationDialog';
import { SearchSection } from '../../components/search/SearchSection/SearchSection';
import { StoryReel } from '../../components/Story/StoryReel';
import { useAppDispatch } from '../../hooks';
import useSearchWithInfiniteScroll from '../../hooks/search/useSearchWithInfiniteScroll';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import { CollectableMarketTab } from './CollectableMarketTab';

const Marketplace = () => {
  const { t } = useTranslationByNamespaces('pages.marketplace');
  const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false);

  const [
    searchParams,
    // setSearchParams
  ] = useSearchParams();
  const q = useMemo(() => searchParams.get('q') ?? '', [searchParams]);

  const { data, isLoading, enterWaypoint, isLastPage } =
    useSearchWithInfiniteScroll();

  // const [currentTab, setCurrentTab] = useState<MarketplaceTab>(
  //   MarketplaceTab.COLLECTABLE,
  // );

  const dispatch = useAppDispatch();

  const handleAddFilter = (filter: AppliedFilter) =>
    dispatch(addFilter(filter));

  const handleRemoveFilter = (filterId: string) =>
    dispatch(removeFilter(filterId));

  useEffect(() => {
    // dispatch the search filter to the store if the query parameter "q" changes
    // if (currentTab === MarketplaceTab.COLLECTABLE) {
    dispatch(setMarketplaceFilter(q));
    // } else if (currentTab === MarketplaceTab.TRADE) {
    //   dispatch(setTradesFilter(q));
    // }
    return () => {
      dispatch(clearFilters()); // when unmount clear filters
    };
  }, [
    dispatch,
    // currentTab,
    q,
  ]);

  // useEffect(() => {
  //   const tab = searchParams.get('tab') as MarketplaceTab;
  //   if (tab) {
  //     setCurrentTab(tab);
  //   }
  // }, [currentTab, searchParams]);

  // const { t } = useTranslationByNamespaces('pages.marketplace');
  // const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false);
  // const navigate = useNavigate();
  // const growthbook = useGrowthBook();
  // const MARKETPLACE_TABS: MarketplaceTab[] = [
  //   MarketplaceTab.COLLECTABLE,
  // ].concat(growthbook?.isOn(WEB3_FEATURES) ? [MarketplaceTab.TRADE] : []);

  // const handleTabChange = (
  //   event: React.SyntheticEvent,
  //   newValue: MarketplaceTab,
  // ) => {
  //   // this will change the query params depending if our tabs
  //   if (searchParams) {
  //     setSearchParams();
  //   }
  //   navigate(`?tab=${newValue}`);
  //   // this change the current tab to the selected by the user
  //   setCurrentTab(newValue);
  // };

  // const displayTrades = useMemo(
  //   () => growthbook?.isOn(SHOW_TRADES) && growthbook?.isOn(WEB3_FEATURES),

  //   [growthbook],
  // );

  const isDesktop = useDesktopDeviceMediaQuery();

  return (
    <PageContainer
      id="marketplacePage"
      rightChild={
        !isDesktop && data ? (
          <SearchSection
            addFilter={handleAddFilter}
            data={data}
            removeFilter={handleRemoveFilter}
          />
        ) : undefined
      }
      title={t('pageTitle')}
      trackCode={TAG_EVENT.CONTENT_GROUP.MARKETPLACE}
    >
      <StoryReel />
      <Stack
        data-testid="marketplace-results"
        spacing={SpaceSizes.sm}
        width="100%"
      >
        <Grid width="100%" container>
          <Grid width="100%" xs={12} item>
            <CollectableMarketTab
              data={data}
              enterWaypoint={enterWaypoint}
              isLastPage={isLastPage}
              isLoading={isLoading}
              openPhoneDialog={() => setOpenPhoneDialog(true)}
            />
          </Grid>
        </Grid>
      </Stack>
      <PhoneVerificationDialog
        handleClose={() => {
          setOpenPhoneDialog(false);
        }}
        open={openPhoneDialog}
      />
    </PageContainer>
  );
};

export default Marketplace;
