import { LoadingButton } from '@mui/lab';
import { Dialog, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import {
  WARNING_MODAL_DESCRIPTION,
  WARNING_MODAL_TITLE,
  WARNING_MODAL_WIDTH,
} from './ConfirmWarningModal.constants';
import { ConfirmWarningModalProps } from './ConfirmWarningModal.interfaces';

export const ConfirmWarningModal = ({
  isOpen,
  onClose,
  onSubmit,
  externalComponent,
  isLoading,
  namespaces,
}: ConfirmWarningModalProps) => {
  const { t } = useTranslationByNamespaces(namespaces);

  const submitAndClose = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }
    onClose();
  }, [onSubmit, onClose]);

  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          alignItems: 'center',
          paddingY: SpaceSizes.mdPlus,
          border: `1px solid ${theme.palette.primary.dark}`,
          width: WARNING_MODAL_WIDTH,
        },
      }}
      sx={{ backdropFilter: 'blur(4px)' }}
      onClose={onClose}
    >
      <Stack
        alignItems="center"
        gap={SpaceSizes.lg}
        justifyContent="center"
        width="70%"
      >
        <Stack alignItems="center" gap={SpaceSizes.sm}>
          <Typography
            component="h2"
            fontSize={WARNING_MODAL_TITLE}
            textAlign="center"
          >
            {t('title')}
          </Typography>
          <Typography
            component="h2"
            fontSize={WARNING_MODAL_DESCRIPTION}
            textAlign="center"
          >
            {t('description')}
          </Typography>
        </Stack>
        {externalComponent}
        <Stack
          direction="row"
          gap={SpaceSizes.sm}
          justifyContent={onSubmit ? 'space-between' : 'center'}
          width="100%"
        >
          <LoadingButton
            data-testid="cancel-warn-modal"
            loading={isLoading}
            sx={{
              fontSize: WARNING_MODAL_DESCRIPTION,
              paddingY: SpaceSizes.sm,
              paddingX: SpaceSizes.lg,
            }}
            variant="outlined"
            onClick={onClose}
          >
            {t('cancel')}
          </LoadingButton>
          {onSubmit && (
            <LoadingButton
              data-testid="confirm-warn-modal"
              loading={isLoading}
              sx={{
                fontSize: WARNING_MODAL_DESCRIPTION,
                paddingY: SpaceSizes.sm,
                paddingX: SpaceSizes.lg,
              }}
              variant="contained"
              onClick={submitAndClose}
            >
              {t('submit')}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};
