import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import Panel from '../Panel/Panel';

export const ProductEditionInfo = ({
  maxSupply,
  onSale,
}: {
  maxSupply?: number;
  onSale?: number;
}) => {
  const isMobile = useMobileMediaQuery();
  const { t } = useTranslation();

  return (
    <Panel
      sx={{
        p: SpaceSizes.md,
        justifyContent: 'center',
      }}
    >
      <Stack justifyContent="center" spacing={SpaceSizes.lg}>
        {/* <Typography align="left" variant="cH4">
          {t('components.productEdition.limited_edition.title')}
        </Typography> */}
        <Typography align="left">
          {t('components.productEdition.limited_edition.description')}
        </Typography>

        <Stack alignItems="center" gap={SpaceSizes.sm} justifyContent="center">
          <Typography
            borderBottom="2px solid"
            borderColor="text.primary"
            component="span"
            fontWeight="bold"
            textAlign="center"
            variant={isMobile ? 'h4' : 'h6'}
            width="70%"
          >
            {onSale}/{maxSupply}
          </Typography>
          <Typography component="span" textAlign="center" variant="body2">
            {t(
              isMobile
                ? 'components.productEdition.limited_edition.on_sale'
                : 'components.productEdition.limited_edition.on_sale',
            )}
            /
            {t(
              isMobile
                ? 'components.productEdition.limited_edition.max_supply'
                : 'components.productEdition.limited_edition.max_supply',
            )}
          </Typography>
        </Stack>
      </Stack>
    </Panel>
  );
};
