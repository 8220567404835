import { Stack } from '@mui/material';
import React from 'react';

import env from '../../../env/env';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { StoryBubble } from './StoryBubble';

export const StoryPreview = ({
  story,
  clickFn,
  isStatic = false,
}: {
  clickFn?: () => void;
  story: string;
  isStatic?: boolean;
}) => {
  const { t } = useTranslationByNamespaces('pages.home.stories');

  if (!story) {
    return null;
  }
  return (
    <Stack
      alignItems="center"
      data-testid={`story-bubble-${story}`}
      height="100%"
      justifyContent="center"
      position={isStatic ? 'absolute' : 'relative'}
      sx={{
        cursor: 'pointer',
        backgroundColor: 'neutral.800',
      }}
      width="100%"
      zIndex={100}
      onClick={clickFn}
    >
      <Stack>
        <StoryBubble
          isLoading={isStatic}
          preview={`${env.REACT_APP_STATIC_BASE_URL}marketplace/stories/${story}/prev.png`}
          title={t(`title.${story}`)}
        />
      </Stack>
    </Stack>
  );
};
