import { Stack } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';

import { OfferedItem } from '../../../features/trades/Trades.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { CompactCard } from '../../components/CompactCard/CompactCard';
import {
  VIEW_SIZE_HEIGHT,
  VIEW_SIZE_HEIGHT_SMALLEST,
  VIEW_SIZE_WIDTH,
  VIEW_SIZE_WIDTH_SMALLEST,
} from '../../components/CompactCard/CompactCard.constants';
import { CompactCardE } from '../../components/CompactCard/CompactCard.interfaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import {
  MIN_OFFER_ITEMS,
  TRADE_GRID_GAP,
} from './TradeRequestShowcase.constants';
import { TradeRequestShowcaseProps } from './TradeRequestShowcase.interfaces';
import { scaleIn } from './TradeRequestShowcase.motion';
import { chunkArray } from './TradeRequestShowcase.utils';

const StackMotion = motion(Stack);

const TradeRequestShowcase = ({
  items = [],
  removeOffer,
  minimum = MIN_OFFER_ITEMS,
  isStatic = false,
}: TradeRequestShowcaseProps) => {
  const isSmallestMobile = useSmallMobileMediaQuery();

  const ITEMS_ARR: OfferedItem[] = Array.from({
    length: items
      ? items?.length > minimum
        ? items?.length
        : minimum
      : minimum,
  });

  const itemGroups = useMemo(() => {
    const emptyItemsNeeded =
      minimum - items.length > 0 ? minimum - items.length : 0;
    const emptyItems = new Array(emptyItemsNeeded).fill({});
    const filledItems = [...items, ...emptyItems];
    return chunkArray(filledItems, 2);
  }, [items, minimum]);

  const IS_SINGLE = ITEMS_ARR.length === 1;

  const conditionalBorder = useMemo(() => {
    if (IS_SINGLE) {
      return 'none';
    }
    return '2px dotted';
  }, [IS_SINGLE]);

  const getType = useCallback(
    (item?: OfferedItem) => {
      if (IS_SINGLE) {
        return CompactCardE.VIEW;
      }
      if (!item) {
        return CompactCardE.EMPTY;
      }
      return CompactCardE.REMOVE;
    },
    [IS_SINGLE],
  );

  const cardWidth = useMemo(() => {
    return isSmallestMobile ? VIEW_SIZE_WIDTH_SMALLEST : VIEW_SIZE_WIDTH;
  }, [isSmallestMobile]);

  const cardHeight = useMemo(() => {
    return isSmallestMobile ? VIEW_SIZE_HEIGHT_SMALLEST : VIEW_SIZE_HEIGHT;
  }, [isSmallestMobile]);

  return (
    <Stack direction="column" gap={TRADE_GRID_GAP} justifyContent="center">
      <AnimatePresence mode="popLayout">
        {itemGroups.map((itemGroup, groupIndex) => (
          <Stack
            key={`grouped-${
              itemGroup[groupIndex]?.product_id || `empty-${groupIndex}`
            }`}
            direction="row"
            gap={TRADE_GRID_GAP}
          >
            {itemGroup.map((item, index) => (
              <StackMotion
                key={item?.product_id || `${index}-${Date.now()}`}
                animate="visible"
                data-testid={`offer-removable-${Boolean(removeOffer)}`}
                exit={item?.product_id ? 'hidden' : 'visible'}
                initial={item?.product_id ? 'hidden' : 'visible'}
                variants={scaleIn}
                onClick={() =>
                  removeOffer &&
                  removeOffer(item?.piece_number, item?.product_id)
                }
              >
                {item?.product_id ? (
                  <CompactCard
                    ballId={item?.ball_id}
                    disabled={isStatic}
                    image={item?.player?.image}
                    isStatic={isStatic}
                    pieceNumber={item?.piece_number}
                    type={getType(item)}
                  />
                ) : (
                  <Stack
                    alignItems="center"
                    border={conditionalBorder}
                    borderColor="text.secondary"
                    borderRadius={SpaceSizes.xs}
                    height={cardHeight}
                    justifyContent="center"
                    overflow="visible"
                    position="relative"
                    width={cardWidth}
                  />
                )}
              </StackMotion>
            ))}
          </Stack>
        ))}
      </AnimatePresence>
    </Stack>
  );
};

export default TradeRequestShowcase;
