import Env from '../../../env/env';
import { api, RTKTags } from '../../api/Api';
import { SearchResponse } from '../responses/SearchResponse';

export const matchFilterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchByMatch: builder.query<SearchResponse, string>({
      query: (matchId) => {
        return `${Env.REACT_APP_BASE_API_URL_SEARCH}search/matches/${matchId}`;
      },
      keepUnusedDataFor: 0,
      providesTags: () => [RTKTags.SearchByMatch],
    }),
  }),
});

export const { useSearchByMatchQuery } = matchFilterApi;
