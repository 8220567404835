import { Stack, Typography } from '@mui/material';
import React from 'react';

import Env from '../../../env/env';
import { selectEarnedPoints } from '../../../features/referrals/Referrals.selectors';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import { PageError } from '../../components/PageError/PageError';
import { ReferFriend } from '../../components/ReferFriend/ReferFriend';
import { ReferMyGolpoints } from '../../components/ReferMyGolPoints/ReferMyGolpoints';
import { ReferQuestions } from '../../components/ReferQuestions/ReferQuestions';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { ReferralsSkeleton } from './Referrals.skeleton';
import { useFetchData } from './useFetchData';

const backgroundReferrals = `${Env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/referrals/background-referrals.png`;
const referralsIcon = `${Env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/referrals/referrals-icon.svg`;

const Referrals = () => {
  const { t } = useTranslationByNamespaces('pages.referrals');
  const isSmallestMobile = useSmallMobileMediaQuery();

  const { isLoading, isError, isErrorReferralCode, isUninitialized, data } =
    useFetchData();
  const { referralCodeResponse, summaryResponse } = data;
  if (isError) return <PageError errorCode={t('errors.GENERIC_ERROR')} />;
  if (isLoading || isUninitialized || !summaryResponse) {
    return <ReferralsSkeleton />;
  }

  return (
    <PageContainer
      id="referralsPage"
      maxWidth="md"
      trackCode={TAG_EVENT.CONTENT_GROUP.REFERRALS}
      backButton
    >
      <Stack
        padding={SpaceSizes.md}
        style={{
          backgroundImage: `url(${backgroundReferrals})`,
          width: '100%',
        }}
      >
        <Stack gap={SpaceSizes.md}>
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={SpaceSizes.sm}
            pt={{ xs: SpaceSizes.md, md: SpaceSizes.lg }}
          >
            <Typography
              fontWeight="600"
              variant={isSmallestMobile ? 'cH3' : 'h5'}
            >
              {t('pageTitle')}
            </Typography>
            <ImageFallback
              fallback={referralsIcon}
              height={50}
              src={referralsIcon}
              width={50}
            />
          </Stack>
          <Typography variant={isSmallestMobile ? 'body2' : 'body1'}>
            {t('description')}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        bgcolor="background.paper"
        gap={SpaceSizes.md}
        padding={SpaceSizes.md}
      >
        {isErrorReferralCode ? (
          <Typography>{t('errors.404NOTFOUND')}</Typography>
        ) : (
          referralCodeResponse?.url && (
            <ReferFriend link={referralCodeResponse?.url} />
          )
        )}
        <Stack gap={SpaceSizes.md}>
          <ReferMyGolpoints golPoints={selectEarnedPoints(summaryResponse)} />
          <ReferQuestions />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default Referrals;
