/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Box, Chip, Stack, styled, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { useGetHomeQuery } from '../../../features/home/Home.api';
import {
  selectHome,
  selectHomeAmbassadors,
  selectHomeCollectablesProducts,
  selectHomeFeatured,
  selectHomeFeaturedProducts,
  selectHomeNextMatches,
  selectHomeSaleAndAuctions,
  selectHomeTeamProducts,
  selectHomeTeamRankings as selectHomeTeamsRanking,
  selectHomeTopLeagues,
  selectHomeTopScorers,
} from '../../../features/home/Home.selectors';
import {
  AmbassadorsContent,
  FeaturedContent,
  Multimedia,
  ProductContent,
  TeamsRanking,
  TeamsRankingMultiLeagueContent,
  TopLeaguesContent,
  TopScorersContent,
} from '../../../features/home/Home.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getReferralCookie } from '../../../utils/cookies';
import { TAG_EVENT } from '../../../utils/tagger';
import { Animation } from '../../components/Animate/Animate';
import { AnimationEnum } from '../../components/Animate/Animate.constants';
import { ComponentSwiper } from '../../components/ComponentSwiper/ComponentSwiper';
import { componentSwiperSizes } from '../../components/ComponentSwiper/ComponentSwiper.types';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { HomeCard } from './HomeCard';
import { ProductSlider } from './ProductSlider';

const HomeSectionTitle = styled(Typography)({
  color: 'white',
  fontSize: '20px',
  textAlign: 'left',
  fontWeight: 900,
  textTransform: 'uppercase',
  marginBottom: SpaceSizes.sm, // Using shitty margin because flex breaks the swiper
});

const componentSizes = {
  [componentSwiperSizes.sm]: { width: '105px', height: '136px' },
  [componentSwiperSizes.md]: { width: '141px', height: '185px' },
  [componentSwiperSizes.lg]: { width: '358px', height: '474px' },
  [componentSwiperSizes.wide]: { width: '458px', height: '240px' },
};

const Home = () => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const referralCode = getReferralCookie() ?? params.get('ref');
  const {
    isLoading,
    featured,
    topScorers,
    nextMatches,
    topLeagues,
    teamsRanking,
    teamProducts,
    collectablesProducts,
    featuredProducts,
    salesAndAuctions,
    ambassadors,
  } = useGetHomeQuery(
    { referralCode },
    {
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        home: selectHome(data),
        featured: selectHomeFeatured(data),
        topScorers: selectHomeTopScorers(data),
        nextMatches: selectHomeNextMatches(data),
        topLeagues: selectHomeTopLeagues(data),
        teamsRanking: selectHomeTeamsRanking(data),
        teamProducts: selectHomeTeamProducts(data),
        collectablesProducts: selectHomeCollectablesProducts(data),
        featuredProducts: selectHomeFeaturedProducts(data),
        salesAndAuctions: selectHomeSaleAndAuctions(data),
        ambassadors: selectHomeAmbassadors(data),
      }),
    },
  );

  const findImage = useCallback(
    (multimedia: Multimedia[], forceDesktop?: boolean) =>
      multimedia
        ? {
            location: multimedia.find(
              (item) =>
                item.type ===
                (isTablet && !forceDesktop
                  ? 'BANNER_MOBILE'
                  : 'BANNER_DESKTOP'),
            )?.location,
          }
        : { location: undefined },
    [isTablet],
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('contact') === 'true' && window.Intercom) {
      window.Intercom('show');
    }
  }, [location]);

  const sendDataToGTM = useGTMDispatch();

  const handleGoTo = useCallback(
    (url: string, section: string, idx: number, refID: string) => {
      // Log Data to GTM

      sendDataToGTM({
        event: TAG_EVENT.NEW_HOME,
        content_type: section,
        index: idx,
        ref_id: refID,
      });

      if (url.includes('http')) {
        window.location.href = url;
      } else {
        navigate(url);
      }
    },
    [navigate, sendDataToGTM],
  );

  const sliderStyles = useCallback(
    (clickable: boolean, size: string, isAuto = false) => ({
      cursor: clickable ? 'pointer' : 'default',
      width: '100%',
      maxWidth: size === 'lg' ? '100%' : componentSizes[size].width,
      height: isAuto ? 'auto' : componentSizes[size].height,
    }),
    [],
  );

  return (
    <PageContainer
      id="homePage"
      loading={isLoading}
      trackCode={TAG_EVENT.CONTENT_GROUP.HOME}
    >
      <Stack gap={SpaceSizes.lg}>
        {isTablet && (
          <Animation
            key="menu-chips-animation"
            timeProps={{ duration: 0.85 }}
            transformProps={{ offsetX: '-15%' }}
            type={AnimationEnum.Offset}
          >
            <Box width="100%">
              <Stack
                direction="row"
                gap={{ xs: SpaceSizes.sm, sm: SpaceSizes.md }}
              >
                <Chip
                  label={t('pages.home.menu.teams')}
                  onClick={() => navigate('/matches')}
                />
                <Chip
                  label={t('pages.home.menu.collections')}
                  onClick={() => navigate('/collections')}
                />
                <Chip
                  label={t('pages.home.menu.market')}
                  onClick={() => navigate('/marketplace?tab=collectables')}
                />
              </Stack>
            </Box>
          </Animation>
        )}
        <Box width="100%">
          <Animation
            key="featured-animation"
            timeProps={{ duration: 0.85 }}
            transformProps={{ offsetX: '-15%' }}
            type={AnimationEnum.Offset}
          >
            <ComponentSwiper slidesPerView="auto" spaceBetween={50} pagination>
              {(featured?.content as FeaturedContent[])?.map(
                (featuredItem, index) => (
                  <SwiperSlide
                    key={featuredItem?.feat_id}
                    style={sliderStyles(
                      !!featuredItem?.link,
                      isMobile
                        ? componentSwiperSizes.wide
                        : featured?.size || componentSwiperSizes.lg,
                    )}
                    onClick={() => {
                      if (featuredItem.type === 'IMAGE')
                        handleGoTo(
                          featuredItem?.link,
                          'home_featured',
                          index,
                          featuredItem?.feat_id,
                        );
                    }}
                  >
                    {({ isActive }) => (
                      <HomeCard
                        coverImageUrl={
                          isMobile
                            ? featuredItem?.cover_mobile_image
                            : featuredItem?.cover_web_image
                        }
                        imgSize="cover"
                        index={featured?.show_ranking ? index + 1 : undefined}
                        isActive={isActive}
                        location={
                          isMobile
                            ? featuredItem.mobile
                            : isTablet
                            ? featuredItem.tablet
                            : featuredItem.desktop
                        }
                        title={featuredItem?.alt_text || ''}
                        type={featuredItem.type}
                      />
                    )}
                  </SwiperSlide>
                ),
              )}
            </ComponentSwiper>
          </Animation>
        </Box>

        {/* Featured Products */}
        {featuredProducts &&
          featuredProducts.content &&
          featuredProducts.content.length > 0 && (
            <ProductSlider
              handleGoTo={handleGoTo}
              nextMatches={nextMatches}
              prods={featuredProducts?.content as ProductContent[]}
              title={t('pages.home.sections.featuredProducts')}
            />
          )}
        {/* Sale & Auction Products */}
        {salesAndAuctions &&
          salesAndAuctions.content &&
          salesAndAuctions.content.length > 0 && (
            <ProductSlider
              handleGoTo={handleGoTo}
              nextMatches={nextMatches}
              prods={salesAndAuctions?.content as ProductContent[]}
              title={t('pages.home.sections.salesAndAuctions')}
            />
          )}
        {/* Collectables */}
        {collectablesProducts &&
          collectablesProducts.content &&
          collectablesProducts.content.length > 0 && (
            <ProductSlider
              handleGoTo={handleGoTo}
              nextMatches={nextMatches}
              prods={collectablesProducts?.content as ProductContent[]}
              title={t('pages.home.sections.collectablesProducts')}
            />
          )}

        {/** !!nextMatches?.content?.length && (
          <Box width="100%">
            <Animation
              key="next-matches-animation"
              timeProps={{ delay: 0.45, duration: 0.85 }}
              transformProps={{ offsetX: '-15%' }}
              type={AnimationEnum.Offset}
              delayUntilVisible
            >
              <HomeSectionTitle>
                {t('pages.home.sections.nextMatches')}
              </HomeSectionTitle>
              <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
                {(nextMatches?.content as NextMatchesContent[])?.map(
                  (nextMatch, index) => (
                    <SwiperSlide
                      key={nextMatch?.id}
                      style={sliderStyles(
                        !!nextMatch?.link,
                        nextMatches?.size || componentSwiperSizes.md,
                      )}
                      onClick={() =>
                        handleGoTo(
                          nextMatch.link,
                          'home_nextMatches',
                          index,
                          nextMatch?.id,
                        )
                      }
                    >
                      <HomeCard
                        index={
                          nextMatches?.show_ranking ? index + 1 : undefined
                        }
                        location={
                          findImage(nextMatch?.multimedia, true).location || ''
                        }
                        noCover
                      />
                    </SwiperSlide>
                  ),
                )}
              </ComponentSwiper>
            </Animation>
          </Box>)
          */}
        <Box width="100%">
          <Animation
            key="top-scorer-animation"
            timeProps={{ duration: 0.85 }}
            transformProps={{ offsetX: '-15%' }}
            type={AnimationEnum.Offset}
            delayUntilVisible
          >
            <HomeSectionTitle>
              {t('pages.home.sections.topScorers')}
            </HomeSectionTitle>
            <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
              {(topScorers?.content as TopScorersContent[])?.map(
                (scorerItem, index) => (
                  <SwiperSlide
                    key={scorerItem?.id}
                    style={sliderStyles(
                      !!scorerItem?.link,
                      topScorers?.size || componentSwiperSizes.sm,
                    )}
                    onClick={() => {
                      handleGoTo(
                        scorerItem?.link,
                        'home_topScorers',
                        index,
                        scorerItem?.id,
                      );
                    }}
                  >
                    <HomeCard
                      cardName={
                        scorerItem?.known_name ||
                        `${scorerItem?.short_first_name?.substring(0, 1)}. ${
                          scorerItem.short_last_name || ''
                        }`
                      }
                      imgSize="cover"
                      index={topScorers?.show_ranking ? index + 1 : undefined}
                      location={
                        findImage(scorerItem?.multimedia)?.location || ''
                      }
                    />
                  </SwiperSlide>
                ),
              )}
            </ComponentSwiper>
          </Animation>
        </Box>
        <Box width="100%">
          <Animation
            key="top-leagues-animation"
            timeProps={{ duration: 0.85 }}
            transformProps={{ offsetX: '-15%' }}
            type={AnimationEnum.Offset}
            delayUntilVisible
          >
            <HomeSectionTitle>
              {t('pages.home.sections.leagues')}
            </HomeSectionTitle>
            <Stack display="flex" flexDirection="row">
              <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
                {(topLeagues?.content as TopLeaguesContent[])?.map(
                  (topLeague, index) => (
                    <SwiperSlide
                      key={topLeague?.id}
                      style={sliderStyles(
                        !!topLeague?.link,
                        componentSwiperSizes.md,
                        true,
                      )}
                      onClick={() => {
                        handleGoTo(
                          topLeague?.link,
                          'home_teamsRanking',
                          index,
                          topLeague?.id,
                        );
                      }}
                    >
                      <HomeCard
                        imgSize="contain"
                        location={topLeague.location}
                      />
                    </SwiperSlide>
                  ),
                )}
              </ComponentSwiper>
            </Stack>
          </Animation>
        </Box>
        <Box width="100%">
          <Animation
            key="teams-ranking-multi-league-animation"
            timeProps={{ duration: 0.85 }}
            transformProps={{ offsetX: '-15%' }}
            type={AnimationEnum.Offset}
            delayUntilVisible
          >
            <Stack gap={SpaceSizes.md}>
              {(teamsRanking?.content as TeamsRankingMultiLeagueContent[])?.map(
                (teamRanking) =>
                  teamRanking.teams && (
                    <Stack key={`team-ranking-${teamRanking.league_id}`}>
                      <HomeSectionTitle>
                        {t('pages.home.sections.teams', {
                          leagueId: teamRanking.league_id,
                        })}
                      </HomeSectionTitle>
                      <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
                        {teamRanking.teams?.map((team: TeamsRanking, index) => (
                          <SwiperSlide
                            key={team?.id}
                            style={sliderStyles(
                              !!team?.link,
                              teamsRanking?.size || componentSwiperSizes.sm,
                            )}
                            onClick={() => {
                              handleGoTo(
                                team?.link,
                                'home_teamsRankingMultiLeague',
                                index,
                                team?.id,
                              );
                            }}
                          >
                            <HomeCard
                              imgSize="cover"
                              index={
                                teamRanking?.show_ranking
                                  ? index + 1
                                  : undefined
                              }
                              location={team.location || ''}
                            />
                          </SwiperSlide>
                        ))}
                      </ComponentSwiper>
                    </Stack>
                  ),
              )}
            </Stack>
          </Animation>
        </Box>

        <Box width="100%">
          <Stack alignItems={isMobile ? 'flex-start' : 'center'} width="100%">
            <Animation
              key="ambassadors-animation"
              timeProps={{ duration: 0.85 }}
              transformProps={{ offsetX: '-15%' }}
              type={AnimationEnum.Offset}
              delayUntilVisible
            >
              <HomeSectionTitle
                sx={{ textAlign: isMobile ? 'left' : 'center' }}
              >
                {t('pages.home.sections.ambassadors')}
              </HomeSectionTitle>
              <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
                {(ambassadors?.content as AmbassadorsContent[])?.map(
                  (ambassador) => (
                    <SwiperSlide
                      key={ambassador.name}
                      style={sliderStyles(
                        !!ambassador,
                        ambassadors?.size || componentSwiperSizes.sm,
                      )}
                    >
                      <HomeCard
                        cardName={ambassador.name}
                        imgSize="cover"
                        location={ambassador.link}
                        title={ambassador.name}
                      />
                    </SwiperSlide>
                  ),
                )}
              </ComponentSwiper>
            </Animation>
          </Stack>
        </Box>
      </Stack>
    </PageContainer>
  );
};

export default Home;
