import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { IRowProps } from './Row.types';

export const Row = ({
  collapsibleChild,
  rowsContent,
  backgroundColor,
  index,
  isLast = false,
}: IRowProps) => {
  const [openCollapsibleItem, setOpenCollapsibleItem] = useState(false);
  const isMobile = useMobileMediaQuery();
  return (
    <>
      <TableRow>
        {!!collapsibleChild && (
          <TableCell key={`collapsibleIcon-${index}`}>
            <IconButton
              size="small"
              onClick={() => setOpenCollapsibleItem(!openCollapsibleItem)}
            >
              {openCollapsibleItem ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}

        {rowsContent.map((row, columnIndex) => (
          <TableCell
            // eslint-disable-next-line react/no-array-index-key
            key={`row-${index}-column-${columnIndex}`}
            align={row.align || 'inherit'}
            colSpan={row.colSpan || 1}
            scope="row"
            sx={{
              ...row?.containerStyle,
              paddingX: isMobile ? 0 : SpaceSizes.md,
              paddingY: isMobile ? SpaceSizes.smPlus : SpaceSizes.md,
              borderBottomColor: isLast ? 'transparent' : 'text.secondary',
            }}
          >
            {typeof row.content === 'string' ? (
              <Typography sx={row?.contentStyle}>{row.content}</Typography>
            ) : (
              row.content
            )}
          </TableCell>
        ))}
      </TableRow>

      {!!collapsibleChild && (
        <TableRow
          sx={{
            backgroundColor: `${backgroundColor}`,
          }}
        >
          <TableCell
            key={`collapsibleContent-${index}`}
            colSpan={rowsContent.length || 1}
            style={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <Collapse in={openCollapsibleItem} timeout="auto" unmountOnExit>
              <Box sx={{ margin: SpaceSizes.xs }}>{collapsibleChild}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
