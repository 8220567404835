import { Grid, Stack } from '@mui/material';
import React from 'react';

import { selectCurrentBrand } from '../../../features/brand/Brand.selector';
import { selectUserSession } from '../../../features/session/Session.selector';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_TYPE, TAG_VALUE } from '../../../utils/tagger';
import { useAppSelector } from '../../hooks';
import {
  HEADER_LOGO_HEIGHT_DESKTOP,
  HEADER_LOGO_WIDTH_DESKTOP,
} from '../../layout/Header/Header.constants';
import { LanguageMenu } from '../../layout/Header/LanguageMenu/LanguageMenu';
import { Link } from '../Link/Link';
import Logo from '../Logo/Logo';
import { Comunity } from './Community';
import { CopyrightAndTyc } from './CopyrightAndTyc';
import { FooterLinks } from './FooterLinks';

const Footer = () => {
  const user = useAppSelector(selectUserSession);
  const logo = useAppSelector(selectCurrentBrand)?.logo;
  return (
    <Stack
      component="footer"
      data-testid="footer"
      spacing={SpaceSizes.sm}
      sx={{
        p: SpaceSizes.lg,
        bgcolor: 'background.paper',
      }}
    >
      <Stack direction="row">
        <Link
          tagId={TAG_VALUE.FOOTER.LOGO}
          tagSectionId={TAG_TYPE.MENU_FOOTER}
          to="/home"
        >
          <Logo
            $height={HEADER_LOGO_HEIGHT_DESKTOP}
            $width={HEADER_LOGO_WIDTH_DESKTOP}
            src={logo}
          />
        </Link>
      </Stack>
      <Grid justifyContent="flex-start" rowSpacing={SpaceSizes.lg} container>
        <Grid md={8} xs={12} item>
          <FooterLinks />
        </Grid>
        <Grid md={4} sm={6} xs={12} item>
          <Comunity />
        </Grid>
        <Grid xs={12} container item>
          <Grid item>
            <LanguageMenu user={user} />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <CopyrightAndTyc />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Footer;
