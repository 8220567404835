import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { setGlobalAlert } from '../../../../features/alert/Alert.slice';
import { useDeleteBookmarkMutation } from '../../../../features/searchfilter/bookmarks/BookmarkedFilter.api';
import { BookmarkItem } from '../../../../features/searchfilter/responses/BookmarkItem';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import DEFAULT_LOGO from '../../../assets/img/default_logo_club.png';
import { ImageFallback } from '../../../components/ImageFallback/ImageFallback';
import { useAppDispatch } from '../../../hooks';

interface FavsProps {
  key: string;
  bookmark: BookmarkItem;
}

const FavsDropdownItem = ({ bookmark, ...props }: FavsProps) => {
  const { t } = useTranslation();
  const [
    deleteBookmark,
    {
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteBookmarkMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    deleteBookmark(bookmark.product_id);
  };

  const navigateToProductDetails = useCallback(() => {
    navigate(`/product/${bookmark.product_id}`);
  }, [navigate, bookmark]);

  useEffect(() => {
    if (!isDeleteLoading) {
      if (isDeleteError) {
        dispatch(
          setGlobalAlert({
            type: 'error',
            message: t('alert.deleteBookmarkError'),
          }),
        );
      }
      if (isDeleteSuccess) {
        dispatch(
          setGlobalAlert({
            type: 'success',
            message: t('alert.deleteBookmarkSuccess'),
          }),
        );
      }
    }
  }, [isDeleteLoading, dispatch, isDeleteError, isDeleteSuccess, t]);

  return (
    <Box {...props} margin={SpaceSizes.sm}>
      <Grid spacing={SpaceSizes.xs} container>
        <Grid
          sx={{ cursor: 'pointer' }}
          xs={3.5}
          item
          onClick={navigateToProductDetails}
        >
          <Stack
            alignItems="center"
            direction="row"
            height="100%"
            justifyContent="center"
            spacing={SpaceSizes.sm}
          >
            <ImageFallback
              alt="Home club"
              fallback={DEFAULT_LOGO}
              height="33"
              src={bookmark.home_logo}
              width="33"
            />
            <Typography>{t('components.productCard.versus')}</Typography>
            <ImageFallback
              alt="Away club"
              fallback={DEFAULT_LOGO}
              height="33"
              src={bookmark.away_logo}
              width="33"
            />
          </Stack>
        </Grid>

        <Grid
          marginLeft={SpaceSizes.sm}
          sx={{ cursor: 'pointer' }}
          xs={7}
          item
          onClick={navigateToProductDetails}
        >
          <Stack alignItems="start" justifyContent="center">
            <Typography fontWeight="bold">{bookmark.title}</Typography>
            <Typography>
              {`${t('header.userMenu.favorites.jornada')} ${bookmark.week}`}
            </Typography>
          </Stack>
        </Grid>

        <Grid xs={1} item>
          <IconButton
            title={t('header.userMenu.favorites.delete')}
            onClick={handleClick}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FavsDropdownItem;
