import { Stack, Typography } from '@mui/material';
import React from 'react';

import {
  selectIsTriviaPlayerAnswered,
  selectIsTriviaPlayerEliminated,
  selectIsTriviaPlayerEliminatedForNotRespond,
  selectProductAssigment,
  selectProductIsOwned,
  selectProductIsTriviaBeforeLastRound,
  selectProductIsTriviaFinished,
  selectProductIsTriviaLastRound,
  selectProductIsTriviaNotStarted,
  selectProductIsTriviaStarted,
  selectProductIsTriviaWinner,
  selectProductIsUserAbleToPlayTrivia,
  selectProductShowFinishedComponent,
} from '../../../features/products/Product.selectors';
import { IProduct } from '../../../features/products/Product.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import Panel from '../Panel/Panel';
import { TriviaCountdown } from '../Trivia/TriviaCountdown';
import TriviaFinishedNotWinner from '../Trivia/TriviaFinishedNotWinner';
import { TriviaWinner } from '../Trivia/TriviaWinner';
import AnswerTriviaButton from './AnswerTriviaButton';
import TriviaPlayerEliminated from './TriviaPlayerEliminated';

interface TriviaStartedBannerProps {
  product?: IProduct;
  isOwner?: boolean;
}

const TriviaDetails = ({
  product,
  isOwner = false,
}: TriviaStartedBannerProps) => {
  const { t } = useTranslationByNamespaces('pages.product.trivia');

  const assignment = selectProductAssigment(product);
  const isTriviaPlayerAnswered = selectIsTriviaPlayerAnswered(product);
  const isTriviaPlayerEliminated = selectIsTriviaPlayerEliminated(product);
  const isTriviaPlayerEliminatedForNotRespond =
    selectIsTriviaPlayerEliminatedForNotRespond(product);

  const isOwned = selectProductIsOwned(product);
  const isUserAbleToPlayTrivia = selectProductIsUserAbleToPlayTrivia(product);
  const isTriviaNotStarted = selectProductIsTriviaNotStarted(product);
  const isTriviaWinner = selectProductIsTriviaWinner(product);
  const isTriviaFinished = selectProductIsTriviaFinished(product);
  const showWinnerComponent = selectProductShowFinishedComponent(product);
  const isTriviaLastRound = selectProductIsTriviaLastRound(product);
  const isTriviaBeforeLastRound = selectProductIsTriviaBeforeLastRound(product);
  const isTriviaStarted = selectProductIsTriviaStarted(product);

  return (
    <Stack alignItems="center" gap={SpaceSizes.sm} justifyContent="center">
      {isOwner && isTriviaStarted && !isUserAbleToPlayTrivia && (
        <AlertCard
          content={t(`started.notPlayer`)}
          id="triviaNotPlayer"
          severity={AlertSeverity.INFO}
          show
        />
      )}
      {isTriviaPlayerEliminated ? (
        <TriviaPlayerEliminated text="triviaPlayerEliminated" />
      ) : isTriviaPlayerEliminatedForNotRespond ? (
        <TriviaPlayerEliminated text="triviaPlayerEliminatedForNotRespond" />
      ) : showWinnerComponent ? (
        isTriviaWinner ? (
          <TriviaWinner />
        ) : (
          isTriviaFinished && (
            <TriviaFinishedNotWinner
              nickname={assignment?.assigned_to?.nickname ?? ''}
            />
          )
        )
      ) : (
        <>
          {isTriviaNotStarted && assignment?.start_date && (
            <TriviaCountdown
              assigmentStartDate={assignment?.start_date}
              isOwned={isOwned ?? false}
            />
          )}

          {isUserAbleToPlayTrivia && (
            <Panel>
              <Stack alignItems="center" gap={SpaceSizes.sm}>
                <Stack flexDirection="row" gap={SpaceSizes.sm}>
                  <Typography fontWeight="bold">
                    {t(
                      isTriviaLastRound && isTriviaPlayerAnswered
                        ? 'lastRound.lastRoundTitle'
                        : 'started.triviaAnswerTitle',
                    )}
                  </Typography>
                </Stack>
                <Typography textAlign="center">
                  {isTriviaPlayerAnswered
                    ? isTriviaBeforeLastRound
                      ? t('lastRound.answeredRightBeforeLastRound')
                      : isTriviaLastRound
                      ? t('lastRound.answeredLastRound')
                      : t('started.waitingForQuestion')
                    : isTriviaLastRound
                    ? t('lastRound.notAnsweredLastRound')
                    : t('started.readyQuestion')}
                </Typography>

                {!(isTriviaLastRound && isTriviaPlayerAnswered) && (
                  <Stack alignItems="center" justifyContent="center">
                    <AnswerTriviaButton
                      battleId={assignment?.trivia?.battle_id}
                      disabled={isTriviaPlayerAnswered}
                    />
                  </Stack>
                )}
              </Stack>
            </Panel>
          )}
        </>
      )}
    </Stack>
  );
};

export default TriviaDetails;
