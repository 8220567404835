import { useGrowthBook } from '@growthbook/growthbook-react';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePostSyncUserTransfersMutation } from '../../../features/transfer/Transfer.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { WEB3_FEATURES } from '../../../utils/growthbookFeatures';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { DialogFeedback } from '../DialogFeedback/DialogFeedback';
import { ReceiveModal } from '../ReceiveModal/ReceiveModal';
import { isVenlyOutageDefined } from '../VenlyOutageModal/utils';
import { VenlyOutageModal } from '../VenlyOutageModal/VenlyOutageModal';
import { IVenlyOutage } from '../VenlyOutageModal/VenlyOutageModal.types';
import { IBallsOwned } from './BallsOwned.constant';

export const BallsOwned = ({ isLoading, isTransferringCard }: IBallsOwned) => {
  const { t } = useTranslationByNamespaces('pages.myBuys.header');
  const [isReceiveOpen, setIsReceiveOpen] = useState<boolean>(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState<boolean>(false);
  const [isVenlyOutageModalOpen, setIsVenlyOutageModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const [postSyncUserTransfers, { isLoading: isSyncing, isError }] =
    usePostSyncUserTransfersMutation();

  const growthbook = useGrowthBook();

  const venlyOutage = growthbook?.getFeatureValue(
    'venly_outage',
    {},
  ) as IVenlyOutage;

  const handleSync = useCallback(async () => {
    if (isVenlyOutageDefined(venlyOutage)) {
      setIsVenlyOutageModalOpen(true);
    } else {
      await postSyncUserTransfers();
      // refresh
      navigate(0);
    }
  }, [venlyOutage, navigate, postSyncUserTransfers]);

  const handleReceive = useCallback(() => {
    if (isVenlyOutageDefined(venlyOutage)) {
      setIsVenlyOutageModalOpen(true);
    } else {
      setIsReceiveOpen(true);
    }
  }, [venlyOutage]);

  useEffect(() => {
    if (isError) setIsSyncModalOpen(true);
  }, [isError, setIsSyncModalOpen]);

  return (
    <Stack alignItems="center" gap={SpaceSizes.xs} justifyContent="center">
      {isVenlyOutageDefined(venlyOutage) && (
        <VenlyOutageModal
          endDate={venlyOutage.outage_end_date}
          endTime={venlyOutage.outage_end_time}
          isOpen={isVenlyOutageModalOpen}
          setIsOpen={setIsVenlyOutageModalOpen}
          startDate={venlyOutage.outage_start_date}
          startTime={venlyOutage.outage_start_time}
        />
      )}

      {growthbook?.isOn(WEB3_FEATURES) && (
        <>
          <ReceiveModal
            handleClose={() => setIsReceiveOpen(false)}
            isOpen={isReceiveOpen}
          />
          <Stack
            direction="row"
            gap={SpaceSizes.sm}
            justifyContent="center"
            position="relative"
            width="80%"
          >
            <Button
              data-testid="receive-button-open"
              id="receieve"
              sx={{
                maxWidth: SpaceSizes.xxl * 2,
              }}
              variant="contained"
              fullWidth
              onClick={handleReceive}
            >
              <Typography variant="cButton">{t('ctaReceive')}</Typography>
            </Button>
            <LoadingButton
              disabled={isTransferringCard}
              id="sync"
              loading={isLoading || isSyncing}
              sx={{
                maxWidth: SpaceSizes.xxl * 2,
              }}
              variant="contained"
              fullWidth
              onClick={handleSync}
            >
              <Typography variant="cButton">{t('ctaSync')}</Typography>
            </LoadingButton>
            <DialogFeedback
              content=""
              handleClose={() => setIsSyncModalOpen(false)}
              icon="error"
              isOpen={isSyncModalOpen}
              title={t('error')}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
