import { createSlice } from '@reduxjs/toolkit';

export interface CountriesState {
  countryFilter: string;
}

const initialState = {
  countryFilter: '',
} as CountriesState;

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    showAllCountries: (state) => {
      state.countryFilter = 'all';
    },
  },
});

export const { showAllCountries } = countriesSlice.actions;

export default countriesSlice.reducer;
