import { Typography, useTheme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import type { GolHelperTextProps } from './GolHelperText.types';

export const GolHelperText = ({ value, testId }: GolHelperTextProps) => {
  const theme = useTheme();

  return (
    <Typography
      data-testid={testId}
      fontSize={12}
      sx={{ color: theme.palette.error.main, paddingInline: SpaceSizes.sm }}
    >
      {value}
    </Typography>
  );
};
