import { getStorage } from './storage';

const getJWTToken = () => {
  const session = getStorage()?.getItem('session');

  if (session) {
    return JSON.parse(session).jwtToken;
  }
  return null;
};

export default getJWTToken;
