import { useCallback, useEffect, useState } from 'react';

import {
  IPieceCollected,
  IProduct,
} from '../../../../features/products/Product.types';
import { usePostDeliverUserNFTMutation } from '../../../../features/transfer/Transfer.api';
import { SUCCESS_MESSAGE_DURATION } from './useDeliver.constants';

type TDeliveryError = 'unminted' | 'unassigned' | 'generic' | null;

export const useDeliver = (results?: IProduct[] | undefined) => {
  const [
    postDeliverUserNFT,
    { data, isLoading: isLoadingPostDeliver, isError, isSuccess, error },
  ] = usePostDeliverUserNFTMutation();
  const [isProcessingModalOpen, setIsProcessingModalOpen] =
    useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<TDeliveryError>(null);
  const [isTransferringCard, setIsTransferringCard] = useState<boolean>(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState<boolean>(false);

  const handleDeliver = async (
    target: string,
    productId: string,
    pieceNumber: number,
    isMinted: boolean,
  ) => {
    if (!isMinted) {
      setErrorType('unminted');
      return;
    }

    setIsProcessing(true);
    setIsProcessingModalOpen(true);
    try {
      await postDeliverUserNFT({
        recipient_address: target,
        product_id: productId,
        piece_number: pieceNumber,
      });
      setIsTransferringCard(true);
    } catch {
      setErrorType('generic');
      setIsTransferringCard(false);
    } finally {
      setIsProcessingModalOpen(false);
    }
  };

  useEffect(() => {
    if (isError && error) {
      setIsTransferringCard(false);
      setIsProcessing(false);
      setIsProcessingModalOpen(false);
      const parsedError = error as { data: { code: string } };
      const errorCode = parsedError?.data?.code;
      if (errorCode === 'NOT_MINTED') {
        setErrorType('unminted');
        return;
      }
      if (errorCode === 'NOT_ASSIGNED') {
        setErrorType('unassigned');
        return;
      }
      setErrorType('generic');
    }
  }, [isError, error]);

  const handleSuccess = useCallback(() => {
    setIsSuccessVisible(true);
    const cleanup = setTimeout(() => {
      setIsSuccessVisible(false);
    }, SUCCESS_MESSAGE_DURATION);

    return () => clearTimeout(cleanup);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      handleSuccess();
    }
  }, [data, isSuccess, handleSuccess]);

  useEffect(() => {
    if (errorType) {
      setIsErrorModalOpen(true);
    }
  }, [errorType]);

  useEffect(() => {
    if (results) {
      const pieces = results.map((card: IProduct) => card.pieces).flat();
      const transferStatus = pieces?.map(
        (piece: IPieceCollected | undefined) => piece && piece.transfer_status,
      );
      setIsTransferringCard(
        transferStatus?.some(
          (transfer: string | undefined) => transfer === 'PENDING',
        ),
      );
      return;
    }
    setIsTransferringCard(false);
  }, [results]);

  const closeError = () => {
    setIsErrorModalOpen(false);
    const cleanup = setTimeout(() => {
      setErrorType(null);
      setIsTransferringCard(false);
    }, 500);
    return () => clearTimeout(cleanup);
  };
  const closeProcessingModal = () => setIsProcessingModalOpen(false);

  const isDeliveryModalDisabled = isLoadingPostDeliver || isProcessing;

  return {
    isErrorModalOpen,
    isProcessingModalOpen,
    handleDeliver,
    errorType,
    closeError,
    closeProcessingModal,
    isSuccessVisible,
    isDeliveryModalDisabled,
    isTransferringCard,
  };
};
