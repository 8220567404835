import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, Stack, useTheme } from '@mui/material';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import BlockchainIcon from '../../assets/img/blockchain.svg';
import PolygonscanIcon from '../../assets/img/polygon.svg';
import {
  COLLECTABLES_ADDITIONAL_ANCHOR,
  COLLECTABLES_ADDITIONAL_TRANSFORM,
  COLLECTABLES_ICON_SIZE,
} from './CollectableAdditionalInfo.constants';
import { ICollectableAdditionalInfo } from './CollectableAdditionalInfo.types';
import { CollectableAdditionalInfoItem } from './CollectableAdditionalInfoItem';

const CollectableAdditionalInfo = ({
  contractAddress,
  transactionAddress,
}: ICollectableAdditionalInfo) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const contractAddressLink = useMemo(() => {
    if (!contractAddress) {
      return null;
    }
    return { url: contractAddress, icon: PolygonscanIcon, alt: 'PolygonScan' };
  }, [contractAddress]);

  const transactionAddressLink = useMemo(() => {
    if (!transactionAddress) {
      return null;
    }
    return { url: transactionAddress, icon: BlockchainIcon, alt: 'Blockchain' };
  }, [transactionAddress]);

  const theme = useTheme();

  return (
    <Stack justifyContent="center">
      <IconButton
        size="medium"
        sx={{
          height: COLLECTABLES_ICON_SIZE,
          width: COLLECTABLES_ICON_SIZE,
          borderRadius: SpaceSizes.xxs,
          border: `1px solid ${theme.palette.primary.main}`,
        }}
        onClick={handleButtonClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={COLLECTABLES_ADDITIONAL_ANCHOR}
        open={Boolean(anchorEl)}
        transformOrigin={COLLECTABLES_ADDITIONAL_TRANSFORM}
        onClose={handleMenuClose}
      >
        {contractAddressLink && (
          <CollectableAdditionalInfoItem
            key={contractAddressLink.alt}
            closeMenu={handleMenuClose}
            icon={contractAddressLink.icon}
            text={contractAddressLink.alt}
            url={contractAddressLink.url}
          />
        )}
        {transactionAddressLink && (
          <CollectableAdditionalInfoItem
            key={transactionAddressLink.alt}
            closeMenu={handleMenuClose}
            icon={transactionAddressLink.icon}
            text={transactionAddressLink.alt}
            url={transactionAddressLink.url}
          />
        )}
      </Menu>
    </Stack>
  );
};

export default CollectableAdditionalInfo;
