import { Border } from './borders.types';

export default {
  none: '0px',
  min: '1px',
  xxs: '2px',
  xs2: '3px',
  xs: '4px',
  sm: '8px',
  smPlus: '12px',
  md: '16px',
  mdPlus: '24px',
  lg: '32px',
  lgPlus: '48px',
  xl: '64px',
  xlPlus: '96px',
  xxl: '128px',
  xxl2: '180px',
} as Border;
