import { useEffect } from 'react';

import { useGetShoppingCartQuery } from '../../../features/shoppingCart/ShoppingCart.api';
import { syncCart } from '../../../features/shoppingCart/ShoppingCart.slice';
import { Items } from '../../components/CardsCart/CardsCart.interfaces';
import { useAppDispatch } from '../../hooks';

export const useFetchData = () => {
  const dispatch = useAppDispatch();

  const {
    data: shoppingCartData,
    isLoading: shoppingCartIsLoading,
    isError: shoppingCartIsError,
    isUninitialized: shoppingCartIsUninitialized,
  } = useGetShoppingCartQuery();

  useEffect(() => {
    if (shoppingCartData) {
      dispatch(syncCart(shoppingCartData as unknown as Items[]));
    }
  }, [shoppingCartData, dispatch]);

  const data = {
    shoppingCartResponse: shoppingCartData,
  };

  return {
    isLoading: shoppingCartIsLoading,
    isError: shoppingCartIsError,
    isUninitialized: shoppingCartIsUninitialized,
    data,
  };
};
