import { ThemeOptions } from '@mui/material';

import env from '../env/env';
import dimayorTheme from '../theme/brands/dimayorTheme';

export interface BrandConfig {
  id: string;
  name: string;
  theme?: ThemeOptions;
  language: string;
  logo: string;
  logo_2x: string;
}

const brandOptions: BrandConfig[] = [
  {
    id: 'default',
    name: 'Gol-Ball',
    language: 'es',
    logo: `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/logos/logo_v2_en.png`,
    logo_2x: `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/logos/logo_v2_en_desktop.png`,
  },
  {
    id: 'dimayor',
    name: 'Dimayor',
    language: 'es',
    logo: `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/logos/logo_v2_en.png`,
    logo_2x: `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/logos/logo_v2_en_desktop.png`,
    theme: dimayorTheme,
  },
  {
    id: 'laliga',
    name: 'Liga Santander',
    language: 'es',
    logo: `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/logos/logo_v2_en.png`,
    logo_2x: `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/golball/logos/logo_v2_en_desktop.png`,
  },
];

export default brandOptions;
