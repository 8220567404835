import { createSelector } from '@reduxjs/toolkit';
import { compose, isEmpty, prop, sortBy, toLower } from 'ramda';

import { RootState } from '../../app/store';
import { BrandConfig } from '../../brands/brandOptions';
import { selectCurrentBrand } from '../brand/Brand.selector';
import {
  AppliedFilter,
  FilterRequest,
  SearchResponse,
} from './responses/SearchResponse';
import { SearchFilterState } from './SearchFilter.slice';

export const selectSearchFilter = (state: RootState): SearchFilterState =>
  state.searchFilter;

export const selectMatchResults = (
  searchResponse: SearchResponse | undefined,
) => searchResponse?.match_result;

const sortByIdFilter = sortBy<AppliedFilter>(compose(toLower, prop('id')));

export const selectSearchPath = createSelector(
  selectSearchFilter,
  selectCurrentBrand,
  (search: SearchFilterState, currentBrand: BrandConfig | undefined) => {
    const appliedFilters: FilterRequest[] = sortByIdFilter(
      search.appliedFilters,
    ).map((f) => ({
      id: f.id,
      value: f.value,
      valueLabel: f.valueLabel,
    }));

    const sortFilter: FilterRequest = {
      id: 'sort',
      value: search.appliedSort.id,
      valueLabel: '',
    };

    const query = search.searchQuery;

    return {
      appliedFilters,
      sortFilter,
      query,
      currentBrand,
    };
  },
);

export const selectSearchIsSkipped = createSelector(
  selectSearchFilter,
  (search: SearchFilterState) =>
    search?.appliedFilters?.length === 0 && isEmpty(search?.searchQuery),
);
