import Env from '../../env/env';
import { api } from '../api/Api';
import { DeliverRequest, PolygonWalletResponse } from './Transfer.types';

export const transferApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserPolygonAddress: builder.query<PolygonWalletResponse, undefined>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_WEB3}web3/wallet`,
        method: 'GET',
      }),
    }),
    getUsersNewCards: builder.query<string, number>({
      query: (userId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_WEB3}sync/${userId}`,
        method: 'GET',
      }),
    }),
    postSyncUserTransfers: builder.mutation<void, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_WEB3}web3/wallet/sync`,
        method: 'POST',
      }),
    }),
    postDeliverUserNFT: builder.mutation<string, DeliverRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_WEB3}web3/transfer`,
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const {
  useGetUserPolygonAddressQuery,
  useGetUsersNewCardsQuery,
  usePostSyncUserTransfersMutation,
  usePostDeliverUserNFTMutation,
} = transferApi;
