import { ICollectionsGroup } from '../../app/pages/collections/CollectionCard.types';
import Env from '../../env/env';
import { api } from '../api/Api';
import {
  CollectionItemsRequest,
  CollectionListItem,
  CollectionsByGroupRequest,
  ICollectionItems,
} from './Collections.types';

export const collectionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // CollectionsByGroup
    getCollectionsByGroup: builder.query<
      CollectionListItem[],
      CollectionsByGroupRequest
    >({
      query: ({ collectionGroupId }) => {
        return `${Env.REACT_APP_BASE_API_URL_COLLECTIONS}collections/groups/${collectionGroupId}`;
      },
    }),
    // CollectionsGroups
    getCollectionsGroups: builder.query<ICollectionsGroup[], null>({
      query: () => {
        return `${Env.REACT_APP_BASE_API_URL_COLLECTIONS}collections/groups/`;
      },
    }),
    // CollectionItems
    getCollectionItems: builder.query<
      ICollectionItems[],
      CollectionItemsRequest
    >({
      query: ({ collectionId }) => {
        return `${Env.REACT_APP_BASE_API_URL_COLLECTIONS}collections/${collectionId}`;
      },
    }),
  }),
});

export const {
  useGetCollectionsByGroupQuery,
  useGetCollectionsGroupsQuery,
  useGetCollectionItemsQuery,
} = collectionsApi;
