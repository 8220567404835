export enum EMatchStatus {
  Fixture = 'Fixture',
  Playing = 'Playing',
}
export interface CommonClub {
  id: string;
  code?: string;
  name: string;
  group_id?: string;
  score?: number;
  logo: string;
}

export interface Tournament {
  id: string;
  season: string;
  commercial_name: string;
}

export interface Competition {
  id: string;
  league_id: string;
  name: string;
  official_name: string;
}

export interface Stage {
  id: string;
  name: string;
}

export type Home = CommonClub;
export type Away = CommonClub;

export interface Match {
  id: string;
  is_live: boolean;
  dt: Date;
  away: Away;
  home: Home;
  stadium: Stadium;
  week: string;
  tournament: Tournament;
  competition: Competition;
  stage: Stage;
}

export interface ProductFile {
  file_id: string;
  product_id: string;
  location: string;
  thumb_location: string;
  order_id: number;
}

export const ProductMultimediaTypeEnum = {
  VIDEO_GOAL: 'VIDEO_GOAL',
  VIDEO: 'VIDEO',
  CHAIN_OF_CUSTODY: 'CHAIN_OF_CUSTODY',
  THREED: '3D_BALL',
  THREED_CERTIFICATE: '3D_CERTIFICATE',
  PLAYER: 'PLAYER',
  SELFIE_STICKERS: 'SELFIE_STICKERS',
  WHATSAPP_STICKERS: 'WHATSAPP_STICKERS',
  GOAL_SOUND: 'GOAL_SOUND',
  SIGNED_BALL: 'SIGNED_BALL',
};

export type ProductMultimediaType =
  (typeof ProductMultimediaTypeEnum)[keyof typeof ProductMultimediaTypeEnum];

export interface ProductMultimedia {
  type: ProductMultimediaType;
  files: ProductFile[];
}

export type TEventTypes =
  | 'OWN_GOAL'
  | 'CHAIN_OF_CUSTODY'
  | 'SIGN'
  | 'BALL_SIGNATURE'
  | 'GOAL'
  | 'INITIAL_BALL'
  | 'FAILED_PENALTY'
  | 'LOST'
  | 'BROKEN';

export interface EventData {
  away_partial_score: number;
  home_partial_score: number;
  is_own_goal: boolean;
}
export interface ProductEvent {
  event_id: string;
  event_minute_in_match: number;
  event_data: EventData;
  event_dt: string;
  event_type: TEventTypes;
  event_type_icon: string;
  description?: string;
  event_files: ProductFile[];
}

export interface Player {
  image: string;
  name: string;
  first_name: string;
  last_name: string;
  team_id: string;
  team: string;
  team_logo: string;
  team_group_id: string;
}

export interface SaleStatus {
  id: 'active' | 'finished' | 'canceled' | 'paused';
  label: string;
}

export interface Stadium {
  stadium_id: string;
  stadium_name: string;
  stadium_icon: string;
  stadium_image: string;
  stadium_background: string;
}

export interface Price {
  value: string;
  currency: PriceCurrency;
  symbol: string;
  coins: string;
  is_free: boolean;
}

export type PriceCurrency = 'USD' | 'EUR' | 'GOL';

export interface Multimedia {
  type: ProductMultimediaType;
  files: ProductFile[];
}

export interface ITacticMultimedia {
  season: string;
  competition: string;
  event: string;
  match: string;
}

export interface TournamentType {
  id: string;
  official_name: string;
  trade_name: string;
}

export interface League {
  id: string;
  name: string;
  logo: string;
}

export interface ProductLogo {
  default: string;
}

export type TProductStatus = 'new' | 'completed' | 'finished' | 'deactivated';

export interface Collectibles {
  on_sale: number;
  total: number;
  sold_out?: boolean;
}

export interface ProductContract {
  contract_address_opensea?: string;
  minted: number;
  max_supply: number;
  contract_address: string;
}

export interface IPieceCollected {
  product_id: string;
  is_new: boolean;
  create_date: string;
  piece_number: number;
  status: string;
  is_tradeable?: boolean;
  is_free?: boolean;
  is_assigned?: boolean;
  transfer_status: string;
  current_trade: TradePieces;
}

export interface ProductContentI18n {
  lang_id: string;
  vars: ProductContent[];
}

export interface ProductContent {
  id: string;
  title: string;
  content: string;
}

export enum TradeStatus {
  OPENED = 'opened',
  IN_PROGRESS = 'in_progress',
  PENDING = 'PENDING',
}

export enum EBallType {
  ORIGINAL = 'original',
  NONE = 'none',
}

export enum EAssignmentType {
  DEFAULT = 'default',
  TRIVIA = 'trivia',
  AUCTION = 'auction',
  NONE = 'none',
}

export enum ETriviaBattleStatus {
  NOT_STARTED = 'not_started',
  STARTED = 'started',
  FINISHED = 'finished',
}

export enum ETriviaQuestionStatus {
  WRONG = 'wrong',
  RIGHT = 'right',
  NOT_ANSWERED = 'not_answered',
}

export enum ETriviaContestantStatus {
  PLAYING = 'playing',
  ELIMINATED = 'eliminated',
  WINNER = 'winner',
}

export interface IProduct {
  product_id_winner?: string;
  ball_type: EBallType;
  product_id: string;
  product_type: string;
  pieces?: IPieceCollected[];
  last_piece_number: number;
  product_status: TProductStatus;
  created_at?: Date;
  player: Player;
  league: League;
  match: Match;
  multimedia: Multimedia[];
  events: ProductEvent[];
  tags: string[];
  logos: ProductLogo;
  collectibles: Collectibles;
  price: Price;
  product_entity_id: string;
  bookmarked: boolean;
  has_uploaded_selfie: boolean;
  ball_name: string;
  contract: ProductContract;
  opta_widget: ITacticMultimedia;
  backdrop_url?: string;
  selfie_url?: string;
  market_status?: string;
  assignment: Assignment;
  contents: ProductContentI18n[];
  single_assignment_tx_url?: string;
  web2wallet_tx_id?: string;
  current_trade?: TradePieces;
  is_owned: boolean;
}

export interface TradePieces {
  trade_id?: string;
  trade_status?: TradeStatus;
}

export interface Assigned {
  display: boolean;
  is_self: boolean;
  nickname: string;
}

export interface Assignment {
  assigned_at: string;
  assigned_to: Assigned | undefined;
  due_date?: string;
  piece_number: string;
  start_date?: string;
  status?: ETriviaBattleStatus;
  type: string;
  trivia?: Trivia;
}

export interface Trivia {
  remaining_days: number;
  current_round: number;
  remaining_rounds: number;
  battle_id?: string;
  battle_status: ETriviaBattleStatus;
  contestant: {
    not_seen_previous_question: boolean;
    status: ETriviaContestantStatus;
    current_question_status: ETriviaQuestionStatus;
    current_lives: number;
  };
}

export interface IProductRequest {
  productId: string;
  context?: string;
}

export interface IProductByBallRequest {
  ballId: string;
  context?: string;
}

export interface MatchFiles {
  web: string;
  mobile: string;
}

export interface MatchesResponse {
  inLastWeek: boolean;
  week: string;
  season: string;
  matches: MatchItem[];
  brand_id?: string;
}

export interface MatchesRequest {
  week: string;
  brandId: string;
}

export interface MatchItem {
  id: string;
  is_live: boolean;
  away?: Away;
  home?: Home;
  date: string;
  time: string;
  status: EMatchStatus;
  teams: Teams;
  week: string;
  multimedia: MatchMultimedia[];
  active_presale: boolean;
  description?: string;
  first_active_presale?: string;
}

export interface Teams {
  home: Team;
  away: Team;
}

export interface Team {
  id: string;
  logo: string;
  name: string;
  code: string;
  score: number;
}

export interface MatchMultimedia {
  type: string;
  location: string;
}

export interface Week {
  stage_key: string;
  stage_id: string;
  stage_name: string;
  is_current: boolean;
  week: string;
}

export interface IPolygonscanLinkRequest {
  product_id: string;
  piece_number: number;
}

export interface BenefitsPlatform {
  id: string;
  image: string;
  icon?: string;
  title: string;
  description: string;
  shortDescription?: string;
  actionUrl?: string;
  bullets?: string[];
}

export interface BenefitsPlatforms {
  web: BenefitsPlatform[];
  mobile: BenefitsPlatform[];
}

export interface BenefitsInfoRequest {
  lang: string;
}
