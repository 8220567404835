export const RANK_LIMIT = 20;
export const THROPHIES_CANDIDATES = 3;

interface TrophiesI {
  [key: number]: string;
}

export const TROPHIES: TrophiesI = {
  1: 'Gold',
  2: 'Silver',
  3: 'Bronze',
};
