import { useTheme } from '@mui/material';
import { AnimatePresence, motion, MotionStyle, useInView } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  AnimationColor,
  AnimationEnum,
  assignStyleColor,
} from './Animate.constants';
import { IAnimationProps } from './Animate.types';
import {
  assignStyleBorder,
  assignTransitionBorder,
  assignVariants,
  assignVariantsBorder,
} from './Animate.utils';
import { boilerplateMotion, delayedAnimation } from './Variants.utils';

export const Animation = ({
  children,
  stopped = false,
  delayUntilVisible = false,
  timeProps,
  transformProps,
  type = AnimationEnum.Common,
  color = AnimationColor.WHITE,
  hasHover,
  instantBorder,
  instantBorderDelay = 1200,
  sx,
  onClick,
}: IAnimationProps) => {
  const [displaySelector, setDisplaySelector] = useState<boolean>(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const assignHover = useCallback(
    (status: boolean) => {
      if (hasHover) {
        setDisplaySelector(status);
      }
    },
    [hasHover, setDisplaySelector],
  );

  const handleMouse = (newStatus: boolean) => {
    if (!instantBorder) {
      assignHover(newStatus);
    }
  };

  useEffect(() => {
    if (instantBorder) {
      setDisplaySelector(true);
      const enterDelay = setTimeout(() => {
        setDisplaySelector(false);
      }, instantBorderDelay);
      return () => clearTimeout(enterDelay);
    }
    return () => null;
  }, [instantBorder, instantBorderDelay]);

  const assignTransition = delayedAnimation(
    timeProps || { delay: 0.5, duration: 0.5 },
  );

  const conditionalInView = delayUntilVisible ? isInView : true;

  const theme = useTheme();

  return (
    <motion.span
      ref={ref}
      {...boilerplateMotion}
      style={{ position: 'relative', ...sx }}
      transition={assignTransition}
      variants={assignVariants(type, conditionalInView, transformProps)}
      onClick={onClick}
      onMouseEnter={() => handleMouse(true)}
      onMouseLeave={() => handleMouse(false)}
    >
      {children}
      {!stopped && (
        <AnimatePresence>
          {displaySelector && (
            <motion.span
              {...boilerplateMotion}
              style={
                assignStyleBorder(
                  type,
                  assignStyleColor(theme, color),
                ) as MotionStyle
              }
              transition={assignTransitionBorder(type, timeProps)}
              variants={assignVariantsBorder(type, transformProps)}
            />
          )}
        </AnimatePresence>
      )}
    </motion.span>
  );
};
