import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { InlineItemProps } from './InlineItem.types';

export const InlineItem = ({
  title,
  subtitle,
  children,
  icon,
  fullContentWidth = false,
  sx,
}: React.PropsWithChildren<InlineItemProps>) => {
  const isMobile = useMobileMediaQuery();
  return (
    <Stack alignItems="flex-start" spacing={SpaceSizes.md} sx={sx}>
      <Stack
        alignContent="center"
        direction={isMobile ? 'column' : 'row'}
        spacing={SpaceSizes.md}
      >
        <Typography fontWeight="bold" variant="h6">
          {title}
        </Typography>
        {icon}
      </Stack>
      {subtitle && (
        <Typography fontWeight="semi-bold" variant="body1">
          {subtitle}
        </Typography>
      )}

      <Stack
        justifyContent="flex-start"
        sx={fullContentWidth ? { width: '100%' } : {}}
      >
        {children}
      </Stack>
    </Stack>
  );
};
