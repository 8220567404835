import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { CheckoutConditional } from '../../components/CheckoutConditional/CheckoutConditional';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { CHECKOUT_TYPE } from './Checkout.constants';
import { CheckoutSkeleton } from './Checkout.skeleton';
import { useFetchData } from './useFetchData';

const successUrl = `${env.REACT_APP_BASE_APP_URL}payment/success/{CHECKOUT_ID}`;
const cancelUrl = `${env.REACT_APP_BASE_APP_URL}checkout/{CHECKOUT_ID}`;

const Checkout = () => {
  const { t } = useTranslationByNamespaces('pages.checkout');
  const navigate = useNavigate();
  const { checkoutId } = useParams();
  const { data, isLoading, isError, isUninitialized } =
    useFetchData(checkoutId);

  const { checkoutResponse } = data;
  const { productId, type } = checkoutResponse || {};

  const onBackButtonClick = useCallback(() => {
    if (type === CHECKOUT_TYPE.CART) {
      navigate('/cart');
    }
    if (type === CHECKOUT_TYPE.DIRECT) {
      navigate(`/product/${productId}`);
    }
  }, [type, navigate, productId]);

  if ((isLoading || isUninitialized || !productId) && !isError)
    return <CheckoutSkeleton />;

  return (
    <PageContainer
      {...(isError ? { errorCode: t('errors.GENERIC_ERROR') } : {})}
      backButton={!isLoading && !isUninitialized && !!productId}
      id="checkoutPage"
      maxWidth={type === CHECKOUT_TYPE.CART ? 'xl' : 'sm'}
      title={t('pageTitle')}
      trackCode={TAG_EVENT.CONTENT_GROUP.CHECKOUT}
      onBackButtonClick={onBackButtonClick}
    >
      <Stack gap={SpaceSizes.lg} width="100%">
        <CheckoutConditional
          checkoutResponse={checkoutResponse}
          paymentUrls={{ successUrl, cancelUrl }}
        />
      </Stack>
    </PageContainer>
  );
};

export default Checkout;
