import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import {
  CheckoutSummaryResponse,
  createCheckoutApiRequest,
  createCheckoutApiResponse,
  getCheckoutApiResponse,
  getStatusApiResponse,
  payCheckoutApiRequest,
  payCheckoutApiResponse,
  payTopUpApiRequest,
  payTopUpApiResponse,
} from './Checkout.types';

export const checkoutApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCheckout: builder.mutation<
      createCheckoutApiResponse,
      createCheckoutApiRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/direct`,
        method: 'POST',
        body: request,
      }),
    }),
    createCheckoutShoppingCart: builder.mutation<
      createCheckoutApiResponse,
      void
    >({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/shopping-cart`,
        method: 'POST',
      }),
    }),
    payCheckout: builder.mutation<
      payCheckoutApiResponse,
      payCheckoutApiRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/pay`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [RTKTags.TotalItems],
    }),
    payTopUp: builder.mutation<payTopUpApiResponse, payTopUpApiRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/topup/pay`,
        method: 'POST',
        body: request,
      }),
    }),
    getStatus: builder.query<getStatusApiResponse, string>({
      query: (checkoutId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/${checkoutId}/status`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getCheckout: builder.query<getCheckoutApiResponse, string>({
      query: (checkoutId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/${checkoutId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getCheckoutSummary: builder.query<
      CheckoutSummaryResponse,
      string | undefined
    >({
      query: (checkoutId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_CHECKOUT}checkout/${checkoutId}/summary`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  usePayCheckoutMutation,
  usePayTopUpMutation,
  useCreateCheckoutMutation,
  useGetCheckoutQuery,
  useGetStatusQuery,
  useGetCheckoutSummaryQuery,
  useCreateCheckoutShoppingCartMutation,
} = checkoutApi;
