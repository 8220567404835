import { Box, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LogoHeader } from '../header/LogoHeader/LogoHeader';

export const CookiesDisabled = () => {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      pb={20}
      px={40}
    >
      <DialogTitle>
        <LogoHeader height={30} width={220} />
      </DialogTitle>
      <Stack
        alignItems="center"
        direction="column"
        gap="20px"
        justifyContent="space-between"
        textAlign="center"
      >
        <Typography variant="h6">{t('cookiesDisabledPopup.title')}</Typography>
        <Typography variant="h6">
          {t('cookiesDisabledPopup.secondRow')}
        </Typography>
      </Stack>
    </Box>
  );
};
