import React from 'react';

import { IconWrapperProps } from './IconWrapper.types';

export const IconWrapper = ({
  color = 'primary.contrastText',
  iconComponent,
}: IconWrapperProps) => {
  const IconComponent = iconComponent;
  return <IconComponent sx={{ color }} />;
};
