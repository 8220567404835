import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import {
  TRADE_OFFER_CTA_FONT_SIZE,
  TRADE_OFFER_CTA_GAP,
  TRADE_OFFER_TITLE_FONT_SIZE,
} from './TradeOfferResult.constants';
import { TradeOfferResultE } from './TradeOfferResult.interfaces';
import { fadeInUp, stagger } from './TradeOfferResult.motion';

const MotionStack = motion(Stack);

export const TradeOfferResult = () => {
  const { status } = useParams();

  const { t } = useTranslationByNamespaces(
    `pages.myTradeRequests.result.${status}`,
  );

  const navigate = useNavigate();
  const goToMaket = useCallback(() => {
    navigate('/marketplace?tab=trade');
  }, [navigate]);
  const goToMyTrades = useCallback(() => {
    if (
      status === TradeOfferResultE.SUCCESS_OFFER ||
      status === TradeOfferResultE.FAILURE_OFFER
    ) {
      navigate('/trades?tab=sent');
    }
    navigate('/trades?tab=received');
  }, [status, navigate]);

  const successfulStatus = useMemo(() => {
    return (
      status === TradeOfferResultE.SUCCESS_OFFER ||
      status === TradeOfferResultE.SUCCESS_OFFER_ACCEPT ||
      status === TradeOfferResultE.SUCCESS_OFFER_REJECT
    );
  }, [status]);

  const statusIcon = useMemo(() => {
    if (successfulStatus) {
      return (
        <CheckCircleOutlineIcon
          color="success"
          data-testid="trade-offer-success-result"
          fontSize="inherit"
        />
      );
    }
    return <CancelIcon color="error" fontSize="inherit" />;
  }, [successfulStatus]);

  return (
    <PageContainer id="resultTradesPage" title={t('retry')} backButton>
      <MotionStack
        alignItems="center"
        animate="animate"
        gap={SpaceSizes.lg}
        initial="initial"
        my={SpaceSizes.xl}
        variants={stagger}
        width="80%"
      >
        <MotionStack fontSize={100} variants={fadeInUp}>
          {statusIcon}
        </MotionStack>

        <MotionStack alignItems="center" variants={fadeInUp} width="100%">
          <Typography
            fontSize={TRADE_OFFER_TITLE_FONT_SIZE}
            fontWeight={600}
            textAlign="center"
          >
            {t('title')}
          </Typography>
          <Typography fontSize={TRADE_OFFER_CTA_FONT_SIZE} textAlign="center">
            {t('description')}
          </Typography>
        </MotionStack>
        <MotionStack
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          gap={TRADE_OFFER_CTA_GAP}
          justifyContent="space-between"
          variants={fadeInUp}
        >
          <Button
            sx={{
              width: { xs: '100%', sm: 'auto' },
              fontSize: TRADE_OFFER_CTA_FONT_SIZE,
            }}
            variant="contained"
            onClick={goToMaket}
          >
            {t('cta.market')}
          </Button>
          <Button
            sx={{
              width: { xs: '100%', sm: 'auto' },
              fontSize: TRADE_OFFER_CTA_FONT_SIZE,
            }}
            variant="contained"
            onClick={goToMyTrades}
          >
            {t('cta.sent')}
          </Button>
        </MotionStack>
      </MotionStack>
    </PageContainer>
  );
};
