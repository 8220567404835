import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { TableRank } from '../../components/TableRank/TableRank';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';

const Rank = () => {
  const { t } = useTranslationByNamespaces('pages.rank');
  return (
    <PageContainer
      id="rankPage"
      maxWidth="sm"
      title={t('pageTitle')}
      backButton
    >
      <Stack alignItems="center" gap={SpaceSizes.md} width="100%">
        <TableRank />
      </Stack>
    </PageContainer>
  );
};

export default Rank;
