import React from 'react';
import { useParams } from 'react-router-dom';

import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import { StoryReel } from '../../components/Story/StoryReel';

const REEL_VIEWS = ['guia', 'collections_landing'];

export const ExternalContentPage = () => {
  const { keyId } = useParams();

  return (
    <PageContainer id="external-content">
      {keyId && REEL_VIEWS.includes(keyId) && <StoryReel />}
      <ExternalContent
        filter={{ key: 'page_id', value: keyId || 'default' }}
        type="pages"
      />
    </PageContainer>
  );
};
