import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import {
  addFilter,
  removeFilter,
} from '../../../features/searchfilter/SearchFilter.slice';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { CompactCard } from '../CompactCard/CompactCard';
import { EMPTY_ADD_SIZE_HEIGHT } from '../CompactCard/CompactCard.constants';
import { SearchFilterModal } from '../search/SearchFilterModal/SearchFilterModal';
import {
  EMPTY_DESCRIPTION_FONT_SIZE,
  SCROLL_BAR,
  SELECTOR_TITLE_FONT_SIZE,
} from './HorizontalSelector.constants';
import {
  IAvailableSelectionItem,
  IHorizontalSelector,
} from './HorizontalSelector.interfaces';
import { fadeInDown, fadeOutUp } from './HorizontalSelector.motion';

export const HorizontalSelector = ({
  availableSelection,
  data,
  disabled,
  handleOffer,
  handleEnterWaypoint,
  isLastPage,
}: IHorizontalSelector) => {
  const { t } = useTranslationByNamespaces('components.horizontalSelector');

  const navigate = useNavigate();

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  return (
    <Stack gap={SpaceSizes.md} minHeight={EMPTY_ADD_SIZE_HEIGHT} width="100%">
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        width="100%"
      >
        <Stack>
          <Typography
            component="h3"
            fontSize={SELECTOR_TITLE_FONT_SIZE}
            fontWeight={600}
          >
            {t('title')}
          </Typography>

          <Typography color="text.secondary" component="h4" fontWeight={300}>
            {t('description')}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          gap={SpaceSizes.sm}
          sx={{
            cursor: availableSelection?.length > 0 ? 'pointer' : 'not-allowed',
          }}
          onClick={
            availableSelection?.length > 0
              ? () => setFilterOpen(true)
              : () => null
          }
        >
          <Typography
            color={
              availableSelection?.length > 0 ? 'primary' : 'secondary.light'
            }
            component="h4"
            variant="cH5"
          >
            {t('filters')}
          </Typography>
          <FilterListIcon
            color={availableSelection?.length > 0 ? 'primary' : 'disabled'}
          />
        </Stack>
      </Stack>
      <Stack direction="row" sx={SCROLL_BAR} width="100%">
        <Stack
          key="container-horizontal-selector"
          direction="row"
          gap={SpaceSizes.smPlus}
          paddingLeft={{ xs: SpaceSizes.sm, xl: SpaceSizes.md }}
          paddingY={{ xs: SpaceSizes.md, xl: SpaceSizes.mdPlus }}
          sx={{ overflow: 'auto' }}
          width="100%"
        >
          <AnimatePresence mode="sync" presenceAffectsLayout>
            {availableSelection?.length === 0 ? (
              <Stack
                key="horizontal-inner-container"
                alignItems="center"
                data-testid="empty-horizontal-inner-container"
                gap={SpaceSizes.md}
                width="100%"
              >
                <Stack
                  alignItems="center"
                  gap={SpaceSizes.sm}
                  width={{ xs: '80%', lg: '50%' }}
                >
                  <Typography
                    color="text.primary"
                    fontSize={SELECTOR_TITLE_FONT_SIZE}
                    fontWeight={600}
                    textAlign="center"
                  >
                    {t('empty.title')}
                  </Typography>
                  <Typography
                    color="text.primary"
                    fontSize={EMPTY_DESCRIPTION_FONT_SIZE}
                    textAlign="center"
                  >
                    {t('empty.description')}
                  </Typography>
                </Stack>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/marketplace?tab=collectables')}
                >
                  {t('empty.goToMarket')}
                </Button>
              </Stack>
            ) : (
              availableSelection
                .filter(
                  (item): item is IAvailableSelectionItem => item !== undefined,
                )
                .map(
                  ({
                    product_id: productId,
                    assignment,
                    product_entity_id: ballId,
                    backdrop_url: backDrop,
                    piece,
                    player,
                  }) => (
                    <motion.div
                      key={`owned-${productId}-${piece?.piece_number}`}
                      animate="visible"
                      data-testid="horizontal-selector-collectible"
                      exit="exit"
                      initial="hidden"
                      variants={{
                        hidden: fadeInDown.hidden,
                        visible: fadeInDown.visible,
                        exit: fadeOutUp.exit,
                      }}
                      layout
                      onClick={() =>
                        handleOffer &&
                        !disabled &&
                        handleOffer(
                          ballId,
                          piece?.piece_number ||
                            Number(assignment?.piece_number) ||
                            0,
                          player,
                          productId,
                        )
                      }
                    >
                      <CompactCard
                        ballId={ballId}
                        disabled={disabled}
                        image={player.image || backDrop}
                        pieceNumber={Number(
                          piece?.piece_number || assignment?.piece_number,
                        )}
                      />
                    </motion.div>
                  ),
                )
            )}
          </AnimatePresence>

          {!isLastPage && (
            <Waypoint
              topOffset={100}
              horizontal
              onEnter={handleEnterWaypoint}
            />
          )}
        </Stack>
      </Stack>

      <SearchFilterModal
        addFilter={addFilter}
        appliedFilters={data.appliedFilters}
        filters={data.filters}
        handleClose={() => setFilterOpen(false)}
        isShowModalFilter={filterOpen}
        removeFilter={removeFilter}
      />
    </Stack>
  );
};
