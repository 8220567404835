import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import SelectDate from './SelectDate';

interface SelectDateTimeProps {
  date: Date | null;
  onChange: (state: Date | null) => void;
}

const SelectDateTime = ({ date, onChange }: SelectDateTimeProps) => {
  return (
    <Stack spacing={SpaceSizes.xs}>
      <SelectDate date={date} setDate={onChange} />
    </Stack>
  );
};

export default SelectDateTime;
