import { MotionStyle, Transition } from 'framer-motion';

import {
  AnimationEnum,
  BORDER_SELECTOR_STYLES_GLOW,
  BORDER_SELECTOR_STYLES_LIGHT_FOCUS,
  BORDER_SELECTOR_STYLES_PULSE,
} from './Animate.constants';
import { AnimationType } from './Animate.types';
import { ITransformProps, ITransitionProps } from './Variants.types';
import {
  delayedAnimation,
  loopedAnimation,
  offsetApparition,
  pulseAnimation,
} from './Variants.utils';

export const assignVariants = (
  type: AnimationType,
  isInView: boolean,
  transformProps: ITransformProps = {},
) => {
  if (isInView) {
    if (type === AnimationEnum.Offset && transformProps) {
      return offsetApparition(transformProps);
    }
    if (type === AnimationEnum.Pulse && transformProps) {
      return offsetApparition(transformProps);
    }
    return {
      initial: {
        opacity: 1,
      },
    };
  }
  return {
    initial: {
      opacity: 0,
    },
  };
};

export const assignTransitionBorder = (
  type: AnimationType,
  timeProps: ITransitionProps = { delay: 0, duration: 0.15 },
): Transition => {
  if (type === AnimationEnum.Offset && timeProps) {
    return delayedAnimation(timeProps);
  }
  if (type === AnimationEnum.Pulse && timeProps) {
    return loopedAnimation;
  }
  return delayedAnimation(timeProps);
};

export const assignVariantsBorder = (
  type: AnimationType,
  transformProps: ITransformProps = {},
) => {
  if (type === AnimationEnum.Offset && transformProps) {
    return offsetApparition(transformProps);
  }
  if (type === AnimationEnum.Pulse && transformProps) {
    return pulseAnimation();
  }
  return offsetApparition(transformProps);
};

export const assignStyle = (
  displayOnView?: boolean,
  isInView?: boolean,
  sx?: Record<string, string | number>,
): MotionStyle => {
  if (displayOnView) {
    return {
      transform: isInView ? 'none' : 'translateX(-200px)',
      opacity: isInView ? 1 : 0,
      transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
      position: 'relative',
      ...sx,
    };
  }

  return { position: 'relative', ...sx };
};

export const assignStyleBorder = (type: AnimationType, color?: string) => {
  if (type === AnimationEnum.Glow) {
    return BORDER_SELECTOR_STYLES_GLOW(false, color);
  }
  if (type === AnimationEnum.GlowFixed) {
    return BORDER_SELECTOR_STYLES_GLOW(true, color);
  }
  if (type === AnimationEnum.BorderBottom) {
    return BORDER_SELECTOR_STYLES_LIGHT_FOCUS(color);
  }
  if (type === AnimationEnum.Pulse) {
    return BORDER_SELECTOR_STYLES_PULSE(color);
  }
  return {};
};
