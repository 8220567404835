/* eslint-disable react/destructuring-assignment */
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import {
  PRODUCT_TAG_PADDING,
  PRODUCT_TAG_RADIUS,
} from './ProductTag.constants';
import { ProductTagTypeEnum } from './ProductTag.types';

interface ProductTagLabelProps {
  tag: string;
  golden: boolean;
}

const TextTag = ({ text }: { text: string }) => {
  const theme = useTheme();

  const textStyle = useMemo(() => {
    return {
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      textAlign: 'center',
      alignSelf: 'center',
      display: 'flex',
    };
  }, [theme.palette.primary.main]);

  return (
    <Typography sx={{ ...textStyle }} variant="body2" whiteSpace="nowrap">
      {text}
    </Typography>
  );
};

export const ProductTagLabel = ({ tag, golden }: ProductTagLabelProps) => {
  const { t } = useTranslationByNamespaces('components.productCard.tags');
  const theme = useTheme();

  const getTagElement = () => {
    switch (tag) {
      case ProductTagTypeEnum.DOUBLE_GOAL:
        return <TextTag text={t('doubleGoal')} />;
      case ProductTagTypeEnum.TRIPLE_GOAL:
        return <TextTag text={t('tripleGoal')} />;
      case ProductTagTypeEnum.OWN_GOAL:
        return <TextTag text={t('ownGoal')} />;
      case ProductTagTypeEnum.MUSEUM:
        return <TextTag text={t('museum')} />;
      case ProductTagTypeEnum.AUCTION:
        return <TextTag text={t('auction')} />;
      case ProductTagTypeEnum.LIMITED_EDITION:
        return <TextTag text={t('limitedEdition')} />;
      case ProductTagTypeEnum.DIRECT_SALE:
        return <TextTag text={t('directSale')} />;
      default:
        return <Stack />;
    }
  };

  return (
    <Box
      sx={{
        background: golden ? theme.palette.golden.gradient : 'white',
        padding: PRODUCT_TAG_PADDING.mobile,
        borderRadius: PRODUCT_TAG_RADIUS.mobile,
      }}
    >
      {getTagElement()}
    </Box>
  );
};
