import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopMediaQuery from '../../hooks/useDesktopMediaQuery';
import { Link } from '../Link/Link';
import Panel from '../Panel/Panel';
import { ProductTitleSection } from '../ProductTitleSection/ProductTitleSection';
import { TRIVIA_GOLDEN_BALL } from './TriviaWinner.constants';

export const TriviaWinner = () => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.finished');
  const isDesktop = useDesktopMediaQuery();

  const theme = useTheme();

  return (
    <Panel>
      <Stack gap={SpaceSizes.sm}>
        <Stack alignItems="center" direction="row" spacing={SpaceSizes.md}>
          <img
            alt="Trivia golden ball"
            height={isDesktop ? SpaceSizes.xlPlus : SpaceSizes.xl}
            src={TRIVIA_GOLDEN_BALL}
          />
          <Stack alignItems="flex-start" direction="column" gap={SpaceSizes.sm}>
            <ProductTitleSection>
              <span style={{ color: theme.palette.golden.main }}>
                {t('title')}
              </span>
            </ProductTitleSection>
            <Typography textAlign="left" variant="body1">
              {t('description')}{' '}
              <Link color="secondary" to="/configuration">
                {t('link')}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Panel>
  );
};
