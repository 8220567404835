import BackspaceIcon from '@mui/icons-material/Backspace';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import { isNonEmptyString } from 'ramda-adjunct';
import React from 'react';

// Generic searchBar with icons
interface Props {
  placeholder: string;
  value: string;
  onClear: React.MouseEventHandler<SVGSVGElement>;
  onSearch: (arg: string) => void;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  onChange: React.FormEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme> | undefined;
}
export const SearchBar = ({
  value,
  placeholder,
  sx,
  onClear,
  onSearch,
  onChange,
  onKeyDown,
  ...props
}: Props) => {
  return (
    <TextField
      className="text"
      id="search-bar"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              style={{ fill: 'gray' }}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => onSearch(value)}
            />
          </InputAdornment>
        ),
        endAdornment: isNonEmptyString(value) ? (
          <InputAdornment position="end">
            <BackspaceIcon
              data-testid="clearSearch"
              fontSize="small"
              style={{ fill: 'primary' }}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={onClear}
            />
          </InputAdornment>
        ) : null,
      }}
      placeholder={placeholder}
      sx={{
        ...sx,
      }}
      value={value}
      onInput={onChange}
      onKeyDown={onKeyDown}
      {...props}
      autoFocus
    />
  );
};
