export const WARNING_MODAL_TITLE = {
  xs: 20,
  md: 24,
  lg: 26,
};
export const WARNING_MODAL_DESCRIPTION = {
  xs: 11,
  md: 13,
  lg: 14,
};

export const WARNING_MODAL_WIDTH = {
  xs: 500,
  md: 700,
  lg: 900,
};
