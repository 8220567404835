import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Container, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Env from '../../../env/env';
import { useAccountRecoveryMutation } from '../../../features/session/Session.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { isValidEmail } from '../../../utils/validation';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input/Input';
import { GolButton } from '../../components/GolButton/GolButton.component';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useRedirectLoginSignup from '../../hooks/useRedirectLoginSignup';
import { Inputs } from './AccountRecovery.types';

const AccountRecovery = () => {
  const { t } = useTranslationByNamespaces('pages.accountRecovery');
  const [email, setEmail] = useState('');
  const sendDataToGTM = useGTMDispatch();

  const [accountRecovery, { isLoading, isSuccess }] =
    useAccountRecoveryMutation();

  const [errorCode, setErrorCode] = useState('');
  const navigate = useNavigate();

  const methods = useForm<Inputs>();

  const { loginRoute } = useRedirectLoginSignup();

  const handleForgetPress: SubmitHandler<Inputs> = async (data) => {
    // only resets if some error is showing
    if (isNotNilOrEmpty(errorCode)) {
      setErrorCode('');
    }
    try {
      await accountRecovery({
        email: data.email,
        baseUrl: `${Env.REACT_APP_BASE_APP_URL}account-recovery/` || '',
      }).unwrap();
      sendDataToGTM({
        event: TAG_EVENT.ONBOARDING.RECOVERY_LINK,
      });
      setEmail(data.email);
    } catch (error) {
      setErrorCode('accountRecoveryError');
    }
  };

  return (
    <PageContainer id="accountRecoveryPage" maxWidth="xs">
      {!isSuccess && (
        <Container maxWidth="xs">
          <Form methods={methods} onSubmit={handleForgetPress}>
            <Stack
              justifyContent="center"
              spacing={SpaceSizes.mdPlus}
              width="100%"
            >
              <Stack alignSelf="start">
                <Typography fontWeight="bold" variant="h5">
                  {t('title')}
                </Typography>
              </Stack>
              <Stack alignSelf="start">
                <Typography align="center" textAlign="start">
                  {t('subtitle')}
                </Typography>
              </Stack>

              <Input
                errorMessage={t(`errors.mailError`)}
                id="email"
                label={t('email')}
                validate={isValidEmail}
              />

              {isNotNilOrEmpty(errorCode) && (
                <AlertCard
                  content={t(`errors.${errorCode}`)}
                  id="accountRecoveryError"
                  show
                />
              )}

              <GolButton
                disabled={isNotNilOrEmpty(methods.formState.errors)}
                loading={isLoading}
                testId="accountRecoveryButton"
                type="submit"
                value={t('actionButton')}
              />
            </Stack>
          </Form>
        </Container>
      )}
      {isSuccess && (
        <Container maxWidth="xs">
          <Stack
            data-testid="accountRecoverySuccess"
            id="accountRecoverySuccess"
            justifyContent="center"
            spacing={SpaceSizes.mdPlus}
            width="100%"
          >
            <Stack alignSelf="start">
              <Typography fontWeight="bold" variant="h5">
                {t('titleSuccess')}
              </Typography>
            </Stack>
            <Stack alignSelf="start">
              <Typography align="center" textAlign="start">
                {t('subtitleSuccess')} {email}
                {'. '}
                {t('subtitleSuccess2')}
              </Typography>
            </Stack>

            <GolButton
              value={t('returnSuccess')}
              variant="secondary"
              onClick={() => navigate(loginRoute)}
            />
          </Stack>
        </Container>
      )}
    </PageContainer>
  );
};

export default AccountRecovery;
