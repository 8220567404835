import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { OfferType } from '../../../features/trades/Trades.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { TRADE_SUMMARY_FONT_SIZE } from './TradeOfferSummary.constants';
import { TradeOfferSummaryProps } from './TradeOfferSummary.interfaces';
import { capitalizeFirstLetter } from './TradeOfferSummary.utils';

export const TradeOfferSummary = ({
  offer,
  sentPieces,
  offerType,
}: TradeOfferSummaryProps) => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const receiveOffer = `${offer?.player.name} - #${offer?.ball_id}`;

  const sentOffer = (piece: string, index: number) =>
    `${piece} ${
      sentPieces?.length > 0 && index + 1 < sentPieces?.length ? '|' : ''
    }`;

  const messages = useMemo(() => {
    const isReceived = offerType === OfferType.RECEIVED;
    const isEmpty = sentPieces.length === 0;

    const leftKey = `summary.${
      isReceived ? (isEmpty ? 'emptySend' : 'send') : 'receive'
    }`;
    const rightKey = `summary.${
      isReceived ? 'receive' : isEmpty ? 'emptySend' : 'send'
    }`;

    return {
      leftMessage: capitalizeFirstLetter(t(leftKey)),
      rightMessage: capitalizeFirstLetter(t(rightKey)),
    };
  }, [offerType, sentPieces.length, t]);

  return (
    <Stack
      alignItems="flex-start"
      borderRadius={SpaceSizes.xs}
      data-testid="trade-offer-summary-modal"
      gap={SpaceSizes.sm}
      justifyContent="center"
      padding={SpaceSizes.md}
      sx={{ backgroundColor: 'background.paper' }}
      width="100%"
    >
      <Stack>
        <Typography component="p" fontSize={TRADE_SUMMARY_FONT_SIZE}>
          {messages.leftMessage}
        </Typography>
        <Typography color="text.primary" fontSize={TRADE_SUMMARY_FONT_SIZE}>
          {receiveOffer}
        </Typography>
      </Stack>
      <Stack>
        <Typography component="p" fontSize={TRADE_SUMMARY_FONT_SIZE}>
          {messages.rightMessage}
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap={SpaceSizes.xs}>
          {sentPieces.map((piece, index) => (
            <Typography
              key={piece}
              color="text.primary"
              fontSize={TRADE_SUMMARY_FONT_SIZE}
            >
              {sentOffer(piece, index)}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
