/* eslint-disable no-shadow */
enum ETriviaSummaryStatus {
  OK = 'ok',
  BATTLE_ENDED = 'battle_ended',
  USER_ELIMINATED = 'user_eliminated',
  ALREADY_ANSWERED = 'already_answered',
}

enum ETriviaAnswerStatus {
  RIGHT = 'right',
  WRONG = 'wrong',
}

export { ETriviaAnswerStatus, ETriviaSummaryStatus };
