import { createSelector } from '@reduxjs/toolkit';
import { equals } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

import { RootState } from '../../app/store';
import { SessionState } from './Session.slice';

export const selectSession = (state: RootState) => state.session;

export const selectIsAlpha = createSelector(
  selectSession,
  (session: SessionState) => session.isAlpha,
);

export const selectUserSession = createSelector(
  selectSession,
  (session: SessionState) => session.user,
);

export const selectUserId = createSelector(
  selectSession,
  (session: SessionState) => session.user?.user_id,
);

/**
 * if session is pending or failed we assume loading state.
 * Failed is used when there is no session and the system is loading a anon session
 */
export const selectIsSessionLoading = createSelector(
  selectSession,
  (session: SessionState) =>
    equals(session.status, 'pending') || equals(session.status, 'idle'),
);

export const selectIsUserNeedsAnonymousSession = createSelector(
  selectSession,
  (session: SessionState) =>
    equals(session.status, 'pending') &&
    isNilOrEmpty(session.user) &&
    !session.isAnonymous,
);

export const selectIsUserLoggedIn = createSelector(
  selectSession,
  (session: SessionState) => isNotNilOrEmpty(session.user),
);

export const selectIsUserNeedsPhoneValidation = createSelector(
  selectSession,
  (session: SessionState) => isNilOrEmpty(session.user?.phone),
);

export const selectSessionError = createSelector(
  selectSession,
  (session: SessionState) => session.error,
);

export const selectUserHasSeenOnboarding = createSelector(
  selectSession,
  (session: SessionState) => session.user?.has_seen_onboarding,
);

export const selectLocationCountry = createSelector(
  selectSession,
  (session: SessionState) => session.location?.country || '',
);

export const selectLocationIp = createSelector(
  selectSession,
  (session: SessionState) => session.location?.ip || '',
);

export const selectShouldTrackSignup = createSelector(
  selectSession,
  (session: SessionState) => session.shouldTrackSignup,
);
