import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { AlertSeverity } from '../AlertCard/AlertSeverity';

const TriviaPlayerEliminated = ({ text }: { text: string }) => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.started');
  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      gap={SpaceSizes.sm}
      width="100%"
    >
      <Stack flexDirection="row" gap={SpaceSizes.sm}>
        <InfoIcon color={AlertSeverity.INFO} />
        <Typography align="center" fontWeight="bold">
          {t(text)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TriviaPlayerEliminated;
