import EventIcon from '@mui/icons-material/Event';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Waypoint } from 'react-waypoint';

import {
  GetBuysMovementsApiResponse,
  IBuyMovement,
} from '../../../../features/movements/Movements.types';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { EmptyResults } from '../../../components/EmptyResults/EmptyResults';
import { StatesEmptyResults } from '../../../components/EmptyResults/EmptyResults.constant';
import useMyBuysMovementsWithInfiniteScroll from '../../../hooks/movements/useMyBuysMovementsWithInfiniteScroll';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { groupBuysByDate } from '../utils/Movement.utils';
import { BuyMovementContent } from './content/BuysMovementsContent';
import { MovementsSkeleton } from './content/Movements.skeletons';

export const MyBuysMovements = () => {
  const { t } = useTranslationByNamespaces('pages.movements');
  const { data, isLoading, isError, isFetching, isLastPage, enterWaypoint } =
    useMyBuysMovementsWithInfiniteScroll();

  const renderBuyGroups = (renderedData: GetBuysMovementsApiResponse) => {
    if (renderedData?.results.length === 0)
      return <EmptyResults origin={StatesEmptyResults.BUYS} t={t} />;

    const groupedBuys = groupBuysByDate(renderedData.results, t);
    return Array.from(groupedBuys.entries()).map(([date, buys]) => (
      <Stack key={date}>
        <Stack alignItems="center" direction="row" spacing={SpaceSizes.sm}>
          <EventIcon />
          <Typography color="text.primary" fontWeight={400} variant="cH5">
            {date}
          </Typography>
        </Stack>
        {buys.map((buy: IBuyMovement) => (
          <BuyMovementContent key={`${buy.order_id}`} {...buy} />
        ))}
      </Stack>
    ));
  };

  return (
    <Stack gap={SpaceSizes.md} width="100%">
      {isLoading ? (
        <Stack gap={SpaceSizes.md}>
          {[...Array(6)].map(() => (
            <MovementsSkeleton key={Number(Date.now()) * Math.random()} />
          ))}
        </Stack>
      ) : (
        <Stack gap={SpaceSizes.md}>
          {data && data?.results && renderBuyGroups(data)}
        </Stack>
      )}
      {(isFetching || isLoading) && (
        <Stack padding={SpaceSizes.sm}>
          <MovementsSkeleton />
        </Stack>
      )}
      {!isLastPage && <Waypoint onEnter={enterWaypoint} />}
      {isError && (
        <Stack
          alignItems="center"
          gap={SpaceSizes.md}
          justifyContent="center"
          padding={SpaceSizes.sm}
          width="100%"
        >
          <Typography color="text.primary" component="p" variant="cBody1">
            {t('serverError.buys')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
