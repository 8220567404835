import { Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';

export const TradeRequestSkeleton = () => {
  return (
    <Stack gap={SpaceSizes.md} width="100%">
      <Skeleton
        height={550}
        sx={{ borderRadius: SpaceSizes.xs }}
        variant="rectangular"
        width="100%"
      />
    </Stack>
  );
};
