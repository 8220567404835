import { LoadingButton } from '@mui/lab';
import { Container, Grid, Stack } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useChangePasswordMutation } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import Form from '../../components/form/Form';
import { InputPassword } from '../../components/form/InputPassword/InputPassword';

type Inputs = {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = useState('');
  const [changePassword, { isLoading, isSuccess, isError }] =
    useChangePasswordMutation();

  // Get methods for Form Provider
  const methods = useForm<Inputs>();
  const { watch } = methods;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setErrorCode('');
    await changePassword({
      new_password: data.newPassword,
      old_password: data.oldPassword,
      new_password_validation: data.repeatPassword,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/configuration?alert=passwordSuccess`);
    }
    if (isError) {
      setErrorCode(t('invalidCredentials'));
    }
  }, [isSuccess, isError, navigate, t]);

  return (
    <PageContainer
      id="changePasswordPage"
      title={t('pages.change-password.title')}
      backButton
    >
      <Stack spacing={SpaceSizes.md}>
        {isNotNilOrEmpty(errorCode) && (
          <AlertCard
            content={t(`pages.change-password.errors.${errorCode}`)}
            id="changePasswordError"
            show={isNotNilOrEmpty(errorCode)}
          />
        )}
        <Form methods={methods} onSubmit={onSubmit}>
          <Container maxWidth="xs">
            <Grid
              columns={12}
              justifyContent="center"
              spacing={SpaceSizes.mdPlus}
              container
            >
              <Grid xs={12} item>
                <InputPassword
                  id="oldPassword"
                  label={t(`pages.change-password.formData.oldPassword`)}
                  placeholder={t(
                    `pages.change-password.formData.oldPasswordPlaceholder`,
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <InputPassword
                  id="newPassword"
                  label={t(`pages.change-password.formData.newPassword`)}
                  placeholder={t(
                    `pages.change-password.formData.newPasswordPlaceholder`,
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <InputPassword
                  id="repeatPassword"
                  label={t(`pages.change-password.formData.repeatPassword`)}
                  match={watch('newPassword')}
                  placeholder={t(
                    `pages.change-password.formData.repeatPasswordPlaceholder`,
                  )}
                />
              </Grid>
              <Grid item>
                <LoadingButton
                  disabled={isLoading && !isSuccess}
                  type="submit"
                  variant="contained"
                >
                  {t('pages.change-password.confirmButton')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Container>
        </Form>
      </Stack>
    </PageContainer>
  );
};

export default ChangePassword;
