import { useGetTransactionsMovementsQuery } from '../../../features/movements/Movements.api';
import { GetTransactionsMovementsApiResponse } from '../../../features/movements/Movements.types';
import createUseDataWithInfiniteScroll from '../shared/createUseDataWithInfiniteScroll';

/**
 * Hook that let us reuse infinite scroll for movements api
 * @returns
 */
const useMyTransactionsMovementsWithInfiniteScroll =
  createUseDataWithInfiniteScroll<GetTransactionsMovementsApiResponse>(
    useGetTransactionsMovementsQuery,
  );

export default useMyTransactionsMovementsWithInfiniteScroll;
