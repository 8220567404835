import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import Panel from '../Panel/Panel';

export const DirectSaleInfo = () => {
  const { t } = useTranslation();

  return (
    <Panel
      sx={{
        p: SpaceSizes.md,
        justifyContent: 'center',
      }}
    >
      <Stack justifyContent="center" spacing={SpaceSizes.lg}>
        <Typography align="left" variant="cH4">
          {t('components.directSale.title')}
        </Typography>
        <Typography align="left">
          {t('components.directSale.description')}
        </Typography>
      </Stack>
    </Panel>
  );
};
