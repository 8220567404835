import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  addURLParamByKey,
  deleteURLParamByKey,
  getURLParamByKey,
} from '../router/router.utils';
import { AVAILABLE_KEY, FILTER_EXCLUDED_KEYS } from './Filters.constants';
import { AppliedFilter, AppliedSort } from './responses/SearchResponse';
import { searchFiltersURL } from './SearchFilter.api';

export interface SearchFilterState {
  searchQuery: string;
  appliedFilters: AppliedFilter[];
  appliedSort: AppliedSort;
}

const initialState = {
  searchQuery: '',
  appliedFilters: [],
  appliedSort: {
    id: 'relevance',
    name: 'Más relevantes',
  },
} as SearchFilterState;

const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.appliedFilters = [];
      state.searchQuery = '';
    },
    addFilter: (state, action: PayloadAction<AppliedFilter>) => {
      const filterIndex = state.appliedFilters.findIndex(
        (f) => f.id === action.payload.id,
      );
      // if filter exists update its value
      if (filterIndex >= 0) {
        state.appliedFilters[filterIndex] = action.payload;
      } else {
        // if filter does not exist add it
        state.appliedFilters.push(action.payload);
      }
      addURLParamByKey(action.payload.id, action.payload.value);
    },
    removeFilter: (state, action: PayloadAction<string>) => {
      state.appliedFilters = state.appliedFilters.filter(
        (appliedFilter) => appliedFilter.id !== action.payload,
      );
      deleteURLParamByKey(action.payload);
    },
    syncAppliedFilters: (state, action: PayloadAction<AppliedFilter[]>) => {
      state.appliedFilters = state.appliedFilters.filter((filter) => {
        // Check if the current filter is included in the new filters
        const isInNewFilters = action.payload.some(
          (newFilter) => newFilter.id === filter.id,
        );

        // If the filter is not in the new filters and it is not one of the excluded keys,
        // remove it from the URL
        if (!isInNewFilters && !FILTER_EXCLUDED_KEYS.includes(filter.id)) {
          deleteURLParamByKey(filter.id);
        }

        // Keep the filter in the state if it is in the new filters or it is one of the excluded keys
        return isInNewFilters || FILTER_EXCLUDED_KEYS.includes(filter.id);
      });
    },

    setMarketplaceFilter: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.appliedFilters = [];

      searchFiltersURL.forEach((filter) => {
        if (getURLParamByKey(filter)) {
          state.appliedFilters.push({
            id: filter,
            name: filter,
            value: getURLParamByKey(filter),
            type: filter,
            valueLabel: '',
          });
        }
      });

      // always add available filter (if not present)
      if (
        state.appliedFilters.findIndex((f) => f.id === AVAILABLE_KEY) === -1
      ) {
        state.appliedFilters.push({
          id: AVAILABLE_KEY,
          value: 'true',
          type: 'boolean',
          name: AVAILABLE_KEY,
          valueLabel: '',
        });
      }
    },
    changeSort: (state, action: PayloadAction<AppliedSort>) => {
      state.appliedSort = action.payload;
    },
  },
});

export const {
  clearFilters,
  addFilter,
  removeFilter,
  syncAppliedFilters,
  setMarketplaceFilter,
  changeSort,
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
