import { Badge, Box, Modal, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';

import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { UploadImageButtonHandler } from '../../UploadImageButtonHandler/UploadImageButtonHandler';
import UserAvatar from '../../UserAvatar/UserAvatar';
import { AvatarCrop } from '../AvatarCrop/AvatarCrop';
import { IAvatarInputFileProps } from './AvatarInputFile.types';

export const AvatarInputFile = ({ onSuccess }: IAvatarInputFileProps) => {
  const isMobile = useMobileMediaQuery();
  const [file, setFile] = useState('');
  const [open, setOpen] = useState(false);

  const setImage = useCallback((image: string) => {
    setFile(image);
    setOpen(true);
  }, []);

  const onFinishUpload = () => {
    setOpen(false);
    setFile('');
    onSuccess();
  };

  return (
    <Badge
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={<UploadImageButtonHandler setImageCallback={setImage} />}
      overlap="circular"
    >
      <Stack
        alignContent="center"
        justifyContent="center"
        sx={{
          width: isMobile ? 65 : 75,
          height: isMobile ? 65 : 75,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box m="auto">
          <Modal
            aria-describedby="modal-update-avatar"
            aria-labelledby="modal-update-avatar"
            open={open}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClose={() => setOpen(false)}
          >
            {/* Span trick to avoid MUI forwad error */}
            <span>
              <AvatarCrop
                sourceImg={file}
                onClose={() => setOpen(false)}
                onFinishUpload={onFinishUpload}
              />
            </span>
          </Modal>
        </Box>
        <UserAvatar height="100%" width="100%" />
      </Stack>
    </Badge>
  );
};
