import { Theme } from '@mui/material';
import { MotionStyle } from 'framer-motion';

export const DEFAULT_ANIMATION_TIME = 0.5;
export const DEFAULT_DELAY_TIME = 0;

export const ANIMATION_SLIDE_UP = {
  y: 0,
  transition: {},
};

export const BORDER_SELECTOR_STYLES_LIGHT_FOCUS = (glowColor = 'white') => {
  return {
    position: 'absolute',
    left: '20%',
    right: '-10%',
    top: '93.5%',
    cursor: 'pointer',
    color: glowColor,
    boxShadow: `${glowColor} -30px -15px 30px 3px`,
    borderRadius: `10%`,
    zIndex: 0, // If you change zIndex dont forget to check on click functions or you are in for some issues
  };
};

export const BORDER_SELECTOR_STYLES_GLOW = (
  isFixed?: boolean,
  glowColor = 'white',
): MotionStyle => {
  return {
    position: isFixed ? 'fixed' : 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 45,
    cursor: 'pointer',
    border: '3px solid',
    color: glowColor,
    boxShadow: `${glowColor} 0px 22px 40px 4px`,
    borderRadius: `6%`,
    zIndex: 0, // If you change zIndex dont forget to check on click functions or you are in for some issues
  };
};

export const BORDER_SELECTOR_STYLES_PULSE = (
  pulseColor = 'white',
): MotionStyle => {
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 4,
    height: '1.5rem',
    border: '1px solid',
    color: pulseColor,
    boxShadow: `inset ${pulseColor} 0 0 1px 1px`,
    borderRadius: '120%',
    zIndex: 0,
  };
};

export enum AnimationEnum {
  Glow = 'glow',
  GlowFixed = 'glowFixed',
  BorderBottom = 'borderBottom',
  Pulse = 'pulse',
  Common = 'common',
  FadeIn = 'fadeIn',
  FadeOut = 'fadeOut',
  SlideUp = 'slideUp',
  SlideDown = 'slideDown',
  CardHover = 'cardHover',
  Offset = 'offset',
}

export enum AnimationColor {
  WHITE,
  PURPLE,
}

export const assignStyleColor = (theme: Theme, color?: AnimationColor) => {
  switch (color) {
    case AnimationColor.WHITE:
      return theme.palette.text.primary;
    case AnimationColor.PURPLE:
      return theme.palette.primary.main;
    default:
      return theme.palette.secondary.main;
  }
};
