import EventIcon from '@mui/icons-material/Event';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Waypoint } from 'react-waypoint';

import {
  GetTransactionsMovementsApiResponse,
  ITransactionMovement,
} from '../../../../features/movements/Movements.types';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { EmptyResults } from '../../../components/EmptyResults/EmptyResults';
import { StatesEmptyResults } from '../../../components/EmptyResults/EmptyResults.constant';
import useMyTransactionsMovementsWithInfiniteScroll from '../../../hooks/movements/useMyTransactionsMovementsWithInfiniteScroll';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { groupTransactionsByDate } from '../utils/Movement.utils';
import { MovementsSkeleton } from './content/Movements.skeletons';
import { TransactionsMovementsContent } from './content/TransactionsMovementsContent';

export const MyTransactionsMovements = () => {
  const { t } = useTranslationByNamespaces('pages.movements');
  const { data, isLoading, isError, isFetching, isLastPage, enterWaypoint } =
    useMyTransactionsMovementsWithInfiniteScroll();
  const renderTransactionGroups = (
    renderedData: GetTransactionsMovementsApiResponse,
  ) => {
    if (renderedData?.results.length === 0)
      return <EmptyResults origin={StatesEmptyResults.TRANSFERS} t={t} />;

    const groupedTransactions = groupTransactionsByDate(
      renderedData.results,
      t,
    );
    return Array.from(groupedTransactions.entries()).map(
      ([date, transactions]) => (
        <Stack key={date} gap={SpaceSizes.sm}>
          <Stack alignItems="center" direction="row" spacing={SpaceSizes.sm}>
            <EventIcon />
            <Typography color="text.primary" fontWeight={400} variant="cH5">
              {date}
            </Typography>
          </Stack>
          {transactions.map((transaction: ITransactionMovement) => (
            <TransactionsMovementsContent
              key={transaction.transaction_id}
              {...transaction}
            />
          ))}
        </Stack>
      ),
    );
  };

  return (
    <Stack gap={SpaceSizes.md} width="100%">
      {isLoading ? (
        <Stack gap={SpaceSizes.md}>
          {[...Array(6)].map(() => (
            <MovementsSkeleton key={Number(Date.now()) * Math.random()} />
          ))}
        </Stack>
      ) : (
        <Stack gap={SpaceSizes.md}>
          {data && data?.results && renderTransactionGroups(data)}
        </Stack>
      )}
      {(isFetching || isLoading) && (
        <Stack padding={SpaceSizes.sm}>
          <MovementsSkeleton />
        </Stack>
      )}
      {!isLastPage && <Waypoint onEnter={enterWaypoint} />}
      {isError && (
        <Stack
          alignItems="center"
          gap={SpaceSizes.md}
          justifyContent="center"
          padding={SpaceSizes.sm}
          width="100%"
        >
          <Typography color="text.primary" component="p" variant="cBody1">
            {t('serverError.transactions')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
