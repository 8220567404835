import { Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetOffersQuery } from '../../../features/trades/Trades.api';
import { OfferType } from '../../../features/trades/Trades.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { StoryReel } from '../../components/Story/StoryReel';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { TradeRequest } from './items/TradeRequest';
import { TradeRequestSkeleton } from './items/TradeRequest.skeleton';
import { TRADE_REQUEST_TABS } from './MyTradeRequest.constants';

const MyTradeRequests = () => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const [currentTab, setCurrentTab] = useState<OfferType>(OfferType.RECEIVED);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading, isError, refetch } = useGetOffersQuery();
  const navigate = useNavigate();
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: OfferType,
  ) => {
    if (searchParams) {
      setSearchParams();
    }
    navigate(`?tab=${newValue}`);
    setCurrentTab(newValue);
  };

  useEffect(() => {
    refetch();
    const tab = searchParams.get('tab') as OfferType;
    if (tab) {
      setCurrentTab(tab);
    }
  }, [currentTab, refetch, searchParams]);
  return (
    <PageContainer
      {...(isError && { errorCode: 'error.DEFAULT' })}
      id="tradesPage"
      title={t('pageTitle')}
      backButton
      onBackButtonClick={() => navigate('/marketplace?tab=collectables')}
    >
      <Stack
        alignItems="start"
        gap={SpaceSizes.lg}
        marginY={SpaceSizes.lg}
        width="100%"
      >
        <StoryReel />
        <Tabs
          sx={{
            borderBottom: 1,
            width: '100%',
            borderColor: 'text.secondary',
          }}
          value={currentTab}
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleTabChange}
        >
          {TRADE_REQUEST_TABS.map((tab) => {
            return (
              <Tab
                key={tab}
                data-testid={`trade-tab-${tab}`}
                label={t(`tabs.${tab}`)}
                sx={{ width: '50%', color: 'text.primary' }}
                value={tab}
              />
            );
          })}
        </Tabs>
        {isLoading ? (
          <TradeRequestSkeleton />
        ) : (
          data &&
          (data.filter((o) => o.type === currentTab).length > 0 ? (
            data
              .filter((o) => o.type === currentTab)
              .map((offer) => <TradeRequest offer={offer} />)
          ) : (
            <Typography sx={{ opacity: 0.6 }} textAlign="center" width="100%">
              {t(`empty.${currentTab}`)}
            </Typography>
          ))
        )}
      </Stack>
    </PageContainer>
  );
};

export default MyTradeRequests;
