import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useVerifyRecaptchaMutation } from '../../features/recaptcha/Recaptcha.api';

/**
 * Reusable hook to verify recaptcha
 * @param onSuccess callback when the verification is successful
 * @param onFail callback when the verification is failed
 * @param onError callback when verification throws an error
 */
export default function useRecaptcha({
  onSuccess,
  onFail,
  onError,
}: {
  onSuccess: () => void;
  onFail: () => void;
  onError?: (error: Error) => void;
}) {
  const [verifyRecaptcha, { isSuccess, isLoading }] =
    useVerifyRecaptchaMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaValidated, setCaptchaValidated] = useState<boolean>(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // Execute recaptcha not yet available
      return;
    }

    const token = await executeRecaptcha('smsform');

    try {
      // call the verifyRecaptcha mutation
      const response = await verifyRecaptcha({ token }).unwrap();
      // call callbacks based on the response
      response.success ? onSuccess() : onFail();
      // change the state to prevent the useEffect from triggering the verification again
      setCaptchaValidated(true);
    } catch (error) {
      if (onError) onError(error as Error);
    }

    // Do whatever you want with the token
  }, [executeRecaptcha, onError, onFail, onSuccess, verifyRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    if (!isLoading && !isSuccess && !captchaValidated) {
      handleReCaptchaVerify();
    }
  }, [handleReCaptchaVerify, captchaValidated, isLoading, isSuccess]);
}
