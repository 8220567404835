export enum AlertSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export const SEVERITY_ORDER = [
  AlertSeverity.ERROR,
  AlertSeverity.WARNING,
  AlertSeverity.INFO,
  AlertSeverity.SUCCESS,
];
