const mailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const phoneRegex = /^\+[1-9]\d{1,14}$/;
const passwordRegex = /^(?=.*[A-Z\u00C0-\u00DC])(?=.*\d)[\w\W]{8,80}$/;
const nameRegex = /^[^#&*[\]()=<^>%${},\\!/]{0,30}$/;
const nicknameRegex = /^(?!\.\.)[\w][\w.]{1,28}[\w]$/;

export const isValidEmail = (value: string) =>
  mailRegex.test(value?.trim().toLocaleLowerCase() || '');

export const isValidCellphone = (value: string) => phoneRegex.test(value);

export const isValidPassword = (value: string) => passwordRegex.test(value);

export const isValidName = (value: string) => nameRegex.test(value);

export const isValidNickname = (value: string) => nicknameRegex.test(value);

export const isSameArray = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
