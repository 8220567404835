import { TypographyVariant } from '@mui/material';
import { useMemo } from 'react';

import {
  CARD_DESKTOP,
  CARD_MOBILE,
  CARD_SMALL_MOBILE,
  CARD_TABLET,
  RELATED_CARD,
  RELATED_CARD_LARGE,
} from '../../components/MatchCard/MatchCard.constants';
import useDesktopDeviceMediaQuery from '../useDesktopDeviceMediaQuery';
import useDesktopMediaQuery from '../useDesktopMediaQuery';
import useMobileMediaQuery from '../useMobileMediaQuery';
import useSmallMobileMediaQuery from '../useSmallMobileMediaQuery';

export const useGetCardSizes = (isRelatedProduct: boolean) => {
  const isDesktop = useDesktopMediaQuery();
  const isMobile = useMobileMediaQuery();
  const isSmallMobile = useSmallMobileMediaQuery();
  const isDeviceQuery = useDesktopDeviceMediaQuery();

  const getCardSize = useMemo(() => {
    if (isRelatedProduct) {
      if (isDeviceQuery) {
        return RELATED_CARD_LARGE;
      }
      return RELATED_CARD;
    }
    if (isDesktop) return CARD_DESKTOP;
    if (isSmallMobile) return CARD_SMALL_MOBILE;
    if (isMobile) return CARD_MOBILE;
    return CARD_TABLET;
  }, [isDesktop, isMobile, isSmallMobile, isRelatedProduct, isDeviceQuery]);

  const assignClubDistance = (
    originalDistance: number,
    team: 'home' | 'away',
  ): number | string => {
    if (!isRelatedProduct) {
      return originalDistance;
    }
    return team === 'home' ? '56.5%' : '20%';
  };
  const assignClubDistanceHorizontal = (originalDistance: number): number => {
    if (!isRelatedProduct) return originalDistance;
    return 0;
  };

  const assignRelatedFontSize = (
    originalHeight: TypographyVariant,
  ): TypographyVariant => {
    if (isRelatedProduct) {
      return isDeviceQuery ? 'body2' : 'caption';
    }
    return originalHeight;
  };

  return {
    getCardSize,
    assignRelatedFontSize,
    assignClubDistanceHorizontal,
    assignClubDistance,
  };
};
