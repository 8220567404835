export const PER_PAGE_DISPLAY = 4;
export const CAROUSEL_CONTAINER_HEIGHT = 640;
export const CAROUSEL_CONTAINER_WIDTH = 380;

export const COLLECTIONS_TITLE_SKELETON_HEIGHT = { xs: 65, sm: 35 };
export const COLLECTIONS_TITLE_SKELETON_WIDTH = { xs: 210, sm: 350 };

const CARD_COLLECTIONS_LIST_HEIGHT = 184;

export { CARD_COLLECTIONS_LIST_HEIGHT };
