import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

export const SHARE_BUTTONS = [
  {
    component: WhatsappShareButton,
    icon: <WhatsappIcon round />,
    key: 'whatsapp',
  },
  {
    component: LineShareButton,
    icon: <LineIcon round />,
    key: 'line',
  },
  {
    component: EmailShareButton,
    icon: <EmailIcon round />,
    key: 'email',
  },
  {
    component: FacebookShareButton,
    icon: <FacebookIcon round />,
    key: 'facebook',
  },
  {
    component: VKShareButton,
    icon: <VKIcon round />,
    key: 'VK',
  },
  {
    component: TelegramShareButton,
    icon: <TelegramIcon round />,
    key: 'telegram',
  },
  {
    component: TwitterShareButton,
    icon: <TwitterIcon round />,
    key: 'twitter',
  },
  {
    component: LinkedinShareButton,
    icon: <LinkedinIcon round />,
    key: 'linkedin',
  },
  {
    component: RedditShareButton,
    icon: <RedditIcon round />,
    key: 'reddit',
  },
  {
    component: HatenaShareButton,
    icon: <HatenaIcon round />,
    key: 'hatena',
  },
];
