import { useEffect, useState } from 'react';

import {
  useGetReferralCodeQuery,
  useGetSummaryQuery,
} from '../../../features/referrals/Referrals.api';

export const useFetchData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isUninitialized, setIsUninitialized] = useState(true);

  const {
    data: referralCodeData,
    isLoading: referralCodeIsLoading,
    isError: isErrorReferralCode,
    isUninitialized: referralCodeIsUninitialized,
  } = useGetReferralCodeQuery();

  const {
    data: summaryData,
    isLoading: summaryIsLoading,
    isError: summaryIsError,
    isUninitialized: summaryIsUninitialized,
  } = useGetSummaryQuery();

  const data = {
    referralCodeResponse: referralCodeData,
    summaryResponse: summaryData,
  };

  useEffect(() => {
    if (summaryIsError) setIsError(true);
  }, [summaryIsError]);

  useEffect(() => {
    if (!referralCodeIsLoading && !summaryIsLoading) setIsLoading(false);
  }, [referralCodeIsLoading, summaryIsLoading]);

  useEffect(() => {
    if (!summaryIsUninitialized && !referralCodeIsUninitialized)
      setIsUninitialized(false);
  }, [referralCodeIsUninitialized, summaryIsUninitialized]);

  return {
    isLoading,
    isError,
    isErrorReferralCode,
    isUninitialized,
    data,
  };
};
