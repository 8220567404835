import { Grid, Skeleton } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import {
  CARD_HEIGHT_DESKTOP,
  CARD_MOBILE_HEIGHT,
  CARD_MOBILE_WIDTH,
  CARD_WIDTH_DESKTOP,
} from '../profile/Profile.constant';
import {
  COLLECTABLE_SKELETON_AMOUNT,
  COLLECTABLE_SKELETON_FILTERS_AMOUNT,
} from './Marketplace.constants';

export const CollectableMarketSkeletons = () => {
  const isDesktop = useDesktopDeviceMediaQuery();
  const isSmallestMobile = useSmallMobileMediaQuery();
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  return (
    <Grid justifyContent="center" container>
      {isDesktop && (
        <Grid md={3} mt={SpaceSizes.lg} width="25%" xs={12} item>
          {Array.from(
            { length: COLLECTABLE_SKELETON_FILTERS_AMOUNT },
            (_, index) => {
              return (
                <Skeleton
                  key={`skeleton_colletablemarkettab_${_}${index}`}
                  height={SpaceSizes.lgPlus}
                  sx={{ marginY: SpaceSizes.xs }}
                  variant="rectangular"
                  width="90%"
                />
              );
            },
          )}
        </Grid>
      )}
      <Grid
        columnSpacing={{ xs: SpaceSizes.xs, sm: SpaceSizes.mdPlus }}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        rowSpacing={SpaceSizes.mdPlus}
        width={{ xs: '100%', lg: '75%' }}
        container
      >
        {Array.from({ length: COLLECTABLE_SKELETON_AMOUNT }).map((_, index) => (
          <Grid
            key={`${timestamp.toString() + String(index)}`}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            item
          >
            <Skeleton
              style={{
                borderRadius: '20px',
                height: isSmallestMobile
                  ? CARD_MOBILE_HEIGHT
                  : CARD_HEIGHT_DESKTOP,
                width: isSmallestMobile
                  ? CARD_MOBILE_WIDTH
                  : CARD_WIDTH_DESKTOP,
              }}
              variant="rectangular"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
