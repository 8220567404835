export const TRADE_IMAGE_SIZE = {
  smallMobile: {
    height: 125,
    width: 125,
  },
  mobile: {
    height: 250,
    width: 250,
  },
  desktop: {
    height: 400,
    width: 400,
  },
};

export const TRADE_SKELETON_TEXT_WIDTH = {
  sm: 100,
  md: 150,
  lg: 200,
};
export const TRADE_TITLE_FONT_SIZE = {
  xs: 18,
  sm: 20,
  md: 24,
  lg: 32,
};

export const TRADE_TEAM_FONT_SIZE = {
  xs: 16,
  sm: 18,
  md: 20,
  lg: 24,
};

export const EMPTY_FONT_SIZE = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
};
export const DETAIL_DISPLAY_LIMIT = 3;
export const CARD_TRADE_MIN_HEIGHT = 800;
