import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import {
  AddItemApiRequest,
  AddItemApiResponse,
  ChangeItemQuantityApiRequest,
  GetItemSummaryApiResponse,
  GetShoppingCartApiResponse,
  GetTotalItemsApiResponse,
} from './ShoppingCart.types';

export const shoppingCartApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getShoppingCart: builder.query<GetShoppingCartApiResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getTotalItems: builder.query<GetTotalItemsApiResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart/total-items`,
        method: 'GET',
      }),
      providesTags: [RTKTags.TotalItems],
    }),
    getItemSummary: builder.query<GetItemSummaryApiResponse, string>({
      query: (productId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart/products/${productId}/summary`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    addItem: builder.mutation<AddItemApiResponse, AddItemApiRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart/add-item`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [RTKTags.TotalItems],
    }),
    deleteItem: builder.mutation<void, string>({
      query: (productId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart/products/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.TotalItems],
    }),
    undoDelete: builder.mutation<void, string>({
      query: (productId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart/products/${productId}/undo-delete`,
        method: 'POST',
      }),
      invalidatesTags: [RTKTags.TotalItems],
    }),
    changeItemQuantity: builder.mutation<void, ChangeItemQuantityApiRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_SHOPPING_CART}shopping-cart/change-item-quantity`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [RTKTags.TotalItems],
    }),
  }),
});

export const {
  useGetTotalItemsQuery,
  useGetItemSummaryQuery,
  useAddItemMutation,
  useGetShoppingCartQuery,
  useDeleteItemMutation,
  useUndoDeleteMutation,
  useChangeItemQuantityMutation,
} = shoppingCartApi;
