export const IMAGE_FALLBACK = {
  HEIGHT: '96px',
  WIDTH: '96px',
  BORDER_RADIUS: '8px',
};

export const CHECKOUT_TYPE = {
  CART: 'cart',
  DIRECT: 'direct',
};

export type Inputs = {
  amount: number;
  amountEquivalent: number;
};
