export const TRADE_ITEM_SELF_FONT_SIZE = {
  xs: 13,
  sm: 14,
  md: 15,
  lg: 16,
};
export const TRADE_ITEM_NAME_FONT_SIZE = {
  xs: 14,
  sm: 15,
  md: 16,
  lg: 17,
};
