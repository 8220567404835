import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import Env from '../../../env/env';
import { useLoginByGoogleMutation } from '../../../features/session/Session.api';
import {
  setSession,
  setShouldTrackSignup,
} from '../../../features/session/Session.slice';
import { getRegisteredCookie } from '../../../utils/cookies';
import { TAG_EVENT } from '../../../utils/tagger';
import { useAppDispatch } from '../../hooks';
import i18n, { geti18nResolvedLanguage } from '../../i18n';

const useGoogleSignIn = (referralCode?: string) => {
  const [login] = useLoginByGoogleMutation();
  const dispatch = useAppDispatch();
  const sendDataToGTM = useGTMDispatch();

  const handleLoginPress = async (token: string) => {
    const session = await login({
      jwtToken: token,
      lang_id: geti18nResolvedLanguage(),
      base_url: `${Env.REACT_APP_BASE_APP_URL}account-activation/` || '',
      ...(referralCode && { referral_code: referralCode }),
    }).unwrap();
    sendDataToGTM({
      event: TAG_EVENT.ONBOARDING.LOGIN,
      user_id: session.user_id,
      method: 'Google',
    });
    const isRegistered = getRegisteredCookie();
    if (!isRegistered) {
      dispatch(setShouldTrackSignup(true));
    }
    // Load session into the store
    dispatch(setSession(session));
    i18n.changeLanguage(session?.user?.lang_id);
  };
  return handleLoginPress;
};
export default useGoogleSignIn;
