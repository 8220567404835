import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { StoryReel } from '../../components/Story/StoryReel';
import useDesktopMediaQuery from '../../hooks/useDesktopMediaQuery';
import {
  ICollectionGroupList,
  ICollectionsGroup,
} from './CollectionCard.types';
import {
  COLLECTIONS_FONT_SIZE,
  COLLECTIONS_VARIABLE_SHADOW,
  SKELETON_SIZE_HEIGHT,
} from './CollectionGroupList.constants';

export const CollectionGroupList = ({
  isLoading,
  collections,
}: ICollectionGroupList) => {
  const { t } = useTranslation();
  const isDesktop = useDesktopMediaQuery();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (id: string) => {
      navigate(id);
    },
    [navigate],
  );

  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      gap={{ xs: SpaceSizes.sm, md: SpaceSizes.xs }}
      justifyContent="center"
      style={{ marginTop: 0 }}
      width="100%"
    >
      <StoryReel />
      <Stack
        alignItems="flex-start"
        flexDirection={isDesktop ? 'row' : 'column'}
        gap={{ xs: SpaceSizes.sm, md: SpaceSizes.xs }}
        justifyContent="center"
        style={{ marginTop: 0 }}
        width="100%"
      >
        {isLoading || !collections ? (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            gap={{ xs: SpaceSizes.sm, md: SpaceSizes.xs }}
            spacing={SpaceSizes.md}
            width="100%"
          >
            <Skeleton
              sx={{
                height: SKELETON_SIZE_HEIGHT,
                width: { xs: '100%', md: '80%' },
              }}
              variant="rectangular"
            />
            <Skeleton
              sx={{
                height: SKELETON_SIZE_HEIGHT,
                width: { xs: '100%', md: '80%' },
              }}
              variant="rectangular"
            />
          </Stack>
        ) : (
          <>
            {collections?.length &&
              collections.map(({ id, url }: ICollectionsGroup) => {
                return (
                  <Stack
                    key={id}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleNavigate(id)}
                  >
                    <Container
                      id={id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Card
                        sx={{
                          position: 'relative',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <CardMedia component="img" image={url} title={id} />
                        <CardContent
                          sx={{
                            position: 'absolute',
                            boxShadow: COLLECTIONS_VARIABLE_SHADOW,
                            bottom: 0,
                            backgroundColor: 'transparent',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <Stack
                            flexDirection="column"
                            gap={SpaceSizes.smPlus}
                            justifyContent="space-between"
                            sx={{
                              paddingX: SpaceSizes.md,
                              left: 0,
                              position: 'absolute',
                              bottom: SpaceSizes.smPlus,
                            }}
                            width="100%"
                          >
                            <Typography
                              fontSize={COLLECTIONS_FONT_SIZE}
                              fontWeight={600}
                            >
                              {t(`pages.collections.items.${id}`)}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Container>
                  </Stack>
                );
              })}
            {/* <Stack
              key="laliga"
              sx={{ cursor: 'pointer' }}
              onClick={() => toggleLaLigaModal(!showLaLigaModal)}
            >
              <Container
                id="laliga"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card
                  sx={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`${env.REACT_APP_STATIC_BASE_URL}marketplace/collections/laliga/primera-division/2022-2023/laliga/banners/desktop.jpg`}
                    title="laliga"
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      boxShadow: COLLECTIONS_VARIABLE_SHADOW,
                      bottom: 0,
                      backgroundColor: 'transparent',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Stack
                      flexDirection="column"
                      gap={SpaceSizes.smPlus}
                      justifyContent="space-between"
                      sx={{
                        paddingX: SpaceSizes.md,
                        left: 0,
                        position: 'absolute',
                        bottom: SpaceSizes.smPlus,
                      }}
                      width="100%"
                    >
                      <Typography
                        fontSize={COLLECTIONS_FONT_SIZE}
                        fontWeight={600}
                      >
                        {t(`pages.collections.items.${'laliga'}`)}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Container>
            </Stack> */}
          </>
        )}
        {/* <Dialog
          open={showLaLigaModal}
          fullWidth
          onClose={() => toggleLaLigaModal(!showLaLigaModal)}
        >
          <DialogTitle>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                {t('pages.collections.items.laliga')}
              </Typography>
              <IconButton onClick={() => toggleLaLigaModal(!showLaLigaModal)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {t('pages.collections.description.laliga')}
            </Typography>
          </DialogContent>
        </Dialog> */}
      </Stack>
    </Stack>
  );
};
