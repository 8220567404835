import { styled } from '@mui/material';
import React, { useRef, useState } from 'react';

import { VideoProps } from './Video.types';

const VideoWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  // maxWidth: '920px',
  margin: '0 auto',
}));

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
  zIndex: 0,
});

const CoverImg = styled('img')<{ isHidden: boolean }>(({ isHidden }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  display: isHidden ? 'none' : 'block',
  zIndex: 1,
}));

export const Video = ({ location, isActive, coverImageUrl }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  if (isPlaying && !isActive) {
    videoRef.current?.pause();
    setIsPlaying(false);
  }

  const videoHandler = () => {
    if (isPlaying) {
      videoRef.current?.pause();
      setIsPlaying(false);
    } else {
      videoRef.current?.play();
      setIsPlaying(true);
    }
  };

  return (
    <VideoWrapper>
      <StyledVideo
        ref={videoRef}
        preload="auto"
        src={location}
        playsInline
        onClick={() => videoHandler()}
      >
        <p>Cant play video</p>
      </StyledVideo>

      <CoverImg
        alt=""
        isHidden={isPlaying}
        src={
          coverImageUrl ||
          'https://static.prod.golball.com/home/video-place-holder-small.png'
        }
        onClick={() => videoHandler()}
      />
    </VideoWrapper>
  );
};
