import Env from '../../env/env';
import { api } from '../api/Api';
import {
  GetGolpointsBalanceApiResponse,
  GetTiersApiResponse,
} from './Golpoints.types';

export const golpointsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGolpointsBalance: builder.query<GetGolpointsBalanceApiResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_GOLPOINTS}golpoints/balance`,
        method: 'GET',
      }),
    }),
    getTiers: builder.query<GetTiersApiResponse, void>({
      query: (from) => ({
        url: `${Env.REACT_APP_BASE_API_URL_GOLPOINTS}golpoints/tiers`,
        method: 'GET',
        params: {
          from,
        },
      }),
    }),
  }),
});

export const { useGetGolpointsBalanceQuery, useGetTiersQuery } = golpointsApi;
