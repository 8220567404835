import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { InformationContainer } from '../../components/InformationContainer/InformationContainer';
import { RelatedProducts } from '../../components/RelatedProducts/RelatedProducts';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { useFetchData } from './useFetchData';

const ConfirmationCart = () => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const { t } = useTranslationByNamespaces('pages.confirmationCart');
  const {
    data,
    isLoading,
    isUninitialized,
    isError,
    handleCreateCheckout,
    createCheckoutIsLoading,
  } = useFetchData();

  const { shoppingCartResponse } = data;

  const {
    total_points: totalPoints,
    total_items: totalItems,
    product,
  } = shoppingCartResponse || {};

  return (
    <PageContainer
      {...(isError ? { errorCode: t('errors.GENERIC_ERROR') } : {})}
      id="confirmationCartPage"
      loading={isLoading || isUninitialized}
      title={t('title')}
      trackCode={TAG_EVENT.CONTENT_GROUP.CONFIRMATION_CART}
      backButton
    >
      <Stack gap={isTablet ? SpaceSizes.xl : SpaceSizes.xxl2} width="100%">
        <InformationContainer
          createCheckoutIsLoading={createCheckoutIsLoading}
          handleCreateCheckout={handleCreateCheckout}
          product={product}
          totalItems={totalItems}
          totalPoints={totalPoints}
        />

        <RelatedProducts
          canUserClaim={false}
          productId={product?.product_id}
          title={
            <Typography
              fontWeight="bold"
              textAlign="center"
              variant={isMobile ? 'body1' : 'h5'}
            >
              {t('relatedProducts.title')}
            </Typography>
          }
        />
      </Stack>
    </PageContainer>
  );
};

export default ConfirmationCart;
