import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import Panel from '../Panel/Panel';
import ProductDetailTitle from '../ProductDetailTitle/ProductDetailTitle';
import { CountdownComponent } from './Countdown';
import { TriviaKnowMore } from './TriviaKnowMore';

export const TriviaCountdown = ({
  assigmentStartDate,
  isOwned,
}: {
  assigmentStartDate: string;
  isOwned: boolean;
}) => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.countdown');

  return (
    <Panel>
      <Stack gap={SpaceSizes.sm}>
        {!isOwned && (
          <ProductDetailTitle bold={false} textAlign="left">
            {t('title')}
          </ProductDetailTitle>
        )}
        <TriviaKnowMore isOwned={isOwned} />
        <CountdownComponent datetime={assigmentStartDate} />
      </Stack>
    </Panel>
  );
};
