import Env from '../../env/env';
import { api, RTKTagsAsArray } from '../api/Api';
import { User } from '../users/Users.types';
import {
  ACTIVATION_ALREADY_MADE,
  CODE_ALREADY_USED,
  INVALID_RECOVERY_DATA,
} from './Session.constants';

export interface CommonSessionData {
  session_id: string;
  jwtToken: string;
  due_date: string;
  user_id: string;
}

export interface AuthenticatedSessionResponse extends CommonSessionData {
  user: User;
}

export interface AuthenticatedSessionRequest {
  login: string;
  password: string;
}

export interface AuthenticatedGoogleSessionRequest {
  jwtToken: string | undefined;
  lang_id: string;
  base_url: string;
  referral_code?: string;
}

export interface AuthenticatedAppleSessionRequest {
  nonce: string;
  jwtToken: string;
  lang_id: string;
  user: {
    photo_url: string;
    first_name: string;
    last_name: string;
  };
}

export interface AnonymousSessionResponse extends CommonSessionData {
  anonymous: boolean;
}

export interface AnonymousSessionRequest {
  seed: string;
}

export interface AccountRecoveryRequest {
  email: string;
  baseUrl: string;
}

export interface AccountRecoveryResetPasswordRequest {
  recoveryId: string;
  email: string;
  password: string;
}

export interface AccountRecoveryResetPasswordResponse {
  status: 'string';
}

export interface LocationResponse {
  country: string;
  ip: string;
}

export const sessionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ipLocation: builder.query<LocationResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_COUNTRY_LOCATION}`,
        method: 'GET',
      }),
    }),
    anonymousSession: builder.mutation<
      AnonymousSessionResponse,
      AnonymousSessionRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/anonymous`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: RTKTagsAsArray(), // This will invalidate all cache when user logs out
    }),
    login: builder.mutation<
      AuthenticatedSessionResponse,
      AuthenticatedSessionRequest
    >({
      query: (credentials) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth`,
        method: 'POST',
        body: credentials,
      }),
    }),
    loginByGoogle: builder.mutation<
      AuthenticatedSessionResponse,
      AuthenticatedGoogleSessionRequest
    >({
      query: (token) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/google`,
        method: 'POST',
        body: token,
      }),
    }),
    loginByApple: builder.mutation<
      AuthenticatedSessionResponse,
      AuthenticatedAppleSessionRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/apple`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: RTKTagsAsArray(),
    }),
    accountRecovery: builder.mutation<
      Record<string, unknown>,
      AccountRecoveryRequest
    >({
      query: (payload) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/accountRecovery`,
        method: 'POST',
        body: payload,
      }),
    }),
    accountRecoveryResetPassword: builder.mutation<
      Record<string, unknown>,
      AccountRecoveryResetPasswordRequest
    >({
      query: (payload) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/accountRecovery/${payload.recoveryId}`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const sessionRecoveryError = [
  INVALID_RECOVERY_DATA,
  CODE_ALREADY_USED,
  ACTIVATION_ALREADY_MADE,
];
export const sessionApiExtractErrors = (error: unknown) => {
  const err = error as { data: { code: string } };
  return sessionRecoveryError.find((errorCode) => errorCode === err?.data?.code)
    ? `accountRecoveryError_${err.data.code}`
    : 'accountRecoveryError';
};

export const {
  useIpLocationQuery,
  useAnonymousSessionMutation,
  useLoginMutation,
  useLoginByGoogleMutation,
  useLoginByAppleMutation,
  useAccountRecoveryMutation,
  useAccountRecoveryResetPasswordMutation,
} = sessionApi;
