import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { SearchResults } from '../../components/search/SearchResults/SearchResults';
import useSearchBookmarkedWithInfiniteScroll from '../../hooks/search/useSearchBookmarkedWithInfiniteScroll';

const Bookmarks = () => {
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    isError,
    isUninitialized,
    enterWaypoint,
    isLastPage,
  } = useSearchBookmarkedWithInfiniteScroll();

  return (
    <PageContainer
      {...(isError && { errorCode: 'error.DEFAULT' })}
      id="bookmarksPage"
      loading={isLoading || isUninitialized}
      title={t('pages.bookmarks.pageTitle')}
    >
      <Grid container>
        {data && data?.results?.length > 0 && (
          <Grid
            display="flex"
            justifyContent="center"
            spacing={SpaceSizes.lg}
            xs={12}
            container
            item
          >
            <Grid item>
              <SearchResults
                enterWaypoint={enterWaypoint}
                isLastPage={isLastPage}
                results={data.results}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};

export default Bookmarks;
