import { Box, css, Stack, styled, Typography } from '@mui/material';
import React from 'react';

import { TopUsersRankI } from '../../../features/users/responses/Rank';
import { useGetRanksQuery } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { TableRankSkeleton } from './TableRank.skeleton';
import { TableRankRow } from './TableRankRow';

type BoxLoggedInUserProps = React.HTMLAttributes<HTMLDivElement> & {
  $isMobile: boolean;
};

const BoxLoggedInUser = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isMobile',
})<BoxLoggedInUserProps>(({ theme, $isMobile }) =>
  css({
    background: theme.palette.primary.dark,
    justifyContent: 'center',
    borderRadius: '16px',
    width: $isMobile ? '124px' : '170px',
    height: $isMobile ? '24px' : '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 4px',
  }),
);

export const TableRank = () => {
  const { t } = useTranslationByNamespaces('components.tableRank');
  const isMobile = useMobileMediaQuery();
  const { data, isLoading } = useGetRanksQuery();

  if (isLoading) {
    return <TableRankSkeleton />;
  }
  const {
    top_users_ranking: topRanking,
    logged_in_user_ranking: loggedInUserRanking,
  } = data || {};
  return (
    <Stack gap={SpaceSizes.md} style={{ width: '100%' }}>
      {loggedInUserRanking && (
        <BoxLoggedInUser
          $isMobile={isMobile}
          data-testid="payment-rank-position"
          flexDirection="row"
        >
          <Typography fontWeight="700" variant={isMobile ? 'cButton' : 'body2'}>
            {t('yourPosition')} {loggedInUserRanking.position}
          </Typography>
        </BoxLoggedInUser>
      )}
      <Stack gap={SpaceSizes.md}>
        {topRanking?.map((item: TopUsersRankI, index: number) => (
          <TableRankRow
            key={`${item.balls_amount / index + Date.now()}`}
            data={item}
          />
        ))}
        {loggedInUserRanking && !loggedInUserRanking.in_ranking ? (
          <TableRankRow data={loggedInUserRanking} />
        ) : null}
      </Stack>
    </Stack>
  );
};
