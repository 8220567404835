import { map, props } from 'ramda';

import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import { getURLParamByKey } from '../router/router.utils';
import { TEAM_KEY } from '../searchfilter/Filters.constants';
import {
  OfferResponse,
  PublishItemRequest,
  SendOfferRequest,
  TradeItemDetailByTradeIdResponse,
  TradeItemDetailResponse,
  TradeItemRequest,
  TradeItemResponse,
} from './Trades.types';

export const tradesFiltersURL = [TEAM_KEY];

const transformSearchRequestToSearchParams = (request: TradeItemRequest) => {
  const { appliedFilters, pagination, currentBrand } = request;
  let searchParams: URLSearchParams;
  if (appliedFilters && appliedFilters.length > 0) {
    const p = map(props(['id', 'value']));
    searchParams = new URLSearchParams(p(appliedFilters));
  } else {
    searchParams = new URLSearchParams();
  }

  if (pagination.limit !== undefined) {
    searchParams.set('limit', pagination.limit.toString());
  }

  if (pagination.offset !== undefined) {
    searchParams.set('offset', pagination.offset.toString());
  }

  if (currentBrand && currentBrand.id !== 'default') {
    searchParams.set('brand', currentBrand.id);
  }

  tradesFiltersURL.forEach((filter) => {
    if (getURLParamByKey(filter)) {
      searchParams.set(filter, getURLParamByKey(filter));
    }
  });
  return searchParams.toString();
};

export const tradesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    publishProduct: builder.mutation<void, PublishItemRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [RTKTags.MyCollection],
    }),
    unpublishProduct: builder.mutation<void, string>({
      query: (txID) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/${txID}/dismiss`,
        method: 'POST',
      }),
      invalidatesTags: [RTKTags.MyCollection],
    }),
    getTrades: builder.query<TradeItemResponse, TradeItemRequest>({
      query: (request) => {
        const urlTradeSearchParams =
          transformSearchRequestToSearchParams(request);
        return `${Env.REACT_APP_BASE_API_URL_TRADE}trades?q=${request.query}&${urlTradeSearchParams}`;
      },
      providesTags: [RTKTags.Trades],
      serializeQueryArgs: ({ queryArgs }) => {
        const value = [
          queryArgs.currentBrand?.id || '',
          queryArgs.query || '',
          queryArgs.appliedFilters ?? [],
        ];
        return value;
      },
      merge: (currentCache, newItems) => {
        if (
          !newItems.results[0] ||
          currentCache.results.find(
            (trade) => trade.product_id === newItems.results[0].product_id,
          )
        ) {
          return currentCache;
        }
        const newResponse: TradeItemResponse = {
          results: currentCache.results.concat(newItems.results),
          paging: newItems.paging,
          filters: newItems.filters,
          appliedFilters: newItems.appliedFilters,
          search: newItems.search,
        };

        return newResponse;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        (currentArg?.pagination?.offset ?? 0) >
        (previousArg?.pagination?.offset ?? 0),
    }),
    getTradeIdDetail: builder.query<TradeItemDetailByTradeIdResponse, string>({
      query: (tradeId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/${tradeId}`,
        method: 'GET',
      }),
    }),
    getOffers: builder.query<OfferResponse[], void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/offers`,
        method: 'GET',
      }),
    }),
    getOffer: builder.query<OfferResponse, string>({
      query: (offerID) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/offers/${offerID}`,
        method: 'GET',
      }),
    }),
    rejectOffer: builder.query<void, string>({
      query: (txID) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/offers/${txID}/reject`,
        method: 'POST',
      }),
    }),
    acceptOffer: builder.query<void, string>({
      query: (txID) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/offers/${txID}/accept`,
        method: 'POST',
      }),
    }),
    sendOffer: builder.query<void, SendOfferRequest>({
      query: (body) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/offers`,
        method: 'POST',
        body,
      }),
    }),
    getTradeDetails: builder.query<TradeItemDetailResponse, string>({
      query: (id) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRADE}trades/products/${id}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTradeDetailsQuery,
  useGetTradeIdDetailQuery,
  useGetOffersQuery,
  useGetOfferQuery,
  useLazySendOfferQuery,
  useLazyRejectOfferQuery,
  useLazyAcceptOfferQuery,
  usePublishProductMutation,
  useUnpublishProductMutation,
  useGetTradesQuery,
} = tradesApi;
