import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Box, Container, Skeleton, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../../utils/tagger';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { SKELETON_LINE_HEIGHT } from '../../../pages/collection-album/CardAlbum.constants';
import BackButton from '../../BackButton/BackButton';
import { PageError } from '../../PageError/PageError';
import Spinner from '../../Spinner';
import { PageContainerProps } from './PageContainer.types';

export const PageContainer = ({
  id,
  title,
  children,
  loading = false,
  loadingWithSkeleton = false,
  errorCode = '',
  backButton = false,
  onBackButtonClick,
  maxWidth = 'xl',
  sx = {},
  titleSx = {},
  rightChild,
  trackCode,
}: React.PropsWithChildren<PageContainerProps>) => {
  const location = useLocation();
  const hasBackPage = useMemo(() => {
    return location.key !== 'default';
  }, [location.key]);

  const isMobile = useMobileMediaQuery();

  const sendDataToGTM = useGTMDispatch();
  useEffect(() => {
    if (isNotNilOrEmpty(trackCode)) {
      sendDataToGTM({
        event: TAG_EVENT.TRACK_CONTENT_GROUP,
        content_group: trackCode,
      });
    }
  }, [trackCode, sendDataToGTM]);

  return (
    <Box
      sx={{
        mx: { xs: SpaceSizes.md, sm: SpaceSizes.md, md: SpaceSizes.lg },
        my: { xs: SpaceSizes.md, sm: SpaceSizes.md, md: SpaceSizes.md },
        ...sx,
      }}
    >
      <Container maxWidth={maxWidth}>
        <Stack spacing={SpaceSizes.md}>
          <Stack data-testid={id}>
            {(backButton || title) && (
              <Stack alignItems="center" direction="row" gap={SpaceSizes.min}>
                {backButton && hasBackPage && (
                  <BackButton onClick={onBackButtonClick} />
                )}
                {!loading && isNotNilOrEmpty(title) && (
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {loadingWithSkeleton ? (
                      <Skeleton
                        height={SKELETON_LINE_HEIGHT}
                        variant="text"
                        width="25ch"
                      />
                    ) : (
                      <Typography
                        fontWeight="bold"
                        sx={{ ...titleSx }}
                        variant={isMobile ? 'h6' : 'h5'}
                      >
                        {title}
                      </Typography>
                    )}
                    {rightChild}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          <Stack alignItems="center">
            {loading ? (
              <Spinner />
            ) : isNotNilOrEmpty(errorCode) ? (
              <PageError errorCode={errorCode} />
            ) : (
              children
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
