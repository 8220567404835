/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, styled, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { SwiperSlide } from 'swiper/react';

import { ProductContent } from '../../../features/home/Home.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { Animation } from '../../components/Animate/Animate';
import { AnimationEnum } from '../../components/Animate/Animate.constants';
import { ComponentSwiper } from '../../components/ComponentSwiper/ComponentSwiper';
import { componentSwiperSizes } from '../../components/ComponentSwiper/ComponentSwiper.types';
import { ProductHomeCard } from './ProductHomeCard';

export const ProductSlider = ({
  title,
  prods,
  handleGoTo,
  nextMatches,
}: {
  title: string;
  prods: ProductContent[];
  handleGoTo: any;
  nextMatches: any;
}) => {
  const componentSizes = {
    [componentSwiperSizes.sm]: { width: '105px', height: '136px' },
    [componentSwiperSizes.md]: { width: '141px', height: '185px' },
    [componentSwiperSizes.lg]: { width: '358px', height: '474px' },
    [componentSwiperSizes.wide]: { width: '458px', height: '240px' },
  };

  const SliderTitle = styled(Typography)({
    color: 'white',
    fontSize: '20px',
    textAlign: 'left',
    fontWeight: 900,
    textTransform: 'uppercase',
    marginBottom: SpaceSizes.sm, // Using shitty margin because flex breaks the swiper
  });

  const sliderStyles = useCallback(
    (clickable: boolean, size: string, isAuto = false) => ({
      cursor: clickable ? 'pointer' : 'default',
      width: '100%',
      maxWidth: size === 'lg' ? '100%' : componentSizes[size].width,
      height: isAuto ? 'auto' : componentSizes[size].height,
    }),
    [],
  );

  return (
    <Box width="100%">
      <Animation
        key="products-animation"
        timeProps={{ duration: 0.85 }}
        transformProps={{ offsetX: '-15%' }}
        type={AnimationEnum.Offset}
        delayUntilVisible
      >
        <SliderTitle>{title}</SliderTitle>

        <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
          {(prods as ProductContent[])?.map((prod, index) => (
            <SwiperSlide
              key={prod?.product_id}
              style={sliderStyles(
                !!prod?.link,
                nextMatches?.size || componentSwiperSizes.md,
              )}
              onClick={() =>
                handleGoTo(
                  prod.link,
                  'home_teamProducts',
                  index,
                  prod?.product_id,
                )
              }
            >
              <ProductHomeCard key={prod.product_id} cardData={prod.card} />
            </SwiperSlide>
          ))}
        </ComponentSwiper>
      </Animation>
    </Box>
  );
};
