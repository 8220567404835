import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import {
  selectProductIsTriviaFinished,
  selectProductIsTriviaNotStarted,
  selectProductIsTriviaStarted,
} from '../../../../features/products/Product.selectors';
import { useProductContext } from '../../../../provider/ProductProvider/ProductProvider';
import BorderRadius from '../../../../theme/foundations/borders/BorderRadius';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { boxShadowMixin } from '../../../../utils/mixins';
import PiecesIcon from '../../../assets/img/pieces.svg';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import {
  TITLE_FONT_SIZE,
  TITLE_TRIVIA_FONT_SIZE,
} from './GolBallCardHeader.constants';

export const GolBallCardHeader = ({
  isGolden,
  hasTrivia,
}: {
  isGolden: boolean;
  hasTrivia: boolean;
}) => {
  const { t } = useTranslationByNamespaces('pages.publicProfile');
  const { t: tTag } = useTranslationByNamespaces('components.productCard.tags');
  const theme = useTheme();
  const product = useProductContext();

  const triviaStarted = selectProductIsTriviaStarted(product);
  const triviaNotStarted = selectProductIsTriviaNotStarted(product);
  const triviaFinished = selectProductIsTriviaFinished(product);

  const assignedTriviaStatus = () => {
    if (triviaNotStarted) {
      if (product?.assignment.trivia?.remaining_days === 0) {
        return t('trivia.status.comingSoon');
      }
      return t('trivia.status.someDays', {
        days: product?.assignment.trivia?.remaining_days?.toString() || '',
      });
    }

    if (triviaStarted) return t('trivia.status.active');
    return null;
  };

  const showQuantity = !(hasTrivia && !triviaFinished);

  return (
    <Stack
      alignItems="center"
      boxShadow={boxShadowMixin(theme.palette.secondary.light)}
      justifyContent="center"
      sx={[
        {
          borderRadius: `0 ${BorderRadius.md} 0 ${BorderRadius.md}`,
          padding: SpaceSizes.sm,
          paddingInline: SpaceSizes.md,
          backgroundColor: 'background.paper',
        },
        triviaStarted && {
          borderBottom: 1,
          borderLeft: 1,
          borderColor: theme.palette.success.light,
        },
        isGolden && {
          borderBottom: 1,
          borderLeft: 1,
          borderColor: theme.palette.golden.main,
        },
      ]}
    >
      {hasTrivia && !triviaFinished && (
        <Typography
          color={triviaStarted ? theme.palette.success.main : 'text.text'}
          fontSize={TITLE_TRIVIA_FONT_SIZE}
        >
          {assignedTriviaStatus()}
        </Typography>
      )}
      {product?.assignment?.type === 'auction' && (
        <Typography color="text.text" variant="body2">
          {tTag('auction')}
        </Typography>
      )}
      {showQuantity && (
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.xs}>
          <ImageFallback
            height={SpaceSizes.md}
            src={PiecesIcon}
            width={SpaceSizes.md}
          />
          <Typography fontSize={TITLE_FONT_SIZE}>
            x{product?.pieces?.length}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
