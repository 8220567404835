import { Box, Button, Divider, styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import env from '../../../../env/env';
import { CHECKOUT_STATUS } from '../../../../features/checkout/Checkout.constants';
import { CheckoutStatusCodeEnum } from '../../../../features/checkout/Checkout.types';
import { selectProduct3dCertificateMultimedia } from '../../../../features/products/Product.selectors';
import {
  EAssignmentType,
  ETriviaBattleStatus,
} from '../../../../features/products/Product.types';
import { useGetProductByIdQuery } from '../../../../features/products/Products.api';
import { useGetReferralCodeQuery } from '../../../../features/referrals/Referrals.api';
import { selectUserSession } from '../../../../features/session/Session.selector';
import { useGetUserRanksQuery } from '../../../../features/users/Users.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { formatGP } from '../../../../utils/formatNumbers';
import { AlertCard } from '../../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../../components/AlertCard/AlertSeverity';
import { ConfettiCelebration } from '../../../components/ConfettiCelebration/ConfettiCelebration';
import { PageContainer } from '../../../components/containers/PageContainer/PageContainer';
import { ExternalContent } from '../../../components/ExternalContent/ExternalContent';
import { ImageFallback } from '../../../components/ImageFallback/ImageFallback';
import { Link } from '../../../components/Link/Link';
import { ModelViewer } from '../../../components/ModelViewer/ModelViewer';
import { ReferFriend } from '../../../components/ReferFriend/ReferFriend';
import { RelatedProducts } from '../../../components/RelatedProducts/RelatedProducts';
import { TableRankRow } from '../../../components/TableRank/TableRankRow';
import { TriviaCountdown } from '../../../components/Trivia/TriviaCountdown';
import { useAppSelector } from '../../../hooks';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import {
  GOLPOINTS_CONTAINER_IMG_DESKTOP,
  GOLPOINTS_CONTAINER_IMG_MOBILE,
} from './PaymentSuccessCheckout.constants';
import { PaymentSuccessCheckoutSkeleton } from './PaymentSuccessCheckout.skeleton';
import { useFetchData } from './useFetchData';

const golpointsContainerImg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/checkout/golpoints-container.svg`;
const golpointsImg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/golpoints.png`;

const CenteredStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const FlexStartStack = styled(Stack)({
  alignItems: 'flex-start',
});

const HighLightedTypo = styled(Typography)({
  fontWeight: 700,
});

const BalanceDisplay = ({
  titleKey,
  balance,
  imgSrc,
  imgFallback,
}: {
  titleKey: string;
  balance?: number | string;
  imgSrc: string;
  imgFallback: string;
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <HighLightedTypo>{t(titleKey)}</HighLightedTypo>
      <Stack direction="row" gap={SpaceSizes.xs}>
        <HighLightedTypo>{formatGP(Number(balance))}</HighLightedTypo>
        <ImageFallback
          fallback={imgFallback}
          height={SpaceSizes.mdPlus}
          src={imgSrc}
          width={SpaceSizes.mdPlus}
        />
      </Stack>
    </Stack>
  );
};

const PaymentSuccessCheckout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();
  const user = useAppSelector(selectUserSession);

  // checkout status
  const {
    isLoading,
    isError,
    isUninitialized,
    stopPolling,
    data: { checkoutResponse, checkoutSummaryResponse },
  } = useFetchData();

  // 3d product
  const { product3dCertificateMultimedia } = useGetProductByIdQuery(
    checkoutSummaryResponse &&
      checkoutSummaryResponse.items &&
      checkoutSummaryResponse.items.length > 0
      ? { productId: checkoutSummaryResponse?.items[0].product_id }
      : skipToken,
    {
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        product3dCertificateMultimedia:
          selectProduct3dCertificateMultimedia(data),
      }),
    },
  );

  const { status: checkoutStatus } = checkoutResponse || {};

  // ranking
  const { data: userRankResponse } = useGetUserRanksQuery(
    checkoutStatus && checkoutStatus !== CHECKOUT_STATUS.COMPLETE
      ? skipToken
      : undefined,
  );

  // referral
  const { data: referralCodeData } = useGetReferralCodeQuery();

  useEffect(() => {
    if (checkoutStatus && checkoutStatus !== CHECKOUT_STATUS.PENDING) {
      stopPolling();
    }
  }, [checkoutStatus, stopPolling]);

  const isCheckoutWithItems = useMemo(
    () =>
      checkoutResponse &&
      checkoutSummaryResponse &&
      checkoutSummaryResponse?.items?.length > 0,
    [checkoutResponse, checkoutSummaryResponse],
  );

  const isCheckoutWithOnlyTopups = useMemo(
    () =>
      checkoutResponse &&
      checkoutSummaryResponse &&
      isNotNilOrEmpty(checkoutSummaryResponse?.topup) &&
      checkoutSummaryResponse?.items?.length === 0,
    [checkoutResponse, checkoutSummaryResponse],
  );

  // while checkout is pending, show skeleton
  if (
    !isError &&
    (isLoading || isUninitialized || checkoutStatus === CHECKOUT_STATUS.PENDING)
  ) {
    return <PaymentSuccessCheckoutSkeleton />;
  }

  return (
    <PageContainer
      {...(isError ? { errorCode: t('errors.GENERIC_ERROR') } : {})}
      id="payment-success-checkout"
      loading={isLoading}
      maxWidth="sm"
      title={
        !isError ? t('pages.checkout.paymentSuccessCheckout.pageTitle') : ''
      }
    >
      <Stack gap={SpaceSizes.lg} width="100%">
        <CenteredStack gap={SpaceSizes.md} width="100%">
          <Typography textAlign="center" variant="body1">
            {t('pages.checkout.paymentSuccessCheckout.thanks')}
          </Typography>
          {isCheckoutWithOnlyTopups && (
            <ImageFallback
              alt="Golpoints Logo"
              fallback={golpointsContainerImg}
              height={
                isMobile
                  ? GOLPOINTS_CONTAINER_IMG_MOBILE
                  : GOLPOINTS_CONTAINER_IMG_DESKTOP
              }
              src={golpointsContainerImg}
              width={
                isMobile
                  ? GOLPOINTS_CONTAINER_IMG_MOBILE
                  : GOLPOINTS_CONTAINER_IMG_DESKTOP
              }
            />
          )}
          {isCheckoutWithItems && product3dCertificateMultimedia && (
            <ModelViewer
              height="350px"
              poster={product3dCertificateMultimedia.thumb_location}
              src={product3dCertificateMultimedia.location}
            />
          )}
        </CenteredStack>
        {checkoutSummaryResponse?.status_code &&
          checkoutSummaryResponse?.status_code !==
            CheckoutStatusCodeEnum.OK && (
            <CenteredStack gap={SpaceSizes.mdPlus} width="100%">
              <AlertCard
                content={t(
                  `pages.checkout.paymentSuccessCheckout.errors.${checkoutSummaryResponse?.status_code}`,
                  { golpoints: checkoutSummaryResponse?.topup?.golpoints || 0 },
                )}
                id="error_out_of_stock"
                severity={AlertSeverity.WARNING}
                show
              />
            </CenteredStack>
          )}
        <CenteredStack gap={SpaceSizes.mdPlus}>
          <Stack alignItems="center" width="100%">
            <Typography>
              {t(
                'pages.checkout.paymentSuccessCheckout.subtitle.congratulations',
              )}
            </Typography>

            <Typography>
              {t(
                isCheckoutWithItems
                  ? 'pages.checkout.paymentSuccessCheckout.subtitle.buySuccessItems'
                  : 'pages.checkout.paymentSuccessCheckout.subtitle.buySuccessGolPoints',
              )}
            </Typography>
          </Stack>
          {!checkoutSummaryResponse?.total_points &&
            checkoutSummaryResponse?.items &&
            checkoutSummaryResponse?.items.length > 0 && (
              <ExternalContent
                filter={{
                  key: 'content_id',
                  value: 'payment_success_free_congrats',
                }}
                type="contents"
                vars={[
                  {
                    id: 'product_id',
                    content: checkoutSummaryResponse?.items[0].product_id || '',
                  },
                ]}
              />
            )}
          <FlexStartStack gap={SpaceSizes.smPlus} width="100%">
            <Typography fontWeight={500}>
              {t('pages.checkout.paymentSuccessCheckout.summary')}
            </Typography>
            {isCheckoutWithItems && (
              <Stack gap={SpaceSizes.xxs} width="100%">
                {checkoutSummaryResponse?.items?.map((item) => (
                  <FlexStartStack
                    key={item.product_id}
                    gap={SpaceSizes.sm}
                    width="100%"
                  >
                    <Stack flexDirection="row" gap={SpaceSizes.md}>
                      <Typography
                        fontWeight={500}
                        variant={isMobile ? 'caption' : 'body2'}
                      >
                        {item.title} {item.subtitle.line1}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        variant={isMobile ? 'caption' : 'body2'}
                      >
                        x{item.quantity}
                      </Typography>
                    </Stack>
                    <Divider sx={{ width: '100%' }} />
                  </FlexStartStack>
                ))}
              </Stack>
            )}
            {isCheckoutWithOnlyTopups && (
              <FlexStartStack gap={SpaceSizes.sm} width="100%">
                <Typography fontWeight={500}>
                  {formatGP(Number(checkoutSummaryResponse?.topup.golpoints))}{' '}
                  Gol-Points
                </Typography>
                <Divider sx={{ width: '100%' }} />
              </FlexStartStack>
            )}
            {isCheckoutWithItems && (
              <BalanceDisplay
                balance={checkoutSummaryResponse?.total_points}
                imgFallback={golpointsImg}
                imgSrc={golpointsImg}
                titleKey="pages.checkout.paymentSuccessCheckout.total"
              />
            )}
            <BalanceDisplay
              balance={checkoutSummaryResponse?.user_balance}
              imgFallback={golpointsImg}
              imgSrc={golpointsImg}
              titleKey="pages.checkout.paymentSuccessCheckout.myGolpoints"
            />
          </FlexStartStack>
          {isCheckoutWithItems &&
            checkoutSummaryResponse?.items.length === 1 &&
            checkoutSummaryResponse?.assignment &&
            checkoutSummaryResponse?.assignment.type ===
              EAssignmentType.TRIVIA &&
            checkoutSummaryResponse?.assignment.status ===
              ETriviaBattleStatus.NOT_STARTED && (
              <TriviaCountdown
                assigmentStartDate={
                  checkoutSummaryResponse?.assignment.start_date
                }
                isOwned
              />
            )}
        </CenteredStack>
        <Stack alignItems="center" gap={SpaceSizes.sm} width="100%">
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate('/marketplace')}
          >
            {t('pages.checkout.paymentSuccessCheckout.goToMarket')}
          </Button>
          <Button
            id="goto_market_congrats"
            variant="outlined"
            fullWidth
            onClick={() => navigate(`/user/${user?.user_id}`)}
          >
            {t('pages.checkout.paymentSuccessCheckout.goToMyGolballs')}
          </Button>
        </Stack>
        {isCheckoutWithItems && (
          <Box>
            {userRankResponse && (
              <Link to="/rank" underline="none">
                <TableRankRow
                  data={userRankResponse}
                  highlightSelf={false}
                  isThanksYouPage
                />
              </Link>
            )}
          </Box>
        )}
        {referralCodeData?.url && <ReferFriend link={referralCodeData?.url} />}
        {isCheckoutWithItems && (
          <RelatedProducts
            canUserClaim={false}
            productId={checkoutSummaryResponse?.items[0]?.product_id}
            title={
              <Typography fontWeight={700} variant="h5">
                {t('pages.checkout.paymentSuccessCheckout.related')}
              </Typography>
            }
          />
        )}
        <ConfettiCelebration />
      </Stack>
    </PageContainer>
  );
};

export default PaymentSuccessCheckout;
