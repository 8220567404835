import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { OfferedItem } from '../../../../../features/trades/Trades.types';
import SpaceSizes from '../../../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../../../../components/ImageFallback/ImageFallback';
import { useTranslationByNamespaces } from '../../../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../../../hooks/useMobileMediaQuery';
import TradeTypeIndicator from '../../../trade-offer-details/trade-type/TradeTypeIndicator';
import { TradeRequestItemProps } from './TradeRequestItem.types';

export const TradeRequestItem = ({ items, type }: TradeRequestItemProps) => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const isMobile = useMobileMediaQuery();

  const getGoalNumber = useCallback((offerItem: OfferedItem) => {
    const event = offerItem?.events?.find((e) => e.event_type === 'GOAL');
    let isHome = offerItem.player.team_id === offerItem?.match?.home.id;
    isHome = event?.event_data.is_own_goal ? !isHome : isHome;

    return isHome
      ? event?.event_data.home_partial_score
      : event?.event_data.away_partial_score;
  }, []);

  const getOppositeTeamName = useCallback((offerItem: OfferedItem) => {
    const event = offerItem?.events?.find((e) => e.event_type === 'GOAL');
    let isHome = offerItem.player.team_id === offerItem?.match?.home.id;
    isHome = event?.event_data.is_own_goal ? !isHome : isHome;

    return isHome ? offerItem?.match?.away.name : offerItem?.match?.home.name;
  }, []); // Añade las dependencias aquí si hay alguna

  return (
    <Stack flexDirection="column" gap={SpaceSizes.sm}>
      <TradeTypeIndicator size="small" type={type} />
      {items.map((item) => (
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
          <ImageFallback
            fallback={item.player.image}
            height={SpaceSizes.lgPlus}
            src={item.player.image}
            style={{ borderRadius: '25%' }}
            width={SpaceSizes.lgPlus}
          />
          <Stack flexDirection="column">
            <Typography fontSize={isMobile ? 'medium' : 'large'}>
              #{item.ball_id.toUpperCase()}
            </Typography>
            <Typography fontSize="small" sx={{ opacity: 0.6 }}>
              {item.player.name} - {t('item.goal')} #{getGoalNumber(item)} VS{' '}
              {getOppositeTeamName(item)}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
