import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import useDesktopMediaQuery from '../../../hooks/useDesktopMediaQuery';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import { BALL_URL } from './NoResults.constants';
import { INoResultProps } from './NoResults.types';

export const NoResults = ({ title, subtitle }: INoResultProps) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <Stack alignItems="center">
      <Box p={isDesktop ? SpaceSizes.xl : SpaceSizes.md}>
        <Stack
          alignItems="center"
          spacing={isDesktop ? SpaceSizes.xl : SpaceSizes.md}
        >
          <ImageFallback
            alt="No results ball"
            height={isDesktop ? '20%' : '35%'}
            src={BALL_URL}
            width={isDesktop ? '20%' : '35%'}
          />
          <Stack spacing={SpaceSizes.md}>
            <Typography fontWeight="bold" textAlign="center" variant="h6">
              {title}
            </Typography>
            <Typography textAlign="center" variant="h6">
              {subtitle}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
