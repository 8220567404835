import { LANGUAGES } from '../../app/i18n';
import Env from '../../env/env';
import { api } from '../api/Api';
import {
  ExternalContentContentByIdResponse,
  ExternalContentRequest,
} from './ExternalContent.types';

export const externalContentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // External content by id
    getExternalContentById: builder.query<
      ExternalContentContentByIdResponse,
      ExternalContentRequest
    >({
      query: (request) => {
        const langId = request.lang?.split('-')[0];
        const filters =
          request.id?.key && request.id?.value
            ? `&filters[${request.id.key}][$eq]=${request.id.value}`
            : '';

        return `${Env.REACT_APP_STATIC_BASE_URL_STRAPI}${request.type}?locale=${
          LANGUAGES.includes(langId) ? langId : 'es'
        }${filters}`;
      },
    }),
  }),
});

export const { useGetExternalContentByIdQuery } = externalContentApi;
