import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import React from 'react';

import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import { PropsClubIcon } from './ClubIcon.constant';

export const ClubIcon = ({ height, icon, teamName, width }: PropsClubIcon) => {
  const { t } = useTranslationByNamespaces('components.tableRank');

  if (teamName && icon) {
    return (
      <ImageFallback
        alt={teamName}
        fallback={icon}
        height={height}
        src={icon}
        width={width}
      />
    );
  }

  return (
    <Tooltip placement="top" title={t('undefinedTeam.tooltip')} followCursor>
      <HelpOutlineIcon
        sx={{
          height,
          width,
        }}
      />
    </Tooltip>
  );
};
