import { LoadingButton } from '@mui/lab';
import { Stack, SxProps, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import ProductPrice from '../ProductPrice/ProductPrice';
import { IPaymentButtonProps } from './PaymentButton.type';

export const PaymentButton = ({
  showPrice = true,
  handleBuy,
  disableButton = false,
  sx,
  buttonText,
  dataTestId = 'paymentWidgetBuyButton',
  isLoading = false,
  variantButton = 'contained',
  variant = 'h6',
  id,
  size = {},
  price,
  currency,
  icon,
  children,
}: IPaymentButtonProps) => {
  const { t } = useTranslationByNamespaces('pages.product.piece');

  const typographySx: SxProps = {
    fontWeight: 'bold',
    letterSpacing: 0.5,
    ...size,
  };

  return (
    <LoadingButton
      data-testid={dataTestId}
      disabled={disableButton}
      endIcon={icon}
      id={id}
      loading={isLoading}
      sx={{ ...sx, borderRadius: SpaceSizes.lg }}
      variant={variantButton}
      onClick={handleBuy}
    >
      <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.xs}>
        <Typography sx={typographySx} variant={variant}>
          {buttonText || t('buyTicket')}
        </Typography>
        {showPrice && (
          <Typography sx={typographySx} variant={variant}>
            |
          </Typography>
        )}
        {showPrice && (
          <ProductPrice
            currency={currency}
            size="large"
            sx={typographySx}
            value={price}
          />
        )}
      </Stack>
      {children && children}
    </LoadingButton>
  );
};
