import { SearchResponse } from '../../../features/searchfilter/responses/SearchResponse';
import { useSearchQuery } from '../../../features/searchfilter/SearchFilter.api';
import {
  selectSearchIsSkipped,
  selectSearchPath,
} from '../../../features/searchfilter/SearchFilter.selector';
import { syncAppliedFilters } from '../../../features/searchfilter/SearchFilter.slice';
import createUseDataWithInfiniteScroll from '../shared/createUseDataWithInfiniteScroll';

/**
 * Hook that let us reuse infinite scroll for search api
 * @returns
 */
const useSearchWithInfiniteScroll =
  createUseDataWithInfiniteScroll<SearchResponse>(
    useSearchQuery,
    syncAppliedFilters,
    selectSearchPath,
    selectSearchIsSkipped,
  );

export default useSearchWithInfiniteScroll;
