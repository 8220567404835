import React, { useMemo } from 'react';

import { CheckoutFree } from '../CheckoutFree/CheckoutFree';
import { CheckoutProduct } from '../CheckoutProduct/CheckoutProduct';
import { CheckoutRecharge } from '../CheckoutRecharge/CheckoutRecharge';
import { COMPONENT_KEYS, PRODUCT_FREE } from './CheckoutConditional.constants';
import { CheckoutConditionalProps } from './CheckoutConditional.types';

export const CheckoutConditional = ({
  paymentUrls,
  checkoutResponse,
}: CheckoutConditionalProps) => {
  const { tiers, totalPoints } = checkoutResponse || {};

  // Memoize the common props to avoid unnecessary re-renders
  const commonProps = useMemo(
    () => ({ checkoutResponse, paymentUrls }),
    [checkoutResponse, paymentUrls],
  );

  // Determine the component key based on conditions
  const componentKey = useMemo(() => {
    if (totalPoints === PRODUCT_FREE) return COMPONENT_KEYS.FREE;
    if (tiers) return COMPONENT_KEYS.RECHARGE;
    return COMPONENT_KEYS.PRODUCT;
  }, [totalPoints, tiers]);

  switch (componentKey) {
    case COMPONENT_KEYS.FREE:
      return <CheckoutFree {...commonProps} />;
    case COMPONENT_KEYS.RECHARGE:
      return <CheckoutRecharge {...commonProps} />;
    case COMPONENT_KEYS.PRODUCT:
      return <CheckoutProduct {...commonProps} />;
    default:
      return null;
  }
};
