import React from 'react';

import TriviaCountdown from './TriviaCountdown/TriviaCountdown';

const TriviaStatus = ({
  countdownInSeconds,
}: {
  countdownInSeconds: number;
}) => {
  return (
    <TriviaCountdown
      countdownInSeconds={countdownInSeconds >= 0 ? countdownInSeconds : 0}
    />
  );
};

export default TriviaStatus;
