import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';

interface AnswerTriviaButtonProps {
  disabled?: boolean;
  battleId?: string;
}

const AnswerTriviaButton = ({
  disabled,
  battleId,
}: AnswerTriviaButtonProps) => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.started');
  const navigate = useNavigate();

  return (
    <Button
      disabled={disabled}
      sx={{ width: 'fit-content' }}
      variant="contained"
      onClick={() => {
        navigate(`/trivia/${battleId}`);
      }}
    >
      {t('answer')}
    </Button>
  );
};

export default AnswerTriviaButton;
