import { Stack } from '@mui/material';
import React, { useState } from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { useMediumDesktopQuery } from '../../../hooks/useMediumDesktopQuery';
import NotificationsDropdown from '../../../pages/notifications/NotificationsDropdown';
import UserDropdown from '../UserDropdown/UserDropdown';
import { FavsDropdown } from './FavsDropdown';

interface Props {
  isAuthenticated: boolean;
}

const UserMenu = ({ isAuthenticated }: Props) => {
  const [isDropdownUserOpen, setIsDropdownUserOpen] = useState(false);

  const handleUserDropdown = (isOpen: boolean) => {
    setIsDropdownUserOpen(isOpen);
  };
  const isMediumDesktop = useMediumDesktopQuery();

  return (
    <Stack
      alignItems="center"
      data-testid="userMenuHeader"
      direction="row"
      justifyContent="center"
      spacing={isMediumDesktop ? SpaceSizes.xs : SpaceSizes.md}
    >
      {isAuthenticated && <FavsDropdown />}
      {isAuthenticated && <NotificationsDropdown />}

      <UserDropdown
        isDropdownOpen={isDropdownUserOpen}
        setIsDropdownOpen={handleUserDropdown}
      />
    </Stack>
  );
};

export default UserMenu;
