import { createSlice } from '@reduxjs/toolkit';
import { equals } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import Cookies from 'universal-cookie';

import { getStorage } from '../../utils/storage';
import { BaseSliceState } from '../commons/types';
import { User } from '../users/Users.types';
import { LocationResponse } from './Session.api';

export interface SessionState extends BaseSliceState {
  user: User | null;
  isAnonymous: boolean;
  isAlpha?: boolean;
  location: LocationResponse | null;
  shouldTrackSignup: boolean;
}

const initialState = {
  user: null,
  isAnonymous: false,
  isAlpha: false,
  status: 'idle',
  location: null,
  shouldTrackSignup: false,
} as SessionState;

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loadSession: (state) => {
      const localStorageSessionJSON = getStorage()?.getItem('session') || '';
      if (isNotNilOrEmpty(localStorageSessionJSON)) {
        const localSessionObject = JSON.parse(localStorageSessionJSON);
        const cookies = new Cookies();
        const dueDate = new Date(localSessionObject.due_date);
        const today = new Date();
        if (
          equals(localSessionObject.session_id, cookies.get('session-id')) &&
          today <= dueDate
        ) {
          // if session is an anonymous session, don't load user into store
          const isLocalAnonymous = isNotNilOrEmpty(
            localSessionObject.anonymous,
          );
          if (!isLocalAnonymous) {
            cookies.set('session-is-logged', true, {
              path: '/',
              expires: new Date(dueDate),
            });
          }
          state.user = isLocalAnonymous ? null : localSessionObject.user;
          state.isAnonymous = isLocalAnonymous;
          state.isAlpha = localSessionObject.isAlpha;
          state.status = 'succeeded';
        } else {
          state.status = 'pending';
        }
      } else {
        state.status = 'pending';
      }
    },
    setSession: (state, action) => {
      const session = action.payload;
      // Set session info
      const cookies = new Cookies();
      cookies.set('session-id', session.session_id, {
        path: '/',
        expires: new Date(session.due_date),
      });
      // if session is not an anonymous session, set session-is-logged cookie
      if (isNotNilOrEmpty(session.user)) {
        cookies.set('session-is-logged', true, {
          path: '/',
          expires: new Date(session.due_date),
        });
      }
      getStorage()?.setItem('session', JSON.stringify(session));
      state.user = session.user;
      state.isAlpha = session.isAlpha;
      state.isAnonymous = isNilOrEmpty(session.user);
      state.status = 'succeeded';
    },
    updateAvatar: (state, action) => {
      // Update avatar url on redux and local storage
      const localStorageSessionJSON = getStorage()?.getItem('session') || '';
      if (isNotNilOrEmpty(localStorageSessionJSON) && state.user) {
        const localSessionObject = JSON.parse(localStorageSessionJSON);
        localSessionObject.user.image_url = action.payload;
        getStorage()?.setItem('session', JSON.stringify(localSessionObject));
        state.user.image_url = action.payload;
      }
    },
    updateCellphone: (state, action) => {
      // Update cellphone on redux and local storage
      const localStorageSessionJSON = getStorage()?.getItem('session') || '';
      if (isNotNilOrEmpty(localStorageSessionJSON) && state.user) {
        const localSessionObject = JSON.parse(localStorageSessionJSON);
        localSessionObject.user.phone = action.payload;
        getStorage()?.setItem('session', JSON.stringify(localSessionObject));
        state.user.phone = action.payload;
      }
    },
    updateUser: (state, action) => {
      // Update personal data on redux and localstorage
      const localStorageSessionJSON = getStorage()?.getItem('session') || '';
      if (isNotNilOrEmpty(localStorageSessionJSON) && state.user) {
        const updatedUser = {
          ...state.user,
          nickname: action.payload.nickname ?? state.user.nickname,
          first_name: action.payload.first_name ?? state.user.first_name,
          last_name: action.payload.last_name ?? state.user.last_name,
          country_id: action.payload.country_id ?? state.user.country_id,
          lang_id: action.payload.lang_id ?? state.user.lang_id,
          address_state_id:
            action.payload.address_state_id ?? state.user.address_state_id,
          address_city_name:
            action.payload.address_city_name ?? state.user.address_city_name,
          address: action.payload.address ?? state.user.address,
          is_public: action.payload.is_public ?? state.user.is_public,
        };
        state.user = updatedUser;
        const localSessionObject = JSON.parse(localStorageSessionJSON);
        localSessionObject.user = updatedUser;
        getStorage()?.setItem('session', JSON.stringify(localSessionObject));
      }
    },
    setOnboardingHasSeen: (state, action) => {
      // Set onboarding boolean on redux store
      const localStorageSessionJSON = getStorage()?.getItem('session') || '';
      if (isNotNilOrEmpty(localStorageSessionJSON) && state.user) {
        const localSessionObject = JSON.parse(localStorageSessionJSON);
        localSessionObject.user.has_seen_onboarding = action.payload;
        getStorage()?.setItem('session', JSON.stringify(localSessionObject));
        state.user.has_seen_onboarding = action.payload;
      }
    },
    setShouldTrackSignup: (state, action) => {
      state.shouldTrackSignup = action.payload;
    },
    removeSession: (state) => {
      getStorage()?.removeItem('session');
      const cookies = new Cookies();
      cookies.remove('session-id');
      cookies.remove('session-is-logged');
      state.user = initialState.user;
      state.status = 'pending';
      state.isAnonymous = initialState.isAnonymous;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
  },
});
export const {
  loadSession,
  setSession,
  setLocation,
  updateAvatar,
  updateUser,
  updateCellphone,
  setOnboardingHasSeen,
  setShouldTrackSignup,
  removeSession,
} = sessionSlice.actions;

export default sessionSlice.reducer;
