export interface ICompactCard {
  ballId?: string;
  disabled?: boolean;
  isStatic?: boolean;
  pieceNumber?: number;
  image?: string;
  type?: CompactCardE;
}

export enum CompactCardE {
  VIEW = 'view',
  REMOVE = 'remove',
  ADD = 'add',
  EMPTY = 'empty',
}
