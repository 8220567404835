export const ADD_SPINNER_IMAGE =
  "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23333' stroke-width='5' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")";

export const CARD_SIZE = {
  width: {
    xs: 120,
    sm: 135,
    md: 150,
    lg: 155,
    xl: 180,
  },
  height: {
    xs: 200,
    sm: 225,
    md: 250,
    lg: 265,
    xl: 300,
  },
};

export const CONTAINER_SIZE = {
  xs: 300,
  sm: 370,
  md: 420,
  lg: 675,
};

export const CARD_WIDTH_DIVISION_FACTOR = 1.6;
export const SKELETON_LINE_HEIGHT = 40;

export const TRADE_MARKET_AVAILABLE_FONT_SIZE = {
  xs: 10,
  sm: 11,
  md: 12,
  lg: 13,
  xl: 14,
};

export const TRADE_MARKET_LEAGUE_FONT_SIZE = {
  xs: 12,
  sm: 14,
  md: 15,
  lg: 16,
  xl: 18,
};

export const CARD_ALBUM_HEADER_FONT_SIZE = {
  xs: 10,
  sm: 11,
  md: 12,
  lg: 13,
  xl: 14,
};

export const CARD_ALBUM_SUB_FONT_SIZE = {
  xs: 8,
  sm: 9,
  md: 10,
  lg: 11,
  xl: 12,
};
