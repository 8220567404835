import { Stack, SxProps, Theme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useDesktopMediaQuery from '../../hooks/useDesktopMediaQuery';
import { PanelProps } from './Panel.types';

const Panel = ({ children, sx, onClick }: PanelProps) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <Stack
      alignItems="center"
      borderRadius={SpaceSizes.xs2}
      p={SpaceSizes.md}
      position="relative"
      sx={
        {
          backgroundColor: 'background.paper',
          border: SpaceSizes.none,
          ...sx,
        } as SxProps<Theme>
      }
      textAlign={isDesktop ? 'center' : 'inherit'}
      width="100%"
      onClick={onClick}
    >
      {children}
    </Stack>
  );
};

export default Panel;
