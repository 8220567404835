import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import Panel from '../Panel/Panel';

const ProductAssignmentWarning = () => {
  const { t } = useTranslationByNamespaces('pages.product.type');

  return (
    <Panel>
      <Stack
        alignItems="left"
        flexDirection="column"
        gap={SpaceSizes.sm}
        width="100%"
      >
        <Stack flexDirection="row" gap={SpaceSizes.sm}>
          <InfoIcon color={AlertSeverity.INFO} />
          <Typography align="left" fontWeight="bold">
            {t('header')}
          </Typography>
        </Stack>
        <Stack alignItems="left" flexDirection="row" gap={SpaceSizes.md}>
          <Typography align="left">{t('content')}</Typography>
        </Stack>
      </Stack>
    </Panel>
  );
};

export default ProductAssignmentWarning;
