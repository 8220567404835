import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  addURLParamByKey,
  deleteURLParamByKey,
  getURLParamByKey,
} from '../router/router.utils';
import { AppliedFilter } from '../searchfilter/responses/SearchResponse';
import { tradesFiltersURL } from './Trades.api';

export interface TradesFilterState {
  searchQuery: string;
  appliedFilters: AppliedFilter[];
}

const initialState = {
  searchQuery: '',
  appliedFilters: [],
} as TradesFilterState;

const tradesFilterSlice = createSlice({
  name: 'tradesFilter',
  initialState,
  reducers: {
    addTradeFilter: (state, action: PayloadAction<AppliedFilter>) => {
      if (state.appliedFilters.some((f) => f.id === action.payload.id)) {
        return;
      }

      state.appliedFilters.push(action.payload);
      addURLParamByKey(action.payload.id, action.payload.value);
    },
    removeTradeFilter: (state, action: PayloadAction<string>) => {
      state.appliedFilters = state.appliedFilters.filter(
        (appliedFilter) => appliedFilter.id !== action.payload,
      );

      deleteURLParamByKey(action.payload);
    },
    setTradesFilter: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.searchQuery = getURLParamByKey('q');
      state.appliedFilters = [];
      // remove filters from query string
      tradesFiltersURL.forEach((filter) => {
        deleteURLParamByKey(filter);
      });
    },
  },
});

export const { addTradeFilter, removeTradeFilter, setTradesFilter } =
  tradesFilterSlice.actions;

export default tradesFilterSlice.reducer;
