import { useGrowthBook } from '@growthbook/growthbook-react';
import { append, defaultTo, filter, includes, pipe, pluck } from 'ramda';

import { useGetBrandsQuery } from '../../features/tournaments/Tournaments.api';

interface LeagueData {
  id: string;
}

interface LeagueDataResponse {
  leagues: string[];
}
/**
 * Get available brands from database
 * @returns {string[]} available brands
 */
export const useGetAvailableLeaguesId = (): string[] => {
  const { data } = useGetBrandsQuery();

  // also filter by active league id from growthbook
  const growthbook = useGrowthBook();

  const desiredBrands = growthbook?.getFeatureValue(
    'active_leagues',
    'golball',
  ) as LeagueDataResponse | undefined;

  if (!data) {
    return ['default'];
  }

  const extractId = pluck('id');
  const appendDefault = append('default');
  const defaultToDefaultArray = defaultTo(['default']);
  const isInDesiredBrands = filter((brand: string) =>
    includes(brand, desiredBrands?.leagues || []),
  );

  const brands = pipe(
    extractId as (leagueData: LeagueData[] | null | undefined) => string[],
    isInDesiredBrands,
    appendDefault,
    defaultToDefaultArray,
  )(data);

  return brands;
};
