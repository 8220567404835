import { Box, css, Stack, styled } from '@mui/material';
import React, { useMemo } from 'react';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { Nickname } from '../profile/Nickname/Nickname';
import { flexDirectionT } from '../profile/Nickname/Nickname.type';
import { THROPHIES_CANDIDATES, TROPHIES } from './TableRank.constants';
import { ITableRankRow } from './TableRankRow.interfaces';

type BoxProps = React.HTMLAttributes<HTMLDivElement> & {
  $isMobile: boolean;
  $isSelf: boolean;
};

export const TableRankRowStyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isMobile' && prop !== '$isSelf',
})<BoxProps>(({ theme, $isMobile, $isSelf }) =>
  css({
    width: '100%',
    height: $isMobile ? '60px' : '70px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: $isSelf
      ? theme.palette.primary.dark
      : theme.palette.background.paper,
    border: $isSelf ? `1px solid ${theme.palette.text.primary}` : '',
    boxShadow: $isSelf
      ? '2px 5px 13px rgba(38, 41, 81, 0.2)'
      : '2px 5px 13px rgba(38, 41, 81, 0.2)',
  }),
);

export const TableRankRow = ({
  data,
  isThanksYouPage,
  highlightSelf = true,
}: ITableRankRow) => {
  const {
    user,
    position,
    balls_amount: ballsAmount,
    position_increase: positionIncrease,
  } = data || {};
  const {
    user_name: userName,
    image_url: profileUrl,
    country_id: countryId,
    is_self: isSelf,
    is_public: isPublic,
    user_id: userId,
  } = user;
  const isMobile = useMobileMediaQuery();

  const getTrophy = useMemo(() => {
    if (position <= THROPHIES_CANDIDATES) {
      return `${env.REACT_APP_STATIC_BASE_URL}marketplace/rank/${TROPHIES[position]}.svg`;
    }
    return null;
  }, [position]);

  const flexDirection = useMemo(
    () => (isMobile ? 'column' : 'row'),
    [isMobile],
  );

  const styleProps = {
    flexDirection: flexDirection as flexDirectionT,
    height: SpaceSizes.md,
    width: SpaceSizes.md,
  };

  const userProps = {
    ballsAmount,
    countryId,
    getTrophy: getTrophy ?? undefined,
    isPublic,
    isRanking: true,
    isSelf,
    position,
    positionIncrease,
    profileUrl,
    userId,
    userName,
    isThanksYouPage,
  };

  return (
    <TableRankRowStyledBox
      key={user.user_id}
      $isMobile={isMobile}
      $isSelf={highlightSelf && isSelf}
      data-testid={`user-${userName || 'anonymous'}`}
    >
      <Stack
        style={{
          borderRight: 'none',
          width: '100%',
        }}
      >
        <Stack
          alignItems="center"
          direction={flexDirection}
          gap={isMobile ? SpaceSizes.sm : SpaceSizes.smPlus}
          justifyContent="flex-start"
          width="100%"
        >
          <Stack
            alignItems="center"
            direction={flexDirection}
            gap={SpaceSizes.sm}
            justifyContent="flex-start"
            width="100%"
          >
            <Nickname styleProps={styleProps} userProps={userProps} />
          </Stack>
        </Stack>
      </Stack>
    </TableRankRowStyledBox>
  );
};
