import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Button, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import {
  useActivateAccountMutation,
  usersApiExtractErrors,
} from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getReferralCookie } from '../../../utils/cookies';
import { TAG_EVENT } from '../../../utils/tagger';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import useRedirectLoginSignup from '../../hooks/useRedirectLoginSignup';

const AccountActivation = () => {
  const { loginRoute } = useRedirectLoginSignup();
  const [errorCode, setErrorCode] = useState('');
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();
  const [params] = useSearchParams();
  const referralCode = getReferralCookie();

  const [activateAccount, { isSuccess, isLoading, isUninitialized }] =
    useActivateAccountMutation();

  const activateAccountCallback = useCallback(async () => {
    const email = params.get('email');
    const activationId = params.get('activationId');
    if (email && activationId) {
      try {
        const account = await activateAccount({
          email,
          activationId,
          ...(referralCode && { referral_code: referralCode }),
        }).unwrap();

        sendDataToGTM({
          event: TAG_EVENT.ONBOARDING.ACCOUNT_VALIDATED,
          user_id: account.user_id,
        });
      } catch (error) {
        setErrorCode(usersApiExtractErrors(error, 'activationError'));
      }
    } else {
      setErrorCode('activationError');
    }
  }, [referralCode, params, activateAccount, sendDataToGTM]);

  useEffect(() => {
    if (isUninitialized) {
      activateAccountCallback();
    }
  }, [activateAccountCallback, isUninitialized]);

  return (
    <PageContainer id="accountActivationPage" loading={isLoading} maxWidth="xs">
      {!isSuccess && isNotNilOrEmpty(errorCode) && (
        <AlertCard
          content={t(`pages.accountActivation.errors.${errorCode}`)}
          id="accountActivationError"
          show={!isSuccess && isNotNilOrEmpty(errorCode)}
        />
      )}
      {isSuccess && (
        <AlertCard
          content={
            <Stack spacing={SpaceSizes.sm}>
              <Typography variant="body1">
                {t(`pages.accountActivation.isSuccess.message.line1`)}
              </Typography>
              <Typography variant="body1">
                {t(`pages.accountActivation.isSuccess.message.line2`)}
                <Button component={RouterLink} size="small" to={loginRoute}>
                  {t(`pages.accountActivation.isSuccess.action`)}
                </Button>
              </Typography>
            </Stack>
          }
          id="accountActivationSuccess"
          severity={AlertSeverity.SUCCESS}
          show={isSuccess}
        />
      )}
    </PageContainer>
  );
};

export default AccountActivation;
