import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const BUTTON_WIDTH = 250;
export const SCROLL_BAR = {
  overflowX: 'scroll',
  overflowY: 'visible',
  '&::-webkit-scrollbar': {
    display: 'block',
    height: SpaceSizes.smPlus,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'text.primary',
    borderRadius: SpaceSizes.md,
    ':hover': {
      backgroundColor: 'text.secondary',
    },
    ':active': {
      backgroundColor: 'text.secondary',
    },
  },
};

export const EMPTY_DESCRIPTION_FONT_SIZE = {
  xs: 11,
  sm: 12,
  md: 13,
  lg: 14,
  xl: 15,
};

export const SELECTOR_TITLE_FONT_SIZE = {
  xs: 16,
  sm: 18,
  md: 20,
  lg: 24,
};
