import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import {
  GetAuctionProductRequest,
  GetAuctionProductResponse,
  PostBidRequest,
} from './Auction.types';

export const auctionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAuction: builder.query<
      GetAuctionProductResponse,
      GetAuctionProductRequest
    >({
      query: ({ productId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_GOLPOINTS}auctions/product/${productId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: () => [RTKTags.Auctions],
    }),
    getBids: builder.query<GetAuctionProductResponse, GetAuctionProductRequest>(
      {
        query: ({ productId }) => ({
          url: `${Env.REACT_APP_BASE_API_URL_GOLPOINTS}auctions/${productId}/bids`,
          method: 'GET',
        }),
        keepUnusedDataFor: 0,
      },
    ),
    postBid: builder.mutation<GetAuctionProductResponse, PostBidRequest>({
      query: ({ productId, bid_amount: bidAmount }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_GOLPOINTS}auctions/${productId}/bids`,
        method: 'POST',
        body: {
          currency_id: 'GOL',
          bid_amount: bidAmount,
        },
      }),
      invalidatesTags: [RTKTags.Auctions],
    }),
  }),
});

export const { useGetAuctionQuery, useGetBidsQuery, usePostBidMutation } =
  auctionApi;
