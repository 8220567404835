import { createApi } from '@reduxjs/toolkit/query/react';
import { values } from 'ramda';

import { baseQuery } from '../../utils/baseQuery';

export const RTKTags = {
  Favs: 'Favs',
  Search: 'Search',
  Bookmarked: 'Bookmarked',
  MyCollection: 'MyCollection',
  SearchByMatch: 'SearchByMatch',
  Notifications: 'Notifications',
  Preferences: 'Preferences',
  Products: 'Products',
  Trades: 'Trades',
  Coupons: 'Coupons',
  Referrals: 'Referrals',
  MovementsTransactions: 'MovementsTransactions',
  MovementsMyBuys: 'MovementsMyBuys',
  Trivia: 'Trivia',
  TotalItems: 'TotalItems',
  Auctions: 'Auctions',
};

export const RTKTagsAsArray = () => values(RTKTags);

export const api = createApi({
  reducerPath: 'api',
  tagTypes: RTKTagsAsArray(),
  baseQuery,
  /**
   * By default the invalidation Cache time is 60 seg.
   */
  keepUnusedDataFor: 60,
  /**
   * Re-validate all data when user comes from off-line mode.
   */
  refetchOnReconnect: true,
  endpoints: () => ({}),
});
