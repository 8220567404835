import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  Box,
  Button,
  Skeleton,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useCarousel from '../../hooks/useCarousel';
import useSmallLaptopMediaQuery from '../../hooks/useLargeMobileMediaQuery';
import {
  CAROUSEL_CONTAINER_HEIGHT,
  CAROUSEL_CONTAINER_WIDTH,
} from '../collections/Collections.constants';
import { CollectionCarouselI } from './CollectionSelector.types';

const CollectionCarousel = ({
  setCurrentCarousel,
  setAvatar,
  images,
  loading,
  pickedCollection,
  collectionGroupId,
}: CollectionCarouselI) => {
  const { step, set, peek, page, last } = useCarousel({
    length: images.length - 1,
  });

  const isLargeMobileDevice = useSmallLaptopMediaQuery();
  const navigate = useNavigate();

  const mappedImages = useMemo(() => {
    if (images.length) {
      return images.map((image) => image.id);
    }
    return [];
  }, [images]);

  const currentImage = useMemo(() => images[page], [images, page]);

  useEffect(() => {
    if (pickedCollection && mappedImages.length) {
      set(mappedImages.indexOf(pickedCollection));
    }
  }, [mappedImages, pickedCollection, set]);

  useEffect(() => {
    if (!loading) {
      navigate(`/collections/${collectionGroupId}/${currentImage.id}`);
      setCurrentCarousel(currentImage.title);
      setAvatar(currentImage.avatar || '');
    }
  }, [
    setCurrentCarousel,
    setAvatar,
    loading,
    navigate,
    collectionGroupId,
    currentImage,
  ]);

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <Tooltip
        placement="top"
        title={
          <Stack alignItems="center">
            <Avatar src={images[peek(-1)]?.avatar} />
            <Typography variant="caption">{images[peek(-1)]?.title}</Typography>
          </Stack>
        }
      >
        <Button
          disabled={loading}
          sx={{
            height: SpaceSizes.xl,
            padding: isLargeMobileDevice ? SpaceSizes.xxs : SpaceSizes.sm,
            borderRadius: SpaceSizes.xs,
            minWidth: isLargeMobileDevice ? SpaceSizes.sm : SpaceSizes.xl,
          }}
          onClick={() => step(-1)}
        >
          <ArrowBackIosNewIcon />
        </Button>
      </Tooltip>
      <Box
        sx={{
          alignItems: 'center',
          width: CAROUSEL_CONTAINER_WIDTH,
          height: '100%',
          minHeight: CAROUSEL_CONTAINER_HEIGHT,
          overflow: 'hidden',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box alignItems="center" display="flex" height="100%" width="100%">
          {loading ? (
            <Skeleton height="90%" variant="rectangular" width="100%" />
          ) : (
            <Slide direction="left" in mountOnEnter unmountOnExit>
              <Box width="100%">
                <AnimatePresence mode="wait">
                  <motion.img
                    key={page}
                    alt={`Image ${page + 1}`}
                    animate="center"
                    exit="exit"
                    initial="enter"
                    src={currentImage.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    transition={{ duration: 0.35 }}
                    variants={{
                      enter: { x: 550 * last },
                      center: { x: 0 },
                      exit: { x: 550 * -last },
                    }}
                  />
                </AnimatePresence>
              </Box>
            </Slide>
          )}
        </Box>
      </Box>
      <Tooltip
        placement="top"
        title={
          <Stack alignItems="center">
            <Avatar src={images[peek(1)]?.avatar} />
            <Typography variant="caption">{images[peek(1)]?.title}</Typography>
          </Stack>
        }
      >
        <Button
          disabled={loading}
          sx={{
            height: SpaceSizes.xl,
            padding: isLargeMobileDevice ? SpaceSizes.xxs : SpaceSizes.sm,
            minWidth: isLargeMobileDevice ? SpaceSizes.sm : SpaceSizes.xl,
            borderRadius: SpaceSizes.xs,
          }}
          onClick={() => step(1)}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default CollectionCarousel;
