import Env from '../../env/env';
import { api } from '../api/Api';
import { Country, State } from './responses/Country';

export const countriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Countries
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: `${Env.REACT_APP_COUNTRY_STATE_CITY_API_BASE_URL}/countries`,
      }),
      transformResponse: (response: Country[]) => {
        return response.map((country) => {
          if (country.id === 216) {
            return { ...country, name: 'Taiwan, China' };
          }
          if (country.id === 128) {
            return { ...country, name: 'Macau S.A.R., China' };
          }
          if (country.id === 98) {
            return { ...country, name: 'Hong Kong S.A.R., China' };
          }

          return country;
        });
      },
    }),
    getStatesByCountry: builder.mutation<State[], string>({
      query: (country) => ({
        url: `${Env.REACT_APP_COUNTRY_STATE_CITY_API_BASE_URL}/countries/${country}/states`,
      }),
    }),
  }),
});

export const { useGetCountriesQuery, useGetStatesByCountryMutation } =
  countriesApi;
