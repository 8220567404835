import { PopoverOrigin } from '@mui/material';

export const COLLECTABLES_ICON_SIZE = 46;

export const COLLECTABLES_ADDITIONAL_ANCHOR: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
export const COLLECTABLES_ADDITIONAL_TRANSFORM: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
