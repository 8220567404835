import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import React, { PropsWithChildren } from 'react';

import { useAppSelector } from '../app/hooks';
import { selectCurrentBrand } from '../features/brand/Brand.selector';
import defaultThemeOptions from '../theme/index';

export const BrandThemeProvider = ({ children }: PropsWithChildren) => {
  const brandOptions = useAppSelector(selectCurrentBrand);

  // Get custom brand extended theme
  const brandThemeOptions = brandOptions?.theme;

  const theme = createTheme(
    deepmerge(defaultThemeOptions, brandThemeOptions || {}),
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
