import { useEffect, useState } from 'react';

import {
  useGetGolpointsBalanceQuery,
  useGetTiersQuery,
} from '../../../features/golpoints/Golpoints.api';

export const useFetchData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isUninitialized, setIsUninitialized] = useState(true);
  const {
    data: golpointsData,
    isLoading: golpointsIsLoading,
    isError: golpointsIsError,
    isUninitialized: golpointsIsUninitialized,
  } = useGetGolpointsBalanceQuery();

  const {
    data: tiersData,
    isLoading: tiersIsLoading,
    isError: tiersIsError,
    isUninitialized: tiersIsUninitialized,
  } = useGetTiersQuery();

  const data = {
    golpointsResponse: golpointsData,
    tiersResponse: tiersData,
  };

  useEffect(() => {
    if (golpointsIsError || tiersIsError) setIsError(true);
  }, [golpointsIsError, tiersIsError]);

  useEffect(() => {
    if (!golpointsIsLoading && !tiersIsLoading) setIsLoading(false);
  }, [golpointsIsLoading, tiersIsLoading]);

  useEffect(() => {
    if (!golpointsIsUninitialized && !tiersIsUninitialized)
      setIsUninitialized(false);
  }, [golpointsIsUninitialized, tiersIsUninitialized]);

  return {
    isLoading,
    isError,
    isUninitialized,
    data,
  };
};
