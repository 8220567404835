import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import DEFAULT_LOGO from '../../../assets/img/default_logo_club.png';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { Animation } from '../../Animate/Animate';
import { AnimationColor, AnimationEnum } from '../../Animate/Animate.constants';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import { ClubFilterI } from './ClubFilterItems.types';

export const ClubFilterItem = ({
  id,
  label,
  logo,
  justifyContent,
  onClickFn,
}: ClubFilterI) => {
  const isMobile = useMobileMediaQuery();
  return (
    <Grid
      key={id}
      data-testid={`filterteamButton-${id}`}
      flexDirection="row"
      item
    >
      <Box
        key={id}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isMobile ? 'center' : justifyContent,
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={onClickFn}
      >
        <Animation
          color={AnimationColor.WHITE}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            gap: SpaceSizes.md,
            width: isMobile ? '21ch' : '80%',
          }}
          type={AnimationEnum.BorderBottom}
          hasHover
        >
          <ImageFallback
            alt={label}
            aria-label={label}
            fallback={DEFAULT_LOGO}
            height={42}
            src={logo || ''}
            width={42}
          />
          <Typography variant="body2">{label}</Typography>
        </Animation>
      </Box>
    </Grid>
  );
};
