import TuneIcon from '@mui/icons-material/Tune';
import { Box, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';

import useDesktopDeviceMediaQuery from '../../../hooks/useDesktopDeviceMediaQuery';
import { SearchFilterModal } from '../SearchFilterModal/SearchFilterModal';
import { SearchFilterSection } from '../SearchFilterSection/SearchFilterSection';
import { ISearchSectionProps } from './SearchSection.types';

export const SearchSection = ({
  data,
  options,
  addFilter,
  removeFilter,
}: ISearchSectionProps) => {
  const isDesktop = useDesktopDeviceMediaQuery();

  const [isShowModalFilter, setIsShowModalFilter] = useState(false);

  const handleClick = () => {
    setIsShowModalFilter(true);
  };
  const handleClose = () => {
    setIsShowModalFilter(false);
  };

  return (
    <Stack>
      {!isDesktop ? (
        <>
          <Box>
            <IconButton onClick={handleClick}>
              <TuneIcon />
            </IconButton>
          </Box>

          <SearchFilterModal
            addFilter={addFilter}
            appliedFilters={data.appliedFilters}
            filters={data.filters}
            handleClose={handleClose}
            isShowModalFilter={isShowModalFilter}
            options={options}
            removeFilter={removeFilter}
          />
        </>
      ) : (
        <SearchFilterSection
          addFilter={addFilter}
          appliedFilters={data.appliedFilters}
          filters={data.filters}
          options={options}
          removeFilter={removeFilter}
        />
      )}
    </Stack>
  );
};
