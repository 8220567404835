import { Location } from 'react-router-dom';

export const selectIsMarketplacePage = (location: Location) =>
  location.pathname === '/marketplace';

export const selectIsMatchesPage = (location: Location) =>
  location.pathname === '/matches';

export const selectIsFavPage = (location: Location) =>
  location.pathname === '/favs';

export const selectIsMyCollectionPage = (location: Location) =>
  location.pathname === '/collections';
export const selectIsMyCollectionPublicPage = (location: Location) =>
  location.pathname === '/collections/collections_landing';
export const selectIsGuidesPage = (location: Location) =>
  location.pathname === '/pages/guia';

export const selectIsRankPage = (location: Location) =>
  location.pathname === '/rank';

export const selectIsLoginPage = (location: Location) =>
  location.pathname === '/login';

export const selectIsSignupPage = (location: Location) =>
  location.pathname === '/signup';

export const selectIsSignupEmailPage = (location: Location) =>
  location.pathname === '/signup-email';

export const selectIsTradePage = (location: Location) =>
  location.pathname === '/trades';

export const selectIsAccountRecoveryPage = (location: Location) =>
  location.pathname.includes('/account-recovery');

export const selectIsAccountActivationPage = (location: Location) =>
  location.pathname.includes('/account-activation');

export const selectIsMyProfilePage = (location: Location) =>
  location.pathname === '/my-profile';

export const selectIsMembershipsPage = (location: Location) =>
  location.pathname === '/memberships/laliga';

export const selectIsHomePage = (location: Location) =>
  location.pathname === '/';

export const selectIsCertificatePage = (location: Location) =>
  location.pathname.includes('/my-collection');

export const selectIsAuthSection = (location: Location) =>
  selectIsLoginPage(location) ||
  selectIsSignupPage(location) ||
  selectIsSignupEmailPage(location) ||
  selectIsAccountRecoveryPage(location) ||
  selectIsAccountActivationPage(location);
