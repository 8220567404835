import { Skeleton } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ACCORDION_SKELETON_AMOUNT } from './AccordionSkeleton.constants';

export const AccordionSkeleton = ({
  accordionItemsLenght = ACCORDION_SKELETON_AMOUNT,
}: {
  accordionItemsLenght?: number;
}) => {
  return (
    <>
      {Array.from({ length: accordionItemsLenght }, (_, index) => {
        return (
          <Skeleton
            key={`skeleton_tab_${_}${index}`}
            height={SpaceSizes.lgPlus}
            sx={{ gap: SpaceSizes.xs }}
            variant="rectangular"
            width="100%"
          />
        );
      })}
    </>
  );
};
