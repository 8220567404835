import { Stack, useTheme } from '@mui/material';
import React, { useCallback } from 'react';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import AddToWalletButton from '../AddToWalletButton/AddToWalletButton';
import { HeartBookmarkIcon } from '../HeartBookmarkIcon/HeartBookmarkIcon';
import ProductShareWidget from '../ProductShareWidget/ProductShareWidget';
import { BookmarkAndShareProps } from './BookmarkAndShare.types';

const BookmarkAndShare = ({
  product,
  isOwned = false,
}: BookmarkAndShareProps) => {
  const isMobile = useMobileMediaQuery();
  const theme = useTheme();

  const handleAddToWallet = useCallback(() => {
    window.location.href = `${env.REACT_APP_BASE_API_URL_PKPASS}pkpass-engine/${product.product_id}/${product.web2wallet_tx_id}`;
  }, [product.product_id, product.web2wallet_tx_id]);

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-evenly"
      sx={{
        borderStyle: 'solid none',
        borderWidth: '1px',
        borderColor: theme.palette.text.secondary,
        padding: isMobile ? SpaceSizes.xs : SpaceSizes.sm,
      }}
    >
      {!isOwned && <HeartBookmarkIcon product={product} />}
      <ProductShareWidget productId={product?.product_id} />
      {isOwned && <AddToWalletButton onClick={handleAddToWallet} />}
    </Stack>
  );
};

export default BookmarkAndShare;
