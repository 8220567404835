import React from 'react';
import { useParams } from 'react-router-dom';

import { selectProduct } from '../../../features/products/Product.selectors';
import { useGetProductByIdQuery } from '../../../features/products/Products.api';
import { Certificate } from '../../components/Certificate/Certificate';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';

export const CollectionDetails = () => {
  const { productId } = useParams();

  const { product, isLoading, isError, isUninitialized } =
    useGetProductByIdQuery(
      { productId: productId ?? '', context: 'my_collection' },
      {
        selectFromResult: ({ data, ...rest }) => ({
          ...rest,
          product: selectProduct(data),
        }),
      },
    );

  return (
    <PageContainer
      {...(isError && {
        errorCode: 'pages.product.piece.modal.errors.standard',
      })}
      id="certificate-collection"
      maxWidth="md"
      backButton
    >
      <Certificate
        isLoading={isLoading || isUninitialized}
        product={product}
        showUserActions
      />
    </PageContainer>
  );
};
