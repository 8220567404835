import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const paperStyled = {
  backgroundColor: 'transparent',
  // flex: '0 0 33.333333%',
  flex: { xs: '0 0 100%', md: '0 0 33.333333%' },
  maxWidth: { xs: '100%', md: '33.333333%' },
};

export const paperContainerStyled = {
  alignItems: 'flex-start',
  flexDirection: 'row',
  padding: SpaceSizes.md,
};

export const containerTextStyled = {
  alignItems: 'flex-start',
  flexDirection: 'column',
  paddingLeft: SpaceSizes.smPlus,
};

export const titleStyled = {
  fontWeight: '700',
  fontSize: 'cH5',
};

export const descriptionStyled = {
  color: 'text.secondary',
  fontWeight: '400',
  marginTop: SpaceSizes.xs2,
};

export const linkStyled = {
  marginTop: SpaceSizes.md,
};
