import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Env from '../../../env/env';
import { useLoginMutation } from '../../../features/session/Session.api';
import { setSession } from '../../../features/session/Session.slice';
import {
  useCreateAccountMutation,
  usersApiExtractErrors,
} from '../../../features/users/Users.api';
import { LOGIN_DELIVERABLE_EMAIL } from '../../../features/users/Users.constants';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getReferralCookie } from '../../../utils/cookies';
import { pixelTracks, TAG_EVENT } from '../../../utils/tagger';
import { isValidEmail } from '../../../utils/validation';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import CheckboxField from '../../components/form/CheckboxField';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input/Input';
import { InputPassword } from '../../components/form/InputPassword/InputPassword';
import { GolButton } from '../../components/GolButton/GolButton.component';
import { Link } from '../../components/Link/Link';
import { useAppDispatch } from '../../hooks';
import useLoginSignupRedirect from '../../hooks/useLoginSignupRedirect';
import i18n, { geti18nResolvedLanguage } from '../../i18n';
import {
  alertStyled,
  checkboxStyled,
  formSignUpStyled,
  pageContainerStyled,
} from './SignupEmail.constants';
import { AccountActivationBody, SignupInputs } from './SignUpEmail.types';

export const SignupEmail = () => {
  useLoginSignupRedirect();
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();
  const dispatch = useAppDispatch();
  const [errorCode, setErrorCode] = useState('');
  const [emailSent, setEmailSent] = useState('');
  const [params] = useSearchParams();
  const referralCode = getReferralCookie() ?? params.get('ref');

  const [createAccount, { isLoading, isSuccess }] = useCreateAccountMutation();
  const [login, { isLoading: isLogging, isSuccess: hasLoggedIn }] =
    useLoginMutation();

  const methods = useForm<SignupInputs>();
  useEffect(() => {
    methods.setValue('email', params.get('email') ?? '');
  }, [params, methods]);

  const onSubmit: SubmitHandler<SignupInputs> = async (data) => {
    try {
      const accountBody: AccountActivationBody = {
        email: data.email,
        password: data.password,
        lang_id: geti18nResolvedLanguage(),
        base_url: `${Env.REACT_APP_BASE_APP_URL}account-activation/` || '',
        ...(referralCode && { referral_code: referralCode }),
      };

      const res = await createAccount(accountBody).unwrap();

      sendDataToGTM({
        event: TAG_EVENT.ONBOARDING.SIGN_UP,
        user_id: res.user_id,
        method: 'Manual',
      });

      pixelTracks();

      if (res?.email_status === LOGIN_DELIVERABLE_EMAIL) {
        const session = await login({
          login: data.email,
          password: data.password,
        }).unwrap();

        sendDataToGTM({
          event: TAG_EVENT.ONBOARDING.LOGIN,
          user_id: session.user_id,
          method: 'Email',
        });
        // Load session into the store
        dispatch(setSession(session));
        i18n.changeLanguage(session?.user?.lang_id || 'es');
      } else {
        setEmailSent(data?.email);
      }
    } catch (error) {
      setErrorCode(usersApiExtractErrors(error, 'signupError'));
    }
  };
  return (
    <PageContainer id="signupEmailPage" maxWidth="xs" sx={pageContainerStyled}>
      <AlertCard
        content={
          <Stack data-testid="successSignup" spacing={SpaceSizes.sm}>
            <Typography>{t('pages.signup.isSuccess.message.line1')}</Typography>
            <Typography
              fontWeight="bold"
              sx={{ wordWrap: 'break-word' }}
              variant="body1"
            >
              {emailSent}
            </Typography>
            <Typography>{t('pages.signup.isSuccess.message.line2')}</Typography>
          </Stack>
        }
        id="successSignup"
        severity={AlertSeverity.SUCCESS}
        show={isSuccess && !isLogging && !hasLoggedIn}
        sx={alertStyled}
      />

      {!isSuccess && (
        <Form methods={methods} style={formSignUpStyled} onSubmit={onSubmit}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={SpaceSizes.md}
          >
            <Stack alignSelf="start">
              <Typography fontWeight="bold" variant="h5">
                {t('pages.signup.title')}
              </Typography>
            </Stack>
            {isNotNilOrEmpty(errorCode) && (
              <AlertCard
                content={t(`pages.signup.errors.${errorCode}`)}
                id="signupError"
                show={isNotNilOrEmpty(errorCode)}
              />
            )}
            <Input
              errorMessage={t('pages.signup.errors.mailError')}
              id="email"
              label={t('pages.signup.email')}
              placeholder=""
              validate={isValidEmail}
              onError={() => {
                sendDataToGTM({
                  event: TAG_EVENT.EMAIL_SINGUP_ERROR,
                });
              }}
            />
            <InputPassword
              id="password"
              label={t('pages.signup.password')}
              placeholder={t(`pages.signup.placeholderPassword`)}
              onError={() => {
                sendDataToGTM({
                  event: TAG_EVENT.PWD_SIGNUP_ERROR,
                });
              }}
            />
            <InputPassword
              id="passwordConfirmation"
              label={t(`pages.signup.passwordConfirmation`)}
              match={methods.watch('password')}
              placeholder={t(`pages.signup.placeholderConfirmationPassword`)}
              onError={() => {
                sendDataToGTM({
                  event: TAG_EVENT.CONFIRM_PWD_SIGNUP_ERROR,
                });
              }}
            />
            <Typography
              color="text.secondary"
              textAlign="left"
              variant="caption"
            >
              {t(`pages.signup.passwordRequirements`)}
            </Typography>

            <CheckboxField
              errorMessage={t('pages.signup.errors.termsAndPrivacyMissing')}
              gap={SpaceSizes.sm}
              id="termsAndPrivacy"
              label={
                <Typography
                  color="text.secondary"
                  fontWeight="500"
                  variant="caption"
                >
                  {`${t('pages.signup.agreeText')} `}
                  <Link color="secondary.main" to={t('footer.legal.tycLink')}>
                    {t('footer.legal.tyc')}
                  </Link>
                  {` ${t('pages.signup.agreeTextConector')} `}
                  <Link
                    color="secondary.main"
                    to={t('footer.legal.polityLink')}
                  >
                    {t('footer.legal.polity')}
                  </Link>
                </Typography>
              }
              sx={checkboxStyled}
              isRequired
            />

            <Stack
              spacing={SpaceSizes.lg}
              sx={{
                '& .MuiStack-root': { margin: '0', marginTop: SpaceSizes.md },
              }}
              width="100%"
            >
              <GolButton
                disabled={isNotNilOrEmpty(methods.formState.errors)}
                id="register_with_mail"
                loading={
                  (isLoading && !isSuccess) || (isLogging && !hasLoggedIn)
                }
                size="large"
                testId="signupButton"
                type="submit"
                value={t('pages.signup.actionButton')}
              />
            </Stack>
          </Stack>
        </Form>
      )}
    </PageContainer>
  );
};
