import Stack from '@mui/material/Stack';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useCheckoutPay } from '../../hooks/useCheckoutPay';
import {
  CHECKOUT_TYPE,
  IMAGE_FALLBACK,
} from '../../pages/checkout/Checkout.constants';
import { CheckoutTypeCart } from '../CheckoutTypeCart/CheckoutTypeCart';
import { CheckoutTypeDirect } from '../CheckoutTypeDirect/CheckoutTypeDirect';
import { CheckoutProductProps } from './CheckoutProduct.types';

export const CheckoutProduct = ({
  paymentUrls,
  checkoutResponse,
}: CheckoutProductProps) => {
  const { successUrl, cancelUrl } = paymentUrls;
  const {
    errorCode,
    isLoading: createPayIsLoading,
    handlePayment,
  } = useCheckoutPay({ successUrl, cancelUrl });
  const { items, userBalance, totalPoints, type } = checkoutResponse;

  return (
    <Stack
      alignContent="center"
      gap={SpaceSizes.mdPlus}
      justifyContent="center"
      sx={{
        borderRadius: IMAGE_FALLBACK.BORDER_RADIUS,
        flexDirection: { xs: 'column' },
      }}
      width="100%"
    >
      <Stack width="100%">
        {type === CHECKOUT_TYPE.CART && (
          <CheckoutTypeCart
            createPayIsLoading={createPayIsLoading}
            handlePayment={handlePayment}
            items={items}
            totalPoints={totalPoints}
            userBalance={userBalance}
            isProduct
          />
        )}
        {type === CHECKOUT_TYPE.DIRECT && (
          <CheckoutTypeDirect
            createPayIsLoading={createPayIsLoading}
            errorCode={errorCode}
            handlePayment={handlePayment}
            item={items && items[0]}
            totalPoints={totalPoints}
          />
        )}
      </Stack>
    </Stack>
  );
};
