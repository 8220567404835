import { Stack, Typography } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { URL_CHECKOUT_AUTHENTICATION } from '../../../constants/checkout/checkout';
import { useCreateCheckoutShoppingCartMutation } from '../../../features/checkout/Checkout.api';
import { shouldNotShowCheckoutButton } from '../../../features/checkout/Checkout.helpers';
import { selectIsUserLoggedIn } from '../../../features/session/Session.selector';
import {
  useChangeItemQuantityMutation,
  useDeleteItemMutation,
  useUndoDeleteMutation,
} from '../../../features/shoppingCart/ShoppingCart.api';
import {
  selectCart,
  selectCartActive,
  selectModifiedStock,
  selectOutOfStock,
  selectTotalPrice,
} from '../../../features/shoppingCart/ShoppingCart.selectors';
import {
  decreaseQuantityProduct,
  increaseQuantityProduct,
  removeProductCart,
  removeProductCartRemoved,
  restoreProductCart,
} from '../../../features/shoppingCart/ShoppingCart.slice';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import ProductPrice from '../ProductPrice/ProductPrice';
import { CardsCart } from './CardsCart';
import { CardsCartOperation } from './CardsCart.enums';
import { canDecreaseQuantity, canIncreaseQuantity } from './CardsCart.helpers';
import { Items } from './CardsCart.interfaces';
import { CardsCartHistory } from './CardsCartHistory';

const MAX_TIME_DELETE = 30000;

export const CardsCartContainer = () => {
  const { t } = useTranslationByNamespaces('pages.cart');
  const [errorCode, setErrorCode] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const [deleteItem, { isLoading: deleteItemIsLoading }] =
    useDeleteItemMutation();
  const [undoDelete, { isLoading: undoDeleteIsLoading }] =
    useUndoDeleteMutation();
  const [changeItemQuantity, { isLoading: changeItemQuantityIsLoading }] =
    useChangeItemQuantityMutation();
  const removeItemRef = useRef<NodeJS.Timeout | undefined>();
  const totalPrice = useAppSelector(selectTotalPrice);
  const [
    createCheckout,
    { isLoading: createCheckoutIsLoading, isError: createCheckoutIsError },
  ] = useCreateCheckoutShoppingCartMutation();
  const cart = useAppSelector(selectCart);
  const cartActive = useAppSelector(selectCartActive);
  const modifiedStock = useAppSelector(selectModifiedStock);
  const outOfStock = useAppSelector(selectOutOfStock);

  const handleRemove = async (itemSelected: Items) => {
    if (!deleteItemIsLoading) {
      try {
        await deleteItem(itemSelected.product.id);
        const timerId = setTimeout(() => {
          if (removeItemRef.current) {
            dispatch(removeProductCartRemoved(itemSelected));
          }
        }, MAX_TIME_DELETE);
        removeItemRef.current = timerId;
        dispatch(removeProductCart(itemSelected));
      } catch (error) {
        setErrorCode(true);
      }
    }
  };

  const handleCreateCheckout = useCallback(async () => {
    if (isLoggedUser && createCheckout) {
      try {
        const response = await createCheckout().unwrap();
        const { checkout_id: checkoutId } = response;
        navigate(`/checkout/${checkoutId}`);
      } catch (error) {
        navigate(`/cart`);
      }
    } else {
      navigate(
        `${URL_CHECKOUT_AUTHENTICATION}?redirect=${window.location.pathname}`,
      );
    }
  }, [isLoggedUser, createCheckout, navigate]);

  const handleUndoDelete = async (itemSelected: Items) => {
    if (!undoDeleteIsLoading) {
      removeItemRef.current = undefined;
      try {
        await undoDelete(itemSelected.product.id);
        dispatch(restoreProductCart(itemSelected));
      } catch (error) {
        setErrorCode(true);
      }
    }
  };

  const handleIncreaseQuantity = async (itemSelected?: Items) => {
    if (
      itemSelected &&
      canIncreaseQuantity(itemSelected) &&
      !changeItemQuantityIsLoading
    ) {
      try {
        await changeItemQuantity({
          product_id: itemSelected.product.id,
          quantity: 1,
          action: CardsCartOperation.INCREASE,
        });

        dispatch(increaseQuantityProduct(itemSelected));
      } catch (error) {
        setErrorCode(true);
      }
    }
  };

  const handleDecreaseQuantity = async (itemSelected?: Items) => {
    if (
      itemSelected &&
      canDecreaseQuantity(itemSelected) &&
      !changeItemQuantityIsLoading
    ) {
      try {
        await changeItemQuantity({
          product_id: itemSelected.product.id,
          quantity: 1,
          action: CardsCartOperation.DECREASE,
        });
        dispatch(decreaseQuantityProduct(itemSelected));
      } catch (error) {
        setErrorCode(true);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (removeItemRef.current) {
        clearTimeout(removeItemRef.current);
      }
    };
  }, [removeItemRef, dispatch]);

  return (
    <Stack flexDirection="column" gap={SpaceSizes.md} width="100%">
      {(createCheckoutIsError || errorCode) && (
        <Stack>
          <AlertCard
            content={t('errors.GENERIC_ERROR')}
            id="alert-error-checkout"
            severity={AlertSeverity.ERROR}
            sx={{
              margin: SpaceSizes.none,
              padding: SpaceSizes.none,
              fontSize: isMobile
                ? typography.cBody1.fontSize
                : typography.cH6.fontSize,
            }}
            show
          />
        </Stack>
      )}
      <Stack gap={SpaceSizes.mdPlus} height="auto" width="100%">
        <AnimatePresence mode="popLayout" presenceAffectsLayout>
          {cart?.map(({ product }) => {
            const originalItem = cart.find(
              (item) =>
                item.product.id === product.id && item.status === 'active',
            );
            const originalRemovedItem = cart.find(
              (item) =>
                item.product.id === product.id && item.status === 'deleted',
            );
            return originalItem ? (
              <Stack key={originalItem.product.id} gap={SpaceSizes.mdPlus}>
                <CardsCart
                  entityName={originalItem.product.entity_name}
                  handleDecreaseQuantity={handleDecreaseQuantity}
                  handleIncreaseQuantity={handleIncreaseQuantity}
                  image={originalItem.product.image}
                  originalItem={originalItem}
                  priceValue={originalItem.product.price.value}
                  productId={originalItem.product.id}
                  quantity={originalItem.selected_quantity}
                  subtitle={originalItem.product.subtitle.line1}
                  title={originalItem.product.title}
                  isCart
                  onRemove={() => handleRemove(originalItem)}
                />
              </Stack>
            ) : originalRemovedItem ? (
              <AnimatePresence
                key={originalRemovedItem.product.id}
                mode="popLayout"
                presenceAffectsLayout
              >
                <CardsCartHistory
                  removedItem={originalRemovedItem}
                  onUndoDelete={handleUndoDelete}
                />
              </AnimatePresence>
            ) : null;
          })}
        </AnimatePresence>
      </Stack>
      <Stack
        alignItems={{
          xs: 'center',
          md: 'flex-end',
        }}
        bgcolor="transparent"
        width="100%"
      >
        <Stack
          alignContent="center"
          gap={SpaceSizes.xs}
          justifyContent="flex-end"
          width={{
            xs: '100%',
            md: '30%',
          }}
        >
          <Stack
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            p={SpaceSizes.sm}
          >
            <Typography color="text.secondary" fontWeight="500" variant="body1">
              {t('total')}
            </Typography>
            <Typography fontWeight="500">
              <ProductPrice size="medium" value={totalPrice} />
            </Typography>
          </Stack>
          <Stack>
            <PaymentButton
              buttonText={t('button.confirmCart')}
              dataTestId="payment-cart-button"
              disableButton={shouldNotShowCheckoutButton(
                cartActive,
                createCheckoutIsLoading,
                modifiedStock,
                outOfStock,
                undefined,
              )}
              handleBuy={handleCreateCheckout}
              isLoading={createCheckoutIsLoading}
              showPrice={false}
              variant={isMobile ? 'body2' : 'subtitle1'}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
