export const CARD_PAYMENT_METHOD = 'card';

export const tiersProductIdToSkeleton = [
  {
    productId: 1,
  },
  {
    productId: 2,
  },
  {
    productId: 3,
  },
  {
    productId: 4,
  },
  {
    productId: 5,
  },
  {
    productId: 6,
  },
  {
    productId: 7,
  },
  {
    productId: 8,
  },
];
