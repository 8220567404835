import { createSelector } from '@reduxjs/toolkit';

import { Notification, NotificationStatusType } from './responses/Notification';

export const selectNotificationsByStatus = createSelector(
  (status: NotificationStatusType) => status,
  (status: NotificationStatusType, res?: Notification[]) => res,
  (status: NotificationStatusType, data?: Notification[]): Notification[] =>
    data?.filter((n) => n.status_code === status) ?? [],
);
