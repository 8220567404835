import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TradeOfferNotificationProductProps } from './TradeOfferNotificationProduct.types';

const TradeOfferNotificationProduct = ({
  offer,
  count,
}: TradeOfferNotificationProductProps) => {
  return (
    <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.xs}>
      <Stack flexDirection="column">
        <Typography fontSize={SpaceSizes.smPlus}>
          {offer?.player_name.toUpperCase()}
        </Typography>
        <Typography fontSize={SpaceSizes.smPlus}>
          {offer?.home_code} vs {offer?.away_code}
        </Typography>
      </Stack>
      {count > 1 && (
        <Typography fontSize={SpaceSizes.smPlus}>+{count - 1}</Typography>
      )}
    </Stack>
  );
};

export default TradeOfferNotificationProduct;
