import { createSelector } from '@reduxjs/toolkit';

import { getCheckoutApiResponse } from './Checkout.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectCheckout = (checkout: any) => checkout;

export const selectCheckoutProductId = createSelector(
  selectCheckout,
  (checkout: getCheckoutApiResponse) => checkout?.items[0].product_id as string,
);

export const selectCheckoutUserBalance = createSelector(
  selectCheckout,
  (checkout: getCheckoutApiResponse) => checkout?.user_balance,
);

export const selectCheckoutItems = createSelector(
  selectCheckout,
  (checkout: getCheckoutApiResponse) => checkout?.items,
);

export const selectCheckoutTotalPoints = createSelector(
  selectCheckout,
  (checkout: getCheckoutApiResponse) => checkout?.total_points,
);

export const selectCheckoutTiers = createSelector(
  selectCheckout,
  (checkout?: getCheckoutApiResponse) => checkout?.tiers,
);

export const selectCheckoutType = createSelector(
  selectCheckout,
  (checkout: getCheckoutApiResponse) => checkout?.type,
);

export const selectCheckoutCreatedAt = createSelector(
  selectCheckout,
  (checkout: getCheckoutApiResponse) => checkout?.created_at,
);
