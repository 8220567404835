import { Grid, Skeleton } from '@mui/material';
import React from 'react';

import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import useTabletMediaQuery from '../../../hooks/useTabletMediaQuery';
import { skeletonStyle } from './Presentation.constant';

export const PresentationSkeleton = () => {
  const isSmallestMobile = useSmallMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  return (
    <Grid
      height={isSmallestMobile ? '200px' : '280px'}
      width={isTablet ? '100%' : '72%'}
    >
      <Skeleton style={skeletonStyle(isSmallestMobile)} />
    </Grid>
  );
};
