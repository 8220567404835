import { MOVEMENT_FILTER } from '../../../features/movements/Movements.types';

export const DEFAULT_MOVEMENT_FILTER_OPTION = MOVEMENT_FILTER['7days'];

export enum MovementTabs {
  BUYS = 'buys',
  TRANSFERS = 'transfers',
}

export const MOVEMENTS_CONTENT_SCROLL_SIZE = 500;
