import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import {
  selectCurrentGol,
  selectProductGolClubId,
  selectProductLogo,
  selectProductPlayerName,
} from '../../../../features/products/Product.selectors';
import { useProductContext } from '../../../../provider/ProductProvider/ProductProvider';
import BorderRadius from '../../../../theme/foundations/borders/BorderRadius';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import ImageTint from '../../../../utils/imageTint/ImageTint';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../../hooks/useTabletMediaQuery';
import { MatchResult } from '../../MatchResult/MatchResult';
import Panel from '../../Panel/Panel';
import { SUMMARY_FONT_SIZE } from './CardTemplateFooter.constants';

export const CardTemplateFooter = ({
  isGolden = false,
}: {
  isGolden?: boolean;
}) => {
  const theme = useTheme();
  const product = useProductContext();
  const isTablet = useTabletMediaQuery();
  const isSmallestMobile = useMobileMediaQuery();

  const playerName = selectProductPlayerName(product);
  const currentGol = selectCurrentGol(product);
  const golClubId = selectProductGolClubId(product);
  const logo = selectProductLogo(product);

  const logoSize = isSmallestMobile ? '75%' : isTablet ? '50%' : '65%';

  const textColor = isGolden
    ? theme.palette.background.default
    : theme.palette.text.primary;

  return (
    <Stack height="100%" width="100%">
      <Panel
        sx={{
          width: 'auto',
          background: isGolden
            ? theme.palette.golden.gradient
            : theme.palette.background.paper,
          position: 'absolute',
          transform: 'translateY(-50%)',
          borderRadius: `0 ${BorderRadius.md} ${BorderRadius.md} 0`,
          justifyContent: 'center',
          flexDirection: 'row',
          padding: isSmallestMobile ? SpaceSizes.xs : SpaceSizes.sm,
          gap: SpaceSizes.sm,
          alignItems: 'center',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          color={textColor}
          fontSize={SUMMARY_FONT_SIZE}
          fontWeight="600"
          noWrap
        >
          {playerName}
        </Typography>
        <Typography
          color={textColor}
          fontSize={SUMMARY_FONT_SIZE}
          fontWeight="600"
          sx={{ opacity: 0.7 }}
        >
          {`${currentGol?.event_minute_in_match}'`}
        </Typography>
      </Panel>
      <Stack
        alignItems="center"
        flexDirection="column"
        gap={SpaceSizes.sm}
        height="100%"
        justifyContent="center"
        p={isSmallestMobile ? SpaceSizes.sm : SpaceSizes.md}
        pt={isSmallestMobile ? SpaceSizes.sm : SpaceSizes.lg}
        sx={{
          borderTop: 1,
          borderColor: isGolden
            ? theme.palette.golden.main
            : theme.palette.background.paper,
        }}
      >
        {!!product?.match && (
          <MatchResult
            away={product?.match.away}
            awayScore={currentGol?.event_data.away_partial_score}
            golClubId={golClubId}
            home={product?.match.home}
            homeScore={currentGol?.event_data.home_partial_score}
            iconsSize={isTablet ? 23 : 34}
            isGolden={isGolden}
            separator="vs"
            sx={{ gap: SpaceSizes.sm }}
            textVariant={isSmallestMobile ? 'body1' : 'h5'}
            variant="secondary"
          />
        )}
        {logo && (
          <ImageTint
            alt="tournament-logo"
            color={
              isGolden
                ? theme.palette.text.primary
                : theme.palette.primary.light
            }
            height="auto"
            src={logo || ''}
            width={logoSize}
          />
        )}
      </Stack>
    </Stack>
  );
};
