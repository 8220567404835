import { createSelector } from '@reduxjs/toolkit';

import { HomeResponse } from './Home.types';

export const selectHome = (home: HomeResponse | undefined) => home;

export const selectHomeFeatured = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter((homeItem) => homeItem.type === 'featured')[0],
);

export const selectHomeTopScorers = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter((homeItem) => homeItem.type === 'topScorers')[0],
);

export const selectHomeNextMatches = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter((homeItem) => homeItem.type === 'nextMatches')[0],
);

export const selectHomeTeamRankings = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter(
      (homeItem) => homeItem.type === 'teamsRankingMultiLeague',
    )[0],
);

export const selectHomeTopLeagues = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter((homeItem) => homeItem.type === 'topLeagues')[0],
);

export const selectHomeTeamProducts = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter((homeItem) => homeItem.type === 'teamProducts')[0],
);

export const selectHomeCollectablesProducts = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter(
      (homeItem) => homeItem.type === 'collectablesProducts',
    )[0],
);

export const selectHomeFeaturedProducts = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter(
      (homeItem) => homeItem.type === 'featuredProducts',
    )[0],
);

export const selectHomeSaleAndAuctions = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter(
      (homeItem) => homeItem.type === 'salesAndAuctions',
    )[0],
);

export const selectHomeAmbassadors = createSelector(
  selectHome,
  (home?: HomeResponse) =>
    home?.categories?.filter((homeItem) => homeItem.type === 'ambassadors')[0],
);
