import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { PresentationSkeleton } from '../../components/profile/Presentation/Presentation.skeleton';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { COLLECTABLE_SKELETON_FILTERS_AMOUNT } from '../marketplace/Marketplace.constants';
import {
  CARD_HEIGHT_DESKTOP,
  CARD_MOBILE_HEIGHT,
  CARD_MOBILE_WIDTH,
  CARD_WIDTH_DESKTOP,
  COLLECTIONS_LIMIT,
} from './Profile.constant';

export const ProfileSkeleton = () => {
  const isSmallestMobile = useSmallMobileMediaQuery();
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const isDesktop = useDesktopDeviceMediaQuery();

  return (
    <PageContainer id="profilePublic">
      <PresentationSkeleton />
      <Skeleton style={{ margin: 'auto' }}>
        <Typography
          fontWeight="800"
          sx={{ fontSize: { xs: SpaceSizes.mdPlus, md: SpaceSizes.lgPlus } }}
        >
          Colecciones
        </Typography>
      </Skeleton>
      <Stack marginTop={isDesktop ? SpaceSizes.lgPlus : SpaceSizes.md}>
        {!isDesktop && (
          <Grid mt={SpaceSizes.lg} xs={isDesktop ? 3 : 12} item>
            <Skeleton height={SpaceSizes.lgPlus} width={SpaceSizes.lgPlus} />
          </Grid>
        )}
        <Stack flexDirection="row">
          {isDesktop && (
            <Grid md={3} mt={SpaceSizes.lg} width="25%" xs={12} item>
              {Array.from(
                { length: COLLECTABLE_SKELETON_FILTERS_AMOUNT },
                (_, index) => {
                  return (
                    <Skeleton
                      key={`skeleton_colletablemarkettab_${_}${index}`}
                      height={SpaceSizes.lgPlus}
                      sx={{ marginY: SpaceSizes.xs }}
                      variant="rectangular"
                      width="100%"
                    />
                  );
                },
              )}
            </Grid>
          )}
          <Grid
            columnSpacing={{ xs: SpaceSizes.xs, sm: SpaceSizes.mdPlus }}
            justifyContent="center"
            rowSpacing={SpaceSizes.mdPlus}
            container
          >
            {Array.from({ length: COLLECTIONS_LIMIT }).map((_, index) => (
              <Grid
                key={`${timestamp.toString() + String(index)}`}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                item
              >
                <Skeleton
                  style={{
                    borderRadius: '20px',
                    height: isSmallestMobile
                      ? CARD_MOBILE_HEIGHT
                      : CARD_HEIGHT_DESKTOP,
                    width: isSmallestMobile
                      ? CARD_MOBILE_WIDTH
                      : CARD_WIDTH_DESKTOP,
                  }}
                  variant="rectangular"
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </PageContainer>
  );
};
