import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { isUndefined } from 'ramda-adjunct';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetAuctionQuery } from '../../../features/auction/Auction.api';
import { selectUserSession } from '../../../features/session/Session.selector';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useAppSelector } from '../../hooks';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import {
  GOLPOINTS_LOGO,
  PRODUCT_DETAIL_PRICE,
} from '../ProductDetail/ProductDetail.constants';
import ProductPrice from '../ProductPrice/ProductPrice';
import { CountdownComponent } from '../Trivia/Countdown';

const AuctionDetail = ({ productId = '' }: { productId?: string }) => {
  const { t } = useTranslationByNamespaces('pages.product.auction');
  const isTablet = useTabletMediaQuery();
  const navigate = useNavigate();
  const user = useAppSelector(selectUserSession);

  const { data, isLoading } = useGetAuctionQuery(
    {
      productId,
    },
    { skip: isUndefined(productId) },
  );

  const handlePress = () => {
    navigate(`/product/auction/${productId}`);
  };

  return (
    <Stack width="100%">
      {data?.status === 'closed' && (
        <Stack width="100%">
          <AlertCard
            content={t(`finished`)}
            id="auctionFinished"
            severity={AlertSeverity.INFO}
            show
          />
        </Stack>
      )}
      {data?.status === 'open' && (
        <Stack gap={SpaceSizes.sm} width="100%">
          <Stack alignItems="center" flexDirection="row">
            <Typography sx={{ marginRight: SpaceSizes.sm }} variant="body1">
              {t('price')}
            </Typography>
            <ProductPrice
              height={GOLPOINTS_LOGO}
              size={isTablet ? 'medium' : 'large'}
              sx={{ fontSize: PRODUCT_DETAIL_PRICE }}
              value={data.max_bid.bid_amount}
              width={GOLPOINTS_LOGO}
              showEuro
            />
          </Stack>

          <Stack
            alignItems="center"
            flexDirection="row"
            gap={SpaceSizes.sm}
            justifyContent="flex-start"
          >
            <Typography variant="body2">{`${data.bids_count} ${t(
              'totalOffers',
            )}`}</Typography>
            {user?.user_id === data?.max_bid?.user_id && (
              <Typography fontWeight="bold" variant="body2">
                - {t('winning')}
              </Typography>
            )}
          </Stack>

          <Stack gap={SpaceSizes.md}>
            <LoadingButton
              disabled={isLoading || user?.user_id === data?.max_bid?.user_id}
              id="auctionDetail"
              sx={{ borderRadius: SpaceSizes.lg, marginY: SpaceSizes.sm }}
              variant="contained"
              onClick={handlePress}
            >
              <Stack
                alignItems="center"
                flexDirection="row"
                gap={SpaceSizes.xs}
              >
                <Typography fontWeight="bold" letterSpacing={0.5}>
                  {t('auctionDetail')}
                </Typography>
              </Stack>
            </LoadingButton>
          </Stack>
          <CountdownComponent datetime={data?.end_dt} title={t('finish')} />
        </Stack>
      )}
    </Stack>
  );
};

export default AuctionDetail;
