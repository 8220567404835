import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';

export const ReferQuestions = () => {
  const { t } = useTranslationByNamespaces('pages.referrals.questions');
  const isSmallestMobile = useSmallMobileMediaQuery();

  return (
    <Stack gap={SpaceSizes.md}>
      <Stack gap={SpaceSizes.md}>
        <Typography
          fontWeight="600"
          variant={isSmallestMobile ? 'body1' : 'h6'}
        >
          {t('friends.title')}
        </Typography>
        <Stack gap={SpaceSizes.sm}>
          <Typography variant={isSmallestMobile ? 'body2' : 'body1'}>
            {t('friends.firstItem')}
          </Typography>
          <Typography variant={isSmallestMobile ? 'body2' : 'body1'}>
            {t('friends.secondItem')}
          </Typography>
          <Typography variant={isSmallestMobile ? 'body2' : 'body1'}>
            {t('friends.thirdItem')}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={SpaceSizes.md} py={SpaceSizes.md}>
        <Typography
          fontWeight="600"
          variant={isSmallestMobile ? 'body1' : 'h6'}
        >
          {t('golPoints.title')}
        </Typography>
        <Stack gap={SpaceSizes.sm}>
          <Typography variant={isSmallestMobile ? 'body2' : 'body1'}>
            {t('golPoints.forYou.title')}
          </Typography>
          <Typography
            component="li"
            variant={isSmallestMobile ? 'body2' : 'body1'}
          >
            {t('golPoints.forYou.firstItem')}
          </Typography>
          <Typography
            component="li"
            variant={isSmallestMobile ? 'body2' : 'body1'}
          >
            {t('golPoints.forYou.secondItem')}
          </Typography>
        </Stack>
        <Stack gap={SpaceSizes.sm}>
          <Typography variant={isSmallestMobile ? 'body2' : 'body1'}>
            {t('golPoints.forYourFriend.title')}
          </Typography>
          <Typography
            component="li"
            variant={isSmallestMobile ? 'body2' : 'body1'}
          >
            {t('golPoints.forYourFriend.firstItem')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
