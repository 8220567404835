import React from 'react';

import { NOTIFICATION_COMPONENTS } from './Notification.constants';
import { NotificationProps, NotificationType } from './Notification.types';
import NotificationItem from './NotificationItem';

export const NotificationWrapper = ({ notification }: NotificationProps) => {
  const NotificationComponent =
    NOTIFICATION_COMPONENTS[notification?.type as NotificationType] ||
    NotificationItem;

  return <NotificationComponent notification={notification} />;
};
