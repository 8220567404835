import { geti18nResolvedLanguage, LANGUAGES } from '../../app/i18n';
import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import { ExternalContentResponse } from '../externalContent/ExternalContent.types';
import {
  GetTriviaDetailsResponse,
  GetTriviaQuestionResponse,
  TriviaAnswerRequest,
  TriviaAnswerResponse,
  TriviaInfoItem,
  TriviaInfoRequest,
} from './Trivia.types';

export const TriviaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTriviaSummary: builder.query<GetTriviaDetailsResponse, string>({
      query: (battleId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRIVIA}trivia/battles/${battleId}/summary`,
        method: 'GET',
      }),
      providesTags: [RTKTags.Trivia],
      keepUnusedDataFor: 0,
    }),
    getTriviaQuestion: builder.query<GetTriviaQuestionResponse, string>({
      query: (battleId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRIVIA}trivia/battles/${battleId}/today-question`,
        method: 'GET',
        params: {
          lang_id: geti18nResolvedLanguage(),
        },
      }),
      providesTags: [RTKTags.Trivia],
      keepUnusedDataFor: 0,
    }),
    setTriviaAnswer: builder.mutation<
      TriviaAnswerResponse,
      TriviaAnswerRequest
    >({
      query: (body) => ({
        url: `${Env.REACT_APP_BASE_API_URL_TRIVIA}trivia/battles/${body.battle_id}/answer`,
        method: 'POST',
        body,
      }),
      extraOptions: {
        maxRetries: 3,
      },
    }),
    getTriviaInformation: builder.query<
      ExternalContentResponse<TriviaInfoItem>,
      TriviaInfoRequest
    >({
      query: ({ lang }) => {
        const langId = lang?.split('-')[0];
        return `${Env.REACT_APP_STATIC_BASE_URL_STRAPI}Educations?locale=${
          LANGUAGES.includes(langId) ? langId : 'es'
        }&filters[education_id][$eq]=trivia`;
      },
    }),
  }),
});

export const {
  useGetTriviaSummaryQuery,
  useGetTriviaQuestionQuery,
  useGetTriviaInformationQuery,
  useSetTriviaAnswerMutation,
} = TriviaApi;
