import { useGrowthBook } from '@growthbook/growthbook-react';
import { Stack } from '@mui/material';
import { isNonEmptyString } from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { selectIsUserLoggedIn } from '../../../../features/session/Session.selector';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { SHOW_CART } from '../../../../utils/growthbookFeatures';
import { TAG_VALUE } from '../../../../utils/tagger';
import { LogoHeader } from '../../../components/header/LogoHeader/LogoHeader';
import { SearchBall } from '../../../components/search/SearchBall/SearchBall';
import { useAppSelector } from '../../../hooks';
import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import {
  HEADER_LOGO_HEIGHT_DESKTOP,
  HEADER_LOGO_HEIGHT_MOBILE,
  HEADER_LOGO_WIDTH_DESKTOP,
  HEADER_LOGO_WIDTH_MOBILE,
} from '../Header.constants';
import { ShoppingCartMenu } from '../ShoppingCartMenu/ShoppingCartMenu';
import UserDropdown from '../UserDropdown/UserDropdown';
import MenuDropdown from './MenuDropdown';

const HeaderMobile = () => {
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [isDropdownUserOpen, setIsDropdownUserOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const q = useMemo(() => searchParams.get('q') ?? '', [searchParams]);
  const [openSearch, setOpenSearch] = useState(isNonEmptyString(q));
  const isMobile = useSmallMobileMediaQuery();
  const growthbook = useGrowthBook();

  const handleMenuDropdown = (isOpen: boolean) => {
    setIsDropdownMenuOpen(isOpen);
    setIsDropdownUserOpen(false);
  };
  const handleUserDropdown = (isOpen: boolean) => {
    setIsDropdownUserOpen(isOpen);
    setIsDropdownMenuOpen(false);
  };

  const handleOpenCloseSearch = useCallback(() => {
    setOpenSearch(!openSearch);
  }, [openSearch]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={SpaceSizes.sm}
    >
      <Stack alignItems="center" direction="row" spacing={SpaceSizes.sm}>
        {!openSearch && (
          <>
            <MenuDropdown
              isDropdownOpen={isDropdownMenuOpen}
              isLoggedUser={isLoggedUser}
              setIsDropdownOpen={handleMenuDropdown}
            />
            <LogoHeader
              height={
                isMobile
                  ? HEADER_LOGO_HEIGHT_MOBILE
                  : HEADER_LOGO_HEIGHT_DESKTOP
              }
              tagId={TAG_VALUE.HEADER.LOGO}
              width={
                isMobile ? HEADER_LOGO_WIDTH_MOBILE : HEADER_LOGO_WIDTH_DESKTOP
              }
            />
          </>
        )}
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={SpaceSizes.sm}
      >
        <SearchBall
          openSearch={openSearch}
          setOpenSearch={handleOpenCloseSearch}
        />
        {growthbook?.isOn(SHOW_CART) && isLoggedUser && <ShoppingCartMenu />}
        {isLoggedUser && !openSearch && (
          <UserDropdown
            isDropdownOpen={isDropdownUserOpen}
            setIsDropdownOpen={handleUserDropdown}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default HeaderMobile;
