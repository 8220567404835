export const CARD_WIDTH_DESKTOP = 240;
export const CARD_HEIGHT_DESKTOP = 390;
export const CARD_MOBILE_HEIGHT = 230;
export const CARD_MOBILE_WIDTH = 140;
export const COLLECTIONS_LIMIT = 8;
export const TITLE_FONT_SIZE = {
  xs: 20,
  sm: 26,
  md: 30,
};
