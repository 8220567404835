import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { CopyAction } from '../CopyAction/CopyAction';
import { ShareModalProps } from './ShareModal.types';
import { SHARE_BUTTONS } from './ShareOptions';

export const ShareModal = ({ onClose, text, link }: ShareModalProps) => {
  const sendDataToGTM = useGTMDispatch();

  const { t } = useTranslation();

  const trackShareLink = useCallback(
    (socialMedia: string, copiedLink: string) => {
      sendDataToGTM({
        event: TAG_EVENT.SHARE_LINK,
        app_id: socialMedia,
        link: copiedLink,
      });
    },
    [sendDataToGTM],
  );

  return (
    <Box
      alignItems="center"
      data-testid="share-modal"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <DialogTitle
        sx={{ display: 'flex', width: '100%', alignItems: 'center' }}
      >
        <Typography component="p" variant="h5" width="100%">
          {t('shareModal.title')}
        </Typography>
        <Box display="flex" justifyContent="right">
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={SpaceSizes.smPlus}
        justifyContent="center"
        margin="10px"
      >
        {SHARE_BUTTONS.map(({ component: ShareButton, icon, key }) => (
          <ShareButton
            key={key}
            body={text}
            data-testid={key}
            quote={text}
            subject={text}
            title={text}
            url={link}
            openShareDialogOnClick
            onClick={() => trackShareLink(key, link)}
          >
            {icon}
          </ShareButton>
        ))}
      </Stack>
      <CopyAction dateTestId="button-share-copy" sharedLink={link} />
    </Box>
  );
};
