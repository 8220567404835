import { Typography } from '@mui/material';
import React from 'react';

import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { Link } from '../Link/Link';
import Panel from '../Panel/Panel';
import ProductDetailTitle from '../ProductDetailTitle/ProductDetailTitle';

const HowPossible = ({
  isProductAuction = false,
}: {
  isProductAuction: boolean;
}) => {
  const { t } = useTranslationByNamespaces('pages.product.howPossible');

  return (
    <Panel sx={{ alignItems: 'flex-start' }}>
      <ProductDetailTitle variant="cH3">{t('title')}</ProductDetailTitle>
      <Typography color="text.secondary" sx={{ marginTop: 10 }}>
        • {t('step1')}
      </Typography>
      <Typography color="text.secondary">• {t('step2')}</Typography>
      <Typography color="text.secondary">• {t('step3')}</Typography>
      {!isProductAuction && (
        <Typography color="text.secondary">• {t('step4')}</Typography>
      )}
      <Link
        color="secondary"
        id="seeMoreHowPossible"
        to="https://www.youtube.com/watch?v=eClgETwPLqw"
        external
      >
        <Typography sx={{ marginTop: 10, marginLeft: 12 }} variant="body1">
          {t('seeMore')}
        </Typography>
      </Link>
    </Panel>
  );
};

export default HowPossible;
