import { useEffect, useState } from 'react';

const useIsIphone = (): boolean => {
  const [isIphone, setIsIphone] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.navigator) {
      const { userAgent } = window.navigator;
      setIsIphone(/iPhone/i.test(userAgent));
    }
  }, []);

  return isIphone;
};

export default useIsIphone;
