import {
  Avatar,
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CollectionListItem } from '../../../features/collections/Collections.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { CollectionSelectorI } from './CardAlbum.types';

export const CollectionSelector = ({
  collections,
  pickedCollection,
  setPickedCollection,
  loading,
}: CollectionSelectorI) => {
  const navigate = useNavigate();
  const { collectionGroupId } = useParams();

  const handleSelectCollection = useCallback(
    (e: SelectChangeEvent) => {
      setPickedCollection(e.target.value);
      navigate(`/collections/${collectionGroupId}/${e.target.value}`);
    },
    [navigate, setPickedCollection, collectionGroupId],
  );

  const getAvatar = (collection: CollectionListItem) => {
    return collection?.multimedia?.find((item) => item.type === 'HORIZONTAL')
      ?.location;
  };

  // do not render if collection group is size 1
  if (
    collections &&
    collections?.collectionByGroup &&
    collections?.collectionByGroup.length === 1
  ) {
    return null;
  }

  return (
    <Box width="100%">
      <FormControl fullWidth>
        {loading ? (
          <Skeleton height={40} variant="rectangular" width="100%" />
        ) : (
          <Select
            sx={{
              div: {
                paddingY: SpaceSizes.xxs,
              },
              ':before': {
                content: 'none',
              },
              padding: SpaceSizes.xs,
            }}
            value={pickedCollection}
            variant="filled"
            fullWidth
            onChange={handleSelectCollection}
          >
            {collections?.collectionByGroup?.map((collection) => {
              return (
                <MenuItem
                  key={collection.collection_id}
                  value={collection.collection_id}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: SpaceSizes.sm,
                    }}
                  >
                    {getAvatar(collection) && (
                      <Avatar
                        src={getAvatar(collection)}
                        sx={{
                          height: SpaceSizes.mdPlus,
                          width: SpaceSizes.mdPlus,
                        }}
                      />
                    )}
                    <Typography>{collection.title}</Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};
