import { Button, Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { tiersProductIdToSkeleton } from './Tiers.constants';

export const TiersSkeleton = () => {
  return (
    <PageContainer id="tiersPage" maxWidth="sm" backButton>
      <Stack
        alignItems="center"
        gap={SpaceSizes.lg}
        justifyContent="center"
        width="100%"
      >
        <Stack
          alignContent="center"
          alignItems="center"
          flexDirection="column"
          gap={SpaceSizes.xs}
          justifyContent="center"
          width="100%"
        >
          <Stack
            alignItems="center"
            borderRadius={`${SpaceSizes.smPlus}px`}
            flexDirection="column"
            gap={SpaceSizes.xs}
            sx={{
              backgroundColor: 'background.paper',
              width: '100%',
              padding: SpaceSizes.smPlus,
            }}
          >
            <Skeleton height={SpaceSizes.lg} variant="text" width="20%" />
            <Stack
              alignItems="center"
              flexDirection="row"
              gap={SpaceSizes.smPlus}
            >
              <Skeleton
                height={SpaceSizes.lgPlus}
                variant="text"
                width={SpaceSizes.xlPlus}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={SpaceSizes.sm} width="100%">
          <Stack gap={{ xs: SpaceSizes.sm, sm: SpaceSizes.md }} width="100%">
            {tiersProductIdToSkeleton.map((tier) => (
              <Stack
                key={tier.productId}
                borderRadius={`${SpaceSizes.smPlus}px`}
                flexDirection="row"
                sx={{
                  backgroundColor: 'background.paper',
                  width: '100%',
                  padding: SpaceSizes.sm,
                  paddingLeft: SpaceSizes.lg,
                }}
              >
                <Stack justifyContent="center">
                  <Skeleton
                    height={SpaceSizes.mdPlus}
                    variant="circular"
                    width={SpaceSizes.mdPlus}
                  />
                </Stack>
                <Stack alignItems="center" ml={SpaceSizes.md}>
                  <Skeleton
                    height={SpaceSizes.mdPlus}
                    variant="text"
                    width={SpaceSizes.xxl}
                  />
                  <Skeleton
                    height={SpaceSizes.mdPlus}
                    variant="text"
                    width={SpaceSizes.xxl}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack alignItems="center" justifyContent="center" width="100%">
          <Button
            data-testid="receive-button-open"
            id="receieve"
            sx={{
              padding: SpaceSizes.smPlus,
              borderRadius: `${SpaceSizes.md}px`,
              width: {
                xs: '95%',
                md: '50%',
              },
            }}
            variant="contained"
          >
            <Skeleton
              height={SpaceSizes.mdPlus}
              variant="text"
              width={SpaceSizes.xl}
            />
          </Button>
        </Stack>
      </Stack>
    </PageContainer>
  );
};
