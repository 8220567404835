import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  addURLParamByKey,
  deleteURLParamByKey,
} from '../../router/router.utils';
import {
  FILTER_EXCLUDED_KEYS,
  TRADES_KEY,
  USER_KEY,
} from '../Filters.constants';
import { AppliedFilter, AppliedSort } from '../responses/SearchResponse';
import { searchFiltersURL } from '../SearchFilter.api';

export interface SearchMyCollectionFilterState {
  searchQuery: string;
  appliedFilters: AppliedFilter[];
  appliedSort: AppliedSort;
}

const initialState = {
  searchQuery: '',
  appliedFilters: [],
  appliedSort: {
    id: 'relevance',
    name: 'Más relevantes',
  },
} as SearchMyCollectionFilterState;

const myCollectionFilterSlice = createSlice({
  name: 'myCollectionFilter',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.appliedFilters = [];
      state.searchQuery = '';
    },
    addFilter: (state, action: PayloadAction<AppliedFilter>) => {
      const filterIndex = state.appliedFilters.findIndex(
        (f) => f.id === action.payload.id,
      );
      // if filter exists update its value
      if (filterIndex >= 0) {
        state.appliedFilters[filterIndex] = action.payload;
      } else {
        // if filter does not exist add it
        state.appliedFilters.push(action.payload);
      }
      addURLParamByKey(action.payload.id, action.payload.value);
    },
    removeFilter: (state, action: PayloadAction<string>) => {
      state.appliedFilters = state.appliedFilters.filter(
        (appliedFilter) => appliedFilter.id !== action.payload,
      );
      deleteURLParamByKey(action.payload);
    },
    syncAppliedFilters: (state, action: PayloadAction<AppliedFilter[]>) => {
      state.appliedFilters = state.appliedFilters.filter((filter) => {
        // Check if the current filter is included in the new filters
        const isInNewFilters = action.payload.some(
          (newFilter) => newFilter.id === filter.id,
        );

        // If the filter is not in the new filters and it is not one of the excluded keys,
        // remove it from the URL
        if (!isInNewFilters && !FILTER_EXCLUDED_KEYS.includes(filter.id)) {
          deleteURLParamByKey(filter.id);
        }

        // Keep the filter in the state if it is in the new filters or it is one of the excluded keys
        return isInNewFilters || FILTER_EXCLUDED_KEYS.includes(filter.id);
      });
    },

    setTradableFilter: (state, action: PayloadAction<string>) => {
      state.searchQuery = '';
      state.appliedFilters = [];

      // remove filters from query string
      searchFiltersURL.forEach((filter) => {
        deleteURLParamByKey(filter);
      });

      state.appliedFilters.push({
        id: TRADES_KEY,
        value: 'true',
        type: 'boolean',
        name: TRADES_KEY,
        valueLabel: '',
      });

      if (action?.payload) {
        state.appliedFilters.push({
          id: USER_KEY,
          value: action.payload,
          type: USER_KEY,
          name: USER_KEY,
          valueLabel: '',
        });
      }
    },

    setMyCollectionFilter: (state, action: PayloadAction<string>) => {
      state.searchQuery = '';
      state.appliedFilters = [];

      // remove filters from query string
      searchFiltersURL.forEach((filter) => {
        deleteURLParamByKey(filter);
      });

      if (action?.payload) {
        state.appliedFilters.push({
          id: USER_KEY,
          value: action.payload,
          type: USER_KEY,
          name: USER_KEY,
          valueLabel: '',
        });
      }
    },
  },
});

export const {
  clearFilters,
  addFilter,
  removeFilter,
  syncAppliedFilters,
  setTradableFilter,
  setMyCollectionFilter,
} = myCollectionFilterSlice.actions;

export default myCollectionFilterSlice.reducer;
