import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import theme from '../../../theme';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { CardsCart } from '../CardsCart/CardsCart';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import ProductPrice from '../ProductPrice/ProductPrice';
import { CheckoutTypeCartProps } from './CheckoutTypeCart.types';

export const CheckoutTypeCart = ({
  items,
  totalPoints,
  userBalance,
  createPayIsLoading = false,
  handlePayment,
  isProduct,
  sx = {},
}: CheckoutTypeCartProps) => {
  const { t } = useTranslationByNamespaces('pages.checkout');
  const isMobile = useMobileMediaQuery();
  return (
    <Stack alignItems="center" flexDirection="column" gap={SpaceSizes.md}>
      <Stack
        flexDirection="column"
        gap={SpaceSizes.mdPlus}
        width={{ xs: '100%', md: '100%', ...sx }}
      >
        {items?.map((item) => (
          <CardsCart
            key={item.product_id}
            entityName={item.entity_name}
            image={item.image}
            priceValue={parseInt(item.price.value, 10)}
            productId={item.product_id}
            quantity={item.quantity}
            subtitle={item.subtitle.line1}
            title={item.title}
          />
        ))}
      </Stack>
      {isProduct && handlePayment && (
        <Stack
          alignItems={{ xs: 'center', md: 'flex-end' }}
          bgcolor={isMobile ? 'background.paper' : 'transparent'}
          paddingX={isMobile ? SpaceSizes.md : SpaceSizes.none}
          paddingY={SpaceSizes.md}
          style={{
            borderTopLeftRadius: SpaceSizes.sm,
            borderBottomLeftRadius: SpaceSizes.sm,
            borderTopRightRadius: SpaceSizes.sm,
            borderBottomRightRadius: SpaceSizes.sm,
          }}
          width="100%"
        >
          <Stack gap={SpaceSizes.smPlus} width={{ xs: '100%', md: '30%' }}>
            <Stack gap={SpaceSizes.sm}>
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  color="text.secondary"
                  variant={isMobile ? 'caption' : 'body2'}
                >
                  {t('amounts.priceGolball')}
                </Typography>
                <ProductPrice
                  size={isMobile ? 'small' : 'medium'}
                  value={totalPoints}
                />
              </Stack>
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  color="text.secondary"
                  variant={isMobile ? 'caption' : 'body2'}
                >
                  {t('amounts.userBalance')}
                </Typography>
                <ProductPrice
                  size={isMobile ? 'small' : 'medium'}
                  value={userBalance}
                />
              </Stack>
              <Divider sx={{ width: '100%' }} />
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  color="success.main"
                  variant={isMobile ? 'caption' : 'body2'}
                >
                  {t('amounts.YouWouldHave')}
                </Typography>
                <ProductPrice
                  size={isMobile ? 'small' : 'medium'}
                  sx={{
                    color: 'success.main',
                  }}
                  value={userBalance - totalPoints}
                />
              </Stack>
            </Stack>
            <Stack>
              <PaymentButton
                buttonText={t('buy')}
                dataTestId="payment-button"
                disableButton={createPayIsLoading}
                handleBuy={handlePayment}
                id="confirm_buy"
                showPrice={false}
                variant={isMobile ? 'body2' : 'subtitle1'}
              />
              <Backdrop
                open={createPayIsLoading}
                sx={{
                  zIndex: theme.zIndex.drawer + 1,
                  width: '100%',
                  height: '100%',
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
