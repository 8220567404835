import { Stack, useTheme } from '@mui/material';
import React from 'react';

const BOX_LOGO =
  'https://static.prod.golball.com/marketplace/img/golball/logos/box-logo.png';

const LogoBoxBanner = () => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        border: `0.5px solid ${theme.palette.text.primary}`,
        borderRadius: '16px',
      }}
    >
      <img alt="app logo" height="64px" src={BOX_LOGO} width="64px" />
    </Stack>
  );
};

export default LogoBoxBanner;
