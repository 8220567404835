interface StatusTitle {
  [key: string]: string;
}

export const statusTitleFunction = (t: (str: string) => void) => {
  const statusTitle: StatusTitle = {
    rejected: `${t('details.rejected')}`,
    accepting: `${t('details.accepted')}`,
    opened: `${t('details.pending')}`,
    in_progress: `${t('details.inProgress')}`,
    expired: `${t('details.expired')}`,
    complete: `${t('details.complete')}`,
  };
  return statusTitle;
};
