import { Items } from './CardsCart.interfaces';

export const canIncreaseQuantity = (itemSelected?: Items) =>
  itemSelected &&
  itemSelected.selected_quantity <
    parseInt(itemSelected.max_allowed_quantity, 10) &&
  itemSelected.selected_quantity < itemSelected.available_stock;

export const canDecreaseQuantity = (itemSelected?: Items) =>
  itemSelected && itemSelected.selected_quantity > 1;
