import type { Middleware } from '@reduxjs/toolkit';
import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  PreloadedState,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { isNilOrEmpty } from 'ramda-adjunct';
import logger from 'redux-logger';

import Env from '../env/env';
import alertReducer from '../features/alert/Alert.slice';
import { api } from '../features/api/Api';
import brandSlice from '../features/brand/Brand.slice';
import collectionsReducer from '../features/collections/Collections.slice';
import countriesReducer from '../features/countries/Countries.slice';
import FansPreferencesReducer from '../features/fansPreferences/FansPreferences.slice';
import searchMyCollectionReducer from '../features/searchfilter/mycollection/MyCollectionFilter.slice';
import searchFilterReducer from '../features/searchfilter/SearchFilter.slice';
import sessionReducer from '../features/session/Session.slice';
import shoppingCartReducer from '../features/shoppingCart/ShoppingCart.slice';
import tradesReducer from '../features/trades/Trades.slice';

// Create the root reducer independently to obtain the RootState type
const rootReducer = combineReducers({
  searchFilter: searchFilterReducer,
  myCollectionFilter: searchMyCollectionReducer,
  alert: alertReducer,
  session: sessionReducer,
  trades: tradesReducer,
  countries: countriesReducer,
  fansPreferences: FansPreferencesReducer,
  collections: collectionsReducer,
  brand: brandSlice,
  shoppingCart: shoppingCartReducer,
  [api.reducerPath]: api.reducer,
});

/**
 * Check if an error is due to an aborted request or other client-side errors
 */
const isAbortedRequestError = (error: unknown) => {
  return (
    error instanceof DOMException &&
    error.name === 'AbortError' &&
    error.message !== 'The user aborted a request.' // exclude timeouts
  );
};

/**
 * Add a Sentry error-capture middleware
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // it's an unknown api error
    if (
      isNilOrEmpty(action?.payload?.data) &&
      !isAbortedRequestError(action?.payload?.error)
    ) {
      const { method, url, body } = action.meta.baseQueryMeta.request;
      const fingerprint = [
        'API-ERROR',
        method,
        action?.meta?.arg?.endpointName,
      ];

      // is an api unknown error
      Sentry.captureException(
        new Error(
          `API-ERROR-${action?.meta?.arg?.endpointName}-${action?.payload?.error}`,
        ),
        {
          level: 'error',
          extra: {
            request: {
              method,
              url,
              body,
            },
          },
          fingerprint,
        },
      );
    }
  }
  return next(action);
};

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: Env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
      const middlewares = [api.middleware];

      // add logger only for dev
      if (Env.NODE_ENV === 'development') {
        middlewares.push(logger);
      }

      middlewares.push(rtkQueryErrorLogger);

      return getDefaultMiddleware().concat(middlewares);
    },
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
