import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { Link } from '../Link/Link';
import { ListBullets } from '../ListBullets/ListBullets';
import { BenefitItemProps } from './BenefitIem.types';
import {
  containerTextStyled,
  descriptionStyled,
  linkStyled,
  paperContainerStyled,
  paperStyled,
  titleStyled,
} from './BenefitItem.styled';

export const BenefitItem = ({
  icon,
  description,
  list,
  title,
  actionUrl,
}: BenefitItemProps) => {
  const { t } = useTranslationByNamespaces('components.productBenefits');
  return (
    <Paper elevation={SpaceSizes.none} sx={paperStyled}>
      <Stack sx={paperContainerStyled}>
        {icon && <img alt={title} src={icon} />}

        <Stack sx={containerTextStyled}>
          <Typography component="h3" sx={titleStyled}>
            {title}
          </Typography>
          {description && (
            <Typography sx={descriptionStyled}>{description}</Typography>
          )}
          {list?.length && (
            <ListBullets colorItemList="text.secondary" list={list} />
          )}

          {actionUrl && (
            <Link sx={linkStyled} target="_blank" to={actionUrl}>
              {t('expand')}
            </Link>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
