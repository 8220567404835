import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Stack, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../env/env';
import { selectProductId } from '../../../features/products/Product.selectors';
import BorderRadius from '../../../theme/foundations/borders/BorderRadius';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import ImageTint from '../../../utils/imageTint/ImageTint';
import { boxShadowMixin } from '../../../utils/mixins';
import { TAG_EVENT } from '../../../utils/tagger';
import { useGetItemToTrack } from '../../../utils/useGetItemToTrack';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { CardTemplate } from '../Card/CardTemplate';
import { MarketplaceCardHeader } from './Header/MarketplaceCardHeader';
import { MarketplaceCardProps } from './MarketplaceCard.types';

const BrandSticker = () => {
  const isMobile = useMobileMediaQuery();
  const theme = useTheme();
  return (
    <Stack
      boxShadow={boxShadowMixin(theme.palette.secondary.light)}
      sx={{
        width: 'auto',
        borderRadius: `${BorderRadius.md} 0 ${BorderRadius.md} 0`,
        justifyContent: 'center',
        alignItems: 'center',
        padding: isMobile ? SpaceSizes.sm : SpaceSizes.smPlus,
        backgroundColor: 'background.paper',
      }}
    >
      <ImageTint
        alt="movistar"
        color="white"
        height={`${isMobile ? SpaceSizes.smPlus : SpaceSizes.md}`}
        src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/logos/brands/movistar/movistar-big.png`}
        width="auto"
      />
    </Stack>
  );
};

const MarketplaceCard = ({ product, index }: MarketplaceCardProps) => {
  const productId = selectProductId(product);
  const sendDataToGTM = useGTMDispatch();

  const navigate = useNavigate();

  const itemToTrack = useGetItemToTrack(product, index);

  const selectItemTrack = useCallback(() => {
    sendDataToGTM({
      event: TAG_EVENT.SELECT_ITEM,
      ecommerce: {
        items: [itemToTrack],
      },
    });
  }, [sendDataToGTM, itemToTrack]);

  const handleClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();

      selectItemTrack();
      navigate(`/product/${productId}`);
    },
    [navigate, productId, selectItemTrack],
  );

  return (
    <CardTemplate
      brandSticker={<BrandSticker />}
      header={<MarketplaceCardHeader />}
      product={product}
      onClick={handleClick}
    />
  );
};

export default MarketplaceCard;
