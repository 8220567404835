import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import env from '../../../../env/env';
import { TriviaAnswerResponse } from '../../../../features/trivia/Trivia.types';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../../../components/ImageFallback/ImageFallback';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';

const triviaRightAnswerIcon = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/trivia/trivia_right_answer.png`;
const triviaWrongAnswerIcon = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/trivia/trivia_wrong_answer.png`;
const triviaWrongAnswerEliminatedIcon = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/trivia/trivia_wrong_answer_eliminated.png`;

const TriviaAnswer = ({ answer }: { answer: TriviaAnswerResponse }) => {
  const { t } = useTranslationByNamespaces('pages.trivia');
  const determineAnswerData = useCallback(() => {
    if (
      answer?.user_answer_status === 'right' &&
      answer.remaining_rounds === 1
    ) {
      return { key: 'rightLastRound', icon: triviaRightAnswerIcon };
    }

    if (
      answer?.user_answer_status === 'right' &&
      answer.remaining_rounds !== 1
    ) {
      return { key: 'right', icon: triviaRightAnswerIcon };
    }

    if (answer?.user_answer_status === 'wrong' && answer.user_lives > 0) {
      return { key: 'wrong', icon: triviaWrongAnswerIcon };
    }

    if (answer?.user_answer_status === 'wrong' && answer.user_lives === 0) {
      return { key: 'wrongEliminated', icon: triviaWrongAnswerEliminatedIcon };
    }
    return {};
  }, [answer]);

  const { icon, key } = determineAnswerData();

  if (!icon && !key) return null;

  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      gap={SpaceSizes.md}
      textAlign="center"
      width="100%"
    >
      <ImageFallback height={48} src={icon} width={48} />
      <Typography variant="h5">
        {t(`triviaGame.battleAnswerCases.${key}.title`)}
      </Typography>
      <Typography variant="body2">
        {t(`triviaGame.battleAnswerCases.${key}.description`)}
      </Typography>
    </Stack>
  );
};

export default TriviaAnswer;
