import { Stack } from '@mui/material';
import React from 'react';

import Env from '../../../env/env';
import { ShareAction } from '../ShareAction/ShareAction';
import { ProductShareWidgetProps } from './ProductShareWidget.types';

const ProductShareWidget = ({ productId }: ProductShareWidgetProps) => {
  return (
    <Stack
      alignContent="center"
      direction="row"
      display="flex"
      justifyContent="center"
    >
      <ShareAction link={`${Env.REACT_APP_BASE_APP_URL}product/${productId}`} />
    </Stack>
  );
};
export default ProductShareWidget;
