import { ExpandLess } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';

import brandOptions from '../../../../brands/brandOptions';
import { selectCurrentBrand } from '../../../../features/brand/Brand.selector';
import { setBrand } from '../../../../features/brand/Brand.slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useGetAvailableLeaguesId } from '../../../hooks/useGetAvailableLeaguesId';

export const BrandMenu = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  // cambiarlo por get brands
  const brands = useGetAvailableLeaguesId();
  const selectedBrand = useAppSelector(selectCurrentBrand);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const brandMenuOpened = Boolean(anchorEl);

  const handleClickBrandMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBrandMenu = () => {
    setAnchorEl(null);
  };

  if (!brands) {
    return null;
  }

  return (
    <>
      {brands && brands.length === 1 ? (
        <Typography
          component="div"
          sx={{ mr: 2, fontSize: 'medium', p: 1, color: 'white' }}
          variant="button"
          noWrap
        >
          {selectedBrand?.name.toLocaleUpperCase()}
        </Typography>
      ) : (
        <Button
          aria-controls={brandMenuOpened ? 'menu-brands' : undefined}
          aria-expanded={brandMenuOpened ? 'true' : undefined}
          aria-haspopup="true"
          aria-label="List of brands"
          endIcon={brandMenuOpened ? <ExpandLess /> : <ExpandMore />}
          size="large"
          sx={{
            mr: 2,
          }}
          variant="contained"
          disableElevation
          onClick={handleClickBrandMenu}
        >
          {selectedBrand?.name}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        id="menu-brands"
        open={brandMenuOpened}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        keepMounted
        onClose={handleCloseBrandMenu}
      >
        {brandOptions
          .filter(
            (brand) =>
              brands?.findIndex((brandId) => brand.id === brandId) !== -1,
          )
          .map((brand) => {
            return (
              <MenuItem
                key={brand.id}
                onClick={() => {
                  handleCloseBrandMenu();
                  dispatch(setBrand(brand.id));
                }}
              >
                <Typography textAlign="center">{brand.name}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};
