import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  selectIsGoldenTicket,
  selectProductHasTrivia,
  selectProductId,
  selectProductIsTriviaStarted,
} from '../../../features/products/Product.selectors';
import { TAG_EVENT } from '../../../utils/tagger';
import { useGetItemToTrack } from '../../../utils/useGetItemToTrack';
import { CardTemplate } from '../Card/CardTemplate';
import { GolBallCardProps } from './GolBallCard.types';
import { GolBallCardHeader } from './Header/GolBallCardHeader';

const GolBallCard = ({ product, index, isSelf }: GolBallCardProps) => {
  const productId = selectProductId(product);
  const sendDataToGTM = useGTMDispatch();

  const navigate = useNavigate();

  const itemToTrack = useGetItemToTrack(product, index);

  const isGolden = selectIsGoldenTicket(product);
  const triviaStarted = selectProductIsTriviaStarted(product);

  const selectItemTrack = useCallback(() => {
    sendDataToGTM({
      event: TAG_EVENT.SELECT_ITEM,
      ecommerce: {
        items: [itemToTrack],
      },
    });
  }, [sendDataToGTM, itemToTrack]);

  const handleClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();

      selectItemTrack();
      if (isSelf) {
        return navigate(`/my-collection/${productId}`);
      }
      return navigate(`/product/${productId}`);
    },
    [navigate, productId, selectItemTrack, isSelf],
  );

  const hasTrivia = selectProductHasTrivia(product);

  return (
    <CardTemplate
      header={<GolBallCardHeader hasTrivia={hasTrivia} isGolden={isGolden} />}
      isGolden={isGolden}
      product={product}
      triviaStarted={triviaStarted}
      onClick={handleClick}
    />
  );
};

export default GolBallCard;
