import PersonIcon from '@mui/icons-material/Person';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';

import { selectUserSession } from '../../../features/session/Session.selector';
import { useAppSelector } from '../../hooks';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { UserAvatarProps } from './UserAvatar.types';

const UserAvatar = ({
  sx,
  height = '45',
  width = '45',
  onClick,
  ...props
}: UserAvatarProps) => {
  const user = useAppSelector(selectUserSession);
  const isMobile = useMobileMediaQuery();

  return user ? (
    <Avatar
      alt={user.nickname}
      data-testid="userMenu"
      src={user?.image_url}
      {...props}
      sx={{
        cursor: 'pointer',
        height,
        width,
        backgroundColor: 'background.default',
        color: 'text.primary',
        ...sx,
      }}
      onClick={onClick}
    />
  ) : (
    <Button
      sx={{
        borderRadius: 8,
      }}
      onClick={onClick}
    >
      <PersonIcon
        data-testid="userMenu"
        fontSize={isMobile ? 'inherit' : 'medium'}
      />
    </Button>
  );
};

export default UserAvatar;
