import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { URL_CHECKOUT_AUTHENTICATION } from '../../constants/checkout/checkout';
import env from '../../env/env';
import {
  usePayCheckoutMutation,
  usePayTopUpMutation,
} from '../../features/checkout/Checkout.api';
import {
  GENERIC_ERROR,
  NO_FUNDS,
  OUT_OF_STOCK,
} from '../../features/checkout/Checkout.constants';
import { checkoutPayFactory } from '../../features/checkout/Checkout.factory';
import { CheckoutStatusCodeEnum } from '../../features/checkout/Checkout.types';
import {
  selectLocationIp,
  selectUserSession,
} from '../../features/session/Session.selector';
import { useAppSelector } from '../hooks';

export const paymentErrors = [NO_FUNDS, OUT_OF_STOCK];

export const updatePayExtractErrors = (error: unknown) => {
  const err = error as { data: { code: string } };
  return paymentErrors.find((errorCode) => errorCode === err?.data?.code)
    ? err?.data?.code
    : GENERIC_ERROR;
};

export const useCheckoutPay = (url: {
  successUrl: string;
  cancelUrl: string;
}) => {
  const [selectedTier, setSelectedTier] = useState<{
    topUpId?: string;
    points: number;
  }>({
    topUpId: undefined,
    points: 0,
  });
  const { topUpId } = selectedTier || {};
  const [errorCode, setErrorCode] = useState<string>('');
  const { checkoutId } = useParams();
  const { successUrl, cancelUrl } = url;
  const [
    payTopUp,
    { isLoading: payTopUpIsLoading, isSuccess: payTopUpIsSuccess },
  ] = usePayTopUpMutation();
  const [
    payCheckout,
    { isLoading: payCheckoutIsLoading, isSuccess: payCheckoutIsSuccess },
  ] = usePayCheckoutMutation();
  const navigate = useNavigate();
  const user = useAppSelector(selectUserSession);
  const userIp = useAppSelector(selectLocationIp);

  const disableButton = useMemo(() => {
    if (!topUpId) return true;
    return payTopUpIsLoading && !payTopUpIsSuccess;
  }, [payTopUpIsLoading, payTopUpIsSuccess, topUpId]);

  const createPay = checkoutId ? payCheckout : payTopUp;
  const isLoading = checkoutId ? payCheckoutIsLoading : payTopUpIsLoading;
  const isSuccess = checkoutId ? payCheckoutIsSuccess : payTopUpIsSuccess;

  const handlePayment = async () => {
    if (user) {
      try {
        const response = await createPay(
          checkoutPayFactory({
            topUpId,
            checkoutId,
            successUrl,
            cancelUrl,
            userIp,
            metadata: {
              user_ip: userIp,
              base_url: env.REACT_APP_BASE_APP_URL || '',
            },
          }),
        ).unwrap();

        if (response.status_code === CheckoutStatusCodeEnum.OK) {
          if (response.next_url) {
            // Navigate to the checkout page.
            window.location.href = response.next_url;
            return;
          }

          if (!response.needs_topup) {
            navigate(`/payment/success/${response?.checkout_id}`);
            return;
          }
        } else {
          setErrorCode(updatePayExtractErrors(response.status_code));
        }
      } catch (error) {
        setErrorCode(updatePayExtractErrors(error));
      }
    } else {
      navigate(
        `${URL_CHECKOUT_AUTHENTICATION}?redirect=${window.location.pathname}`,
      );
    }
  };

  const handleTierChange = useCallback(
    (tier: { topUpId: string; points: number }) => {
      setSelectedTier(tier);
    },
    [],
  );

  return {
    handlePayment,
    handleTierChange,
    selectedTier,
    disableButton,
    isLoading,
    isSuccess,
    errorCode,
  };
};
