import { format, isToday, parseISO } from 'date-fns';

import {
  IBuyMovement,
  ITransactionMovement,
} from '../../../../features/movements/Movements.types';

export function formatDate(
  dateStr: string,
  t: (key: string, variable?: Record<string, string>) => string,
): string {
  const inputDate = parseISO(dateStr);

  if (isToday(inputDate)) {
    return t('today');
  }

  const monthAbbr = t(`months.${inputDate.getMonth() + 1}`);
  return format(inputDate, `d '${monthAbbr}' yyyy`);
}

export function groupTransactionsByDate(
  transactions: ITransactionMovement[],
  t: (key: string, variable?: Record<string, string>) => string,
): Map<string, ITransactionMovement[]> {
  return transactions.reduce((acc, transaction) => {
    const formattedDate = formatDate(transaction.date, t);
    const transactionsForDate = acc.get(formattedDate) ?? [];
    acc.set(formattedDate, [...transactionsForDate, transaction]);
    return acc;
  }, new Map<string, ITransactionMovement[]>());
}

export function extractHour(isoString: string): string {
  const date = new Date(isoString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  const formattedHours = String(adjustedHours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${period}`;
}
export function groupBuysByDate(
  buys: IBuyMovement[],
  t: (key: string, variable?: Record<string, string>) => string,
): Map<string, IBuyMovement[]> {
  return buys.reduce((acc, transaction) => {
    const formattedDate = formatDate(transaction.date, t);
    const buysForDate = acc.get(formattedDate) ?? [];
    acc.set(formattedDate, [...buysForDate, transaction]);
    return acc;
  }, new Map<string, IBuyMovement[]>());
}
