import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import {
  CreateCookieResponse,
  GetBenefitsResponse,
  GetDynamicLinkResponse,
  GetReferralCodeResponse,
  GetSummaryResponse,
} from './Referrals.types';

export const ReferralsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReferralCode: builder.query<GetReferralCodeResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_REFERRALS}referrals/code`,
        method: 'GET',
      }),
      providesTags: [RTKTags.Referrals],
    }),
    createCookie: builder.query<CreateCookieResponse, string>({
      query: (referralCode) => ({
        url: `${Env.REACT_APP_BASE_API_URL_REFERRALS}referrals/cookie`,
        method: 'POST',
        body: {
          referral_code: referralCode,
        },
      }),
      providesTags: [RTKTags.Referrals],
    }),
    getBenefits: builder.query<GetBenefitsResponse, string>({
      query: (referralCode) => ({
        url: `${Env.REACT_APP_BASE_API_URL_REFERRALS}referrals/benefits`,
        method: 'GET',
        params: {
          ref: referralCode,
        },
      }),
      providesTags: [RTKTags.Referrals],
    }),
    getSummary: builder.query<GetSummaryResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_REFERRALS}referrals/summary`,
        method: 'GET',
      }),
      providesTags: [RTKTags.Referrals],
    }),
    getDynamicLink: builder.query<GetDynamicLinkResponse, string>({
      query: (referralCode) => ({
        url: `${Env.REACT_APP_BASE_API_URL_REFERRALS}referrals/dynamic-link`,
        method: 'GET',
        params: {
          ref: referralCode,
        },
      }),
      providesTags: [RTKTags.Referrals],
    }),
  }),
});

export const {
  useGetReferralCodeQuery,
  useCreateCookieQuery,
  useGetSummaryQuery,
  useGetBenefitsQuery,
  useGetDynamicLinkQuery,
} = ReferralsApi;
