import { styled } from '@mui/material';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const StyledNotificationImage = styled('img')`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;
export const buttonTriviaStyled = {
  marginTop: SpaceSizes.smPlus,
};
