import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';

export const CARD_HEIGHT_CAROUSEL = 390;
export const CARD_MOBILE_HEIGHT_CAROUSEL = 230;
export const CARD_HEIGHT = 390;
export const CARD_WIDTH = 240;
export const CARD_MOBILE_WIDTH = 140;
export const CARD_MOBILE_HEIGHT = 230;
export const CAROUSEL_BUTTON_HEIGHT = 50;
export const CAROUSEL_MOBILE_BUTTON_HEIGHT = 25;
export const BUTTON_Y_POSITION = '42%';

export const swapHorizIconStyle = { fontSize: { xs: '25px', sm: '40px' } };
export const NEW_TAG_FONT_SIZE = {
  xs: 9,
  sm: 12,
  md: 13,
};

export const NEW_TAG_TOP_DISTANCE = {
  default: {
    xs: SpaceSizes.sm,
    sm: SpaceSizes.smPlus,
    md: SpaceSizes.md,
  },
  isOpen: {
    xs: SpaceSizes.lgPlus,
    sm: SpaceSizes.xl,
  },
};
export const stackVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.25 } },
  exit: { opacity: 0, transition: { duration: 0.25 } },
};

export const SHADOW_EFFECT_BOTTOM = {
  height: '45%',
  padding: SpaceSizes.sm,
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 2,
  background: 'linear-gradient(0deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 92%)',
  borderRadius: '9%',
  opacity: 0.7,
};

export const CarouselStyle = (isSmallestMobile: boolean) => {
  return {
    '.MuiIconButton-root > .MuiSvgIcon-root': {
      fontSize: isSmallestMobile ? typography.cH5.fontSize : '1.5rem',
    },
    height: isSmallestMobile
      ? CARD_MOBILE_HEIGHT_CAROUSEL
      : CARD_HEIGHT_CAROUSEL,
    width: isSmallestMobile ? CARD_MOBILE_WIDTH : CARD_WIDTH,
    borderRadius: 6,
    cursor: 'pointer',
    position: 'relative',
  };
};
