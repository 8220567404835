import Env from '../../../env/env';
import { handlePaginatedResponse } from '../../../utils/handlePaginatedResponse';
import { api, RTKTags } from '../../api/Api';
import { BookmarkItem } from '../responses/BookmarkItem';
import { SearchRequest, SearchResponse } from '../responses/SearchResponse';
import { validateBookmark } from './Bookmark.utils';

export const bookmarkedFilterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchBookmarked: builder.query<SearchResponse, SearchRequest>({
      query: ({ pagination }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_SEARCH}search/bookmarked`,
        method: 'GET',
        params: {
          offset: pagination.offset,
          limit: pagination.limit,
        },
      }),
      providesTags: () => [RTKTags.Bookmarked],
      merge: (currentCache, newItems) =>
        handlePaginatedResponse(currentCache, newItems, validateBookmark),
      serializeQueryArgs: () => [],
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    }),

    getBookmarks: builder.query<BookmarkItem[], void>({
      query: () => `${Env.REACT_APP_BASE_API_URL_BOOKMARKS}bookmarks/`,
      providesTags: [RTKTags.Favs],
      extraOptions: { maxRetries: 2 },
    }),

    addBookmark: builder.mutation<BookmarkItem, string>({
      query: (bookmarkId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_BOOKMARKS}bookmarks/add/${bookmarkId}`,
        method: 'POST',
      }),
      invalidatesTags: [RTKTags.Favs],
    }),

    deleteBookmark: builder.mutation<BookmarkItem, string>({
      query: (bookmarkId) => ({
        url: `${Env.REACT_APP_BASE_API_URL_BOOKMARKS}bookmarks/remove/${bookmarkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.Favs],
    }),
  }),
});

export const {
  useSearchBookmarkedQuery,
  useGetBookmarksQuery,
  useAddBookmarkMutation,
  useDeleteBookmarkMutation,
} = bookmarkedFilterApi;
