import { Stack, Typography } from '@mui/material';
import React from 'react';

import { useTranslationByNamespaces } from '../../../../hooks/shared/useTranslationByNamespaces';
import { MovementContentItemTeams } from '../../items/MovementContentItemTeams';
import { formatDate } from '../../utils/Movement.utils';
import { EMovementType } from './BuyMovementsContent.enums';
import { IBuyMovementMessage } from './BuyMovementsMessage.types';

export const BuyMovementsMessage = ({
  teamsData,
  matchDate,
  type,
}: IBuyMovementMessage) => {
  const { t } = useTranslationByNamespaces('pages.movements');

  if (type === EMovementType.GOAL && teamsData) {
    return (
      <MovementContentItemTeams
        awardedPosition={teamsData.awarded_position}
        awayCode={teamsData.away_code}
        awayScore={teamsData.away_score}
        homeCode={teamsData.home_code}
        homeScore={teamsData.home_score}
      />
    );
  }

  if (matchDate) {
    return (
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography color="text.secondary" fontWeight={400} variant="cH6">
          {t('card.date')}: {formatDate(matchDate, t)}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      justifyContent="space-between"
      width="100%"
    >
      <Typography color="text.primary" fontWeight={700} variant="cH5">
        {t('card.golpointsAdquisicion')}
      </Typography>
    </Stack>
  );
};
