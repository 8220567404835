import { useMediaQuery } from '@mui/material';

export default function useLaptopMediaQuery() {
  // Check if endpoint is a laptop
  const isLaptop = useMediaQuery(
    `(min-width: ${1370}px) and (max-width: ${1550}px) and (min-height: ${600}px)`,
  );

  return isLaptop;
}
