import { useGrowthBook } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { selectIsUserLoggedIn } from '../../../features/session/Session.selector';
import { useGetLiveMatchQuery } from '../../../features/tournaments/Tournaments.api';
import { LIVE_LANDING } from '../../../utils/growthbookFeatures';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { useAppSelector } from '../../hooks';

export const RedirectManager = () => {
  const { leagueId } = useParams();
  const {
    data: liveMatchItems,
    isSuccess,
    isError,
  } = useGetLiveMatchQuery(leagueId || 'laliga');
  const growthbook = useGrowthBook();
  const navigate = useNavigate();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  useEffect(() => {
    const cookies = new Cookies();

    if (
      !cookies.get('is_registered') &&
      !isLoggedUser &&
      growthbook?.isOn(LIVE_LANDING)
    ) {
      navigate(growthbook.getFeatureValue(LIVE_LANDING, '/matches'));
      return;
    }

    const liveMatch = liveMatchItems && liveMatchItems[0];
    if (isError) navigate('/matches');

    if (isSuccess) {
      if (liveMatch) {
        navigate(`/matches/${liveMatch.id}`);
      } else {
        navigate('/matches');
      }
    }
  }, [isError, isSuccess, liveMatchItems, navigate, growthbook, isLoggedUser]);

  return <PageContainer id="loadingPage" maxWidth="sm" loading />;
};
