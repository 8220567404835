export const TradeTypeConstants = {
  OFFER: 'offer' as const,
  REQUEST: 'request' as const,
} as const;

export type TradeType =
  (typeof TradeTypeConstants)[keyof typeof TradeTypeConstants];

interface TradeTypeIndicatorProps {
  type: TradeType;
  size?: 'small' | 'medium' | 'large';
}

export type { TradeTypeIndicatorProps };
