import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { AlertSeverity } from '../AlertCard/AlertSeverity';

const TriviaStartedNoOwner = () => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.started');
  return (
    <Stack
      alignItems="left"
      flexDirection="row"
      gap={SpaceSizes.sm}
      padding={SpaceSizes.sm}
      width="100%"
    >
      <InfoIcon color={AlertSeverity.INFO} />
      <Typography align="left">{t('triviaStartedNotOwner')}</Typography>
    </Stack>
  );
};

export default TriviaStartedNoOwner;
