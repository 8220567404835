export const CONTAINER_TRADE_WIDTH = {
  xs: '60%',
  sm: '70%',
  xl: '60%',
};

export const TRADE_ICON_SIZE = {
  xs: 38,
  sm: 42,
  md: 46,
  lg: 50,
  xl: 54,
};
