import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getStorage } from '../../utils/storage';

export interface BrandState {
  currentBrand: string | undefined;
}

const initialState = {
  currentBrand: undefined,
} as BrandState;

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    setBrand: (state, action: PayloadAction<string>) => {
      state.currentBrand = action.payload;
      getStorage()?.setItem('brand', action.payload);
    },
  },
});
export const { setBrand } = brandSlice.actions;

export default brandSlice.reducer;
