import { useGrowthBook } from '@growthbook/growthbook-react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  RadioGroup,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isNotEmpty } from 'ramda-adjunct';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../env/env';
import theme from '../../../theme';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatGP } from '../../../utils/formatNumbers';
import { TAG_EVENT } from '../../../utils/tagger';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import { OptionTier } from '../../components/OptionTier/OptionTier';
import { PageError } from '../../components/PageError/PageError';
import { PaymentButton } from '../../components/PaymentButton/PaymentButton';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { useCheckoutPay } from '../../hooks/useCheckoutPay';
import { TiersSkeleton } from './Tiers.skeleton';
import { useFetchData } from './useFetchData';

const golpointsImg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/golpoints.png`;
const successUrl = `${env.REACT_APP_BASE_APP_URL}payment/success/{CHECKOUT_ID}`;
const cancelUrl = `${env.REACT_APP_BASE_APP_URL}tiers`;

const Tiers = () => {
  const { t } = useTranslationByNamespaces('pages.tiers');
  const navigate = useNavigate();

  const growthbook = useGrowthBook();
  const webPaymentsEnabled = growthbook?.isOn('web_payments_enabled');

  const {
    handlePayment,
    handleTierChange,
    disableButton,
    isLoading: createPayIsLoading,
    isSuccess: createPayIsSuccess,
    errorCode,
    selectedTier,
  } = useCheckoutPay({ successUrl, cancelUrl });
  const { isLoading, isError, isUninitialized, data } = useFetchData();
  const { golpointsResponse, tiersResponse } = data;

  const onBackButtonClick = useCallback(() => {
    navigate('/marketplace');
  }, [navigate]);

  if (isError) return <PageError errorCode={t('errors.GENERIC_ERROR')} />;
  if (isLoading || isUninitialized) {
    return <TiersSkeleton />;
  }

  return (
    <PageContainer
      id="tiersPage"
      maxWidth="sm"
      title={t('pageTitle')}
      trackCode={TAG_EVENT.CONTENT_GROUP.TIERS}
      backButton
      onBackButtonClick={onBackButtonClick}
    >
      <Stack
        alignItems="center"
        gap={SpaceSizes.lg}
        justifyContent="center"
        width="100%"
      >
        <Stack
          alignItems="center"
          borderRadius={`${SpaceSizes.smPlus}px`}
          flexDirection="column"
          gap={SpaceSizes.xs}
          sx={{
            backgroundColor: 'background.paper',
            width: '100%',
            padding: SpaceSizes.smPlus,
          }}
        >
          <Typography fontWeight="500" variant="cH5">
            {t('myGolpoints')}
          </Typography>
          <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.xs}>
            {golpointsResponse && (
              <Typography variant="cH1">
                {formatGP(golpointsResponse?.balance)}
              </Typography>
            )}
            <ImageFallback
              fallback={golpointsImg}
              height={SpaceSizes.lg}
              src={golpointsImg}
              width={SpaceSizes.lg}
            />
          </Stack>
        </Stack>
        {/* Si esta habilitada la compra via web */}

        {webPaymentsEnabled ? (
          <>
            <Stack gap={SpaceSizes.sm} width="100%">
              {isNotEmpty(errorCode) && (
                <AlertCard
                  content={t(`errors.${errorCode}`)}
                  id="buyTicketError"
                  show={isNotEmpty(errorCode)}
                />
              )}
              <FormControl sx={{ width: '100%' }}>
                <RadioGroup sx={{ alignContent: 'center' }}>
                  <Stack
                    borderRadius={`${SpaceSizes.smPlus}px`}
                    gap={{ xs: SpaceSizes.sm, sm: SpaceSizes.md }}
                    width="100%"
                  >
                    {tiersResponse?.map((element) => (
                      <OptionTier
                        key={element.tier_id}
                        handleTierChange={handleTierChange}
                        selectedTier={selectedTier}
                        tier={element}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack alignItems="center" width="100%">
              <PaymentButton
                buttonText={t('buy')}
                dataTestId="receive-button-open"
                disableButton={disableButton}
                handleBuy={handlePayment}
                showPrice={false}
                sx={{
                  width: {
                    xs: '95%',
                    md: '40%',
                  },
                }}
              />
              <Backdrop
                open={createPayIsLoading || createPayIsSuccess}
                sx={{
                  zIndex: theme.zIndex.drawer + 1,
                  width: '100%',
                  height: '100%',
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Stack>
          </>
        ) : (
          <ExternalContent
            filter={{ key: 'content_id', value: 'web_payments_disabled' }}
            type="contents"
          />
        )}
      </Stack>
    </PageContainer>
  );
};

export default Tiers;
