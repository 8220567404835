export const COLLECTION_CARD_SIZE = { width: 240, height: 450 };
export const COLLECTION_CARD_SIZE_MOBILE = { width: 160, height: 320 };

export const SKELETON_WIDTH = {
  xs: 150,
  sm: 160,
  md: 240,
};

export const SKELETON_HEIGHT = {
  xs: 180,
  sm: 230,
  md: 300,
};
