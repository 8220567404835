import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../app/hooks';
import { selectCurrentBrand } from '../features/brand/Brand.selector';
import {
  selectCurrentGol,
  selectPriceWithCurrency,
  selectProductMatch,
  selectProductMatchTournament,
  selectProductPlayer,
  selectTeamNameOfLoser,
} from '../features/products/Product.selectors';
import {
  IProduct,
  Match,
  Player,
  Tournament,
} from '../features/products/Product.types';
import { TAG_EVENT } from './tagger';

interface ItemTrack {
  productId: string;
  tournament?: Tournament;
  price?: string;
  match?: Match;
  player?: Player;
  title: string;
  index?: number;
  brand: string;
  listName: string;
}

export const getItemToTrack = ({
  productId,
  tournament,
  price,
  match,
  player,
  title,
  index,
  listName,
  brand,
}: ItemTrack) => {
  return {
    item_name: title,
    item_id: productId,
    item_brand: brand,
    item_category: player?.name,
    item_category2: `${match?.home.name} vs ${match?.away.name}`,
    item_category3:
      player?.team_id === match?.home.id ? match?.home.name : match?.away.name,
    item_category4: null,
    item_category5: tournament
      ? `${tournament?.season} - Jornada ${match?.week}`
      : null,
    item_list_name: listName,
    index: index || 0,
    price,
  };
};

const mapProductToTrackableItem = (
  product: IProduct,
  index: number,
  brand: string,
  t: (arg: string) => string,
) => {
  const loserTeamName = selectTeamNameOfLoser(product);
  const productEvent = selectCurrentGol(product);
  const player = selectProductPlayer(product);
  const match = selectProductMatch(product);
  const tournament = selectProductMatchTournament(product);
  const price = selectPriceWithCurrency(product);

  const productTitle = `${player?.name} ${t(
    `pages.product.title`,
  )} vs ${loserTeamName} - ${productEvent?.event_minute_in_match}'`;

  return getItemToTrack({
    productId: product.product_id,
    tournament,
    price,
    match,
    player,
    title: productTitle,
    index,
    listName: TAG_EVENT.CONTENT_GROUP.LIST_ITEMS,
    brand,
  });
};

export const useGetItemToTrack = (
  product: IProduct | undefined,
  index: number,
) => {
  const { t } = useTranslation();
  const brand = useAppSelector(selectCurrentBrand)?.id;
  return (
    product && mapProductToTrackableItem(product, index, brand || 'default', t)
  );
};

export const useGetItemsToTrack = (products: IProduct[]) => {
  const { t } = useTranslation();
  const brand = useAppSelector(selectCurrentBrand)?.id;

  return products.map((product, index) => {
    return mapProductToTrackableItem(product, index, brand || 'default', t);
  });
};
