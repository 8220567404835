import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend'; // fallback http load
import { initReactI18next } from 'react-i18next';

// import { getStorage } from '../utils/storage';
import Env from '../env/env';
// import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import version from '../version.json';
// import pjson from '../../package.json';

export const LANGUAGES = ['es', 'en', 'fr', 'pt'];

const isDevelopment = Env.NODE_ENV === 'development';

// const backends = isDevelopment ? [HttpApi] : [LocalStorageBackend, HttpApi];

const backends = [HttpApi];
// const localStorageOptions = {
//   loadPath: `/i18n/{{lng}}.json`,
//   // prefix for stored languages
//   prefix: 'i18next_res_',
//   // expiration
//   expirationTime: 3 * 60 * 60 * 1000, // 3 hours
//   // Version applied to all languages, can be overriden using the option `versions`
//   defaultVersion: pjson.version,
//   // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
//   store: getStorage(),
// };

const httpApiOptions = {
  loadPath: `/i18n/{{lng}}.json`, // http load path for my own fallback
  queryStringParams: { v: version.translationVersion }, // adds the versioning parameter to the end of the url
};

const backendOptions = [httpApiOptions];
// const backendOptions = isDevelopment
//   ? [httpApiOptions]
//   : [localStorageOptions, httpApiOptions];

export const geti18nResolvedLanguage = (): string =>
  i18n.resolvedLanguage || 'es';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGES,
    load: 'languageOnly',
    debug: isDevelopment,
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends,
      backendOptions,
    },
  });

export default i18n;
