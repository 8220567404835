import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { PageContainer } from '../../containers/PageContainer/PageContainer';
import { FormTeams } from './FormTeams/FormTeams';

export const Teams = () => {
  const { t } = useTranslationByNamespaces('pages.profile');
  return (
    <PageContainer
      id="teamsPage"
      maxWidth="sm"
      sx={{ justifyContent: 'flex-start' }}
      title={t('preferencesSection.teams.title')}
      backButton
    >
      <Stack spacing={SpaceSizes.md} width="100%">
        <FormTeams />
      </Stack>
    </PageContainer>
  );
};
