import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import {
  selectPrice,
  selectProductIsAuction,
  selectProductIsDirectSale,
  selectProductIsFinished,
  selectProductIsHolded,
  selectProductIsMuseum,
} from '../../../../features/products/Product.selectors';
import { useProductContext } from '../../../../provider/ProductProvider/ProductProvider';
import BorderRadius from '../../../../theme/foundations/borders/BorderRadius';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { boxShadowMixin } from '../../../../utils/mixins';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import ProductPrice from '../../ProductPrice/ProductPrice';

export const MarketplaceCardHeader = () => {
  const { t } = useTranslationByNamespaces(
    'components.productCard.productState',
  );
  const { t: tAuction } = useTranslationByNamespaces(
    'components.productCard.tags',
  );
  const isSmallestMobile = useSmallMobileMediaQuery();
  const product = useProductContext();
  const productIsHolded = selectProductIsHolded(product);
  const price = selectPrice(product);
  const isFinished = selectProductIsFinished(product);
  const isMuseum = selectProductIsMuseum(product);
  const isDirectSale = selectProductIsDirectSale(product);
  const isAuction = selectProductIsAuction(product);
  const theme = useTheme();

  // check what should be rendered as "price"
  const getPriceValue = () => {
    const conditions = [
      // directSale

      { check: () => isMuseum, value: () => t('museum') },

      {
        check: () => isFinished,
        value: () => t(`${productIsHolded ? 'notAvailable' : 'finished'}`),
      },
    ];

    const condition = conditions.find(({ check }) => check());

    // real price has default
    return condition ? condition.value() : price?.value;
  };

  const priceValue = getPriceValue();

  return (
    <Stack
      boxShadow={boxShadowMixin(theme.palette.secondary.light)}
      sx={{
        width: 'auto',
        borderRadius: `0 ${BorderRadius.md} 0 ${BorderRadius.md}`,
        justifyContent: 'center',
        alignItems: 'center',
        padding: SpaceSizes.sm,
        backgroundColor: isDirectSale
          ? '#c22222'
          : isAuction
          ? 'warning.dark'
          : 'background.paper',
      }}
    >
      {isMuseum ? (
        <Typography variant="cBody1">{priceValue}</Typography>
      ) : (
        <>
          <ProductPrice
            currency={product?.price?.currency}
            showLogo={!isFinished}
            size={isSmallestMobile || isDirectSale ? 'verysmall' : 'medium'}
            value={priceValue}
          />
          {/* color={isDirectSale ? '#cb3a3d' : ''}
 text={isDirectSale ? tAuction('directSale') : ''}
 */}
          {isDirectSale && (
            <Typography variant="cBody1">{tAuction('directSale')}</Typography>
          )}
          {isAuction && (
            <Typography variant="cBody1">{tAuction('auction')}</Typography>
          )}
        </>
      )}
    </Stack>
  );
};
