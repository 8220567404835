import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { URL_CHECKOUT_AUTHENTICATION } from '../../../constants/checkout/checkout';
import { selectIsUserLoggedIn } from '../../../features/session/Session.selector';
import { useAddItemMutation } from '../../../features/shoppingCart/ShoppingCart.api';
import { useAppSelector } from '../../hooks';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import {
  BUTTON_FONT_SIZE_DESKTOP,
  BUTTON_FONT_SIZE_MOBILE,
} from './AddProductWidget.constants';
import { AddProductWidgetProps } from './AddProductWidget.types';

export const AddProductWidget = ({
  productId,
  setErrorCode,
  setShowAlert,
  selectedQuantity,
}: AddProductWidgetProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const isMobile = useMobileMediaQuery();

  const [addItem, { isLoading: shoppingCartIsLoading }] = useAddItemMutation();

  const handleAddItem = async () => {
    if (productId && isLoggedUser) {
      try {
        const { status_code: statusCode } = await addItem({
          product_id: productId,
          quantity: selectedQuantity,
        }).unwrap();

        navigate(`/cart/confirmation/${productId}?statusCode=${statusCode}`);
        return;
      } catch (error) {
        setErrorCode({
          id: 'addProduct',
          exist: true,
        });
        setShowAlert(true);
        return;
      }
    }
    navigate(
      `${URL_CHECKOUT_AUTHENTICATION}?redirect=${window.location.pathname}`,
    );
  };

  return (
    <PaymentButton
      buttonText={t('pages.product.piece.addProduct')}
      dataTestId="addProductWidgetButton"
      disableButton={shoppingCartIsLoading}
      handleBuy={handleAddItem}
      icon={<ShoppingCartOutlinedIcon />}
      id="add_to_cart"
      isLoading={shoppingCartIsLoading}
      showPrice={false}
      size={{
        fontSize: isMobile ? BUTTON_FONT_SIZE_MOBILE : BUTTON_FONT_SIZE_DESKTOP,
      }}
      sx={{
        width: '100%',
        textTransform: 'none',
      }}
      variantButton="outlined"
    />
  );
};
