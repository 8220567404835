import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { GolButton } from '../../GolButton/GolButton.component';
import SelectDateTime from './SelectDateTime';

interface SelectDatesProps {
  setState: {
    setFromDate: (state: Date | null) => void;
    setToDate: (state: Date | null) => void;
  };
  state: { fromDate: Date | null; toDate: Date | null };
  handleSearch: () => void;
}

export const SelectDates = ({
  setState,
  state,
  handleSearch,
}: SelectDatesProps) => {
  const { setFromDate, setToDate } = setState;

  const { fromDate, toDate } = state;
  const { t } = useTranslation();

  return (
    <Stack
      flexDirection="column"
      gap={SpaceSizes.md}
      justifyContent="center"
      p={SpaceSizes.md}
      width="100%"
    >
      <Stack flexDirection="column">
        <Typography variant="body1">
          {t('pages.matches.selectDatesModal.from')}
        </Typography>
        <SelectDateTime date={fromDate} onChange={setFromDate} />
      </Stack>

      <Stack flexDirection="column">
        <Typography variant="body1">
          {t('pages.matches.selectDatesModal.to')}
        </Typography>
        <SelectDateTime date={toDate} onChange={setToDate} />
      </Stack>

      <GolButton
        disabled={!(fromDate && toDate)}
        testId="SearchMatchesButton"
        value={t('pages.matches.selectDatesModal.search')}
        fullWidth
        onClick={() => {
          handleSearch();
        }}
      />
    </Stack>
  );
};
