import { Button, Typography } from '@mui/material';
import React, { MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomButtonProps {
  id?: string;
  nativeId?: string;
  icon: ReactElement;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  textKey: string;
}

const IconTextButton = ({
  nativeId,
  id,
  icon,
  onClick,
  textKey,
}: CustomButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button data-testid={id} endIcon={icon} id={nativeId} onClick={onClick}>
      <Typography color="text.secondary" textTransform="none">
        {t(textKey)}
      </Typography>
    </Button>
  );
};

export default IconTextButton;
