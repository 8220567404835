import { Box, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { SwiperSlide } from 'swiper/react';

import { IProduct } from '../../../features/products/Product.types';
import { useGetRelatedProductsQuery } from '../../../features/products/Products.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { Animation } from '../Animate/Animate';
import { AnimationColor, AnimationEnum } from '../Animate/Animate.constants';
import { ComponentSwiper } from '../ComponentSwiper/ComponentSwiper';
import { componentSwiperSizes } from '../ComponentSwiper/ComponentSwiper.types';
import MarketplaceCard from '../MarketplaceCard/MarketplaceCard';
import Panel from '../Panel/Panel';
import {
  CARD_HEIGHT_DESKTOP,
  CARD_HEIGHT_MOBILE,
  CARD_WIDTH_DESKTOP,
  CARD_WIDTH_MOBILE,
} from '../ProductCard/ProductCard.constants';
import { MAX_ITEMS_TO_SHOW_DESKTOP } from './RelatedProducts.constants';
import { RelatedProductsSkeleton } from './RelatedProducts.skeleton';

const componentSizes = {
  [componentSwiperSizes.sm]: {
    width: CARD_WIDTH_MOBILE,
    height: CARD_HEIGHT_MOBILE,
  },
  [componentSwiperSizes.lg]: {
    width: CARD_WIDTH_DESKTOP,
    height: CARD_HEIGHT_DESKTOP,
  },
};

export const RelatedProducts = ({
  productId,
  canUserClaim,
  title,
}: {
  productId: string | undefined;
  canUserClaim?: boolean;
  title?: React.ReactNode;
}) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  const {
    data: relatedProducts,
    isLoading,
    isSuccess,
  } = useGetRelatedProductsQuery(productId ? { productId } : skipToken);

  const getStyles = useCallback(
    () => ({
      alignItems: 'center',
      marginBottom: isMobile || isTablet ? SpaceSizes.md : SpaceSizes.lg,
    }),
    [isMobile, isTablet],
  );

  const sliderStyles = useCallback(
    (size: string) => ({
      cursor: 'pointer',
      width: componentSizes[size].width,
      height: componentSizes[size].height,
    }),
    [],
  );

  if (isSuccess && relatedProducts?.related_products?.length === 0) return null;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="inherit"
      justifyContent="center"
      sx={{
        ...getStyles(),
      }}
    >
      {isLoading && <RelatedProductsSkeleton />}
      {isSuccess ? (
        <Panel
          sx={{
            backgroundColor: null,
            justifyContent: 'center',
            p: isMobile ? SpaceSizes.none : SpaceSizes.lg,
          }}
        >
          <Stack
            justifyContent="center"
            spacing={SpaceSizes.md}
            width="inherit"
          >
            {title || (
              <Typography
                fontSize={
                  isMobile ? typography.cH4.fontSize : typography.cH3.fontSize
                }
                textAlign="center"
              >
                {t('components.relatedProducts.title')}
              </Typography>
            )}
            <ComponentSwiper slidesPerView="auto" spaceBetween={10}>
              {relatedProducts?.related_products?.length > 0 &&
                relatedProducts.related_products.map(
                  (relatedProduct: IProduct, index: number) => {
                    if (index < MAX_ITEMS_TO_SHOW_DESKTOP) {
                      return (
                        <SwiperSlide
                          key={relatedProduct.product_id}
                          id={`related_product_${index}`}
                          style={sliderStyles(
                            isMobile
                              ? componentSwiperSizes.sm
                              : componentSwiperSizes.lg,
                          )}
                        >
                          <Animation
                            key={`${relatedProduct.product_id}_key_animation`}
                            color={AnimationColor.PURPLE}
                            timeProps={{ delay: index / 10, duration: 0.15 }}
                            transformProps={{ offsetY: '-5%' }}
                            type={AnimationEnum.Offset}
                          >
                            <MarketplaceCard
                              key={relatedProduct.product_id}
                              canUserClaim={canUserClaim}
                              index={index}
                              product={relatedProduct}
                            />
                          </Animation>
                        </SwiperSlide>
                      );
                    }
                    return null;
                  },
                )}
            </ComponentSwiper>
          </Stack>
        </Panel>
      ) : null}
    </Box>
  );
};
