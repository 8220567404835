export const skeletonStyle = (isSmallestMobile: boolean) => {
  return {
    marginBottom: isSmallestMobile ? '60px' : '80px',
    height: '100%',
  };
};

export const HEIGHT_GRID_DESKTOP = '280px';
export const HEIGHT_GRID_MOBILE = '200px';
export const WIDTH_GRID_DESKTOP = '72%';
export const WIDTH_GRID_TABLET = '100%';
