import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ShoppingCartState } from './ShoppingCart.slice';

export const selectShoppingCart = (state: RootState): ShoppingCartState =>
  state.shoppingCart;

export const selectCart = createSelector(selectShoppingCart, (shoppingCart) => {
  return shoppingCart.cart;
});

export const selectTotalPrice = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    return shoppingCart.totalPrice;
  },
);

export const selectOutOfStock = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    return shoppingCart.cart.some(
      (item) => item.status === 'active' && item.available_stock === 0,
    );
  },
);

export const selectModifiedStock = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    return shoppingCart.cart.some(
      (item) =>
        item.status === 'active' &&
        item.available_stock < item.selected_quantity,
    );
  },
);

export const selectCartActive = createSelector(
  selectShoppingCart,
  (shoppingCart) => {
    return shoppingCart.cart.filter((item) => item.status === 'active');
  },
);
