import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query';

import Env from '../env/env';
import getJWTToken from './getJWTToken';

export const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: '', // This will be overriden by any api inject.

    prepareHeaders: (headers, api) => {
      const token = getJWTToken();

      const tokenNeeded =
        api.endpoint !== 'getExternalContentById' &&
        api.endpoint !== 'getTriviaInformation' &&
        api.endpoint !== 'getBenefitsInformation';

      if (token && tokenNeeded) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      // TODO: Implement custom header splitted method.
      if (
        api.endpoint === 'getCountries' ||
        api.endpoint === 'getStatesByCountry'
      ) {
        headers.set(
          'x-cscapi-key',
          Env.REACT_APP_COUNTRY_STATE_CITY_API_KEY as string,
        );
        headers.set('Cache-Control', 'no-cache');
      }

      return headers;
    },
  }),
  { maxRetries: 0 },
);
