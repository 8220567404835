import React, { MouseEventHandler, useMemo } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';

import Env from '../../../env/env';
import SignupButton from '../SignupButton/SignupButton';
import Spinner from '../Spinner';
import AppleIcon from './AppleIcon';
import { generateNonceToken } from './nonceCreator';
import useAppleSignIn from './useAppleSignIn';

interface AppleSignInButtonProps {
  isSignup?: boolean;
}

const CLIENT_ID = 'com.gol-ball.auth.client';

const AppleSignInButton = ({ isSignup }: AppleSignInButtonProps) => {
  const { onSuccessAppleLogin, isLoading: isLoadingApple } = useAppleSignIn();

  const { t } = useTranslation();

  const nonce = useMemo(() => {
    return generateNonceToken();
  }, []);

  if (isLoadingApple) return <Spinner />;

  const buttonText = isSignup
    ? t('pages.login.signUpWithApple')
    : t('pages.login.signInWithApple');

  return (
    <AppleSignin
      authOptions={{
        clientId: CLIENT_ID,
        scope: 'email name',
        redirectURI: isSignup
          ? `${Env.REACT_APP_BASE_APP_URL}signup`
          : `${Env.REACT_APP_BASE_APP_URL}login`,
        state: 'state',
        nonce,
        usePopup: true,
      }}
      buttonExtraChildren={buttonText}
      className="apple-auth-btn"
      render={(props) => {
        // eslint-disable-next-line react/prop-types
        const { onClick } = props;
        return (
          <SignupButton
            buttonText={buttonText}
            icon={<AppleIcon />}
            id="apple_signup"
            onClick={onClick as MouseEventHandler<HTMLButtonElement>}
          />
        );
      }}
      skipScript={false}
      uiType="light"
      noDefaultStyle
      onSuccess={(response) => onSuccessAppleLogin(response, nonce)}
    />
  );
};

export default AppleSignInButton;
