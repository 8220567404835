import { TTransactionStatus } from '../../../features/movements/Movements.types';

export const STATUS_BACKGROUND_COLORS_MOVEMENTS: Record<
  TTransactionStatus,
  string
> = {
  sent: 'primary.dark',
  received: 'warning.dark',
  pending: 'text.secondary',
  expired: 'error.dark',
};

export const STATUS_COLORS_MOVEMENTS: Record<TTransactionStatus, string> = {
  sent: '#F5F7FF',
  received: 'primary.dark',
  pending: 'text.primary',
  expired: 'error.50',
};

export const MOVEMENT_TABS = ['buys', 'transfers'];
