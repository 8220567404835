import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { IMovementContentItemTeams } from './MovementContentItem.types';

export const MovementContentItemTeams = ({
  awardedPosition,
  homeCode,
  homeScore,
  awayCode,
  awayScore,
}: IMovementContentItemTeams) => {
  const homeTypographyProps = useMemo(() => {
    const color =
      homeScore === undefined
        ? 'text.primary'
        : awardedPosition === 'home' || awardedPosition === 'both'
        ? 'text.primary'
        : 'text.secondary';
    const fontWeight = homeScore === undefined ? 800 : 400;

    return { color, fontWeight };
  }, [awardedPosition, homeScore]);

  const awayTypographyProps = useMemo(() => {
    const color =
      awayScore === undefined
        ? 'text.primary'
        : awardedPosition === 'away' || awardedPosition === 'both'
        ? 'text.primary'
        : 'text.secondary';
    const fontWeight = awayScore === undefined ? 800 : 400;

    return { color, fontWeight };
  }, [awardedPosition, awayScore]);

  return (
    <Stack alignItems="center" direction="row" gap={SpaceSizes.xs}>
      <Typography variant="cH6" {...homeTypographyProps}>
        {homeCode} {homeScore} {homeScore === undefined && 'vs'}
      </Typography>
      <Typography variant="cH6" {...awayTypographyProps}>
        {awayScore} {awayCode}
      </Typography>
    </Stack>
  );
};
