import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { shrinkWord } from '../TradeItem/TradeItemDetail.utils';
import { UserSummaryProps } from './UserSummary.interfaces';

const anonymousUserUrl = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/anonymous-user.png`;

export const UserSummary = ({
  userId,
  userName,
  profilePic,
  country,
  isLoading,
  navigable,
}: UserSummaryProps) => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const navigate = useNavigate();
  const isSmallestMobile = useSmallMobileMediaQuery();
  const wordAmount = useMemo(() => {
    if (isSmallestMobile) {
      return 10;
    }
    return 25;
  }, [isSmallestMobile]);

  const navigateToUserProfile = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      navigate(`/user/${userId}`);
    },
    [navigate, userId],
  );

  if (isLoading) {
    return (
      <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
        <Skeleton
          height={SpaceSizes.xl}
          variant="circular"
          width={SpaceSizes.xl}
        />
        <Skeleton
          height={SpaceSizes.lgPlus}
          sx={{ width: { xs: SpaceSizes.xlPlus, md: SpaceSizes.xxl2 } }}
          variant="text"
        />
        <Skeleton
          height={SpaceSizes.mdPlus}
          variant="rectangular"
          width={SpaceSizes.lgPlus}
        />
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
      <IconButton disabled={!navigable} onClick={navigateToUserProfile}>
        <ImageFallback
          alt={userName}
          fallback={profilePic}
          height={SpaceSizes.xl}
          src={profilePic || anonymousUserUrl}
          style={{ borderRadius: '100%' }}
          width={SpaceSizes.xl}
        />
      </IconButton>
      <Typography fontSize="large">
        {(userName && shrinkWord(userName, wordAmount)) || t('anonymous')}
      </Typography>
      {country && (
        <ImageFallback
          fallback={country}
          height={SpaceSizes.lg}
          src={country}
          width={SpaceSizes.lg}
        />
      )}
    </Stack>
  );
};
