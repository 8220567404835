import React from 'react';

import { Filters } from '../Filters/Filters';
import { SearchFilterSectionProps } from './SearchFilterSection.types';

export const SearchFilterSection = ({
  filters,
  appliedFilters,
  options,
  addFilter,
  removeFilter,
}: SearchFilterSectionProps) => {
  return (
    <Filters
      addFilter={addFilter}
      appliedFilters={appliedFilters}
      filters={filters}
      options={options}
      removeFilter={removeFilter}
    />
  );
};
