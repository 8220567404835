import { Chip, Stack } from '@mui/material';
import React, { useCallback } from 'react';

import { FILTER_EXCLUDED_KEYS } from '../../../../features/searchfilter/Filters.constants';
import { AppliedFilter } from '../../../../features/searchfilter/responses/SearchResponse';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { IAppliedFiltersProps } from './AppliedFilters.types';

const FilterApplied = ({
  filter,
  removeFilter,
  ...props
}: {
  removeFilter: (filterId: string) => void;
  filter: AppliedFilter;
}) => {
  const handleRemoval = () => {
    removeFilter(filter.id);
  };

  return (
    <Chip
      {...props}
      data-testid={`${filter.id}-close`}
      label={`${filter.valueLabel}`}
      onDelete={handleRemoval}
    />
  );
};

export const AppliedFilters = ({
  appliedFilters,
  removeFilter,
}: IAppliedFiltersProps) => {
  const renderFiltersInSingleRow = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={SpaceSizes.md}>
        {appliedFilters.map(
          (appliedFilter) =>
            !FILTER_EXCLUDED_KEYS.includes(appliedFilter.id) && (
              <FilterApplied
                key={`${appliedFilter.id}-${appliedFilter.value}`}
                filter={appliedFilter}
                removeFilter={removeFilter}
              />
            ),
        )}
      </Stack>
    );
  }, [appliedFilters, removeFilter]);

  return (
    <Stack py={SpaceSizes.sm}>
      {appliedFilters && renderFiltersInSingleRow()}
    </Stack>
  );
};
