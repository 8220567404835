import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import { ITransactionMovement } from '../../../../../features/movements/Movements.types';
import { useGetPolygonscanLinkQuery } from '../../../../../features/products/Products.api';
import { selectIsUserLoggedIn } from '../../../../../features/session/Session.selector';
import SpaceSizes from '../../../../../theme/foundations/spacing/SpaceSizes';
import { Animation } from '../../../../components/Animate/Animate';
import { AnimationEnum } from '../../../../components/Animate/Animate.constants';
import { Link } from '../../../../components/Link/Link';
import { useAppSelector } from '../../../../hooks';
import { useTranslationByNamespaces } from '../../../../hooks/shared/useTranslationByNamespaces';
import { MovementContentItemTeams } from '../../items/MovementContentItemTeams';
import {
  STATUS_BACKGROUND_COLORS_MOVEMENTS,
  STATUS_COLORS_MOVEMENTS,
} from '../../Movements.constants';
import { extractHour } from '../../utils/Movement.utils';

export const TransactionsMovementsContent = (
  transaction: ITransactionMovement,
) => {
  const {
    status,
    date,
    from,
    to,
    product: {
      id,
      scorer_last_name: scorer,
      piece_number: pieceNumber,
      goal_minute: goalMinute,
      event: {
        awarded_position: awardedPosition,
        home_code: homeCode,
        home_score: homeScore,
        away_code: awayCode,
        away_score: awayScore,
      },
    },
  } = transaction;
  const { t } = useTranslationByNamespaces('pages.movements.card');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);

  const polygonScanLinkQuery = useGetPolygonscanLinkQuery(
    isLoggedUser
      ? {
          product_id: id,
          piece_number: pieceNumber,
        }
      : skipToken,
  );
  const transactionAddress = polygonScanLinkQuery.data?.[0];

  return (
    <Stack
      key={id}
      gap={SpaceSizes.sm}
      sx={{ cursor: 'pointer' }}
      width="100%"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <Stack width="100%">
        <Stack direction="row" gap={SpaceSizes.sm} padding={SpaceSizes.sm}>
          <Stack width="100%">
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  color="text.primary"
                  component="h4"
                  fontWeight={600}
                  variant="cBody1"
                >
                  {t(`transactionStatus.${status}`)}
                </Typography>
                <Stack flexDirection="row" gap={SpaceSizes.md} height="100%">
                  <Chip
                    label={
                      <Typography
                        component="h6"
                        fontWeight={400}
                        variant="cBody2"
                      >
                        {t(`transactionChip.${status}`)}
                      </Typography>
                    }
                    size="medium"
                    sx={{
                      color: STATUS_COLORS_MOVEMENTS[status],
                      backgroundColor:
                        STATUS_BACKGROUND_COLORS_MOVEMENTS[status],
                      span: { paddingX: SpaceSizes.xs },
                      height: SpaceSizes.mdPlus,
                      width: SpaceSizes.xl,
                    }}
                  />
                  {isExpanded ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Typography color="text.primary" component="h5" variant="cH5">
              {scorer} {goalMinute}
            </Typography>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <MovementContentItemTeams
                awardedPosition={awardedPosition}
                awayCode={awayCode}
                awayScore={awayScore}
                homeCode={homeCode}
                homeScore={homeScore}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <AnimatePresence>
        {isExpanded && (
          <Animation
            key={`${id}-an`}
            timeProps={{ delay: 0, duration: 0.25, ease: 'easeInOut' }}
            transformProps={{ offsetY: '-5%' }}
            type={AnimationEnum.Offset}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              padding={SpaceSizes.md}
              sx={{
                borderRadius: `0 0 ${SpaceSizes.sm}px ${SpaceSizes.sm}px `,
                backgroundColor: 'background.paper',
              }}
              width="100%"
            >
              <Stack direction="column" gap={SpaceSizes.xxs}>
                <Typography
                  color="text.primary"
                  component="p"
                  fontWeight={400}
                  variant="cBody1"
                >
                  {t('transferTime')}: {extractHour(date)}
                </Typography>
                {transactionAddress && (
                  <Link to={transactionAddress} animatedHover>
                    <Typography
                      color="info.main"
                      component="h6"
                      variant="cBody1"
                    >
                      {t('seeTransaction')}
                    </Typography>
                  </Link>
                )}
                <Typography
                  color="text.secondary"
                  component="h6"
                  variant="cBody1"
                >
                  {status !== 'pending' &&
                    t(status === 'received' ? 'origin' : 'target')}
                  : {from || to}
                </Typography>
              </Stack>
            </Stack>
          </Animation>
        )}
      </AnimatePresence>
    </Stack>
  );
};
