import { Box, Stack, styled, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { Animation } from '../Animate/Animate';
import { AnimationEnum } from '../Animate/Animate.constants';

const HiddenInput = styled('span')`
  opacity: 0;
  filter: alpha(opacity=0);
  height: 0;
`;

interface Props {
  code: string;
  isError: boolean;
  isRefocused: boolean;
  isBlocked: boolean;
  onChange: (arg0: string) => void;
}

export const CodeInput = ({
  code,
  isError,
  isBlocked,
  isRefocused,
  onChange,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focus, setFocus] = useState<Record<number, boolean>>({ 1: false });
  const isMobile = useMobileMediaQuery();
  useEffect(() => {
    if (!isBlocked || isRefocused) {
      inputRef.current?.focus();
    } else if (isBlocked) {
      setFocus({ 1: false });
    }
  }, [isBlocked, isRefocused]);

  const theme = useTheme();

  return (
    <Animation
      key="codeinput-fill-sms"
      timeProps={{ delay: 0, duration: 0.85 }}
      transformProps={{ offsetY: '5%' }}
      type={AnimationEnum.Offset}
    >
      <Stack onClick={() => inputRef.current?.focus()}>
        <HiddenInput>
          <input
            ref={inputRef}
            autoComplete="one-time-code"
            disabled={isBlocked}
            type="number"
            value={code || ''}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
        </HiddenInput>
        <Stack
          alignItems="center"
          direction="row"
          display="flex"
          spacing={isMobile ? SpaceSizes.xxs : SpaceSizes.sm}
          sx={{
            height: SpaceSizes.lgPlus,
          }}
        >
          <AnimatePresence>
            {Array.from(Array(6).keys()).map((currentChar, index: number) => {
              return (
                <span key={`${currentChar}-key-codeinput`}>
                  <Animation
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      cursor: isBlocked ? 'not-allowed' : 'text',
                      zIndex: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: SpaceSizes.md,
                    }}
                    timeProps={{ delay: 1 + index / 6, duration: 0.25 }}
                    type={AnimationEnum.Offset}
                  >
                    <Animation
                      instantBorderDelay={2500}
                      stopped={
                        Boolean(code[index]) ||
                        index > 0 ||
                        focus[index] ||
                        isBlocked
                      }
                      sx={{ zIndex: 0 }}
                      timeProps={{ delay: 1 + index / 6, duration: 0.25 }}
                      type={AnimationEnum.Pulse}
                      instantBorder
                    >
                      <Box
                        sx={{
                          '&::before': {
                            content: '" "',
                            position: 'absolute',
                            backgroundColor: code[index]
                              ? theme.palette.primary.dark
                              : theme.palette.primary.light,
                            width: isBlocked ? 0 : '50%',
                            height: '1px',
                            bottom: '25%',
                            marginTop: 0,
                          },
                          backgroundColor: isBlocked
                            ? theme.palette.secondary.light
                            : theme.palette.primary.light,
                          color: theme.palette.text.primary,
                          textAlign: 'center',
                          border: `1px solid ${
                            isBlocked
                              ? theme.palette.secondary.light
                              : isError
                              ? theme.palette.error.main
                              : theme.palette.primary.contrastText
                          }`,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height:
                            code.charAt(currentChar) ||
                            focus[index] ||
                            isBlocked
                              ? SpaceSizes.lg
                              : SpaceSizes.mdPlus,
                          width:
                            code.charAt(currentChar) ||
                            focus[index] ||
                            isBlocked
                              ? SpaceSizes.lg
                              : SpaceSizes.mdPlus,
                          transition:
                            'width .1s ease-out; height .1s ease-out; border .3s ease-in-out',
                          borderRadius:
                            code.charAt(currentChar) || focus[index] ? 2.5 : 2,
                          fontWeight: 'bold',
                        }}
                      >
                        {isBlocked ? '' : code.charAt(currentChar)}
                      </Box>
                    </Animation>
                  </Animation>
                </span>
              );
            })}
          </AnimatePresence>
        </Stack>
      </Stack>
    </Animation>
  );
};
