import { useGrowthBook } from '@growthbook/growthbook-react';
import { Stack, Typography } from '@mui/material';
import { isNotNumber } from 'ramda-adjunct';
import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  selectIsHomePage,
  selectIsMarketplacePage,
  selectIsMatchesPage,
} from '../../../features/router/router';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatGP } from '../../../utils/formatNumbers';
import { SHOW_PRICES_IN_EUROS } from '../../../utils/growthbookFeatures';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import {
  PRODUCT_PRICE_DEFAULT_CURRENCY,
  PRODUCT_PRICE_DEFAULT_LOGO,
  ProductPriceSize,
  SUMMARY_FONT_SIZE,
} from './ProductPrice.contants';
import { ProductPriceProps } from './ProductPrice.types';

const ProductPrice = ({
  logo = PRODUCT_PRICE_DEFAULT_LOGO,
  currency = PRODUCT_PRICE_DEFAULT_CURRENCY,
  value = 0,
  size = 'small',
  width,
  height,
  showLogo = true,
  showEuro = false,
  sx,
}: ProductPriceProps) => {
  const growthbook = useGrowthBook();

  const isForcedEuro = growthbook?.isOn(SHOW_PRICES_IN_EUROS);

  const { typography: typographySx, logo: logoSx } = ProductPriceSize[size];
  const location = useLocation();
  const isHomePage = selectIsHomePage(location);
  const isMarketplacePage = selectIsMarketplacePage(location);
  const isMatchesPage = selectIsMatchesPage(location);

  if (isForcedEuro && (isHomePage || isMarketplacePage || isMatchesPage)) {
    return (
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={SpaceSizes.xs}
        justifyContent="center"
      >
        <Typography
          fontSize={SUMMARY_FONT_SIZE}
          sx={{ ...typographySx, ...sx }}
        >
          {isNotNumber(value) ? value : `${Number(value) / 100} €`}
        </Typography>
      </Stack>
    );
  }

  const LogoComponent = logo ? (
    <ImageFallback
      fallback={logo}
      height={height || logoSx.height}
      src={logo}
      width={width || logoSx.width}
    />
  ) : (
    <Typography fontSize={SUMMARY_FONT_SIZE} sx={{ ...typographySx, ...sx }}>
      {currency}
    </Typography>
  );

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={SpaceSizes.xs}
      justifyContent="center"
    >
      {showLogo && LogoComponent}
      <Typography fontSize={SUMMARY_FONT_SIZE} sx={{ ...typographySx, ...sx }}>
        {formatGP(Number(value))}
      </Typography>

      {showEuro && (
        <Typography
          fontSize={SUMMARY_FONT_SIZE}
          sx={{ ...typographySx, ...sx }}
        >
          /
        </Typography>
      )}

      {showEuro && (
        <Typography
          fontSize={SUMMARY_FONT_SIZE}
          sx={{ ...typographySx, ...sx }}
        >
          {Number(value) / 100} &euro;
        </Typography>
      )}
    </Stack>
  );
};

export default ProductPrice;
