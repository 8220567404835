/* eslint-disable @typescript-eslint/no-explicit-any */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { F } from 'ramda';
import { useCallback, useEffect } from 'react';

import {
  AppliedFilter,
  DEFAULT_PAGE_SIZE,
} from '../../../features/searchfilter/responses/SearchResponse';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import useInfiniteScroll from './useInfiniteScroll';

// default selector when selector is not provided
const defaultSelector = () => ({});
const defaultSkipCondition = F;

export default function createUseDataWithInfiniteScroll<T>(
  useQueryFunction: any,
  syncAppliedFilters?: (appliedFilters: AppliedFilter[]) => any,
  selector: (state: RootState) => any = defaultSelector,
  skipCondition: (state: RootState) => any = defaultSkipCondition,
) {
  return function useDataWithInfiniteScroll() {
    const path = useAppSelector(selector);
    const skip = useAppSelector(skipCondition);

    const { offset, setOffset, resetOffset, limit } = useInfiniteScroll({
      defaultLimit: DEFAULT_PAGE_SIZE,
    });

    const {
      data,
      isFetching,
      isLoading,
      isSuccess,
      isError,
      isUninitialized,
      status,
    } = useQueryFunction(
      skip
        ? skipToken
        : {
            ...(path || {}),
            pagination: { offset, limit },
          },
    );

    const enterWaypoint = useCallback(() => {
      if (!data?.paging?.is_last_page && !isFetching) {
        setOffset(data?.results.length || 0);
      }
    }, [
      data?.paging?.is_last_page,
      data?.results.length,
      isFetching,
      setOffset,
    ]);

    // Reset offset when filters change
    useEffect(() => {
      resetOffset();
    }, [resetOffset, path]);

    const dispatch = useAppDispatch();

    // Sync applied filters when data changes
    useEffect(() => {
      if (
        syncAppliedFilters &&
        data?.appliedFilters &&
        data?.appliedFilters?.length > 0 &&
        status === 'fulfilled'
      ) {
        // the payload is our backend applied filters
        dispatch(syncAppliedFilters(data?.appliedFilters));
      }
    }, [data?.appliedFilters, dispatch, status]);

    return {
      data: data as T,
      isFetching,
      isLoading,
      isSuccess,
      isError,
      isUninitialized,
      enterWaypoint,
      resetOffset,
      isLastPage: data?.paging?.is_last_page,
    };
  };
}
