const CART_ALREADY_CONFIRMED = 'CART_ALREADY_CONFIRMED';
const MAX_HOLD_USER_REACHED = 'MAX_HOLD_USER_REACHED';
const CANT_HOLD_PIECE_NUMBER = 'CANT_HOLD_PIECE_NUMBER';
const GENERIC_ERROR = 'GENERIC_ERROR';
const NO_FUNDS = 'NO_FUNDS';
const OUT_OF_STOCK = 'OUT_OF_STOCK';

export {
  CANT_HOLD_PIECE_NUMBER,
  CART_ALREADY_CONFIRMED,
  GENERIC_ERROR,
  MAX_HOLD_USER_REACHED,
  NO_FUNDS,
  OUT_OF_STOCK,
};

export const CHECKOUT_STATUS = {
  CANCELLED: 'cancelled',
  COMPLETE: 'complete',
  PENDING: 'pending',
  FAILED: 'failed',
  EXPIRED: 'expired',
};
