import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const MIN_OFFER_ITEMS = 4;

export const TRADE_GRID_GAP = {
  xs: SpaceSizes.xxs,
  sm: SpaceSizes.xs,
  md: SpaceSizes.sm,
  lg: SpaceSizes.md,
};
