import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { selectIsUserLoggedIn } from '../../features/session/Session.selector';
import { useAppSelector } from '../hooks';

/**
 * Redirects to marketplace session is ready
 */
const useLoginSignupRedirect = () => {
  const navigate = useNavigate();
  const userLoggedIn = useAppSelector(selectIsUserLoggedIn);

  const [params] = useSearchParams();
  const redirect = params.get('redirect') || undefined;

  useEffect(() => {
    // redirect to marketplace when user is logged in and there is no redirect param
    if (userLoggedIn) {
      navigate(redirect ?? '/marketplace');
    }
  }, [navigate, redirect, userLoggedIn]);
};

export default useLoginSignupRedirect;
