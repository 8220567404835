import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { setMyCollectionFilter } from '../../../features/searchfilter/mycollection/MyCollectionFilter.slice';
import { selectUserSession } from '../../../features/session/Session.selector';
import {
  useGetOfferQuery,
  useLazyAcceptOfferQuery,
  useLazyRejectOfferQuery,
} from '../../../features/trades/Trades.api';
import { useAppDispatch, useAppSelector } from '../../hooks';

export const useOfferDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { offerId } = useParams();
  const {
    data: offer,
    isLoading: loadingDetail,
    isError,
    refetch,
  } = useGetOfferQuery(offerId || '');
  const user = useAppSelector(selectUserSession);

  const [
    rejectOffer,
    {
      data: rejectData,
      isLoading: loadingReject,
      isSuccess: successReject,
      isError: errorReject,
    },
  ] = useLazyRejectOfferQuery();

  const [
    acceptOffer,
    {
      data: acceptData,
      isLoading: loadingAccept,
      isSuccess: successAccept,
      isError: errorAccept,
    },
  ] = useLazyAcceptOfferQuery();

  const getPieces = useMemo(() => {
    if (!offer?.trading_items) {
      return [];
    }
    return offer.trading_items.map(
      (item) => `${item.player.name} #${item.ball_id}`,
    );
  }, [offer]);

  useEffect(() => {
    if (user?.user_id) dispatch(setMyCollectionFilter(user.user_id));
  }, [dispatch, user?.user_id]);

  useEffect(() => {
    if (isError) navigate('/trades?tab=received');
  }, [isError, navigate]);

  const handleAccept = () => {
    if (offerId) {
      acceptOffer(offerId);
    }
  };

  const handleReject = () => {
    if (offerId) {
      rejectOffer(offerId);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (successReject) {
      navigate('/trades?tab=received');
    }
    if (successAccept) {
      navigate('/trades/offer/result/successAccept');
    }
    if (errorAccept) {
      navigate('/trades/offer/result/failurePending');
    }
    if (errorReject) {
      navigate('/trades/offer/result/failureReject');
    }
  }, [successReject, errorReject, successAccept, errorAccept, navigate]);

  return {
    offer,
    loadingDetail,
    handleReject,
    rejectData,
    loadingReject,
    handleAccept,
    acceptData,
    loadingAccept,
    getPieces,
  };
};
