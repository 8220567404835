import { Box } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useBigDesktopMediaQuery from '../../hooks/useBigDesktopMediaQuery';
import HeaderDesktop from '../../layout/Header/desktop/HeaderDesktop';
import HeaderMobile from '../../layout/Header/mobile/HeaderMobile';

const Header = () => {
  const isBigDdesktop = useBigDesktopMediaQuery();

  return (
    <Box
      sx={{
        marginTop: isBigDdesktop ? SpaceSizes.md : SpaceSizes.sm,
        marginBottom: isBigDdesktop ? SpaceSizes.md : SpaceSizes.sm,
        marginInline: isBigDdesktop ? SpaceSizes.lg : SpaceSizes.sm,
        zIndex: 1,
      }}
    >
      {isBigDdesktop ? <HeaderDesktop /> : <HeaderMobile />}
    </Box>
  );
};

export default Header;
