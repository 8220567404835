import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Hook to add redirect param to signup and login routes
 * @returns signupRoute: string, loginRoute: string
 */
const useRedirectLoginSignup = () => {
  const [params] = useSearchParams();
  const redirect = params.get('redirect') || undefined;

  // Añadir el parámetro de redirección a signup si existe
  const signupRoute = useMemo(() => {
    return `/signup${redirect ? `?redirect=${redirect}` : ''}`;
  }, [redirect]);

  // Añadir el parámetro de redirección a signup si existe
  const signupWithEmailRoute = useMemo(() => {
    return `/signup-email${redirect ? `?redirect=${redirect}` : ''}`;
  }, [redirect]);

  // Añadir el parámetro de redirección a login si existe
  const loginRoute = useMemo(() => {
    return `/login${redirect ? `?redirect=${redirect}` : ''}`;
  }, [redirect]);

  return {
    signupRoute,
    signupWithEmailRoute,
    loginRoute,
  };
};

export default useRedirectLoginSignup;
