import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';

export const CARD_WIDTH_MOBILE = 140;
export const CARD_HEIGHT_MOBILE = 240;

export const CARD_WIDTH_DESKTOP = 240;
export const CARD_HEIGHT_DESKTOP = 390;

export const CARD_FOOTER_HEIGHT_DESKTOP = 102;
export const CARD_FOOTER_HEIGHT_MOBILE = 70;

export const RELATED_CARD_HEIGHT = 210;
export const RELATED_CARD_WIDTH = 133;

export const RELATED_CARD_HEIGHT_LARGE = 280;
export const RELATED_CARD_WIDTH_LARGE = 180.5;

export const useSizeRelatedProduct = () => {
  const isMobile = useMobileMediaQuery();

  const relatedHeight = isMobile ? CARD_HEIGHT_MOBILE : CARD_HEIGHT_DESKTOP;
  const relatedWidth = isMobile ? CARD_WIDTH_MOBILE : CARD_WIDTH_DESKTOP;

  return {
    relatedHeight,
    relatedWidth,
  };
};
