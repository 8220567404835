import { styled } from '@mui/material/styles';

import { LogoProps } from './Logo.types';

const Logo = styled('img', {
  shouldForwardProp: (prop) => prop !== '$width' && prop !== '$height',
})<LogoProps>(
  ({ $width, $height }) => `
max-width: ${$width}px;
max-height: ${$height}px;
cursor: pointer;
`,
);

export default Logo;
