import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatUTCtoLocalTime } from '../../../utils/date';
import { geti18nResolvedLanguage } from '../../i18n';

export const VenlyOutageModal = ({
  isOpen,
  setIsOpen,
  startDate,
  startTime,
  endDate,
  endTime,
}: {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      sx={{
        div: {
          margin: SpaceSizes.xxs,
          alignItems: 'center',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClose={() => setIsOpen(false)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: SpaceSizes.sm,
          textAlign: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundImage:
            'linear-gradient(rgba(42, 42, 68, 1), rgba(22, 22, 48, .85))',
          paddingY: SpaceSizes.xl,
          paddingX: SpaceSizes.lg,
        }}
      >
        <Typography>
          {t('venlyOutageModal.text', {
            startDate: formatUTCtoLocalTime(
              geti18nResolvedLanguage(),
              startDate,
              startTime,
              undefined,
              false,
            ),
            endDate: formatUTCtoLocalTime(
              geti18nResolvedLanguage(),
              endDate,
              endTime,
              undefined,
              false,
            ),
          })}
        </Typography>
      </Box>
    </Dialog>
  );
};
