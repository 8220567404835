import { Stack, styled } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  MOVEMENTS_CONTENT_SCROLL_SIZE,
  MovementTabs,
} from './MovementsContent.constants';
import { MyBuysMovements } from './tabs/MyBuysMovements';
import { MyTransactionsMovements } from './tabs/MyTransactionsMovements';

const MovementsContentContainer = styled(Stack)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '0.6em',
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': `inset 0 0 6px ${theme.palette.text.secondary}`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: SpaceSizes.xs,
  },
  paddingY: SpaceSizes.mdPlus,
  paddingX: SpaceSizes.xs,
  overflowY: 'scroll',
}));

export const MovementsContent = ({ tab }: { tab: MovementTabs }) => {
  return (
    <Stack gap={SpaceSizes.lg} width="100%">
      <MovementsContentContainer
        gap={SpaceSizes.mdPlus}
        maxHeight={MOVEMENTS_CONTENT_SCROLL_SIZE}
        width="100%"
      >
        {tab === MovementTabs.BUYS && <MyBuysMovements />}
        {tab === MovementTabs.TRANSFERS && <MyTransactionsMovements />}
      </MovementsContentContainer>
    </Stack>
  );
};
