import { Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import DEFAULT_LOGO from '../../assets/img/default_logo_club.png';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { MATCH_RESULT_SPACING } from './MatchResult.constants';
import { MatchResultProps } from './MatchResult.types';

export const MatchResult = ({
  home,
  away,
  showNames = false,
  showScore = true,
  separator = '-',
  iconsSize = '33',
  homeScore,
  awayScore,
  golClubId,
  isRelatedProduct,
  textVariant = 'h6',
  sx,
  isGolden,
  variant = 'primary',
}: MatchResultProps) => {
  const theme = useTheme();
  const homeGoal = useMemo(() => golClubId === home.id, [golClubId, home.id]);
  const awayGoal = useMemo(() => golClubId === away.id, [golClubId, away.id]);
  const marginModifier = { margin: 0 };
  const widthModifier = showNames ? 100 : 'auto';

  const textColor = isGolden
    ? theme.palette.golden.main
    : variant === 'primary'
    ? theme.palette.text.primary
    : theme.palette.background.default;

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={{ xs: SpaceSizes.smPlus, sm: SpaceSizes.md, md: SpaceSizes.mdPlus }}
      justifyContent="space-between"
      spacing={isRelatedProduct ? SpaceSizes.xs : SpaceSizes.sm}
      sx={sx}
    >
      <Stack
        alignItems="center"
        direction="column"
        spacing={isRelatedProduct ? SpaceSizes.xs : MATCH_RESULT_SPACING}
        style={marginModifier}
      >
        <ImageFallback
          alt="Home club"
          fallback={DEFAULT_LOGO}
          height={iconsSize}
          src={home.logo}
          width={iconsSize}
        />
        {showNames && (
          <Typography
            align="center"
            data-testid={`team-home-${home.code}`}
            style={marginModifier}
            variant="body1"
            width={widthModifier}
          >
            {home.name}
          </Typography>
        )}
      </Stack>
      {showScore && (
        <Typography
          className="no-margin"
          color={textColor}
          fontWeight="bold"
          style={marginModifier}
          sx={{
            opacity: homeGoal ? '100%' : '68%',
          }}
          variant={isRelatedProduct ? 'h6' : textVariant}
        >
          {homeScore}
        </Typography>
      )}

      <Typography
        color={textColor}
        style={marginModifier}
        variant={textVariant}
      >
        {separator}
      </Typography>

      {showScore && (
        <Typography
          color={textColor}
          fontWeight="bold"
          style={marginModifier}
          sx={{
            opacity: awayGoal ? '100%' : '68%',
          }}
          variant={isRelatedProduct ? 'h6' : textVariant}
        >
          {awayScore}
        </Typography>
      )}
      <Stack
        alignItems="center"
        direction="column"
        spacing={isRelatedProduct ? SpaceSizes.xs : MATCH_RESULT_SPACING}
        style={marginModifier}
      >
        <ImageFallback
          alt="Away club"
          fallback={DEFAULT_LOGO}
          height={iconsSize}
          src={away.logo}
          width={iconsSize}
        />
        {showNames && (
          <Typography
            align="center"
            data-testid={`team-away-${away.code}`}
            style={marginModifier}
            variant="body1"
            width={widthModifier}
          >
            {away.name}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
