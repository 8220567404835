import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../env/env';
import { TradablePiecesProps } from '../../../features/trades/Trades.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import GOLBALL from '../../assets/img/total_golballs_indicator.svg';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { ConfirmWarningModal } from '../ConfirmWarningModal/ConfirmWarningModal';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import {
  TRADE_ITEM_NAME_FONT_SIZE,
  TRADE_ITEM_SELF_FONT_SIZE,
} from './TradeItemDetail.constants';
import { shrinkWord } from './TradeItemDetail.utils';

const anonymousUserUrl = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/anonymous-user.png`;

export const TradeItemDetail: FC<TradablePiecesProps> = ({
  user,
  tradeId,
  pieceNumber,
}) => {
  const navigate = useNavigate();
  const [selfModalOpen, setSelfModalOpen] = useState<boolean>(false);
  const isSmallestMobile = useSmallMobileMediaQuery();
  const {
    nickname,
    avatar_url: avatarUrl,
    user_id: userId,
    is_public: isPublic,
    is_self: isSelf,
  } = user;

  const navigateToUserProfile = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      navigate(`/user/${userId}`);
    },
    [navigate, userId],
  );

  const navigateToInitiateOffer = useCallback(() => {
    if (isSelf) {
      setSelfModalOpen(true);
    } else {
      navigate(`/trades/offer/new/${tradeId || 'cancelled'}`);
    }
  }, [navigate, isSelf, tradeId]);

  const { t } = useTranslationByNamespaces('components.tableRank');

  const closeWarning = useCallback(() => {
    setSelfModalOpen(false);
  }, [setSelfModalOpen]);

  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      borderBottom="2px solid transparent"
      borderRadius={SpaceSizes.xs}
      direction="row"
      justifyContent="space-between"
      px={SpaceSizes.md}
      py={SpaceSizes.mdPlus}
      sx={{
        cursor: 'pointer',
        backgroundColor: 'background.paper',
        transition: '0.3s ease',
        '&:hover': {
          borderColor: theme.palette.secondary.main,
          boxShadow: `0px 4px 10px ${theme.palette.secondary.main}`,
        },
      }}
      width="100%"
    >
      <Stack
        alignItems="center"
        data-testid="trade-item-detail"
        direction="row"
        justifyContent="space-between"
        width="100%"
        onClick={navigateToInitiateOffer}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={SpaceSizes.md}
          width={isSmallestMobile ? '100%' : '40%'}
        >
          <IconButton
            aria-label="User's profile picture"
            onClick={navigateToUserProfile}
          >
            <ImageFallback
              fallback={avatarUrl || anonymousUserUrl}
              height={SpaceSizes.lgPlus}
              src={avatarUrl || anonymousUserUrl}
              style={{ borderRadius: SpaceSizes.lg, zIndex: 10 }}
              width={SpaceSizes.lgPlus}
            />
          </IconButton>
          <Stack direction="column">
            <Stack alignItems="center" direction="row" gap={SpaceSizes.xs}>
              <Typography
                color="text.primary"
                component="p"
                fontSize={TRADE_ITEM_NAME_FONT_SIZE}
              >
                {isPublic
                  ? shrinkWord(nickname, isSmallestMobile ? 15 : 25)
                  : t('anonymous')}
              </Typography>
              <Typography
                color="text.primary"
                component="p"
                fontSize={TRADE_ITEM_SELF_FONT_SIZE}
              >
                {isSelf && t('you')}
              </Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={SpaceSizes.xs}>
              <ImageFallback
                alt="Gol-ball"
                fallback={GOLBALL}
                height={SpaceSizes.smPlus}
                src={GOLBALL}
                width={SpaceSizes.smPlus}
              />
              {user.total_amount && (
                <Typography color="text.primary" fontWeight={400} variant="cH6">
                  {user.total_amount} Gol-balls
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="40%">
          <SyncAltIcon sx={{ color: 'text.secondary' }} />
          <Typography color="text.primary" variant="cH4">
            #{pieceNumber}
          </Typography>
        </Stack>
      </Stack>
      <ConfirmWarningModal
        isOpen={selfModalOpen}
        namespaces="pages.myTradeRequests.cta.self.warning"
        onClose={closeWarning}
      />
    </Stack>
  );
};
