import { Skeleton, Stack, styled, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { geti18nResolvedLanguage } from '../../i18n';
import ProductSwiper from '../ProductSwiper/ProductSwiper';
import { ResponsiveIframe } from '../ResponsiveIframe/ResponsiveIframe';
import { TACTIC_MIN_HEIGHT } from './ProductMediaWidget.constants';
import { IProductMediaWidgetProps } from './ProductMediaWidget.types';

const StyledTab = styled(Tab)({
  borderRadius: `${SpaceSizes.smPlus}px ${SpaceSizes.smPlus}px 0px 0px`,
  textTransform: 'none',
  '&.Mui-selected': {
    borderBottom: 0,
    fontWeight: 'normal',
  },
});

const TabContent = ({
  content,
  index,
  tabValue,
}: {
  content: React.ReactNode;
  index: number;
  tabValue: number;
}) => {
  return (
    <div
      aria-labelledby={`tab-media-${index}`}
      hidden={tabValue !== index}
      role="tabpanel"
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      {content}
    </div>
  );
};

export const ProductMediaWidget = ({
  productTacticMultimedia,
  productChainOfCustodyMultimedia,
  isLoading,
}: IProductMediaWidgetProps) => {
  const { t } = useTranslationByNamespaces(
    'pages.product.multimediaSection.tabs',
  );
  const isMobile = useMobileMediaQuery();
  const [tabValue, setTabValue] = useState(0);

  if (isLoading) return <Skeleton height="20" variant="rectangular" />;

  // Map lang to opta localisation spanish id
  const lang =
    geti18nResolvedLanguage() === 'es' ? 'es_ES' : geti18nResolvedLanguage();
  const iframeUrl = `/opta.html?lang=${lang}&competition=${productTacticMultimedia?.competition}&event_id=${productTacticMultimedia?.event}&match=${productTacticMultimedia?.match}&season=${productTacticMultimedia?.season}`;

  return (
    <Stack>
      <Tabs
        TabIndicatorProps={{
          hidden: true,
        }}
        value={tabValue}
        variant={isMobile ? 'fullWidth' : 'standard'}
        onChange={(_, newValue) => setTabValue(newValue)}
      >
        {productTacticMultimedia && (
          <StyledTab
            data-testid="tactic"
            label={t('multimediaTabTACTIC')}
            sx={{
              backgroundColor:
                tabValue === 0 ? 'background.paper' : 'background.default',
            }}
          />
        )}
        {productChainOfCustodyMultimedia && (
          <StyledTab
            data-testid="chain_of_custody"
            label={t('multimediaTabCHAIN_OF_CUSTODY')}
            sx={{
              backgroundColor:
                tabValue === 1 ? 'background.paper' : 'background.default',
            }}
          />
        )}
      </Tabs>
      {productTacticMultimedia && (
        <TabContent
          content={
            <ResponsiveIframe
              iframeUrl={iframeUrl}
              minHeightPx={TACTIC_MIN_HEIGHT}
              title={t(
                'pages.product.multimediaSection.tabs.multimediaTabTACTIC',
              )}
            />
          }
          index={0}
          tabValue={tabValue}
        />
      )}
      {productChainOfCustodyMultimedia && (
        <TabContent
          content={
            <ProductSwiper
              images={productChainOfCustodyMultimedia.map(
                (file) => file.thumb_location,
              )}
            />
          }
          index={1}
          tabValue={tabValue}
        />
      )}
    </Stack>
  );
};
