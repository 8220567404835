import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { ReferFriendProps } from './ReferFriend.types';
import ReferralLink from './ReferralLink';

export const ReferFriend = ({ link }: ReferFriendProps) => {
  const { t } = useTranslationByNamespaces('pages.referrals');
  const isSmallestMobile = useSmallMobileMediaQuery();

  return (
    <Stack gap={SpaceSizes.md} justifyContent="flex-start" width="100%">
      <Typography
        fontWeight="600"
        textAlign="start"
        variant={isSmallestMobile ? 'body1' : 'h6'}
      >
        {t('referFriend.title')}
      </Typography>
      <ReferralLink link={link} />
    </Stack>
  );
};
