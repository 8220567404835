import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { ContentCopy } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { COPY_TOOLTIP_ACTIVE_MS } from './CopyAction.constants';

export const CopyAction = ({
  sharedLink,
  isRefer = false,
  dateTestId = 'button-action-copy',
}: {
  sharedLink?: string;
  isRefer?: boolean;
  dateTestId?: string;
}) => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const sendDataToGTM = useGTMDispatch();
  const copyTooltipOpen = () => {
    setTooltipActive(true);
    const timer = setTimeout(() => {
      setTooltipActive(false);
    }, COPY_TOOLTIP_ACTIVE_MS);
    return () => clearTimeout(timer);
  };

  const handleClipboard = () => {
    if (!tooltipActive) {
      copyTooltipOpen();
    }
    navigator.clipboard.writeText(sharedLink ?? '');
    setTooltipActive(true);
    sendDataToGTM({
      event: TAG_EVENT.COPY_LINK,
      link: sharedLink,
    });
  };
  return (
    <Tooltip
      open={isRefer && tooltipActive}
      placement="top"
      title={t('shareModal.done')}
      arrow
    >
      <Button
        color="primary"
        data-testid={dateTestId}
        variant="text"
        onClick={handleClipboard}
      >
        {!tooltipActive ? (
          <>
            <ContentCopy
              sx={{ pr: !isRefer ? SpaceSizes.sm : SpaceSizes.none }}
            />
            {!isRefer && (
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {t('shareModal.copy')}
              </Typography>
            )}
          </>
        ) : (
          <>
            <CheckCircleIcon
              color="success"
              sx={{ pr: !isRefer ? SpaceSizes.sm : SpaceSizes.none }}
            />
            {!isRefer && (
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {t('shareModal.done')}
              </Typography>
            )}
          </>
        )}
      </Button>
    </Tooltip>
  );
};
