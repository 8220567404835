import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import NotificationsDropdownList from './NotificationsDropdownList';

const Notifications = () => {
  const [t] = useTranslation();

  return (
    <PageContainer
      id="notificationsPage"
      title={t('pages.notifications.title')}
      backButton
    >
      <NotificationsDropdownList />
    </PageContainer>
  );
};

export default Notifications;
