import React from 'react';
import { useParams } from 'react-router-dom';

import {
  selectProduct,
  selectProductSelfieStickersMultimedia,
} from '../../../features/products/Product.selectors';
import { useGetProductByIdQuery } from '../../../features/products/Products.api';
import { CollectableSelfieEditor } from '../../components/CollectableSelfieEditor/CollectableSelfieEditor';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';

export const Selfie = () => {
  const { productId } = useParams();

  const { product, productSelfieStickersMultimedia, isLoading } =
    useGetProductByIdQuery(
      { productId: productId || '', context: 'my_collection' },
      {
        selectFromResult: ({ data, ...rest }) => ({
          ...rest,
          product: selectProduct(data),
          productSelfieStickersMultimedia:
            selectProductSelfieStickersMultimedia(data),
        }),
      },
    );

  return (
    <PageContainer id="selfie" loading={isLoading} maxWidth="lg" backButton>
      {product && (
        <CollectableSelfieEditor
          product={product}
          stickers={productSelfieStickersMultimedia}
        />
      )}
    </PageContainer>
  );
};
