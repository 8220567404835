import { geti18nResolvedLanguage } from '../../app/i18n';
import Env from '../../env/env';
import { api } from '../api/Api';
import { HomeResponse } from './Home.types';

export const homeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHome: builder.query<HomeResponse, { referralCode: string }>({
      query: ({ referralCode }) => ({
        url: `${
          Env.REACT_APP_BASE_API_URL_HOME
        }home/me?lang_id=${geti18nResolvedLanguage()}`,
        method: 'GET',
        params: {
          ref: referralCode,
        },
      }),
    }),
  }),
});

export const { useGetHomeQuery } = homeApi;
