import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { MenuIconItemProps } from './MenuIconItem.types';

export const MenuIconItem = ({
  children,
  tagId,
  to = '',
  handleOnClick,
  sx,
  popOver,
}: MenuIconItemProps) => {
  const navigate = useNavigate();
  return (
    <Stack flexDirection="row" gap={SpaceSizes.md}>
      <Button
        data-testid={`${tagId}ButtonHeader`}
        sx={{
          width: '100%',
          backgroundColor: 'background.default',
          borderRadius: SpaceSizes.sm,
          ...sx,
        }}
        onClick={handleOnClick || (() => navigate(to))}
      >
        {children}
      </Button>
      {popOver && popOver}
    </Stack>
  );
};
