import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { SKELETON_LINE_HEIGHT } from './CardAlbum.constants';
import { CollectionsHeaderI } from './CardAlbum.types';

export const CollectionsHeader = ({
  title,
  avatar,
  loading,
}: CollectionsHeaderI) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      marginY={SpaceSizes.md}
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        gap={SpaceSizes.sm}
      >
        {loading ? (
          <Skeleton height={40} variant="circular" width={40} />
        ) : (
          <Avatar src={avatar} />
        )}
        {loading ? (
          <Skeleton height={SKELETON_LINE_HEIGHT} variant="text" width="15ch" />
        ) : (
          <Typography
            fontWeight={600}
            textTransform="uppercase"
            variant="body1"
          >
            {title}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
