import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSliceState } from '../commons/types';
import { CollectionListItem } from './Collections.types';

export interface CollectionsByGroup extends BaseSliceState {
  collectionByGroup: CollectionListItem[] | null;
  groupId?: string;
}

const initialState = {
  collectionByGroup: null,
  groupId: undefined,
} as CollectionsByGroup;

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setCollectionsByGroup: (
      state,
      action: PayloadAction<CollectionsByGroup>,
    ) => {
      const { groupId, collectionByGroup } = action.payload;
      state.collectionByGroup = collectionByGroup;
      state.groupId = groupId;
    },
  },
});

export default collectionsSlice.reducer;
export const { setCollectionsByGroup } = collectionsSlice.actions;
