import { Divider, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetNotificationsQuery,
  usePatchNotificationMutation,
} from '../../../features/notifications/Notifications.api';
import { selectNotificationsByStatus } from '../../../features/notifications/Notifications.selector';
import { NotificationStatus } from '../../../features/notifications/responses/Notification';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ContainerCenteredStyled } from '../../components/containers/ContainerCentered';
import Spinner from '../../components/Spinner';
import { NotificationWrapper } from './NotificationWrapper';

export const PressableStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== '$clickable',
})<{ $clickable: boolean }>`
  ${({ $clickable }) =>
    $clickable &&
    `
      cursor: pointer;
    `}
`;

const NotificationsDropdownList = () => {
  const [patchNotification] = usePatchNotificationMutation();
  const [tabValue, setTabValue] = useState<string | number>('0');
  const [t] = useTranslation();

  // RTKQ queries selecting sub-result from the notification whole result
  const { isLoading, allNotifications, newNotifications, unreadNotifications } =
    useGetNotificationsQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        allNotifications: result?.data || [],
        unreadNotifications: selectNotificationsByStatus(
          NotificationStatus.SHOWN,
          result?.data,
        ),
        newNotifications: selectNotificationsByStatus(
          NotificationStatus.NEW,
          result?.data,
        ),
      }),
    });

  useEffect(() => {
    // Set NEW notifications to UNREAD
    newNotifications?.map((n) =>
      patchNotification({
        notificationId: n.id,
        status: NotificationStatus.SHOWN,
      }),
    );
  });

  const onClickNotificationHandler = (id: string, clickable: boolean) => {
    // if the notification is clickable, return the onClick event
    return clickable
      ? {
          onClick: () =>
            patchNotification({
              // when notification is opened, mark as read
              notificationId: id,
              status: NotificationStatus.READ,
            }),
        }
      : {};
  };

  if (isLoading) {
    return (
      <ContainerCenteredStyled>
        <Spinner dark />
      </ContainerCenteredStyled>
    );
  }

  return (
    <Stack spacing={SpaceSizes.md} width="100%">
      {allNotifications.length > 0 && (
        <Tabs value={tabValue} variant="fullWidth">
          <Tab
            label={t('header.userMenu.notifications.all')}
            sx={{ color: 'text.primary' }}
            value="0"
            onClick={() => setTabValue('0')}
          />
          <Tab
            label={t('header.userMenu.notifications.noRead')}
            sx={{ color: 'text.primary' }}
            value="1"
            onClick={() => setTabValue('1')}
          />
        </Tabs>
      )}
      {/* Render notifications or no notifications title */}
      {tabValue === '0' && allNotifications.length === 0 ? (
        <Typography>
          {t('header.userMenu.notifications.noNotifications')}
        </Typography>
      ) : (
        <Stack
          data-testid="notificationsPageItemsContainer"
          spacing={SpaceSizes.sm}
          sx={{
            maxHeight: 320,
            overflowY: 'auto',
            height: '100%',
            '&::-webkit-scrollbar': {
              width: '0.5em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'background.paper',
              outline: '1px solid slategrey',
            },
          }}
        >
          {(tabValue === '0' ? [...allNotifications] : unreadNotifications).map(
            (n) => (
              <PressableStack
                key={n.id}
                $clickable={n.status_code !== NotificationStatus.READ}
                {...onClickNotificationHandler(
                  n.id,
                  n.status_code !== NotificationStatus.READ,
                )}
              >
                <NotificationWrapper notification={n} />
                <Divider sx={{ width: '95%' }} />
              </PressableStack>
            ),
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default NotificationsDropdownList;
