import { useGrowthBook } from '@growthbook/growthbook-react';
import { Box } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { SHOW_FREE_BENEFIT_SEARCH } from '../../../utils/growthbookFeatures';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { Animation } from '../Animate/Animate';
import { AnimationEnum } from '../Animate/Animate.constants';
import { ExternalContent } from '../ExternalContent/ExternalContent';

export const BenefitAlert = ({ available }: { available: boolean }) => {
  const { pathname } = useLocation();
  const isMobile = useMobileMediaQuery();
  const isMarketplace = pathname === '/marketplace';

  const growthbook = useGrowthBook();

  if (!available || growthbook?.isOff(SHOW_FREE_BENEFIT_SEARCH)) {
    return null;
  }
  return (
    <Animation
      key="benefit-message-alert"
      sx={{
        marginBottom: SpaceSizes.mdPlus,
        marginLeft: SpaceSizes.md,
      }}
      timeProps={{ delay: 0, duration: 0.85 }}
      transformProps={{ offsetY: '5%' }}
      type={AnimationEnum.Offset}
    >
      <NavLink
        style={{
          color: 'inherit',
          textDecoration: 'none',
          cursor: isMarketplace ? 'inherit' : 'pointer',
        }}
        to={isMarketplace ? '' : '/marketplace'}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gap={isMobile ? SpaceSizes.xs : SpaceSizes.mdPlus}
          justifyContent="flex-start"
          marginBottom={isMarketplace ? SpaceSizes.mdPlus : 0}
          sx={{ width: '100%' }}
        >
          <ExternalContent
            filter={{ key: 'content_id', value: 'first_buy_free' }}
            type="contents"
          />
        </Box>
      </NavLink>
    </Animation>
  );
};
