export default {
  NODE_ENV: import.meta.env.MODE || 'development',
  REACT_APP_BASE_API_URL_DEFAULT: import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_AUTH:
    import.meta.env.VITE_BASE_API_URL_AUTH ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_TRADE:
    import.meta.env.VITE_BASE_API_URL_TRADE ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_USERS:
    import.meta.env.VITE_BASE_API_URL_USERS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_PREFERENCES:
    import.meta.env.VITE_BASE_API_URL_PREFERENCES ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_PRODUCTS:
    import.meta.env.VITE_BASE_API_URL_PRODUCTS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_COLLECTIONS:
    import.meta.env.VITE_BASE_API_URL_COLLECTIONS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_NOTIFICATIONS:
    import.meta.env.VITE_BASE_API_URL_NOTIFICATIONS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_TRANSFER:
    import.meta.env.VITE_BASE_API_URL_NOTIFICATIONS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_SEARCH:
    import.meta.env.VITE_BASE_API_URL_SEARCH ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_BOOKMARKS:
    import.meta.env.VITE_BASE_API_URL_BOOKMARKS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_CART:
    import.meta.env.VITE_BASE_API_URL_CART ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_SHOPPING_CART:
    import.meta.env.VITE_BASE_API_URL_SHOPPING_CART ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_WEB2_NFT:
    import.meta.env.VITE_BASE_API_URL_WEB2_NFT ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_WEB3:
    import.meta.env.VITE_BASE_API_URL_WEB3 ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_TOURNAMENTS:
    import.meta.env.VITE_BASE_API_URL_TOURNAMENTS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_HOME:
    import.meta.env.VITE_BASE_API_URL_HOME ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_GOLPOINTS:
    import.meta.env.VITE_BASE_API_URL_GOLPOINTS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_CHECKOUT:
    import.meta.env.VITE_BASE_API_URL_CHECKOUT ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_REFERRALS:
    import.meta.env.VITE_BASE_API_URL_REFERRALS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_PKPASS:
    import.meta.env.VITE_BASE_API_URL_PKPASS ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_API_URL_TRIVIA:
    import.meta.env.VITE_BASE_API_URL_TRIVIA ||
    import.meta.env.VITE_BASE_API_URL_DEFAULT,
  REACT_APP_BASE_APP_URL: import.meta.env.VITE_BASE_APP_URL,
  REACT_APP_GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  REACT_APP_FEATURES_ENDPOINT: import.meta.env.VITE_FEATURES_ENDPOINT || '',
  REACT_APP_COUNTRY_STATE_CITY_API_BASE_URL: import.meta.env
    .VITE_COUNTRY_STATE_CITY_API_BASE_URL,
  REACT_APP_COUNTRY_STATE_CITY_API_KEY: import.meta.env
    .VITE_COUNTRY_STATE_CITY_API_KEY,
  REACT_APP_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  REACT_APP_COUNTRY_LOCATION: import.meta.env.VITE_COUNTRY_LOCATION,
  REACT_APP_GOLBALL_ENV: import.meta.env.VITE_GOLBALL_ENV || 'development',
  REACT_APP_STATIC_BASE_URL:
    import.meta.env.VITE_STATIC_BASE_URL || 'https://static.dev.golball.com/',
  REACT_APP_GTM_AUTH: import.meta.env.VITE_GTM_AUTH,
  REACT_APP_GTM_PREVIEW: import.meta.env.VITE_GTM_PREVIEW,
  REACT_APP_GTM_ID: import.meta.env.VITE_GTM_ID,
  REACT_APP_STATIC_BASE_URL_STRAPI: import.meta.env.VITE_STATIC_BASE_URL_STRAPI,
  REACT_APP_GOOGLE_RECAPTCHA_KEY: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,
};
