import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';

import { useGetTotalItemsQuery } from '../../../../features/shoppingCart/ShoppingCart.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import palette from '../../../../theme/palette';
import { MenuIconItem } from '../../../components/MenuIconItem/MenuIconItem';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';

export const ShoppingCartMenu = () => {
  const isMobile = useMobileMediaQuery();

  const {
    data: shoppingCartData,
    isLoading: shoppingCartIsLoading,
    isUninitialized: shoppingCartIsUninitialized,
    isError: shoppingCartIsError,
  } = useGetTotalItemsQuery();

  const { total_items: totalItems } = shoppingCartData || {};

  if (shoppingCartIsError && !shoppingCartIsLoading) return null;
  return (
    <MenuIconItem
      sx={{ minWidth: isMobile ? SpaceSizes.lg : SpaceSizes.xl }}
      tagId="cart"
      to="/cart"
    >
      {shoppingCartIsLoading || shoppingCartIsUninitialized ? (
        <Stack alignContent="center" flexDirection="row" gap={SpaceSizes.md}>
          <Skeleton
            height={SpaceSizes.mdPlus}
            variant="circular"
            width={SpaceSizes.mdPlus}
          />
        </Stack>
      ) : (
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
          <Badge
            badgeContent={totalItems || 0}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: palette.primary.dark,
                fontWeight: 'bold',
                fontSize: isMobile ? SpaceSizes.sm : SpaceSizes.smPlus,
                width: '100%',
                height: '100%',
              },
            }}
          >
            <ShoppingCartIcon fontSize={isMobile ? 'inherit' : 'medium'} />
          </Badge>
        </Stack>
      )}
    </MenuIconItem>
  );
};
