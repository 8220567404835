import ShareIcon from '@mui/icons-material/Share';
import { Dialog } from '@mui/material';
import React, { useState } from 'react';

import IconTextButton from '../IconTextButton/IconTextButton';
import { ShareModal } from '../ShareModal/ShareModal';
import { ShareActionProps } from './ShareAction.types';

export const ShareAction = ({ link, text = undefined }: ShareActionProps) => {
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={isShowingShareModal}
        onClose={() => setIsShowingShareModal(false)}
      >
        <ShareModal
          link={link}
          text={text}
          onClose={() => setIsShowingShareModal(false)}
        />
      </Dialog>
      <IconTextButton
        icon={<ShareIcon sx={{ color: 'text.secondary' }} />}
        id="share-button"
        nativeId="share"
        textKey="pages.product.share"
        onClick={() => setIsShowingShareModal(true)}
      />
    </>
  );
};
