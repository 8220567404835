import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  selectHasUploadedSelfie,
  selectProductSelfieStickersMultimedia,
  selectProductWhatsappStickersMultimedia,
  selectSelfieUrl,
} from '../../../features/products/Product.selectors';
import { IProduct } from '../../../features/products/Product.types';
import {
  selectIsUserNeedsPhoneValidation,
  selectUserSession,
} from '../../../features/session/Session.selector';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useAppSelector } from '../../hooks';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { DialogCommon } from '../Dialog/Dialog';

const StyledSelfie = styled('img')`
  width: 500px;
`;

interface UserActionsProps {
  product: IProduct;
  isLoadingSendStickers: boolean;
  isSuccessSendStickers: boolean;
  showUserActions: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  sendStickers: Function;
}

const UserActions = ({
  product,
  isLoadingSendStickers,
  isSuccessSendStickers,
  showUserActions,
  sendStickers,
}: UserActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTablet = useTabletMediaQuery();
  const isMobile = useMobileMediaQuery();

  const [openUserRequiresPhoneDialog, setOpenUserRequiresPhoneDialog] =
    useState<boolean>(false);
  const [showStickersPreview, setShowStickersPreview] =
    useState<boolean>(false);
  const [showSelfiePreview, setShowSelfiePreview] = useState<boolean>(false);

  const productSelfieStickersMultimedia =
    selectProductSelfieStickersMultimedia(product);
  const hasUploadedSelfie = selectHasUploadedSelfie(product);
  const productWhatsappStickersMultimedia =
    selectProductWhatsappStickersMultimedia(product);
  const selfieUrl = selectSelfieUrl(product);
  const user = useAppSelector(selectUserSession);
  const userNeedsPhoneValidation = useAppSelector(
    selectIsUserNeedsPhoneValidation,
  );

  const getWhatsappStickerList = () => {
    return (
      <Box mb={SpaceSizes.mdPlus}>
        <Grid columns={3} justifyContent="center" spacing={1} container>
          {productWhatsappStickersMultimedia?.map((sticker, index) => (
            <Grid xs={1} item>
              <img
                alt={`sticker_${index}`}
                src={sticker.location}
                width={isTablet ? '60px' : '120px'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const handleSendStickers = useCallback(async () => {
    await sendStickers({
      product_id: product.product_id,
      lang_id: user?.lang_id || 'es',
    });
    setShowStickersPreview(false);
  }, [product.product_id, sendStickers, user]);

  const handleOnClickSelfie = useCallback(() => {
    navigate(`/selfie/${product.product_id}`);
  }, [navigate, product.product_id]);

  const handleToggleSelfieModal = useCallback(() => {
    setShowSelfiePreview(!showSelfiePreview);
  }, [showSelfiePreview]);

  const handleToggleStickersModal = useCallback(() => {
    setShowStickersPreview(!showStickersPreview);
  }, [showStickersPreview]);

  const handleActionRequiringPhone = (action: () => void) => {
    if (userNeedsPhoneValidation) {
      setOpenUserRequiresPhoneDialog(true);
    } else {
      action();
    }
  };

  return (
    <>
      {showUserActions && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'space-evenly'}
          py={SpaceSizes.xs}
        >
          {selfieUrl ? (
            <Button
              key="button-selfie-preview"
              endIcon={<CameraAltIcon />}
              sx={{ textTransform: 'none', borderRadius: '20px' }}
              variant="contained"
              onClick={handleToggleSelfieModal}
            >
              {t('pages.collectionDetails.selfie.mySelfie')}
            </Button>
          ) : (
            <Button
              key="button-selfie-editor"
              disabled={hasUploadedSelfie || !productSelfieStickersMultimedia}
              endIcon={<CameraAltIcon />}
              sx={{ textTransform: 'none', borderRadius: '20px' }}
              variant="contained"
              onClick={handleOnClickSelfie}
            >
              {t('pages.collectionDetails.selfie.button')}
            </Button>
          )}
          <LoadingButton
            key="button-whatsapp"
            disabled={!productWhatsappStickersMultimedia}
            sx={{ textTransform: 'none', borderRadius: '20px' }}
            variant="contained"
            onClick={() =>
              handleActionRequiringPhone(handleToggleStickersModal)
            }
          >
            {t('pages.collectionDetails.stickers.button')}
          </LoadingButton>
        </Stack>
      )}

      <DialogCommon
        confirm={{
          url: '/sms-verification',
          message: t('pages.collectionDetails.dialog.confirm'),
        }}
        description={t('pages.collectionDetails.dialog.description')}
        handleClose={() => setOpenUserRequiresPhoneDialog(false)}
        isOpen={openUserRequiresPhoneDialog}
        title={t('pages.collectionDetails.dialog.title')}
      />

      <Dialog open={showStickersPreview}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          padding="0px 10px 0px 10px"
        >
          <Box
            display="flex"
            justifyContent="right"
            mt={SpaceSizes.md}
            width="100%"
          >
            <IconButton onClick={handleToggleStickersModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <DialogContent>{getWhatsappStickerList()}</DialogContent>
          <Box mb={SpaceSizes.mdPlus}>
            <LoadingButton
              key="button-whatsapp"
              disabled={!productWhatsappStickersMultimedia}
              loading={isLoadingSendStickers && !isSuccessSendStickers}
              size="large"
              startIcon={<WhatsAppIcon />}
              variant="contained"
              onClick={handleSendStickers}
            >
              {t('pages.collectionDetails.stickers.button')}
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={showSelfiePreview}>
        <Box display="flex" justifyContent="right">
          <IconButton onClick={handleToggleSelfieModal}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <StyledSelfie src={selfieUrl} />
      </Dialog>
    </>
  );
};

export default UserActions;
