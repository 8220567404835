import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import env from '../../../env/env';
import { shouldNotShowCheckoutButton } from '../../../features/checkout/Checkout.helpers';
import { STATUS_CODE_SHOPPING_CART } from '../../../features/shoppingCart/ShoppingCart.constants';
import {
  selectCartActive,
  selectModifiedStock,
  selectOutOfStock,
} from '../../../features/shoppingCart/ShoppingCart.selectors';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useAppSelector } from '../../hooks';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
} from '../../pages/confirmation-cart/ConfirmationCart.constants';
import { CardsCart } from '../CardsCart/CardsCart';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import ProductPrice from '../ProductPrice/ProductPrice';
import { InformationContainerProps } from './InformationContainer.types';

const golpointsImg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/golpoints.png`;

export const InformationContainer = ({
  product,
  createCheckoutIsLoading,
  totalPoints,
  totalItems,
  handleCreateCheckout,
}: InformationContainerProps) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslationByNamespaces('pages.confirmationCart');
  const statusCode = params.get('statusCode') ?? undefined;
  const isTablet = useTabletMediaQuery();
  const cartActive = useAppSelector(selectCartActive);
  const modifiedStock = useAppSelector(selectModifiedStock);
  const outOfStock = useAppSelector(selectOutOfStock);

  const navigateToCart = useCallback(() => {
    navigate('/cart');
  }, [navigate]);

  const navigateToProduct = useCallback(() => {
    navigate(`/product/${product?.product_id}`);
  }, [product, navigate]);

  const subtitleActual = `${product?.subtitle.line1} - ${product?.entity_name}`;

  if (!product) return null;
  return (
    <Stack>
      {!isTablet ? (
        <Stack gap={SpaceSizes.md} width="100%">
          <Stack flexDirection="row">
            <Stack
              alignItems="center"
              direction="row"
              gap={SpaceSizes.xl}
              justifyContent="center"
              width="100%"
            >
              <Stack
                flexDirection="row"
                gap={SpaceSizes.md}
                style={{ cursor: 'pointer' }}
                onClick={navigateToProduct}
              >
                <Stack>
                  <ImageFallback
                    alt="card-product"
                    fallback={product?.image}
                    height={IMAGE_HEIGHT}
                    src={product?.image}
                    style={{
                      borderRadius: SpaceSizes.sm,
                      objectFit: 'cover',
                    }}
                    width={IMAGE_WIDTH}
                  />
                </Stack>
                <Stack justifyContent="center">
                  {statusCode && (
                    <Typography
                      color={
                        statusCode === STATUS_CODE_SHOPPING_CART.ADDED
                          ? 'success.main'
                          : 'error.main'
                      }
                      fontWeight={700}
                      variant="body1"
                    >
                      {t(`status.${statusCode}`)}
                    </Typography>
                  )}
                  <Typography
                    color="neutral.50"
                    fontWeight="medium"
                    variant="body2"
                  >
                    {product?.title}
                  </Typography>
                  <Stack direction="row">
                    <Typography color="text.primary" variant="body2">
                      {subtitleActual}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider orientation="vertical" />
              <Stack>
                <Typography variant="body2">
                  {totalItems} {t('golpoints.subtitle')}
                </Typography>
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  gap={SpaceSizes.sm}
                >
                  <ProductPrice
                    height={SpaceSizes.mdPlus}
                    size="large"
                    value={totalPoints}
                    width={SpaceSizes.mdPlus}
                    showLogo
                  />
                </Stack>
              </Stack>
              <Stack
                flexDirection="row"
                gap={SpaceSizes.md}
                justifyContent="center"
                width="35%"
              >
                <PaymentButton
                  buttonText={t('buttons.buyCart')}
                  disableButton={shouldNotShowCheckoutButton(
                    cartActive,
                    createCheckoutIsLoading,
                    modifiedStock,
                    outOfStock,
                    statusCode,
                  )}
                  handleBuy={handleCreateCheckout}
                  isLoading={createCheckoutIsLoading}
                  showPrice={false}
                  sx={{
                    width: '100%',
                  }}
                  variant="caption"
                  variantButton="outlined"
                />
                <PaymentButton
                  buttonText={t('buttons.seeCart')}
                  disableButton={false}
                  handleBuy={navigateToCart}
                  showPrice={false}
                  sx={{
                    width: '100%',
                  }}
                  variant="caption"
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack gap={SpaceSizes.md}>
          <Stack gap={SpaceSizes.lg}>
            {statusCode && (
              <Typography
                color={
                  statusCode === STATUS_CODE_SHOPPING_CART.ADDED
                    ? 'success.main'
                    : 'error.main'
                }
                fontWeight={700}
                variant="body1"
              >
                {t(`status.${statusCode}`)}
              </Typography>
            )}
            <CardsCart
              key={product.product_id}
              entityName={product.entity_name}
              image={product.image}
              priceValue={parseInt(product.price.value, 10)}
              productId={product.product_id}
              quantity={1}
              subtitle={product.subtitle.line1}
              title={product.title}
            />
          </Stack>
          <Stack
            bgcolor="background.paper"
            gap={SpaceSizes.lg}
            p={SpaceSizes.sm}
            style={{
              borderTopLeftRadius: SpaceSizes.sm,
              borderBottomLeftRadius: SpaceSizes.sm,
              borderTopRightRadius: SpaceSizes.sm,
              borderBottomRightRadius: SpaceSizes.sm,
            }}
          >
            <Stack>
              <Typography variant="caption">
                {totalItems} {t('golpoints.subtitle')}
              </Typography>
              <Stack
                alignItems="center"
                flexDirection="row"
                gap={SpaceSizes.sm}
              >
                <Typography fontWeight={700} variant="body2">
                  {totalPoints}
                </Typography>
                <ImageFallback
                  fallback={golpointsImg}
                  height={SpaceSizes.mdPlus}
                  src={golpointsImg}
                  style={{
                    objectFit: 'cover',
                  }}
                  width={SpaceSizes.mdPlus}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            gap={SpaceSizes.lg}
            justifyContent="center"
            width="100%"
          >
            <PaymentButton
              buttonText={t('buttons.buyCart')}
              disableButton={shouldNotShowCheckoutButton(
                cartActive,
                createCheckoutIsLoading,
                modifiedStock,
                outOfStock,
                statusCode,
              )}
              handleBuy={handleCreateCheckout}
              isLoading={createCheckoutIsLoading}
              showPrice={false}
              sx={{
                width: '50%',
              }}
              variant="caption"
              variantButton="outlined"
            />
            <PaymentButton
              buttonText={t('buttons.seeCart')}
              disableButton={false}
              handleBuy={navigateToCart}
              showPrice={false}
              sx={{
                width: '50%',
              }}
              variant="caption"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
