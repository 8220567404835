import CalendarToday from '@mui/icons-material/CalendarToday';
import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import Chip from '../../components/Chip/Chip';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { TournamentMatchesSelector } from '../../components/TournamentMatchesSelector/TournamentMatchesSelector';

const Matches = () => {
  const [handleModal, setHandleModal] = useState(false);
  const [dates, setDates] = useState({ from: '', to: '', enabled: false });
  const { t } = useTranslation();

  return (
    <PageContainer
      id="matchesPage"
      maxWidth="sm"
      rightChild={
        dates.enabled ? (
          <Box onClick={() => setHandleModal(true)}>
            <Chip
              content={`${dates.from} - ${dates.to}`}
              prefix={
                <CalendarToday style={{ marginRight: 8, fontSize: 14 }} />
              }
              style={{
                flexDirection: 'row-reverse',
                padding: `${SpaceSizes.sm}px`,
              }}
            />
          </Box>
        ) : (
          <IconButton onClick={() => setHandleModal(true)}>
            <CalendarToday />
          </IconButton>
        )
      }
      title={t('pages.matches.pageTitle')}
      trackCode={TAG_EVENT.CONTENT_GROUP.MATCHES}
    >
      <TournamentMatchesSelector
        handleModal={handleModal}
        setDates={setDates}
        setHandleModal={setHandleModal}
      />
    </PageContainer>
  );
};

export default Matches;
