import { LinearProgress, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { StoryProgressProps } from './Story.interfaces';

const MotionStack = motion(Stack);

export const StoryProgress = forwardRef(
  (props: StoryProgressProps, ref: Ref<unknown>) => {
    const { isComplete, isUnactive, markAsSeen } = props;

    const [progress, setProgress] = useState<number>(0);
    const isMarkedRef = React.useRef<boolean>(false);

    useImperativeHandle(ref, () => ({
      updateProgress(video: { duration: number; currentTime: number }) {
        const value = (100 / video.duration) * video.currentTime;
        if (value >= 75 && !isMarkedRef.current && !isUnactive && !isComplete) {
          markAsSeen();
          isMarkedRef.current = true;
        }
        setProgress(value);
      },
    }));

    let progressValue = 0;
    if (isUnactive) {
      progressValue = 0;
    } else if (isComplete) {
      progressValue = 100;
    } else {
      progressValue = progress;
    }

    return (
      <MotionStack
        height={SpaceSizes.md}
        px={SpaceSizes.xxs}
        transition={{ duration: 0.2 }}
        width="100%"
      >
        <LinearProgress value={progressValue} variant="determinate" />
      </MotionStack>
    );
  },
);
