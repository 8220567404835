import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { MouseEventHandler } from 'react';

import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';

interface SignupButtonProps {
  buttonText: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
  dataTestId?: string;
  id?: string;
}

const SignupButton = ({
  buttonText,
  onClick,
  icon,
  dataTestId,
  id,
}: SignupButtonProps) => {
  const isMobile = useMobileMediaQuery();

  return (
    <ButtonBase
      id={id}
      {...(dataTestId && { 'data-testid': dataTestId })}
      sx={{
        padding: '2px, 10px',
        height: '40px',
        borderRadius: '20px',
        width: isMobile ? '280px' : '343px',
        backgroundColor: '#fff',
      }}
      onClick={onClick}
    >
      <Stack alignItems="center" direction="row" gap={1}>
        {icon && icon}
        <Typography
          sx={{
            fontWeight: 500,
            textOverflow: 'ellipsis',
            fontSize: '14px',
            letterSpacing: '0.25px',
            color: 'primary.main',
            overflow: 'hidden',
          }}
        >
          {buttonText}
        </Typography>
      </Stack>
    </ButtonBase>
  );
};

export default SignupButton;
