import { useRef, useState } from 'react';

const LIMIT_DEFAULT = 6;

const useInfiniteScroll = ({
  defaultLimit = LIMIT_DEFAULT,
}: {
  defaultLimit?: number;
}) => {
  const [offset, setOffset] = useState<number>(0);
  const limit = useRef<number>(defaultLimit);

  const resetOffset = (): void => {
    setOffset(0);
  };

  return { offset, setOffset, limit: limit.current, resetOffset };
};

export default useInfiniteScroll;
