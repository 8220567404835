import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { ProductDetailTitleProps } from './ProductDetailTitle.types';

const ProductDetailTitle = ({
  children,
  textAlign = 'center',
  variant = 'cH4',
  bold = true,
  golden = false,
}: ProductDetailTitleProps) => {
  const theme = useTheme();

  const goldenStyle = {
    background: theme.palette.golden.gradient,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  };

  return (
    <Typography
      align={textAlign}
      fontWeight={bold ? 'bold' : 'medium'}
      sx={golden ? goldenStyle : {}}
      variant={variant}
    >
      {children}
    </Typography>
  );
};
export default ProductDetailTitle;
