import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';

export const AuctionHelpModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslationByNamespaces('pages.auction.detail');

  return (
    <Box
      alignItems="center"
      data-testid="auction-help"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      p={SpaceSizes.md}
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Stack
        sx={{
          flex: 'row',
          width: '100%',
          alignItems: 'flex-end',
        }}
      >
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography variant="body2">{t('modal')}</Typography>
    </Box>
  );
};
