import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Link as MuiLink } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { TAG_EVENT } from '../../../utils/tagger';
import { ANIMATED_LINK_SX } from './Link.constants';
import { LinkProps } from './Link.types';

export const Link = ({
  id,
  children,
  to,
  color = 'inherit',
  underline = 'hover',
  external = false,
  sx,
  target,
  tagEventId = TAG_EVENT.SELECT_CONTENT,
  tagSectionId,
  animatedHover = false,
  tagId,
}: React.PropsWithChildren<LinkProps>) => {
  const sendDataToGTM = useGTMDispatch();

  const tagLink = () => {
    sendDataToGTM({
      event: tagEventId,
      content_type: tagSectionId,
      item_id: tagId,
    });
  };

  // If includes http is external link in new tab otherwise, we handle whit internal router.
  return to.includes('http') || external ? (
    <MuiLink
      color={color}
      href={to}
      id={id}
      sx={animatedHover ? ANIMATED_LINK_SX : sx}
      target="_blank"
      underline={animatedHover ? 'none' : underline}
      {...(tagId && { onClick: tagLink })}
    >
      {children}
    </MuiLink>
  ) : (
    <MuiLink
      color={color}
      component={RouterLink}
      id={id}
      sx={animatedHover ? ANIMATED_LINK_SX : sx}
      target={target}
      to={to}
      underline={animatedHover ? 'none' : underline}
      {...(tagId && { onClick: tagLink })}
    >
      {children}
    </MuiLink>
  );
};
