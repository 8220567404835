import { ITier } from '../golpoints/Golpoints.types';

export interface IItem {
  product_id: string;
  product_type: string;
  title: string;
  entity_name: string;
  subtitle: {
    line1: string;
  };
  image: string;
  price: {
    value: string;
    currency: string;
  };
  quantity: number;
  is_assigned: boolean;
}

interface createPay {
  success_url: string;
  cancel_url: string;
  user_ip: string;
  payment_method: string;
  metadata: {
    user_ip: string;
    base_url: string;
  };
}

export interface payCheckoutApiRequest extends createPay {
  checkout_id?: string;
}

export interface payTopUpApiRequest extends createPay {
  topup_id?: string;
}

export interface createCheckoutApiRequest {
  item: {
    product_id?: string;
    quantity: number;
    price: {
      unit_value?: string;
      currency?: string;
    };
  };
  coupons?: string[];
}

type payApiResponse = {
  needs_topup?: boolean;
  checkout_id?: string;
  next_url?: string;
  status_code: CheckoutStatusCodeEnum;
};

export type payTopUpApiResponse = payApiResponse;
export type payCheckoutApiResponse = payApiResponse;

export interface createCheckoutApiResponse {
  checkout_id: string;
}

export interface getCheckoutApiResponse {
  checkout_id: string;
  status: string;
  created_at: string;
  total_points: number;
  items: IItem[];
  tiers: ITier[];
  type: 'direct' | 'cart';
  user_balance: number;
}

export type TStatus =
  | 'cancelled'
  | 'complete'
  | 'pending'
  | 'failed'
  | 'expired';

export interface getStatusApiResponse {
  status?: TStatus;
}

export interface Assignment {
  status: string;
  type: string;
  start_date: string;
}

export interface CheckoutSummaryResponse {
  checkout_id: string;
  status: string;
  status_code: CheckoutStatusCodeEnum;
  created_at: string;
  total_points: number;
  items: Item[];
  user_balance: number;
  topup: Topup;
  assignment?: Assignment;
}

export interface Item {
  quantity: number;
  product_id: string;
  product_type: string;
  subtitle: Subtitle;
  title: string;
  image: string;
  price: Price;
}

export interface Subtitle {
  line1: string;
}

export interface Price {
  value: string;
  currency: string;
}

export interface Topup {
  product_id: string;
  entity_id: string;
  golpoints: number;
}

export interface CreateCheckoutApiError {
  data: { code: string };
}

export enum CheckoutStatusCodeEnum {
  OK = 'OK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}
