import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isColorful, tintData } from './ImageTint.utils';

interface ImageTintProps {
  src: string;
  color: string;
  alt: string;
  cache?: boolean;
  height: string;
  width: string;
}

const ImageTint: React.FC<ImageTintProps> = ({
  src,
  color,
  alt,
  cache,
  height,
  width,
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const mountedRef = useRef<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);

  const applyTint = useCallback(
    (source: string, tintColor: string) => {
      if (!isColorful(source) && imgRef.current) {
        const imgElement = imgRef.current;
        tintData(imgElement, tintColor, { cache: Boolean(cache) }).then(
          (tintedSrc: string) => {
            if (mountedRef.current) {
              setImageSrc(tintedSrc);
            }
          },
        );
      }
    },
    [cache],
  );

  useEffect(() => {
    mountedRef.current = true;
    applyTint(src, color);

    return () => {
      mountedRef.current = false;
    };
  }, [src, color, applyTint]);

  return (
    <img
      ref={imgRef}
      alt={alt}
      crossOrigin="anonymous"
      height={height}
      src={imageSrc}
      width={width}
    />
  );
};

export default ImageTint;
