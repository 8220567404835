import PhoneIcon from '@mui/icons-material/Phone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Grid, Stack, Typography } from '@mui/material';
import { isNotEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetCountriesQuery,
  useGetStatesByCountryMutation,
} from '../../../../features/countries/Countries.api';
import {
  Country,
  State,
} from '../../../../features/countries/responses/Country';
import { selectIsUserNeedsPhoneValidation } from '../../../../features/session/Session.selector';
import { updateUser } from '../../../../features/session/Session.slice';
import {
  usersApiExtractErrors,
  useUpdateUserMutation,
} from '../../../../features/users/Users.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import {
  isValidEmail,
  isValidName,
  isValidNickname,
} from '../../../../utils/validation';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CountrySelect } from '../../form/CountrySelect/CountrySelect';
import Form from '../../form/Form';
import { FormSelectAuto } from '../../form/FormSelectAuto/FormSelectAuto';
import Input from '../../form/Input/Input';
import { GolButton } from '../../GolButton/GolButton.component';
import { InlineItem } from '../InlineItem/InlineItem';
import { UserFormSkeleton } from './UserForm.skeleton';
import { UserFormInputs, UserFormProps } from './UserForm.types';

export const UserForm = ({
  user,
  setAlertCode,
  setIsShowing,
}: UserFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [patchUpdateUser, { isLoading, isSuccess }] = useUpdateUserMutation();
  const [getStatesByCountry, { isLoading: isLoadingStates }] =
    useGetStatesByCountryMutation();
  const { data: countries, isLoading: isLoadingCountries } =
    useGetCountriesQuery();
  const [validStates, setValidStates] = useState<State[] | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<State | undefined>();

  // Get methods for Form Provider
  const methods = useForm<UserFormInputs>();
  const { watch, setValue, resetField } = methods;

  const onSubmit: SubmitHandler<UserFormInputs> = async (data) => {
    // update user api call
    try {
      const payload = {
        nickname: data.nickname,
        first_name: data.first_name,
        last_name: data.last_name,
        country_id: data.country_iso2,
        address_state_id: data.address_state_id ?? '',
        address_city_name: data.address_city_name,
        address: data.address,
      };
      await patchUpdateUser({
        user_id: user?.user_id ?? '',
        data: payload,
      }).unwrap();
      // update redux store too
      dispatch(updateUser(payload));
      setAlertCode('dataSuccess');
    } catch (error) {
      setAlertCode(
        usersApiExtractErrors(error, '', {
          parentKey: 'profileErrors',
          separator: '',
          defaultKey: 'serverError',
        }),
      );
    }
  };

  const handleCountryChange = async (country: Country) => {
    resetField('address_state_id');
    setValue('country_iso2', country.iso2);
    setValue('country_id', country.name, { shouldValidate: true });
    setSelectedCountry(country);
  };

  // Set default form values
  useEffect(() => {
    setValue('nickname', user?.nickname ?? '');
    setValue('email', user?.email ?? '');
    setValue('first_name', user?.first_name ?? '');
    setValue('last_name', user?.last_name ?? '');
    setValue('country_iso2', user?.country_id ?? '');
    setValue('address_state_id', user?.address_state_id ?? '');
    setValue('address_city_name', user?.address_city_name ?? '');
    setValue('address', user?.address ?? '');
  }, [user, setValue]);

  // Set default country value
  useEffect(() => {
    if (countries && user?.country_id) {
      const newCountry = countries?.find(
        (country: Country) => country.iso2 === user?.country_id,
      );
      setSelectedCountry(newCountry);
      setValue('country_id', newCountry?.name ?? '');
    }
  }, [user, setValue, countries]);

  // Update states
  useEffect(() => {
    if (selectedCountry) {
      const fetchStates = async () => {
        const states = await getStatesByCountry(selectedCountry.iso2).unwrap();
        setValidStates(states);
      };
      fetchStates();
    }
  }, [selectedCountry, getStatesByCountry]);

  const userNeedsPhoneValidation = useAppSelector(
    selectIsUserNeedsPhoneValidation,
  );
  useEffect(() => {
    setIsShowing(userNeedsPhoneValidation);
  }, [setIsShowing, userNeedsPhoneValidation]);

  if (isLoadingCountries) return <UserFormSkeleton />;

  return (
    user && (
      <Stack spacing={SpaceSizes.md}>
        <InlineItem
          icon={
            user?.phone && (
              <Stack
                alignItems="center"
                direction="row"
                spacing={SpaceSizes.xs}
              >
                <VerifiedUserIcon />
                <Typography variant="body1">{user.phone}</Typography>
              </Stack>
            )
          }
          subtitle={t('pages.profile.securitySection.phoneDescription')}
          title={t('pages.profile.securitySection.phoneTitle')}
        >
          <GolButton
            startIcon={<PhoneIcon />}
            to="/sms-verification"
            value={
              userNeedsPhoneValidation
                ? t('pages.profile.securitySection.phoneButtonNew')
                : t('pages.profile.securitySection.phoneButtonEdit')
            }
          />
        </InlineItem>
        <Form methods={methods} onSubmit={onSubmit}>
          <Stack justifyContent="center" spacing={SpaceSizes.lg}>
            <Grid gap={SpaceSizes.md} container>
              <Grid xs={12} item>
                <Input
                  errorMessage={t('pages.profile.errors.mailError')}
                  id="email"
                  label={t(`pages.profile.formBasicData.email`)}
                  placeholder={t(`pages.profile.formBasicData.email`)}
                  validate={() => isValidEmail(watch('email'))}
                  disabled
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  errorMessage={t('pages.profile.errors.nicknameError')}
                  id="nickname"
                  label={t(`pages.profile.formBasicData.nickname`)}
                  placeholder={t(`pages.profile.formBasicData.nickname`)}
                  validate={() => isValidNickname(watch('nickname'))}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  errorMessage={t('pages.profile.errors.firstNameError')}
                  id="first_name"
                  label={t(`pages.profile.formBasicData.firstName`)}
                  placeholder={t(`pages.profile.formBasicData.firstName`)}
                  validate={() => isValidName(watch('first_name'))}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  errorMessage={t('pages.profile.errors.lastNameError')}
                  id="last_name"
                  label={t(`pages.profile.formBasicData.lastName`)}
                  placeholder={t(`pages.profile.formBasicData.lastName`)}
                  validate={() => isValidName(watch('last_name'))}
                />
              </Grid>
              <Grid xs={12} item>
                <CountrySelect
                  countries={countries ?? []}
                  defaultValue={{
                    name: watch('country_id'),
                  }}
                  handleCountryChange={handleCountryChange}
                />
              </Grid>
              <Grid xs={12} item>
                <FormSelectAuto
                  defaultValue={{
                    name: watch('address_state_id'),
                  }}
                  disabled={isLoadingStates}
                  errorMessage={t('pages.profile.errors.departmentError')}
                  getOptionLabel={(option: Country) => option.name ?? ''}
                  id="address_state_id"
                  label={t(`pages.profile.formBasicData.department`)}
                  loading={isLoadingStates}
                  noOptionsText={t(
                    `pages.profile.formBasicData.departmentNoOptions`,
                  )}
                  options={validStates ?? []}
                  placeholder={t(
                    `pages.profile.formBasicData.departmentPlaceholder`,
                  )}
                  required={isNotEmpty(validStates)}
                  onChange={(e, data: State) => {
                    setValue('address_state_id', data.name, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  errorMessage={t('pages.profile.errors.cityError')}
                  id="address_city_name"
                  label={t(`pages.profile.formBasicData.city`)}
                  placeholder={t(`pages.profile.formBasicData.city`)}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  errorMessage={t('pages.profile.errors.directionError')}
                  id="address"
                  label={t(`pages.profile.formBasicData.direction`)}
                  placeholder={t(`pages.profile.formBasicData.direction`)}
                />
              </Grid>
            </Grid>
            <Stack alignItems="center">
              <GolButton
                disabled={
                  isNotNilOrEmpty(methods.formState.errors) || isLoadingStates
                }
                loading={isLoading && !isSuccess}
                size="large"
                type="submit"
                value={t('pages.profile.confirmButton')}
              />
            </Stack>
          </Stack>
        </Form>
      </Stack>
    )
  );
};
