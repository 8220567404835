import { Container } from '@mui/material';
import React from 'react';

export const ResponsiveIframe = ({
  iframeUrl,
  title,
  minHeightPx,
  allowIframeProp,
}: {
  iframeUrl: string;
  title: string;
  minHeightPx?: number;
  allowIframeProp?: string;
}) => (
  <Container>
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: '75%',
        minHeight: minHeightPx ? `${minHeightPx}px` : '0',
        width: 300,
      }}
    >
      <iframe
        allow={allowIframeProp}
        loading="lazy"
        src={iframeUrl}
        style={{
          border: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title={title}
        allowFullScreen
      />
    </div>
  </Container>
);
