import { isNonEmptyString } from 'ramda-adjunct';

const replaceHistory = (params: URLSearchParams) => {
  window.history.replaceState(
    '',
    '',
    `${
      window.location.pathname +
      (isNonEmptyString(params.toString()) ? `?${params.toString()}` : '')
    }`,
  );
};

export const deleteURLParamByKey = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(key);
  replaceHistory(urlParams);
};

export const addURLParamByKey = (key: string, value: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(key, value);
  replaceHistory(urlParams);
};

export const getURLParamByKey = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key) ?? '';
};
