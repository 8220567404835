import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetCountriesQuery } from '../../../../../features/countries/Countries.api';
import { Country } from '../../../../../features/countries/responses/Country';
import {
  selectLocationCountry,
  selectUserSession,
} from '../../../../../features/session/Session.selector';
import { updateUser } from '../../../../../features/session/Session.slice';
import { useUpdateUserMutation } from '../../../../../features/users/Users.api';
import SpaceSizes from '../../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../../../utils/tagger';
import { AlertCard } from '../../../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../../../components/AlertCard/AlertSeverity';
import { Animation } from '../../../../components/Animate/Animate';
import { AnimationEnum } from '../../../../components/Animate/Animate.constants';
import { CountrySelect } from '../../../../components/form/CountrySelect/CountrySelect';
import Form from '../../../../components/form/Form';
import { useAppDispatch, useAppSelector } from '../../../../hooks';

export type Inputs = {
  country_id: string;
  country_iso2: string;
};

const CountrySelector = ({
  setCheckedCountry,
  refSubmitButtom,
}: {
  setCheckedCountry: (state: boolean) => void;
  refSubmitButtom: React.RefObject<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: countries, isLoading: isLoadingGetCountries } =
    useGetCountriesQuery();
  const [patchUpdateUser] = useUpdateUserMutation();
  const [pickedCountryTemp, setPickedCountryTemp] = useState<string | null>(
    null,
  );
  const ipLocation = useAppSelector(selectLocationCountry);
  const defaultCountryToIp = countries?.find(
    (country) => country.iso2 === ipLocation,
  );

  const countryDefault = (name: string | null) => {
    if (name) {
      return countries?.find((country) => country.name === name);
    }

    return defaultCountryToIp;
  };
  const user = useAppSelector(selectUserSession);
  const sendDataToGTM = useGTMDispatch();

  const [alertCode, setAlertCode] = useState<string | null>(null);

  const methods = useForm<Inputs>();
  const { setValue } = methods;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // update user api call
    try {
      await patchUpdateUser({
        user_id: user?.user_id ?? '',
        data: {
          country_id: data.country_iso2,
        },
      }).unwrap();
      sendDataToGTM({
        event: TAG_EVENT.ONBOARDING,
        country: data.country_iso2,
      });
      // update redux store too
      dispatch(updateUser({ country_id: data.country_iso2 }));
    } catch (error) {
      setAlertCode('profileError');
    }
  };

  useEffect(() => {
    if (pickedCountryTemp) {
      setCheckedCountry(true);
    }
  }, [pickedCountryTemp, setCheckedCountry]);

  const handleCountryChange = async (country: Country) => {
    setValue('country_iso2', country.iso2);
    setValue('country_id', country.name, { shouldValidate: true });

    sendDataToGTM({
      event: TAG_EVENT.ONBOARDING.COUNTRY_SELECTION,
      country: country.iso2,
    });

    setPickedCountryTemp(country.name);
  };

  // Set default country value
  useEffect(() => {
    if (countries && !user?.country_id) {
      setValue('country_id', defaultCountryToIp?.name ?? '');
      if (defaultCountryToIp?.name) {
        handleCountryChange(defaultCountryToIp);
        setPickedCountryTemp(defaultCountryToIp?.name);
      }
    }

    if (
      countries &&
      user?.country_id &&
      user?.country_id !== defaultCountryToIp?.iso2
    ) {
      const newCountry = countries?.find(
        (country: Country) => country.iso2 === user?.country_id,
      );
      setValue('country_id', newCountry?.name ?? '');
      if (newCountry?.name) {
        handleCountryChange(newCountry);
        setPickedCountryTemp(newCountry?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setValue, countries]);

  return (
    <Box data-testid="onboardingCountrySelector">
      <Stack justifyContent="center" spacing={SpaceSizes.lg}>
        <Stack alignSelf="start">
          <Typography component="h2" fontWeight="bold" variant="h5">
            {t('pages.onboarding.selectCountry')}
          </Typography>
        </Stack>
        <AlertCard
          content={t(`pages.onboarding.errors.countryError`)}
          id="profileError"
          severity={
            alertCode?.includes('Error')
              ? AlertSeverity.ERROR
              : AlertSeverity.SUCCESS
          }
          show={isNotNilOrEmpty(alertCode)}
        />
        <Animation
          key="country-selector-form"
          sx={{
            position: 'relative',
          }}
          timeProps={{ delay: 0, duration: 0.25 }}
          transformProps={{ offsetY: '25px' }}
          type={AnimationEnum.Offset}
        >
          {!isLoadingGetCountries && (
            <Form methods={methods} onSubmit={onSubmit}>
              <CountrySelect
                countries={countries ?? []}
                defaultCountryToIp={countryDefault(pickedCountryTemp)}
                handleCountryChange={handleCountryChange}
                loading={isLoadingGetCountries}
                sx={{ '& .MuiTypography-root': { color: 'text.secondary' } }}
              />
              <button
                ref={refSubmitButtom}
                aria-label="hidden"
                id="confirm_country"
                type="submit"
                hidden
              />
            </Form>
          )}
          {isLoadingGetCountries && (
            <CircularProgress
              color="inherit"
              sx={{
                opacity: 0.5,
                position: 'absolute',
                transition: 'opacity .15s ease-in-out',
                top: '-20px',
                right: { xs: '45%' },
              }}
            />
          )}
        </Animation>
      </Stack>
    </Box>
  );
};

export default CountrySelector;
