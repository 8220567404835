import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import brandOptions from '../../brands/brandOptions';
import { DEFAULT_BRAND_ID } from './Brand.constants';
import { BrandState } from './Brand.slice';

export const selectBrand = (state: RootState) => state.brand;

export const selectCurrentBrand = createSelector(
  selectBrand,
  (brand: BrandState) =>
    brandOptions.find((brandData) => brandData.id === brand.currentBrand),
);

// returns "laliga" has default (null or "golball" brand)
export const selectCurrentBrandId = createSelector(
  selectBrand,
  (brand: BrandState) => {
    const brandId = brandOptions.find(
      (brandData) => brandData.id === brand.currentBrand,
    )?.id;
    return brandId === 'default' || !brandId ? DEFAULT_BRAND_ID : brandId;
  },
);
