import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetCheckoutSummaryQuery,
  useGetStatusQuery,
} from '../../../../features/checkout/Checkout.api';
import { CHECKOUT_STATUS } from '../../../../features/checkout/Checkout.constants';

export const useFetchData = () => {
  // State variables for component status
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isUninitialized, setIsUninitialized] = useState(true);

  // Polling flag and checkoutId from route parameters
  const [polling, setPolling] = useState<boolean>(true);
  const { checkoutId } = useParams();

  // Maximum polling duration set to 15 seconds
  const maxPollingTime = 15000;

  // useRef hook to hold the polling timeout reference
  const pollingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Callback to stop polling
  const stopPolling = useCallback(() => {
    setPolling(false);
    if (pollingTimeoutRef.current) clearTimeout(pollingTimeoutRef.current);
  }, [pollingTimeoutRef]);

  // Callback to stop polling and mark error after timeout
  const stopPollingDueToTimeoutOrFailure = useCallback(() => {
    stopPolling();
    setIsError(true);
  }, [stopPolling]);

  // Callback to start the timeout for polling
  const startPollingTimeout = useCallback(() => {
    pollingTimeoutRef.current = setTimeout(
      stopPollingDueToTimeoutOrFailure,
      maxPollingTime,
    );
  }, [stopPollingDueToTimeoutOrFailure, maxPollingTime]);

  // Effect hook to start polling timeout and cleanup on unmount
  useEffect(() => {
    startPollingTimeout();
    return () => {
      if (pollingTimeoutRef.current) clearTimeout(pollingTimeoutRef.current);
    };
  }, [startPollingTimeout]);

  // Fetch checkout data with polling based on checkoutId
  const {
    data: checkoutData,
    isLoading: checkoutIsLoading,
    isError: checkoutIsError,
    isUninitialized: checkoutIsUninitialized,
  } = useGetStatusQuery(checkoutId ?? skipToken, {
    pollingInterval: polling ? 1000 : 0,
  });

  // Fetch checkout summary
  const {
    data: checkoutSummaryData,
    isLoading: isLoadingSummary,
    isError: checkoutSummaryIsError,
  } = useGetCheckoutSummaryQuery(
    checkoutData?.status !== CHECKOUT_STATUS.COMPLETE ? skipToken : checkoutId,
  );

  // Combine fetched data
  const data = {
    checkoutResponse: checkoutData,
    checkoutSummaryResponse: checkoutSummaryData,
  };

  // Stop polling when reach a final state
  useEffect(() => {
    if (
      checkoutIsError ||
      checkoutSummaryIsError ||
      checkoutData?.status === CHECKOUT_STATUS.FAILED ||
      checkoutData?.status === CHECKOUT_STATUS.EXPIRED ||
      checkoutData?.status === CHECKOUT_STATUS.CANCELLED
    ) {
      stopPollingDueToTimeoutOrFailure();
    }
  }, [
    checkoutIsError,
    checkoutData,
    checkoutSummaryIsError,
    stopPollingDueToTimeoutOrFailure,
  ]);

  // When loading has finished, mark as not loading
  useEffect(() => {
    if (!isLoadingSummary && !checkoutIsLoading) setIsLoading(false);
  }, [checkoutIsLoading, isLoadingSummary]);

  // When data is fetched, mark as initialized
  useEffect(() => {
    if (!checkoutIsUninitialized) setIsUninitialized(false);
  }, [checkoutIsUninitialized]);

  // Return state and data for use in component
  return {
    isLoading,
    isError,
    isUninitialized,
    data,
    stopPolling,
  };
};
