import { Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect } from 'react';

import { setMyCollectionFilter } from '../../../features/searchfilter/mycollection/MyCollectionFilter.slice';
import { selectUserSession } from '../../../features/session/Session.selector';
import { useGetUserPreferenceQuery } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { BallsOwned } from '../../components/BallsOwned/BallsOwned';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { EmptyResults } from '../../components/EmptyResults/EmptyResults';
import { StatesEmptyResults } from '../../components/EmptyResults/EmptyResults.constant';
import { PageError } from '../../components/PageError/PageError';
import { Presentation } from '../../components/profile/Presentation/Presentation';
import { SearchResults } from '../../components/search/SearchResults/SearchResults';
import { useDeliver } from '../../components/search/SearchResults/useDeliver';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useSearchMyCollectionWithInfiniteScroll from '../../hooks/search/useSearchMyCollectionWithInfiniteScroll';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import { MyAuctionsSkeleton } from './MyAuctions.skeleton';

const MyAuctions = () => {
  const { t } = useTranslationByNamespaces('pages.myAuctions');
  const dispatch = useAppDispatch();
  const isDesktop = useDesktopDeviceMediaQuery();
  const user = useAppSelector(selectUserSession);

  const {
    data: dataPreference,
    isLoading: isLoadingPreference,
    isUninitialized: isUninitializedPreference,
    isError: isErrorPreference,
  } = useGetUserPreferenceQuery(user ? { user_id: user?.user_id } : skipToken);

  const {
    data: dataCollections,
    isLoading: isLoadingCollections,
    isUninitialized: isUninitializedCollections,
    isError: isErrorCollections,
    enterWaypoint,
    resetOffset,
    isLastPage,
  } = useSearchMyCollectionWithInfiniteScroll();

  const { handleDeliver, isTransferringCard, isDeliveryModalDisabled } =
    useDeliver(dataCollections?.results);

  useEffect(() => {
    dispatch(setMyCollectionFilter(user?.user_id ?? ''));
  }, [dispatch, user]);

  if (isErrorPreference || isErrorCollections)
    return <PageError errorCode={t('errors.GENERIC_ERROR')} />;

  if (
    isUninitializedPreference ||
    isLoadingPreference ||
    isUninitializedCollections ||
    isLoadingCollections
  )
    return <MyAuctionsSkeleton />;

  return (
    <Stack>
      <Presentation
        isLoading={isLoadingPreference}
        team={dataPreference.team}
        user={dataPreference.user}
      />
      <PageContainer
        id="profilePublic"
        title={t('pageTitle')}
        trackCode={TAG_EVENT.CONTENT_GROUP.AUCTIONS}
      >
        <Stack gap={SpaceSizes.xs} width="100%">
          <Stack
            alignItems="center"
            direction={isDesktop ? 'column' : 'row'}
            justifyContent="space-between"
          >
            <Stack>
              {dataPreference.user.is_self && (
                <BallsOwned
                  isLoading={isLoadingCollections}
                  isTransferringCard={isTransferringCard}
                />
              )}
            </Stack>
          </Stack>
          <Stack
            component="main"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {dataCollections && dataCollections?.results?.length > 0 ? (
              <SearchResults
                enterWaypoint={enterWaypoint}
                handleDeliver={handleDeliver}
                isDeliveryModalDisabled={isDeliveryModalDisabled}
                isLastPage={isLastPage}
                isSelf={dataPreference.user.is_self}
                refetch={() => resetOffset()}
                results={dataCollections?.results}
              />
            ) : (
              <EmptyResults origin={StatesEmptyResults.AUCTIONS} t={t} />
            )}
          </Stack>
        </Stack>
      </PageContainer>
    </Stack>
  );
};

export default MyAuctions;
