import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { AvatarInputFile } from '../AvatarInputFile/AvatarInputFile';
import { ProfileHeaderProps } from './ProfileHeader.types';

export const ProfileHeader = ({ user, setAlertCode }: ProfileHeaderProps) => {
  return (
    <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
      <Stack>
        <Typography fontWeight="bold" variant="h6">
          {user?.first_name} {user?.last_name}
        </Typography>
        <Typography variant="h6">{user?.nickname}</Typography>
      </Stack>
      <Stack alignItems="flex-end" direction="row" spacing={SpaceSizes.sm}>
        <AvatarInputFile onSuccess={() => setAlertCode('avatarSuccess')} />
      </Stack>
    </Stack>
  );
};
