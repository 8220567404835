import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { addURLParamByKey } from '../../../features/router/router.utils';
import { selectUserSession } from '../../../features/session/Session.selector';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { GolButton } from '../../components/GolButton/GolButton.component';
import { PreferencesSection } from '../../components/profile/PreferencesSection/PreferencesSection';
import { ProfileHeader } from '../../components/profile/ProfileHeader/ProfileHeader';
import { SecuritySection } from '../../components/profile/SecuritySection/SecuritySection';
import { UserForm } from '../../components/profile/UserForm/UserForm';
import { useAppSelector } from '../../hooks';
import { TABS } from './Configuration.constant';

const Configuration = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const user = useAppSelector(selectUserSession);
  const [alertCode, setAlertCode] = useState<string | null>(null);
  const [value, setValue] = useState(params.get('tab') ?? '1');
  const [isShowing, setIsShowingModal] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setAlertCode('');
    addURLParamByKey('tab', newValue);
  };

  const handleToggleModal = useCallback(() => {
    setIsShowingModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (params.get('alert')) {
      setAlertCode(params.get('alert'));
    }
  }, [params]);

  return (
    <PageContainer
      id="profilePage"
      maxWidth="sm"
      title={t('pages.profile.title')}
      trackCode={TAG_EVENT.CONTENT_GROUP.PROFILE}
      backButton
    >
      <Stack spacing={SpaceSizes.md} width="100%">
        <Dialog open={isShowing}>
          <DialogTitle>{t('pages.profile.phoneModal.title')}</DialogTitle>
          <DialogContent>
            <Stack
              alignItems="center"
              gap="20px"
              justifyContent="center"
              mt="20px"
            >
              <Typography variant="body1">
                {t('pages.profile.phoneModal.description')}
              </Typography>
              <GolButton
                value={t('pages.profile.phoneModal.buttonTitle')}
                onClick={handleToggleModal}
              />
            </Stack>
          </DialogContent>
        </Dialog>
        <ProfileHeader setAlertCode={setAlertCode} user={user} />
        {isNotNilOrEmpty(alertCode) && (
          <AlertCard
            content={t(`pages.profile.alerts.${alertCode}`)}
            id="profileError"
            severity={
              alertCode?.includes('Error')
                ? AlertSeverity.ERROR
                : AlertSeverity.SUCCESS
            }
            show={isNotNilOrEmpty(alertCode)}
          />
        )}
        <TabContext value={value}>
          <Stack
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <TabList variant="scrollable" onChange={handleTabChange}>
              {TABS.map((option, index) => (
                <Tab
                  key={option}
                  label={t(`pages.profile.tabs.${option}`)}
                  sx={{ color: 'text.primary' }}
                  value={(index + 1).toString()}
                />
              ))}
            </TabList>
          </Stack>
          <TabPanel value="1">
            <UserForm
              setAlertCode={setAlertCode}
              setIsShowing={setIsShowingModal}
              user={user}
            />
          </TabPanel>
          <TabPanel value="2">
            <SecuritySection />
          </TabPanel>
          <TabPanel value="3">
            <PreferencesSection setAlertCode={setAlertCode} />
          </TabPanel>
        </TabContext>
      </Stack>
    </PageContainer>
  );
};

export default Configuration;
