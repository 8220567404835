import { Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';

const SelectDate = ({
  date,
  setDate,
}: {
  date: Date | null;
  setDate: (value: Date | null) => void;
}) => {
  return (
    <Stack maxWidth="100%" spacing={SpaceSizes.xxs}>
      <LocalizationProvider adapterLocale={enGB} dateAdapter={AdapterDateFns}>
        <DatePicker value={date} onChange={(newDate) => setDate(newDate)} />
      </LocalizationProvider>
    </Stack>
  );
};

export default SelectDate;
