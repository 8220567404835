import { styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { notificationsDateDiff } from '../../../utils/date';
import { NotificationDateProps } from './NotificationDate.types';

const NotificationDateStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isShown',
})<{ isShown: boolean }>`
  ${({ isShown }) =>
    isShown &&
    `
    font-weight: semibold;  
    text-decoration: underline;
  `}
`;

export const NotificationDate = ({
  isShown,
  createdDt,
}: NotificationDateProps) => {
  const diff = notificationsDateDiff(new Date(createdDt), new Date());
  const { t } = useTranslation();

  return (
    <NotificationDateStyled isShown={isShown}>
      {diff.value
        ? t(`header.userMenu.notifications.date.${diff.unit}`, {
            count: diff.value,
          })
        : t(`header.userMenu.notifications.date.a_time_ago`)}
    </NotificationDateStyled>
  );
};
