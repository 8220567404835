import {
  CollectionListItem,
  CollectionMultimedia,
} from '../../../features/collections/Collections.types';
import { CarouselImage } from './CollectionSelector.types';

export const convertToCarouselImg = (
  collectionListItems: CollectionListItem[],
): CarouselImage[] => {
  const imageProps: CarouselImage[] = [];

  collectionListItems.forEach((collectionListItem) => {
    const horizontalMultimedia = collectionListItem.multimedia.find(
      (media: CollectionMultimedia) => media.type === 'HORIZONTAL',
    );
    const verticalMultimedia = collectionListItem.multimedia.find(
      (media: CollectionMultimedia) => media.type === 'VERTICAL',
    );

    if (verticalMultimedia) {
      imageProps.push({
        id: collectionListItem.collection_id,
        image: verticalMultimedia.location,
        avatar: horizontalMultimedia?.location,
        title: collectionListItem.title,
      });
    }
  });

  return imageProps;
};

export const handleCarouselNavigation = (
  index: number,
  maxIndex: number,
  target: -1 | 1,
): number => {
  if (index === 0 && target === -1) {
    return maxIndex;
  }
  if (index + target > maxIndex) {
    return 0;
  }
  return index + target;
};
