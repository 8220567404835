import { Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { ModelViewer } from '../ModelViewer/ModelViewer';
import { ICertificateTabs } from './CertificateTabs.types';
import LockedFeatureOverlay from './LockedFeatureOverlay';
import { ProductCertificatesTabsWidgetSkeleton } from './ProductCertificatesTabsWidget.skeleton';

const StyledTab = styled(Tab)({
  borderRadius: `${SpaceSizes.smPlus}px ${SpaceSizes.smPlus}px 0px 0px`,
  textTransform: 'none',
  '&.Mui-selected': {
    borderBottom: 0,
    fontWeight: 'normal',
  },
});

const TabContent = ({
  content,
  index,
  tabValue,
}: {
  content: React.ReactNode;
  index: number;
  tabValue: number;
}) => {
  return (
    <div
      aria-labelledby={`tab-${index}`}
      hidden={tabValue !== index}
      role="tabpanel"
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      {content}
    </div>
  );
};

export const CertificatesTabs = ({
  product3dCertificateMultimedia,
  product3dMultimedia,
  isLocked = false,
  isMissing = false,
  isLoading,
  productBallId,
}: ICertificateTabs) => {
  const { t } = useTranslationByNamespaces('components');
  const isMobile = useMobileMediaQuery();
  const [tabValue, setTabValue] = useState(0);

  return isLoading ? (
    <ProductCertificatesTabsWidgetSkeleton />
  ) : (
    <Stack>
      <Tabs
        TabIndicatorProps={{
          hidden: true,
        }}
        value={tabValue}
        variant={isMobile ? 'fullWidth' : 'standard'}
        onChange={(_, newValue) => setTabValue(newValue)}
      >
        <StyledTab
          data-testid="components.productTabs.tabs.CERTIFICATE"
          id="digital_cert"
          label={t('productTabs.tabs.CERTIFICATE')}
          sx={{
            backgroundColor:
              tabValue === 0 ? 'background.paper' : 'background.default',
          }}
        />
        <StyledTab
          data-testid="components.productTabs.tabs.BALL"
          id="3d_ball"
          label={t('productTabs.tabs.BALL')}
          sx={{
            backgroundColor:
              tabValue === 1 ? 'background.paper' : 'background.default',
          }}
        />
      </Tabs>
      {product3dCertificateMultimedia && (
        <TabContent
          content={
            <LockedFeatureOverlay
              data-testid="certificate-tab"
              isLocked={isLocked}
            >
              <ModelViewer
                poster={product3dCertificateMultimedia.thumb_location}
                src={product3dCertificateMultimedia.location}
              />
            </LockedFeatureOverlay>
          }
          index={0}
          tabValue={tabValue}
        />
      )}
      {product3dMultimedia && (
        <TabContent
          content={
            <LockedFeatureOverlay
              data-testid="3d-tab"
              isLocked={isLocked}
              isMissing={isMissing}
            >
              <ModelViewer
                poster={product3dMultimedia.thumb_location}
                src={product3dMultimedia.location}
              />
            </LockedFeatureOverlay>
          }
          index={1}
          tabValue={tabValue}
        />
      )}
      <Typography
        color="text.secondary"
        sx={{ mt: 2 }}
        variant={isMobile ? 'caption' : 'body2'}
      >
        #{productBallId}
      </Typography>
    </Stack>
  );
};
