import React, { createContext, useContext } from 'react';

import { IProduct } from '../../features/products/Product.types';
import { ProductProviderProps } from './ProductProvider.types';

const ProductContext = createContext<IProduct | undefined>(undefined);

export const ProductProvider = ({
  product,
  children,
}: ProductProviderProps) => {
  return (
    <ProductContext.Provider value={product}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);
