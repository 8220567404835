import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSliceState } from '../commons/types';

export interface FansPreferencesState extends BaseSliceState {
  players: string[] | null;
  teams: string[] | null;
  leagues: string[] | null;
}

const initialState = {
  teams: null,
  leagues: null,
  players: null,
} as FansPreferencesState;

export interface FansPreferencesPayload {
  players: string[];
  teams: string[];
  leagues: string[];
}

const fansPreferencesSlice = createSlice({
  name: 'fansPreferences',
  initialState,
  reducers: {
    setNewPreferences(state, action: PayloadAction<FansPreferencesPayload>) {
      state.teams = action.payload.teams;
      state.leagues = action.payload.leagues;
      state.players = action.payload.players;
    },
  },
});
export const { setNewPreferences } = fansPreferencesSlice.actions;
export default fansPreferencesSlice.reducer;
