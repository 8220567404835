import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import env from '../../../env/env';
import { useGetTradeIdDetailQuery } from '../../../features/trades/Trades.api';
import { OfferedItem } from '../../../features/trades/Trades.types';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';

const anonymousUserUrl = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/anonymous-user.png`;

export const useTradeDetail = () => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const { txId } = useParams();
  const {
    data: detail,
    isLoading: loadingDetail,
    refetch: refetchDetail,
    isError: errorDetail,
  } = useGetTradeIdDetailQuery(txId || 'cancelled');

  const country = useMemo(
    () =>
      `${
        env.REACT_APP_STATIC_BASE_URL
      }marketplace/img/commons/countries/${detail?.user?.country_id?.toLowerCase()}.svg`,
    [detail?.user?.country_id],
  );

  const userId = detail?.user.user_id;

  const profilePic = useMemo(() => {
    return detail?.user?.avatar_url || anonymousUserUrl;
  }, [detail?.user?.avatar_url]);

  const userName = useMemo(() => {
    return detail?.user?.nickname || t('anonymous');
  }, [detail?.user?.nickname, t]);

  const generatedOffer = useCallback((): OfferedItem | undefined => {
    if (detail) {
      return {
        match: detail.match,
        events: detail.events,
        player: detail.player,
        ball_id: detail.ball_id,
        brand_id: detail.brand_id,
        piece_number: detail.tradable_amount,
        product_id: detail.product_id,
      };
    }
    return undefined;
  }, [detail]);

  const offer = useMemo(() => {
    return generatedOffer();
  }, [generatedOffer]);

  return {
    country,
    profilePic,
    userName,
    userId,
    loadingDetail,
    offer,
    refetchDetail,
    errorDetail,
  };
};
