import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { NotificationMessage } from './NotificationContent.types';

export const NotificationContent = ({
  message,
}: {
  message: NotificationMessage;
}) => {
  if (typeof message === 'object') {
    return (
      <Stack flexDirection="column" gap={SpaceSizes.xs}>
        <Typography fontWeight="bold">{message.title}</Typography>
        <Typography variant="body2">{message.body}</Typography>
      </Stack>
    );
  }
  return <Typography>{message}</Typography>;
};
