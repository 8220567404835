import { Button, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import env from '../../../env/env';
import { geti18nResolvedLanguage } from '../../i18n';

const AppleWalletButton = styled(Button)(() => ({
  backgroundColor: 'transparent',
  marginTop: 4,
  marginBottom: 4,
  padding: 0,
  minWidth: '180px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const StyledImg = styled('img')({});

const AddToWalletButton = ({ onClick, ...rest }: { onClick: () => void }) => {
  const { t } = useTranslation();

  const svgUrl = `${
    env.REACT_APP_STATIC_BASE_URL
  }marketplace/img/commons/icons/apple/${geti18nResolvedLanguage()}_button.svg`;

  return (
    <AppleWalletButton
      size="large"
      variant="contained"
      onClick={onClick}
      {...rest}
    >
      <StyledImg
        alt={t('pages.collectionDetails.wallet.button')}
        src={svgUrl}
        sx={{ width: { xs: '70%', sm: '100%' }, height: '100%' }}
      />
    </AppleWalletButton>
  );
};

export default AddToWalletButton;
