import { Stack, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { MOVEMENT_TABS } from './Movements.constants';
import { MovementsContent } from './MovementsContent';
import { MovementTabs } from './MovementsContent.constants';

export const Movements = () => {
  const { t } = useTranslationByNamespaces('pages.movements');
  const [currentTab, setCurrentTab] = useState<MovementTabs>(MovementTabs.BUYS);

  return (
    <PageContainer
      id="matchesPage"
      loading={false}
      sx={{ my: SpaceSizes.sm }}
      title={t('title')}
      backButton
    >
      <Stack
        alignItems="start"
        gap={SpaceSizes.lg}
        marginY={SpaceSizes.lg}
        width="100%"
      >
        <Tabs
          sx={{
            borderBottom: 1,
            width: '100%',
            borderColor: 'text.secondary',
          }}
          value={currentTab}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {MOVEMENT_TABS.map((tab) => {
            return (
              <Tab
                key={tab}
                label={t(`panel.${tab}`)}
                sx={{ color: 'text.primary', width: '50%' }}
                value={tab}
                onClick={() => setCurrentTab(tab as MovementTabs)}
              />
            );
          })}
        </Tabs>
        <MovementsContent tab={currentTab} />
      </Stack>
    </PageContainer>
  );
};
