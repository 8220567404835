import {
  Autocomplete,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { GolHelperText } from '../GolHelperText/GolHelperText.component';
import { FormSelectAutoProps } from './FormSelectAuto.types';

export const FormSelectAuto = ({
  id,
  label,
  disabled,
  required = true,
  labelAlign = 'left',
  placeholder,
  onChange,
  options,
  getOptionLabel,
  renderOption,
  noOptionsText,
  errorMessage,
  defaultValue,
  loading = false,
  sx,
}: FormSelectAutoProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = errors[id];

  const theme = useTheme();

  return (
    <Stack gap={SpaceSizes.xs} width="100%">
      <Typography color="text.secondary" textAlign={labelAlign}>
        {label}
      </Typography>
      {loading && <Skeleton sx={{ height: '47px' }} />}
      {!loading && (
        <Controller
          control={control}
          name={id}
          render={({ field: { ref } }) => (
            <Autocomplete
              disabled={disabled}
              getOptionLabel={getOptionLabel}
              id={id}
              loading={loading}
              loadingText="..."
              noOptionsText={noOptionsText}
              options={options}
              renderInput={(props) => (
                <TextField
                  {...props}
                  ref={ref}
                  autoComplete="off"
                  error={isNotNilOrEmpty(hasError)}
                  helperText={hasError ? errorMessage : ''}
                  inputProps={{
                    ...props.inputProps,
                    'data-testid': id,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...props.InputProps,
                    style: { borderRadius: '8px' },
                  }}
                  placeholder={placeholder}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      color: theme.palette.text.secondary,
                    },
                    ...sx,
                  }}
                  variant="outlined"
                />
              )}
              renderOption={renderOption}
              value={defaultValue}
              disableClearable
              onChange={onChange}
            />
          )}
          rules={{ required }}
        />
      )}
      {hasError && errorMessage && (
        <GolHelperText testId={`${id}HelperError`} value={errorMessage} />
      )}
    </Stack>
  );
};
