import { useMediaQuery } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ICollectionItems } from '../../../features/collections/Collections.types';
import useLaptopMediaQuery from '../../hooks/useLaptopMediaQuery';
import useMediumMobileMediaQuery from '../../hooks/useMediumMobileMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { PER_PAGE_DISPLAY } from '../collections/Collections.constants';

export const useCollectionPagination = (data: ICollectionItems[]) => {
  const { collectionId } = useParams();
  const [pageAmount, setPageAmount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(PER_PAGE_DISPLAY);
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const isSmallMobile = useSmallMobileMediaQuery();
  const isMediumMobile = useMediumMobileMediaQuery();
  const isLargeMobile = useMediaQuery(
    `(min-width: ${780}px) and (max-width: ${900}px) `,
  );
  const isLargeDevice = useMediaQuery(
    `(min-width: ${960}px) and (max-width: ${1200}px)`,
  );
  const isMediumDevice = useMediaQuery(
    `(min-width: ${900}px) and (max-width: ${1000}px)`,
  );
  const isLargeDesktop = useMediaQuery(`(min-width: ${1900}px)`);
  const isMediumDesktop = useMediaQuery(`(min-width: ${1550}px)`);
  const isDesktop = useLaptopMediaQuery();

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const handleAmountPerPage = useCallback((): number => {
    if (isMediumMobile) {
      return 6;
    }
    if (isSmallMobile) {
      return 4;
    }
    if (isLargeDevice) {
      return 10;
    }
    if (isDesktop || isLargeMobile || isMediumDevice) {
      return 8;
    }
    if (isLargeDesktop) {
      return 14;
    }
    if (isMediumDesktop) {
      return 10;
    }
    return PER_PAGE_DISPLAY;
  }, [
    isSmallMobile,
    isLargeDevice,
    isDesktop,
    isLargeMobile,
    isMediumDevice,
    isLargeDesktop,
    isMediumDesktop,
    isMediumMobile,
  ]);

  useEffect(() => {
    setItemsPerPage(handleAmountPerPage());
  }, [handleAmountPerPage, setItemsPerPage]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  useEffect(() => {
    const amount = Math.ceil(Number(data?.length) / itemsPerPage);
    setPageAmount(amount);
  }, [setPageAmount, data?.length, itemsPerPage]);

  useEffect(() => {
    setIsPreviewVisible(false);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [collectionId, setCurrentPage]);

  useEffect(() => {
    setIsPreviewVisible(Boolean(currentPageData?.length < itemsPerPage));
  }, [currentPageData, itemsPerPage]);
  return {
    pageAmount,
    isPreviewVisible,
    currentPage,
    handlePageChange,
    currentPageData,
    itemsPerPage,
  };
};
