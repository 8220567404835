import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import {
  COLORS_PER_TEAM,
  CONFETTI_ANIMATION,
} from './ConfettiCelebration.constants';
import { IConfettiProps, TColorKey } from './ConfettiCelebration.types';

export function ConfettiCelebration({
  customWidth,
  customHeight,
  customOpacity,
  team,
  duration = 8000,
}: IConfettiProps) {
  const [runConfetti, setRunConfetti] = useState(true);
  const width = customWidth || document.body.scrollWidth;
  const height = customHeight || document.body.scrollHeight;
  const opacity = customOpacity || 0.8;

  const colorArray = team ? COLORS_PER_TEAM[team as TColorKey] : undefined;

  useEffect(() => {
    const timer = setTimeout(() => {
      setRunConfetti(false);
    }, duration);
    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <AnimatePresence>
      {runConfetti && (
        <motion.div
          animate="animate"
          exit="exit"
          initial="initial"
          style={{ zIndex: 10 }}
          variants={CONFETTI_ANIMATION}
        >
          <Confetti
            colors={colorArray}
            data-testid="confetti"
            height={height}
            opacity={opacity}
            width={width}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
