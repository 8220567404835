import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const DIALOG_TITLE_FONT_SIZE = {
  xs: 16,
  sm: 18,
  md: 20,
  lg: 24,
  xl: 26,
};
export const DIALOG_DESCRIPTION_FONT_SIZE = {
  xs: 13,
  sm: 14,
  md: 15,
  lg: 16,
  xl: 18,
};

export const DIALOG_CTA_PADDING = {
  xs: SpaceSizes.sm,
  sm: SpaceSizes.smPlus,
  lg: SpaceSizes.md,
};
