/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import Env from '../../../env/env';
import Spinner from '../../components/Spinner';
import useInterval from '../../hooks/useInterval';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useScript from '../../hooks/useScript';
import { geti18nResolvedLanguage } from '../../i18n';
import useGoogleSignIn from './useGoogleSignIn';

const opts = (
  isSignup?: boolean,
  isMobile?: boolean,
): GsiButtonConfiguration => ({
  type: 'standard',
  theme: 'outline',
  size: 'large',
  text: isSignup ? 'continue_with' : 'continue_with',
  shape: 'circle',
  logo_alignment: 'center',
  width: isMobile ? '280' : '343',
  locale: geti18nResolvedLanguage(),
});

const clientId = Env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleSignInButton = ({
  isSignup,
  referralCode,
}: {
  isSignup?: boolean;
  referralCode?: string;
}) => {
  useScript('https://accounts.google.com/gsi/client', 'gsi-client');
  const divRef = useRef<HTMLDivElement>(null);
  const handleLoginPress = useGoogleSignIn(referralCode);

  const [google, setGoogle] = useState<any>();
  const [googleIsLoading, setGoogleIsLoading] = useState(true);
  const [googleIsLoaded, setGoogleIsLoaded] = useState(false);

  const isMobile = useMobileMediaQuery();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useInterval(
    () => {
      if (typeof window !== 'undefined' && window.google) {
        setGoogle(window.google);
        setGoogleIsLoading(false);
      }
    },
    googleIsLoading ? 100 : null,
  );

  useEffect(() => {
    if (!googleIsLoaded && !googleIsLoading && divRef.current) {
      google?.accounts?.id?.initialize({
        client_id: clientId,
        callback: async (res: CredentialResponse) => {
          if (res.credential) {
            setIsLoggingIn(true);
            await handleLoginPress(res.credential);
          }
        },
      });
      setGoogleIsLoaded(true);
    }
  }, [
    divRef?.current?.ATTRIBUTE_NODE,
    google,
    googleIsLoaded,
    googleIsLoading,
    handleLoginPress,
    isSignup,
  ]);

  useEffect(() => {
    if (!googleIsLoading && googleIsLoaded && google && divRef.current)
      google?.accounts?.id?.renderButton(
        divRef.current,
        opts(isSignup, isMobile),
      );
  }, [isMobile, google, isSignup, googleIsLoading, googleIsLoaded]);

  return googleIsLoading || isLoggingIn ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : (
    <div
      ref={divRef}
      data-testid="googleSignupButton"
      id="google_signup"
      style={{ display: 'flex', justifyContent: 'center' }}
    />
  );
};

export default GoogleSignInButton;
