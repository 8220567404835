import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { CollectionsByGroup } from './Collections.slice';
import { CollectionListItem } from './Collections.types';

export const selectCollection = (collection: CollectionListItem | undefined) =>
  collection;

export const selectHorizontalMultimedia = (
  collection: CollectionListItem | undefined,
) =>
  collection?.multimedia.find((item) => item.type === 'HORIZONTAL')?.location ||
  '';

export const selectCollectionsByGroup = createSelector(
  (state: RootState): CollectionsByGroup => state.collections,
  (collectionGroup) => {
    return collectionGroup;
  },
);
