/* eslint-disable @typescript-eslint/no-namespace */
import '@google/model-viewer';

import { useTheme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ModelViewerProps } from './ModelViewer.types';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': ModelViewerAttributes;
    }
    interface ModelViewerAttributes {
      ar: boolean;
      src: string;
      poster: string;
      children?: JSX.Element;
      style: Record<string, unknown>;
    }
  }
}

export const ModelViewer = ({
  src,
  poster,
  shadowIntensity = 0.5,
  borderRadius = true,
  height = '420px',
}: ModelViewerProps) => {
  const theme = useTheme();

  return (
    <model-viewer
      ar-modes="webxr quick-look"
      data-testid="model-viewer"
      poster={poster}
      shadow-intensity={shadowIntensity}
      src={src}
      style={{
        width: '100%',
        height,
        borderBottomLeftRadius: borderRadius ? `${SpaceSizes.smPlus}px` : '0px',
        borderBottomRightRadius: borderRadius
          ? `${SpaceSizes.smPlus}px`
          : '0px',
        backgroundColor: theme.palette.background.paper,
      }}
      ar
      camera-controls
      enable-pan
    />
  );
};
