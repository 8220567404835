import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, Stack } from '@mui/material';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetMatchesByTournamentResponse } from '../../../features/tournaments/Tournaments.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useFetchData } from '../../pages/matches/useFetchData';
import { SelectDates } from '../commons/Selects/SelectDates';
import { MatchesByTournament } from '../MatchesByTournament/MatchesByTournament';
import { NoResults } from '../search/NoResults/NoResults';
import { TournamentMatchesSelectorSkeleton } from './TournamentMatchesSelector.skeleton';
import { TournamentMatchesSelectorProps } from './TournamentMatchesSelector.types';

export const TournamentMatchesSelector = ({
  handleModal,
  setHandleModal,
  setDates,
}: TournamentMatchesSelectorProps) => {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const {
    data,
    setData,
    getMatchesByTournament,
    getMatchesByTournamentIsLoading,
    getMatchesByTournamentIsError,
  } = useFetchData();

  const state = {
    fromDate,
    toDate,
  };

  const setState = {
    setDates,
    setFromDate,
    setToDate,
    setResponse: setData,
    setOpenModal: setHandleModal,
  };

  const handleAction = async () => {
    if (!(fromDate && toDate)) return;

    try {
      const response = await getMatchesByTournament({
        from: format(fromDate, 'yyyy-MM-dd'),
        to: format(toDate, 'yyyy-MM-dd'),
      }).unwrap();

      setData(response);
    } catch (e) {
      setData([]);
    }

    setDates({
      from: format(fromDate, 'dd-MMM'),
      to: format(toDate, 'dd-MMM'),
      enabled: true,
    });

    setHandleModal(false);
  };

  if (getMatchesByTournamentIsLoading || !data)
    return <TournamentMatchesSelectorSkeleton />;

  return (
    <Stack alignItems="center" gap={SpaceSizes.md} width="100%">
      <Stack flexDirection="column" justifyContent="center" width="100%">
        {(getMatchesByTournamentIsError || data?.length === 0) && (
          <NoResults
            subtitle={t('pages.matches.noResults.subtitle')}
            title={t('pages.matches.noResults.title')}
          />
        )}

        {!getMatchesByTournamentIsError &&
          data?.map((league: GetMatchesByTournamentResponse, index) => (
            <Stack
              key={`matches-by-tournament-${league.tournament_id}`}
              flexDirection="column"
              justifyContent="center"
            >
              <MatchesByTournament
                {...league}
                openFirstTournament={index === 0}
              />
            </Stack>
          ))}
      </Stack>
      <Dialog open={handleModal} sx={{ borderRadius: SpaceSizes.sm }} fullWidth>
        <Stack bgcolor="background.paper">
          <Box display="flex" justifyContent="right">
            <IconButton onClick={() => setHandleModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <SelectDates
            handleSearch={handleAction}
            setState={setState}
            state={state}
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};
