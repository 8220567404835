import 'swiper/swiper-bundle.min.css';
import './ProductSwiper.css';

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Stack } from '@mui/material';
import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';

const ProductSwiper = ({
  images,
  video,
}: {
  images: (string | undefined)[] | undefined;
  video?: string | undefined;
}) => {
  const { t } = useTranslationByNamespaces('pages.product.productSection');

  const lastSlide =
    images && video ? (
      <SwiperSlide key="video-slide" className="last-slide">
        <Stack
          alignItems="center"
          display="flex"
          gap={10}
          justifyContent="center"
          sx={{
            height: '100%',
            backgroundImage: `url(${images?.[images.length - 1]})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 12,
              opacity: '60%',
              color: 'black',
              padding: '0px 15px',
            }}
          >
            {t('goalReplay')}
          </div>
          <a href={video} rel="noopener noreferrer" target="_blank">
            <OndemandVideoIcon htmlColor="white" sx={{ fontSize: 80 }} />
          </a>
        </Stack>
      </SwiperSlide>
    ) : null;

  return (
    <Swiper modules={[Pagination]} pagination={{ clickable: true }}>
      {images?.map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>
          <img alt="" className="object-fit-cover" src={image} />
        </SwiperSlide>
      ))}
      {lastSlide}
    </Swiper>
  );
};

export default ProductSwiper;
