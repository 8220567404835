import { Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  CERTIFICATE_HEIGHT,
  TABS_BUTTON_SKELETON_HEIGHT,
} from './ProductCertificatesTabsWidget.constants';

export const ProductCertificatesTabsWidgetSkeleton = () => {
  return (
    <Stack gap={SpaceSizes.xs} justifyContent="center" spacing={SpaceSizes.sm}>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={SpaceSizes.md}
        sx={{ borderRadius: SpaceSizes.xs }}
      >
        {Array.from({ length: 2 }, (_, index) => (
          <Skeleton
            key={`skeleton_certificates_${_}${index}`}
            height={TABS_BUTTON_SKELETON_HEIGHT}
            sx={{ borderRadius: SpaceSizes.xxs }}
            variant="rectangular"
            width="100%"
          />
        ))}
      </Stack>
      <Skeleton
        height={CERTIFICATE_HEIGHT}
        sx={{ borderRadius: SpaceSizes.xs }}
        variant="rounded"
      />
    </Stack>
  );
};
