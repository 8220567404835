import { Button, Stack } from '@mui/material';
import React, { useCallback, useContext } from 'react';

import { NotificationStatus } from '../../../../features/notifications/responses/Notification';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import {
  buttonTriviaStyled,
  StyledNotificationImage,
} from '../Notification.styled';
import {
  NotificationType,
  TriviaNotificationProps,
} from '../Notification.types';
import NotificationAlert from '../NotificationAlert';
import { NotificationContent } from '../NotificationContent';
import { NotificationContext } from '../NotificationContext';
import { NotificationDate } from '../NotificationDate';
import { NotificationLayout } from '../NotificationLayout';

export const TriviaNotification = ({
  notification,
}: TriviaNotificationProps) => {
  const context = useContext(NotificationContext);
  const { t } = useTranslationByNamespaces('header.userMenu.notifications');

  const notificationType = notification.type || 'UNHANDLED';

  const productTitle = notification?.extra_data?.product_title || '';
  const roundNumber = notification?.extra_data?.round_number?.toString() || '';
  const shouldShowCTA =
    notification.type === NotificationType.BATTLE_STARTED ||
    notification.type === NotificationType.NEXT_ROUND_STARTED;

  const notificationMessage = t(`type.${notificationType}`, {
    productTitle,
    roundNumber,
  });

  const navigateToTrivia = useCallback(() => {
    const { close } = context || {};
    if (close) close();

    // Asignar la nueva URL
    window.location.href = `/trivia/${notification?.extra_data?.battle_id}`;
  }, [context, notification?.extra_data?.battle_id]);

  return (
    <NotificationLayout>
      <Stack
        alignItems="flex-start"
        direction="column"
        justifyContent="space-between"
        spacing={SpaceSizes.sm}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={SpaceSizes.sm}
        >
          {notification.thumbnail && (
            <StyledNotificationImage src={notification.thumbnail} />
          )}

          <NotificationContent message={notificationMessage} />
        </Stack>
        {notification.status_code === NotificationStatus.SHOWN && (
          <NotificationAlert />
        )}
        {notification.extra_data?.battle_id && shouldShowCTA ? (
          <Button
            size="small"
            sx={buttonTriviaStyled}
            variant="contained"
            onClick={navigateToTrivia}
          >
            {t('trivia.details')}
          </Button>
        ) : null}
      </Stack>

      <Stack direction="row" justifyContent="flex-end">
        <NotificationDate
          createdDt={notification.created_dt}
          isShown={notification.status_code === NotificationStatus.SHOWN}
        />
      </Stack>
    </NotificationLayout>
  );
};
