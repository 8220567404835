import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Button, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_TYPE } from '../../../../utils/tagger';
import { Badge } from '../../../components/Badge/Badge';

interface Props {
  text: string;
  to: string;
  isActive?: boolean;
  icon?: ReactNode;
  setIsDropdownOpen: (isOpen: boolean) => void;
  tagId?: string;
  newBadge?: boolean;
}

const MenuDropdownItem = ({
  text,
  to,
  isActive = false,
  icon,
  tagId,
  setIsDropdownOpen,
  newBadge,
  ...props
}: Props) => {
  const sendDataToGTM = useGTMDispatch();
  return (
    <Button
      component={RouterLink}
      data-testid={tagId}
      startIcon={icon}
      sx={{
        justifyContent: 'flex-start',
        py: SpaceSizes.md,
      }}
      to={to}
      onClick={() => {
        sendDataToGTM({
          event: TAG_EVENT.SELECT_CONTENT,
          content_type: TAG_TYPE.MENU,
          item_id: tagId,
        });
        setIsDropdownOpen(false);
      }}
      {...props}
    >
      <Typography
        align="center"
        fontWeight={isActive ? ' bold' : 'normal'}
        letterSpacing={1}
        variant="body2"
      >
        {text}
      </Typography>
      {newBadge && <Badge />}
    </Button>
  );
};

export default MenuDropdownItem;
