// Check if exist brand in local storage and is valid for user

import { useEffect } from 'react';

import brandOptions from '../../brands/brandOptions';
import { setBrand } from '../../features/brand/Brand.slice';
import { getStorage } from '../../utils/storage';
import { useAppDispatch } from '../hooks';

/**
 * pick brand from local storage
 */
export const useBrand = () => {
  const brands = brandOptions && brandOptions.map((brand) => brand.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (brands && brands.length > 0) {
      const lastSelectedBrand = getStorage()?.getItem('brand');
      if (
        lastSelectedBrand &&
        brands.find((userBrand: string) => userBrand === lastSelectedBrand)
      ) {
        dispatch(setBrand(lastSelectedBrand));
      } else {
        dispatch(setBrand(brands[0]));
      }
    }
  }, [brands, dispatch]);
};
