export const TEAM_KEY = 'team';
export const FEATURE_FLAG_FILTER = false;
export const PLAYER_KEY = 'player';
export const BRAND_KEY = 'brand';
export const COMPETITION_KEY = 'competition';
export const SEASON_KEY = 'season';
export const AVAILABLE_KEY = 'available';
export const FREE_KEY = 'free';
export const USER_KEY = 'user_id';
export const TRADES_KEY = 'trades';

export const FILTER_EXCLUDED_KEYS = [
  USER_KEY,
  TRADES_KEY,
  AVAILABLE_KEY,
  FREE_KEY,
];
