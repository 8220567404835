import React from 'react';

import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';

const TriviaFinishedNotWinner = ({ nickname }: { nickname: string }) => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.finished');

  return (
    <AlertCard
      content={`${t('winnerIs')} ${nickname}`}
      id="triviaFinishedNotWinner"
      severity={AlertSeverity.INFO}
      show
    />
  );
};

export default TriviaFinishedNotWinner;
