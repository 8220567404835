import { Box, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatGP } from '../../../utils/formatNumbers';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import {
  PRODUCT_PRICE_DEFAULT_LOGO,
  PRODUCT_PRICE_LOGO_DEFAULT_SIZE,
} from '../ProductPrice/ProductPrice.contants';
import { IOptionTierProps } from './OptionTier.types';

export const OptionTier = ({
  tier,
  selectedTier,
  handleTierChange,
  isCheckout = false,
  isFirstSelected = false,
}: IOptionTierProps) => {
  const { t } = useTranslationByNamespaces('pages.tiers');
  const [selectedProductId, setSelectedProductId] = useState<
    string | undefined
  >(selectedTier.topUpId ?? undefined);
  const { topUpId } = selectedTier || {};

  useEffect(() => {
    if (
      (tier?.selected && !selectedProductId) ||
      (isFirstSelected && !selectedTier.topUpId)
    ) {
      handleTierChange({ topUpId: tier.product_id, points: tier.points });
    }
  }, [
    handleTierChange,
    tier,
    selectedProductId,
    isFirstSelected,
    selectedTier,
  ]);

  useEffect(() => {
    if (topUpId) {
      setSelectedProductId(topUpId);
    }
  }, [topUpId, setSelectedProductId]);

  const theme = useTheme();

  const isSmallestMobile = useSmallMobileMediaQuery();

  const golpointsCustomSx = useMemo(
    () =>
      tier.bonus_points > 0
        ? {
            position: 'relative',
            '&::before': {
              content: '" "',
              position: 'absolute',
              top: '50%',
              left: 0,
              right: 0,
              height: '3px',
              backgroundColor: '#f5fe3b',
              transform: 'rotate(-12deg)',
              transformOrigin: 'left center',
            },
          }
        : {},
    [tier.bonus_points],
  );

  return (
    <Stack
      style={{
        borderRadius: SpaceSizes.sm,
      }}
      sx={{
        backgroundColor: 'background.paper',
        border: isCheckout
          ? `1px solid ${theme.palette.primary.light}`
          : 'none',
        width: '100%',
      }}
    >
      <FormControlLabel
        control={
          <Stack flexDirection="row" gap={SpaceSizes.smPlus}>
            <Radio
              checked={
                tier.product_id === selectedProductId ||
                (!selectedProductId && !!isFirstSelected)
              }
              data-testid={tier.tier_id}
            />
            {isCheckout && (
              <Stack
                alignItems="center"
                flexDirection="row"
                gap={SpaceSizes.xs}
              >
                <ImageFallback
                  fallback={PRODUCT_PRICE_DEFAULT_LOGO}
                  height={PRODUCT_PRICE_LOGO_DEFAULT_SIZE.height}
                  src={PRODUCT_PRICE_DEFAULT_LOGO}
                  width={PRODUCT_PRICE_LOGO_DEFAULT_SIZE.width}
                />
                <Typography sx={golpointsCustomSx}>
                  {formatGP(tier.points)}
                </Typography>
                {tier.bonus_points > 0 && (
                  <Typography color="#f5fe3b" fontWeight={600}>
                    {formatGP(tier.points + tier.bonus_points)} 🎁
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        }
        label={
          <Stack ml={isSmallestMobile ? SpaceSizes.none : SpaceSizes.md}>
            {isCheckout ? (
              <Stack flexDirection="row" gap={SpaceSizes.sm}>
                <Stack>
                  <Typography>{Number(tier.price.value)} &euro;</Typography>
                </Stack>
              </Stack>
            ) : (
              <Stack>
                {tier.bonus_points === 0 ? (
                  <Typography
                    color="text.secondary"
                    fontWeight="700"
                    variant={isSmallestMobile ? 'cH5' : 'cH4'}
                  >
                    {formatGP(tier.points)} Gol-Points
                  </Typography>
                ) : (
                  <Typography
                    color="text.secondary"
                    fontWeight="700"
                    variant={isSmallestMobile ? 'cH5' : 'cH4'}
                  >
                    <>
                      <Box component="span" sx={golpointsCustomSx}>
                        {formatGP(tier.points)}
                      </Box>
                      &nbsp;
                      <Box color="#f5fe3b" component="span">
                        {formatGP(tier.points + tier.bonus_points)}
                      </Box>
                      &nbsp; Gol-Points 🎁
                    </>
                  </Typography>
                )}
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  gap={SpaceSizes.xs}
                >
                  <Typography>{t('optionTier.price')}:</Typography>
                  <Typography>{tier.price.currency_symbol}</Typography>
                  <Typography>{tier.price.value}</Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        }
        sx={{
          justifyContent: isCheckout ? 'space-between' : 'normal',
          px: SpaceSizes.md,
        }}
        value={tier.tier_id}
        onClick={() =>
          handleTierChange({ topUpId: tier.product_id, points: tier.points })
        }
      />
    </Stack>
  );
};
