import SpaceSizes from '../../../../../theme/foundations/spacing/SpaceSizes';

export const formStackStyled = {
  alignItems: 'center',
  direction: 'row',
  gap: SpaceSizes.md,
  width: '100%',
};

export const buttonSytled = {
  flex: '0 0 48%',
  maxWidth: '48%',
};

export const formStyled = {
  width: '100%',
};

export const typographySytled = {
  fontWeight: '500',
  textAlign: 'left',
};

export const accordionStyled = {
  '&.MuiAccordion-root': { borderRadius: '8px' },
  '&.MuiAccordion-root:before': { display: 'none' },
  '& .MuiTypography-root': { fontWeight: '600' },
};

export const accordionContent = {
  padding: SpaceSizes.sm,
  gap: SpaceSizes.sm,
  borderTop: SpaceSizes.xxs,
  borderColor: 'background.paper',
};

export const buttonContainer = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: SpaceSizes.smPlus,
  width: '100%',
};
