import * as Sentry from '@sentry/react';
import { isNilOrEmpty } from 'ramda-adjunct';

import packageJson from '../../package.json';
import Env from '../env/env';

export const shouldSentryBeEnabled = () =>
  Env.NODE_ENV !== 'development' &&
  Env.NODE_ENV !== 'test' &&
  isNilOrEmpty(window.Cypress);

export const sentryInit = () => {
  // only track for staging and production to avoid too many unnecesary events
  Sentry.init({
    dsn: Env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing({ tracingOrigins: ['*'] })],
    tracesSampleRate: 1,
    environment: Env.REACT_APP_GOLBALL_ENV,
    release: packageJson.version,
    attachStacktrace: true,
    enabled: shouldSentryBeEnabled(),
  });
};
