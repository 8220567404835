import { Grid, Skeleton } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import {
  CARD_HEIGHT_DESKTOP,
  CARD_WIDTH_DESKTOP,
} from '../../../pages/profile/Profile.constant';
import {
  CARD_MOBILE_HEIGHT,
  CARD_MOBILE_WIDTH,
} from '../../BuyCard/BuyCard.constants';

export const SearchResultsSkeleton = () => {
  const isSmallestMobile = useSmallMobileMediaQuery();
  const currentDate = new Date();
  const timestamp = currentDate.getTime();

  return (
    <Grid
      columnSpacing={{ xs: SpaceSizes.xs, sm: SpaceSizes.mdPlus }}
      justifyContent="center"
      rowSpacing={SpaceSizes.mdPlus}
      container
    >
      {Array.from({ length: 4 }).map((_, index) => (
        <Grid
          key={`${timestamp.toString() + String(index)}`}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          item
        >
          <Skeleton
            style={{
              borderRadius: '20px',
              height: isSmallestMobile
                ? CARD_MOBILE_HEIGHT
                : CARD_HEIGHT_DESKTOP,
              width: isSmallestMobile ? CARD_MOBILE_WIDTH : CARD_WIDTH_DESKTOP,
            }}
            variant="rectangular"
          />
        </Grid>
      ))}
    </Grid>
  );
};
