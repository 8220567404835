import { EMovementType } from '../../app/pages/movements/tabs/content/BuyMovementsContent.enums';
import { Paging } from '../searchfilter/responses/SearchResponse';

export enum EPaymentTypes {
  STRIPE = 'STRIPE',
  SQUARE = 'SQUARE',
  COINBASE = 'COINBASE',
  COUPON_PRESALES = 'COUPON_PRESALES',
  FREE = 'FREE',
  GOLCOINS = 'GOLCOINS',
}

export type TTransactionHistory = {
  product_id: string;
};

export type TTeamPosition = 'home' | 'away' | 'both';

export interface GetBuysMovementsApiResponse {
  results: IBuyMovement[];
  paging: Paging;
}

export interface GetTransactionsMovementsApiResponse {
  results: ITransactionMovement[];
  paging: Paging;
}

export interface GetMovementsApiRequest {
  pagination: Paging;
  filter?: MOVEMENT_FILTER;
}

export enum MOVEMENT_FILTER {
  'all',
  '7days',
  '30days',
  '90days',
}

export interface IMovementTab {
  filter: MOVEMENT_FILTER;
}

export interface ITransactionMovement {
  status: TTransactionStatus;
  date: string;
  contract_address: string;
  from: string;
  to: string;
  transaction_id: string;
  product: {
    id: string;
    type: string;
    entity_id: string;
    piece_number: number;
    scorer_last_name: string;
    goal_minute: number;
    event: {
      awarded_position: TTeamPosition;
      home_code: string;
      home_score: number;
      away_code: string;
      away_score: number;
    };
  };
}

export interface IBuyMovement {
  date: string;
  price: number;
  price_currency_symbol: string;
  gateway: EPaymentTypes;
  payment_method: string;
  order_id: string;
  gp_amount: number;
  bonus_amount: number;
  product: {
    match_date: string;
    piece_number: number;
    type: EMovementType;
    id: string;
    entity_id: string;
    scorer_last_name: string;
    goal_minute: number;
    event: TEvent;
    presale_type: TTeamPosition;
  };
}

export type TTransactionStatus = 'sent' | 'received' | 'pending' | 'expired';
export type TEvent = {
  awarded_position: TTeamPosition;
  home_code: string;
  home_score: number;
  away_code: string;
  away_score: number;
};
