import { createSelector } from '@reduxjs/toolkit';
import { equals, filter } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

import { selectContentApp } from '../externalContent/ExternalContent.selectors';
import {
  Assignment,
  BenefitsPlatforms,
  EAssignmentType,
  ETriviaBattleStatus,
  ETriviaContestantStatus,
  ETriviaQuestionStatus,
  IProduct,
  Match,
  ProductMultimediaType,
  ProductMultimediaTypeEnum,
  TEventTypes,
} from './Product.types';

export const selectProduct = (product: IProduct | undefined) => product;

export const selectProductPlayer = createSelector(
  selectProduct,
  (product?: IProduct) => product?.player,
);

export const selectProductId = createSelector(
  selectProduct,
  (product?: IProduct) => product?.product_id,
);

export const selectProductStatus = createSelector(
  selectProduct,
  (product?: IProduct) => product?.product_status,
);

export const selectProductMatch = createSelector(
  selectProduct,
  (product?: IProduct) => product?.match,
);

export const selectProductMatchHomeClub = createSelector(
  selectProductMatch,
  (match?: Match) => match?.home,
);

export const selectProductMatchAwayClub = createSelector(
  selectProductMatch,
  (match?: Match) => match?.away,
);

export const selectProductMatchTournament = createSelector(
  selectProduct,
  (product?: IProduct) => product?.match?.tournament,
);

export const selectProductMatchCompetition = createSelector(
  selectProduct,
  (product?: IProduct) => product?.match?.competition,
);

export const selectProductMatchStage = createSelector(
  selectProduct,
  (product?: IProduct) => product?.match?.stage,
);

const getAllFiles = (
  product: IProduct | undefined,
  type: ProductMultimediaType,
) => {
  return filter(
    (multimedia) => equals(type, multimedia.type),
    product?.multimedia || [],
  )[0]?.files;
};

const getFile = (
  product: IProduct | undefined,
  type: ProductMultimediaType,
) => {
  const files = getAllFiles(product, type);
  return files ? files[0] : undefined;
};

const getTags = (product: IProduct | undefined) => {
  return product?.tags || [];
};

export const selectProduct3dMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) => getFile(product, ProductMultimediaTypeEnum.THREED),
);

export const selectProduct3dCertificateMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getFile(product, ProductMultimediaTypeEnum.THREED_CERTIFICATE),
);

export const selectProductPlayerMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) => getFile(product, ProductMultimediaTypeEnum.PLAYER),
);

export const selectProductPlayerImage = createSelector(
  selectProduct,
  (product?: IProduct) => product?.player?.image,
);

export const selectProductPlayerName = createSelector(
  selectProduct,
  (product?: IProduct) => product?.player?.name,
);

export const selectProductSelfieStickersMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getAllFiles(product, ProductMultimediaTypeEnum.SELFIE_STICKERS),
);

export const selectProductWhatsappStickersMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getAllFiles(product, ProductMultimediaTypeEnum.WHATSAPP_STICKERS),
);

export const selectProductGoalSoundMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getFile(product, ProductMultimediaTypeEnum.GOAL_SOUND),
);

export const selectProductVideoMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) => getFile(product, ProductMultimediaTypeEnum.VIDEO),
);

export const selectProductGoalVideoMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getFile(product, ProductMultimediaTypeEnum.VIDEO_GOAL),
);

export const selectProductTacticMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) => product?.opta_widget,
);

export const selectProductChainOfCustodyMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getAllFiles(product, ProductMultimediaTypeEnum.CHAIN_OF_CUSTODY),
);

export const selectProductTags = createSelector(
  selectProduct,
  (product?: IProduct) => getTags(product),
);

export const selectProductIsMuseum = createSelector(
  selectProduct,
  (product?: IProduct) => getTags(product)?.includes('MUSEUM'),
);

export const selectProductIsDirectSale = createSelector(
  selectProduct,
  (product?: IProduct) => getTags(product)?.includes('DIRECT_SALE'),
);

const getEvents = (product: IProduct | undefined) => {
  return product?.events || [];
};

const getEventsWithType = (
  product: IProduct | undefined,
  eventType: TEventTypes | undefined,
) => {
  const events = getEvents(product);
  return filter((event) => equals(event.event_type, eventType), events || []);
};

export const selectCurrentGol = createSelector(
  selectProduct,
  (product?: IProduct) => {
    return getEventsWithType(product, 'GOAL')[0];
  },
);

export const selectBallName = createSelector(
  selectProduct,
  (product?: IProduct) => product?.ball_name,
);

export const selectPrice = createSelector(
  selectProduct,
  (product?: IProduct) => product?.price,
);

export const selectHasUploadedSelfie = createSelector(
  selectProduct,
  (product?: IProduct) => product?.has_uploaded_selfie,
);

export const selectSelfieUrl = createSelector(
  selectProduct,
  (product?: IProduct) => product?.selfie_url,
);

export const selectCollectibles = createSelector(
  selectProduct,
  (product?: IProduct) => product?.collectibles,
);

export const selectProductCarrouselMultimedia = createSelector(
  selectProduct,
  (product?: IProduct) => {
    const goalImages = getEventsWithType(product, 'GOAL');
    const signedBallImages = getAllFiles(
      product,
      ProductMultimediaTypeEnum.SIGNED_BALL,
    );
    const arrayImages = [...(signedBallImages || [])];
    if (goalImages.length && goalImages[0].event_files.length) {
      // insert each element of event_files inside arrayImages
      goalImages[0].event_files.forEach((eventFile) => {
        arrayImages.push(eventFile);
      });
    }
    return arrayImages.filter((image) => !!image);
  },
);

export const parsePriceValue = (value: string) => {
  return `${parseFloat(value)
    .toFixed(2)
    .replace(/[.,]00$/, '')}`;
};

export const getPriceString = (
  currency: string,
  symbol: string,
  value: string,
) =>
  currency === 'USD'
    ? `${symbol} ${parseFloat(value)
        .toFixed(2)
        .replace(/[.,]00$/, '')}`
    : `${parseFloat(value || '')
        .toFixed(2)
        .replace(/[.,]00$/, '')} ${symbol}`;

export const selectPriceWithCurrency = createSelector(
  selectProduct,
  (product?: IProduct) =>
    getPriceString(
      product?.price.currency || '',
      product?.price.symbol || '',
      product?.price.value || '',
    ),
);

export const selectCurrency = createSelector(
  selectProduct,
  (product?: IProduct) => product?.price.currency,
);

export const selectPriceValue = createSelector(
  selectProduct,
  (product?: IProduct) => product?.price.value,
);

export const selectProductContent = createSelector(
  selectProduct,
  (product?: IProduct) => product?.contents,
);

export const selectProductContract = createSelector(
  selectProduct,
  (product?: IProduct) => product?.contract,
);

export const selectProductGolClubId = createSelector(
  selectProduct,
  (product?: IProduct) => {
    const goal = getEventsWithType(product, 'GOAL')[0];

    // OWN GOAL
    if (goal && goal.event_data?.is_own_goal) {
      return product?.player.team_id === product?.match.home.id
        ? product?.match.away.id
        : product?.match.home.id;
    }
    return product?.player.team_id;
  },
);

export const selectProductLogo = createSelector(
  selectProduct,
  (product?: IProduct) => product?.logos?.default,
);

export const selectTeamNameOfLoser = createSelector(
  selectProduct,
  (product?: IProduct) => {
    const goal = getEventsWithType(product, 'GOAL')[0];
    // OWN GOAL
    if (goal && goal.event_data?.is_own_goal) {
      return product?.player.team_id === product?.match.home.id
        ? product?.match.home.name
        : product?.match.away.name;
    }
    return product?.player.team_id === product?.match.home.id
      ? product?.match.away.name
      : product?.match.home.name;
  },
);

export const selectProductIsHolded = createSelector(
  selectProduct,
  (product?: IProduct) => product?.market_status === 'holded',
);

export const selectGolpointsSymbol = createSelector(
  selectProduct,
  (product?: IProduct) => product?.price.symbol === 'GP',
);

const isFinished = (product?: IProduct) => {
  const status = selectProductStatus(product);
  const collectibles = selectCollectibles(product);

  return (
    status === 'finished' ||
    !collectibles?.on_sale ||
    collectibles.on_sale === 0
  );
};

export const selectProductIsFinished = createSelector(
  selectProduct,
  (product?: IProduct) => isFinished(product),
);

export const selectProductAssigment = createSelector(
  selectProduct,
  (product?: IProduct) => product?.assignment,
);

export const selectProductIsOwned = createSelector(
  selectProduct,
  (product?: IProduct) => product?.is_owned,
);

export const selectProductIsTrivia = createSelector(
  selectProductAssigment,
  (assignment?: Assignment) => assignment?.type === EAssignmentType.TRIVIA,
);

export const selectProductIsAuction = createSelector(
  selectProductAssigment,
  (assignment?: Assignment) => assignment?.type === EAssignmentType.AUCTION,
);

export const selectProductIsUserAbleToPlayTrivia = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,

  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia &&
    assignment?.status === ETriviaBattleStatus.STARTED &&
    assignment?.trivia?.contestant, // this is to check the user is participating in the trivia
);

export const selectProductIsTriviaStartedOrFinished = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia &&
    (assignment?.status === ETriviaBattleStatus.STARTED ||
      assignment?.status === ETriviaBattleStatus.FINISHED),
);

export const selectProductIsTriviaStarted = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    !!(isTrivia && assignment?.status === ETriviaBattleStatus.STARTED),
);

export const selectProductIsTriviaFinished = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia && assignment?.status === ETriviaBattleStatus.FINISHED,
);

export const selectProductHasTrivia = createSelector(
  selectProduct,
  (product?: IProduct) => !!product?.assignment.trivia,
);

export const selectProductIsTriviaNotStarted = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia && assignment?.status === ETriviaBattleStatus.NOT_STARTED,
);

export const selectIsTriviaPlayerEliminated = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia &&
    assignment?.trivia?.contestant?.status ===
      ETriviaContestantStatus.ELIMINATED &&
    !assignment?.trivia?.contestant?.not_seen_previous_question,
);

export const selectIsTriviaPlayerEliminatedForNotRespond = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia &&
    assignment?.trivia?.contestant?.status ===
      ETriviaContestantStatus.ELIMINATED &&
    assignment?.trivia?.contestant?.not_seen_previous_question,
);

export const selectIsTriviaPlayerAnswered = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    (isTrivia &&
      assignment?.trivia?.contestant?.current_question_status !==
        ETriviaQuestionStatus.NOT_ANSWERED) ??
    false,
);

export const selectProductIsTriviaWinner = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia &&
    assignment?.status === ETriviaBattleStatus.FINISHED &&
    assignment?.assigned_to?.is_self,
);

export const selectProductIsWinner = createSelector(
  selectProductAssigment,
  (assignment?: Assignment) => assignment?.assigned_to?.is_self,
);

export const selectProductShowFinishedComponent = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    isTrivia &&
    assignment?.status === ETriviaBattleStatus.FINISHED &&
    assignment?.assigned_to?.display,
);

export const selectProductIsTriviaBeforeLastRound = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    (isTrivia && assignment?.trivia?.remaining_rounds === 1) || false,
);

export const selectProductIsTriviaLastRound = createSelector(
  selectProductIsTrivia,
  selectProductAssigment,
  (isTrivia?: boolean, assignment?: Assignment) =>
    (isTrivia && assignment?.trivia?.remaining_rounds === 0) || false,
);

export const selectIsGoldenTicket = createSelector(
  selectProduct,
  (product?: IProduct) => !!product?.pieces?.some((piece) => piece.is_assigned),
);

/**
 * it determines if the product is not assignable or it's already assigned
 * */
export const selectNotAssignableOrAlreadyAssigned = createSelector(
  selectProductIsTrivia,
  selectProduct,
  (isTrivia: boolean, product?: IProduct) => {
    const isNoneAssignmentType =
      product?.assignment.type === EAssignmentType.NONE;
    const isAlreadyAssigned = isNotNilOrEmpty(product?.assignment.assigned_at);
    return isNoneAssignmentType || (isAlreadyAssigned && !isTrivia);
  },
);

export const selectBenefits = createSelector(
  selectContentApp<BenefitsPlatforms>,
  (data: BenefitsPlatforms | undefined) => data,
);

export const selectWebBenefits = createSelector(
  selectBenefits,
  (data?: BenefitsPlatforms) => data && data?.web,
);
