import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  GetMatchesByTournamentResponse,
  Matches,
} from '../../../features/tournaments/Tournaments.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatUTCtoLocalTime } from '../../../utils/date';
import ImageTint from '../../../utils/imageTint/ImageTint';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { geti18nResolvedLanguage } from '../../i18n';
import { ImageFallback } from '../ImageFallback/ImageFallback';

const getMatchDate = (t: (str: string) => string, match: Matches) => {
  const { is_live: isLive, date, time } = match;

  if (isLive) return t('pages.matches.matchesStatus.today');

  return formatUTCtoLocalTime(
    geti18nResolvedLanguage(),
    date,
    time,
    'E. d MMM.',
  );
};

const getMatchTime = (t: (str: string) => string, match: Matches) => {
  const { is_live: isLive, status, date, time } = match;

  if (isLive) return t('pages.matches.matchesStatus.live');
  if (status === 'Played') return t('pages.matches.matchesStatus.finished');
  if (!time) return '';

  return formatUTCtoLocalTime(
    geti18nResolvedLanguage(),
    date,
    time,
    "HH:mm 'hrs'",
  );
};

export const MatchesByTournament = (props: GetMatchesByTournamentResponse) => {
  const {
    logo,
    commercial_name: commercialName,
    matches,
    openFirstTournament,
  } = props;
  const { t } = useTranslation();
  const [openCollapsibleItem, setOpenCollapsibleItem] =
    useState(openFirstTournament);
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useTabletMediaQuery();
  const isSmallestMobile = useMobileMediaQuery();
  const logoSize = isSmallestMobile ? '35%' : isTablet ? '40%' : '25%';

  return (
    <Stack>
      <Stack alignItems="center" flexDirection="row" height="72px">
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.lg}>
          <ImageTint
            alt="tournament-logo"
            color={theme.palette.text.primary}
            height="auto"
            src={logo || ''}
            width={logoSize}
          />

          <Typography variant="body2">{commercialName}</Typography>
        </Stack>
        <Stack>
          <IconButton
            size="small"
            onClick={() => setOpenCollapsibleItem(!openCollapsibleItem)}
          >
            {openCollapsibleItem ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </Stack>
      </Stack>
      {!openCollapsibleItem && <Divider sx={{ width: '100%' }} />}
      <Stack gap={SpaceSizes.md}>
        {openCollapsibleItem &&
          matches.map((match) => (
            <Stack
              key={match.id}
              bgcolor="background.paper"
              borderRadius={SpaceSizes.xs2}
              flexDirection="row"
              p={SpaceSizes.md}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/matches/${match.id}`)}
            >
              <Stack justifyContent="center" width="20%">
                <Typography fontSize={{ xs: 12, md: 14 }} textAlign="center">
                  {getMatchDate(t, match)}
                </Typography>
                <Typography
                  fontSize={{ xs: 12, md: 14 }}
                  textAlign="center"
                  variant="caption"
                >
                  {getMatchTime(t, match)}
                </Typography>
              </Stack>
              <Stack alignItems="center" width="10%">
                <Divider
                  orientation="vertical"
                  sx={{
                    height: '60px',
                    width: '1px',
                    backgroundColor: 'white',
                  }}
                />
              </Stack>
              <Stack gap={SpaceSizes.xs2} width="60%">
                <Stack flexDirection="row" width="100%">
                  <Stack
                    alignItems="center"
                    flexDirection="row"
                    gap={SpaceSizes.sm}
                  >
                    <ImageFallback
                      height={30}
                      src={match.teams.home.logo}
                      width={30}
                    />
                    <Typography fontSize={{ xs: 12, md: 14 }} lineHeight={2}>
                      {match.teams.home.name}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection="row" width="100%">
                  <Stack
                    alignItems="center"
                    flexDirection="row"
                    gap={SpaceSizes.sm}
                  >
                    <ImageFallback
                      height={30}
                      src={match.teams.away.logo}
                      width={30}
                    />
                    <Typography fontSize={{ xs: 12, md: 14 }} lineHeight={2}>
                      {match.teams.away.name}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                alignItems="center"
                flexDirection="column"
                justifyContent="space-around"
                width="10%"
              >
                <Box>
                  <Typography fontWeight={600} variant="body1">
                    {match.teams.home.score}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight={600} variant="body1">
                    {match.teams.away.score}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};
