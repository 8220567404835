import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

import { Row } from '../Row/Row';
import { IColumnDescrion, ICustomTableProps } from './CustomTable.types';

export const CustomTable = ({
  columnDescriptions = [],
  rows,
  collapsible = false,
  paperTable = false,
}: ICustomTableProps) => {
  return (
    <TableContainer component={paperTable ? Paper : Box}>
      <Table>
        {!!columnDescriptions.length && (
          <TableHead>
            <TableRow>
              <>
                {collapsible ?? <TableCell key="columnCollapsibleGap" />}
                {columnDescriptions.map(
                  (columnDescription: IColumnDescrion, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell key={`columnDescription-${index}`} align="right">
                      {columnDescription.description}
                    </TableCell>
                  ),
                )}
              </>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((rowProps, index) => (
            <Row
              {...rowProps}
              // eslint-disable-next-line react/no-array-index-key
              key={`row-${index}`}
              index={index.toString()}
              isLast={index === rows.length - 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
