import { Chip as MuiChip } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ChipProps } from './Chip.types';

const Chip = ({ prefix, content, style = {} }: ChipProps) => {
  return (
    <MuiChip
      icon={prefix}
      label={content}
      style={{ ...style }}
      sx={{ p: SpaceSizes.sm }}
    />
  );
};

export default Chip;
