import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { boxShadowMixin } from '../../../utils/mixins';
import useDesktopMediaQuery from '../../hooks/useDesktopMediaQuery';
import i18n, { LANGUAGES } from '../../i18n';
import { IProductContentWidgetProps } from './ProductContentWidget.types';

export const ProductContentWidget = ({
  contents,
}: IProductContentWidgetProps) => {
  const isDesktop = useDesktopMediaQuery();
  let { language } = i18n;
  language = LANGUAGES.includes(language) ? language : 'es';
  const theme = useTheme();

  const con = contents.filter((c) => c.lang_id === language);
  const vars = con.length > 0 ? con[0].vars : [];

  return vars.length === 0 ? (
    <> </>
  ) : (
    <Stack
      p={isDesktop ? SpaceSizes.md : SpaceSizes.lg}
      sx={{
        display: 'flex',
        padding: '17px 0px 0px 0px',
        textAlign: 'inherit',
        alignItems: 'left',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.default,
        borderRadius: SpaceSizes.xs,
        boxShadow: boxShadowMixin(theme.palette.secondary.light),
      }}
    >
      {vars.map((v) => {
        return (
          <Box key={v.id}>
            <Typography
              fontWeight="bold"
              sx={{ paddingX: SpaceSizes.md, paddingBottom: '0px' }}
            >
              {v.title}
            </Typography>
            <Typography
              fontSize="0.9em"
              sx={{ paddingX: SpaceSizes.md, paddingBottom: SpaceSizes.md }}
            >
              {v.content}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
