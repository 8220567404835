import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectHorizontalMultimedia } from '../../../../features/collections/Collections.selectors';
import { CollectionListItem } from '../../../../features/collections/Collections.types';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import useTabletMediaQuery from '../../../hooks/useTabletMediaQuery';
import {
  COLLECTION_CARD_SIZE,
  COLLECTION_CARD_SIZE_MOBILE,
} from './CollectionCard.constants';

type CollectionCardProps = {
  collection: CollectionListItem;
  collectionGroup: string | undefined;
};

export const CollectionCard = ({
  collection,
  collectionGroup,
}: CollectionCardProps) => {
  const navigate = useNavigate();
  const isTablet = useTabletMediaQuery();
  const navigateToCollection = useCallback(() => {
    if (collectionGroup)
      navigate(`/collections/${collectionGroup}/${collection.collection_id}`);
  }, [navigate, collection, collectionGroup]);

  const hasFilled = useMemo(
    () => collection.q_user_goals === collection.q_entity_goals,
    [collection],
  );
  return (
    <Card
      data-testid={collection.title}
      sx={{
        height: '100%',
        width: isTablet
          ? COLLECTION_CARD_SIZE_MOBILE.width
          : COLLECTION_CARD_SIZE.width,
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        borderRadius: SpaceSizes.xxs,
        border: 0.1,
      }}
      onClick={navigateToCollection}
    >
      <CardMedia
        alt="Collection card image"
        component="img"
        image={selectHorizontalMultimedia(collection)}
        sx={{
          width: '100%',
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={isTablet ? SpaceSizes.xs : SpaceSizes.sm}
          >
            <Typography fontWeight="bolder" textAlign="center">
              {collection.title}
            </Typography>
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="center"
            >
              <Typography
                color={hasFilled ? 'primary.dark' : 'text.primary'}
                fontWeight="bolder"
              >{`${collection.q_user_goals} / ${collection.q_entity_goals}`}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};
