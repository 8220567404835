import Skeleton from '@mui/lab/Skeleton/Skeleton';
import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useGetCardSizes } from '../../hooks/modules/useGetCardSizes';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';

export const RelatedProductsSkeleton = () => {
  const isSmallMobile = useSmallMobileMediaQuery();
  const { getCardSize } = useGetCardSizes(true);

  return (
    <Stack direction={isSmallMobile ? 'column' : 'row'} gap={SpaceSizes.lg}>
      <Skeleton
        height={getCardSize.height}
        sx={{ borderRadius: SpaceSizes.xs }}
        variant="rectangular"
        width={getCardSize.width}
      />
    </Stack>
  );
};
