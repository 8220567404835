export const CONFETTI_ANIMATION = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 2 } },
};
export const COLORS_PER_TEAM = {
  valencia_cf: ['#0097D7', '#FFDF1C', '#AA2D2A', '#D18816', '#000000'],

  real_sociedad: ['#FFFFFF', '#0067B1', '#E4B630', '#E4B630', '#E1061F'],

  real_madrid: ['#FEBE10', '#00529F', '#EE324E', '#ffffff'],

  union_deportiva_almeria: [
    '#ee1119',
    '#ffffff',
    '#007cce',
    '#ffbc00',
    '#c86c05',
    '#000000',
  ],

  fc_barcelona: ['#EDBB00', '#004D98', '#A50044', '#DB0030', '#FFED02'],

  athletic_club_bilbao: ['#FFFFFF', '#EE2523', '#000000'],

  celta_de_vigo: ['#8AC3EE', '#E5254E', '#B19221'],

  espanyol: ['#FFFFFF', '#007FC8', '#DF1116', '#F4CF0C'],

  getafe_cf: ['#005999', '#C43A2F', '#FCED0B', '#FFFFFF', '#7DA738'],

  deportivo_mallorca: ['#E20613', '#000000', '#00844B', '#FFE200'],

  osasuna: ['#0A346F', '#D91A21', '#FFFFFF', '#AD8F1F'],

  real_betis_balompie: ['#0BB363', '#E7A614', '#D62550'],

  sevilla_fc: ['#FFFFFF', '#F43333', '#C79100'],

  real_valladolid: ['#921B88', '#FFFFFF', '#F4E423', '#168554', '#000000'],

  villarreal: ['#005187', '#FFE667', '#E70005', '#000000'],

  atletico_madrid: ['#272E61', '#FFFFFF', ' #CB3524'],

  rayo_vallecano: ['#000000', '#e53027', '#ffffff ', '#eaaf10', '#007c66'],

  cadiz_cf: [
    '#000000',
    ' #fde607',
    ' #0045a7',
    ' #d8b80a',
    ' #cc1424',
    ' #d69400',
  ],

  elche_cf: ['#05642c', '#e6c777', '#c5112e', '#1b458f', '#ffffff'],

  girona_fc: ['#cd2534', '#ffffff', '#edda35', '#000000'],
};
