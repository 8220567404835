import {
  NotificationProps,
  NotificationType,
  TradeNotificationProps,
  TriviaNotificationProps,
} from './Notification.types';
import TradeOfferNotification from './tradeoffer/TradeOfferNotification';
import { TriviaNotification } from './trivia/TriviaNotification';

export const NotificationTradingType = [
  NotificationType.TRADE_RECEIVED,
  NotificationType.TRADE_ACCEPTED,
  NotificationType.TRADE_REJECTED,
];

type SpecificNotificationProps =
  | NotificationProps
  | TriviaNotificationProps
  | TradeNotificationProps;

export type NotificationComponentsMap = {
  [key in NotificationType]?: React.ComponentType<SpecificNotificationProps>;
};

const TriviaNotificationComponent =
  TriviaNotification as React.ComponentType<SpecificNotificationProps>;

const TradeNotificationComponent =
  TradeOfferNotification as React.ComponentType<SpecificNotificationProps>;

const notificationTypeToComponent = {
  [NotificationType.BATTLE_STARTED]: TriviaNotificationComponent,
  [NotificationType.NEXT_ROUND_STARTED]: TriviaNotificationComponent,
  [NotificationType.NEW_BONUS_QUESTION]: TriviaNotificationComponent,
  [NotificationType.BATTLE_USER_WINNER]: TriviaNotificationComponent,
  [NotificationType.BATTLE_USER_ELIMINATED]: TriviaNotificationComponent,
  [NotificationType.BATTLE_USER_ELIMINATED_IN_TIE]: TriviaNotificationComponent,
  [NotificationType.BATTLE_USER_ELIMINATED_NOT_SEEN]:
    TriviaNotificationComponent,
  [NotificationType.TRADE_ACCEPTED]: TradeNotificationComponent,
  [NotificationType.TRADE_RECEIVED]: TradeNotificationComponent,
  [NotificationType.TRADE_REJECTED]: TradeNotificationComponent,
};

export const NOTIFICATION_COMPONENTS: NotificationComponentsMap = {
  ...notificationTypeToComponent,
};
