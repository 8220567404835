import { Grid, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  addFilter,
  removeFilter,
  setMyCollectionFilter,
} from '../../../features/searchfilter/mycollection/MyCollectionFilter.slice';
import { AppliedFilter } from '../../../features/searchfilter/responses/SearchResponse';
import { useGetUserPreferenceQuery } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { BallsOwned } from '../../components/BallsOwned/BallsOwned';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { EmptyResults } from '../../components/EmptyResults/EmptyResults';
import { StatesEmptyResults } from '../../components/EmptyResults/EmptyResults.constant';
import { PageError } from '../../components/PageError/PageError';
import { Presentation } from '../../components/profile/Presentation/Presentation';
import { SearchResults } from '../../components/search/SearchResults/SearchResults';
import { useDeliver } from '../../components/search/SearchResults/useDeliver';
import { SearchSection } from '../../components/search/SearchSection/SearchSection';
import { useAppDispatch } from '../../hooks';
import useSearchMyCollectionWithInfiniteScroll from '../../hooks/search/useSearchMyCollectionWithInfiniteScroll';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import { ProfileSkeleton } from './Profile.skeleton';

const Profile = () => {
  const { t } = useTranslationByNamespaces('pages.publicProfile');
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const isDesktop = useDesktopDeviceMediaQuery();
  const {
    data: dataPreference,
    isLoading: isLoadingPreference,
    isUninitialized: isUninitializedPreference,
    isError: isErrorPreference,
  } = useGetUserPreferenceQuery(userId ? { user_id: userId } : skipToken);

  const {
    data: dataCollections,
    isLoading: isLoadingCollections,
    isUninitialized: isUninitializedCollections,
    isError: isErrorCollections,
    enterWaypoint,
    resetOffset,
    isLastPage,
  } = useSearchMyCollectionWithInfiniteScroll();

  const { handleDeliver, isTransferringCard, isDeliveryModalDisabled } =
    useDeliver(dataCollections?.results);

  useEffect(() => {
    dispatch(setMyCollectionFilter(userId ?? ''));
  }, [dispatch, userId]);

  const handleAddFilter = (filter: AppliedFilter) =>
    dispatch(addFilter(filter));

  const handleRemoveFilter = (filterId: string) =>
    dispatch(removeFilter(filterId));

  if (isErrorPreference || isErrorCollections)
    return <PageError errorCode={t('errors.GENERIC_ERROR')} />;

  if (
    isUninitializedPreference ||
    isLoadingPreference ||
    isUninitializedCollections ||
    isLoadingCollections
  )
    return <ProfileSkeleton />;

  return (
    <Stack>
      <Presentation
        isLoading={isLoadingPreference}
        team={dataPreference.team}
        user={dataPreference.user}
      />
      <PageContainer
        id="profilePublic"
        title={t('pageTitle')}
        trackCode={
          dataPreference.user.is_self
            ? TAG_EVENT.CONTENT_GROUP.MY_PROFILE
            : TAG_EVENT.CONTENT_GROUP.ANOTHER_PROFILE
        }
        {...(!isDesktop &&
          dataCollections && {
            rightChild: (
              <SearchSection
                addFilter={handleAddFilter}
                data={dataCollections}
                removeFilter={handleRemoveFilter}
              />
            ),
          })}
      >
        <Stack gap={SpaceSizes.xs} width="100%">
          <Stack
            alignItems="center"
            direction={isDesktop ? 'column' : 'row'}
            justifyContent="space-between"
          >
            <Stack>
              {dataPreference.user.is_self && (
                <BallsOwned
                  isLoading={isLoadingCollections}
                  isTransferringCard={isTransferringCard}
                />
              )}
            </Stack>
          </Stack>
          <Stack
            component="main"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {isDesktop && dataCollections && (
              <Grid width="20%" item>
                <SearchSection
                  addFilter={handleAddFilter}
                  data={dataCollections}
                  removeFilter={handleRemoveFilter}
                />
              </Grid>
            )}
            {dataCollections && dataCollections?.results?.length > 0 ? (
              <SearchResults
                enterWaypoint={enterWaypoint}
                handleDeliver={handleDeliver}
                isDeliveryModalDisabled={isDeliveryModalDisabled}
                isLastPage={isLastPage}
                isSelf={dataPreference.user.is_self}
                refetch={() => resetOffset()}
                results={dataCollections?.results}
                width={{
                  xs: '100%',
                  lg: '80%',
                }}
              />
            ) : (
              <EmptyResults origin={StatesEmptyResults.BUYS} t={t} />
            )}
          </Stack>
        </Stack>
      </PageContainer>
    </Stack>
  );
};

export default Profile;
