import { Transition, Variants } from 'framer-motion';

import {
  ITransformProps,
  ITransitionProps,
  IVariantApparitionInterface,
} from './Variants.types';

export const boilerplateMotion = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
};

export const VariantApparition = (
  transitionProps?: IVariantApparitionInterface,
) => {
  const defaultValue = {
    in: {
      opacity: 1,
      y: 220,
    },
    out: {
      opacity: 1,
      y: -220,
    },
    transition: {
      delay: 14,
    },
  };
  const { in: joinAn, out: leaveAn } = transitionProps || defaultValue;
  return {
    inactive: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 14,
        ease: 'easeInOut',
      },
    },
    out: leaveAn,
    in: joinAn,
  };
};

export const PathApparition = () => {
  return {
    initial: {
      pathLength: 0,
      opacity: 0,
    },
    animate: {
      pathLength: 1,
      opacity: 1,
    },
  };
};

export const loopedAnimation: Transition = {
  duration: 1.5,
  repeat: Infinity,
  ease: 'easeOut',
  repeatDelay: 1,
  repeatType: 'loop',
};

export const delayedAnimation = (transitionProps: ITransitionProps) => {
  const {
    delay = 0,
    duration,
    ease = 'easeInOut',
    type,
    damping = 40,
    mass = 1,
    stiffness = 300,
  } = transitionProps || {};
  return {
    delay,
    duration,
    ease,
    type,
    damping,
    mass,
    stiffness,
  };
};
export const glowBorders = () => {
  const gradientAngleVariants = {
    initial: {
      border: '1px solid linear-gradient(90deg, #00b09b, #96c93d)',
    },
    animate: {
      border: '1px solid linear-gradient(45deg, #00b09b, #96c93d)',
    },
  };
  return gradientAngleVariants;
};

export const pulseAnimation = (timeProps?: ITransitionProps) => {
  return {
    initial: {
      opacity: 1,
      scale: 1,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: timeProps,
    },
    exit: {
      scale: 2.25,
      opacity: 0,
    },
  };
};

export const offsetApparition = (
  transform?: ITransformProps,
  initialTransparency = 0,
  exitTransition: ITransitionProps = {},
): Variants => {
  const { offsetX, offsetY, targetX, targetY } = transform || {};
  const {
    delay: eDelay = 0,
    duration: eDuration,
    ease: eEase,
    type: eType,
    damping: eDamping,
    mass: eMass,
    stiffness: eStiffness,
  } = exitTransition || {};
  return {
    initial: {
      translateX: offsetX || 0,
      translateY: offsetY || 0,
      opacity: Number(initialTransparency) || 0,
    },
    animate: {
      opacity: 1,
      translateX: targetX || 0,
      translateY: targetY || 0,
    },
    exit: {
      translateX: offsetX || 0,
      translateY: offsetY || 0,
      opacity: 0,
      transition: {
        delay: eDelay || 0,
        duration: eDuration,
        ease: eEase,
        type: eType,
        damping: eDamping,
        mass: eMass,
        stiffness: eStiffness,
      },
    },
  };
};

export const transitionalCommon: Variants = {
  in: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  },
  out: {
    opacity: 0,
    scale: 1,
    y: 55,
    transition: {
      duration: 0.3,
    },
  },
};

export const springCommon: Transition = {
  type: 'spring',
  damping: 10,
  mass: 555,
  stiffness: 100,
};

export const hoverCardStyle = {
  opacity: 0,
  border: '4px double transparent',
  borderImageSlice: 1,
  borderRadius: '7%',
};

export const hoverCard = {
  opacity: 1,
  border: '4px double',
  borderImageSlice: 1,
  borderRadius: '9%',
  borderImageSource:
    'linear-gradient(280deg, #0b98c3 10%, #d033df 90%) 0% 0% no-repeat padding-box',
};
