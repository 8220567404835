import { useGrowthBook } from '@growthbook/growthbook-react';
import { Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetGolpointsBalanceQuery } from '../../../features/golpoints/Golpoints.api';
import { golpointsImg } from '../../../features/golpoints/Golpoints.constants';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatGP } from '../../../utils/formatNumbers';
import { PURCHASE_GOLPOINTS } from '../../../utils/growthbookFeatures';
import { TAG_VALUE } from '../../../utils/tagger';
import { GolButton } from '../GolButton/GolButton.component';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { GolpointsBalanceProps } from './GolpointsBalance.types';

const GolpointsBalance = ({
  shouldRefetch,
  onNavigate,
}: GolpointsBalanceProps) => {
  const navigate = useNavigate();
  const growthbook = useGrowthBook();
  const {
    data: golpoints,
    isLoading,
    refetch: refetchGolpointsBalance,
  } = useGetGolpointsBalanceQuery();

  useEffect(() => {
    if (shouldRefetch) refetchGolpointsBalance();
  }, [shouldRefetch, refetchGolpointsBalance]);

  const golpointsBalance = useMemo(() => {
    if (!golpoints) return '';
    return formatGP(golpoints.balance);
  }, [golpoints]);

  const { t } = useTranslation();

  if (isLoading)
    return <Skeleton key="balance-skeleton" variant="rectangular" />;

  const handleOnClickAddGolpoints = () => {
    onNavigate();
    navigate('/tiers');
  };

  return (
    <Stack
      alignItems="start"
      flexDirection="column"
      gap={SpaceSizes.md}
      justifyContent="space-between"
    >
      <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.sm}>
        <ImageFallback
          fallback={golpointsImg}
          height={SpaceSizes.mdPlus}
          src={golpointsImg}
          width={SpaceSizes.mdPlus}
        />
        <Typography>{golpointsBalance} Gol-Points</Typography>
      </Stack>
      {growthbook?.isOn(PURCHASE_GOLPOINTS) && (
        <Stack alignItems="center" justifyContent="center" width="100%">
          <GolButton
            size="small"
            testId={TAG_VALUE.HEADER.TIERS}
            value={t('pages.tiers.buy')}
            fullWidth
            onClick={handleOnClickAddGolpoints}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default GolpointsBalance;
