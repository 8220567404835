import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import {
  GetBuysMovementsApiResponse,
  GetMovementsApiRequest,
  GetTransactionsMovementsApiResponse,
} from './Movements.types';

export const movementsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuysMovements: builder.query<
      GetBuysMovementsApiResponse,
      GetMovementsApiRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/v2/buys`,
        params: {
          limit: request.pagination.limit,
          offset: request.pagination.offset,
        },
        method: 'GET',
      }),
      providesTags: [RTKTags.MovementsMyBuys],
      serializeQueryArgs: () => {
        return [];
      },
      merge: (currentCache, newItems) => {
        if (
          !newItems.results[0] ||
          currentCache.results.find(
            (movement) => movement.order_id === newItems.results[0].order_id,
          )
        ) {
          return currentCache;
        }
        const newResponse: GetBuysMovementsApiResponse = {
          results: currentCache.results.concat(newItems.results),
          paging: newItems.paging,
        };

        return newResponse;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        (currentArg?.pagination?.offset ?? 0) >
        (previousArg?.pagination?.offset ?? 0),
    }),
    // transactions
    getTransactionsMovements: builder.query<
      GetTransactionsMovementsApiResponse,
      GetMovementsApiRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/v2/transactions`,
        params: {
          limit: request.pagination.limit,
          offset: request.pagination.offset,
        },
        method: 'GET',
      }),
      providesTags: [RTKTags.MovementsTransactions],
      serializeQueryArgs: () => {
        return [];
      },
      merge: (currentCache, newItems) => {
        if (
          !newItems.results[0] ||
          currentCache.results.find(
            (movement) =>
              movement.transaction_id === newItems.results[0].transaction_id,
          )
        ) {
          return currentCache;
        }
        const newResponse: GetTransactionsMovementsApiResponse = {
          results: currentCache.results.concat(newItems.results),
          paging: newItems.paging,
        };

        return newResponse;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        (currentArg?.pagination?.offset ?? 0) >
        (previousArg?.pagination?.offset ?? 0),
    }),
  }),
});

export const { useGetBuysMovementsQuery, useGetTransactionsMovementsQuery } =
  movementsApi;
