import { Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

const NotificationAlert = () => {
  return (
    <Stack
      alignItems="center"
      alignSelf="flex-end"
      bgcolor="info.main"
      borderRadius={SpaceSizes.xxl}
      height={SpaceSizes.sm}
      justifyContent="center"
      width={SpaceSizes.sm}
    />
  );
};

export default NotificationAlert;
