import { Stack } from '@mui/material';
import React from 'react';

import env from '../../../../env/env';
import { selectCurrentBrand } from '../../../../features/brand/Brand.selector';
import { TAG_TYPE } from '../../../../utils/tagger';
import { useAppSelector } from '../../../hooks';
import { Link } from '../../Link/Link';
import Logo from '../../Logo/Logo';

interface Props {
  height: number;
  width: number;
  tagId?: string;
}

export const LogoHeader = ({ height, width, tagId }: Props) => {
  const logo = useAppSelector(selectCurrentBrand)?.logo;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: '100%' }}
    >
      <Link
        sx={{ display: 'flex' }}
        tagId={tagId}
        tagSectionId={TAG_TYPE.MENU}
        to="/home"
      >
        {env.REACT_APP_GOLBALL_ENV === 'development' ? (
          <Logo
            $height={height}
            $width={width}
            src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/img/logo-melting.png`}
          />
        ) : (
          <Logo $height={height} $width={width} src={logo} />
        )}
      </Link>
    </Stack>
  );
};
