import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_TYPE, TAG_VALUE } from '../../../utils/tagger';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { Link } from '../Link/Link';
import TyCLink from './TyCLink';

interface CopyrightProps {
  renderCopy?: boolean;
}

const CopyrightAndTyc = ({ renderCopy = true }: CopyrightProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileMediaQuery();

  return (
    <Stack
      alignItems="center"
      direction={isMobile ? 'column' : 'row'}
      justifyContent="center"
      spacing={SpaceSizes.md}
      textAlign="center"
    >
      {renderCopy && (
        <>
          <Typography sx={{ opacity: 0.4 }} textAlign="start" variant="body2">
            <Link
              tagId={TAG_VALUE.FOOTER.COPY}
              tagSectionId={TAG_TYPE.MENU_FOOTER}
              to="/home"
            >
              {`© ${new Date().getFullYear()} ${t('footer.legal.copyright')}`}
            </Link>
          </Typography>
          {!isMobile && (
            <Typography sx={{ opacity: 0.4 }} variant="body2">
              |
            </Typography>
          )}
        </>
      )}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={SpaceSizes.md}
        textAlign="center"
      >
        <TyCLink />
        <Typography sx={{ opacity: 0.4 }} variant="body2">
          |
        </Typography>
        <Typography sx={{ opacity: 0.4 }} variant="body2">
          <Link
            tagId={TAG_VALUE.FOOTER.POLITY}
            tagSectionId={TAG_TYPE.MENU_FOOTER}
            to={t('footer.legal.polityLink')}
            external
          >
            {t('footer.legal.polity')}
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};

export { CopyrightAndTyc };
