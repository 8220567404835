import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { ART_SIZE, CONTAINER_SIZE } from './constants';
import animations from './motion.json';

const MotionStack = motion(Stack);

export const Maintenance = () => {
  const { t } = useTranslationByNamespaces('pages.maintenance');
  const isDesktop = useDesktopDeviceMediaQuery();
  const art = `${env.REACT_APP_STATIC_BASE_URL}marketplace/maintenance/${
    isDesktop ? 'art' : 'art_m'
  }.png`;
  const bg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/maintenance/${
    isDesktop ? 'bg' : 'bg_m'
  }.jpg`;

  return (
    <Stack
      gap={SpaceSizes.sm}
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        paddingY: SpaceSizes.lg,
      }}
    >
      <MotionStack
        {...animations.barAnimation}
        alignSelf="center"
        bgcolor="primary.light"
        height={SpaceSizes.sm}
      />
      <MotionStack {...animations.contentAnimation}>
        <Stack alignItems="center" gap={SpaceSizes.xl} width="100%">
          <Stack gap={SpaceSizes.sm} width={CONTAINER_SIZE}>
            <Typography
              component="h2"
              fontWeight={600}
              textAlign="center"
              variant="h4"
            >
              {t('pageTitle')}
            </Typography>
            <Typography component="h3" textAlign="center">
              {t('description')}
            </Typography>
          </Stack>
          <ImageFallback
            alt="maintenance"
            height={
              isDesktop ? ART_SIZE.height.desktop : ART_SIZE.height.mobile
            }
            src={art}
            width={isDesktop ? ART_SIZE.width.desktop : ART_SIZE.width.mobile}
          />
        </Stack>
      </MotionStack>
    </Stack>
  );
};
