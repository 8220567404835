import { BrandConfig } from '../../brands/brandOptions';
import { Away, Home } from '../products/Product.types';
import {
  AppliedFilter,
  Filter,
  FilterRequest,
  PaginationRequest,
  Paging,
  Search,
} from '../searchfilter/responses/SearchResponse';

export interface TradeItemI {
  match: MatchType;
  events: EventType[];
  ballId: string;
  productId: string;
  player: Player;
  tradableAmount: number;
}
export interface PublishItemRequest {
  product_id: string;
  piece_number: number;
}

export interface TradeItemUnit {
  match: MatchType;
  events: EventType[];
  ball_id: string;
  product_id: string;
  player: Player;
  tradable_amount: number;
}

export interface TradeItemResponse {
  results: TradeItemUnit[];
  paging: Paging;
  filters: Filter[];
  appliedFilters: AppliedFilter[];
  search?: Search;
}

export interface TradeItemRequest {
  currentBrand: BrandConfig | undefined;
  appliedFilters: FilterRequest[];
  pagination: PaginationRequest;
  query: string;
}

export interface TradeItemDetailByTradeIdResponse {
  match: MatchType;
  events: EventType[];
  product_id: string;
  tradable_amount: number;
  player: Player;
  ball_id: string;
  brand_id: string;
  user: User;
}

export interface TradeItemDetailResponse {
  match: MatchType;
  ball_id: string;
  is_own_goal: number;
  product_id: string;
  player: Player;
  tradable_pieces: TradablePieces[];
  tradable_amount: number;
  events: EventType[];
}
export interface EventType {
  event_data: {
    home_partial_score: number;
    away_partial_score: number;
    is_own_goal: boolean;
  };
  event_minute_in_match: number;
  event_type: string;
}

export interface TradablePiecesProps {
  user: User;
  tradeId?: string;
  pieceNumber: number;
}

export interface MatchType {
  home: Home;
  away: Away;
}

export interface TradablePieces {
  user: User;
  piece_number: number;
  trade_id: string;
}

interface User {
  nickname: string;
  avatar_url: string;
  total_amount: number;
  user_id: string;
  is_public: boolean;
  country_id?: string;
  is_self?: boolean;
}

export interface Player {
  name: string;
  team_id: string;
  image: string;
}

export interface OfferResponse {
  tx_id: string;
  status: OfferStatus;
  type: OfferType;
  trading_user: OfferUser;
  offered_item: OfferedItem;
  trading_items: OfferedItem[];
}

export enum OfferType {
  SENT = 'sent',
  RECEIVED = 'received',
}

export enum OfferStatus {
  OPEN = 'opened',
  REJECT = 'rejected',
  ACCEPT = 'accepted',
  EXPIRED = 'expired',
  COMPLETE = 'complete',
}

export interface OfferedItem {
  match?: MatchType;
  events?: EventType[];
  player: Player;
  ball_id: string;
  brand_id?: string;
  piece_number: number;
  product_id: string;
}

export interface OfferUser {
  user_id: string;
  nickname: string;
  avatar_url: string;
  total_amount: number;
  country_id?: string;
}

export interface SendOfferRequest {
  trade_piece_id: string;
  trading_items: PublishItemRequest[];
}
