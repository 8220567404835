export const MIN_QUANTITY_SELECTED = 1;

export const MAX_QUANTITY_SELECTED = 5;

export const PRODUCT_DETAIL_PRICE = {
  xs: 18,
  md: 22,
};

export const GOLPOINTS_LOGO = 22;
