import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isNotEmpty } from 'ramda-adjunct';
import React from 'react';

import theme from '../../../theme';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { useCheckoutPay } from '../../hooks/useCheckoutPay';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { IMAGE_FALLBACK } from '../../pages/checkout/Checkout.constants';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import { CheckoutFreeProps } from './CheckoutFree.types';

export const CheckoutFree = ({
  paymentUrls,
  checkoutResponse,
}: CheckoutFreeProps) => {
  const { successUrl, cancelUrl } = paymentUrls;
  const {
    errorCode,
    isLoading: createPayIsLoading,
    isSuccess: createPayIsSuccess,
    handlePayment,
  } = useCheckoutPay({ successUrl, cancelUrl });
  const { t } = useTranslationByNamespaces('pages.checkout');
  const isMobile = useMobileMediaQuery();

  const { items } = checkoutResponse;

  return (
    <Stack
      alignContent="center"
      bgcolor="background.paper"
      gap={SpaceSizes.mdPlus}
      justifyContent="center"
      p={SpaceSizes.md}
      sx={{
        borderRadius: IMAGE_FALLBACK.BORDER_RADIUS,
        flexDirection: { xs: 'column' },
      }}
      width="100%"
    >
      <Stack width="100%">
        {items?.map((item) => (
          <Stack key={item.product_id}>
            <Box border-radius={IMAGE_FALLBACK.BORDER_RADIUS} width="100%">
              <ImageFallback
                height={
                  isMobile ? IMAGE_FALLBACK.HEIGHT : IMAGE_FALLBACK.HEIGHT
                }
                src={item.image}
                style={{
                  borderRadius: IMAGE_FALLBACK.BORDER_RADIUS,
                  objectFit: 'cover',
                }}
                width="100%"
              />
            </Box>
            <Stack gap={SpaceSizes.mdPlus}>
              <Stack gap={SpaceSizes.xs}>
                <Typography
                  color="text.secondary"
                  fontWeight="500"
                  variant="cH5"
                >
                  {item.entity_name}
                </Typography>
                <Typography variant="cH1">{item.title}</Typography>
                <Typography color="text.secondary" fontWeight="600" />
              </Stack>
              {item.is_assigned && (
                <Stack gap={SpaceSizes.xs}>
                  <AlertCard
                    content={t('alerts.physicalBall.title')}
                    id="alert-physicalBall"
                    severity={AlertSeverity.INFO}
                    sx={{
                      margin: SpaceSizes.none,
                      padding: SpaceSizes.none,
                      fontSize: isMobile
                        ? typography.cBody1.fontSize
                        : typography.cH6.fontSize,
                    }}
                    show
                  />
                </Stack>
              )}
              <Stack gap={SpaceSizes.xs} textAlign="center">
                <Stack
                  flexDirection="row"
                  gap={SpaceSizes.sm}
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>{t('amounts.priceGolball')}</Typography>
                  <Typography>{t('amounts.free')}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack alignItems="center" gap={SpaceSizes.sm} width="100%">
        {isNotEmpty(errorCode) && (
          <AlertCard
            content={t(`errors.${errorCode}`)}
            id="alert-payError"
            show={isNotEmpty(errorCode)}
            sx={{
              margin: SpaceSizes.none,
              padding: SpaceSizes.none,
              fontSize: isMobile
                ? typography.cBody1.fontSize
                : typography.cH6.fontSize,
            }}
          />
        )}
        <PaymentButton
          buttonText={t('buy')}
          dataTestId="payment-button"
          disableButton={false}
          handleBuy={handlePayment}
          id="buy_free"
          showPrice={false}
          sx={{ width: '100%' }}
          variant={isMobile ? 'body2' : 'subtitle1'}
        />
        <Backdrop
          open={createPayIsLoading || createPayIsSuccess}
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Stack>
    </Stack>
  );
};
