import { useGrowthBook } from '@growthbook/growthbook-react';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, Stack, Typography } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationStatus } from '../../../../features/notifications/responses/Notification';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { WEB3_FEATURES } from '../../../../utils/growthbookFeatures';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { TradeNotificationProps } from '../Notification.types';
import NotificationAlert from '../NotificationAlert';
import { NotificationContext } from '../NotificationContext';
import TradeOfferNotificationProduct from './TradeOfferNotificationProduct';

const TradeOfferNotification = ({
  notification,
  ...props
}: TradeNotificationProps) => {
  const isMobile = useMobileMediaQuery();
  const growthbook = useGrowthBook();
  const isWeb3FeatureEnabled = growthbook?.isOn(WEB3_FEATURES);
  const { t } = useTranslationByNamespaces('header.userMenu.notifications');
  const navigate = useNavigate();
  const context = useContext(NotificationContext);

  const date = new Date(notification.created_dt);

  const formattedDate = date?.toLocaleDateString('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const yourOffer = notification.extra_data?.your_offer;
  const hisOffer = notification.extra_data?.his_offer;

  const navigateToTrade = useCallback(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (context) context.close();
    navigate(`/trades/offer/${notification.extra_data?.tx_id}`);
  }, [context, navigate, notification.extra_data?.tx_id]);

  return (
    isWeb3FeatureEnabled && (
      <Stack
        {...props}
        bgcolor="background.paper"
        borderRadius={SpaceSizes.xs}
        mb={SpaceSizes.xs}
        mr={SpaceSizes.md}
        px={SpaceSizes.mdPlus}
        py={SpaceSizes.md}
      >
        <Stack direction="row">
          <Typography fontSize={SpaceSizes.smPlus} sx={{ opacity: 0.4 }}>
            {formattedDate}
          </Typography>
        </Stack>
        <Stack alignContent="center" spacing={SpaceSizes.sm}>
          <Stack
            alignContent="center"
            direction="column"
            justifyContent="space-between"
            spacing={SpaceSizes.sm}
            width="100%"
          >
            <Typography>
              {t(`type.${notification.type || 'UNHANDLED'}`)}
            </Typography>
            {yourOffer && hisOffer && (
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent={isMobile ? 'space-between' : 'space-evenly'}
                sx={{ opacity: 0.6 }}
                width="100%"
              >
                <TradeOfferNotificationProduct count={1} offer={yourOffer} />
                <SyncAltIcon fontSize="small" />
                <TradeOfferNotificationProduct
                  count={hisOffer.length}
                  offer={hisOffer[0]}
                />
              </Stack>
            )}
            <Stack width="100%">
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                my={SpaceSizes.sm}
                width="100%"
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={
                    notification.extra_data?.tx_id ? navigateToTrade : undefined
                  }
                >
                  {t('trade.details')}
                </Button>
                {notification.status_code === NotificationStatus.SHOWN && (
                  <NotificationAlert />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    )
  );
};

export default TradeOfferNotification;
