import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import {
  selectIsUserLoggedIn,
  selectUserSession,
} from '../../features/session/Session.selector';
import { useAppSelector } from '../hooks';

export default function useSentryUser() {
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const user = useAppSelector(selectUserSession);

  useEffect(() => {
    if (isLoggedUser && user) {
      Sentry.setUser({ email: user.email, id: user.user_id });
    }
  });
}
