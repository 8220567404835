import { useGrowthBook } from '@growthbook/growthbook-react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import env from '../../../env/env';
import {
  selectIsUserLoggedIn,
  selectUserId,
} from '../../../features/session/Session.selector';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { SPONSOR_USERS } from '../../../utils/growthbookFeatures';
import { TAG_EVENT } from '../../../utils/tagger';
import { AccordionSection } from '../../components/AccordionSection/AccordionSection';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import BookmarkAndShare from '../../components/BookmarkAndShare/BookmarkAndShare';
import { CollectableMainWidget } from '../../components/CollectableMainWidget/CollectableMainWidget';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { DirectSaleInfo } from '../../components/DirectSale/DirectSaleInfo';
import HowPossible from '../../components/HowPossible/HowPossible';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import Panel from '../../components/Panel/Panel';
import ProductAssignmentWarning from '../../components/ProductAssignmentWarning/ProductAssignmentWarning';
import { ProductBenefitsWidget } from '../../components/ProductBenefitsWidget/ProductBenefitsWidget';
import { CertificatesTabs } from '../../components/ProductCertificatesTabsWidget/CertificatesTabs';
import { ProductContentWidget } from '../../components/ProductContentWidget/ProductContentWidget';
import { ProductDetail } from '../../components/ProductDetail/ProductDetail';
import { ProductEditionInfo } from '../../components/ProductEdition/ProductEditionInfo';
import { ProductStatsTableWidget } from '../../components/ProductStatsTableWidget/ProductStatsTableWidget';
import { ProductTagTypeEnum } from '../../components/ProductTag/ProductTag.types';
import { RelatedProducts } from '../../components/RelatedProducts/RelatedProducts';
import { TriviaCountdown } from '../../components/Trivia/TriviaCountdown';
import TriviaStartedNoOwner from '../../components/TriviaDetails/TriviaStartedNoOwner';
import { useAppSelector } from '../../hooks';
import { useProducts } from '../../hooks/modules/Product/useProducts';
import useBigDesktopMediaQuery from '../../hooks/useBigDesktopMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { CHECKOUT_DIRECT_STATUS_CODE } from './Product.constant';

const Product = () => {
  const isTablet = useTabletMediaQuery();
  const isDesktop = useBigDesktopMediaQuery();
  const { productId } = useParams();
  const navigate = useNavigate();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const [showAlert, setShowAlert] = useState(true);
  const [errorCode, setErrorCode] = useState({
    id: '',
    exist: false,
  });
  const userId = useAppSelector(selectUserId);
  const [sponsorUsers, setSponsorUsers] = useState<string[]>([]);

  const growthbook = useGrowthBook();
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const onBackButtonClick = useCallback(() => {
    navigate('/marketplace');
  }, [navigate]);

  const {
    isLoading,
    isError,
    isUninitialized,
    data: productResponse,
  } = useProducts(productId);

  const {
    isStatusFinished,
    product,
    isBuyAllowed,
    hasPhysicalBall,
    notAssignableOrAlreadyAssigned,
    homeGoal: homeGoalTime,
    awayGoal: awayGoalTime,
    productEvent: productEventTime,
    product3dMultimedia,
    product3dCertificateMultimedia,
    productGoalVideoMultimedia,
    productTags,
    productEvent,
    tournament,
    competition,
    stage,
    collectibles,
    goalClubId,
    priceValue,
    homeClub: homeClubTime,
    awayClub: awayClubTime,
    productBallName: productBallId,
    eventImages,
    contents,
    productIsHolded,
    player,
    match,
    assigment,
    currency,
    isOwned,
    isTriviaStartedOrFinished,
    isTriviaNotStarted,
    isProductAuction,
  } = productResponse;

  const productDetailResponse = {
    awayClubTime,
    awayGoalTime: awayGoalTime ?? false,
    homeClubTime,
    homeGoalTime: homeGoalTime ?? false,
    isUninitialized,
    isLoading,
    player,
    productEvent,
    productGoalVideoMultimedia,
    product,
    priceValue,
    currency,
    setErrorCode,
    isBuyAllowed,
    setShowAlert,
    productIsHolded,
    collectibles,
    isProductAuction,
  };

  useEffect(() => {
    const cooldown = setTimeout(() => {
      setShowAlert(false);
      setErrorCode({ id: '', exist: false });
    }, 10000);

    return () => clearTimeout(cooldown);
  }, [errorCode]);

  useEffect(() => {
    const users = growthbook?.getFeatureValue(SPONSOR_USERS, []);
    setSponsorUsers(users ?? []);
  }, [growthbook]);

  useEffect(() => {
    if (!isProductAuction && collectibles?.on_sale === 0) {
      setErrorCode({
        id: CHECKOUT_DIRECT_STATUS_CODE.OUT_OF_STOCK,
        exist: true,
      });

      setShowAlert(true);
    }

    if (
      collectibles &&
      collectibles?.on_sale !== 0 &&
      selectedQuantity > collectibles?.total
    ) {
      setErrorCode({
        id: CHECKOUT_DIRECT_STATUS_CODE.INSUFFICIENT_STOCK,
        exist: true,
      });

      setShowAlert(true);
    }
  }, [collectibles, selectedQuantity, isProductAuction]);

  return (
    <PageContainer
      {...(isError && {
        errorCode: 'pages.product.piece.modal.errors.standard',
      })}
      id={`productDetails-${product?.product_id}`}
      maxWidth="md"
      title={t('pages.product.productDetail')}
      trackCode={TAG_EVENT.CONTENT_GROUP.PRODUCT}
      backButton
      onBackButtonClick={onBackButtonClick}
    >
      <Stack
        data-testid={`productDetails-${product?.product_id}`}
        spacing={SpaceSizes.md}
        width={isTablet ? '100%' : '95%'}
      >
        <CollectableMainWidget
          images={eventImages}
          isLoading={isLoading || isUninitialized}
          rightChild={
            isDesktop && (
              <Stack gap={SpaceSizes.md}>
                {userId && sponsorUsers.includes(userId) && (
                  <Panel>
                    {t('pages.product.collaboration')}
                    <ImageFallback
                      alt="movistar"
                      height={SpaceSizes.lg}
                      src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/logos/brands/movistar/movistar-big.png`}
                      width="auto"
                    />
                  </Panel>
                )}
                <Box p={SpaceSizes.sm}>
                  <ProductStatsTableWidget
                    awayClub={awayClubTime}
                    competition={competition}
                    goalClubId={goalClubId}
                    homeClub={homeClubTime}
                    isLoading={isLoading || isUninitialized}
                    match={match}
                    player={player}
                    productEvent={productEventTime}
                    stage={stage}
                    tournament={tournament}
                  />
                </Box>
              </Stack>
            )
          }
          video={productGoalVideoMultimedia?.location}
        />

        <Grid
          alignItems="stretch"
          columnSpacing={SpaceSizes.sm}
          justifyContent="center"
          container
        >
          {errorCode.exist && showAlert && (
            <Grid style={{ paddingLeft: 0 }} xs={12} item>
              <AlertCard
                content={t(`pages.product.piece.errors.${errorCode.id}`)}
                id={`${errorCode.id}_ERROR`}
                severity={AlertSeverity.WARNING}
                show
              />
            </Grid>
          )}
          <Grid style={{ paddingLeft: 0, paddingRight: 8 }} xs={12} item>
            <Stack gap={SpaceSizes.md} justifyContent="center" width="100%">
              <ProductDetail
                productDetailResponse={productDetailResponse}
                selectedQuantity={selectedQuantity}
                setSelectedQuantity={setSelectedQuantity}
                tags={productTags}
                validations={{
                  isLoggedUser,
                  productIsHolded: !!productIsHolded,
                  isStatusFinished,
                }}
              />
              {/* Show collection info on desired products */}
              {productTags.includes(ProductTagTypeEnum.LIMITED_EDITION) && (
                <ProductEditionInfo
                  maxSupply={collectibles?.total}
                  onSale={collectibles?.on_sale}
                />
              )}
              {productTags.includes(ProductTagTypeEnum.DIRECT_SALE) && (
                <DirectSaleInfo />
              )}
              {isTriviaNotStarted && assigment?.start_date && (
                <TriviaCountdown
                  assigmentStartDate={assigment?.start_date}
                  isOwned={isOwned ?? false}
                />
              )}
              {product && <BookmarkAndShare product={product} />}
              {isDesktop &&
                !isLoading &&
                product &&
                notAssignableOrAlreadyAssigned && <ProductAssignmentWarning />}
              {isTriviaStartedOrFinished && <TriviaStartedNoOwner />}
            </Stack>
          </Grid>
        </Grid>

        {!isDesktop && (
          <>
            {notAssignableOrAlreadyAssigned && <ProductAssignmentWarning />}
            {userId && sponsorUsers.includes(userId) && (
              <Panel>
                {t('pages.product.collaboration')}
                <ImageFallback
                  alt="movistar"
                  height={SpaceSizes.lg}
                  src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/logos/brands/movistar/movistar-big.png`}
                  width="auto"
                />
              </Panel>
            )}
            <Stack py={SpaceSizes.xs}>
              <ProductStatsTableWidget
                awayClub={awayClubTime}
                competition={competition}
                homeClub={homeClubTime}
                isLoading={isLoading || isUninitialized}
                match={match}
                player={player}
                productEvent={productEventTime}
                stage={stage}
                tournament={tournament}
              />
            </Stack>
          </>
        )}
        {!isProductAuction && (
          <CertificatesTabs
            isLoading={isLoading || isUninitialized}
            isLocked={!isOwned}
            isMissing={!hasPhysicalBall}
            product3dCertificateMultimedia={product3dCertificateMultimedia}
            product3dMultimedia={product3dMultimedia}
            productBallId={productBallId}
          />
        )}
        {contents && <ProductContentWidget contents={contents} />}
        <HowPossible isProductAuction={isProductAuction} />
        {!isProductAuction && (
          <ProductBenefitsWidget hasPhysicalBall={hasPhysicalBall ?? false} />
        )}
        {productId && <RelatedProducts productId={productId} />}
        {productEventTime?.description && (
          <Box py={SpaceSizes.md}>
            <AccordionSection titleKey="pages.product.multimediaSection.comments">
              <Typography
                sx={{ paddingX: SpaceSizes.md, paddingBottom: SpaceSizes.md }}
              >
                {productEventTime?.description}
              </Typography>
            </AccordionSection>
          </Box>
        )}
      </Stack>
    </PageContainer>
  );
};

export default Product;
