import {
  CARD_HEIGHT,
  CARD_WIDTH,
} from '../app/components/BuyCard/BuyCard.constants';
import {
  CARD_HEIGHT_MOBILE,
  CARD_WIDTH_MOBILE,
  RELATED_CARD_HEIGHT,
  RELATED_CARD_HEIGHT_LARGE,
  RELATED_CARD_WIDTH,
  RELATED_CARD_WIDTH_LARGE,
} from '../app/components/ProductCard/ProductCard.constants';
import useDesktopDeviceMediaQuery from '../app/hooks/useDesktopDeviceMediaQuery';
import useDesktopMediaQuery from '../app/hooks/useDesktopMediaQuery';
import useMobileMediaQuery from '../app/hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../app/hooks/useTabletMediaQuery';

export const useGetSizeToCard = (isRelatedProduct?: boolean) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const isSmallDesktop = useDesktopMediaQuery();
  const isDesktop = useDesktopDeviceMediaQuery();
  if (isRelatedProduct) {
    if (isMobile || isTablet || (isSmallDesktop && !isDesktop)) {
      return {
        heightDevice: RELATED_CARD_HEIGHT,
        widthDevice: RELATED_CARD_WIDTH,
      };
    }
    return {
      heightDevice: RELATED_CARD_HEIGHT_LARGE,
      widthDevice: RELATED_CARD_WIDTH_LARGE,
    };
  }
  if (isMobile) {
    return {
      heightDevice: CARD_HEIGHT_MOBILE,
      widthDevice: CARD_WIDTH_MOBILE,
    };
  }
  return {
    heightDevice: CARD_HEIGHT,
    widthDevice: CARD_WIDTH,
  };
};
