import { Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../env/env';
import { selectCart } from '../../../features/shoppingCart/ShoppingCart.selectors';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { CardsCartContainer } from '../../components/CardsCart/CardsCartContainer';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import { RelatedProducts } from '../../components/RelatedProducts/RelatedProducts';
import { useAppSelector } from '../../hooks';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { IMAGE_SIZE_DESKTOP, IMAGE_SIZE_MOBILE } from './Cart.constants';
import { useFetchData } from './useFetchData';

const navigationPath = '/marketplace';
const emptyCartSvg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/cart/cart-empty.svg`;

const MotionStack = motion(Stack);
export const Cart = () => {
  const { t } = useTranslationByNamespaces('pages.cart');
  const navigate = useNavigate();
  const cart = useAppSelector(selectCart);
  const isMobile = useMobileMediaQuery();

  const { isLoading, isUninitialized, isError, data } = useFetchData();
  const { shoppingCartResponse: items } = data;

  const cartEmpty = cart.length === 0;
  const productId = items && items[0]?.product.id;

  return (
    <PageContainer
      {...(isError ? { errorCode: t('errors.GENERIC_ERROR') } : {})}
      id="cartPage"
      loading={isLoading && !isUninitialized && !items && !productId}
      title={t('title')}
    >
      {cartEmpty ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          width={isMobile ? '100%' : '50%'}
        >
          <ImageFallback
            fallback={emptyCartSvg}
            height={isMobile ? IMAGE_SIZE_MOBILE : IMAGE_SIZE_DESKTOP}
            src={emptyCartSvg}
            width={isMobile ? IMAGE_SIZE_MOBILE : IMAGE_SIZE_DESKTOP}
          />
          <Stack gap={SpaceSizes.md} justifyContent="center" textAlign="center">
            <Typography fontWeight={800} variant={isMobile ? 'body1' : 'h6'}>
              {t('emptyState.title')}
            </Typography>
            <Typography fontWeight={600} variant={isMobile ? 'body2' : 'body1'}>
              {t('emptyState.subtitle')}
            </Typography>
            <Button
              sx={{
                width: '100%',
              }}
              variant="contained"
              onClick={() => navigate(navigationPath)}
            >
              {t('emptyState.button')}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Stack gap={SpaceSizes.xl} py={SpaceSizes.md} width="100%">
          <CardsCartContainer />
          <MotionStack gap={SpaceSizes.md} width="100%">
            <RelatedProducts
              canUserClaim={false}
              productId={productId}
              title={
                <Typography
                  fontWeight="bold"
                  textAlign="center"
                  variant={isMobile ? 'body1' : 'h5'}
                >
                  {t('relatedProducts.title')}
                </Typography>
              }
            />
          </MotionStack>
        </Stack>
      )}
    </PageContainer>
  );
};
