import { golpointsImg } from '../../../features/golpoints/Golpoints.constants';
import { PriceCurrency } from '../../../features/products/Product.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

const PRODUCT_PRICE_LOGO_DEFAULT_SIZE = {
  height: SpaceSizes.md,
  width: SpaceSizes.md,
};

export const SUMMARY_FONT_SIZE = {
  xs: 10,
  sm: 14,
  md: 14,
};

const PRODUCT_PRICE_DEFAULT_CURRENCY: PriceCurrency = 'GOL';
const PRODUCT_PRICE_DEFAULT_LOGO = golpointsImg;

const ProductPriceSize = {
  verysmall: {
    logo: {
      width: 12,
      height: 12,
    },
    typography: {
      fontSize: '.6rem',
      fontWeigth: '400',
    },
  },
  small: {
    logo: {
      width: 12,
      height: 12,
    },
    typography: {
      fontSize: 'small',
      fontWeight: '400',
    },
  },
  medium: {
    logo: {
      width: 18,
      height: 18,
    },
    typography: {
      fontSize: 'medium',
      fontWeight: '500',
    },
  },
  large: {
    logo: {
      width: 20,
      height: 20,
    },
    typography: {
      fontSize: 'large',
      fontWeight: '800',
    },
  },
};

export {
  PRODUCT_PRICE_DEFAULT_CURRENCY,
  PRODUCT_PRICE_DEFAULT_LOGO,
  PRODUCT_PRICE_LOGO_DEFAULT_SIZE,
  ProductPriceSize,
};
