import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useBigDesktopMediaQuery from '../../hooks/useBigDesktopMediaQuery';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { ProductStatsTableWidgetSkeleton } from '../ProductStatsTableWidget/ProductStatsTableWidget.skeleton';
import ProductSwiper from '../ProductSwiper/ProductSwiper';
import { COLLECTABLE_MAIN_WIDGET_CARD_HEIGHT } from './CollectableMainWidget.constants';
import { ICollectableMainWidgetProps } from './CollectableMainWidget.types';

export const CollectableMainWidget = ({
  images,
  isLoading,
  rightChild,
  video,
}: ICollectableMainWidgetProps) => {
  const isDesktop = useBigDesktopMediaQuery();
  const isMobile = useMobileMediaQuery();

  const HEIGHT = isDesktop
    ? COLLECTABLE_MAIN_WIDGET_CARD_HEIGHT.isDesktop
    : COLLECTABLE_MAIN_WIDGET_CARD_HEIGHT.isMobile;

  if (isLoading)
    return (
      <Grid justifyContent="space-between" container>
        <Grid lg={5.8} xs={12} item>
          <Skeleton
            height={HEIGHT}
            sx={{ borderRadius: 5 }}
            variant="rectangular"
          />
        </Grid>
        {isDesktop && (
          <Grid lg={5.8} xs={12} item>
            <ProductStatsTableWidgetSkeleton />
          </Grid>
        )}
      </Grid>
    );

  const filteredImages = images?.filter((image) => image !== '');

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        background: 'transparent',
        ...(isMobile ? { borderRadius: 10 } : {}),
        ...(isDesktop ? { minHeight: HEIGHT } : {}),
      }}
    >
      <Grid
        alignItems="center"
        columnSpacing={SpaceSizes.lg}
        justifyContent="center"
        container
      >
        <Grid height={HEIGHT} lg={6} xs={12} item>
          <ProductSwiper images={filteredImages} video={video} />
        </Grid>

        <Grid lg={6} xs={12} item>
          {rightChild}
        </Grid>
      </Grid>
    </Box>
  );
};
