import { Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';

export const CheckoutSkeleton = () => {
  return (
    <PageContainer id="checkout-skeleton" maxWidth="sm">
      <Stack gap={SpaceSizes.lg} width="100%">
        <Skeleton height={100} variant="rectangular" />
        <Skeleton height={200} variant="rectangular" />
      </Stack>
    </PageContainer>
  );
};
