import { map, props } from 'ramda';

import env from '../../../env/env';
import { api, RTKTags } from '../../api/Api';
import { SearchRequest, SearchResponse } from '../responses/SearchResponse';

const transformSearchRequestToSearchParams = (request: SearchRequest) => {
  const { appliedFilters, sortFilter, pagination, currentBrand } = request;
  let searchParams: URLSearchParams;
  if (appliedFilters && appliedFilters.length > 0) {
    const p = map(props(['id', 'value']));
    searchParams = new URLSearchParams(p(appliedFilters));
  } else {
    searchParams = new URLSearchParams();
  }

  if (sortFilter.value) {
    searchParams.set(sortFilter.id, sortFilter.value);
  }

  if (pagination.limit !== undefined) {
    searchParams.set('limit', pagination.limit.toString());
  }

  if (pagination.offset !== undefined) {
    searchParams.set('offset', pagination.offset.toString());
  }

  if (currentBrand && currentBrand.id !== 'default') {
    searchParams.set('brand', currentBrand.id);
  }

  return searchParams.toString();
};

export const myCollectionFilterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchMyCollection: builder.query<SearchResponse, SearchRequest>({
      query: (request) => {
        const urlSearchParams = transformSearchRequestToSearchParams(request);
        return `${env.REACT_APP_BASE_API_URL_SEARCH}search/my-collection?q=${request.query}&${urlSearchParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: () => [RTKTags.MyCollection],
      serializeQueryArgs: ({ queryArgs }) => {
        const value = [
          queryArgs.currentBrand?.id || '',
          queryArgs.query || '',
          queryArgs.appliedFilters ?? [],
        ];
        return value;
      },
      merge: (currentCache, newItems, { arg: request }) => {
        let { results } = newItems;
        // if offset is not zero, we have to merge only if newItems has a new page
        // if offset is zero, always return newItems.results (replacing with data from backend)
        if (request.pagination.offset !== 0) {
          if (
            !newItems.results[0] ||
            currentCache.results.find(
              (product) =>
                product.product_id === newItems.results[0].product_id,
            )
          ) {
            return currentCache;
          }

          results = currentCache.results.concat(newItems.results);
        }

        const newResponse: SearchResponse = {
          results,
          paging: newItems.paging,
          filters: newItems.filters,
          appliedFilters: newItems.appliedFilters,
          search: newItems.search,
        };

        return newResponse;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          // Case 1: We've moved back to the first page due to an update on elements
          (currentArg?.pagination?.offset === 0 &&
            (previousArg?.pagination?.offset ?? 0) > 0) ||
          // Case 2: We've moved to a subsequent page.
          (currentArg?.pagination?.offset ?? 0) >
            (previousArg?.pagination?.offset ?? 0)
        );
      },
    }),
  }),
});

export const { useSearchMyCollectionQuery } = myCollectionFilterApi;
