import { Stack, Typography } from '@mui/material';
import React from 'react';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopMediaQuery from '../../hooks/useDesktopMediaQuery';
import { Link } from '../Link/Link';

const TRIVIA_BALL = `${env.REACT_APP_STATIC_BASE_URL}marketplace/trivia/trivia-ball.png`;

export const TriviaKnowMore = ({ isOwned }: { isOwned: boolean }) => {
  const { t } = useTranslationByNamespaces('pages.product.trivia.countdown');

  const isDesktop = useDesktopMediaQuery();

  return (
    <Stack alignItems="center" direction="row" spacing={SpaceSizes.md}>
      <img
        alt="Trivia ball"
        height={isDesktop ? SpaceSizes.xlPlus : SpaceSizes.xl}
        src={TRIVIA_BALL}
      />
      <Stack alignItems="flex-start" direction="column" gap={SpaceSizes.sm}>
        <Typography textAlign="left" variant="body1">
          {t(isOwned ? 'infoIsOwned' : 'info')}
        </Typography>
        <Link
          color="secondary"
          id="trivial_info_product_detail"
          to="/trivial"
          external
        >
          <Typography variant="body1">{t('knowMore')}</Typography>
        </Link>
      </Stack>
    </Stack>
  );
};
