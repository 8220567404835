export enum CardCartStatus {
  Increase = 'increase',
  Warning = 'warning',
  Common = 'common',
}

export enum CardsCartOperation {
  ADD = 'add',
  INCREASE = 'increase',
  DECREASE = 'decrease',
  REMOVE = 'remove',
  RESTORE = 'restore',
}
