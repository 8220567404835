import { Skeleton, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetCollectionsGroupsQuery } from '../../../features/collections/Collections.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import useDesktopMediaQuery from '../../hooks/useDesktopMediaQuery';
import { ICollectionsGroup } from './CollectionCard.types';
import { CollectionGroupList } from './CollectionGroupList';
import {
  COLLECTIONS_TITLE_SKELETON_HEIGHT,
  COLLECTIONS_TITLE_SKELETON_WIDTH,
} from './Collections.constants';

const Collections = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: collectionsData,
    isSuccess,
    isLoading,
  } = useGetCollectionsGroupsQuery(null);
  const [collections, setCollections] = useState<ICollectionsGroup[]>();
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    if (isSuccess) {
      setCollections(collectionsData);
    }
  }, [isSuccess, collectionsData]);

  const onBackButtonClick = useCallback(() => {
    navigate(`../`);
  }, [navigate]);
  const largeScreenFix = isDesktop ? { minHeight: '70vh' } : {};
  return (
    <PageContainer
      id="collectionsPage"
      loading={isLoading}
      sx={largeScreenFix}
      title={
        isLoading ? (
          <Skeleton
            sx={{
              height: COLLECTIONS_TITLE_SKELETON_HEIGHT,
              width: COLLECTIONS_TITLE_SKELETON_WIDTH,
            }}
            variant="rectangular"
          />
        ) : (
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={SpaceSizes.md}
            justifyContent="space-between"
          >
            {t('pages.collections.pageTitle')}
          </Stack>
        )
      }
      trackCode={TAG_EVENT.CONTENT_GROUP.COLLECTIONS}
      backButton
      onBackButtonClick={onBackButtonClick}
    >
      <CollectionGroupList collections={collections} isLoading={isLoading} />
      <ExternalContent
        filter={{ key: 'page_id', value: 'collections_footer' }}
        type="pages"
      />
    </PageContainer>
  );
};

export default Collections;
