import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetTradeDetailsQuery } from '../../../features/trades/Trades.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { TradeItemDetail } from '../../components/TradeItem/TradeItemDetail';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useDesktopDeviceMediaQuery from '../../hooks/useDesktopDeviceMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import {
  CARD_TRADE_MIN_HEIGHT,
  EMPTY_FONT_SIZE,
} from './CardTradeDetail.constants';
import { CardTradeDetailSkeleton } from './CardTradeDetail.skeleton';
import { CardTradeDetailSummary } from './CardTradeDetailSummary';

export const CardTradeDetail = () => {
  const { t } = useTranslationByNamespaces('pages.cardTrade');
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetTradeDetailsQuery(id || '');
  const { tradable_pieces: tradablePieces } = data || {};
  const isSmallestMobile = useSmallMobileMediaQuery();
  const isDesktop = useDesktopDeviceMediaQuery();

  // if the product doesn't exists at all
  if (!data?.ball_id && !isLoading)
    return (
      <Stack
        alignItems="center"
        gap={SpaceSizes.lg}
        my={SpaceSizes.lg}
        width="100%"
      >
        <Typography component="p" fontWeight={600} variant="cH3">
          {t('unknownProduct.pageTitle')}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/marketplace?tab=trade')}
        >
          <Typography component="p" fontWeight={400} variant="cH5">
            {t('unknownProduct.cta')}
          </Typography>
        </Button>
      </Stack>
    );

  return (
    <PageContainer
      id="card-trade-detail"
      sx={{ minHeight: CARD_TRADE_MIN_HEIGHT }}
      title={t('detail.pageTitle')}
      backButton
    >
      {isLoading ? (
        <CardTradeDetailSkeleton />
      ) : (
        <Grid spacing={isDesktop ? SpaceSizes.xs : SpaceSizes.md} container>
          <Grid my={SpaceSizes.lg} xs={isDesktop ? 6 : 12} item>
            <Stack
              gap={SpaceSizes.md}
              height="100%"
              position="relative"
              width="100%"
            >
              <Stack gap={SpaceSizes.xl}>
                <Stack gap={SpaceSizes.md}>
                  {data && (
                    <CardTradeDetailSummary
                      ballId={data.ball_id}
                      events={data.events}
                      match={data.match}
                      player={data.player}
                    />
                  )}

                  {isSmallestMobile && (
                    <Typography
                      color="text.primary"
                      fontWeight={400}
                      variant="cH6"
                    >
                      {t('detail.description')}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid my={SpaceSizes.lg} xs={isDesktop ? 6 : 12} item>
            <Stack gap={SpaceSizes.sm}>
              {tradablePieces &&
              data?.tradable_amount &&
              data.tradable_amount > 0 ? (
                tradablePieces.map((item) => (
                  <TradeItemDetail
                    key={item.piece_number}
                    pieceNumber={item.piece_number}
                    tradeId={item.trade_id}
                    user={item.user}
                  />
                ))
              ) : (
                <Stack
                  alignItems="center"
                  flexDirection="column"
                  gap={SpaceSizes.lg}
                >
                  <Typography
                    component="p"
                    fontSize={EMPTY_FONT_SIZE}
                    fontWeight={400}
                    textAlign="center"
                  >
                    {t('empty.description')}
                  </Typography>
                  <Stack width={{ xs: '100%', lg: '50%' }}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate('/marketplace?tab=trade')}
                    >
                      <Typography component="p" fontWeight={400} variant="cH5">
                        {t('empty.cta')}
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </PageContainer>
  );
};
