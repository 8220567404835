import { Add, Remove } from '@mui/icons-material';
import AlarmIcon from '@mui/icons-material/Alarm';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import ProductPrice from '../ProductPrice/ProductPrice';
import {
  ALARM,
  ALERT,
  IMAGE,
  LARGE_FONT_SIZE,
  removeRed,
  SMALL_FONT_SIZE,
} from './CardsCart.constants';
import { canDecreaseQuantity, canIncreaseQuantity } from './CardsCart.helpers';
import { CardsCartProps } from './CardsCart.interfaces';

const MotionStack = motion(Stack);

export const CardsCart: FC<CardsCartProps> = ({
  quantity,
  title,
  subtitle,
  entityName,
  priceValue,
  image,
  originalItem,
  productId,
  isCart = false,
  onRemove,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
}) => {
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();
  const { t } = useTranslationByNamespaces('pages.cart');

  const navigateToProduct = useCallback(() => {
    navigate(`/product/${productId}`);
  }, [productId, navigate]);

  const subtitleActual = `${subtitle} - ${entityName}`;

  const stockOutProduct = originalItem?.available_stock === 0;

  return (
    <MotionStack
      alignItems="center"
      animate="visible"
      bgcolor="background.paper"
      direction="row"
      exit="exit"
      initial="hidden"
      justifyContent="space-between"
      style={{
        borderTopLeftRadius: SpaceSizes.sm,
        borderBottomLeftRadius: SpaceSizes.sm,
        borderTopRightRadius: SpaceSizes.sm,
        borderBottomRightRadius: SpaceSizes.sm,
      }}
      variants={{
        hidden: removeRed.hidden,
        visible: removeRed.visible,
        exit: removeRed.exit,
      }}
      width="100%"
      layout
    >
      <Stack alignItems="center" direction="row" width="100%">
        <Stack
          height={
            isMobile ? IMAGE.SIZE_HEIGHT_MOBILE : IMAGE.SIZE_HEIGHT_DESKTOP
          }
          style={{ cursor: 'pointer' }}
          width={isMobile ? IMAGE.SIZE_WIDTH_MOBILE : IMAGE.SIZE_WIDTH_DESKTOP}
          onClick={navigateToProduct}
        >
          <ImageFallback
            alt="card-product"
            fallback={image}
            height="100%"
            src={image}
            style={{
              borderRadius: SpaceSizes.sm,
              objectFit: 'cover',
            }}
            width="100%"
          />
        </Stack>
        <Stack
          flexDirection="column"
          gap={isMobile ? SpaceSizes.xs : SpaceSizes.sm}
          paddingX={isMobile ? SpaceSizes.smPlus : SpaceSizes.md}
          width="100%"
        >
          {originalItem && isCart && (
            <Stack>
              <Stack>
                {originalItem.price.value !==
                  originalItem.product.price.value && (
                  <Stack>
                    <AlertCard
                      content={t('alerts.priceChanged', {
                        x1: originalItem.price.value.toString(),
                        x2: originalItem.product.price.value.toString(),
                      })}
                      customContainerStyles={{
                        paddingX: SpaceSizes.none,
                        width: isMobile
                          ? ALERT.SIZE_MOBILE
                          : ALERT.SIZE_DESKTOP,
                        height: isMobile
                          ? ALERT.SIZE_MOBILE
                          : ALERT.SIZE_DESKTOP,
                      }}
                      id="alert-price-change"
                      severity={AlertSeverity.INFO}
                      sx={{
                        alignItems: 'center',
                        padding: SpaceSizes.none,
                        fontSize: isMobile
                          ? typography.cCaption.fontSize
                          : typography.cH6.fontSize,
                      }}
                      show
                    />
                  </Stack>
                )}
                {originalItem.available_stock <
                  originalItem.selected_quantity &&
                  !stockOutProduct && (
                    <Stack>
                      <AlertCard
                        content={t('alerts.modifiedStock')}
                        customContainerStyles={{
                          paddingX: SpaceSizes.none,
                          width: isMobile
                            ? ALERT.SIZE_MOBILE
                            : ALERT.SIZE_DESKTOP,
                          height: isMobile
                            ? ALERT.SIZE_MOBILE
                            : ALERT.SIZE_DESKTOP,
                        }}
                        id="alert-quantity-stock-out"
                        severity={AlertSeverity.WARNING}
                        sx={{
                          alignItems: 'center',
                          padding: SpaceSizes.none,
                          fontSize: isMobile
                            ? typography.cCaption.fontSize
                            : typography.cH6.fontSize,
                        }}
                        show
                      />
                    </Stack>
                  )}
              </Stack>
            </Stack>
          )}

          <Stack
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack sx={{ cursor: 'pointer' }} onClick={navigateToProduct}>
              <Typography fontWeight={600} variant={isMobile ? 'body2' : 'h5'}>
                {title}
              </Typography>
            </Stack>
            {stockOutProduct ? (
              <Stack
                alignItems="center"
                flexDirection="row"
                gap={isMobile ? SpaceSizes.xs : SpaceSizes.md}
              >
                <Typography
                  fontSize={{
                    xs: ALARM.TYPOGRAPHY.xs,
                    sm: ALARM.TYPOGRAPHY.sm,
                    md: ALARM.TYPOGRAPHY.md,
                  }}
                >
                  {t('alerts.outOfStock')}
                </Typography>
                <AlarmIcon
                  sx={{
                    color: 'error.main',
                    fontSize: {
                      xs: ALARM.ICON.xs,
                      sm: ALARM.ICON.sm,
                      md: ALARM.ICON.md,
                    },
                  }}
                />
              </Stack>
            ) : (
              <Stack alignItems="center" direction="row" gap={SpaceSizes.xs}>
                <ProductPrice
                  height={isMobile ? SpaceSizes.smPlus : SpaceSizes.mdPlus}
                  size="large"
                  value={priceValue}
                  width={isMobile ? SpaceSizes.smPlus : SpaceSizes.mdPlus}
                  showLogo
                />
              </Stack>
            )}
          </Stack>
          <Stack
            alignItems="center"
            flexDirection="row"
            justifyContent={isCart ? 'flex-start' : 'space-between'}
          >
            <Stack
              sx={{ cursor: 'pointer' }}
              width={isCart ? '100%' : '80%'}
              onClick={navigateToProduct}
            >
              <Typography
                color="text.secondary"
                fontSize={isMobile ? SMALL_FONT_SIZE.md : SMALL_FONT_SIZE.lg}
              >
                {subtitleActual}
              </Typography>
            </Stack>
            {!isCart && (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                width="20%"
              >
                <Typography variant={isMobile ? 'caption' : 'h6'}>
                  {`X${quantity}`}
                </Typography>
              </Stack>
            )}
          </Stack>
          {isCart && (
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                justifyItems="space-between"
                sx={{ cursor: 'pointer' }}
                width="100%"
                onClick={onRemove}
              >
                <Stack
                  alignItems="center"
                  color={stockOutProduct ? 'error.main' : 'text.secondary'}
                  direction="row"
                  gap={SpaceSizes.sm}
                >
                  <DeleteIcon
                    color="inherit"
                    fontSize={isMobile ? 'small' : 'medium'}
                  />
                  <Typography
                    color="inherit"
                    fontSize={
                      isMobile ? SMALL_FONT_SIZE.xs : LARGE_FONT_SIZE.lg
                    }
                  >
                    {t('delete')}
                  </Typography>
                </Stack>
              </Stack>
              {stockOutProduct ? null : (
                <Stack
                  alignItems="center"
                  direction="row"
                  fontWeight="medium"
                  gap={SpaceSizes.md}
                >
                  <Remove
                    fontSize={isMobile ? 'small' : 'large'}
                    fontWeight="800"
                    sx={{
                      cursor: 'pointer',
                      color: canDecreaseQuantity(originalItem)
                        ? 'text.primary'
                        : 'text.disabled',
                    }}
                    onClick={
                      handleDecreaseQuantity
                        ? () => handleDecreaseQuantity(originalItem)
                        : undefined
                    }
                  />
                  <Typography
                    textAlign="center"
                    variant={isMobile ? 'caption' : 'h6'}
                    width={{
                      xs: SpaceSizes.xxs,
                      sm: SpaceSizes.xs,
                      lg: SpaceSizes.lg,
                    }}
                  >
                    {quantity}
                  </Typography>
                  <Add
                    fontSize={isMobile ? 'small' : 'large'}
                    fontWeight="800"
                    sx={{
                      cursor: 'pointer',
                      color: canIncreaseQuantity(originalItem)
                        ? 'text.primary'
                        : 'text.disabled',
                    }}
                    onClick={
                      handleIncreaseQuantity
                        ? () => handleIncreaseQuantity(originalItem)
                        : undefined
                    }
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </MotionStack>
  );
};
