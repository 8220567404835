import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../../env/env';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import ARROW_UP from '../../../assets/img/arrowUp.svg';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { ImageFallback } from '../../ImageFallback/ImageFallback';
import { sizeAvatar, sizeUserName } from './Nickname.constant';
import { PropsNickname } from './Nickname.type';

const anonymousUserUrl = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/anonymous-user.png`;

export const Nickname = ({ styleProps, userProps }: PropsNickname) => {
  const {
    flexDirection,
    variant = 'caption',
    width,
    height,
    fontWeight = '400',
  } = styleProps;
  const {
    countryId,
    getTrophy,
    profileUrl,
    isPublic,
    userName,
    isRanking = false,
    userId,
    position,
    ballsAmount,
    isSelf,
    positionIncrease,
    isThanksYouPage,
  } = userProps;
  const { t } = useTranslationByNamespaces('components.tableRank');
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();

  const countryImageUrl = useMemo(
    () =>
      `${
        env.REACT_APP_STATIC_BASE_URL
      }marketplace/img/commons/countries/${countryId?.toLowerCase()}.svg`,
    [countryId],
  );
  const navigateToUserProfile = useCallback(
    (rankingSection: boolean) => () => {
      if (!rankingSection || isThanksYouPage) return false;
      return navigate(`/user/${userId}`);
    },
    [navigate, userId, isThanksYouPage],
  );

  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      direction={flexDirection}
      gap={isRanking ? SpaceSizes.sm : SpaceSizes.md}
      justifyContent="space-between"
      style={{ cursor: isRanking ? 'pointer' : 'default' }}
      width={isRanking ? '100%' : 'auto'}
      onClick={navigateToUserProfile(isRanking)}
    >
      {isRanking && (
        <Stack alignItems="center" direction="row" width="100%">
          <Stack style={{ paddingLeft: SpaceSizes.md }}>
            {getTrophy ? (
              <ImageFallback
                fallback={getTrophy}
                height={isMobile ? SpaceSizes.mdPlus : SpaceSizes.lg}
                src={getTrophy}
                style={{
                  borderRadius: '100%',
                }}
                width={isMobile ? SpaceSizes.mdPlus : SpaceSizes.lg}
              />
            ) : (
              <Stack alignItems="center" direction="row" gap={SpaceSizes.xs}>
                {positionIncrease ? (
                  <ImageFallback
                    fallback={ARROW_UP}
                    height={isMobile ? SpaceSizes.md : SpaceSizes.mdPlus}
                    src={ARROW_UP}
                    width={isMobile ? SpaceSizes.md : SpaceSizes.mdPlus}
                  />
                ) : null}
                <Typography
                  fontWeight="700"
                  style={{
                    color: positionIncrease
                      ? theme.palette.success.main
                      : theme.palette.text.primary,
                  }}
                  variant={isMobile ? 'body2' : 'h6'}
                >
                  {position}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            style={{ paddingRight: SpaceSizes.sm, paddingLeft: SpaceSizes.sm }}
          >
            <ImageFallback
              fallback={profileUrl || anonymousUserUrl}
              height={sizeAvatar(isMobile, isThanksYouPage)}
              src={profileUrl || anonymousUserUrl}
              style={{ borderRadius: '100%' }}
              width={sizeAvatar(isMobile, isThanksYouPage)}
            />
          </Stack>
          <Stack direction="column">
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                fontWeight={fontWeight}
                sx={{
                  wordBreak: 'break-all',
                  textAlign: isMobile ? 'center' : 'start',
                }}
                variant={sizeUserName(isMobile, isThanksYouPage)}
              >
                {isPublic ? userName : t('anonymous')} {isSelf ? t('you') : ''}
              </Typography>
            </Box>
            <Typography
              fontWeight={fontWeight}
              sx={{
                color: theme.palette.text.primary,
                wordBreak: isThanksYouPage ? 'normal' : 'break-all',
                textAlign: 'start',
              }}
              variant={isMobile ? 'cCaption' : 'body2'}
            >
              {ballsAmount} {t('yourCollection')}
            </Typography>
          </Stack>
        </Stack>
      )}
      {!isRanking && (
        <Stack alignItems="center" direction="row">
          <Typography
            fontWeight={fontWeight}
            sx={{
              wordBreak: 'break-all',
              textAlign: isMobile ? 'center' : 'start',
            }}
            variant={variant}
          >
            {isPublic ? userName : t('anonymous')}
          </Typography>
          <ImageFallback
            fallback={countryImageUrl}
            height={height}
            src={countryImageUrl}
            style={{ marginLeft: SpaceSizes.smPlus }}
            width={width}
          />
        </Stack>
      )}
    </Stack>
  );
};
