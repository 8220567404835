import { useGrowthBook } from '@growthbook/growthbook-react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Stack } from '@mui/material';
import { isNonEmptyString } from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { selectIsUserLoggedIn } from '../../../../features/session/Session.selector';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import {
  SHOW_CART,
  SHOW_COLLECTIONS_BUTTON,
} from '../../../../utils/growthbookFeatures';
import { TAG_VALUE } from '../../../../utils/tagger';
import { LogoHeader } from '../../../components/header/LogoHeader/LogoHeader';
import { MenuIconItem } from '../../../components/MenuIconItem/MenuIconItem';
import { SearchBall } from '../../../components/search/SearchBall/SearchBall';
import { useAppSelector } from '../../../hooks';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { useMediumDesktopQuery } from '../../../hooks/useMediumDesktopQuery';
import useRedirectLoginSignup from '../../../hooks/useRedirectLoginSignup';
import { BrandMenu } from '../BrandMenu/BrandMenu';
import {
  HEADER_LOGO_HEIGHT_DESKTOP,
  HEADER_LOGO_WIDTH_DESKTOP,
} from '../Header.constants';
import { ShoppingCartMenu } from '../ShoppingCartMenu/ShoppingCartMenu';
import { MenuItem } from './MenuItem';
import UserMenu from './UserMenu';

const HeaderDesktop = () => {
  const { t } = useTranslationByNamespaces('header.topMenu');
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const [searchParams] = useSearchParams();
  const q = useMemo(() => searchParams.get('q') ?? '', [searchParams]);
  const [openSearch, setOpenSearch] = useState(isNonEmptyString(q));
  const isMediumDesktop = useMediumDesktopQuery();
  const growthbook = useGrowthBook();
  const showMultileague = growthbook?.isOn('show_multileague');

  const { loginRoute, signupRoute } = useRedirectLoginSignup();

  const handleOpenCloseSearch = useCallback(() => {
    setOpenSearch(!openSearch);
  }, [openSearch]);

  return (
    <Stack
      alignContent="center"
      direction="row"
      justifyContent="space-between"
      spacing={SpaceSizes.md}
      sx={{ px: SpaceSizes.sm, height: 40 }}
    >
      <Stack alignContent="center" justifyContent="center">
        <LogoHeader
          height={HEADER_LOGO_HEIGHT_DESKTOP}
          tagId={TAG_VALUE.HEADER.LOGO}
          width={HEADER_LOGO_WIDTH_DESKTOP}
        />
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={isMediumDesktop ? SpaceSizes.xs : SpaceSizes.md}
      >
        {!openSearch && (
          <Stack alignItems="center" direction="row" spacing={SpaceSizes.md}>
            <MenuItem
              data-testid="marketplaceButtonHeader"
              tagId={TAG_VALUE.HEADER.MARKETPLACE}
              text={t('marketplace')}
              to="/marketplace?tab=collectables"
            />
            <MenuItem
              data-testid="matchesButtonHeader"
              tagId={TAG_VALUE.HEADER.MATCHES}
              text={t('matches')}
              to="/matches"
            />
            {isLoggedUser && (
              <MenuItem
                data-testid="myCollectionButtonHeader"
                tagId={TAG_VALUE.HEADER.COLLECTIONS}
                text={t('myCollection')}
                to="/collections"
              />
            )}
            {!isLoggedUser && growthbook?.isOn(SHOW_COLLECTIONS_BUTTON) && (
              <MenuItem
                data-testid="myCollectionButtonHeader"
                tagId={TAG_VALUE.HEADER.COLLECTIONS}
                text={t('myCollection')}
                to="/collections/collections_landing"
              />
            )}
            {/* <MenuItem
              data-testid="membershipsButtonHeader"
              tagId={TAG_VALUE.HEADER.MEMBERSHIPS}
              text={t('memberships')}
              to="/memberships/laliga"
            /> */}
          </Stack>
        )}

        <SearchBall
          openSearch={openSearch}
          setOpenSearch={handleOpenCloseSearch}
        />
        {showMultileague && <BrandMenu />}
        <MenuIconItem tagId="rank" to="/rank">
          <EmojiEventsIcon />
        </MenuIconItem>
        {growthbook?.isOn(SHOW_CART) && isLoggedUser && <ShoppingCartMenu />}
        {isLoggedUser ? (
          <UserMenu isAuthenticated={isLoggedUser} />
        ) : (
          <>
            <MenuItem
              data-testid="loginButtonHeader"
              tagId={TAG_VALUE.HEADER.LOGIN}
              text={t('login')}
              to={loginRoute}
            />
            <MenuItem
              data-testid="signupButtonHeader"
              tagId={TAG_VALUE.HEADER.REGISTER}
              text={t('register')}
              to={signupRoute}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default HeaderDesktop;
