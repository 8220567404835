import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';

import { useGetCheckoutQuery } from '../../../features/checkout/Checkout.api';
import {
  selectCheckoutCreatedAt,
  selectCheckoutItems,
  selectCheckoutProductId,
  selectCheckoutTiers,
  selectCheckoutTotalPoints,
  selectCheckoutType,
  selectCheckoutUserBalance,
} from '../../../features/checkout/Checkout.selector';

export const useFetchData = (checkoutId?: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isUninitialized, setIsUninitialized] = useState(true);

  const checkoutData = useGetCheckoutQuery(checkoutId ?? skipToken, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      productId: selectCheckoutProductId(data),
      userBalance: selectCheckoutUserBalance(data),
      totalPoints: selectCheckoutTotalPoints(data),
      items: selectCheckoutItems(data),
      tiers: selectCheckoutTiers(data),
      type: selectCheckoutType(data),
      createdAt: selectCheckoutCreatedAt(data),
    }),
  });

  const {
    productId,
    userBalance,
    totalPoints,
    items,
    tiers,
    type,
    createdAt,
    isLoading: checkoutIsLoading,
    isError: checkoutIsError,
    isUninitialized: checkoutIsUninitialized,
  } = checkoutData;

  const data = {
    checkoutResponse: {
      productId,
      userBalance,
      totalPoints,
      items,
      tiers,
      type,
      createdAt,
    },
  };

  useEffect(() => {
    if (checkoutIsError) setIsError(true);
  }, [checkoutIsError]);

  useEffect(() => {
    if (!checkoutIsLoading) setIsLoading(false);
  }, [checkoutIsLoading]);

  useEffect(() => {
    if (!checkoutIsUninitialized) setIsUninitialized(false);
  }, [checkoutIsUninitialized]);

  return {
    isLoading,
    isError,
    isUninitialized,
    data,
  };
};
