/* eslint-disable @typescript-eslint/no-explicit-any */
export const handlePaginatedResponse = (
  currentCache: any,
  newItems: any,
  areItemsEqual: (item1: any, item2: any) => boolean,
) => {
  // First time or refresh, just return the newItems
  if (newItems.paging.offset === 0) {
    return newItems;
  }
  // Check if there are any new items to add to the cache
  if (
    !newItems.results[0] ||
    currentCache.results.some((item: any) =>
      areItemsEqual(item, newItems.results[0]),
    )
  ) {
    // No new items to add, return the current cache
    return currentCache;
  }
  // Concatenate new items to the current cache
  return {
    results: currentCache.results.concat(newItems.results),
    filters: newItems.filters,
    appliedFilters: newItems.appliedFilters,
    search: { searchQuery: newItems.searchQuery?.search ?? '' },
    paging: newItems.paging,
  };
};
