import { Items } from '../../app/components/CardsCart/CardsCart.interfaces';
import { STATUS_CODE_SHOPPING_CART } from '../shoppingCart/ShoppingCart.constants';

// This function allows the creation of validation for when the user tries to create a checkout. The first condition, 'statusCode !== STATUS_CODE_SHOPPING_CART.ADDED,' is meant to handle cases on the confirmation cart page when the user adds a new item to the cart. The second condition, 'cartActive.length === 0,' is used to validate whether a cart with products in the 'active' status exists and has a non-zero amount. The third condition is applicable when the user clicks the button and the call with rtk results in loading. The fourth condition, 'modifiedStock,' is used to determine if any product's quantity allowance has changed. Lastly, the 'outOfStock' condition is used to determine whether any product in the user's cart is out of stock.

export const shouldNotShowCheckoutButton = (
  cartActive: Items[],
  createCheckoutIsLoading: boolean,
  modifiedStock: boolean,
  outOfStock: boolean,
  statusCode?: string,
) => {
  return (
    (!statusCode || statusCode !== STATUS_CODE_SHOPPING_CART.ADDED) &&
    (cartActive.length === 0 ||
      createCheckoutIsLoading ||
      modifiedStock ||
      outOfStock)
  );
};
