import { useGrowthBook } from '@growthbook/growthbook-react';
import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getReferralCookie } from '../../../utils/cookies';
import AppleSignInButton from '../../components/AppleSignIn/AppleSignInButton';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ExternalContent } from '../../components/ExternalContent/ExternalContent';
import { GolButton } from '../../components/GolButton/GolButton.component';
import SignupButton from '../../components/SignupButton/SignupButton';
import useLoginSignupRedirect from '../../hooks/useLoginSignupRedirect';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useRedirectLoginSignup from '../../hooks/useRedirectLoginSignup';
import GoogleSignInButton from '../google-signin-button/GoogleSignInButton';

const Signup = () => {
  useLoginSignupRedirect();

  const { t } = useTranslation();
  const isMobile = useMobileMediaQuery();
  const [params] = useSearchParams();

  const referralCode = getReferralCookie() ?? params.get('ref');

  // add redirect param to login if it exists
  const { loginRoute } = useRedirectLoginSignup();

  const navigate = useNavigate();

  const growthbook = useGrowthBook();

  const handleLoginTrack = useCallback(() => {
    navigate(loginRoute);
  }, [navigate, loginRoute]);

  const { signupWithEmailRoute } = useRedirectLoginSignup();

  const handleSignupWithEmail = () => {
    navigate(signupWithEmailRoute);
  };
  return (
    <PageContainer id="signupPage" maxWidth="xs">
      <Stack
        alignItems="center"
        height="100%"
        justifyContent="center"
        spacing={SpaceSizes.md}
        width="100%"
      >
        <Stack alignSelf="start" gap={SpaceSizes.xs}>
          <Typography component="h2" fontWeight="bold" variant="h5">
            {t('pages.signup.title')}
          </Typography>
          {referralCode && (
            <Stack data-testId="referralsInfo">
              <ExternalContent
                defaultChildren={
                  <ExternalContent
                    filter={{
                      key: 'referral_id',
                      value: 'DEFAULT',
                    }}
                    type="referrals"
                  />
                }
                filter={{
                  key: 'referral_id',
                  value: referralCode,
                }}
                type="referrals"
              />
            </Stack>
          )}
          <Typography component="h3" fontSize={14}>
            {t('pages.signup.subtitle')}
          </Typography>
        </Stack>

        <GoogleSignInButton referralCode={referralCode} isSignup />
        {growthbook && growthbook.isOn('web_apple_login') && (
          <AppleSignInButton isSignup />
        )}
        <Stack spacing={SpaceSizes.md} width={isMobile ? '280px' : '343px'}>
          <SignupButton
            buttonText={t('pages.signup.withEmail')}
            dataTestId="signup-email"
            id="mail_signup"
            onClick={handleSignupWithEmail}
          />
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        gap={SpaceSizes.none}
        justifyContent="center"
        paddingTop={SpaceSizes.md}
      >
        <Typography variant="body2">{t('pages.signup.loginText')}</Typography>
        <GolButton
          id="goto_login_register"
          size="small"
          testId="login-button"
          value={t('pages.signup.login')}
          variant="text"
          onClick={handleLoginTrack}
        />
      </Stack>
    </PageContainer>
  );
};

export default Signup;
