import EditIcon from '@mui/icons-material/Edit';
import { Divider, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { GolButton } from '../../GolButton/GolButton.component';
import { InlineItem } from '../InlineItem/InlineItem';

export const SecuritySection = () => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="flex-start"
      divider={<Divider sx={{ width: '100%' }} />}
      spacing={SpaceSizes.md}
    >
      <InlineItem
        subtitle={t('pages.profile.securitySection.passwordDescription')}
        title={t('pages.profile.securitySection.passwordTitle')}
      >
        <GolButton
          startIcon={<EditIcon />}
          to="/change-password"
          value={t('pages.profile.securitySection.passwordButton')}
        />
      </InlineItem>
    </Stack>
  );
};
