import { Divider, Stack, Typography } from '@mui/material';
import { isUndefined } from 'ramda-adjunct';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useGetAuctionQuery } from '../../../features/auction/Auction.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { GolButton } from '../../components/GolButton/GolButton.component';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import ProductPrice from '../../components/ProductPrice/ProductPrice';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { IMAGE_FALLBACK } from './Auction.constants';

const AuctionSuccess = () => {
  const { t } = useTranslationByNamespaces('pages.auction.success');
  const navigate = useNavigate();
  const { productId } = useParams();
  const [queryParameters] = useSearchParams();
  const price = queryParameters.get('price') || 0;

  const {
    data: auction,
    isLoading,
    isError,
  } = useGetAuctionQuery(
    {
      productId: productId ?? '',
    },
    { skip: isUndefined(productId) },
  );

  return (
    <PageContainer
      {...(isError ? { errorCode: t('errors.GENERIC_ERROR') } : {})}
      id="auctionSuccessPage"
      loading={isLoading}
      maxWidth="sm"
      title={t('pageTitle')}
      backButton
    >
      <Stack gap={SpaceSizes.mdPlus} mx={SpaceSizes.sm}>
        <Stack alignSelf="center" height="50%" position="relative" width="50%">
          {auction?.product.imageLocation && (
            <ImageFallback
              height="100%"
              src={auction?.product.imageLocation}
              style={{
                borderRadius: IMAGE_FALLBACK.BORDER_RADIUS,
                objectFit: 'cover',
              }}
              width="100%"
            />
          )}
        </Stack>
        <Stack>
          <Stack gap={SpaceSizes.sm}>
            <Stack gap={SpaceSizes.smPlus}>
              <Stack gap={SpaceSizes.xs}>
                <Typography textAlign="center" variant="body2">
                  {t('congrats')}
                </Typography>
                <Typography textAlign="center" variant="body2">
                  {t('success')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Stack gap={SpaceSizes.sm}>
            <Stack gap={SpaceSizes.xs}>
              <Stack alignItems="center" flexDirection="row">
                <Typography color="text.secondary" variant="body2">
                  {t('resume')}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  {`${auction?.product.player_name} - ${t('collectible')}`}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  x1
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="body2">{t('total')}</Typography>
              <ProductPrice size="medium" value={price} />
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={SpaceSizes.md}>
          <Stack gap={SpaceSizes.md}>
            <GolButton
              id="myGolBalls"
              value={t('golBalls')}
              fullWidth
              onClick={() => navigate(`/product/${productId}`)}
            />
            <GolButton
              id="marketplace"
              value={t('marketplace')}
              variant="secondary"
              fullWidth
              onClick={() => navigate('/marketplace')}
            />
          </Stack>
        </Stack>
        {/* <Stack gap={SpaceSizes.md} sx={{ maxWidth: '100%' }}>
          {productId && <RelatedProducts productId={productId} />}
        </Stack> */}
      </Stack>
    </PageContainer>
  );
};

export default AuctionSuccess;
