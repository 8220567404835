import { Checkbox, Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

interface CheckboxProps {
  id: string;
  label: ReactNode;
  defaultChecked?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  sx?: SxProps<Theme>;
  gap?: string | number;
}
const CheckboxField = ({
  id,
  label,
  defaultChecked = false,
  errorMessage,
  isRequired = false,
  sx,
  gap,
}: CheckboxProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = errors[id];

  return (
    <Stack direction="column">
      <Stack alignItems="center" direction="row" gap={gap}>
        <Checkbox
          defaultChecked={defaultChecked}
          inputProps={
            { 'data-testid': id } as React.InputHTMLAttributes<HTMLInputElement>
          }
          sx={sx}
          {...register(id, {
            required: isRequired,
          })}
        />
        {label}
      </Stack>
      {hasError && (
        <Typography color="red" textAlign="center" variant="caption">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default CheckboxField;
