import { Stack, Typography } from '@mui/material';
import React from 'react';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { ReferMyGolpointsProps } from './ReferMyGolpoints.types';

const golpointsImg = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/golpoints.png`;

export const ReferMyGolpoints = ({ golPoints }: ReferMyGolpointsProps) => {
  const { t } = useTranslationByNamespaces('pages.referrals.referMyGolpoints');
  return (
    <Stack
      borderRadius={SpaceSizes.sm}
      justifyContent="flex-start"
      width="100%"
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={SpaceSizes.md}
        justifyContent="flex-start"
      >
        <Typography textAlign="start">{t('title')}</Typography>
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
          <Typography data-testid="golpoints">{golPoints}</Typography>
          <ImageFallback
            fallback={golpointsImg}
            height={SpaceSizes.lg}
            src={golpointsImg}
            width={SpaceSizes.lg}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
