import InfoIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { Dialog, Divider, IconButton, Stack, Typography } from '@mui/material';
import { isNonEmptyString, isUndefined } from 'ramda-adjunct';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetAuctionQuery,
  usePostBidMutation,
} from '../../../features/auction/Auction.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import { AuctionHelpModal } from '../../components/Auction/AuctionHelpModal';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input/Input';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import ProductPrice from '../../components/ProductPrice/ProductPrice';
import { CountdownComponent } from '../../components/Trivia/Countdown';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { IMAGE_FALLBACK, Inputs } from './Auction.constants';

const Auction = () => {
  const { t } = useTranslationByNamespaces('pages.auction.detail');
  const navigate = useNavigate();
  const { productId } = useParams();
  const [isShowingHelp, setIsShowingHelp] = useState(false);
  const [errorAlert, setErrorAlert] = useState('');

  const [postBid, { isLoading: isLoadingBid }] = usePostBidMutation();

  const methods = useForm<Inputs>();
  const { setValue, watch } = methods;

  const {
    data: auction,
    isLoading,
    isError,
    refetch,
  } = useGetAuctionQuery(
    {
      productId: productId ?? '',
    },
    { skip: isUndefined(productId) },
  );

  const timeAndPlayerName = useMemo(
    () => `${auction?.product.goal_min}' ${auction?.product.player_name}`,
    [auction],
  );

  const handlePostBid: SubmitHandler<Inputs> = async (data) => {
    try {
      const bidResult = await postBid({
        productId: auction?.auction_id ?? '',
        bid_amount: data.amount,
      }).unwrap();

      if (bidResult) {
        navigate(`/product/auction/${productId}/success?price=${data.amount}`);
      }
    } catch (error: any) {
      if (
        error?.data?.code === 'BID_BELOW_CURRENT_PRICE' ||
        error?.data?.code === 'BID_BELOW_MIN_INCREMENT'
      ) {
        refetch();
      }
      if (error?.data?.code === 'NOT_ENOUGH_GOLPOINTS_FOR_BID') {
        navigate(`/tiers`);
      }
      if (error?.data?.code === 'UNAUTHORIZED') {
        navigate(`/login`);
      }
      setErrorAlert(error?.data?.code ?? '');
    }
  };

  const minBid = useMemo(() => {
    if (
      auction &&
      Number(auction.max_bid.bid_amount) >= Number(auction.min_price)
    ) {
      return Number(auction.max_bid.bid_amount) + Number(auction.min_increment);
    }
    if (auction?.min_price) return Number(auction.min_price);
    return 0;
  }, [auction]);

  // Set default form values
  useEffect(() => {
    if (auction) {
      setValue('amount', minBid);
      setValue('amountEquivalent', minBid / 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auction, setValue]);

  return (
    <PageContainer
      {...(isError ? { errorCode: t('errors.GENERIC_ERROR') } : {})}
      id="auctionPage"
      loading={isLoading}
      maxWidth="xs"
      title={t('pageTitle')}
      backButton
    >
      <Stack gap={SpaceSizes.mdPlus}>
        <Stack
          bgcolor="background.paper"
          p={SpaceSizes.md}
          style={{
            borderRadius: SpaceSizes.md,
          }}
        >
          <Stack key={productId}>
            <Stack gap={SpaceSizes.sm}>
              <Stack flexDirection="row" gap={SpaceSizes.smPlus}>
                <Stack
                  height={IMAGE_FALLBACK.HEIGHT}
                  position="relative"
                  width={IMAGE_FALLBACK.WIDTH}
                >
                  {auction?.product.imageLocation && (
                    <ImageFallback
                      height="100%"
                      src={auction?.product.imageLocation ?? ''}
                      style={{
                        borderRadius: IMAGE_FALLBACK.BORDER_RADIUS,
                        objectFit: 'cover',
                      }}
                      width="100%"
                    />
                  )}
                </Stack>
                <Stack gap={SpaceSizes.xs}>
                  <Typography color="text.secondary" variant="body2">
                    {timeAndPlayerName}
                  </Typography>
                  <Stack alignItems="center" flexDirection="row">
                    <Typography
                      sx={{ marginRight: SpaceSizes.sm }}
                      variant="body2"
                    >
                      {t('price')}
                    </Typography>
                    {auction?.max_bid.bid_amount && (
                      <ProductPrice
                        size="medium"
                        value={Number(auction.max_bid.bid_amount)}
                        showEuro
                      />
                    )}
                  </Stack>
                  <Typography color="text.secondary" variant="body2">
                    {`${auction?.bids_count} ${t('totalOffers')}`}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <CountdownComponent
                datetime={auction?.end_dt}
                title={t('finish')}
                isSmall
              />
            </Stack>
          </Stack>
        </Stack>
        <Form methods={methods} onSubmit={handlePostBid}>
          <Stack gap={SpaceSizes.md}>
            <Stack
              bgcolor="background.paper"
              p={SpaceSizes.md}
              style={{
                borderRadius: SpaceSizes.md,
              }}
            >
              <Stack gap={SpaceSizes.xs}>
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <Dialog
                    maxWidth="sm"
                    open={isShowingHelp}
                    onClose={() => setIsShowingHelp(false)}
                  >
                    <AuctionHelpModal onClose={() => setIsShowingHelp(false)} />
                  </Dialog>

                  <Typography variant="body1">{t('input')}</Typography>

                  <IconButton
                    size="small"
                    onClick={() => setIsShowingHelp(true)}
                  >
                    <InfoIcon color={AlertSeverity.INFO} />
                  </IconButton>
                </Stack>
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  gap={SpaceSizes.xs}
                >
                  <Input
                    errorMessage={t(`inputError`)}
                    id="amount"
                    min={minBid}
                    step={auction?.min_increment}
                    type="number"
                    onChange={(value) => {
                      setValue('amountEquivalent', (value as number) / 100);
                      setValue('amount', value as number);
                    }}
                  />
                  {/* <Input
                    errorMessage={t(`inputError`)}
                    id="amountEquivalent"
                    min={minBid / 100}
                    step={(auction?.min_increment ?? 0) / 100}
                    type="number"
                    onChange={(value) =>
                      setValue('amount', (value as number) * 100)
                    }
                  /> */}
                </Stack>
                <ProductPrice size="small" value={watch('amount')} showEuro />

                <Typography textAlign="center" variant="body2">
                  {t('inputHelp')}
                </Typography>
              </Stack>
            </Stack>
            {auction && (
              <Typography px={SpaceSizes.sm} variant="body2">
                {t('info', { pct: Number(auction.bid_reserve_pct).toString() })}
              </Typography>
            )}
            <Stack gap={SpaceSizes.md}>
              <LoadingButton
                disabled={isLoading}
                id="postBid"
                loading={isLoadingBid}
                sx={{ borderRadius: SpaceSizes.lg }}
                type="submit"
                variant="contained"
              >
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  gap={SpaceSizes.xs}
                >
                  <Typography>{t('offer')}</Typography>
                </Stack>
              </LoadingButton>
            </Stack>
            {isNonEmptyString(errorAlert) && (
              <Stack width="100%">
                <AlertCard
                  content={t(`error.${errorAlert}`)}
                  id="bidError"
                  severity={AlertSeverity.ERROR}
                  show
                />
              </Stack>
            )}
          </Stack>
        </Form>
      </Stack>
    </PageContainer>
  );
};

export default Auction;
