export const ANIMATED_LINK_SX = {
  position: 'relative',
  textDecoration: 'none',
  color: 'inherit',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -2,
    width: '0%',
    height: '1px',
    backgroundColor: 'info.main',
    transition: 'width 0.3s',
  },
  '&:hover::before': {
    width: '40%',
  },
};
