import { assoc } from 'ramda';
import { isNilOrEmpty, isNonEmptyArray } from 'ramda-adjunct';
import { useEffect, useState } from 'react';

import Env from '../../env/env';
import {
  selectIsUserLoggedIn,
  selectUserSession,
} from '../../features/session/Session.selector';
import { useGetTeamsQuery } from '../../features/tournaments/Tournaments.api';
import { useGetPreferencesQuery } from '../../features/users/Users.api';
import { useAppSelector } from '../hooks';
import { geti18nResolvedLanguage } from '../i18n';

export const shouldIntercomBeEnabled = () =>
  Env.NODE_ENV !== 'development' &&
  Env.NODE_ENV !== 'test' &&
  isNilOrEmpty(window.Cypress);

export default function useIntercom() {
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const user = useAppSelector(selectUserSession);
  const langId = geti18nResolvedLanguage();
  const [skip, setSkip] = useState(true);
  const { data: preferences, isSuccess: isSuccessPreferences } =
    useGetPreferencesQuery(undefined, { skip });
  const { data: teams, isSuccess: isSuccessTeam } = useGetTeamsQuery('laliga', {
    skip,
  });

  useEffect(() => {
    if (shouldIntercomBeEnabled()) {
      let settings = { app_id: window.APP_ID, language_override: langId };

      if (isLoggedUser && user) {
        setSkip(false);
        settings = assoc(
          'name',
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.nickname,
          settings,
        );
        settings = assoc('email', user.email, settings);
        const unixDate = Math.floor(new Date(user.reg_dt).getTime() / 1000);
        settings = assoc('created_at', unixDate, settings);
        settings = assoc('user_id', user.user_id, settings);
        settings = assoc('language_override', langId, settings);

        if (isSuccessPreferences && isSuccessTeam) {
          if (preferences && teams && isNonEmptyArray(preferences.teamIds)) {
            settings = assoc('team_id', preferences.teamIds[0], settings);
            const team = teams.find((t) => t.id === preferences.teamIds[0]);
            settings = assoc('team_name', team?.name, settings);
          }
        }
      }
      window.intercomSettings = settings;

      if (window.Intercom) {
        window.Intercom('update');
      }
    }
  }, [
    isLoggedUser,
    isSuccessPreferences,
    isSuccessTeam,
    langId,
    preferences,
    teams,
    user,
  ]);
}
