import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  CloseOutlined,
  PauseOutlined,
  PlayArrowOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, {
  createRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../utils/tagger';
import { StoryProgressInstance, StoryVideoProps } from './Story.interfaces';
import { StoryProgress } from './StoryProgress';

export const StoryVideo = forwardRef<HTMLVideoElement, StoryVideoProps>(
  (
    {
      src,
      storyName,
      isActive,
      stories,
      finishedStories,
      closeFn,
      isChanging,
      onLoaded,
      onEnd,
    },
    ref,
  ) => {
    const [muted, setMuted] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const sendDataToGTM = useGTMDispatch();
    const { pathname } = useLocation();
    const progressRefs = stories.map(() => createRef<StoryProgressInstance>());

    useEffect(() => {
      const videoRef = ref as RefObject<HTMLVideoElement>;
      const video = videoRef.current;
      const updateProgress = () => {
        if (video) {
          progressRefs.forEach((progressRef) => {
            progressRef.current?.updateProgress(video);
          });
        }
      };
      if (isActive && video) {
        video.addEventListener('timeupdate', updateProgress);
      }
      return () => {
        if (video) {
          video.removeEventListener('timeupdate', updateProgress);
        }
      };
    }, [isActive, ref, progressRefs]);

    useEffect(() => {
      const videoRef = ref as RefObject<HTMLVideoElement>;
      const video = videoRef.current;
      const handleCanPlayThrough = () => {
        if (isActive) {
          video?.play();
        } else {
          video?.pause();
        }
      };
      video?.addEventListener('canplaythrough', handleCanPlayThrough);

      return () => {
        video?.removeEventListener('canplaythrough', handleCanPlayThrough);
      };
    }, [isActive, ref]);

    const play = useCallback(() => {
      const videoRef = ref as RefObject<HTMLVideoElement>;
      if (videoRef.current) {
        setIsPaused(false);
        videoRef.current.play();
      }
    }, [ref]);
    const pause = useCallback(() => {
      const videoRef = ref as RefObject<HTMLVideoElement>;
      if (videoRef.current) {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }, [ref]);

    const togglePlayPause = useCallback(() => {
      const videoRef = ref as RefObject<HTMLVideoElement>;
      if (videoRef?.current?.paused) {
        play();
      } else {
        pause();
      }
    }, [ref, play, pause]);

    useEffect(() => {
      sendDataToGTM({
        event: TAG_EVENT.AVATAR_SEEN,
        story: storyName,
        route: pathname?.slice(1),
        fullySeen: false,
      });
      if (isChanging) {
        pause();
      } else {
        play();
      }
    }, [storyName, pathname, isChanging, pause, play, sendDataToGTM]);
    const markAsSeenAndSendTag = useCallback(() => {
      sendDataToGTM({
        event: TAG_EVENT.AVATAR_SEEN,
        story: storyName,
        route: pathname?.slice(1),
        fullySeen: true,
      });
    }, [pathname, sendDataToGTM, storyName]);

    if (!isActive) {
      return null;
    }
    return (
      <>
        <video
          ref={ref}
          data-testid="story-video-component"
          muted={muted}
          src={src}
          style={{
            width: '100%',
            objectFit: 'fill',
            height: '100%',
            opacity: isChanging ? 0 : 1,
          }}
          playsInline
          onClick={togglePlayPause}
          onEnded={onEnd}
          onLoadedData={onLoaded}
        >
          <track kind="captions" />
          <p>Error</p>
        </video>

        <Stack
          gap={SpaceSizes.sm}
          left="2.5%"
          position="absolute"
          top={SpaceSizes.mdPlus}
          width="95%"
        >
          <Stack direction="row" gap={SpaceSizes.xs} width="100%">
            {stories.map((story, index) => (
              <StoryProgress
                key={`progress-${story}`}
                ref={progressRefs[index]}
                isComplete={index < finishedStories}
                isUnactive={index > finishedStories}
                markAsSeen={markAsSeenAndSendTag}
              />
            ))}
          </Stack>
          <Stack
            direction="row"
            fontSize={{
              xs: 24,
              sm: 28,
              xl: 32,
            }}
            gap={{ xs: SpaceSizes.xs, md: SpaceSizes.sm }}
            justifyContent="flex-end"
          >
            {isPaused ? (
              <PlayArrowOutlined
                cursor="pointer"
                data-testid="story-video-pause"
                fontSize="inherit"
                onClick={togglePlayPause}
              />
            ) : (
              <PauseOutlined
                cursor="pointer"
                data-testid="story-video-play"
                fontSize="inherit"
                onClick={togglePlayPause}
              />
            )}
            {muted ? (
              <VolumeOffOutlined
                cursor="pointer"
                data-testid="story-volume-unmute"
                fontSize="inherit"
                onClick={() => setMuted(false)}
              />
            ) : (
              <VolumeUpOutlined
                cursor="pointer"
                data-testid="story-volume-mute"
                fontSize="inherit"
                onClick={() => setMuted(true)}
              />
            )}
            <CloseOutlined
              cursor="pointer"
              data-testid="story-video-close"
              fontSize="inherit"
              onClick={closeFn}
            />
          </Stack>
        </Stack>
      </>
    );
  },
);
