import './Selfie.style.css';

import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { noop } from 'ramda-adjunct';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { selectHasUploadedSelfie } from '../../../features/products/Product.selectors';
import { useSaveSelfieMutation } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { geti18nResolvedLanguage } from '../../i18n';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import { UploadImageButtonHandler } from '../UploadImageButtonHandler/UploadImageButtonHandler';
import {
  ICollectableSelfieEditorProps,
  TEditorRef,
} from './CollectableSelfieEditor.types';

export const CollectableSelfieEditor = ({
  product,
  stickers,
}: ICollectableSelfieEditorProps) => {
  const { t } = useTranslation();
  const currentLanguage = geti18nResolvedLanguage();
  const defaultFile =
    'https://upload.wikimedia.org/wikipedia/commons/5/59/Empty.png?20091205084734';
  /**
   * White image canvas, its mandatory use a image to initialice the image editor,
   * we need change this url to another in own s3 bucked.
   */
  const [file, setFile] = useState<string>(defaultFile);
  const editorRef = useRef<TEditorRef>(null);
  const [showSaveAlertError, setShowSaveAlertError] = useState<boolean>(false);
  const hasUploadedSelfie = selectHasUploadedSelfie(product);
  const navigate = useNavigate();

  const [saveSelfie, { isSuccess, isLoading, isError }] =
    useSaveSelfieMutation();

  const hasStickers = !!stickers?.length;
  const handleSave = useCallback(async () => {
    if (!hasStickers) {
      setShowSaveAlertError(true);
      return;
    }

    setShowSaveAlertError(false);
    const { current: saveFunction } = editorRef;

    if (saveFunction) {
      const result = saveFunction();
      const { imageData } = result;

      const uriResult =
        imageData.imageBase64 || imageData.imageCanvas?.toDataURL();
      if (uriResult) {
        await saveSelfie({
          image: uriResult,
          product_id: product.product_id,
        });
      }
    }
  }, [product.product_id, saveSelfie, hasStickers]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/my-collection/${product.product_id}`);
      navigate(0);
    }
  }, [navigate, isSuccess, product.product_id]);

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <FilerobotImageEditor
        defaultSavedImageName={`golball_selfie_${product.product_id}`}
        defaultSavedImageType="jpeg"
        defaultTabId={TABS.WATERMARK}
        defaultToolId={TOOLS.WATERMARK}
        getCurrentImgDataFnRef={editorRef}
        language={currentLanguage}
        previewPixelRatio={4}
        savingPixelRatio={4}
        source={file}
        tabsIds={[TABS.ADJUST, TABS.FINETUNE, TABS.RESIZE, TABS.WATERMARK]}
        translations={{
          finetuneTab: t(
            'pages.collectableSelfieEditor.editor.finetuneAdjustments',
          ),
          watermarkTab: t('pages.collectableSelfieEditor.editor.players'),
          stroke: t('pages.collectableSelfieEditor.editor.stroke'),
        }}
        Watermark={{
          gallery: hasStickers
            ? stickers.map((sticker) => sticker.location)
            : [],
        }}
        avoidChangesNotSavedAlertOnLeave
        useBackendTranslations
        onBeforeSave={noop}
        onSave={noop}
      />

      <div id="secondFileRob" />
      <AlertCard
        content={
          <Stack spacing={SpaceSizes.sm}>
            <Typography variant="body1">
              {isSuccess
                ? t(`pages.selfieUpdate.success`)
                : t(`pages.selfieUpdate.error`)}
            </Typography>
          </Stack>
        }
        id={isSuccess ? 'selfieUpdateSuccess' : 'selfieUpdateError'}
        severity={isSuccess ? AlertSeverity.SUCCESS : AlertSeverity.ERROR}
        show={isSuccess || isError || showSaveAlertError}
      />
      <Stack
        alignContent="center"
        direction="row"
        justifyContent="center"
        spacing={SpaceSizes.md}
        sx={{
          marginY: SpaceSizes.md,
        }}
      >
        <UploadImageButtonHandler
          customContainerStyles={{
            backgroundColor: 'secondary.main',
            fontWeight: '600',
          }}
          setImageCallback={setFile}
          type="big"
        />
        <LoadingButton
          disabled={!hasStickers || file === defaultFile || hasUploadedSelfie}
          loading={isLoading && !isSuccess}
          startIcon={<SaveIcon />}
          sx={{ backgroundColor: 'secondary.main', fontWeight: '600' }}
          variant="contained"
          onClick={handleSave}
        >
          {t('pages.collectableSelfieEditor.saveButton')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
