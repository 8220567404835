import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { Avatar, Chip, Divider, FormControl, Stack } from '@mui/material';
import { isEmpty } from 'ramda';
import { isNonEmptyArray } from 'ramda-adjunct';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { selectUserSession } from '../../../../features/session/Session.selector';
import { updateUser } from '../../../../features/session/Session.slice';
import { useGetBrandsQuery } from '../../../../features/tournaments/Tournaments.api';
import {
  useGetPreferencesTeamsQuery,
  usersApiExtractErrors,
  useSaveLeaguesPrefMutation,
  useUpdateUserMutation,
} from '../../../../features/users/Users.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { LanguageMenu } from '../../../layout/Header/LanguageMenu/LanguageMenu';
import { IllustrableItem } from '../../../pages/onboarding-post-activation/fans-preferences/FanaticPreferences.types';
import { DialogCommon } from '../../Dialog/Dialog';
import Form from '../../form/Form';
import { FormSelectAuto } from '../../form/FormSelectAuto/FormSelectAuto';
import { GolButton } from '../../GolButton/GolButton.component';
import Spinner from '../../Spinner';
import { InlineItem } from '../InlineItem/InlineItem';
import { PreferenceSelectItem } from './PreferenceSelectItem/PreferenceSelectItem';
import { LeagueChangeInput } from './PreferenceSelectItem/PreferenceSelectItem.types';
import { SHOW_LEAGUE_PREFERENCE } from './PreferenceSelectItem/PreferencesSection.constants';
import { inlineItemStyled, SwitchStyled } from './PreferencesSection.constants';
import { PreferenceSectionProps, UserState } from './PreferencesSection.types';

export const PreferencesSection = ({
  setAlertCode,
}: PreferenceSectionProps) => {
  const { t } = useTranslation();
  const { data: leagues, isSuccess: isSuccessLeague } = useGetBrandsQuery();
  const {
    data: preferenceLeaguesWithTeams,
    isSuccess: isSuccessPreferenceLeaguesWithTeams,
  } = useGetPreferencesTeamsQuery();

  const preferenceTeams = preferenceLeaguesWithTeams?.flatMap(
    (league) => league.teams.filter((team) => team.preferred) || [],
  );

  const showSpinner = !isSuccessPreferenceLeaguesWithTeams;
  const showTeamChip =
    isSuccessPreferenceLeaguesWithTeams && !isEmpty(preferenceTeams);

  const [selectedLeague, setSelectedLeague] = useState<IllustrableItem>();
  const [publicWarningActive, setPublicWarningActive] = useState<boolean>(true);
  const [openWarning, setOpenWarning] = useState<boolean>(false);

  const navigate = useNavigate();
  const [
    saveLeagues,
    { isLoading: isLoadingSaveLeague, isSuccess: isSuccessSaveLeague },
  ] = useSaveLeaguesPrefMutation();
  const methodsLeague = useForm<LeagueChangeInput>();
  const { setValue: setValueLeague } = methodsLeague;
  const dispatch = useAppDispatch();
  const [patchUpdateUser, { isLoading }] = useUpdateUserMutation();
  const user = useAppSelector(selectUserSession);

  const handleNavigationTeam = useCallback(() => {
    navigate('/configuration/teams');
  }, [navigate]);

  const changePrivacy = async (isPublic: boolean) => {
    try {
      await patchUpdateUser({
        user_id: user?.user_id ?? '',
        data: {
          is_public: isPublic,
        },
      }).unwrap();

      dispatch(
        updateUser({
          ...user,
          is_public: isPublic,
        }),
      );

      setAlertCode('dataSuccess');
    } catch (error) {
      setAlertCode(
        usersApiExtractErrors(error, '', {
          parentKey: 'profileErrors',
          separator: '',
          defaultKey: 'serverError',
        }),
      );
    }
  };

  const onConfirmWarning = async () => {
    setOpenWarning(false);
    setPublicWarningActive(false);
    await changePrivacy(!user?.is_public);
  };

  const onChangePrivacy = async (
    event: SyntheticEvent<Element, Event>,
    checked: boolean,
  ) => {
    if (publicWarningActive) {
      setOpenWarning(true);
    } else {
      await changePrivacy(checked);
    }
  };

  const onSubmitLeague: SubmitHandler<LeagueChangeInput> = async () => {
    try {
      await saveLeagues({ leagues: [selectedLeague?.id as string] });
      setAlertCode('leagueSuccess');
    } catch (error) {
      setAlertCode('profileError');
    }
  };

  useEffect(() => {
    if (preferenceLeaguesWithTeams && leagues) {
      if (isNonEmptyArray(preferenceLeaguesWithTeams)) {
        setSelectedLeague(
          leagues.find(
            (league) => league.id === preferenceLeaguesWithTeams[0].id,
          ),
        );
      }
    }
  }, [preferenceLeaguesWithTeams, leagues]);

  return (
    <Stack alignItems="flex-start" spacing={SpaceSizes.md} width="100%">
      <InlineItem
        sx={inlineItemStyled}
        title={t(
          `pages.profile.preferencesSection.profile.${
            user?.is_public ? UserState.PUBLIC : UserState.PRIVATE
          }`,
        )}
      >
        <FormControl>
          <SwitchStyled
            checked={user?.is_public}
            disabled={isLoading}
            onChange={onChangePrivacy}
          />
        </FormControl>
      </InlineItem>
      <Stack
        alignItems="flex-start"
        divider={<Divider sx={{ width: '100%' }} />}
        spacing={SpaceSizes.md}
        width="100%"
      >
        <DialogCommon
          cancelText={t(
            'pages.profile.preferencesSection.profile.warning.cancel',
          )}
          confirm={{
            message: t('pages.profile.preferencesSection.profile.warning.cta'),
          }}
          confirmFn={onConfirmWarning}
          description={t(
            `pages.profile.preferencesSection.profile.warning.${
              user?.is_public ? 'public' : 'private'
            }.description`,
          )}
          handleClose={() => {
            setPublicWarningActive(true);
            setOpenWarning(false);
          }}
          isOpen={openWarning}
          title={t(
            `pages.profile.preferencesSection.profile.warning.${
              user?.is_public ? 'public' : 'private'
            }.title`,
          )}
          isCentered
        />
        {SHOW_LEAGUE_PREFERENCE && (
          <InlineItem
            subtitle={t('pages.profile.preferencesSection.leagueDescription')}
            title={t('pages.profile.preferencesSection.leagueTitle')}
            fullContentWidth
          >
            <Form methods={methodsLeague} onSubmit={onSubmitLeague}>
              <Stack
                alignItems="center"
                direction="row"
                spacing={SpaceSizes.md}
                sx={{ width: '100%' }}
              >
                <FormSelectAuto
                  defaultValue={selectedLeague ?? { id: '' }}
                  disabled={
                    !isSuccessLeague || !isSuccessPreferenceLeaguesWithTeams
                  }
                  getOptionLabel={(option: IllustrableItem) =>
                    option.name || ''
                  }
                  id="league"
                  noOptionsText={t(
                    `pages.profile.preferencesSection.leagueNoOptions`,
                  )}
                  options={leagues ?? []}
                  placeholder={t(
                    `pages.profile.preferencesSection.leagueLabel`,
                  )}
                  renderOption={(props, option) => (
                    <PreferenceSelectItem
                      image={option.icon}
                      text={option.name}
                      {...props}
                    />
                  )}
                  onChange={(e, data: IllustrableItem) => {
                    setSelectedLeague(data);
                    setValueLeague('league', data.name, {
                      shouldValidate: true,
                    });
                  }}
                />
                <Stack>
                  {preferenceLeaguesWithTeams && (
                    <LoadingButton
                      disabled={
                        selectedLeague?.id === preferenceLeaguesWithTeams[0].id
                      }
                      loading={isLoadingSaveLeague && !isSuccessSaveLeague}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('pages.profile.preferencesSection.leagueConfirm')}
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </Form>
          </InlineItem>
        )}
        <InlineItem
          sx={{ width: '100%' }}
          title={t('pages.profile.preferencesSection.teams.title')}
          fullContentWidth
        >
          <Stack
            alignItems="flex-start"
            direction="column"
            spacing={SpaceSizes.md}
          >
            {showSpinner && <Spinner dark />}
            {showTeamChip && (
              <Stack
                alignItems="center"
                flexDirection="row"
                flexWrap="wrap"
                gap={SpaceSizes.sm}
                width="100%"
              >
                {preferenceTeams?.map((team) => (
                  <Chip
                    key={team.id}
                    avatar={<Avatar alt={team.name} src={team.icon} />}
                    label={team.name}
                  />
                ))}
              </Stack>
            )}

            <GolButton
              endIcon={<EditIcon />}
              value={t('pages.profile.preferencesSection.addClub')}
              onClick={handleNavigationTeam}
            />
          </Stack>
        </InlineItem>
        <InlineItem
          subtitle={t('pages.profile.preferencesSection.langDescription')}
          title={t('pages.profile.preferencesSection.langTitle')}
          fullContentWidth
        >
          <LanguageMenu user={user} />
        </InlineItem>
      </Stack>
    </Stack>
  );
};
