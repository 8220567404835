import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';

export const TriviaBanner = () => {
  const isMobile = useMobileMediaQuery();

  const { t } = useTranslation();

  return (
    <Stack
      alignItems="flex-start"
      direction="column"
      justifyContent="flex-end"
      sx={{
        width: '100%',
        height: isMobile ? '134px' : '381px',
        backgroundImage: `url(https://static.prod.golball.com/strapi/education/trivia/background.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: SpaceSizes.sm,
      }}
    >
      <Typography
        color="text.primary"
        fontWeight={900}
        variant={isMobile ? 'h5' : 'h4'}
      >
        {t('pages.trivia.landing.title')}
      </Typography>
      <Typography
        color="text.primary"
        fontWeight={700}
        variant={isMobile ? 'body1' : 'h6'}
      >
        {t('pages.trivia.landing.subtitle')}
      </Typography>
    </Stack>
  );
};
