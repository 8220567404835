import { IRelatedProducts } from '../../app/components/RelatedProducts/RelatedProducts.types';
import { LANGUAGES } from '../../app/i18n';
import Env from '../../env/env';
import { api } from '../api/Api';
import { ExternalContentResponse } from '../externalContent/ExternalContent.types';
import {
  BenefitsInfoRequest,
  BenefitsPlatforms,
  IPolygonscanLinkRequest,
  IProduct,
  IProductByBallRequest,
  IProductRequest,
  MatchFiles,
} from './Product.types';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Product by id
    getProductById: builder.query<IProduct, IProductRequest>({
      query: ({ productId, context }) => {
        return `${Env.REACT_APP_BASE_API_URL_PRODUCTS}products/${productId}${
          context ? `?context=${context}` : ''
        }`;
      },
      keepUnusedDataFor: 0,
    }),
    getRelatedProducts: builder.query<IRelatedProducts, IProductRequest>({
      query: ({ productId }) => {
        return `${Env.REACT_APP_BASE_API_URL_PRODUCTS}products/${productId}/related`;
      },
      keepUnusedDataFor: 0,
    }),
    // Match by id
    getMatchById: builder.query<MatchFiles, string>({
      query: (matchId) => {
        return `${Env.REACT_APP_BASE_API_URL_TOURNAMENTS}tournaments/matches/${matchId}/files`;
      },
    }),
    // Product by ballId
    getProductByBallId: builder.query<IProduct, IProductByBallRequest>({
      query: ({ ballId, context }) => {
        return `${
          Env.REACT_APP_BASE_API_URL_PRODUCTS
        }products/entities/${ballId}${context ? `?context=${context}` : ''}`;
      },
    }),
    // Polygonscan by productId and piece number
    getPolygonscanLink: builder.query<string, IPolygonscanLinkRequest>({
      query: ({ product_id: productId, piece_number: pieceNumber }) => {
        return `${Env.REACT_APP_BASE_API_URL_DEFAULT}web3/links/tx?productId=${productId}&pieceNumber=${pieceNumber}`;
      },
    }),
    getBenefitsInformation: builder.query<
      ExternalContentResponse<BenefitsPlatforms>,
      BenefitsInfoRequest
    >({
      query: ({ lang }) => {
        const langId = lang?.split('-')[0];
        return `${Env.REACT_APP_STATIC_BASE_URL_STRAPI}Educations?locale=${
          LANGUAGES.includes(langId) ? langId : 'es'
        }&filters[education_id][$eq]=why_buy_golball`;
      },
    }),
  }),
});

export const {
  useGetProductByIdQuery,
  useGetProductByBallIdQuery,
  useGetMatchByIdQuery,
  useGetPolygonscanLinkQuery,
  useGetBenefitsInformationQuery,
  useGetRelatedProductsQuery,
} = productsApi;
