import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { ScrollToTop } from './app/components/ScrollToTop/ScrollToTop';
import { geti18nResolvedLanguage } from './app/i18n';
import { setupStore } from './app/store';
import Env from './env/env';
import { init } from './initializers/init';
import { shouldSentryBeEnabled } from './initializers/sentryInit';
import { BrandThemeProvider } from './provider/BrandThemeProvider';
import { configureGrowthbook } from './utils/growthbookFeatures';
import { gtmParams } from './utils/tagger';

declare global {
  interface Window {
    Cypress: string;
  }
}
init();

const rootElement = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);
// eslint-disable-next-line no-console

const growthbookInstance = configureGrowthbook();

root.render(
  <GTMProvider state={gtmParams}>
    <GoogleReCaptchaProvider
      language={geti18nResolvedLanguage()}
      reCaptchaKey={Env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
    >
      <Sentry.ErrorBoundary showDialog={shouldSentryBeEnabled()}>
        <React.StrictMode>
          <GrowthBookProvider growthbook={growthbookInstance}>
            <Provider store={setupStore()}>
              <BrowserRouter>
                <ScrollToTop />
                <BrandThemeProvider>
                  <CssBaseline />
                  <App />
                </BrandThemeProvider>
              </BrowserRouter>
            </Provider>
          </GrowthBookProvider>
        </React.StrictMode>
      </Sentry.ErrorBoundary>
    </GoogleReCaptchaProvider>
  </GTMProvider>,
);
