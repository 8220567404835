export enum StoryActionType {
  NEXT_STORY = 'NEXT_STORY',
  PREV_STORY = 'PREV_STORY',
}

export enum StoryOrigin {
  MARKET = 'market',
  TRADE = 'trade',
  COLLECTION = 'collections',
  DEFAULT = 'default',
}
export type StoryState = {
  stories: string[];
  activeStoryIndex: number;
  viewedStories: string[];
};

export type StoryAction =
  | { type: StoryActionType.NEXT_STORY }
  | { type: StoryActionType.PREV_STORY };
