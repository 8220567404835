import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  RELATED_CARD_HEIGHT,
  RELATED_CARD_HEIGHT_LARGE,
  RELATED_CARD_WIDTH,
  RELATED_CARD_WIDTH_LARGE,
  useSizeRelatedProduct,
} from '../ProductCard/ProductCard.constants';

// Cards have this difference because they dont have multiple layers and borders
export const RELATED_CARD_LARGE = {
  height: RELATED_CARD_HEIGHT_LARGE + SpaceSizes.sm,
  width: RELATED_CARD_WIDTH_LARGE + SpaceSizes.xxs,
};
export const RELATED_CARD = {
  height: RELATED_CARD_HEIGHT + SpaceSizes.sm,
  width: RELATED_CARD_WIDTH + SpaceSizes.xxs,
};
export const CARD_DESKTOP = { height: 500, width: 260 };
export const CARD_TABLET = { height: 320, width: 600 };
export const CARD_MOBILE = { height: 240, width: 350 };
export const CARD_SMALL_MOBILE = { height: 180, width: 300 };

export const PRESALE_BUTTON_SX = {
  width: '100%',
  backgroundColor: 'warning.main',
  color: 'primary.dark',
  '&:hover': { backgroundColor: 'warning.light', opacity: 0.9 },
};

export const useMatchCardSize = (
  heightDevice: number,
  widthDevice: number,
  isMobile: boolean,
  isLarge?: boolean,
  isMatches?: boolean,
  isRelatedProduct?: boolean,
) => {
  const { relatedHeight, relatedWidth } = useSizeRelatedProduct();
  if (isRelatedProduct) {
    return {
      heightCurrent: relatedHeight,
      widthCurrent: relatedWidth,
    };
  }
  if (isMatches && !isLarge) {
    return {
      widthCurrent: isMobile ? '100%' : widthDevice,
      heightCurrent: isMobile ? '200px' : heightDevice,
    };
  }

  return {
    widthCurrent: '100%',
    heightCurrent: isLarge
      ? isMatches
        ? RELATED_CARD_HEIGHT_LARGE
        : RELATED_CARD_HEIGHT_LARGE / 2
      : heightDevice,
  };
};
