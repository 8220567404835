/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import React from 'react';

import { useGetTriviaInformationQuery } from '../../../features/trivia/Trivia.api';
import { selectTrivia } from '../../../features/trivia/Trivia.selectors';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import i18n from '../../i18n';
import { PageContainer } from '../containers/PageContainer/PageContainer';
import { TriviaBanner } from './TriviaBanner';
import { TriviaInfoCard } from './TriviaInfoCard';

const TriviaLanding = () => {
  const { language } = i18n;

  const { trivia, isLoadingTrivia, isErrorTrivia } =
    useGetTriviaInformationQuery(
      { lang: language },
      {
        selectFromResult: ({ data, isLoading, isError }) => ({
          ...data,
          isErrorTrivia: isError,
          isLoadingTrivia: isLoading,
          trivia: selectTrivia(data),
        }),
      },
    );

  return (
    <PageContainer
      id="trivia"
      {...(isErrorTrivia && { errorCode: 'error.DEFAULT' })}
      loading={isLoadingTrivia}
    >
      <Stack gap={SpaceSizes.md}>
        <TriviaBanner />
        <Stack gap={SpaceSizes.md}>
          {trivia?.map((item) => (
            <TriviaInfoCard
              key={item.title}
              bullets={item.bullets}
              image={item.image_web}
              title={item.title}
            />
          ))}
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default TriviaLanding;
