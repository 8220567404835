import { SyncAlt } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { useCallback } from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import TradeTypeIndicator from '../../trade-offer-details/trade-type/TradeTypeIndicator';
import { TradeTypeConstants } from '../../trade-offer-details/trade-type/TradeTypeIndicator.interfaces';
import TradeRequestShowcase from '../../trade-request-showcase/TradeRequestShowcase';
import { TRADE_ICON_SIZE } from '../TradeOfferBuilder.constants';
import { OfferSkeleton } from './Offer.skeleton';
import { PropsOffer } from './Offer.type';

export const Offer = ({
  isLoading,
  requestOffer,
  sentOffer,
  removeOffer,
  isReversed = undefined,
  isBuild = false,
}: PropsOffer) => {
  const isSmallestMobile = useSmallMobileMediaQuery();

  const indicationDirection = useCallback(
    (isLeft: boolean) => {
      if (isReversed || isBuild) {
        return isLeft ? TradeTypeConstants.REQUEST : TradeTypeConstants.OFFER;
      }
      return isLeft ? TradeTypeConstants.OFFER : TradeTypeConstants.REQUEST;
    },
    [isReversed, isBuild],
  );

  if (isLoading) {
    return <OfferSkeleton />;
  }
  return (
    <Stack
      alignItems="stretch"
      direction={isReversed ? 'row-reverse' : 'row'}
      gap={SpaceSizes.md}
      justifyContent="space-between"
      width="100%"
    >
      <Stack
        alignItems="center"
        gap={{ xs: SpaceSizes.sm, md: SpaceSizes.md, lg: SpaceSizes.mdPlus }}
      >
        <TradeTypeIndicator
          size={isSmallestMobile ? 'small' : 'medium'}
          type={indicationDirection(true)}
        />
        {requestOffer && (
          <TradeRequestShowcase
            isStatic={isReversed !== undefined}
            items={[requestOffer]}
            minimum={1}
          />
        )}
      </Stack>
      {!isSmallestMobile && (
        <Stack
          alignItems="center"
          color="text.secondary"
          fontSize={TRADE_ICON_SIZE}
          justifyContent="center"
        >
          <SyncAlt fontSize="inherit" />
        </Stack>
      )}

      <Stack
        alignItems="center"
        flexDirection="column"
        gap={{ xs: SpaceSizes.sm, md: SpaceSizes.md, lg: SpaceSizes.mdPlus }}
      >
        <TradeTypeIndicator
          size={isSmallestMobile ? 'small' : 'medium'}
          type={indicationDirection(false)}
        />
        <TradeRequestShowcase
          isStatic={isReversed !== undefined}
          items={sentOffer}
          removeOffer={removeOffer}
        />
      </Stack>
    </Stack>
  );
};
