/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import DEFAULT_LOGO from '../../assets/img/default_logo_club.png';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import { Video } from '../../components/Video/Video';

const ImageHomeCard = ({
  showBorder,
  location,
  title,
  clickable,
  index,
  imgSize,
  cardName,
}: {
  showBorder?: boolean;
  location: string;
  title?: string;
  clickable?: boolean;
  index?: number;
  imgSize: 'cover' | 'contain' | 'fill';
  cardName?: string;
}) => {
  return (
    <>
      {index && (
        <Box
          px={SpaceSizes.sm}
          py={SpaceSizes.xs}
          sx={{
            backgroundColor: '#262951',
            position: 'absolute',
            borderRadius: '8px 0 8px 0',
            top: '0',
            left: '0',
          }}
        >
          <Typography
            fontSize="20px"
            fontStyle="italic"
            fontWeight={900}
            margin={0}
            padding={0}
          >
            {index}
          </Typography>
        </Box>
      )}
      {cardName && (
        <Box
          px={SpaceSizes.sm}
          py={SpaceSizes.xs}
          sx={{
            backgroundColor: '#262951',
            position: 'absolute',
            borderRadius: '0px 8px 0px 8px',
            bottom: '0',
            left: '0',
          }}
        >
          <Typography
            fontSize="14px"
            fontStyle="italic"
            fontWeight={800}
            margin={0}
            padding={0}
          >
            {cardName}
          </Typography>
        </Box>
      )}
      <ImageFallback
        alt={title || 'Home Card'}
        fallback={DEFAULT_LOGO}
        height="25"
        src={location}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '8px',
          objectFit: imgSize,
          border: showBorder ? '1px solid #7A7A93;' : 'none',
          cursor: clickable ? 'pointer' : 'default',
        }}
        width="25"
      />
    </>
  );
};

const VideoHomeCard = ({
  location,
  isActive,
  coverImageUrl,
}: {
  location: string;
  isActive?: boolean;
  coverImageUrl?: string;
}) => {
  return (
    <Video
      coverImageUrl={coverImageUrl}
      isActive={isActive}
      location={location}
    />
  );
};

export const HomeCard = ({
  cardName,
  showBorder,
  location,
  title,
  clickable,
  index,
  imgSize,
  type,
  isActive,
  coverImageUrl,
}: {
  showBorder?: boolean;
  location: string;
  title?: string;
  clickable?: boolean;
  index?: number;
  imgSize: 'cover' | 'contain' | 'fill';
  type?: string;
  isActive?: boolean;
  cardName?: string;
  coverImageUrl?: string;
}) => {
  if (type === 'IMAGE' || !type)
    return (
      <ImageHomeCard
        cardName={cardName}
        clickable={clickable}
        imgSize={imgSize}
        index={index}
        location={location}
        showBorder={showBorder}
        title={title}
      />
    );
  return (
    <VideoHomeCard
      coverImageUrl={coverImageUrl}
      isActive={isActive}
      location={location}
    />
  );
};
