import React, { useEffect, useState } from 'react';

import { ImageProps } from './ImageFallback.types';

export const ImageFallback = ({
  alt = '',
  src,
  height,
  width,
  fallback = '',
  style,
  onMouseEnter,
  ...props
}: ImageProps) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleMouse = () => {
    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  return (
    <img
      alt={alt}
      height={height}
      loading="lazy"
      src={imgSrc || fallback}
      style={{
        ...style,
        pointerEvents: onMouseEnter ? 'auto' : 'none',
      }}
      width={width}
      onError={() => setImgSrc(fallback)}
      onMouseEnter={handleMouse}
      {...props}
    />
  );
};
