import { useGrowthBook } from '@growthbook/growthbook-react';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_TYPE } from '../../../utils/tagger';
import { Link } from '../Link/Link';
import { LINKS } from './Footer.constants';

const FooterLinks = () => {
  const { t } = useTranslation();
  const growthbook = useGrowthBook();

  const contact = useCallback(() => {
    const isIntercomEnabled = growthbook?.isOn('is_intercom_enabled');

    if (!isIntercomEnabled) {
      window.location.href = 'mailto:help@gol-ball.com';
    } else if (window.Intercom) {
      window.Intercom('show');
    }
  }, [growthbook]);

  return (
    <Grid justifyContent="flex-start" spacing={SpaceSizes.sm} container>
      {LINKS.map((section) => (
        <Grid key={section.I18N} sm={4} xs={6} item>
          <Stack spacing={SpaceSizes.sm}>
            <Typography fontWeight="bold" variant="body1">
              {t(`footer.links.${section.I18N}.title`)}
            </Typography>
            <Stack spacing={SpaceSizes.sm}>
              {section.items.map((link) =>
                link.I18N === 'contact' ? (
                  <Stack
                    key={link.I18N}
                    sx={{ cursor: 'pointer' }}
                    onClick={contact}
                  >
                    <Typography variant="body1">
                      {t(`footer.links.${section.I18N}.${link.I18N}`)}
                    </Typography>
                  </Stack>
                ) : (
                  <Link
                    key={link.url}
                    external={link.external}
                    tagId={link.tag}
                    tagSectionId={TAG_TYPE.MENU_FOOTER}
                    to={link.url}
                  >
                    <Typography variant="body1">
                      {t(`footer.links.${section.I18N}.${link.I18N}`)}
                    </Typography>
                  </Link>
                ),
              )}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export { FooterLinks };
