export const NotificationStatus = {
  NEW: 'NEW',
  SHOWN: 'SHOWN',
  READ: 'READ',
};

export type NotificationStatusType =
  (typeof NotificationStatus)[keyof typeof NotificationStatus];

export interface Notification {
  id: string;
  user_id: string;
  service: string;
  title: string;
  type: string;
  created_dt: Date;
  thumbnail: string;
  link: string;
  status_code: NotificationStatusType;
  status_dt: Date;
  ttl_date: Date;
  // extra_data?: NotificationExtraData;
  extra_data?: Trade | Trivia;
}

// export enum NotificationType {
//   TRADE = 'TRADE',
//   TRIVIA = 'TRIVIA',
// }

// export type NotificationExtraData = Trade | Trivia;

export type Trade = {
  your_offer: NotificationTradeOfferItem;
  his_offer: NotificationTradeOfferItem[];
  tx_id: string;
};
// export interface Trade {
//   type: NotificationType.TRADE;
//   your_offer: NotificationTradeOfferItem;
//   his_offer: NotificationTradeOfferItem[];
//   tx_id: string;
// }

export interface Trivia {
  battle_id: string;
  product_title: string;
  round_number: number;
}

// export interface Trivia {
//   type: NotificationType.TRIVIA;
//   battle_id: string;
//   productTitle: string;
//   round_number: number;
// }
export interface NotificationTradeOfferItem {
  player_name: string;
  home_code: string;
  away_code: string;
}

export interface TradeNotification extends Notification {
  extra_data: Trade;
}

export interface TriviaNotification extends Notification {
  extra_data: Trivia;
}

// export type Notification = TriviaNotification | TradeNotification;
