import { useGrowthBook } from '@growthbook/growthbook-react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  selectIsGuidesPage,
  selectIsMarketplacePage,
  selectIsMyCollectionPage,
  selectIsMyCollectionPublicPage,
  selectIsTradePage,
} from '../../../features/router/router';
import { getStorage } from '../../../utils/storage';
import useIsIphone from '../../hooks/useIsIphone';
import { StoryOrigin } from './Story.enums';
import { GrowthbookStories } from './Story.interfaces';
import { StoryHighlight } from './StoryHighlight';

export const StoryReel = () => {
  const growthbook = useGrowthBook();
  const isIphone = useIsIphone();
  const { stories } = growthbook?.getFeatureValue(
    'avatar_stories',
    {},
  ) as GrowthbookStories;
  const location = useLocation();
  const storyType = useMemo((): StoryOrigin => {
    if (selectIsMarketplacePage(location)) {
      return StoryOrigin.MARKET;
    }
    if (selectIsTradePage(location)) {
      return StoryOrigin.TRADE;
    }
    if (
      selectIsMyCollectionPage(location) ||
      selectIsMyCollectionPublicPage(location)
    ) {
      return StoryOrigin.COLLECTION;
    }
    return StoryOrigin.DEFAULT;
  }, [location]);
  const pickedStories = useMemo(() => {
    return stories?.[storyType];
  }, [stories, storyType]);
  const initialFinishedStories = useMemo(() => {
    const finishedStories = getStorage()?.getItem(
      `finishedStories-${storyType}`,
    );
    return finishedStories ? JSON.parse(finishedStories) : [];
  }, [storyType]);

  const isAllSeen = useMemo(() => {
    if (!pickedStories) return false;
    return pickedStories.every((story) =>
      initialFinishedStories.includes(story),
    );
  }, [pickedStories, initialFinishedStories]);
  const needsResizing = useMemo(() => {
    return (
      selectIsMarketplacePage(location) ||
      selectIsMyCollectionPage(location) ||
      selectIsGuidesPage(location)
    );
  }, [location]);

  if (isAllSeen || !pickedStories || pickedStories?.length === 0 || isIphone) {
    return null;
  }

  return (
    <StoryHighlight
      needsResizing={needsResizing}
      stories={pickedStories}
      storyType={storyType}
    />
  );
};
