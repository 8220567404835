import { Stack, Typography } from '@mui/material';
import { isNonEmptyArray, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  useGetPreferencesTeamsQuery,
  useSaveTeamsPrefMutation,
} from '../../../../../features/users/Users.api';
import { Team } from '../../../../../features/users/Users.types';
import SpaceSizes from '../../../../../theme/foundations/spacing/SpaceSizes';
import { isSameArray } from '../../../../../utils/validation';
import DefaultLogoClub from '../../../../assets/img/default_logo_club.png';
import { useTranslationByNamespaces } from '../../../../hooks/shared/useTranslationByNamespaces';
import { useAccordion } from '../../../../hooks/useAccordion';
import { useBackNavigation } from '../../../../hooks/useBackNavigation';
import { AccordionSection } from '../../../AccordionSection/AccordionSection';
import { AccordionSkeleton } from '../../../AccordionSkeleton/AccordionSkeleton';
import { AlertCard } from '../../../AlertCard/AlertCard';
import { AlertSeverity } from '../../../AlertCard/AlertSeverity';
import { Checkmarks } from '../../../Checkmarks/Checkmarks';
import Form from '../../../form/Form';
import { GolButton } from '../../../GolButton/GolButton.component';
import {
  accordionContent,
  accordionStyled,
  buttonContainer,
  buttonSytled,
  formStackStyled,
  formStyled,
  typographySytled,
} from './FormTeams.constants';
import { TeamChangeInput } from './FormTeams.type';

export const FormTeams = () => {
  const methodsTeam = useForm<TeamChangeInput>();
  const handleBackNavigation = useBackNavigation('/configuration');
  const [alertCode, setAlertCode] = useState<string | null>(null);
  const { t } = useTranslationByNamespaces('pages.profile');
  const { selectedAccordion, handleFilterTypeChange } = useAccordion();
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);

  const {
    data: preferenceLeaguesWithTeams,
    isSuccess: isSuccessPreferenceLeaguesWithTeams,
    isLoading: isLoadingPreferenceLeaguesWithTeams,
  } = useGetPreferencesTeamsQuery();

  const [
    saveTeams,
    { isLoading: isLoadingSaveTeam, isSuccess: isSuccessSaveTeam },
  ] = useSaveTeamsPrefMutation();

  useEffect(() => {
    if (isNonEmptyArray(preferenceLeaguesWithTeams)) {
      const preferenceTeams = preferenceLeaguesWithTeams.flatMap((league) =>
        league.teams.filter((team) => team.preferred),
      );

      setSelectedTeams(preferenceTeams);
    }
  }, [preferenceLeaguesWithTeams]);

  const onSubmitTeams: SubmitHandler<TeamChangeInput> = async () => {
    const selectedIds = selectedTeams.map((team) => team.id);

    try {
      await saveTeams({ teams: selectedIds });
      setAlertCode('teamSuccess');
    } catch (error) {
      setAlertCode('profileError');
    }
  };

  const handleCheckmarksChange = (selectedItems: Team[], leagueId: string) => {
    if (preferenceLeaguesWithTeams) {
      const currentLeagueTeams = preferenceLeaguesWithTeams.find(
        (league) => league.brand_id === leagueId,
      )?.teams;

      const otherLeaguesTeams = selectedTeams.filter(
        (team) =>
          !currentLeagueTeams?.some((leagueTeam) => leagueTeam.id === team.id),
      );

      const updatedSelectedTeams = [...otherLeaguesTeams, ...selectedItems];

      setSelectedTeams(updatedSelectedTeams);
    }
  };

  const isSameSelection = useMemo(() => {
    const selectedIds = selectedTeams.map((team) => team.id);

    const preferredTeamIds =
      preferenceLeaguesWithTeams?.flatMap((league) =>
        league.teams.filter((team) => team.preferred).map((team) => team.id),
      ) || [];

    if (!isNonEmptyArray(selectedIds) || !isNonEmptyArray(preferredTeamIds))
      return false;
    return isSameArray(selectedIds, preferredTeamIds);
  }, [selectedTeams, preferenceLeaguesWithTeams]);

  const buttonDisabled = useMemo(() => {
    return !isNonEmptyArray(selectedTeams) || isSameSelection;
  }, [selectedTeams, isSameSelection]);

  if (isLoadingPreferenceLeaguesWithTeams) return <AccordionSkeleton />;

  return (
    <>
      {isNotNilOrEmpty(alertCode) && (
        <AlertCard
          content={t(`alerts.${alertCode}`)}
          id="profileError"
          severity={
            alertCode?.includes('Error')
              ? AlertSeverity.ERROR
              : AlertSeverity.SUCCESS
          }
          show={isNotNilOrEmpty(alertCode)}
        />
      )}
      <Typography component="h2" sx={typographySytled} variant="cH5">
        {t('preferencesSection.teams.subtitle')}
      </Typography>

      <Form methods={methodsTeam} style={formStyled} onSubmit={onSubmitTeams}>
        <Stack sx={formStackStyled}>
          <Stack spacing={SpaceSizes.smPlus} width="100%">
            {preferenceLeaguesWithTeams?.map((league) => (
              <AccordionSection
                key={league.brand_id}
                accordionKey={`league-${league.brand_id}`}
                expanded={selectedAccordion === league.brand_id}
                imgSrc={league.logo || DefaultLogoClub}
                sx={accordionStyled}
                titleKey={league.league_name}
                onChange={handleFilterTypeChange(league.brand_id)}
              >
                <Stack sx={accordionContent}>
                  <Checkmarks
                    disabled={!isSuccessPreferenceLeaguesWithTeams}
                    id={`club-${league.brand_id}`}
                    initialValues={selectedTeams}
                    options={league.teams ?? []}
                    placeholder={t('preferencesSection.clubLabel')}
                    onChange={(selected) =>
                      handleCheckmarksChange(selected, league.brand_id)
                    }
                  />
                </Stack>
              </AccordionSection>
            ))}
            <Stack sx={buttonContainer}>
              <GolButton
                size="large"
                sx={buttonSytled}
                value={t('preferencesSection.clubCancel')}
                variant="secondary"
                onClick={() => {
                  handleBackNavigation();
                }}
              />
              <GolButton
                disabled={buttonDisabled}
                loading={isLoadingSaveTeam && !isSuccessSaveTeam}
                size="large"
                sx={buttonSytled}
                type="submit"
                value={t('preferencesSection.clubConfirm')}
                variant="primary"
              />
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </>
  );
};
