import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import React, { useState } from 'react';

import { TAG_EVENT, TAG_TYPE, TAG_VALUE } from '../../utils/tagger';

const useNotificationContext = () => {
  const sendDataToGTM = useGTMDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const open = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    sendDataToGTM({
      event: TAG_EVENT.SELECT_CONTENT,
      content_type: TAG_TYPE.MENU,
      item_id: TAG_VALUE.HEADER.NOTIF_DROPDOWN,
    });
  };

  const close = () => {
    setAnchorEl(null);
  };

  return { open, close, isOpen, anchorEl };
};

export default useNotificationContext;
