export const SKELETON_SIZE_HEIGHT = {
  xs: 150,
  md: 250,
  lg: 335,
};

export const COLLECTIONS_FONT_SIZE = {
  xs: 18,
  sm: 20,
  lg: 22,
};

export const COLLECTIONS_BALLS_FONT_SIZE = {
  xs: 13,
  sm: 14,
  md: 15,
  lg: 16,
};

export const COLLECTIONS_VARIABLE_SHADOW = {
  xs: 'inset 0px -30px 90px -8px #000000',
  sm: 'inset 0px -60px 90px -20px #000000',
  md: 'inset 0px -90px 50px -15px #000000',
};
