import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Favorite } from '@mui/icons-material';
import { Box, Popover, Stack } from '@mui/material';
import React, { useState } from 'react';

import { useGetBookmarksQuery } from '../../../../features/searchfilter/bookmarks/BookmarkedFilter.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_TYPE, TAG_VALUE } from '../../../../utils/tagger';
import { MenuIconItem } from '../../../components/MenuIconItem/MenuIconItem';
import Spinner from '../../../components/Spinner';
import { FavsDropdownList } from './FavsDropdownList';

export const FavsDropdown = () => {
  const sendDataToGTM = useGTMDispatch();
  const [skip, setSkip] = useState(true);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const open = !!anchorEl;
  const id = open ? 'favs-popover' : undefined;

  const handleOpen = (event: React.MouseEvent<Element>) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setSkip(false);
    sendDataToGTM({
      event: TAG_EVENT.SELECT_CONTENT,
      content_type: TAG_TYPE.MENU,
      item_id: TAG_VALUE.HEADER.FAVS_DROPDOWN,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isLoading } = useGetBookmarksQuery(undefined, {
    skip,
  });

  return (
    <MenuIconItem
      handleOnClick={handleOpen}
      popOver={
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          id={id}
          open={open}
          sx={{ top: SpaceSizes.md }}
          onClose={handleClose}
        >
          <Box p={SpaceSizes.sm}>
            {isLoading ? (
              <Spinner dark />
            ) : (
              <Stack>
                <FavsDropdownList />
              </Stack>
            )}
          </Box>
        </Popover>
      }
      tagId="favs"
    >
      <Favorite />
    </MenuIconItem>
  );
};
