import { Stack, Typography } from '@mui/material';
import React from 'react';

import { listContainertStyled } from './ListBullets.styled';
import { ListBulletsProps } from './ListBullets.types';

export const ListBullets = ({ list, colorItemList, sx }: ListBulletsProps) => {
  return (
    <Stack sx={{ ...listContainertStyled, ...sx }}>
      {list?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={`benefit-${index}`} color={colorItemList}>
          • {item}
        </Typography>
      ))}
    </Stack>
  );
};
