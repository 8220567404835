import { BrandConfig } from '../../../brands/brandOptions';
import {
  IProduct,
  MatchMultimedia,
  Stage,
  Team,
} from '../../products/Product.types';

export const DEFAULT_PAGE_SIZE = 16;
export type CollectableCardResponse = IProduct;
export interface Paging {
  total: number;
  offset: number;
  limit: number;
  is_last_page: boolean;
}

export interface Search {
  query: string;
}

export interface Base {
  id: string;
  name: string;
}

export interface AppliedFilter extends FilterBase {
  value: string;
  valueLabel: string;
  isTradeFilter?: boolean;
}

export type Sort = Base;

export interface FilterBase extends Base {
  type: string;
}

export interface Filter extends FilterBase {
  values: FilterValue[];
}

export interface FilterValue {
  id: string;
  label: string;
  image?: string;
  image_1x?: string;
  image_2x?: string;
  logo?: string;
}

export type AppliedSort = Base;

export type ProductCardResponse = IProduct;

export interface SearchResponse {
  paging: Paging;
  search: Search;
  results: CollectableCardResponse[];
  applied_sort?: AppliedSort;
  sorts?: Sort[];
  filters: Filter[];
  appliedFilters: AppliedFilter[];
  match_result?: Match;
  total_collectibles?: number;
}

export interface FilterRequest extends Omit<AppliedFilter, 'name' | 'type'> {
  id: string; // filter id
  value: string; // filter value id
}

export interface PaginationRequest
  extends Omit<Paging, 'total' | 'is_last_page'> {
  limit: number;
  offset: number;
}

export interface SearchRequest {
  currentBrand: BrandConfig | undefined;
  appliedFilters: FilterRequest[];
  sortFilter: FilterRequest;
  pagination: PaginationRequest;
  query: string;
}

export type Match = {
  status: string;
  date: string;
  time: string;
  week: string;
  stadium: string;
  winner: string;
  banners: MatchMultimedia[];
  home: Team;
  away: Team;
  is_live: boolean;
  active_presale: boolean;
  stage: Stage;
};

export interface MarketplaceFilter {
  search: string;
  page: number;
}
