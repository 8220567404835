export const PRODUCT_TAG_WIDTH = {
  related: {
    sm: 90,
    md: 112,
  },
  default: {
    sm: 150,
    md: 235,
  },
  profile: {
    sm: 110,
  },
};

export const PRODUCT_TAG_HEIGHT = {
  related: {
    sm: 24,
    md: 30,
  },
  default: {
    sm: 48,
    md: 50,
  },
  profile: {
    sm: 40,
  },
};

export const PRODUCT_TAG_IMAGE_SIZE = {
  sm: 40,
  md: 65,
};

export const PRODUCT_TAG_MUSEUM_GAP = {
  related: 0,
  card: 5,
  default: 10,
};

export const PRODUCT_TAG_PADDING = {
  mobile: '3px 10px',
};

export const PRODUCT_TAG_RADIUS = {
  mobile: '8px',
};
