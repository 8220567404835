import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import LanguageIcon from '@mui/icons-material/Language';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateUser } from '../../../../features/session/Session.slice';
import { useUpdateUserMutation } from '../../../../features/users/Users.api';
import { User } from '../../../../features/users/Users.types';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_TYPE, TAG_VALUE } from '../../../../utils/tagger';
import { useAppDispatch } from '../../../hooks';
import i18n, { geti18nResolvedLanguage } from '../../../i18n';

export const LanguageMenu = ({ user }: { user?: User | null }) => {
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    setSelectedLanguage(user?.lang_id || geti18nResolvedLanguage());
  }, [user]);

  const [patchUser] = useUpdateUserMutation();
  const dispatch = useAppDispatch();

  const changeUserLanguage = async (lang: string) => {
    if (!user) return;

    try {
      await patchUser({
        user_id: user?.user_id ?? '',
        data: {
          lang_id: lang,
        },
      }).unwrap();
      dispatch(updateUser({ lang_id: lang }));
    } catch (error) {
      // do nothing
    }
  };

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    changeUserLanguage(lang);
    sendDataToGTM({
      event: TAG_EVENT.SELECT_CONTENT,
      content_type: TAG_TYPE.MENU,
      value: TAG_VALUE.HEADER.LANG_DROPDOWN,
    });
  };

  return (
    <FormControl fullWidth>
      <Stack gap={SpaceSizes.sm} justifyContent="flex-start">
        <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.sm}>
          <LanguageIcon fontSize="small" />
          <Typography
            id="language-select-label"
            textTransform="uppercase"
            variant="body2"
          >
            {t('components.languageMenu.title')}
          </Typography>
        </Stack>

        <Select
          id="language-select"
          labelId="language-select-label"
          value={selectedLanguage}
          onChange={handleSelect}
        >
          {i18n.languages.map((lang) => (
            <MenuItem key={lang} value={lang}>
              {t(`components.languageMenu.${lang}`)}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </FormControl>
  );
};
