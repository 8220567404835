import LockIcon from '@mui/icons-material/Lock';
import { Box, styled, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { ILockedFeatureOverlay } from './LockedFeatureOverlay.types';

const Container = styled(Box)({
  position: 'relative',
});

const Overlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  pointerEvents: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  rowGap: SpaceSizes.sm,
  alignItems: 'flex-start',
  padding: SpaceSizes.md,
  borderRadius: `0px 0px ${SpaceSizes.smPlus}px ${SpaceSizes.smPlus}px`,
}));

const StyledLockIcon = styled(LockIcon)({
  color: 'white',
});

const StyledText = styled(Typography)({
  color: 'white',
});

const LockedFeatureOverlay: FC<ILockedFeatureOverlay> = ({
  children,
  isLocked,
  isMissing,
}) => {
  const { t } = useTranslationByNamespaces('pages.product.productSection');
  const blocked = useMemo(() => isLocked || isMissing, [isLocked, isMissing]);
  return (
    <Container>
      {children}
      {blocked && (
        <Overlay data-testid={isMissing ? 'missing-feature' : 'locked-feature'}>
          <StyledLockIcon />
          <StyledText variant="body1">
            {t(isMissing ? 'missingFeature' : 'lockedFeature')}
          </StyledText>
        </Overlay>
      )}
    </Container>
  );
};

export default LockedFeatureOverlay;
