const INVALID_RECOVERY_DATA = 'INVALID_RECOVERY_DATA';
const CODE_ALREADY_USED = 'CODE_ALREADY_USED';
const ACTIVATION_ALREADY_MADE = 'ACTIVATION_ALREADY_MADE';
const BANNED_COUNTRIES = ['DZ', 'SA', 'BO', 'CN', 'EG', 'ID', 'MA', 'QA', 'VN'];

const TRIVIA_COUNTRIES = [
  'US',
  'PL',
  'CA',
  'IN',
  'SG',
  'PR',
  'AK',
  'GU',
  'HI',
  'VI',
  'AS',
  'MP',
  'WS',
];

export const isBannedCountry = (ipCountry: string) =>
  BANNED_COUNTRIES.some((country) => country === ipCountry);

export const isTriviaCountry = (ipCountry: string) =>
  TRIVIA_COUNTRIES.some((country) => country === ipCountry);

export {
  ACTIVATION_ALREADY_MADE,
  BANNED_COUNTRIES,
  CODE_ALREADY_USED,
  INVALID_RECOVERY_DATA,
};
