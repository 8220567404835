import { useGrowthBook } from '@growthbook/growthbook-react';
import { Divider, Stack } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  selectIsFavPage,
  selectIsHomePage,
  selectIsMarketplacePage,
  selectIsMatchesPage,
  selectIsMyCollectionPage,
  selectIsRankPage,
} from '../../../../features/router/router';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { SHOW_COLLECTIONS_BUTTON } from '../../../../utils/growthbookFeatures';
import { TAG_VALUE } from '../../../../utils/tagger';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import MenuDropdownItem from './MenuDropdownItem';

interface Props {
  isLoggedUser: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
}
const MenuDropdownList = ({ isLoggedUser, setIsDropdownOpen }: Props) => {
  const { t } = useTranslationByNamespaces('header.topMenu');
  const location = useLocation();
  const growthbook = useGrowthBook();

  return (
    <Stack spacing={SpaceSizes.sm}>
      <MenuDropdownItem
        data-testid="homeButtonHeader"
        isActive={selectIsHomePage(location)}
        setIsDropdownOpen={setIsDropdownOpen}
        tagId={TAG_VALUE.HEADER.HOME}
        text={t('home')}
        to="/home"
      />
      <MenuDropdownItem
        data-testid="marketplaceButtonHeader"
        isActive={selectIsMarketplacePage(location)}
        setIsDropdownOpen={setIsDropdownOpen}
        tagId={TAG_VALUE.HEADER.MARKETPLACE}
        text={t('marketplace')}
        to="/marketplace?tab=collectables"
      />
      <MenuDropdownItem
        data-testid="marketplaceButtonHeader"
        isActive={selectIsMatchesPage(location)}
        setIsDropdownOpen={setIsDropdownOpen}
        tagId={TAG_VALUE.HEADER.MATCHES}
        text={t('matches')}
        to="/matches"
      />
      {isLoggedUser && (
        <>
          <MenuDropdownItem
            data-testid="favsButtonHeader"
            isActive={selectIsFavPage(location)}
            setIsDropdownOpen={setIsDropdownOpen}
            tagId={TAG_VALUE.HEADER.FAVS}
            text={t('favs')}
            to="/favs"
          />
          <MenuDropdownItem
            data-testid="myCollectionButtonHeader"
            isActive={selectIsMyCollectionPage(location)}
            setIsDropdownOpen={setIsDropdownOpen}
            tagId={TAG_VALUE.HEADER.COLLECTIONS}
            text={t('myCollection')}
            to="/collections"
          />
        </>
      )}
      {!isLoggedUser && growthbook?.isOn(SHOW_COLLECTIONS_BUTTON) && (
        <MenuDropdownItem
          data-testid="myCollectionButtonHeader"
          isActive={selectIsMyCollectionPage(location)}
          setIsDropdownOpen={setIsDropdownOpen}
          tagId={TAG_VALUE.HEADER.COLLECTIONS}
          text={t('myCollection')}
          to="/collections/collections_landing"
        />
      )}
      <MenuDropdownItem
        data-testid="rankButtonHeader"
        isActive={selectIsRankPage(location)}
        setIsDropdownOpen={setIsDropdownOpen}
        tagId={TAG_VALUE.HEADER.MEMBERSHIPS}
        text={t('rank')}
        to="/rank"
      />
      {!isLoggedUser && <Divider sx={{ width: '100%' }} />}
    </Stack>
  );
};

export default MenuDropdownList;
