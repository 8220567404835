import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TO_MARKETPLACE } from '../components/BackButton/BackButton.constants';

export const useBackNavigation = (defaultRoute: string = TO_MARKETPLACE) => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasBackPage = useMemo(() => {
    return location.key !== 'default';
  }, [location.key]);

  const handleBackNavigation = useCallback(() => {
    hasBackPage ? navigate(-1) : navigate(defaultRoute);
  }, [hasBackPage, navigate, defaultRoute]);

  return handleBackNavigation;
};
