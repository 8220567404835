import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { Link } from '../Link/Link';
import { ICollectableAdditionalInfoItem } from './CollectableAdditionalInfo.types';

export const CollectableAdditionalInfoItem = ({
  icon,
  text,
  url,
  closeMenu,
}: ICollectableAdditionalInfoItem) => {
  const { t } = useTranslation();
  if (!url) {
    return null;
  }
  return (
    <Link to={url}>
      <MenuItem
        sx={{
          display: 'flex',
          alignContent: 'center',
          gap: SpaceSizes.sm,
        }}
        onClick={closeMenu}
      >
        <ImageFallback
          alt={text}
          fallback={icon}
          height={SpaceSizes.mdPlus}
          src={icon}
          width={SpaceSizes.mdPlus}
        />
        <Typography>
          {t(`pages.collectionDetails.links.${text.toLowerCase()}`)}
        </Typography>
      </MenuItem>
    </Link>
  );
};
