import { addMinutes, differenceInSeconds, format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

export type TimeUnit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';

export type Interval = {
  value: number;
  unit: TimeUnit;
};

const DAY_MS = 86400000;
const HOUR_MS = 3600000;
const MINUTE_MS = 60000;

// Size in milliseconds
const units: { size: number; name: TimeUnit }[] = [
  { size: 31536000000, name: 'year' },
  { size: 2592000000, name: 'month' },
  { size: 604800000, name: 'week' },
  { size: DAY_MS, name: 'day' },
  { size: HOUR_MS, name: 'hour' },
  { size: MINUTE_MS, name: 'minute' },
];

export const notificationsDateDiff = (
  fromDate: Date,
  toDate: Date,
): Interval => {
  const diff = toDate.getTime() - fromDate.getTime();
  const match = units.find((u) => diff >= u.size);
  const unit = match ?? { size: 1000, name: 'second' };

  return {
    value: Math.round(diff / unit.size),
    unit: unit.name,
  };
};

export const formatUTCtoLocalTime = (
  language: string,
  date: string | Date,
  time?: string,
  dateFormat?: string,
  localizeTimezone = true,
): string => {
  if (!date) return '';
  const datetime = new Date(`${date}T${time ?? '00:00'}`);

  // should localize timezone
  const localDate = localizeTimezone
    ? addMinutes(datetime, -new Date().getTimezoneOffset())
    : datetime;

  const defaultDateFormat =
    language === 'en'
      ? `MM/dd/yyyy${time ? ' - hh:mm aa' : ''}`
      : `dd/MM/yyyy${time ? ' - HH:mm' : ''}`;

  return format(localDate, dateFormat || defaultDateFormat, {
    locale: language === 'en' ? enUS : es,
  });
};

export const calculateRemainingSeconds = (date: Date) => {
  const now = new Date();
  return differenceInSeconds(date, now);
};

export const formatTime = (seconds: number) => {
  const date = new Date(seconds * 1000);
  return format(date, 'ss');
};
