import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setGlobalAlert } from '../../../features/alert/Alert.slice';
import {
  useAddBookmarkMutation,
  useDeleteBookmarkMutation,
} from '../../../features/searchfilter/bookmarks/BookmarkedFilter.api';
import { ProductCardResponse } from '../../../features/searchfilter/responses/SearchResponse';
import { selectIsUserLoggedIn } from '../../../features/session/Session.selector';
import { TAG_EVENT } from '../../../utils/tagger';
import { useGetItemToTrack } from '../../../utils/useGetItemToTrack';
import { useAppDispatch, useAppSelector } from '../../hooks';
import IconTextButton from '../IconTextButton/IconTextButton';

interface HeartBookmarkIconProps {
  product: ProductCardResponse;
  productIndex?: number;
}

export const HeartBookmarkIcon = ({
  product,
  productIndex,
}: HeartBookmarkIconProps) => {
  const { t } = useTranslation();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const sendDataToGTM = useGTMDispatch();
  const [bookmarked, setBookmarked] = useState(product.bookmarked);
  const [
    addBookmark,
    { isError: isAddError, isSuccess: isAddSuccess, isLoading: isAddLoading },
  ] = useAddBookmarkMutation();
  const [
    deleteBookmark,
    {
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteBookmarkMutation();
  const dispatch = useAppDispatch();
  const itemToTrack = useGetItemToTrack(product, productIndex || 0);

  const handleChange = useCallback(() => {
    if (bookmarked) {
      deleteBookmark(product.product_id);
    } else {
      addBookmark(product.product_id);
      sendDataToGTM({
        event: TAG_EVENT.ADD_TO_WISHLIST,
        ecommerce: {
          items: [itemToTrack],
        },
      });
    }
    setBookmarked(!bookmarked);
  }, [
    bookmarked,
    addBookmark,
    deleteBookmark,
    sendDataToGTM,
    product.product_id,
    itemToTrack,
  ]);

  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    handleChange();
  };

  useEffect(() => {
    if (!isAddLoading) {
      if (isAddError) {
        dispatch(
          setGlobalAlert({
            type: 'error',
            message: t('alert.addBookmarkerror'),
          }),
        );
      }
      if (isAddSuccess) {
        dispatch(
          setGlobalAlert({
            type: 'success',
            message: t('alert.addBookmarkSuccess'),
          }),
        );
      }
    }
  }, [isAddLoading, dispatch, isAddError, isAddSuccess, t]);

  useEffect(() => {
    if (!isDeleteLoading) {
      if (isDeleteError) {
        dispatch(
          setGlobalAlert({
            type: 'error',
            message: t('alert.deleteBookmarkError'),
          }),
        );
      }
      if (isDeleteSuccess) {
        dispatch(
          setGlobalAlert({
            type: 'success',
            message: t('alert.deleteBookmarkSuccess'),
          }),
        );
      }
    }
  }, [isDeleteLoading, dispatch, isDeleteError, isDeleteSuccess, t]);

  return isLoggedUser ? (
    <IconTextButton
      icon={
        bookmarked ? (
          <FavoriteIcon sx={{ color: 'text.secondary' }} />
        ) : (
          <FavoriteBorderIcon sx={{ color: 'text.secondary' }} />
        )
      }
      id="bookmark-button"
      nativeId={bookmarked ? 'remove_bookmark' : 'add_bookmark'}
      textKey="pages.product.bookmark"
      onClick={handleClick}
    />
  ) : null;
};
