import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';

import {
  selectBallName,
  selectCollectibles,
  selectCurrency,
  selectCurrentGol,
  selectGolpointsSymbol,
  selectNotAssignableOrAlreadyAssigned,
  selectPriceValue,
  selectPriceWithCurrency,
  selectProduct,
  selectProduct3dCertificateMultimedia,
  selectProduct3dMultimedia,
  selectProductAssigment,
  selectProductCarrouselMultimedia,
  selectProductChainOfCustodyMultimedia,
  selectProductContent,
  selectProductContract,
  selectProductGoalSoundMultimedia,
  selectProductGoalVideoMultimedia,
  selectProductGolClubId,
  selectProductId,
  selectProductIsAuction,
  selectProductIsHolded,
  selectProductIsOwned,
  selectProductIsTriviaNotStarted,
  selectProductIsTriviaStartedOrFinished,
  selectProductMatch,
  selectProductMatchAwayClub,
  selectProductMatchCompetition,
  selectProductMatchHomeClub,
  selectProductMatchStage,
  selectProductMatchTournament,
  selectProductPlayer,
  selectProductPlayerMultimedia,
  selectProductStatus,
  selectProductTacticMultimedia,
  selectProductTags,
} from '../../../../features/products/Product.selectors';
import { EBallType } from '../../../../features/products/Product.types';
import { useGetProductByIdQuery } from '../../../../features/products/Products.api';
import { TAG_EVENT } from '../../../../utils/tagger';
import { useGetItemToTrack } from '../../../../utils/useGetItemToTrack';
import { AlertCardProps } from '../../../components/AlertCard/AlertCard.types';
import { AlertSeverity } from '../../../components/AlertCard/AlertSeverity';
import { ProductTagTypeEnum } from '../../../components/ProductTag/ProductTag.types';
import { useTranslationByNamespaces } from '../../shared/useTranslationByNamespaces';

export const useProducts = (productId?: string) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [sentTracks, setSentTracks] = useState<boolean>(false);
  const sendDataToGTM = useGTMDispatch();
  const { t } = useTranslationByNamespaces('pages.product.productSection');

  const getProductByIdResponse = useGetProductByIdQuery(
    productId ? { productId } : skipToken,
    {
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        product: selectProduct(data),
        productId: selectProductId(data),
        product3dMultimedia: selectProduct3dMultimedia(data),
        product3dCertificateMultimedia:
          selectProduct3dCertificateMultimedia(data),
        productTacticMultimedia: selectProductTacticMultimedia(data),
        productChainOfCustodyMultimedia:
          selectProductChainOfCustodyMultimedia(data),
        productGoalSoundMultimedia: selectProductGoalSoundMultimedia(data),
        productGoalVideoMultimedia: selectProductGoalVideoMultimedia(data),
        productTags: selectProductTags(data),
        tournament: selectProductMatchTournament(data),
        competition: selectProductMatchCompetition(data),
        stage: selectProductMatchStage(data),
        awayClub: selectProductMatchAwayClub(data),
        homeClub: selectProductMatchHomeClub(data),
        player: selectProductPlayer(data),
        match: selectProductMatch(data),
        productEvent: selectCurrentGol(data),
        carrouselMultimedia: selectProductCarrouselMultimedia(data),
        price: selectPriceWithCurrency(data),
        currency: selectCurrency(data),
        priceValue: selectPriceValue(data),
        isGolpointsSymbol: selectGolpointsSymbol(data),
        productStatus: selectProductStatus(data),
        productBallName: selectBallName(data),
        productContract: selectProductContract(data),
        playerMultimedia: selectProductPlayerMultimedia(data),
        collectibles: selectCollectibles(data),
        productIsHolded: selectProductIsHolded(data),
        goalClubId: selectProductGolClubId(data),
        contents: selectProductContent(data),
        assigment: selectProductAssigment(data),
        isOwned: selectProductIsOwned(data),
        notAssignableOrAlreadyAssigned:
          selectNotAssignableOrAlreadyAssigned(data),
        isTriviaStartedOrFinished: selectProductIsTriviaStartedOrFinished(data),
        isTriviaNotStarted: selectProductIsTriviaNotStarted(data),
        isProductAuction: selectProductIsAuction(data),
      }),
    },
  );

  const {
    competition,
    product,
    isLoading,
    isUninitialized,
    isError,
    awayClub,
    homeClub,
    player,
    match,
    productEvent,
    carrouselMultimedia,
    productStatus,
    productContract,
    collectibles,
    productIsHolded,
    goalClubId,
    priceValue,
    currency,
    stage,
    product3dMultimedia,
    product3dCertificateMultimedia,
    productGoalSoundMultimedia,
    productGoalVideoMultimedia,
    productTags,
    tournament,
    price,
    isGolpointsSymbol,
    productBallName,
    playerMultimedia,
    contents,
    assigment,
    isOwned,
    notAssignableOrAlreadyAssigned,
    isTriviaStartedOrFinished,
    isTriviaNotStarted,
    isProductAuction,
  } = getProductByIdResponse;

  const hasPhysicalBall =
    product?.ball_type && product?.ball_type === EBallType.ORIGINAL;

  const isStatusFinished = useMemo(
    () =>
      productStatus === 'finished' ||
      !collectibles?.on_sale ||
      collectibles.on_sale === 0,
    [productStatus, collectibles?.on_sale],
  );

  const itemToTrack = useGetItemToTrack(product, 0);

  const showCharityPrefix = useMemo(() => {
    if (!product) {
      return false;
    }

    return (
      product.match.week === '22' ||
      product.product_id === 'c0cc9bf9-54bb-4ac4-87b7-2c75c258e304'
    );
  }, [product]);

  const isStatusNew = useMemo(
    () => productStatus === 'new' && !isStatusFinished,
    [productStatus, isStatusFinished],
  );

  const isLive = useMemo(() => match?.is_live ?? false, [match]);

  const assignmentNumber = useMemo(
    () => product?.assignment?.piece_number,
    [product],
  );

  const showAlert = useMemo(
    () => isStatusNew || isStatusFinished || isLive || !!assignmentNumber,
    [isStatusNew, isStatusFinished, isLive, assignmentNumber],
  );

  const isBuyAllowed = useMemo(() => {
    return !isStatusFinished && !productIsHolded;
  }, [productIsHolded, isStatusFinished]);

  useEffect(() => {
    if (!sentTracks && itemToTrack) {
      sendDataToGTM({
        event: TAG_EVENT.VIEW_ITEM,
        ecommerce: {
          items: [itemToTrack],
        },
      });
      setSentTracks(true);
    }
  }, [itemToTrack, sendDataToGTM, sentTracks]);

  const blockChainContract =
    productContract && productContract.contract_address;

  const isMuseum =
    product?.tags?.some((tag: string) => tag === ProductTagTypeEnum.MUSEUM) ??
    false;

  const golClubId = goalClubId ?? player?.team_id;
  const homeGoal = golClubId === homeClub?.id;
  const awayGoal = golClubId === awayClub?.id;

  const assignAlert = () => {
    if (isMuseum) {
      return t('museumProduct.content');
    }
    if (productIsHolded) {
      return t('holdedProduct.content');
    }
    if (isLive) {
      return t('liveProduct.content');
    }
    return t('newProduct.content');
  };
  const assignAlertSeverity = (): AlertSeverity => {
    if (isStatusFinished && !assignmentNumber) {
      return AlertSeverity.WARNING;
    }
    return AlertSeverity.INFO;
  };

  const alert: AlertCardProps = {
    content: assignAlert(),
    id: 'productDetailsProduct',
    show: showAlert,
    severity: assignAlertSeverity(),
  };

  const eventImages = useMemo(() => {
    const images = [playerMultimedia?.location];
    const locationList = carrouselMultimedia.map((multimedia) =>
      multimedia?.location ? multimedia?.location : '',
    );

    return images.concat(locationList.flat());
  }, [playerMultimedia, carrouselMultimedia]);

  return {
    data: {
      contents,
      stage,
      product3dMultimedia,
      product3dCertificateMultimedia,
      productGoalSoundMultimedia,
      productGoalVideoMultimedia,
      productTags,
      tournament,
      price,
      isGolpointsSymbol,
      productBallName,
      playerMultimedia,
      competition,
      alert,
      isStatusFinished,
      product,
      isMuseum,
      showCharityPrefix,
      blockChainContract,
      isShown,
      isBuyAllowed,
      setIsShown,
      homeGoal,
      awayGoal,
      hasPhysicalBall,
      productEvent,
      eventImages,
      awayClub,
      homeClub,
      player,
      match,
      productStatus,
      productContract,
      collectibles,
      productIsHolded,
      goalClubId,
      priceValue,
      currency,
      assigment,
      isOwned,
      notAssignableOrAlreadyAssigned,
      isTriviaStartedOrFinished,
      isTriviaNotStarted,
      isProductAuction,
    },
    isLoading,
    isError,
    isUninitialized,
  };
};
