import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { Button, Chip, Stack, styled, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import env from '../../../env/env';
import { useGetTriviaSummaryQuery } from '../../../features/trivia/Trivia.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { ImageFallback } from '../../components/ImageFallback/ImageFallback';
import { Link } from '../../components/Link/Link';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import TriviaGame from './TriviaGame';
import { ETriviaSummaryStatus } from './TriviaStatus.types';

const CenteredStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const determineQuestionKey = (
  isBonus: boolean | undefined,
  isLast: boolean | undefined,
) => {
  switch (true) {
    case isBonus:
      return 'bonus';
    case !isBonus && isLast:
      return 'lastQuestion';
    default:
      return 'today';
  }
};

const Trivia = () => {
  const { battleId } = useParams();
  const { data, isLoading } = useGetTriviaSummaryQuery(battleId || skipToken);
  const { t } = useTranslationByNamespaces('pages.trivia');

  const [startGame, setStartGame] = useState(false);

  const navigate = useNavigate();

  const handleStartGame = useCallback(() => setStartGame(true), [setStartGame]);

  const questionKey = useMemo(
    () => determineQuestionKey(data?.is_bonus_question, data?.is_last_question),
    [data?.is_bonus_question, data?.is_last_question],
  );

  return (
    <PageContainer
      backButton={!startGame}
      id="trivia"
      loading={isLoading}
      maxWidth="sm"
      title={t('prevTrivia.pageTitle')}
    >
      <Stack gap={SpaceSizes.lg} width="100%">
        {data?.status_code === ETriviaSummaryStatus.OK ? (
          startGame && battleId ? (
            <TriviaGame battleId={battleId} />
          ) : (
            <>
              <Stack
                borderRadius="8px"
                gap={SpaceSizes.sm}
                justifyContent="start"
                p={SpaceSizes.md}
                sx={{ backgroundColor: 'background.paper' }}
                textAlign="left"
              >
                <Typography fontWeight={700} variant="h5">
                  {t(`prevTrivia.${questionKey}.title`)}
                </Typography>
                <Typography variant="body2">
                  {t(`prevTrivia.${questionKey}.description`)}
                </Typography>
                {data?.question_timeout && (
                  <Typography variant="body2">
                    {t(`prevTrivia.time`, {
                      question_timeout:
                        data?.question_timeout?.toString() || '',
                    })}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" gap={SpaceSizes.sm}>
                {data?.user_lives && data?.user_lives > 0 && (
                  <Chip
                    color="secondary"
                    icon={<GroupOutlinedIcon />}
                    label={t('prevTrivia.lifes', {
                      lifes: data?.user_lives?.toString() || '',
                    })}
                    variant="outlined"
                  />
                )}
                <Chip
                  color="secondary"
                  icon={<AddReactionOutlinedIcon />}
                  label={t('prevTrivia.contestants', {
                    contestants: data?.contestants?.toString() || '',
                  })}
                  variant="outlined"
                />
              </Stack>

              <CenteredStack gap={SpaceSizes.md} width="100%">
                <Button variant="contained" fullWidth onClick={handleStartGame}>
                  {t(`prevTrivia.actions.letsPlay`)}
                </Button>
                <Link color="secondary" to="/trivial">
                  {t(`prevTrivia.actions.knowMore`)}
                </Link>
              </CenteredStack>
            </>
          )
        ) : (
          <CenteredStack gap={SpaceSizes.lg}>
            <ImageFallback
              height={75}
              src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/trivia/${data?.status_code}.png`}
              width={79}
            />
            <Stack gap={SpaceSizes.sm}>
              <Typography fontWeight={700} variant="h5">
                {t(`prevTrivia.battleEndedCases.${data?.status_code}.title`)}
              </Typography>
              <Typography variant="body2">
                {t(
                  `prevTrivia.battleEndedCases.${data?.status_code}.description`,
                )}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              fullWidth
              onClick={() => navigate('/marketplace')}
            >
              {t(`prevTrivia.actions.goToMarket`)}
            </Button>
          </CenteredStack>
        )}
      </Stack>
    </PageContainer>
  );
};

export default Trivia;
