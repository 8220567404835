import { isNotNilOrEmpty } from 'ramda-adjunct';

import Env from '../../env/env';
import { api, RTKTags } from '../api/Api';
import {
  LOGIN_ACTIVATION_ALREADY_MADE,
  LOGIN_DUPLICATED_EMAIL,
  LOGIN_DUPLICATED_NICKNAME,
  LOGIN_INTERNAL_ERROR,
  LOGIN_UNDELIVERABLE_EMAIL,
  SMS_EXPIRATED_CODE,
  SMS_INVALID_CODE,
  SMS_SERVER_ERROR,
} from './Users.constants';
import {
  ActivateAccountRequest,
  ChangePasswordRequest,
  checkSMSRequest,
  CheckSMSResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  GetIsCouponAvailableRequest,
  GetIsCouponAvailableResponse,
  GetPreferencesResponse,
  GetPreferencesTeamsResponse,
  GetRankResponse,
  GetSMSTokenResponse,
  GetUserPreferenceRequest,
  GetUserPreferenceResponse,
  GetUserRankResponse,
  SaveSelfieRequest,
  SaveSelfieResponse,
  SendSMSRequest,
  SendSMSResponse,
  SendStickersRequest,
  UpdateAvatarRequest,
  UpdateAvatarResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  User,
} from './Users.types';

export interface SimplePostResponse {
  status: string;
}

export interface SaveLeaguesRequest {
  leagues: string[];
}

export interface SaveTeamsRequest {
  teams: string[];
}

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    activateAccount: builder.mutation<User, ActivateAccountRequest>({
      query: (payload) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/activate/${payload.activationId}`,
        method: 'POST',
        body: { ...payload },
      }),
    }),
    createAccount: builder.mutation<
      CreateAccountResponse,
      CreateAccountRequest
    >({
      query: (user) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users`,
        method: 'POST',
        body: { ...user },
      }),
    }),
    sendStickers: builder.mutation<void, SendStickersRequest>({
      query: (body) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/send-stickers`,
        body: { ...body },
        method: 'POST',
      }),
    }),
    sendSMSCode: builder.mutation<SendSMSResponse, SendSMSRequest>({
      query: (body) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/sendSmsCode`,
        method: 'POST',
        body: { ...body },
      }),
    }),
    getSMSToken: builder.query<GetSMSTokenResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/getSmsToken`,
        method: 'GET',
      }),
    }),
    checkSMSCode: builder.mutation<CheckSMSResponse, checkSMSRequest>({
      query: (body) => ({
        url: `${Env.REACT_APP_BASE_API_URL_AUTH}auth/checkSmsCode`,
        method: 'POST',
        body: { ...body },
      }),
    }),
    updateAvatar: builder.mutation<UpdateAvatarResponse, UpdateAvatarRequest>({
      query: (image) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/avatar`,
        method: 'POST',
        body: { ...image },
      }),
    }),
    saveSelfie: builder.mutation<SaveSelfieResponse, SaveSelfieRequest>({
      query: (body) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/selfie`,
        method: 'POST',
        body: { ...body },
      }),
    }),
    updateUser: builder.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: (payload) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/${payload.user_id}`,
        method: 'PATCH',
        body: { ...payload.data },
      }),
      invalidatesTags: [RTKTags.Preferences],
    }),
    completeOnboarding: builder.mutation<SimplePostResponse, unknown>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/onboarding/seen`,
        method: 'POST',
        body: {},
      }),
    }),
    getRanks: builder.query<GetRankResponse, void>({
      query: () => `${Env.REACT_APP_BASE_API_URL_USERS}users/ranking`,
      keepUnusedDataFor: 0,
    }),
    getUserRanks: builder.query<GetUserRankResponse, void>({
      query: () => `${Env.REACT_APP_BASE_API_URL_USERS}users/user-ranking`,
      keepUnusedDataFor: 0,
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: (password) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/password`,
        method: 'PATCH',
        body: { ...password },
      }),
    }),
    getUserPreference: builder.query<
      GetUserPreferenceResponse,
      GetUserPreferenceRequest
    >({
      query: (payload) => {
        return `${Env.REACT_APP_BASE_API_URL_USERS}users/pref/${payload.user_id}`;
      },
      providesTags: [RTKTags.Preferences],
    }),
    // Get user preferences
    getPreferences: builder.query<GetPreferencesResponse, void>({
      query: () => {
        return `${Env.REACT_APP_BASE_API_URL_USERS}users/pref`;
      },
      providesTags: ['Preferences'],
    }),
    getPreferencesTeams: builder.query<GetPreferencesTeamsResponse[], void>({
      query: () => {
        return `${Env.REACT_APP_BASE_API_URL_PREFERENCES}preferences/teams`;
      },
      providesTags: ['Preferences'],
    }),
    saveLeaguesPref: builder.mutation<SimplePostResponse, SaveLeaguesRequest>({
      query: (leagues) => ({
        url: `${Env.REACT_APP_BASE_API_URL_USERS}users/pref/leagues`,
        method: 'POST',
        body: { ...leagues },
      }),
      invalidatesTags: ['Preferences'],
    }),
    saveTeamsPref: builder.mutation<SimplePostResponse, SaveTeamsRequest>({
      query: (teams) => ({
        url: `${Env.REACT_APP_BASE_API_URL_PREFERENCES}preferences/teams`,
        method: 'POST',
        body: { ...teams },
      }),
      invalidatesTags: [RTKTags.Preferences],
    }),
    getIsCouponAvailableForUser: builder.query<
      GetIsCouponAvailableResponse,
      GetIsCouponAvailableRequest
    >({
      query: ({ couponId, productId }) => ({
        url: `users/coupons/${couponId}/products/${
          productId ? `${productId}` : "''"
        }`,
        method: 'GET',
      }),
      providesTags: [RTKTags.Coupons],
    }),
  }),
  overrideExisting: true,
});

export const usersSignupError = [
  LOGIN_DUPLICATED_EMAIL,
  LOGIN_UNDELIVERABLE_EMAIL,
  LOGIN_DUPLICATED_NICKNAME,
  LOGIN_ACTIVATION_ALREADY_MADE,
  LOGIN_INTERNAL_ERROR,
];
export const usersApiExtractErrors = (
  error: unknown,
  messageKey: string,
  options: usersApiExtractErrorsOptions = {
    parentKey: undefined,
    separator: '_',
    defaultKey: '',
  },
) => {
  const err = error as { data: { code: string } };
  const parent = `${
    isNotNilOrEmpty(options.parentKey) ? `${options.parentKey}.` : ''
  }`;
  return usersSignupError.find((errorCode) => errorCode === err?.data?.code)
    ? `${parent}${messageKey}${options.separator}${err.data.code}`
    : `${parent}${options?.defaultKey || messageKey}`;
};

export interface usersApiExtractErrorsOptions {
  parentKey?: string;
  separator?: string;
  defaultKey?: string;
}

export const usersSMSError = [SMS_INVALID_CODE, SMS_EXPIRATED_CODE];
export const usersApiExtractSMSErrors = (error: unknown) => {
  const err = error as { data: { code: string } };
  return usersSMSError.find((errorCode) => errorCode === err?.data?.code)
    ? err.data.code
    : SMS_SERVER_ERROR;
};

export const {
  useCreateAccountMutation,
  useGetRanksQuery,
  useGetUserRanksQuery,
  useActivateAccountMutation,
  useUpdateAvatarMutation,
  useUpdateUserMutation,
  useSaveSelfieMutation,
  useSendSMSCodeMutation,
  useCheckSMSCodeMutation,
  useCompleteOnboardingMutation,
  useSaveLeaguesPrefMutation,
  useSaveTeamsPrefMutation,
  useChangePasswordMutation,
  useGetUserPreferenceQuery,
  useGetPreferencesQuery,
  useSendStickersMutation,
  useLazyGetSMSTokenQuery,
  useGetIsCouponAvailableForUserQuery,
  useGetPreferencesTeamsQuery,
} = usersApi;
