import { createSelector } from '@reduxjs/toolkit';

import { GetSummaryResponse } from './Referrals.types';

export const selectReferral = (summary: GetSummaryResponse | undefined) =>
  summary;

export const selectEarnedPoints = createSelector(
  selectReferral,
  (summary?: GetSummaryResponse) => summary?.balance.earned_points,
);
