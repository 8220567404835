import { SxProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAG_TYPE, TAG_VALUE } from '../../../utils/tagger';
import { Link } from '../Link/Link';

const TyCLink = ({
  copy = 'footer.legal.tyc',
  sx,
}: {
  copy?: string;
  sx?: SxProps;
}) => {
  const { t } = useTranslation();

  return (
    <Typography sx={{ opacity: 0.4, ...sx }} variant="body2">
      <Link
        tagId={TAG_VALUE.FOOTER.TYC}
        tagSectionId={TAG_TYPE.MENU_FOOTER}
        to={t('footer.legal.tycLink')}
        external
      >
        {t(copy)}
      </Link>
    </Typography>
  );
};

export default TyCLink;
