import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { CountDownItemProps } from '../Countdown/Countdown.types';

const CountDownItem = ({
  value,
  label,
  isSmall = false,
}: CountDownItemProps) => {
  const isMobile = useMobileMediaQuery();

  const stylesFontSizeTime = {
    fontSize: {
      xs: isSmall ? 'body1' : typography.cH5.fontSize,
      sm: isSmall ? 'body1' : typography.cH4.fontSize,
      md: isSmall ? 'body1' : typography.cH3.fontSize,
    },
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={isMobile ? SpaceSizes.xs : SpaceSizes.sm}
    >
      <Typography
        fontWeight={isSmall ? 'normal' : 'bold'}
        letterSpacing={0.8}
        sx={stylesFontSizeTime}
        textAlign="center"
      >
        {value.toString().padStart(2, '0')}
      </Typography>
      <Typography
        fontWeight={isSmall ? 'normal' : 'bold'}
        sx={stylesFontSizeTime}
        textAlign="center"
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default CountDownItem;
