import { useMemo } from 'react';

import {
  CommonClub,
  Player,
  ProductEvent,
} from '../../../features/products/Product.types';

export const useResultTime = (
  homeGoal: boolean,
  awayGoal: boolean,
  productEvent: ProductEvent,
  homeClub?: CommonClub,
  awayClub?: CommonClub,
) => {
  return useMemo(() => {
    return {
      homeClubTime: homeClub,
      awayClubTime: awayClub,
      productEventTime: productEvent,
      homeGoalTime: homeGoal,
      awayGoalTime: awayGoal,
    };
  }, [homeClub, awayClub, productEvent, homeGoal, awayGoal]);
};

export const usePlayerTime = (productEvent: ProductEvent, player?: Player) =>
  useMemo(
    () => `${productEvent?.event_minute_in_match}' ${player?.name}`,
    [productEvent, player],
  );
