import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import { CopyAction } from '../CopyAction/CopyAction';
import { ShareAction } from '../ShareAction/ShareAction';

const ReferralLink = ({ link }: { link: string }) => {
  const { t } = useTranslationByNamespaces('pages.referrals');
  const isSmallestMobile = useSmallMobileMediaQuery();

  return (
    <Stack
      alignItems="center"
      flexDirection={{ xs: 'column', sm: 'row' }}
      gap={SpaceSizes.md}
      justifyContent="space-between"
      width={{ xs: '100%', sm: '85%' }}
    >
      <Typography
        data-testid="referral-link"
        overflow="hidden"
        textAlign="start"
        textOverflow="ellipsis"
        variant={isSmallestMobile ? 'cBody2' : 'body2'}
        whiteSpace="nowrap"
        width={{ xs: '50%', md: '100%' }}
      >
        {link}
      </Typography>
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={SpaceSizes.md}
        justifyContent="start"
        textAlign="center"
      >
        <CopyAction sharedLink={link} isRefer />
        <ShareAction link={link} text={t('referFriend.shareAction')} />
      </Stack>
    </Stack>
  );
};

export default ReferralLink;
