import { Divider, Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';

export const TournamentMatchesSelectorSkeleton = () => {
  const isMobile = useMobileMediaQuery();

  return (
    <Stack gap={SpaceSizes.md} width="100%">
      {Array.from({ length: 5 }, (_, index) => (
        <Stack key={index} gap={SpaceSizes.lg}>
          <Stack flexDirection="row">
            <Stack
              flexDirection="row"
              gap={isMobile ? SpaceSizes.sm : SpaceSizes.lg}
              justifyContent="flex-start"
              width="90%"
            >
              <Skeleton
                height={25}
                sx={{
                  width: { xs: '30%', md: '20%' },
                }}
                variant="rectangular"
              />
              <Skeleton
                height={25}
                sx={{
                  width: { xs: '60%', md: '50%' },
                }}
                variant="rectangular"
              />
            </Stack>
            <Stack width="10%">
              <Skeleton
                height={25}
                sx={{ marginLeft: SpaceSizes.sm }}
                variant="rectangular"
                width="90%"
              />
            </Stack>
          </Stack>
          <Divider sx={{ width: '100%' }} />
        </Stack>
      ))}
    </Stack>
  );
};
