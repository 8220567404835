import { Button, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import BorderRadius from '../../../../theme/foundations/borders/BorderRadius';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { boxShadowMixin } from '../../../../utils/mixins';
import Panel from '../../../components/Panel/Panel';
import useDesktopMediaQuery from '../../../hooks/useDesktopMediaQuery';
import { TRIVIA_QUESTION } from './TriviaQuestion.constants';
import { TriviaQuestionProps } from './TriviaQuestion.types';

const TriviaQuestion = ({
  handleChoice,
  question,
  choices,
  disabled,
  rightChoice,
  choiceIndex,
}: TriviaQuestionProps) => {
  const theme = useTheme();

  const isDesktop = useDesktopMediaQuery();

  const handleChoiceClick = useCallback(
    (value: number) => {
      if (rightChoice || disabled) return;
      handleChoice(Number(value));
    },
    [disabled, rightChoice, handleChoice],
  );

  const [optionColors, setOptionColors] = useState<
    ('default' | 'success.main' | 'error')[]
  >(new Array(choices.length).fill('default'));

  useEffect(() => {
    const newOptionColors = new Array(choices.length).fill('default');

    // Check if rightChoice and choiceIndex are defined
    if (rightChoice !== undefined) {
      // Case: Timeout without choosing an option - highlight the right choice in green
      if (choiceIndex === null) {
        newOptionColors[rightChoice] = 'success.main';
      }
      // Case: User chose the right answer - highlight the chosen option in green
      else if (choiceIndex === rightChoice) {
        newOptionColors[choiceIndex] = 'success.main';
      }
      // Case: User chose the wrong answer - highlight the chosen option in red
      else {
        if (choiceIndex !== undefined) {
          newOptionColors[choiceIndex] = 'error';
        }
        // And if the right choice is provided, highlight it in green
        if (rightChoice !== undefined) {
          newOptionColors[rightChoice] = 'success.main';
        }
      }
    }

    setOptionColors(newOptionColors);
  }, [choiceIndex, rightChoice, choices.length]);

  if (!choices || !question) return null;

  return (
    <Stack width="100%">
      <Stack sx={{ gap: SpaceSizes.lg }}>
        <Panel
          sx={{
            boxShadow: boxShadowMixin(theme.palette.secondary.light),
            borderRadius: BorderRadius.sm,
          }}
        >
          <Stack
            alignItems="center"
            flexDirection="row"
            sx={{ gap: SpaceSizes.md }}
          >
            <img
              alt="Trivia question"
              height={isDesktop ? SpaceSizes.xl : SpaceSizes.lgPlus}
              src={TRIVIA_QUESTION}
            />
            <Typography
              color="text.primary"
              fontSize={isDesktop ? 18 : 16}
              sx={{ userSelect: 'none' }}
            >
              {question}
            </Typography>
          </Stack>
        </Panel>
        <Stack
          aria-labelledby="trivia-question-label"
          sx={{ gap: SpaceSizes.md }}
        >
          {choices?.map((option: string, index: number) => {
            return (
              <Button
                key={option}
                disabled={rightChoice !== undefined || disabled}
                sx={{
                  boxShadow: boxShadowMixin(theme.palette.secondary.light),
                  borderRadius: BorderRadius.md,
                  paddingY: SpaceSizes.sm,
                }}
                onClick={() => {
                  handleChoiceClick(index);
                }}
              >
                <Typography
                  color={optionColors[index]}
                  sx={{
                    userSelect: 'none',
                  }}
                >
                  {option}
                </Typography>
              </Button>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TriviaQuestion;
