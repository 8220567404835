import { TAG_VALUE } from '../../../utils/tagger';

const LINKS = [
  {
    I18N: 'marketplace',
    items: [
      {
        I18N: 'marketplace',
        url: '/marketplace',
        external: false,
        tag: TAG_VALUE.FOOTER.MARKETPLACE,
      },
    ],
  },
  {
    I18N: 'about',
    items: [
      // {
      //   I18N: 'golball',
      //   url: '/pages/corporative',
      //   external: false,
      //   tag: TAG_VALUE.FOOTER.CORPORATIVE,
      // },
      {
        I18N: 'us',
        url: '/pages/lineup',
        external: false,
        tag: TAG_VALUE.FOOTER.US,
      },
    ],
  },
  {
    I18N: 'support',
    items: [
      {
        I18N: 'contact',
        url: '/contact',
        external: true,
        tag: TAG_VALUE.FOOTER.CONTACT,
      },
    ],
  },
];

export { LINKS };
