import { Button, Stack } from '@mui/material';
import React from 'react';

import {
  Filter,
  FilterValue,
} from '../../../../features/searchfilter/responses/SearchResponse';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { AccordionSection } from '../../AccordionSection/AccordionSection';

interface AccordionFilterProps {
  filter: Filter;
  handleFilter: (filter: Filter, value: FilterValue) => void;
  selectedAccordion: string | false;
  accordionKey: string;
  handleFilterTypeChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const AccordionFilter = ({
  filter,
  handleFilter,
  selectedAccordion,
  accordionKey,
  handleFilterTypeChange,
}: AccordionFilterProps) => {
  return (
    <AccordionSection
      accordionKey={accordionKey}
      expanded={selectedAccordion === accordionKey}
      titleKey={`pages.marketplace.filters.filterType.${accordionKey}`}
      onChange={handleFilterTypeChange(accordionKey)}
    >
      {filter.values.map((value) => (
        <Stack
          key={value.id}
          spacing={SpaceSizes.sm}
          sx={{ borderTop: 2, borderColor: 'background.paper' }}
        >
          <Button
            data-testid={`filter${accordionKey}Button-${value.id}`}
            onClick={() => handleFilter(filter, value)}
          >
            {value.label}
          </Button>
        </Stack>
      ))}
    </AccordionSection>
  );
};

export default AccordionFilter;
