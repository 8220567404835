import 'react-image-crop/dist/ReactCrop.css';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop } from 'react-image-crop';

import { setGlobalAlert } from '../../../../features/alert/Alert.slice';
import { updateAvatar } from '../../../../features/session/Session.slice';
import { useUpdateAvatarMutation } from '../../../../features/users/Users.api';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { useAppDispatch } from '../../../hooks';
import { AlertCard } from '../../AlertCard/AlertCard';
import { AlertSeverity } from '../../AlertCard/AlertSeverity';
import styles from './AvatarCrop.module.css';
import { IAvatarCropProps } from './AvatarCrop.types';

const StyledImg = styled('img')`
  width: 300px;
  height: 300px;
  object-fit: contain;
`;

const Container = styled(Stack)`
  width: 300px;
  height: 300px;
`;

async function getCroppedImg(image: HTMLImageElement, crop: Crop) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  // Get final image
  if (ctx) {
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }
  // As base64 image
  return canvas.toDataURL();
}

export const AvatarCrop = ({
  sourceImg,
  onFinishUpload,
  onClose,
}: IAvatarCropProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [postUpdateAvatar, { data, isError, isSuccess, isLoading }] =
    useUpdateAvatarMutation();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  });

  // Get cropped image and send it to server
  const uploadImage = async () => {
    if (imgRef.current) {
      const blobImg = await getCroppedImg(imgRef.current, crop);
      await postUpdateAvatar({ image: blobImg });
    }
  };

  // Custom set crop to set rounded crop
  // And fix for height resize overflow
  const setRoundedCrop = (c: Crop) => {
    const imageHeight = imgRef.current?.height ?? 0;
    if (c.width + c.y < imageHeight) {
      setCrop({ ...c, width: c.width, height: c.width });
    }
  };

  useEffect(() => {
    // Update store if success
    if (isSuccess && data) {
      dispatch(updateAvatar(data.avatar_url));
      onFinishUpload();
    }
    // Show alert if error
    if (isError) {
      dispatch(
        setGlobalAlert({
          type: 'error',
          message: t('alert.updateAvatarError'),
        }),
      );
    }
  }, [isError, isSuccess, dispatch, t, data, onFinishUpload]);

  return (
    <Grid
      alignItems="center"
      direction="column"
      gap={SpaceSizes.sm}
      justifyContent="center"
      sx={{
        backgroundColor: 'background.paper',
        padding: SpaceSizes.md,
        border: 1,
      }}
      container
    >
      <Box sx={{ placeSelf: 'flex-end' }}>
        <CloseIcon
          fontSize="small"
          style={{ fill: 'primary' }}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={onClose}
        />
      </Box>
      <Container>
        <ReactCrop
          className={styles.crop}
          crop={crop}
          maxHeight={610}
          maxWidth={610}
          minHeight={80}
          minWidth={80}
          circularCrop
          keepSelection
          onChange={(c) => setRoundedCrop(c)}
        >
          <StyledImg ref={imgRef} alt="avatar_edit" src={sourceImg} />
        </ReactCrop>
      </Container>
      <AlertCard
        content={t('pages.profile.avatarDisclaimer')}
        id="avatarCropWarning"
        severity={AlertSeverity.WARNING}
        show
      />
      <LoadingButton
        loading={isLoading && !isSuccess}
        size="large"
        variant="contained"
        onClick={uploadImage}
      >
        {t('pages.profile.saveAvatar')}
      </LoadingButton>
    </Grid>
  );
};
