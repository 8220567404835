/* eslint-disable no-restricted-imports */
import { golballPalette } from '../utils/brandPalettes/golballPalette';
/* eslint-enable no-restricted-imports */

export interface ButtonTheme {
  background: string;
  text: string;
  disableBackground: string;
  disableText: string;
  border: string;
  disableBorder: string;
}
export interface CustomPalette {
  button: {
    primary: ButtonTheme;
    secondary: ButtonTheme;
    text: ButtonTheme;
  };
  golden: {
    main: string;
    gradient: string;
  };
}

export default golballPalette.theme.palette;
