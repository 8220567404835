import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { Divider, Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLoginMutation } from '../../../features/session/Session.api';
import {
  selectIsSessionLoading,
  selectIsUserLoggedIn,
  selectLocationCountry,
} from '../../../features/session/Session.selector';
import { setSession } from '../../../features/session/Session.slice';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_TYPE, TAG_VALUE } from '../../../utils/tagger';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import AppleSignInButton from '../../components/AppleSignIn/AppleSignInButton';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import { CopyrightAndTyc } from '../../components/Footer/CopyrightAndTyc';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input/Input';
import { InputPassword } from '../../components/form/InputPassword/InputPassword';
import { GolButton } from '../../components/GolButton/GolButton.component';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLoginSignupRedirect from '../../hooks/useLoginSignupRedirect';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useRedirectLoginSignup from '../../hooks/useRedirectLoginSignup';
import i18n from '../../i18n';
import GoogleSignInButton from '../google-signin-button/GoogleSignInButton';
import { Inputs } from './Login.types';

const Login = () => {
  useLoginSignupRedirect();

  const { t } = useTranslation();
  const [errorCode, setErrorCode] = useState('');
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ipLocation = useAppSelector(selectLocationCountry);
  const redirect = params.get('redirect') || undefined;
  const sendDataToGTM = useGTMDispatch();

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isSessionLoading = useAppSelector(selectIsSessionLoading);

  // when session is ready, do redirect
  useEffect(() => {
    if (isUserLoggedIn && !isSessionLoading) {
      navigate(redirect ?? '/marketplace');
    }
  }, [isUserLoggedIn, isSessionLoading, navigate, redirect]);

  // Hook to call API for login
  const [login, { isLoading, isSuccess }] = useLoginMutation();

  const methods = useForm<Inputs>();
  const growthbook = useGrowthBook();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const session = await login({
        login: data.loginId,
        password: data.password,
      }).unwrap();

      sendDataToGTM({
        event: TAG_EVENT.ONBOARDING.LOGIN,
        user_id: session.user_id,
        method: 'Email',
      });
      // Load session into the store
      dispatch(setSession(session));
      i18n.changeLanguage(session?.user?.lang_id || 'es');
    } catch (error) {
      setErrorCode('credentialsError');
    }
  };

  const handleForgotPasswordClick = useCallback(() => {
    sendDataToGTM({
      event: TAG_EVENT.SELECT_CONTENT,
      content_type: TAG_TYPE.CTA_FORGOT_PASSWORD,
      item_id: TAG_VALUE.CTA_FORGOT_PASSWORD,
    });
    navigate('/account-recovery');
  }, [sendDataToGTM, navigate]);

  // add redirect param to signup if it exists
  const { signupRoute } = useRedirectLoginSignup();

  const handleCreateAccountTrack = useCallback(() => {
    navigate(signupRoute);
  }, [navigate, signupRoute]);

  const isMobile = useMobileMediaQuery();

  return (
    <PageContainer id="loginPage" maxWidth="xs">
      {!ipLocation ? (
        <Spinner />
      ) : (
        <Form methods={methods} style={{ width: '100%' }} onSubmit={onSubmit}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={SpaceSizes.md}
          >
            <Stack alignSelf="start">
              <Typography fontWeight="bold" variant="h5">
                {t(`pages.login.title`)}
              </Typography>
            </Stack>
            {isNotNilOrEmpty(errorCode) && (
              <AlertCard
                content={t(`pages.login.errors.${errorCode}`)}
                id="loginError"
                show={isNotNilOrEmpty(errorCode)}
              />
            )}
            <Input
              errorMessage={t('pages.login.errors.loginIdError')}
              id="loginId"
              label={t('pages.login.loginId')}
            />
            <Stack alignItems="start" gap={SpaceSizes.xs} width="100%">
              <InputPassword
                id="password"
                label={t('pages.login.password')}
                placeholder={t('pages.login.passwordPlaceholder')}
                validate={false}
              />
              <GolButton
                size="small"
                testId="accountRecoveryButton"
                value={t('pages.login.forgotPassword')}
                variant="text"
                onClick={handleForgotPasswordClick}
              />
            </Stack>

            <Stack spacing={SpaceSizes.md} width={isMobile ? '280px' : '343px'}>
              <GolButton
                disabled={isNotNilOrEmpty(methods.formState.errors)}
                loading={isLoading && !isSuccess}
                testId="loginButton"
                type="submit"
                value={t('pages.login.actionButton')}
              />
            </Stack>

            <Stack
              alignItems="center"
              direction="row"
              gap={SpaceSizes.sm}
              justifyContent="center"
              width="100%"
            >
              <Divider sx={{ width: '40%' }} />
              <Typography>{t('pages.login.signInOr')}</Typography>
              <Divider sx={{ width: '40%' }} />
            </Stack>

            <GoogleSignInButton />
            {growthbook && growthbook.isOn('web_apple_login') && (
              <AppleSignInButton />
            )}
            <Stack alignItems="center" direction="row" gap={SpaceSizes.none}>
              <Typography variant="body2">
                {t('pages.login.createAccountPrevText')}
              </Typography>
              <GolButton
                size="small"
                testId="signupButton"
                value={t('pages.login.createAccount')}
                variant="text"
                onClick={handleCreateAccountTrack}
              />
            </Stack>
            <Divider sx={{ width: '100%' }} />

            <CopyrightAndTyc renderCopy={false} />
          </Stack>
        </Form>
      )}
    </PageContainer>
  );
};

export default Login;
