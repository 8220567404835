import { Typography } from '@mui/material';
import React from 'react';

export const ProductTitleSection = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Typography
      align="center"
      fontWeight="bold"
      textTransform="uppercase"
      variant="cH4"
    >
      {children}
    </Typography>
  );
};
