import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { storyBubble } from './constants.json';
import { borderRotate } from './motion.json';
import { StoryBubbleProps } from './Story.interfaces';

const AnimatedStack = motion(Stack);

export const StoryBubble: FC<StoryBubbleProps> = ({
  preview,
  title,
  isLoading = false,
}) => {
  const animation = isLoading ? 'animate' : 'stopped';

  return (
    <Stack
      alignItems="center"
      direction="row"
      height={storyBubble.container.height}
      justifyContent="space-between"
      position="relative"
      width="100%"
      zIndex={4}
    >
      <Stack
        alignItems="center"
        direction="column"
        gap={{ xs: SpaceSizes.xs, md: SpaceSizes.smPlus }}
      >
        <Stack
          alignItems="center"
          borderRadius={storyBubble.whiteBorder.borderRadius}
          height={storyBubble.container.height}
          justifyContent="center"
          overflow="hidden"
          position="relative"
          width={storyBubble.container.width}
        >
          <AnimatedStack
            alignItems="center"
            animate={animation}
            border={storyBubble.whiteBorder.border}
            borderRadius={storyBubble.whiteBorder.borderRadius}
            height="100%"
            justifyContent="center"
            padding={SpaceSizes.xs}
            position="absolute"
            style={{ boxSizing: 'border-box' }}
            variants={borderRotate}
            width="100%"
            zIndex={100}
          />
          <ImageFallback
            fallback={preview}
            height="90%"
            src={preview}
            style={{ position: 'absolute', bottom: 0 }}
            width="90%"
          />
        </Stack>
        <Typography
          color="text.primary"
          fontSize={{ xs: 10, sm: 12, md: 14 }}
          textAlign="center"
        >
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};
