import { add } from 'date-fns';
import { isNilOrEmpty } from 'ramda-adjunct';
import Cookies from 'universal-cookie';

const REF_CODE_REGEX = /^[0-9A-Za-z_-]{7,36}$/;

const isValidRefCode = (value: string) => REF_CODE_REGEX.test(value);

export const setRegisteredCookie = () => {
  const cookies = new Cookies();

  if (isNilOrEmpty(cookies.get('is_registered'))) {
    const now = new Date();
    const expirationDate = add(now, { years: 1 }); // 1 year max

    cookies.set('is_registered', true, {
      expires: expirationDate,
    });
  }
};

export const getRegisteredCookie = () => {
  const cookies = new Cookies();
  return cookies.get('is_registered');
};

export const getReferralCookie = () => {
  const cookies = new Cookies();
  const currentRefCookie = cookies.get('referralCode');

  if (!isValidRefCode(currentRefCookie)) {
    return undefined;
  }

  return cookies.get('referralCode');
};

export const setReferralCookie = (referralCode: string) => {
  const cookies = new Cookies();
  const currentRefCookie = cookies.get('referralCode');

  const shouldOverrideCurrentCookie =
    (isNilOrEmpty(currentRefCookie) || !isValidRefCode(currentRefCookie)) &&
    !isNilOrEmpty(referralCode) &&
    isValidRefCode(referralCode);

  if (shouldOverrideCurrentCookie) {
    const now = new Date();
    const expirationDate = add(now, { days: 2 }); // 2 days

    cookies.set('referralCode', referralCode, {
      expires: expirationDate,
    });
  }
};
