import { Box, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import { formatUTCtoLocalTime } from '../../../utils/date';
import { useGetSizeToCard } from '../../../utils/useGetSizeToCard';
import DefaultLogoClub from '../../assets/img/default_logo_club.png';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import useBigDesktopMediaQuery from '../../hooks/useBigDesktopMediaQuery';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { geti18nResolvedLanguage } from '../../i18n';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { LiveIndicator } from '../LiveIndicator/LiveIndicator';
import { useMatchCardSize } from './MatchCard.constants';
import { IMatchCard } from './MatchCard.types';

export const MatchCard = ({
  match,
  matchResult,
  isLarge,
  isMatches,
  isRelatedProduct,
}: IMatchCard) => {
  const { date, time, id, teams, is_live: isLiveMatch, status } = match || {};
  const { matchId } = useParams();
  const navigate = useNavigate();
  const isDesktop = useBigDesktopMediaQuery();
  const isMobile = useMobileMediaQuery();
  const { t } = useTranslationByNamespaces('pages.matches');

  const { home, away } = teams || {
    home: matchResult?.home,
    away: matchResult?.away,
  };

  const matchDate = date || matchResult?.date;
  const matchTime = time || matchResult?.time;
  const isLive = isLiveMatch || matchResult?.is_live;
  const matchStatus = status || matchResult?.status;

  const navigateToMatch = useCallback(() => {
    if (!home || !away) return;
    navigate(`/matches/${matchId || id}`);
  }, [navigate, matchId, id, home, away]);

  const { heightDevice, widthDevice } = useGetSizeToCard(true);

  const dateVariant = isDesktop ? 'cH6' : 'cBody1';

  const { widthCurrent, heightCurrent } = useMatchCardSize(
    heightDevice,
    widthDevice,
    isMobile,
    isLarge,
    isMatches,
    isRelatedProduct,
  );

  return (
    <Stack width="100%">
      <Stack
        alignItems="center"
        borderRadius={SpaceSizes.xs}
        gap={SpaceSizes.md}
        justifyContent="center"
        sx={{
          height: heightCurrent,
          width: widthCurrent,
          cursor: match && home && away ? 'pointer' : 'inherit',
          backgroundColor: 'background.paper',
        }}
        onClick={navigateToMatch}
      >
        {matchDate && isMatches && (
          <>
            <Typography
              color="text.primary"
              component="p"
              paddingX={SpaceSizes.md}
              textAlign="center"
              variant={dateVariant}
            >
              {formatUTCtoLocalTime(
                geti18nResolvedLanguage(),
                matchDate,
                matchTime,
                undefined,
                undefined,
              )}
            </Typography>
            <Divider style={{ width: '90%' }} />
          </>
        )}

        <Stack
          alignItems="center"
          direction="column"
          gap={SpaceSizes.sm}
          justifyContent="start"
          width="100%"
        >
          <Stack
            alignItems="center"
            direction="row"
            gap={isLarge ? SpaceSizes.lg : SpaceSizes.sm}
            justifyContent="center"
            width="100%"
          >
            <Stack
              alignItems="center"
              gap={SpaceSizes.sm}
              justifyContent="center"
              width="40%"
            >
              <Box
                height={{
                  xs: SpaceSizes.lgPlus,
                  sm: isLarge ? SpaceSizes.lgPlus : SpaceSizes.mdPlus,
                  lg: SpaceSizes.lgPlus,
                }}
                width={{
                  xs: SpaceSizes.lgPlus,
                  sm: isLarge ? SpaceSizes.lgPlus : SpaceSizes.mdPlus,
                  lg: SpaceSizes.lgPlus,
                }}
              >
                <ImageFallback
                  fallback={home?.logo || DefaultLogoClub}
                  height="100%"
                  src={home?.logo || DefaultLogoClub}
                  width="100%"
                />
              </Box>
              {isMobile ? (
                <Typography
                  color="text.primary"
                  component="h4"
                  textAlign="center"
                  variant="cBody1"
                >
                  {isRelatedProduct ? home?.code : home?.name}
                </Typography>
              ) : (
                <Tooltip title={home?.name}>
                  <Typography
                    color="text.primary"
                    component="h4"
                    textAlign="center"
                    variant="cBody1"
                  >
                    {home && isLarge ? home?.name : home?.code}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
            {home && typeof home.score === 'number' && (
              <Typography color="text.primary" component="h4" variant="cH5">
                {home.score}
              </Typography>
            )}
            <Typography
              color="text.primary"
              component="h4"
              textAlign="center"
              variant="cH5"
              width="10%"
            >
              vs
            </Typography>
            {away && typeof away.score === 'number' && (
              <Typography color="text.primary" component="h4" variant="cH5">
                {away.score}
              </Typography>
            )}
            <Stack
              alignItems="center"
              gap={SpaceSizes.sm}
              justifyContent="center"
              width="40%"
            >
              <Box
                height={{
                  xs: SpaceSizes.lgPlus,
                  sm: isLarge ? SpaceSizes.lgPlus : SpaceSizes.mdPlus,
                  lg: SpaceSizes.lgPlus,
                }}
                width={{
                  xs: SpaceSizes.lgPlus,
                  sm: isLarge ? SpaceSizes.lgPlus : SpaceSizes.mdPlus,
                  lg: SpaceSizes.lgPlus,
                }}
              >
                <ImageFallback
                  fallback={away?.logo || DefaultLogoClub}
                  height="100%"
                  src={away?.logo || DefaultLogoClub}
                  width="100%"
                />
              </Box>
              {isMobile ? (
                <Typography
                  color="text.primary"
                  component="h4"
                  textAlign="center"
                  variant="cBody1"
                >
                  {isRelatedProduct ? away?.code : away?.name}
                </Typography>
              ) : (
                <Tooltip title={away?.name}>
                  <Typography
                    color="text.primary"
                    component="h4"
                    textAlign="center"
                    variant="cBody1"
                  >
                    {away && isLarge ? away?.name : away?.code}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>

        {isMatches && (
          <>
            <Divider style={{ width: '90%' }} />
            <Chip
              label={
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  gap={SpaceSizes.sm}
                  justifyContent="center"
                >
                  {isLive && <LiveIndicator isLive={!!isLive} />}
                  <Typography
                    fontSize={
                      isDesktop
                        ? typography.cBody2.fontSize
                        : typography.cCaption.fontSize
                    }
                  >
                    {t(`matchStatus.${matchStatus}`)}
                  </Typography>
                </Stack>
              }
              sx={{
                backgroundColor: isLive ? 'success.info' : '',
                height: SpaceSizes.mdPlus,
                textTransform: 'uppercase',
                width: '90%',
              }}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
