import { all } from 'ramda';

import { IVenlyOutage } from './VenlyOutageModal.types';

export const isVenlyOutageDefined = (
  outage: Partial<IVenlyOutage>,
): boolean => {
  const requiredKeys: Array<keyof IVenlyOutage> = [
    'outage_start_date',
    'outage_start_time',
    'outage_end_date',
    'outage_end_time',
  ];

  return all((key) => outage[key] !== undefined, requiredKeys);
};
