import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSliceState } from '../commons/types';

export interface AlertState extends BaseSliceState {
  type: string | null;
  message: string | null;
}

const initialState = {
  type: null,
  message: null,
} as AlertState;

export interface AlertPayload {
  type: string;
  message: string;
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setGlobalAlert(state, action: PayloadAction<AlertPayload>) {
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    resetGlobalAlert(state) {
      state.type = null;
      state.message = null;
    },
  },
});
export const { setGlobalAlert, resetGlobalAlert } = alertSlice.actions;
export default alertSlice.reducer;
