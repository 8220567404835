import { createTheme } from '@mui/material/styles';

import components from './components';
import palette, { ButtonTheme } from './palette';
import typography from './typography';

declare module '@mui/material/styles' {
  interface Palette {
    button: {
      primary: ButtonTheme;
      secondary: ButtonTheme;
      text: ButtonTheme;
    };
    golden: {
      main: string;
      gradient: string;
    };
  }
  interface PaletteOptions {
    button: {
      primary: ButtonTheme;
      secondary: ButtonTheme;
      text: ButtonTheme;
    };
    golden: {
      main: string;
      gradient: string;
    };
  }
}

// A custom theme for this app
const theme = createTheme({
  palette,
  typography,
  spacing: 1,
  components,
});

export default theme;
