import TimerIcon from '@mui/icons-material/TimerSharp';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { formatTime } from '../../../../utils/date';
import { TriviaCountdownProps } from './TriviaCountdown.types';

const TriviaCountdown = ({ countdownInSeconds }: TriviaCountdownProps) => {
  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={SpaceSizes.sm}
      justifyItems="center"
    >
      <TimerIcon />
      <Typography
        component="time"
        sx={{ letterSpacing: SpaceSizes.xxs }}
        variant="h6"
      >
        {formatTime(countdownInSeconds)}
      </Typography>
    </Stack>
  );
};

export default TriviaCountdown;
