import { GrowthBook } from '@growthbook/growthbook-react';
import { nanoid } from 'nanoid';

import Env from '../env/env';
import { getStorage } from './storage';

export const LIVE_LANDING = 'landing_live';
export const CRYPTO_PAYMENT = 'crypto_payment';
export const SHOW_COLLECTIONS_BUTTON = 'show_collections_button_public';
export const SHOW_CART = 'show_cart';
export const SHOW_GOLPOINTS = 'show_golpoints';
export const SHOW_NUMBER_SELECTION_ON_CHECKOUT =
  'show_number_selection_on_checkout';
export const SHOW_TRADES = 'show_trades';
export const SHOW_REFERRALS = 'show_referrals';
export const PURCHASE_GOLPOINTS = 'purchase_golpoints';
export const SHOW_PRICES_IN_EUROS = 'show_prices_in_euros';
export const MAINTENANCE = 'maintenance';
export const WEB3_FEATURES = 'web3_features';
export const SPONSOR_USERS = 'sponsor_users';
export const SHOW_FREE_BENEFIT_SEARCH = 'show_free_benefit_search';

export const configureGrowthbook = () => {
  let visitorId = getStorage()?.getItem('visitor_id');
  if (!visitorId) {
    visitorId = nanoid();
    getStorage()?.setItem('visitor_id', visitorId);
  }
  const growthbook = new GrowthBook({
    attributes: {
      id: visitorId,
    },
  });

  const FEATURES_ENDPOINT = Env.REACT_APP_FEATURES_ENDPOINT;
  fetch(FEATURES_ENDPOINT)
    .then((res) => res.json())
    .then((json) => {
      growthbook.setFeatures(json.features);
    });

  return growthbook;
};
