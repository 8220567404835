const LOGIN_DUPLICATED_EMAIL = 'DUPLICATED_EMAIL';
const LOGIN_UNDELIVERABLE_EMAIL = 'UNDELIVERABLE_EMAIL';
const LOGIN_DUPLICATED_NICKNAME = 'DUPLICATED_NICKNAME';
const LOGIN_ACTIVATION_ALREADY_MADE = 'ACTIVATION_ALREADY_MADE';
const LOGIN_INTERNAL_ERROR = 'INTERNAL_ERROR';
const LOGIN_DELIVERABLE_EMAIL = 'deliverable';

const SMS_INVALID_CODE = 'INVALID_CODE';
const SMS_EXPIRATED_CODE = 'EXPIRATED_CODE';
const SMS_ERROR = 'SMS_ERROR';
const SMS_SERVER_ERROR = 'SERVER_ERROR';
const SMS_RECAPTCHA_ERROR = 'RECAPTCHA_ERROR';

export {
  LOGIN_ACTIVATION_ALREADY_MADE,
  LOGIN_DELIVERABLE_EMAIL,
  LOGIN_DUPLICATED_EMAIL,
  LOGIN_DUPLICATED_NICKNAME,
  LOGIN_INTERNAL_ERROR,
  LOGIN_UNDELIVERABLE_EMAIL,
  SMS_ERROR,
  SMS_EXPIRATED_CODE,
  SMS_INVALID_CODE,
  SMS_RECAPTCHA_ERROR,
  SMS_SERVER_ERROR,
};
