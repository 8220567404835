import { Stack, Typography } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import palette from '../../../../theme/palette';
import TradeOfferIcon from '../../../assets/img/trade-offer.svg';
import TradeRequestIcon from '../../../assets/img/trade-request.svg';
import { ImageFallback } from '../../../components/ImageFallback/ImageFallback';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import {
  TRADE_TYPE_INDICATOR_HEIGHT,
  TRADE_TYPE_INDICATOR_WIDTH,
} from './TradeTypeIndicator.constants';
import {
  TradeTypeConstants,
  TradeTypeIndicatorProps,
} from './TradeTypeIndicator.interfaces';

const TradeTypeIndicator = ({
  type,
  size = 'large',
}: TradeTypeIndicatorProps) => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests.item.type');
  const isOffer = type === TradeTypeConstants.OFFER;

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={SpaceSizes.md}
      width="100%"
    >
      <Stack
        alignItems="center"
        bgcolor={palette.background.default}
        borderRadius={SpaceSizes.xxl2}
        justifyContent="center"
        p={SpaceSizes.sm}
      >
        <ImageFallback
          alt="Trade Type Visual Indicator"
          height={TRADE_TYPE_INDICATOR_HEIGHT}
          src={isOffer ? TradeOfferIcon : TradeRequestIcon}
          width={TRADE_TYPE_INDICATOR_WIDTH}
        />
      </Stack>
      <Typography fontSize={size}>{t(type?.toString())}</Typography>
    </Stack>
  );
};

export default TradeTypeIndicator;
