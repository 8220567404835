import { useNavigate } from 'react-router-dom';

import { URL_CHECKOUT_AUTHENTICATION } from '../../../../constants/checkout/checkout';
import { useCreateCheckoutMutation } from '../../../../features/checkout/Checkout.api';
import { checkoutCreateFactory } from '../../../../features/checkout/Checkout.factory';

interface useCreateCheckoutProps {
  price: {
    priceValue?: string;
    currency?: string;
  };
  product: { productId?: string };
  coupons?: string[];
  validations: {
    isLoggedUser: boolean;
    productIsHolded: boolean;
    isStatusFinished: boolean;
  };
  selectedQuantity: number;
}

export const useCreateCheckout = ({
  price,
  product,
  coupons,
  validations,
  selectedQuantity,
}: useCreateCheckoutProps) => {
  const navigate = useNavigate();
  const [
    createCheckout,
    {
      isLoading: createCheckoutIsLoading,
      isSuccess: createCheckoutIsSuccess,
      isUninitialized: createCheckoutIsUninitialized,
      error: createCheckoutError,
    },
  ] = useCreateCheckoutMutation();

  const { priceValue, currency } = price;
  const { productId } = product;
  const { isLoggedUser, productIsHolded, isStatusFinished } = validations;

  const validateCreateCheckout =
    isLoggedUser && !productIsHolded && !isStatusFinished;

  const handleCreateCheckout = async () => {
    if (validateCreateCheckout) {
      try {
        const response = await createCheckout(
          checkoutCreateFactory({
            productId,
            quantity: selectedQuantity,
            price: {
              unit_value: priceValue,
              currency,
            },
            coupons: coupons ?? undefined,
          }),
        ).unwrap();

        const { checkout_id: checkoutId } = response;
        return navigate(`/checkout/${checkoutId}`);
      } catch (error) {
        return error;
      }
    } else {
      return navigate(
        `${URL_CHECKOUT_AUTHENTICATION}?redirect=${window.location.pathname}`,
      );
    }
  };

  return {
    handleCreateCheckout,
    createCheckoutIsLoading,
    createCheckoutIsSuccess,
    createCheckoutIsUninitialized,
    createCheckoutError,
  };
};
