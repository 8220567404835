import { Box, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

interface NotificationLayoutProps {
  children: React.ReactNode;
}

export const NotificationLayout = ({ children }: NotificationLayoutProps) => {
  return (
    <Box px={SpaceSizes.md} py={SpaceSizes.sm}>
      <Stack spacing={SpaceSizes.sm} sx={{ cursor: 'pointer' }}>
        {children}
      </Stack>
    </Box>
  );
};
