import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { COLLECTABLES_ICON_SIZE } from '../../components/CollectableAdditionalInfo/CollectableAdditionalInfo.constants';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';

export const ReferralsSkeleton = () => {
  const isMobile = useMobileMediaQuery();

  return (
    <PageContainer id="referralsPage" maxWidth="md" backButton>
      <Skeleton height="160px" variant="rectangular" width="100%" />
      <Stack
        borderRadius={SpaceSizes.sm}
        justifyContent="flex-start"
        py={SpaceSizes.md}
        width="100%"
      >
        <Box>
          <Skeleton
            height={SpaceSizes.lg}
            sx={{
              width: { xs: '60%', md: '30%' },
            }}
            variant="text"
          />
        </Box>
        <Stack
          alignItems="center"
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
          gap={SpaceSizes.md}
        >
          <Stack
            textAlign={isMobile ? 'start' : 'center'}
            width={{ xs: '50%', md: '100%' }}
          >
            <Skeleton height={SpaceSizes.lg} variant="text" width="80%" />
          </Stack>
          <Stack
            flexDirection="row"
            gap={SpaceSizes.md}
            justifyContent="start"
            width={{ xs: '50%', md: '100%' }}
          >
            <Skeleton
              height={COLLECTABLES_ICON_SIZE}
              variant="rectangular"
              width={SpaceSizes.xl}
            />
            <Skeleton
              height={COLLECTABLES_ICON_SIZE}
              variant="rectangular"
              width={SpaceSizes.xl}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        borderRadius={SpaceSizes.sm}
        justifyContent="flex-start"
        py={SpaceSizes.md}
        width="100%"
      >
        <Stack
          alignItems="center"
          flexDirection="row"
          gap={SpaceSizes.md}
          justifyContent="flex-start"
        >
          <Skeleton height={SpaceSizes.lg} variant="text" width="170px" />
          <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
            <Skeleton
              height={SpaceSizes.lg}
              variant="text"
              width={SpaceSizes.lg}
            />
            <Skeleton
              height={SpaceSizes.lg}
              style={{ borderRadius: '50%' }}
              variant="rectangular"
              width={SpaceSizes.lg}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={SpaceSizes.md} width="100%">
        <Stack gap={SpaceSizes.md}>
          <Skeleton
            height={SpaceSizes.lgPlus}
            variant="text"
            width={isMobile ? '60%' : '40%'}
          />
          <Stack gap={SpaceSizes.sm}>
            <Skeleton height={SpaceSizes.lg} variant="text" width="100%" />
            <Skeleton height={SpaceSizes.lg} variant="text" width="100%" />
            <Skeleton height={SpaceSizes.lg} variant="text" width="100%" />
          </Stack>
        </Stack>
        <Stack gap={SpaceSizes.md} py={SpaceSizes.md}>
          <Skeleton
            height={SpaceSizes.lgPlus}
            variant="text"
            width={isMobile ? '60%' : '40%'}
          />
          <Stack gap={SpaceSizes.sm}>
            <Skeleton height={SpaceSizes.lg} variant="text" width="15%" />
            <Skeleton height={SpaceSizes.lg} variant="text" width="100%" />
            <Skeleton height={SpaceSizes.lg} variant="text" width="100%" />
          </Stack>
          <Stack gap={SpaceSizes.sm}>
            <Skeleton height={SpaceSizes.lg} variant="text" width="15%" />
            <Skeleton height={SpaceSizes.lg} variant="text" width="100%" />
          </Stack>
        </Stack>
      </Stack>
    </PageContainer>
  );
};
