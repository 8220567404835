import { Button, Divider, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import env from '../../../../env/env';
import {
  OfferStatus,
  OfferType,
} from '../../../../features/trades/Trades.types';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../../../components/ImageFallback/ImageFallback';
import { shrinkWord } from '../../../components/TradeItem/TradeItemDetail.utils';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import useSmallMobileMediaQuery from '../../../hooks/useSmallMobileMediaQuery';
import { statusTitleFunction } from '../../trade-offer-details/TradeOfferDetails.constant';
import { TradeRequestItem } from './offers/TradeRequestItem';
import { OfferItemType } from './TradeRequest.constants';
import { TradeRequestProps } from './TradeRequest.types';

const anonymousUserUrl = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/icons/anonymous-user.png`;

export const TradeRequest = ({ offer }: TradeRequestProps) => {
  const { t } = useTranslationByNamespaces('pages.myTradeRequests');
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();

  const navigateToUserProfile = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      navigate(`/user/${offer.trading_user.user_id}`);
    },
    [navigate, offer],
  );

  const handleDetails = useCallback(() => {
    navigate(`/trades/offer/${offer.tx_id}`);
  }, [navigate, offer.tx_id]);

  const isSmallestMobile = useSmallMobileMediaQuery();
  return (
    <Stack
      bgcolor="background.paper"
      borderRadius={SpaceSizes.xs}
      flexDirection="column"
      gap={SpaceSizes.smPlus}
      mb={SpaceSizes.xs}
      mr={SpaceSizes.md}
      px={SpaceSizes.mdPlus}
      py={SpaceSizes.md}
      width="100%"
    >
      <Typography fontSize="large" fontWeight="700">
        {offer?.status !== undefined
          ? statusTitleFunction(t)[offer.status]
          : ''}
      </Typography>

      {isMobile && (
        <Stack flexDirection="column" gap={SpaceSizes.sm}>
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={SpaceSizes.md}
            width="100%"
          >
            <ImageFallback
              fallback={offer.trading_user.avatar_url || anonymousUserUrl}
              height={SpaceSizes.lgPlus}
              src={offer.trading_user.avatar_url || anonymousUserUrl}
              style={{ borderRadius: '100%' }}
              width={SpaceSizes.lgPlus}
            />
            <Stack flexDirection="column" gap={SpaceSizes.sm}>
              <Typography fontSize="medium">
                {(offer?.trading_user.nickname &&
                  shrinkWord(
                    offer?.trading_user?.nickname,
                    isSmallestMobile ? 15 : 25,
                  )) ||
                  t('anonymous')}
              </Typography>
              <Stack onClick={navigateToUserProfile}>
                <Button
                  size="medium"
                  sx={{
                    cursor: 'pointer',
                  }}
                  variant="outlined"
                >
                  {t('item.seeProfile')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Typography fontSize="small" sx={{ opacity: 0.6 }}>
            {offer.trading_user.total_amount} {t('item.golballs')}
          </Typography>
        </Stack>
      )}
      {!isMobile && (
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Stack alignItems="center" flexDirection="row" gap={SpaceSizes.md}>
            <ImageFallback
              fallback={offer.trading_user.avatar_url || anonymousUserUrl}
              height={SpaceSizes.xl}
              src={offer.trading_user.avatar_url || anonymousUserUrl}
              style={{ borderRadius: '100%' }}
              width={SpaceSizes.xl}
            />
            <Stack>
              <Typography fontSize="medium">
                {offer.trading_user.nickname || t('anonymous')}
              </Typography>
              <Typography fontSize="small" sx={{ opacity: 0.6 }}>
                {offer.trading_user.total_amount} {t('item.golballs')}
              </Typography>
            </Stack>
          </Stack>
          <Stack onClick={navigateToUserProfile}>
            <Button
              size="medium"
              sx={{
                cursor: 'pointer',
              }}
              variant="outlined"
            >
              {t('item.seeProfile')}
            </Button>
          </Stack>
        </Stack>
      )}
      <Divider sx={{ width: '95%' }} />
      <TradeRequestItem
        items={offer.trading_items}
        type={
          offer.type === 'sent' ? OfferItemType.OFFER : OfferItemType.REQUEST
        }
      />
      <Divider sx={{ width: '95%' }} />
      <TradeRequestItem
        items={[offer.offered_item]}
        type={
          offer.type === 'sent' ? OfferItemType.REQUEST : OfferItemType.OFFER
        }
      />
      <Stack
        flexDirection="column"
        gap={SpaceSizes.md}
        justifyContent="center"
        width="100%"
      >
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          py={SpaceSizes.sm}
          width="100%"
        >
          <Stack width="50%">
            <Button
              data-testid="enter-details-button"
              size="large"
              variant="contained"
              fullWidth
              onClick={handleDetails}
            >
              {t('item.seeDetails')}
            </Button>
          </Stack>
        </Stack>
        {offer?.type === OfferType.RECEIVED &&
          offer?.status === OfferStatus.OPEN && (
            <Typography
              fontSize="small"
              sx={{ opacity: 0.6 }}
              textAlign="center"
            >
              {t('item.expirationWarning')}
            </Typography>
          )}
      </Stack>
    </Stack>
  );
};
