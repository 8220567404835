import { Button, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetBookmarksQuery } from '../../../../features/searchfilter/bookmarks/BookmarkedFilter.api';
import { BookmarkItem } from '../../../../features/searchfilter/responses/BookmarkItem';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import palette from '../../../../theme/palette';
import { TAG_TYPE, TAG_VALUE } from '../../../../utils/tagger';
import { Link } from '../../../components/Link/Link';
import FavsDropdownItem from './FavsDropdownItem';

export const FavsDropdownList = () => {
  const { t } = useTranslation();
  const { data: bookmarks, isSuccess } = useGetBookmarksQuery();

  return isSuccess && bookmarks && bookmarks.length > 0 ? (
    <Stack sx={{ maxHeight: 350, overflow: 'hidden', width: 380 }}>
      {bookmarks.slice(0, 3).map((bookmark: BookmarkItem) => (
        <Stack key={bookmark.product_id}>
          <FavsDropdownItem key={bookmark.product_id} bookmark={bookmark} />
          <Divider sx={{ width: '100%', marginBottom: SpaceSizes.sm }} />
        </Stack>
      ))}

      <Link
        tagId={TAG_VALUE.HEADER.FAVS}
        tagSectionId={TAG_TYPE.MENU}
        to="/favs"
      >
        <Stack alignContent="center" justifyContent="center">
          <Button>
            <Typography
              color={palette.primary.main}
              fontWeight="bold"
              variant="body2"
            >
              {t('header.userMenu.favorites.seeAll')}
            </Typography>{' '}
          </Button>
        </Stack>
      </Link>
    </Stack>
  ) : (
    <Stack
      alignContent="center"
      justifyContent="center"
      sx={{ maxHeight: 350, overflow: 'hidden', width: 350 }}
    >
      <Typography>{t('header.userMenu.favorites.noFavs')}</Typography>
    </Stack>
  );
};
