import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageErrorProps } from './PageError.types';

export const PageError = ({
  errorCode = '',
}: React.PropsWithChildren<PageErrorProps>) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const error = i18n.exists(errorCode) ? t(errorCode) : t(`error.DEFAULT`);

  return (
    <Box
      mx={{ xs: SpaceSizes.md, sm: SpaceSizes.md, md: SpaceSizes.mdPlus }}
      my={{ xs: SpaceSizes.md, sm: SpaceSizes.md, md: SpaceSizes.mdPlus }}
    >
      <Stack alignItems="center" spacing={SpaceSizes.md}>
        {errorCode === 'error.404NOTFOUND' &&
        Env.REACT_APP_GOLBALL_ENV === 'development' ? (
          <Stack alignItems="center" direction="row" spacing={SpaceSizes.sm}>
            <img
              alt="andapaya"
              src="https://static.dev.golball.com/marketplace/img/32927642.jpg"
            />
          </Stack>
        ) : (
          <Stack alignItems="center" direction="row" spacing={SpaceSizes.sm}>
            <ErrorOutlineIcon />
            <Typography data-testid="error-text" fontWeight="bold" variant="h5">
              {error}
            </Typography>
          </Stack>
        )}
        <Box>
          <Button variant="contained" onClick={() => navigate(0)}>
            {t('error.BUTTON')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
