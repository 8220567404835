import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetCollectionsByGroupQuery } from '../../../features/collections/Collections.api';
import { selectCollectionsByGroup } from '../../../features/collections/Collections.selectors';
import {
  CollectionsByGroup,
  setCollectionsByGroup,
} from '../../../features/collections/Collections.slice';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface CollectionsByGroupHook {
  collections: CollectionsByGroup;
  pickedCollection: string;
  setPickedCollection: (collectionId: string) => void;
  isError: boolean;
  isLoading: boolean;
  errorCode: string;
  collectionGroupId?: string;
}

export const useCollectionByGroup = (): CollectionsByGroupHook => {
  const { collectionGroupId, collectionId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const collections = useAppSelector(selectCollectionsByGroup);
  const [pickedCollection, setPickedCollection] = useState<string>(
    collectionId || '',
  );
  const [errorCode, setErrorCode] = useState<string>('');

  const { data, isLoading, isError, error } = useGetCollectionsByGroupQuery({
    collectionGroupId: collectionGroupId || '',
  });

  useEffect(() => {
    if (collectionGroupId === 'collections_landing') {
      navigate('/collections');
    }

    if (
      collectionId &&
      collectionGroupId &&
      location.pathname !== `/collections/${collectionGroupId}/${collectionId}`
    ) {
      setPickedCollection(collectionId || '');
      navigate(`/collections/${collectionGroupId}/${collectionId}`);
    }
  }, [
    pickedCollection,
    collectionGroupId,
    collectionId,
    location.pathname,
    navigate,
  ]);

  useEffect(() => {
    if (isError && error) {
      const err = error as { data: { code: string } };
      setErrorCode(err.data.code);
    }
  }, [isError, error]);

  useEffect(() => {
    if (data !== collections.collectionByGroup) {
      dispatch(
        setCollectionsByGroup({
          collectionByGroup: data,
          groupId: String(collectionGroupId),
        } as CollectionsByGroup),
      );
    }
  }, [data, collections, collectionGroupId, dispatch]);

  return {
    collections,
    isError,
    isLoading,
    errorCode,
    pickedCollection,
    setPickedCollection,
    collectionGroupId,
  };
};
