import { useGrowthBook } from '@growthbook/growthbook-react';
import { Divider } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isNotEmpty } from 'ramda-adjunct';
import React from 'react';

import theme from '../../../theme';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import typography from '../../../theme/typography';
import { formatGP } from '../../../utils/formatNumbers';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import { useCheckoutPay } from '../../hooks/useCheckoutPay';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import {
  CHECKOUT_TYPE,
  IMAGE_FALLBACK,
} from '../../pages/checkout/Checkout.constants';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import { CheckoutTypeCart } from '../CheckoutTypeCart/CheckoutTypeCart';
import { CheckoutTypeDirect } from '../CheckoutTypeDirect/CheckoutTypeDirect';
import { ExternalContent } from '../ExternalContent/ExternalContent';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { OptionTier } from '../OptionTier/OptionTier';
import { PaymentButton } from '../PaymentButton/PaymentButton';
import ProductPrice from '../ProductPrice/ProductPrice';
import {
  PRODUCT_PRICE_DEFAULT_LOGO,
  PRODUCT_PRICE_LOGO_DEFAULT_SIZE,
} from '../ProductPrice/ProductPrice.contants';
import { CheckoutRechargeProps } from './CheckoutRecharge.types';

export const CheckoutRecharge = ({
  paymentUrls,
  checkoutResponse,
}: CheckoutRechargeProps) => {
  const { t } = useTranslationByNamespaces('pages.checkout');
  const isMobile = useMobileMediaQuery();
  const { successUrl, cancelUrl } = paymentUrls;
  const {
    errorCode,
    isLoading: createPayIsLoading,
    isSuccess: createPayIsSuccess,
    handleTierChange,
    handlePayment,
    disableButton,
    selectedTier,
  } = useCheckoutPay({ successUrl, cancelUrl });

  const growthbook = useGrowthBook();
  const webPaymentsEnabled = growthbook?.isOn('web_payments_enabled');

  const { items, tiers, userBalance, totalPoints, type } = checkoutResponse;
  // const modifiedTiers = tiers?.slice(0, MAX_TIERS_LIMIT);
  const existPreSelected = tiers?.some((tier) => tier.selected);

  return (
    <Stack
      alignContent="center"
      alignItems="center"
      gap={{
        xs: SpaceSizes.md,
        md: type === CHECKOUT_TYPE.DIRECT ? SpaceSizes.md : SpaceSizes.xxl2,
      }}
      justifyContent="space-evenly"
      sx={{
        flexDirection: {
          xs: 'column',
          md: type === CHECKOUT_TYPE.DIRECT ? 'column' : 'row',
        },
      }}
      width="100%"
    >
      <Stack
        bgcolor="transparent"
        borderRadius={IMAGE_FALLBACK.BORDER_RADIUS}
        width="100%"
      >
        {type === CHECKOUT_TYPE.CART && (
          <CheckoutTypeCart
            items={items}
            selectedTier={selectedTier}
            totalPoints={totalPoints}
            userBalance={userBalance}
          />
        )}
        {type === CHECKOUT_TYPE.DIRECT && (
          <CheckoutTypeDirect
            item={items && items[0]}
            totalPoints={totalPoints}
          />
        )}
      </Stack>
      <Stack
        gap={SpaceSizes.sm}
        position={isMobile ? 'relative' : 'initial'}
        width="100%"
      >
        <Stack
          alignItems="center"
          gap={SpaceSizes.md}
          style={{
            borderTopLeftRadius: SpaceSizes.sm,
            borderBottomLeftRadius: SpaceSizes.sm,
            borderTopRightRadius: SpaceSizes.sm,
            borderBottomRightRadius: SpaceSizes.sm,
          }}
          width="100%"
        >
          {type === CHECKOUT_TYPE.CART && (
            <Stack gap={SpaceSizes.md} width="100%">
              {!selectedTier?.topUpId && (
                <AlertCard
                  content={
                    <Stack>
                      <Typography
                        fontWeight={500}
                        textAlign="start"
                        variant={isMobile ? 'caption' : 'body2'}
                      >
                        {t('alerts.insufficientBalance.title')}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        textAlign="start"
                        variant={isMobile ? 'caption' : 'body2'}
                      >
                        {t('alerts.insufficientBalance.subtitle')}
                      </Typography>
                    </Stack>
                  }
                  id="alert-insufficientBalance"
                  severity={AlertSeverity.WARNING}
                  sx={{ margin: SpaceSizes.none, padding: SpaceSizes.none }}
                  show
                />
              )}
              <Stack gap={SpaceSizes.xs}>
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    color="text.secondary"
                    variant={isMobile ? 'caption' : 'body2'}
                  >
                    {t('amounts.priceGolball')}
                  </Typography>
                  <ProductPrice
                    size={isMobile ? 'small' : 'medium'}
                    value={totalPoints}
                  />
                </Stack>
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    color="text.secondary"
                    variant={isMobile ? 'caption' : 'body2'}
                  >
                    {t('amounts.userBalance')}
                  </Typography>
                  <ProductPrice
                    size={isMobile ? 'small' : 'medium'}
                    value={userBalance}
                  />
                </Stack>
                <Divider sx={{ width: '100%' }} />
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    color={
                      !selectedTier?.topUpId ? 'error.main' : 'success.main'
                    }
                    variant={isMobile ? 'caption' : 'body2'}
                  >
                    {!selectedTier?.topUpId
                      ? t('amounts.totalMiss')
                      : t('amounts.YouWouldHave')}
                  </Typography>
                  <ProductPrice
                    size={isMobile ? 'small' : 'medium'}
                    sx={{
                      color: !selectedTier?.topUpId
                        ? 'error.main'
                        : 'success.main',
                    }}
                    value={Math.abs(
                      userBalance + selectedTier.points - totalPoints,
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        {webPaymentsEnabled ? (
          <>
            <Stack gap={SpaceSizes.lg} width="100%">
              <Stack textAlign="center">
                {type === CHECKOUT_TYPE.CART && (
                  <Typography fontWeight="700" variant="h6">
                    {t('tiers.pageTitle')}
                  </Typography>
                )}
                <Stack
                  bgcolor={
                    type === CHECKOUT_TYPE.DIRECT
                      ? 'background.paper'
                      : 'background.transparent'
                  }
                  gap={SpaceSizes.sm}
                  padding={SpaceSizes.md}
                  style={{
                    borderRadius: SpaceSizes.md,
                  }}
                  width="100%"
                >
                  {isNotEmpty(errorCode) && (
                    <AlertCard
                      content={t(`errors.${errorCode}`)}
                      id="alert-payError"
                      show={isNotEmpty(errorCode)}
                      sx={{
                        margin: SpaceSizes.none,
                        padding: SpaceSizes.none,
                        fontSize: isMobile
                          ? typography.cBody1.fontSize
                          : typography.cH6.fontSize,
                      }}
                    />
                  )}
                  {userBalance > 0 && type === CHECKOUT_TYPE.DIRECT && (
                    <Stack
                      alignItems="center"
                      flexDirection="row"
                      gap={SpaceSizes.sm}
                    >
                      <Typography fontWeight="600">
                        {t('amounts.yourBalance')}
                      </Typography>
                      <ImageFallback
                        fallback={PRODUCT_PRICE_DEFAULT_LOGO}
                        height={PRODUCT_PRICE_LOGO_DEFAULT_SIZE.height}
                        src={PRODUCT_PRICE_DEFAULT_LOGO}
                        width={PRODUCT_PRICE_LOGO_DEFAULT_SIZE.width}
                      />
                      <Typography fontWeight="600">
                        {formatGP(userBalance)}
                      </Typography>
                    </Stack>
                  )}
                  <FormControl
                    sx={{ width: '100%', justifyContent: 'space-around' }}
                  >
                    <RadioGroup
                      sx={{
                        alignContent: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Stack gap={SpaceSizes.md} width="100%">
                        {tiers?.map((element, index) => {
                          return (
                            <OptionTier
                              key={element.tier_id}
                              handleTierChange={handleTierChange}
                              isCheckout={type === CHECKOUT_TYPE.DIRECT}
                              isFirstSelected={index === 0 && !existPreSelected}
                              selectedTier={selectedTier}
                              tier={element}
                            />
                          );
                        })}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            <Stack width="100%">
              <PaymentButton
                buttonText={t('buy')}
                dataTestId="payment-button"
                disableButton={disableButton}
                handleBuy={handlePayment}
                id="buy_with_recharge"
                isLoading={createPayIsLoading}
                showPrice={false}
                sx={{
                  width: '100%',
                }}
                variant={isMobile ? 'body2' : 'subtitle1'}
              />
              <Backdrop
                open={createPayIsLoading || createPayIsSuccess}
                sx={{
                  zIndex: theme.zIndex.drawer + 1,
                  width: '100%',
                  height: '100%',
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Stack>
          </>
        ) : (
          <>
            {type !== CHECKOUT_TYPE.CART && (
              <AlertCard
                content={
                  <Stack>
                    <Typography
                      fontWeight={500}
                      textAlign="start"
                      variant={isMobile ? 'caption' : 'body2'}
                    >
                      {t('alerts.insufficientBalance.title')}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      textAlign="start"
                      variant={isMobile ? 'caption' : 'body2'}
                    >
                      {t('alerts.insufficientBalance.subtitle')}
                    </Typography>
                  </Stack>
                }
                id="alert-insufficientBalance"
                severity={AlertSeverity.WARNING}
                sx={{ margin: SpaceSizes.none, padding: SpaceSizes.none }}
                show
              />
            )}
            <ExternalContent
              filter={{ key: 'content_id', value: 'web_payments_disabled' }}
              type="contents"
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
