import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const TRADE_OFFER_CTA_FONT_SIZE = {
  xs: 12,
  sm: 13,
  md: 14,
};

export const TRADE_OFFER_TITLE_FONT_SIZE = {
  xs: 18,
  sm: 22,
  md: 24,
};

export const TRADE_OFFER_CTA_GAP = {
  xs: SpaceSizes.xs,
  sm: SpaceSizes.xlPlus,
};
