import { Button, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectUserId } from '../../../features/session/Session.selector';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useAppSelector } from '../../hooks';
import { StatesEmptyResults } from './EmptyResults.constant';

export const EmptyResults = ({
  origin,
  t,
}: {
  origin: StatesEmptyResults;
  t: (str: string) => string;
}) => {
  const navigate = useNavigate();

  const userId = useAppSelector(selectUserId);

  const typographyText = useMemo(() => {
    return origin === StatesEmptyResults.BUYS ? 'empty' : 'emptyTransfers';
  }, [origin]);

  const buttonText = useMemo(() => {
    return origin === StatesEmptyResults.BUYS ? 'goToMarket' : 'goToTransfers';
  }, [origin]);

  const navigationPath = useMemo(() => {
    return origin === StatesEmptyResults.BUYS
      ? '/marketplace'
      : `/user/${userId}`;
  }, [origin, userId]);

  return (
    <Stack alignItems="center" gap={SpaceSizes.sm}>
      <Typography color="text.primary" fontWeight={700} variant="cH5">
        {t(typographyText)}
      </Typography>
      <Button
        sx={{ paddingX: SpaceSizes.xl }}
        variant="outlined"
        onClick={() => navigate(navigationPath)}
      >
        {t(buttonText)}
      </Button>
    </Stack>
  );
};
