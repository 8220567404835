import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { ITypographycH3 } from './ResultTime.types';

const TypographycH3 = ({
  children,
  bold = false,
  golden = false,
  reducedOpacity = false,
}: ITypographycH3) => {
  const theme = useTheme();

  const goldenStyle = {
    background: theme.palette.golden.gradient,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  };

  return (
    <Typography
      sx={{
        ...(!bold && { fontWeight: 'normal' }),
        ...(reducedOpacity && { opacity: '68%' }),
        ...(golden && goldenStyle),
      }}
      variant="cH3"
    >
      {children}
    </Typography>
  );
};

export default TypographycH3;
