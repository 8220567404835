import { useTranslation } from 'react-i18next';

export const useTranslationByNamespaces = (namespace?: string) => {
  const { t: tHook } = useTranslation();

  const t = (key: string, variable?: Record<string, string>) => {
    return tHook(`${namespace}.${key}`, variable);
  };
  return { t };
};
