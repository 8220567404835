import { useMemo } from 'react';

import {
  EventType,
  MatchType,
  Player,
} from '../../../features/trades/Trades.types';

export const useTradeItem = (
  events: EventType[],
  player: Player,
  match: MatchType,
) => {
  const firstGoalEvent = useMemo(() => {
    return events?.find((event) => event.event_type === 'GOAL');
  }, [events]);
  const pickGoal = useMemo(() => {
    if (player?.team_id === match?.away.id) {
      return firstGoalEvent?.event_data.is_own_goal
        ? firstGoalEvent?.event_data.home_partial_score
        : firstGoalEvent?.event_data.away_partial_score;
    }
    return firstGoalEvent?.event_data.is_own_goal
      ? firstGoalEvent?.event_data.away_partial_score
      : firstGoalEvent?.event_data.home_partial_score;
  }, [player, match, firstGoalEvent]);
  const mainTeam = useMemo(() => {
    if (player.team_id === match.home.id) {
      return match.home;
    }
    return match.away;
  }, [match, player]);
  const awayTeam = useMemo(() => {
    if (player.team_id === match.away.id) {
      return match.home;
    }
    return match.away;
  }, [match, player]);
  return { mainTeam, awayTeam, pickGoal, firstGoalEvent };
};
