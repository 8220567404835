import { Box, Skeleton, Stack } from '@mui/material';
import React, { useMemo } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useSmallMobileMediaQuery from '../../hooks/useSmallMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { RANK_LIMIT } from './TableRank.constants';
import { TableRankRowStyledBox } from './TableRankRow';

export const TableRankSkeleton = ({ limit }: { limit?: number }) => {
  const isSmallestMobile = useSmallMobileMediaQuery();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const flexDirection = useMemo(
    () => (isMobile ? 'column' : 'row'),
    [isMobile],
  );
  return (
    <Stack style={{ width: '100%' }}>
      <Box>
        <Stack>
          {Array.from({ length: limit ?? RANK_LIMIT }).map((_, index) => (
            <Box key={`${Date.now() + index}`}>
              <TableRankRowStyledBox $isMobile={isMobile} $isSelf={false}>
                <Stack
                  alignItems="center"
                  direction={flexDirection}
                  gap={isSmallestMobile ? SpaceSizes.md : SpaceSizes.lg}
                  justifyContent="flex-start"
                >
                  <Stack
                    alignItems="center"
                    direction={flexDirection}
                    gap={isSmallestMobile ? SpaceSizes.xs : SpaceSizes.sm}
                    justifyContent="center"
                    width={isMobile ? '100%' : 'auto'}
                  >
                    <Stack
                      alignItems="center"
                      direction={flexDirection}
                      flexDirection="row"
                      gap={isSmallestMobile ? SpaceSizes.xs : SpaceSizes.sm}
                      justifyContent={isMobile ? 'flex-start' : 'space-between'}
                      width={isMobile ? '100%' : 'auto'}
                    >
                      <Skeleton
                        height={isMobile ? SpaceSizes.mdPlus : SpaceSizes.lg}
                        style={{ marginLeft: SpaceSizes.md }}
                        variant="circular"
                        width={isMobile ? SpaceSizes.mdPlus : SpaceSizes.lg}
                      />
                      <Skeleton
                        height={isMobile ? SpaceSizes.lg : SpaceSizes.lgPlus}
                        variant="circular"
                        width={isMobile ? SpaceSizes.lg : SpaceSizes.lgPlus}
                      />
                      <Stack direction="column" display="flex">
                        <Skeleton
                          height={
                            isSmallestMobile ? SpaceSizes.mdPlus : SpaceSizes.lg
                          }
                          variant="text"
                          width={SpaceSizes.xlPlus}
                        />
                        <Skeleton
                          height={
                            isSmallestMobile ? SpaceSizes.md : SpaceSizes.mdPlus
                          }
                          variant="text"
                          width={
                            isSmallestMobile ? SpaceSizes.xxl : SpaceSizes.xxl2
                          }
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </TableRankRowStyledBox>
              <Stack
                style={{ borderLeft: 'none', width: isTablet ? '20%' : '50%' }}
              >
                <Stack
                  alignItems="center"
                  direction={flexDirection}
                  gap={SpaceSizes.sm}
                >
                  <Skeleton
                    height={isMobile ? SpaceSizes.lg : SpaceSizes.lgPlus}
                    variant="circular"
                    width={isMobile ? SpaceSizes.lg : SpaceSizes.lgPlus}
                  />
                  {!isTablet && (
                    <Skeleton
                      height={SpaceSizes.lg}
                      variant="text"
                      width={SpaceSizes.xxl}
                    />
                  )}
                </Stack>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
