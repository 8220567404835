import { geti18nResolvedLanguage } from '../app/i18n';

export const LanguageToLocale: { [key: string]: string } = {
  es: 'es-ES',
  en: 'en-US',
  pt: 'pt-BR',
  fr: 'fr-FR',
};

export const formatGP = (value: number) => {
  const selectedLanguage = geti18nResolvedLanguage();
  return value.toLocaleString(LanguageToLocale[selectedLanguage], {
    useGrouping: true,
  });
};
