import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

interface IBadge {
  text?: string;
  variant?: 'big' | 'small';
}

const getSize = (variant: string) => {
  if (variant === 'big') return SpaceSizes.smPlus;
  if (variant === 'small') return SpaceSizes.sm;
  return SpaceSizes.sm;
};

export const Badge = ({
  text = 'header.topMenu.new',
  variant = 'small',
}: IBadge) => {
  const { t } = useTranslation();

  return (
    <Stack
      height={SpaceSizes.md}
      justifyContent="center"
      ml={SpaceSizes.sm}
      padding={getSize(variant)}
      sx={{
        backgroundColor: 'primary.main',
        borderRadius: 2,
        color: 'background.default',
      }}
    >
      <Typography fontSize={getSize(variant)} fontWeight="bold">
        {t(text)}
      </Typography>
    </Stack>
  );
};
