import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';

export const VIEW_SIZE_HEIGHT = {
  xs: 125,
  sm: 150,
  md: 200,
  lg: 210,
  xl: 300,
};
export const VIEW_SIZE_HEIGHT_SMALLEST = 100;
export const VIEW_SIZE_WIDTH = {
  xs: 95,
  sm: 110,
  md: 150,
  lg: 155,
  xl: 230,
};
export const VIEW_SIZE_WIDTH_SMALLEST = 70;
export const ADD_SIZE_HEIGHT = {
  xs: 180,
  sm: 210,
  md: 280,
  lg: 240,
  xl: 370,
};
export const ADD_SIZE_HEIGHT_SMALLEST = 140;
export const EMPTY_ADD_SIZE_HEIGHT = {
  xs: 240,
  sm: 270,
  md: 340,
  lg: 300,
  xl: 440,
};
export const EMPTY_ADD_HEIGHT_SMALLEST = 200;
export const ADD_SIZE_WIDTH = {
  xs: 142,
  sm: 158,
  md: 214,
  lg: 175,
  xl: 276,
};
export const ADD_SIZE_WIDTH_SMALLEST = 102;

export const STATIC_SIZE_HEIGHT = {
  xs: 215,
  sm: 305,
  md: 410,
  lg: 445,
  xl: 617,
};
export const STATIC_SIZE_HEIGHT_SMALLEST = 205;
export const STATIC_SIZE_WIDTH = {
  xs: 130,
  sm: 230,
  md: 310,
  lg: 330,
  xl: 470,
};
export const STATIC_SIZE_WIDTH_SMALLEST = 130;

export const ICON_SIZE_ACTION = { xs: 22, sm: 24, md: 28, lg: 32, xl: 40 };

export const FONT_SIZE_PIECE_NUMBER = {
  dynamic: { xs: 9, sm: 8, md: 12, lg: 13, xl: 15 },
  static: { xs: 11, sm: 11, md: 14, lg: 15, xl: 16 },
};

export const FONT_SIZE_BALL_ID = {
  dynamic: { xs: 9, sm: 9, md: 10, lg: 12, xl: 16 },
  static: { xs: 10, sm: 11, md: 13, lg: 16, xl: 16 },
};
export const BALL_ID_PADDING_X = {
  xs: SpaceSizes.xs,
  sm: SpaceSizes.sm,
  md: SpaceSizes.md,
};
