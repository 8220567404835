import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useBackNavigation } from '../../hooks/useBackNavigation';
import { BackButtonProps } from './BackButton.types';

const BackButton = ({ onClick, sx }: BackButtonProps) => {
  const handleBackNavigation = useBackNavigation();

  return (
    <Button
      data-testid="page-back-button"
      size="large"
      startIcon={<ArrowBackIosNewIcon />}
      sx={{ justifyContent: 'flex-start', ...sx, padding: SpaceSizes.none }}
      onClick={onClick || handleBackNavigation}
    />
  );
};

export default BackButton;
