import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_VALUE } from '../../../utils/tagger';
import { DiscordIcon } from '../CustomIcons/DiscordIcon';
import { TikTokIcon } from '../CustomIcons/TikTokIcon';
import { IconWrapper } from '../IconWrapper/IconWrapper';
import { Link } from '../Link/Link';

const Comunity = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="flex-start">
      <Stack spacing={SpaceSizes.sm}>
        <Typography fontWeight="bold" textAlign="left" variant="body1">
          {t('footer.community.title')}
        </Typography>
        <Stack direction="row" spacing={SpaceSizes.md}>
          <Link
            tagEventId={TAG_EVENT.SOCIAL_MEDIA}
            tagId={TAG_VALUE.SOCIAL_MEDIA.IG}
            to={t('footer.community.urls.ig')}
          >
            <IconWrapper iconComponent={InstagramIcon} />
          </Link>
          <Link
            tagEventId={TAG_EVENT.SOCIAL_MEDIA}
            tagId={TAG_VALUE.SOCIAL_MEDIA.FB}
            to={t('footer.community.urls.fb')}
          >
            <IconWrapper iconComponent={FacebookIcon} />
          </Link>
          <Link
            tagEventId={TAG_EVENT.SOCIAL_MEDIA}
            tagId={TAG_VALUE.SOCIAL_MEDIA.TW}
            to={t('footer.community.urls.tw')}
          >
            <IconWrapper iconComponent={TwitterIcon} />
          </Link>
          <Link
            tagEventId={TAG_EVENT.SOCIAL_MEDIA}
            tagId={TAG_VALUE.SOCIAL_MEDIA.TIKTOK}
            to={t('footer.community.urls.tiktok')}
          >
            <IconWrapper iconComponent={TikTokIcon} />
          </Link>
          <Link
            tagEventId={TAG_EVENT.SOCIAL_MEDIA}
            tagId={TAG_VALUE.SOCIAL_MEDIA.LINKEDIN}
            to={t('footer.community.urls.linkedin')}
          >
            <IconWrapper iconComponent={LinkedInIcon} />
          </Link>
          <Link
            tagEventId={TAG_EVENT.SOCIAL_MEDIA}
            tagId={TAG_VALUE.SOCIAL_MEDIA.DISCORD}
            to={t('footer.community.urls.discord')}
          >
            <IconWrapper iconComponent={DiscordIcon} />
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { Comunity };
