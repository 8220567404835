import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { AccordionSectionProps } from './AccordionSection.types';

export const AccordionSection = ({
  titleKey,
  children,
  defaultExpanded = false,
  expanded,
  onChange,
  accordionKey,
  imgSrc,
  sx,
}: AccordionSectionProps) => {
  const { t } = useTranslation();
  const [localExpanded, setLocalExpanded] = useState(defaultExpanded);

  return (
    <Accordion
      expanded={expanded || localExpanded}
      sx={sx}
      onChange={onChange || (() => setLocalExpanded(!localExpanded))}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          alignItems="center"
          flexDirection="row"
          paddingLeft={SpaceSizes.sm}
        >
          {imgSrc && (
            <Stack paddingRight={SpaceSizes.sm}>
              <ImageFallback
                fallback={imgSrc}
                height={40}
                src={imgSrc}
                style={{
                  objectFit: 'contain',
                }}
                width={70}
              />
            </Stack>
          )}
          <Typography data-testid={accordionKey}>{t(titleKey)}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
