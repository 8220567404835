import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Button, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TAG_EVENT, TAG_TYPE } from '../../../../utils/tagger';
import { Badge } from '../../../components/Badge/Badge';

interface Props {
  text: string;
  to?: string;
  onClickCallback?: React.MouseEventHandler<HTMLAnchorElement>;
  endIcon?: React.ReactNode | undefined;
  forceActive?: boolean | undefined;
  disabled?: boolean | undefined;
  tagId?: string;
  newBadge?: boolean;
}

export const MenuItem = ({
  text,
  to,
  onClickCallback,
  endIcon,
  forceActive,
  disabled,
  tagId,
  newBadge,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sendDataToGTM = useGTMDispatch();
  const isActive = useMemo(() => {
    return location.pathname.split('?')[0] === String(to).split('?')[0];
  }, [location, to]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (to) {
        sendDataToGTM({
          event: TAG_EVENT.SELECT_CONTENT,
          content_type: TAG_TYPE.MENU,
          item_id: tagId,
        });
        navigate(to);
      }
      if (onClickCallback) onClickCallback(event);
    },
    [navigate, to, onClickCallback, sendDataToGTM, tagId],
  );

  return (
    <Button
      component="span"
      disabled={disabled}
      endIcon={endIcon}
      sx={{
        borderColor: 'primary.main',
        borderRadius: 0,
        borderBottom: forceActive || isActive ? 2 : 0,
      }}
      onClick={handleClick}
      {...props}
    >
      <Typography
        fontWeight={forceActive || isActive ? 'bold' : 'normal'}
        letterSpacing={1.2}
        variant="body2"
      >
        {text}
      </Typography>
      {newBadge && <Badge />}
    </Button>
  );
};
