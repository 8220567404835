import { isArray, isObject } from 'ramda-adjunct';

import { ExternalContentResponse } from './ExternalContent.types';

export const selectContentApp = <T>(result?: ExternalContentResponse<T>) =>
  result &&
  isArray(result.data) &&
  result.data.length > 0 &&
  isObject(result.data[0].attributes.content_app)
    ? result.data[0].attributes.content_app
    : undefined;
