import { Button, Snackbar, SnackbarContent, Stack } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { add } from 'date-fns';
import { noop } from 'ramda-adjunct';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { getStorage } from '../../../utils/storage';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import { COOKIES_FLAG } from './SnackbarCookies.constants';
import { ISnackbarCookiesProps } from './SnackbarCookies.types';

export const SnackbarCookies = ({
  availableReject = false,
}: ISnackbarCookiesProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isMobile = useMobileMediaQuery();

  useEffect(() => {
    // Check if consent has already been given
    const consent = getStorage()?.getItem(COOKIES_FLAG);
    if (consent !== 'Accepted' && consent !== 'Rejected') {
      setOpen(true);
    } else if (consent === 'Accepted') {
      // add cookie if consent has already been given
      const cookies = new Cookies();
      cookies.set(COOKIES_FLAG, 'Accepted', {
        path: '/',
        expires: add(new Date(), { years: 1 }),
      });
    }
  }, []);

  const handleAcceptClose = useCallback(() => {
    getStorage()?.setItem(COOKIES_FLAG, 'Accepted');
    const cookies = new Cookies();
    cookies.set(COOKIES_FLAG, 'Accepted', {
      path: '/',
      expires: add(new Date(), { years: 1 }),
    });
    setOpen(false);
    window.location.reload();
  }, []);

  const handleRejectClose = useCallback(() => {
    getStorage()?.setItem(COOKIES_FLAG, 'Rejected');
    setOpen(false);
  }, []);

  const slideTransition = useCallback((props: SlideProps) => {
    return <Slide {...props} direction="up" />;
  }, []);

  return (
    <Snackbar
      key="snack-bar-cookies"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isMobile ? 'center' : 'left',
      }}
      message={
        <SnackbarContent
          action={
            <Stack direction="row" spacing={SpaceSizes.md}>
              <Button
                data-testid="accept-cookies"
                size="small"
                variant="contained"
                onClick={handleAcceptClose}
              >
                {t('components.cookiesSnackbar.accept')}
              </Button>
              {availableReject && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleRejectClose}
                >
                  {t('components.cookiesSnackbar.reject')}
                </Button>
              )}
            </Stack>
          }
          elevation={0}
          message={t('components.cookiesSnackbar.content')}
        />
      }
      open={open}
      sx={{
        maxWidth: isMobile ? '100%' : '80%',
        opacity: 0.95,
      }}
      TransitionComponent={slideTransition}
      disableWindowBlurListener
      onClose={noop}
    />
  );
};
