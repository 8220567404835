import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Box, Grid, Stack, Switch, Typography } from '@mui/material';
import { reduce } from 'ramda';
import { noop } from 'ramda-adjunct';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  AVAILABLE_KEY,
  BRAND_KEY,
  COMPETITION_KEY,
  FREE_KEY,
  PLAYER_KEY,
  SEASON_KEY,
  TEAM_KEY,
} from '../../../../features/searchfilter/Filters.constants';
import {
  AppliedFilter,
  Filter,
  FilterValue,
} from '../../../../features/searchfilter/responses/SearchResponse';
import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../../utils/tagger';
import useMobileMediaQuery from '../../../hooks/useMobileMediaQuery';
import { AccordionSection } from '../../AccordionSection/AccordionSection';
import { AppliedFilters } from '../AppliedFilters/AppliedFilters';
import { SearchSectionOptions } from '../SearchSection/SearchSection.types';
import AccordionFilter from './AccordionFilter';
import { ClubFilterItem } from './ClubFilterItem';

// reduce applied filter list helper
export const mapAppliedFiltersById = (filters?: AppliedFilter[]) => {
  return reduce(
    (acc: Record<string, AppliedFilter>, filter: AppliedFilter) => ({
      ...acc,
      [filter.id]: filter,
    }),
    {} as Record<string, AppliedFilter>,
    filters ?? [],
  );
};

// reduce filters list helper
export const mapFiltersById = (filters: Filter[]) => {
  return reduce(
    (acc: Record<string, Filter>, filter: Filter) => ({
      ...acc,
      [filter.id]: filter,
    }),
    {} as Record<string, Filter>,
    filters ?? [],
  );
};

interface FilterProps {
  filters: Filter[];
  options?: SearchSectionOptions;
  appliedFilters?: AppliedFilter[];
  onFilterAdded?: (filter: Filter, filterValue: FilterValue) => void;
  addFilter: (filter: AppliedFilter) => void;
  removeFilter: (filterId: string) => void;
}

export const Filters = ({
  filters,
  appliedFilters,
  onFilterAdded: onFilterAddedCallback = noop,
  options,
  addFilter,
  removeFilter,
}: FilterProps) => {
  const isMobile = useMobileMediaQuery();
  const { t } = useTranslation();

  // filters we can apply
  const filtersById = useMemo(() => mapFiltersById(filters), [filters]);
  const { available, free, competition, season, team, player, brand } =
    filtersById;

  // already applied filters from backend
  const appliedFiltersById = useMemo(
    () => mapAppliedFiltersById(appliedFilters),
    [appliedFilters],
  );
  const { available: appliedAvailable, free: appliedFree } = appliedFiltersById;

  // this is being used as a filter disabler
  const { noAvailable, noPlayers, noCompetition, noSeason, noBrand } =
    options || {};

  // states to manage the switch state
  const [switchFreeValue, setSwitchFreeValue] = useState(false);
  const [switchAvailableValue, setSwitchAvailableValue] = useState(false);

  useEffect(() => {
    setSwitchAvailableValue(!!appliedAvailable?.value);
    setSwitchFreeValue(!!appliedFree?.value);
  }, [appliedAvailable?.value, appliedFree?.value]);

  const [selectedAccordion, setSelectedAccordion] = useState<string | false>(
    false,
  );

  const handleFilterTypeChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSelectedAccordion(isExpanded ? panel : false);
    };

  const sendDataToGTM = useGTMDispatch();

  const handleFilter = useCallback(
    (filter: Filter, filterValue: FilterValue) => {
      const appliedFilter: AppliedFilter = {
        id: filter.id,
        type: filter.type,
        name: filter.name,
        value: filterValue.id,
        valueLabel: filterValue.label,
      };

      setSelectedAccordion(filter.id);

      addFilter(appliedFilter);

      sendDataToGTM({
        event: TAG_EVENT.FILTER,
        filter_type: filter.id,
        filter_value: filterValue.id,
      });
      onFilterAddedCallback(filter, filterValue);
    },
    [addFilter, sendDataToGTM, onFilterAddedCallback],
  );

  const handleSwitch = useCallback(
    (
        filterId: string,
        filterName: string,
        setState: Dispatch<SetStateAction<boolean>>,
      ) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const appliedFilter = {
          id: e.target.checked.toString(),
          label: 'Boolean',
        };

        setState(e.target.checked);

        handleFilter(
          {
            id: filterId,
            type: 'boolean',
            name: filterName,
            values: [],
          },
          appliedFilter,
        );
      },
    [handleFilter],
  );

  const handleFreeValueSwitch = handleSwitch(
    FREE_KEY,
    FREE_KEY,
    setSwitchFreeValue,
  );

  const handleAvailableSwitch = handleSwitch(
    AVAILABLE_KEY,
    AVAILABLE_KEY,
    setSwitchAvailableValue,
  );

  return (
    <Box p={isMobile ? SpaceSizes.xs : SpaceSizes.sm}>
      <Stack spacing={SpaceSizes.sm}>
        {appliedFilters && (
          <AppliedFilters
            appliedFilters={appliedFilters}
            removeFilter={removeFilter}
          />
        )}
        {!noAvailable && available && (
          <Stack
            alignItems="center"
            flexDirection="row"
            py={SpaceSizes.xs}
            sx={{ backgroundColor: 'background.paper' }}
          >
            <Switch
              checked={switchAvailableValue}
              data-testid="available"
              onChange={handleAvailableSwitch}
            />
            <Typography>
              {t('pages.marketplace.filters.filterType.available')}
            </Typography>
          </Stack>
        )}
        {free && (
          <Stack
            alignItems="center"
            flexDirection="row"
            py={SpaceSizes.xs}
            sx={{ backgroundColor: 'background.paper' }}
          >
            <Switch
              checked={switchFreeValue}
              data-testid="free"
              onChange={handleFreeValueSwitch}
            />
            <Typography>
              {t('pages.marketplace.filters.filterType.free')}
            </Typography>
          </Stack>
        )}

        {!noBrand && brand && brand.values.length > 0 && (
          <AccordionFilter
            accordionKey={BRAND_KEY}
            filter={brand}
            handleFilter={handleFilter}
            handleFilterTypeChange={handleFilterTypeChange}
            selectedAccordion={selectedAccordion}
          />
        )}

        {!noCompetition && competition && competition.values.length > 0 && (
          <AccordionFilter
            accordionKey={COMPETITION_KEY}
            filter={competition}
            handleFilter={handleFilter}
            handleFilterTypeChange={handleFilterTypeChange}
            selectedAccordion={selectedAccordion}
          />
        )}

        {!noSeason && season && season.values.length > 0 && (
          <AccordionFilter
            accordionKey={SEASON_KEY}
            filter={season}
            handleFilter={handleFilter}
            handleFilterTypeChange={handleFilterTypeChange}
            selectedAccordion={selectedAccordion}
          />
        )}
        {team && team.values.length > 0 && (
          <AccordionSection
            accordionKey="team"
            expanded={selectedAccordion === TEAM_KEY}
            titleKey="pages.marketplace.filters.filterType.club"
            onChange={handleFilterTypeChange(TEAM_KEY)}
          >
            <Grid spacing={SpaceSizes.xs} container>
              {team.values.map((filter) => (
                <Grid key={filter.id} lg={6} md={6} sm={6} xs={12} item>
                  <ClubFilterItem
                    id={filter.id}
                    justifyContent="flex-start"
                    label={filter.label}
                    logo={filter.logo}
                    onClickFn={() => {
                      handleFilter(team, filter);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionSection>
        )}
        {!noPlayers && player && player.values.length > 0 && (
          <AccordionFilter
            accordionKey={PLAYER_KEY}
            filter={player}
            handleFilter={handleFilter}
            handleFilterTypeChange={handleFilterTypeChange}
            selectedAccordion={selectedAccordion}
          />
        )}
      </Stack>
    </Box>
  );
};
