import { useSearchBookmarkedQuery } from '../../../features/searchfilter/bookmarks/BookmarkedFilter.api';
import { SearchResponse } from '../../../features/searchfilter/responses/SearchResponse';
import createUseDataWithInfiniteScroll from '../shared/createUseDataWithInfiniteScroll';

/**
 * Hook that let us reuse infinite scroll for search api
 * @returns
 */
const useSearchBookmarkedWithInfiniteScroll =
  createUseDataWithInfiniteScroll<SearchResponse>(useSearchBookmarkedQuery);

export default useSearchBookmarkedWithInfiniteScroll;
