import { Button, Skeleton, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { isUndefined } from 'ramda-adjunct';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectUserSession } from '../../../features/session/Session.selector';
import {
  useGetTriviaQuestionQuery,
  useSetTriviaAnswerMutation,
} from '../../../features/trivia/Trivia.api';
import { TriviaAnswerResponse } from '../../../features/trivia/Trivia.types';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { AlertCard } from '../../components/AlertCard/AlertCard';
import { AlertSeverity } from '../../components/AlertCard/AlertSeverity';
import { useAppSelector } from '../../hooks';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import TriviaAnswer from './TriviaAnswer/TriviaAnswer';
import TriviaQuestion from './TriviaQuestion/TriviaQuestion';
import TriviaStatus from './TriviaStatus';

const TriviaGame = ({ battleId }: { battleId: string }) => {
  const [answer, setAnswer] = useState<TriviaAnswerResponse>();

  const [viewedTs, setViewedTs] = useState<number>(0);

  const [countdownInSeconds, setCountdownInSeconds] = useState<number>();

  const [onTimeout, setOnTimeout] = useState<boolean>(false);

  const [serverError, setServerError] = useState<boolean>(false);

  const { t } = useTranslationByNamespaces('pages.trivia');

  const user = useAppSelector(selectUserSession);

  const timerId = useRef<NodeJS.Timeout | null>(null); // Utilizar useRef para mantener la referencia

  const [userChoiceIndex, setUserChoiceIndex] = useState<number | undefined>(
    undefined,
  );

  const {
    data,
    isSuccess: isSuccessQuestion,
    isLoading: isLoadingQuestion,
    isError: isErrorQuestion,
  } = useGetTriviaQuestionQuery(battleId);

  const [setTriviaAnswer, { isLoading: isLoadingAnswer }] =
    useSetTriviaAnswerMutation();

  const navigate = useNavigate();

  const handleTimerStarted = () => {
    const timestamp = Date.now();
    setViewedTs(timestamp);
  };

  const stopCountdown = () => {
    if (timerId?.current) clearInterval(timerId.current);
  };

  const startCountdown = useCallback(() => {
    if (countdownInSeconds !== undefined) {
      // create interval
      timerId.current = setInterval(() => {
        // check for timeouts
        if (countdownInSeconds > 0) {
          setCountdownInSeconds((prevCountdown) => {
            if (typeof prevCountdown === 'number') {
              return prevCountdown - 1;
            }
            return prevCountdown;
          });
        }
      }, 1000);
    }
  }, [countdownInSeconds]);

  const handleSubmit = useCallback(
    async (choiceIndex?: number) => {
      setUserChoiceIndex(choiceIndex);
      if (!onTimeout) {
        stopCountdown();
        const answeredTs = Date.now();
        try {
          const response = await setTriviaAnswer({
            battle_id: battleId,
            choice_index: choiceIndex,
            viewed_ts: viewedTs,
            answered_ts: answeredTs,
            hash: data?.hash || '',
          }).unwrap();
          setAnswer(response);
        } catch (e) {
          const error = e as { status: number };

          // Check if the error response has a status field
          if (error.status && error.status === 500) {
            Sentry.captureException(new Error(`API-ERROR-TRIVIA`), {
              level: 'error',
              extra: {
                battleId,
                choiceIndex,
              },
            });
            setServerError(true);
          } else if (error.status === 400) {
            setServerError(true);
          }
        }
      }
    },
    [battleId, data?.hash, onTimeout, setTriviaAnswer, viewedTs],
  );

  useEffect(() => {
    // if the question is loaded, start the timer
    if (isSuccessQuestion) {
      handleTimerStarted();
    }
  }, [isSuccessQuestion]);

  useEffect(() => {
    // init countdown when the question is loaded
    if (data?.countdown) {
      setCountdownInSeconds(data?.countdown);
    }
  }, [data?.countdown]);

  useEffect(() => {
    // check for timeouts
    // if timeout, submit the answer
    if (countdownInSeconds === 0 && !onTimeout) {
      setOnTimeout(true);

      setTimeout(() => {
        // submit the answer after 2 seconds
        handleSubmit();
      }, 2000);
    }
  }, [countdownInSeconds, handleSubmit, onTimeout]);

  useEffect(() => {
    // countdown management
    startCountdown();

    // when the component unmounts, clear the interval
    return () => {
      stopCountdown();
    };
  }, [countdownInSeconds, startCountdown]);

  if (isLoadingQuestion) return <Skeleton height={200} />;

  return (
    <Stack alignItems="center" flexDirection="column" gap={SpaceSizes.lg}>
      <AlertCard
        content={
          <Typography variant="body1">{t('triviaGame.serverError')}</Typography>
        }
        id="trivia-error"
        severity={AlertSeverity.ERROR}
        show={serverError || isErrorQuestion}
      />
      {answer && <TriviaAnswer answer={answer} />}

      {answer && answer?.user_answer_status === 'wrong' && (
        <Typography variant="body2">
          {isUndefined(userChoiceIndex)
            ? t('triviaGame.rightAnswerIsNotAswered')
            : t('triviaGame.rightAnswerIs')}
        </Typography>
      )}

      {!answer && !serverError && countdownInSeconds !== undefined && (
        <TriviaStatus countdownInSeconds={countdownInSeconds} />
      )}

      <TriviaQuestion
        choiceIndex={userChoiceIndex}
        choices={data?.choices || []}
        disabled={
          isLoadingAnswer ||
          countdownInSeconds === 0 ||
          serverError ||
          onTimeout
        }
        handleChoice={handleSubmit}
        question={data?.question || ''}
        {...(answer ? { rightChoice: answer.correct_choice_index } : {})}
      />
      {answer && (
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            navigate(`/user/${user?.user_id}`);
          }}
        >
          {t('triviaGame.goToMyGolballs')}
        </Button>
      )}
    </Stack>
  );
};

export default TriviaGame;
