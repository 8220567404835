import env from '../../env/env';
import { api } from '../api/Api';
import { RecaptchaRequest, RecaptchaResponse } from './Recaptcha.types';

export const recaptchaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    verifyRecaptcha: builder.mutation<RecaptchaResponse, RecaptchaRequest>({
      query: (request) => ({
        url: `${env.REACT_APP_BASE_API_URL_AUTH}auth/verifyRecaptcha`,
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const { useVerifyRecaptchaMutation } = recaptchaApi;
