import { PaletteMode } from '@mui/material';

export const golballPalette = {
  brandId: 'golball',
  theme: {
    palette: {
      mode: 'dark' as PaletteMode,
      primary: {
        light: '#262951',
        main: '#171839',
        dark: '#262951',
        contrastText: '#F5F7FF',
      },
      secondary: {
        light: '#6ABCFB',
        main: '#419EE9',
        dark: '#1F5B89',
        contrastText: '#F5F7FF',
      },
      background: {
        paper: '#262951',
        default: '#171839',
      },
      text: {
        primary: '#F5F7FF',
        secondary: '#989DC7',
        disabled: '#A9A9A9',
      },
      warning: {
        light: '#E5F66F',
        main: '#D0DF3A',
        dark: '#FFA629',
      },
      error: {
        light: '#FE3B3B',
        main: '#D21F1F',
        dark: '#D51A52',
      },
      info: {
        light: '#0070E3',
        main: '#007AE3',
        dark: '#0D0D0D',
      },
      success: {
        light: '#1FD279',
        main: '#4AB22C',
        dark: '#1FD279',
      },
      button: {
        primary: {
          background: '#419EE9',
          text: '#F5F7FF',
          disableBackground: '#D0D7EF',
          disableText: '#9FACC6',
          border: 'transparent',
          disableBorder: 'transparent',
        },
        secondary: {
          background: 'transparent',
          text: '#548FBD',
          disableBackground: '#D0D7EF',
          disableText: '#9FACC6',
          border: '#1F5B89',
          disableBorder: 'transparent',
        },
        text: {
          background: 'transparent',
          text: '#52B7E0',
          disableBackground: '#D0D7EF',
          disableText: '#9FACC6',
          border: 'transparent',
          disableBorder: 'transparent',
        },
      },
      golden: {
        main: '#DFC25B',
        gradient:
          'linear-gradient(to bottom right, #A56C0B, #D6B64F, #C9A33D,#CEAA44,#DDC058,#F5E279,#F8E67D)',
      },
    },
  },
};
