import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT, TAG_TYPE, TAG_VALUE } from '../../../../utils/tagger';
import BackButton from '../../../components/BackButton/BackButton';
import { Link } from '../../../components/Link/Link';
import useRedirectLoginSignup from '../../../hooks/useRedirectLoginSignup';
import MenuDropdownList from './MenuDropdownList';

interface Props {
  isLoggedUser: boolean;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
}

const MenuDropdown = ({
  isLoggedUser,
  isDropdownOpen,
  setIsDropdownOpen,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const sendDataToGTM = useGTMDispatch();

  const { loginRoute, signupRoute } = useRedirectLoginSignup();

  const handleDrawerClick = () => {
    setOpen(false);
    setIsDropdownOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    sendDataToGTM({
      event: TAG_EVENT.SELECT_CONTENT,
      content_type: TAG_TYPE.MENU,
      item_id: TAG_VALUE.HEADER.OPEN_MENU,
    });
  };

  return (
    <>
      <Button
        sx={{
          backgroundColor: 'background.default',
          borderRadius: 8,
        }}
        onClick={handleOpen}
      >
        {isDropdownOpen ? <CloseIcon /> : <MenuIcon />}
      </Button>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <Box p={SpaceSizes.lg} width={250}>
          <Stack
            divider={<Divider sx={{ width: '100%' }} />}
            spacing={SpaceSizes.sm}
          >
            <BackButton onClick={() => setOpen(false)} />
            {!isLoggedUser && (
              <Stack
                direction="row"
                justifyContent="space-around"
                spacing={SpaceSizes.sm}
              >
                <Link
                  tagId={TAG_VALUE.HEADER.LOGIN}
                  tagSectionId={TAG_TYPE.MENU}
                  to={loginRoute}
                >
                  <Button
                    data-testid="loginButtonHeader"
                    onClick={handleDrawerClick}
                  >
                    <Typography
                      align="center"
                      fontWeight="bold"
                      variant="body2"
                    >
                      {t('header.topMenu.login')}
                    </Typography>
                  </Button>
                </Link>
                <Link
                  tagId={TAG_VALUE.HEADER.REGISTER}
                  tagSectionId={TAG_TYPE.MENU}
                  to={signupRoute}
                >
                  <Button onClick={handleDrawerClick}>
                    <Typography
                      align="center"
                      fontWeight="bold"
                      variant="body2"
                    >
                      {t('header.topMenu.register')}
                    </Typography>
                  </Button>
                </Link>
              </Stack>
            )}
            <MenuDropdownList
              isLoggedUser={isLoggedUser}
              setIsDropdownOpen={handleDrawerClick}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default MenuDropdown;
