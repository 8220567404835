import { Variants } from 'framer-motion';

export const scaleIn: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    transition: { type: 'spring', stiffness: 100, damping: 10, duration: 0.35 },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: 'spring', stiffness: 100, damping: 10, duration: 0.35 },
  },
};
