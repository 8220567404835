import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useState } from 'react';
import { AppleAuthResponse } from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';

import {
  AuthenticatedAppleSessionRequest,
  useLoginByAppleMutation,
} from '../../../features/session/Session.api';
import {
  setSession,
  setShouldTrackSignup,
} from '../../../features/session/Session.slice';
import { getRegisteredCookie } from '../../../utils/cookies';
import { TAG_EVENT } from '../../../utils/tagger';
import { useAppDispatch } from '../../hooks';

const useAppleSignIn = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const [login, { isError }] = useLoginByAppleMutation();

  const dispatch = useAppDispatch();
  const sendDataToGTM = useGTMDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (request: AuthenticatedAppleSessionRequest) => {
    setIsLoading(true);
    const session = await login(request).unwrap();

    sendDataToGTM({
      event: TAG_EVENT.ONBOARDING.LOGIN,
      user_id: session.user_id,
      method: 'Apple',
    });
    const isRegistered = getRegisteredCookie();
    if (!isRegistered) {
      dispatch(setShouldTrackSignup(true));
    }
    // Load session into the store
    dispatch(setSession(session));
    i18n.changeLanguage(session?.user?.lang_id);

    setIsLoading(false);
  };

  const onSuccessAppleLogin = async (
    response: AppleAuthResponse,
    nonceApple: string,
  ) => {
    const firstName = response.user?.name ? response.user.name.firstName : '';

    const lastName = response.user?.name ? response.user.name.lastName : '';

    const identityToken = response.authorization
      ? response.authorization.id_token
      : '';

    const credentials = {
      jwtToken: identityToken,
      nonce: nonceApple,
      lang_id: `${language[0]}${language[1]}`,
      user: {
        photo_url: '',
        first_name: firstName,
        last_name: lastName,
      },
    };

    handleLogin(credentials);
  };

  return {
    onSuccessAppleLogin,
    isError,
    isLoading,
  };
};

export default useAppleSignIn;
