import { useEffect, useState } from 'react';

import { useLazyGetMatchesByTournamentQuery } from '../../../features/tournaments/Tournaments.api';
import { GetMatchesByTournamentResponse } from '../../../features/tournaments/Tournaments.types';

export const useFetchData = () => {
  const [data, setData] = useState<GetMatchesByTournamentResponse[]>();

  const [
    getMatchesByTournament,
    {
      isLoading: getMatchesByTournamentIsLoading,
      isError: getMatchesByTournamentIsError,
    },
  ] = useLazyGetMatchesByTournamentQuery();

  useEffect(() => {
    async function fetchData() {
      const response = await getMatchesByTournament({
        from: '',
        to: '',
      }).unwrap();

      setData(response);
    }
    fetchData();
  }, [getMatchesByTournament]);

  return {
    data,
    setData,
    getMatchesByTournament,
    getMatchesByTournamentIsLoading,
    getMatchesByTournamentIsError,
  };
};
