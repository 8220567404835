import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { URL_CHECKOUT_AUTHENTICATION } from '../../../constants/checkout/checkout';
import { useCreateCheckoutShoppingCartMutation } from '../../../features/checkout/Checkout.api';
import { selectIsUserLoggedIn } from '../../../features/session/Session.selector';
import { useGetItemSummaryQuery } from '../../../features/shoppingCart/ShoppingCart.api';
import { useAppSelector } from '../../hooks';

export const useFetchData = () => {
  const { productId } = useParams();
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const navigate = useNavigate();

  const [createCheckout, { isLoading: createCheckoutIsLoading }] =
    useCreateCheckoutShoppingCartMutation();

  const {
    data: shoppingCartData,
    isLoading: shoppingCartIsLoading,
    isUninitialized: shoppingCartIsUninitialized,
    isError: shoppingCartIsError,
  } = useGetItemSummaryQuery(productId ?? '');

  const handleCreateCheckout = useCallback(async () => {
    if (isLoggedUser && createCheckout) {
      try {
        const response = await createCheckout().unwrap();
        const { checkout_id: checkoutId } = response;
        navigate(`/checkout/${checkoutId}`);
      } catch (error) {
        navigate('/cart');
      }
    } else {
      navigate(
        `${URL_CHECKOUT_AUTHENTICATION}?redirect=${window.location.pathname}`,
      );
    }
  }, [isLoggedUser, createCheckout, navigate]);

  const data = {
    shoppingCartResponse: shoppingCartData,
  };

  return {
    isLoading: shoppingCartIsLoading,
    isError: shoppingCartIsError,
    isUninitialized: shoppingCartIsUninitialized,
    data,
    handleCreateCheckout,
    createCheckoutIsLoading,
  };
};
