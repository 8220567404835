import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import React from 'react';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../../components/containers/PageContainer/PageContainer';
import {
  GOLPOINTS_CONTAINER_IMG_DESKTOP,
  GOLPOINTS_CONTAINER_IMG_MOBILE,
} from './PaymentSuccessCheckout.constants';

export const PaymentSuccessCheckoutSkeleton = () => {
  return (
    <PageContainer id="payment-success-checkout-skeleton">
      <Stack gap={SpaceSizes.lg}>
        <Stack
          alignContent="center"
          alignItems="center"
          gap={SpaceSizes.md}
          justifyContent="center"
          width="100%"
        >
          <Skeleton
            height={SpaceSizes.lgPlus}
            variant="text"
            width={SpaceSizes.xxl}
          />
          <Skeleton
            sx={{
              width: {
                xs: GOLPOINTS_CONTAINER_IMG_MOBILE,
                md: GOLPOINTS_CONTAINER_IMG_DESKTOP,
              },
              height: {
                xs: GOLPOINTS_CONTAINER_IMG_MOBILE,
                md: GOLPOINTS_CONTAINER_IMG_DESKTOP,
              },
            }}
            variant="rectangular"
          />
        </Stack>
        <Stack
          alignItems="center"
          gap={SpaceSizes.md}
          justifyContent="center"
          textAlign="center"
        >
          <Stack alignItems="center">
            <Skeleton
              height={SpaceSizes.mdPlus}
              variant="text"
              width={SpaceSizes.xlPlus}
            />
            <Skeleton
              height={SpaceSizes.mdPlus}
              sx={{
                width: {
                  xs: '300px',
                  md: '320px',
                },
              }}
              variant="text"
            />
          </Stack>
          <Stack
            alignItems="center"
            borderRadius={`${SpaceSizes.smPlus}px`}
            flexDirection="column"
            gap={SpaceSizes.xs}
            justifyContent="center"
            sx={{
              backgroundColor: 'background.paper',
              width: '100%',
              padding: SpaceSizes.smPlus,
            }}
          >
            <Skeleton
              height={SpaceSizes.mdPlus}
              variant="text"
              width={SpaceSizes.xlPlus}
            />
            <Stack
              alignItems="center"
              flexDirection="row"
              gap={SpaceSizes.smPlus}
            >
              <Skeleton
                height={SpaceSizes.lgPlus}
                variant="text"
                width={SpaceSizes.xxl}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack width="100%">
          <Stack alignItems="center" gap={SpaceSizes.sm} width="100%">
            <Button variant="contained" fullWidth>
              <Skeleton
                height={SpaceSizes.mdPlus}
                variant="text"
                width={SpaceSizes.xlPlus}
              />
            </Button>
            <Skeleton
              height={SpaceSizes.mdPlus}
              variant="text"
              width={SpaceSizes.xlPlus}
            />
          </Stack>
        </Stack>
      </Stack>
    </PageContainer>
  );
};
