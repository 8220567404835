import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import SpaceSizes from '../../../../theme/foundations/spacing/SpaceSizes';
import { TAG_EVENT } from '../../../../utils/tagger';
import { useGetItemsToTrack } from '../../../../utils/useGetItemToTrack';
import { useTranslationByNamespaces } from '../../../hooks/shared/useTranslationByNamespaces';
import { Animation } from '../../Animate/Animate';
import { AnimationColor, AnimationEnum } from '../../Animate/Animate.constants';
import GolBallCard from '../../GolBallCard/GolBallCard';
import MarketplaceCard from '../../MarketplaceCard/MarketplaceCard';
import { SearchResultsSkeleton } from './SearchResult.skeleton';
import { ISearchResultsProps } from './SearchResults.types';

export const SearchResults = ({
  results,
  handleDeliver,
  filters,
  isSelf,
  enterWaypoint,
  isLastPage,
  width = '100%',
}: ISearchResultsProps) => {
  const { t } = useTranslationByNamespaces('pages.marketplace.finishScroll');
  const sendDataToGTM = useGTMDispatch();
  const itemsToTrack = useGetItemsToTrack(results);
  const [sentTracks, setSendTracks] = useState(false);

  useEffect(() => {
    if (itemsToTrack.length && !sentTracks) {
      sendDataToGTM({
        event: TAG_EVENT.VIEW_ITEM_LIST,
        ecommerce: {
          filter_type: filters?.length ? filters[0].id : '',
          filter_value: filters?.length ? filters[0].value : '',
          items: itemsToTrack,
        },
      });
      setSendTracks(true);
    }
  }, [itemsToTrack, sendDataToGTM, filters, sentTracks, t]);
  return (
    <Box width={width}>
      <Stack spacing={SpaceSizes.md}>
        <Grid
          columnGap={{ xs: SpaceSizes.sm, sm: SpaceSizes.mdPlus }}
          justifyContent="center"
          rowGap={SpaceSizes.smPlus}
          container
        >
          {results && results.length > 0
            ? results.map((result, index) => (
                <Grid
                  key={result.product_id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  item
                >
                  <Animation
                    color={AnimationColor.PURPLE}
                    timeProps={{
                      delay: 0,
                      duration: 0.2,
                    }}
                    transformProps={{ offsetY: '-5%' }}
                    type={AnimationEnum.Offset}
                  >
                    {handleDeliver ? (
                      <GolBallCard
                        index={index}
                        isSelf={isSelf}
                        product={result}
                      />
                    ) : (
                      <MarketplaceCard index={index} product={result} />
                    )}
                  </Animation>
                </Grid>
              ))
            : null}
        </Grid>
        <Stack>
          {!isLastPage && <Waypoint onEnter={enterWaypoint} />}
          {enterWaypoint && !isLastPage && <SearchResultsSkeleton />}
        </Stack>
      </Stack>
    </Box>
  );
};
