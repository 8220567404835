import { Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { FC, useCallback } from 'react';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { useTranslationByNamespaces } from '../../hooks/shared/useTranslationByNamespaces';
import {
  fadeInDown,
  fadeOutUp,
  GOAL_INFO_WIDTH,
  HISTORY_MIN_HEIGHT,
} from './CardsCart.constants';
import { CardsCartHistoryProps } from './CardsCart.interfaces';

const MotionStack = motion(Stack);
export const CardsCartHistory: FC<CardsCartHistoryProps> = ({
  removedItem,
  onUndoDelete,
}) => {
  const { t } = useTranslationByNamespaces('pages.cart.history');

  const handleRedo = useCallback(() => {
    if (removedItem) {
      onUndoDelete(removedItem);
    }
  }, [onUndoDelete, removedItem]);

  if (!removedItem)
    return (
      <Stack
        borderRadius={SpaceSizes.xs}
        minHeight={HISTORY_MIN_HEIGHT}
        sx={{ backgroundColor: 'transparent' }}
      />
    );
  return (
    <MotionStack
      alignItems="center"
      animate="visible"
      borderRadius={SpaceSizes.xs}
      direction="row"
      exit="exit"
      initial="hidden"
      justifyContent="space-between"
      minHeight={HISTORY_MIN_HEIGHT}
      padding={SpaceSizes.mdPlus}
      sx={{ backgroundColor: 'background.paper' }}
      variants={{
        hidden: fadeInDown.hidden,
        visible: fadeInDown.visible,
        exit: fadeOutUp.exit,
      }}
      width="100%"
      layout
    >
      <Stack
        alignItems="center"
        direction="row"
        fontSize={{ xs: 11, md: 13, lg: 15 }}
        gap={{ xs: SpaceSizes.none, sm: SpaceSizes.sm }}
        width="40%"
      >
        <Stack gap={SpaceSizes.xxs} width={GOAL_INFO_WIDTH}>
          <Stack alignItems="baseline" direction="column" gap={SpaceSizes.sm}>
            <Typography color="neutral.100" fontSize="inherit">
              {t('deleted')}
            </Typography>
            <Typography fontSize={{ xs: 10, md: 12, lg: 14 }}>
              {removedItem.product.title} - {removedItem.product.subtitle.line1}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack alignItems="center" width="20%">
        <Typography color="primary.600" fontWeight="500">
          x{removedItem.selected_quantity}
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: SpaceSizes.none, sm: SpaceSizes.sm }}
        justifyContent="flex-end"
        width="40%"
      >
        <Button sx={{ color: 'info.light' }} onClick={handleRedo}>
          <Typography fontWeight={600} variant="caption">
            {t('undo')}
          </Typography>
        </Button>
      </Stack>
    </MotionStack>
  );
};
