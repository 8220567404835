import { Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { PageContainer } from '../../components/containers/PageContainer/PageContainer';
import useBigDesktopMediaQuery from '../../hooks/useBigDesktopMediaQuery';
import useLaptopMediaQuery from '../../hooks/useLaptopMediaQuery';
import { CardsContainer } from './CardsContainer';
import CollectionCarousel from './CollectionCarousel';
import { convertToCarouselImg } from './CollectionCarousel.utils';
import { CollectionSelector } from './CollectionSelector';
import { CarouselImage } from './CollectionSelector.types';
import { CollectionsHeader } from './CollectionsHeader';
import { useCollectionByGroup } from './useCollectionByGroup';

const CollectionAlbum = () => {
  const isLaptop = useLaptopMediaQuery();
  const isBigDesktop = useBigDesktopMediaQuery();
  const isDesktop = isLaptop || isBigDesktop;
  const { collectionId, collectionGroupId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { collections, pickedCollection, isLoading, setPickedCollection } =
    useCollectionByGroup();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentCarousel, setCurrentCarousel] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [images, setImages] = useState<CarouselImage[]>(
    convertToCarouselImg(collections.collectionByGroup || []),
  );

  const onBackButtonClick = useCallback(() => {
    navigate(`/collections/${collectionGroupId}`);
  }, [collectionGroupId, navigate]);

  useEffect(() => {
    if (collections.collectionByGroup) {
      setImages(convertToCarouselImg(collections.collectionByGroup));
      setLoading(false);
    }
  }, [collections, setImages, setLoading]);
  return (
    <PageContainer
      id="collection-album"
      title={
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          {isLoading || !collections.groupId ? (
            <Skeleton
              sx={{
                height: { xs: 90, sm: 50 },
                width: { xs: 210, sm: 300, md: 340 },
              }}
              variant="text"
            />
          ) : (
            <Stack
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Typography fontWeight="bold" variant="h5">
                {t(`pages.collectionsList.pageTitle.${collections.groupId}`)}
              </Typography>
            </Stack>
          )}
        </Stack>
      }
      titleSx={{ width: '100%' }}
      backButton
      onBackButtonClick={onBackButtonClick}
    >
      <Stack gap={SpaceSizes.sm} width="100%">
        {isDesktop && (
          <CollectionsHeader
            avatar={avatar}
            loading={loading}
            title={currentCarousel}
          />
        )}
        <Stack
          alignItems="start"
          flexDirection={{ md: !isDesktop ? 'column' : 'row' }}
          gap={isDesktop ? SpaceSizes.xxs : SpaceSizes.lg}
          width="100%"
        >
          {isDesktop ? (
            <CollectionCarousel
              collectionGroupId={collectionGroupId || ''}
              images={images}
              loading={loading}
              pickedCollection={pickedCollection}
              setAvatar={setAvatar}
              setCurrentCarousel={setCurrentCarousel}
              setPickedCollection={setPickedCollection}
            />
          ) : (
            <Stack width="100%">
              <CollectionSelector
                collections={collections}
                loading={loading}
                pickedCollection={pickedCollection}
                setPickedCollection={setPickedCollection}
              />
            </Stack>
          )}
          <CardsContainer collectionId={collectionId || ''} loading={loading} />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default CollectionAlbum;
