import {
  Notification,
  TradeNotification,
  TriviaNotification,
} from '../../../features/notifications/responses/Notification';

export interface NotificationProps {
  notification: Notification;
}

export enum NotificationType {
  TRADE_RECEIVED = 'TRADING_OFFER_RECEIVED',
  TRADE_ACCEPTED = 'TRADING_OFFER_ACCEPTED',
  TRADE_REJECTED = 'TRADING_OFFER_REJECTED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  REWARD = 'REWARD',
  BATTLE_STARTED = 'BATTLE_STARTED',
  NEXT_ROUND_STARTED = 'NEXT_ROUND_STARTED',
  NEW_BONUS_QUESTION = 'NEW_BONUS_QUESTION',
  BATTLE_USER_WINNER = 'BATTLE_USER_WINNER',
  BATTLE_USER_ELIMINATED = 'BATTLE_USER_ELIMINATED',
  BATTLE_USER_ELIMINATED_IN_TIE = 'BATTLE_USER_ELIMINATED_IN_TIE',
  BATTLE_USER_ELIMINATED_NOT_SEEN = 'BATTLE_USER_ELIMINATED_NOT_SEEN',
}

// export interface TradeNotificationProps {
//   notification: Notification & { extra_data?: Trade };
// }

// export interface TriviaNotificationProps {
//   notification: Notification & { extra_data?: Trivia };
// }

export type TriviaNotificationProps = NotificationProps & {
  notification: TriviaNotification;
};

export type TradeNotificationProps = NotificationProps & {
  notification: TradeNotification;
};
