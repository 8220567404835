/* eslint-disable react/no-danger */
import { Box, Stack } from '@mui/material';
import { isNonEmptyString, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useMemo } from 'react';

import { useGetExternalContentByIdQuery } from '../../../features/externalContent/ExternalContent.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import i18n from '../../i18n';
import { ExternalContentProps } from './ExternalContent.types';

export const ExternalContent = ({
  type,
  filter,
  defaultChildren,
  downContent,
  align,
  paddingX,
  paddingY,
  vars,
}: React.PropsWithChildren<ExternalContentProps>) => {
  const { language } = i18n;

  const isMobile = useMobileMediaQuery();

  const { data, isSuccess, isUninitialized, isLoading } =
    useGetExternalContentByIdQuery({
      type,
      id: filter,
      lang: language,
    });

  let content = useMemo(
    () =>
      data?.data.length
        ? isMobile // if mobile, use mobile content, if not, use desktop content
          ? isNotNilOrEmpty(data.data[0].attributes.content_mobile)
            ? data.data[0].attributes.content_mobile
            : data.data[0].attributes.content
          : data.data[0].attributes.content // if desktop, use desktop content
        : '', // if no content, return empty string
    [data, isMobile],
  );

  if (vars && vars.length > 0) {
    vars.forEach((v) => {
      content = content?.replace(`{${v.id}}`, v.content);
    });
  }

  if (isUninitialized || isLoading) return null;

  return isSuccess &&
    (isNonEmptyString(content) || isNotNilOrEmpty(defaultChildren)) ? (
    <Stack
      sx={{
        px: paddingX !== undefined ? paddingX : SpaceSizes.mdPlus,
        height: '100%',
        width: '100%',
        py: paddingY !== undefined ? paddingY : SpaceSizes.xs,
      }}
      {...(align && {
        alignItems: align,
      })}
    >
      {isNonEmptyString(content) ? (
        <Stack spacing={SpaceSizes.md}>
          <Box dangerouslySetInnerHTML={{ __html: content || '' }} />
          {downContent}
        </Stack>
      ) : (
        defaultChildren
      )}
    </Stack>
  ) : null;
};
